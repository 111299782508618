import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ConfirmApprove = ({
  show,
  onHide,
  approve,
  handleReference,
  reference,
}) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <h5>Andakah anda pasti untuk meluluskan permohonan?</h5>
          <p>
            Jika anda pasti untuk meluluskan permohonan, perubahan akan dibuat
            di dalam sistem dan anda boleh menyemak maklumat permohonan JPPN di
            dalam profil projek.
          </p>
          <Form.Group className='mb-3'>
            <Form.Label>Rujukan</Form.Label>
            <Form.Control
              type='text'
              value={reference}
              onChange={(e) => handleReference(e.target.value)}
            />
          </Form.Group>
          <div className='d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={onHide}>
              Batal
            </Button>
            <Button variant='success' onClick={approve}>
              Lulus
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmApprove;
