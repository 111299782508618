import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchTargetGroups = createAsyncThunk(
  "targetGroups/fetchTargetGroups",
  async () => {
    const response = await axios.get(`/target-groups`);
    const data = await response.data;
    return data;
  },
);

export const createGroup = createAsyncThunk(
  "targetGroups/createGroups",
  async (values) => {
    const response = await axios.post(`/target-groups`, values);
    const data = await response.data;
    return data;
  },
);

export const updateGroup = createAsyncThunk(
  "targetGroups/updateGroup",
  async (values) => {
    const { id } = values;
    const response = await axios.put(`/target-groups/${id}`, values);
    const data = await response.data;
    return data;
  },
);

export const removeGroup = createAsyncThunk(
  "targetGroups/removeGroup",
  async (id) => {
    const response = await axios.delete(`/target-groups/${id}`);
    const data = await response.data;
    return id;
  },
);

const initialState = {
  groups: [],
  isLoading: true,
  btnLoading: true,
};

export const targetGroupSlice = createSlice({
  name: "targetGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTargetGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.groups.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.groups = state.groups.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.isLoading = false;
    });
    builder.addCase(removeGroup.fulfilled, (state, action) => {
      const id = action.payload;
      state.groups = state.groups.filter(
        (item) => item.id.toString() !== id.toString(),
      );
      state.isLoading = false;
    });
  },
});

export default targetGroupSlice.reducer;
