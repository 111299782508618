import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Card,
  Accordion,
  Table,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import usePalette from "../../../../../hooks/usePalette";
import { Tooltip } from "@mui/material";

// Icons
import Icon from "@mdi/react";
import { mdiPencil, mdiCheckBold, mdiCloseThick } from "@mdi/js";

// redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";

const ExecutingAgencyInfo = ({ project, isAdminLevel }) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [isEdit, setIsEdit] = useState({
    applicant_agency: false,
    owner_agency: false,
    executing_agency: false,
    financially_responsible_agency: false,
  });

  const { btnLoading, departmentOptions } = useSelector((state) => ({
    departmentOptions: state.utilsReducer.dropdownItems.departments,
    btnLoading: state.projectReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicant_agency: project?.applicant_agency?.id || "",
      owner_agency: project?.owner_agency?.id || "",
      executing_agency: project?.executing_agency?.id || "",
      financially_responsible_agency:
        project?.financially_responsible_agency?.id || "",
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        id: project.id,
      };

      await dispatch(updateProject(newValues));

      const hideInput = async (field) => {
        await new Promise((resolve) => {
          setIsEdit((prevIsEdit) => ({
            ...prevIsEdit,
            [field]: false,
          }));
          resolve();
        });
      };

      const keys = Object.keys(values);

      const hideAllInputs = async () => {
        for (const key of keys) {
          await hideInput(key);
        }
      };

      hideAllInputs();
    },
  });

  const handleEditField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: true,
    });
  };

  const handleCloseField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: false,
    });
  };

  const handleSaveForm = () => {
    formik.handleSubmit();
  };

  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>MAKLUMAT AGENSI PELAKSANA</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <Card className='mb-0'>
          <Card.Body>
            <div className='px-3 py-2 table-responsive'>
              <Table size='sm' striped className='m-0 project-overview'>
                <tbody>
                  <tr>
                    <th>Agensi Pemohon</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.applicant_agency ? (
                          <InputGroup>
                            <Form.Select
                              size='sm'
                              className='rounded-2'
                              name='applicant_agency'
                              value={formik.values.applicant_agency}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "applicant_agency",
                                  Number(value),
                                );
                              }}
                            >
                              {departmentOptions.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("applicant_agency")
                              }
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        ) : (
                          <>
                            {project?.applicant_agency
                              ? `${project?.applicant_agency?.code} - ${project?.applicant_agency?.value}`
                              : "-"}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Ubah Agensi Pemohon'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        handleEditField("applicant_agency")
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Agensi Pemilik</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.owner_agency ? (
                          <InputGroup>
                            <Form.Select
                              size='sm'
                              className='rounded-2'
                              name='owner_agency'
                              value={formik.values.owner_agency}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "owner_agency",
                                  Number(value),
                                );
                              }}
                            >
                              {departmentOptions.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() => handleCloseField("owner_agency")}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        ) : (
                          <>
                            {project?.owner_agency
                              ? `${project?.owner_agency?.code} - ${project?.owner_agency?.value}`
                              : "-"}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Ubah Agensi Pemilik'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        handleEditField("owner_agency")
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Agensi Pelaksana</th>
                    <td>
                      <div className='d-flex justify-content-between align-items-center'>
                        {isEdit.executing_agency ? (
                          <InputGroup>
                            <Form.Select
                              size='sm'
                              className='rounded-2'
                              name='executing_agency'
                              value={formik.values.executing_agency}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "executing_agency",
                                  Number(value),
                                );
                              }}
                            >
                              {departmentOptions.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {`${item.label} (${item.short_form})`}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("executing_agency")
                              }
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        ) : (
                          <>
                            {project?.executing_agency
                              ? `${project?.executing_agency?.code} - ${project?.executing_agency?.value}`
                              : "-"}
                            {isAdminLevel && (
                              <>
                                <Tooltip
                                  title='Ubah Agensi Pelaksana'
                                  placement='left'
                                  arrow
                                >
                                  <span>
                                    <Icon
                                      path={mdiPencil}
                                      size={0.7}
                                      color={palette.primary}
                                      onClick={() =>
                                        handleEditField("executing_agency")
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ExecutingAgencyInfo;
