import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import NotyfContext from "../../../../contexts/NotyfContext";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import ExecutingAgencies from "./components/tables/ExecutingAgencies";
import ExecutingAgencyForm from "./components/modals/ExecutingAgencyForm";
import ModalDelete from "../../../../components/common/ModalDelete";
import LoadingState from "../../../../components/common/LoadingState";
import Page500 from "../../../auth/Page500";

// Redux
import {
  fetchExecutingAgencies,
  removeExecutingAgency,
} from "../../../../redux/slices/government-structure/executingAgencySlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const routeParams = useParams();

  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [keywords, setKeywords] = useState("");

  const { isLoading, btnLoading } = useSelector((state) => ({
    isLoading: state.executingAgencyReducer.isLoading,
    btnLoading: state.executingAgencyReducer.btnLoading,
  }));

  const handleResetState = () => {
    setIsEdit(false);
    setItem({});
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    handleResetState();
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    handleResetState();
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeExecutingAgency(data.id));
    handleNotification("error", data);
    handleCloseRemove();
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values?.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values?.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values?.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const goToPage = debounce((value) => {
    setPage(value);
  }, 800);

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToLastPage = (page) => {
    setPage(page);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const handleSearch = debounce((text) => {
    setKeywords(text);
  }, 800);

  useEffect(() => {
    if (isEmpty(routeParams)) {
      const params = {
        page: page,
        per_page: perPage,
        keywords: keywords,
      };
      dispatch(fetchExecutingAgencies(params));
    } else {
      const values = {
        params: {
          page: page,
          keywords: keywords,
        },
      };
    }
  }, [dispatch, page, keywords]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <ExecutingAgencyForm
        showForm={openForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        data={item}
        handleNotification={handleNotification}
        isMinistryPage={!isEmpty(routeParams)}
      />

      <Helmet title='Senarai Jabatan/Agensi' />
      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Struktur Kerajaan'
          breadcrumbItem='Senarai Agensi Pelaksana'
        />
        <Card className='mt-3'>
          <Card.Header>
            <div className={`card-actions float-end`}>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Agensi Pelaksana
              </Button>
            </div>
            <div>
              <div className='d-flex align-items-center gap-2'>
                <div className='search-box-custom'>
                  <div className='position-relative'>
                    <label
                      htmlFor='search-bar-0'
                      className='search-label-custom'
                    >
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Cari agensi...'
                        // value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}
                        style={{ width: 250 }}
                      />
                    </label>
                    <MdSearch className='search-icon-2' size={18} />
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className='pt-0'>
            <ExecutingAgencies
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              perPage={perPage}
              page={page}
              goToPage={goToPage}
              goToFirstPage={goToFirstPage}
              previousPage={previousPage}
              nextPage={nextPage}
              goToLastPage={goToLastPage}
              isMinistryPage={!isEmpty(routeParams)}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
