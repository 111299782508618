import React, { useState } from "react";
import { InputGroup, Form, Collapse } from "react-bootstrap";
import numeral from "numeral";
import CurrencyInput from "react-currency-input-field";
import { IconButton, Tooltip } from "@mui/material";
import usePalette from "../../../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import {
  mdiNoteTextOutline,
  mdiPencil,
  mdiCheck,
  mdiClose,
  mdiMenuDown,
  mdiMenuRight,
} from "@mdi/js";
import { useSelector } from "react-redux";

const TableBody = ({
  data,
  showColumn,
  colSpan,
  project,
  matchCell,
  newValues,
  certifiedId,
  handleChangeAmount,
  handleSaveNewValues,
  handleEditAmount,
  showAllYear,
  showYear,
  handleShow,
  viewColumns,
}) => {
  const palette = usePalette();

  const { certifyFinancial } = useSelector((state) => ({
    certifyFinancial: state.utilsReducer.certifyFinancial,
  }));

  return (
    <tbody>
      {data
        .toSorted((a, b) => a.year - b.year)
        .map((item) => {
          const { children } = item;

          const calculateTotal = (stages, status) => {
            const calculate = children.reduce((total, obj1) => {
              const ceilingStages = obj1.ceiling_flow_detail_stages;
              const filteredStages = ceilingStages.filter(
                (obj2) =>
                  obj2.stages === stages.toString() &&
                  obj2.status === status.toString(),
              );
              if (filteredStages.length > 0) {
                total += parseInt(filteredStages[0].amount, 10);
              }

              return total;
            }, 0);

            return calculate;
          };

          const initialCeilingRequested = calculateTotal(4, 2);
          const certifiedByUpen = calculateTotal(5, 3);
          const certifiedByJppn = calculateTotal(6, 3);

          const calculateEditedTotal = (stages, status) => {
            const findYearData = certifyFinancial.filter(
              (obj1) => obj1.year === Number(item.year),
            );

            const calculate = findYearData.reduce((total, obj2) => {
              return (total += parseFloat(obj2.amount, 10));
            }, 0);

            return calculate;
          };

          const editAmount = calculateEditedTotal();

          return (
            <React.Fragment key={item.year}>
              <tr
                className='custom-table-head'
                onClick={() => handleShow(item.year)}
                style={{ cursor: "pointer" }}
              >
                <td className='text-center' style={{ borderRight: "none" }}>
                  {showYear.includes(item.year) ? (
                    <Icon className='m-0' path={mdiMenuDown} size={1} />
                  ) : (
                    <Icon className='m-0' path={mdiMenuRight} size={1} />
                  )}
                </td>
                <td colSpan={2 + colSpan} style={{ borderLeft: "none" }}>
                  <span
                    className='text-uppercase'
                    style={{ fontSize: 13, fontWeight: 700 }}
                  >
                    Tahun {item.year}
                  </span>
                </td>
              </tr>
              {item.children.map((child, idx) => {
                const upenStages = child.ceiling_flow_detail_stages.find(
                  (obj) => obj.stages === "4" && obj.status === "2",
                );
                const jppnStages = child.ceiling_flow_detail_stages.find(
                  (obj) => obj.stages === "5" && obj.status === "3",
                );

                const initialCeilingRequested =
                  child.ceiling_flow_detail_stages.filter(
                    (obj) => obj.stages === "4" && obj.status === "2",
                  );

                const certifiedCeilingByUpen =
                  child.ceiling_flow_detail_stages.filter(
                    (obj) => obj.stages === "5" && obj.status === "3",
                  );

                return (
                  <tr
                    key={child.id}
                    className='collapse-containing-div text-truncate'
                  >
                    <td className='text-center'>
                      <Collapse in={showYear.includes(item.year)}>
                        <div>{idx + 1}.</div>
                      </Collapse>
                    </td>
                    <td>
                      <Collapse in={showYear.includes(item.year)}>
                        <div>
                          <p className='p-2 mb-0'>
                            {child.cost_description?.toLocaleUpperCase()}
                          </p>
                        </div>
                      </Collapse>
                    </td>

                    {viewColumns.dipohon.checked && (
                      <td className='text-end  text-truncate'>
                        <Collapse in={showYear.includes(item.year)}>
                          <div className='px-2'>
                            {`${numeral(upenStages?.amount).format("0,0.00")}`}
                          </div>
                        </Collapse>
                      </td>
                    )}

                    {/* UPEN Section */}
                    {viewColumns.upen.checked && (
                      <td className='text-end'>
                        <Collapse in={showYear.includes(item.year)}>
                          <div>
                            {matchCell.rowNum === child.id &&
                            matchCell.columnNum === 1 &&
                            project.stages === "4" ? (
                              <InputGroup className='d-flex justify-content-end'>
                                <Form.Control
                                  className={`text-end text-truncate m-1`}
                                  as={CurrencyInput}
                                  min={0}
                                  name='amount'
                                  autoComplete='off'
                                  decimalsLimit={2}
                                  value={newValues?.amount}
                                  onValueChange={(value) =>
                                    handleChangeAmount(value)
                                  }
                                />
                                <IconButton
                                  size='sm'
                                  className='p-1'
                                  onClick={handleSaveNewValues}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <Icon
                                    path={mdiCheck}
                                    size={1}
                                    color={palette.success}
                                  />
                                </IconButton>
                                <IconButton
                                  size='sm'
                                  className='p-1'
                                  onClick={() => handleEditAmount(child.id)}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <Icon
                                    path={mdiClose}
                                    size={1}
                                    color={palette.danger}
                                  />
                                </IconButton>
                              </InputGroup>
                            ) : (
                              <InputGroup className='d-flex justify-content-end'>
                                <div className='text-truncate align-self-center me-2'>
                                  {project.stages === "4" ? (
                                    <React.Fragment>
                                      {`${numeral(child.amount).format(
                                        "0,0.00",
                                      )}`}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {`${numeral(jppnStages?.amount).format(
                                        "0,0.00",
                                      )}`}
                                    </React.Fragment>
                                  )}
                                </div>
                                {project.stages === "4" && (
                                  <Tooltip title='Edit' followCursor arrow>
                                    <IconButton
                                      size='sm'
                                      className='ms-2 p-1 button-animated '
                                      onClick={() => handleEditAmount(child, 1)}
                                      style={{
                                        backgroundColor: "transparent",
                                      }}
                                      disabled={
                                        (certifiedId || []).includes(
                                          child.financial_detail_id,
                                        )
                                          ? true
                                          : false
                                      }
                                    >
                                      <Icon
                                        path={mdiPencil}
                                        size={0.8}
                                        color={`${
                                          (certifiedId || []).includes(
                                            child.financial_detail_id,
                                          )
                                            ? "#c5c5c5"
                                            : palette.primary
                                        }`}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </InputGroup>
                            )}
                          </div>
                        </Collapse>
                      </td>
                    )}

                    {/* JPPN SECTION */}
                    {showColumn.jppn && (
                      <>
                        {viewColumns.jppn.checked && (
                          <td className='text-end'>
                            <Collapse in={showYear.includes(item.year)}>
                              <div>
                                {matchCell.rowNum === child.id &&
                                matchCell.columnNum === 2 &&
                                project.stages === "5" ? (
                                  <InputGroup className='d-flex justify-content-end'>
                                    <div className='align-self-center'>RM</div>
                                    <Form.Control
                                      className={`input-cell text-end text-truncate input-cell-border`}
                                      as={CurrencyInput}
                                      min={0}
                                      name='amount'
                                      autoComplete='off'
                                      decimalsLimit={2}
                                      value={newValues?.amount}
                                      onValueChange={(value) =>
                                        handleChangeAmount(value)
                                      }
                                    />
                                    <IconButton
                                      size='sm'
                                      className='p-1'
                                      onClick={handleSaveNewValues}
                                      style={{ backgroundColor: "transparent" }}
                                    >
                                      <Icon
                                        path={mdiCheck}
                                        size={1}
                                        color={palette.success}
                                      />
                                    </IconButton>
                                    <IconButton
                                      size='sm'
                                      className='p-1'
                                      onClick={() => handleEditAmount(child.id)}
                                      style={{ backgroundColor: "transparent" }}
                                    >
                                      <Icon
                                        path={mdiClose}
                                        size={1}
                                        color={palette.danger}
                                      />
                                    </IconButton>
                                  </InputGroup>
                                ) : (
                                  <InputGroup className='d-flex justify-content-end'>
                                    <div className='text-truncate align-self-center me-2'>
                                      {project.stages === "5" ? (
                                        <React.Fragment>
                                          {`${numeral(child.amount).format(
                                            "0,0.00",
                                          )}`}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {`${numeral(
                                            jppnStages?.amount,
                                          ).format("0,0.00")}`}
                                        </React.Fragment>
                                      )}
                                    </div>
                                    {project.stages === "5" && (
                                      <Tooltip title='Edit' followCursor arrow>
                                        <IconButton
                                          size='sm'
                                          className='ms-2 p-1 button-animated '
                                          onClick={() =>
                                            handleEditAmount(child, 2)
                                          }
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                          disabled={
                                            (certifiedId || []).includes(
                                              child.financial_detail_id,
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          <Icon
                                            path={mdiPencil}
                                            size={0.8}
                                            color={`${
                                              (certifiedId || []).includes(
                                                child.financial_detail_id,
                                              )
                                                ? "#c5c5c5"
                                                : palette.primary
                                            }`}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </InputGroup>
                                )}
                              </div>
                            </Collapse>
                          </td>
                        )}
                      </>
                    )}

                    {/* CABINET SECTION */}
                    {showColumn.cabinet && (
                      <td className='text-end'>
                        <Collapse in={showYear.includes(item.year)}>
                          <div>
                            {matchCell.rowNum === child.id &&
                            matchCell.columnNum === 3 &&
                            project.stages === "6" ? (
                              <InputGroup className='d-flex justify-content-end'>
                                <div className='align-self-center'>RM</div>
                                <Form.Control
                                  className={`input-cell text-end text-truncate input-cell-border`}
                                  as={CurrencyInput}
                                  min={0}
                                  name='amount'
                                  autoComplete='off'
                                  decimalsLimit={2}
                                  value={newValues?.amount}
                                  onValueChange={(value) =>
                                    handleChangeAmount(value)
                                  }
                                />
                                <IconButton
                                  size='sm'
                                  className='p-1'
                                  onClick={handleSaveNewValues}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <Icon
                                    path={mdiCheck}
                                    size={1}
                                    color={palette.success}
                                  />
                                </IconButton>
                                <IconButton
                                  size='sm'
                                  className='p-1'
                                  onClick={() => handleEditAmount(child.id)}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <Icon
                                    path={mdiClose}
                                    size={1}
                                    color={palette.danger}
                                  />
                                </IconButton>
                              </InputGroup>
                            ) : (
                              <InputGroup className='d-flex justify-content-end'>
                                <div className='text-truncate align-self-center me-2'>
                                  {project.stages === "6" ? (
                                    <React.Fragment>
                                      {`${numeral(child.amount).format(
                                        "0,0.00",
                                      )}`}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {`${numeral(jppnStages?.amount).format(
                                        "0,0.00",
                                      )}`}
                                    </React.Fragment>
                                  )}
                                </div>
                                {project.stages === "6" && (
                                  <Tooltip title='Edit' followCursor arrow>
                                    <IconButton
                                      size='sm'
                                      className='ms-2 p-1 button-animated '
                                      onClick={() => handleEditAmount(child, 3)}
                                      style={{
                                        backgroundColor: "transparent",
                                      }}
                                      disabled={
                                        (certifiedId || []).includes(
                                          child.financial_detail_id,
                                        )
                                          ? true
                                          : false
                                      }
                                    >
                                      <Icon
                                        path={mdiPencil}
                                        size={0.8}
                                        color={`${
                                          (certifiedId || []).includes(
                                            child.financial_detail_id,
                                          )
                                            ? "#c5c5c5"
                                            : palette.primary
                                        }`}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </InputGroup>
                            )}
                          </div>
                        </Collapse>
                      </td>
                    )}
                  </tr>
                );
              })}
              <tr>
                <td
                  colSpan={2}
                  className='text-end '
                  style={{ fontWeight: 700 }}
                >
                  JUMLAH
                </td>
                {viewColumns.dipohon.checked && (
                  <td className='text-end ' style={{ fontWeight: 700 }}>
                    <span style={{ marginRight: "0.2rem" }}>
                      {numeral(initialCeilingRequested).format("0,0.00")}
                    </span>
                  </td>
                )}

                {viewColumns.upen.checked && (
                  <td className='text-end ' style={{ fontWeight: 700 }}>
                    <span style={{ marginRight: "0.2rem" }}>
                      {showColumn.jppn ? (
                        <>{numeral(certifiedByUpen).format("0,0.00")}</>
                      ) : (
                        <>{numeral(editAmount).format("0,0.00")}</>
                      )}
                    </span>
                  </td>
                )}

                {showColumn.jppn && (
                  <>
                    {viewColumns.jppn.checked && (
                      <td className='text-end ' style={{ fontWeight: 700 }}>
                        <span style={{ marginRight: "0.2rem" }}>
                          {showColumn.cabinet ? (
                            <>{numeral(certifiedByJppn).format("0,0.00")}</>
                          ) : (
                            <>{numeral(editAmount).format("0,0.00")}</>
                          )}
                        </span>
                      </td>
                    )}
                  </>
                )}
                {showColumn.cabinet && (
                  <td className='text-end ' style={{ fontWeight: 700 }}>
                    <span style={{ marginRight: "0.2rem" }}>
                      <>{numeral(editAmount).format("0,0.00")}</>
                    </span>
                  </td>
                )}
              </tr>
            </React.Fragment>
          );
        })}
    </tbody>
  );
};

export default TableBody;
