import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

import {
  fetchMicroProjects,
  getProjectsApproval,
  createMicroProject,
  getMicroProject,
  updateMicroProject,
  deleteMicroProject,
  storeMicroProjectAttachment,
  previewMicroProjectAttachments,
  removeMicroProjectAttachment,
  getApplicationStatistics,
  getApprovedProjects,
  getApprovedProjectsByDistricts,
  getApprovedProjectsByDun,
  getApprovedProjectsByParliaments,
} from "./microProjectSlice";

const initialState = {
  dashboards: {
    cards: null,
    byDistricts: null,
    byDun: null,
    byParliaments: null,
  },
  microProjects: {
    data: [],
    meta: null,
    links: null,
  },
  applicationStatistics: {
    status_counts: [],
    total_approved_cost: 0,
  },
  microProject: null,
  isLoading: false,
  btnLoading: false,
  keywords: "",
  page: 1,
  perPage: 30,
  selectDistrict: null,
  selectStatus: null,
  isSearching: false,
  attachments: [],
  uploadProgress: {},
  searchApplicationStatus: null,
};

export const microProjectSlices = createSlice({
  name: "microProjects",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setSelectDistrict: (state, action) => {
      state.selectDistrict = action.payload;
    },
    setSelectStatus: (state, action) => {
      state.selectStatus = action.payload;
    },
    setIsSearching: (state, action) => {
      state.isSearching = action.payload;
    },
    storeTempFiles: (state, action) => {
      state.attachments.push(action.payload);
    },
    setUploadProgress: (state, action) => {
      const { fileId, progress } = action.payload;
      state.uploadProgress[fileId] = progress;
    },
    setSearchApplicationStatus: (state, action) => {
      state.searchApplicationStatus = action.payload;
    },
    setResetPage: (state, action) => {
      state.searchApplicationStatus = null;
      state.selectDistrict = null;
      state.keywords = "";
    },
    removeProgress: (state, action) => {
      if (state.uploadProgress[action.payload] === 100) {
        delete state.uploadProgress[action.payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApprovedProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getApprovedProjectsByDistricts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getApprovedProjectsByDun.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      getApprovedProjectsByParliaments.pending,
      (state, action) => {
        state.isLoading = true;
      },
    );

    builder.addCase(getApprovedProjects.fulfilled, (state, action) => {
      state.dashboards.cards = action.payload;
      state.isLoading = false;
    });
    builder.addCase(
      getApprovedProjectsByDistricts.fulfilled,
      (state, action) => {
        state.dashboards.byDistricts = action.payload;
        state.isLoading = false;
      },
    );
    builder.addCase(getApprovedProjectsByDun.fulfilled, (state, action) => {
      state.dashboards.byDun = action.payload;
      state.isLoading = false;
    });
    builder.addCase(
      getApprovedProjectsByParliaments.fulfilled,
      (state, action) => {
        state.dashboards.byParliaments = action.payload;
        state.isLoading = false;
      },
    );

    builder.addCase(fetchMicroProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMicroProjects.fulfilled, (state, action) => {
      const { data, meta, links } = action.payload;
      state.microProjects = {
        data: data,
        meta: meta,
        links: links,
      };
      state.isLoading = false;
    });
    builder.addCase(getProjectsApproval.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectsApproval.fulfilled, (state, action) => {
      const { data, meta, links } = action.payload;
      state.microProjects = {
        data: data,
        meta: meta,
        links: links,
      };
      state.isLoading = false;
    });

    builder.addCase(createMicroProject.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createMicroProject.fulfilled, (state, action) => {
      state.microProject = action.payload;
      state.btnLoading = false;
    });
    builder.addCase(getMicroProject.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getMicroProject.fulfilled, (state, action) => {
      state.microProject = action.payload;
      state.isLoading = false;
    });

    builder.addCase(updateMicroProject.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateMicroProject.fulfilled, (state, action) => {
      state.btnLoading = false;
    });

    builder.addCase(deleteMicroProject.fulfilled, (state, action) => {
      const id = action.payload;
      state.microProjects.data = state.microProjects.data.filter(
        (item) => item.id.toString() !== id.toString(),
      );
      state.btnLoading = false;
    });

    builder.addCase(storeMicroProjectAttachment.fulfilled, (state, action) => {
      const { temp_id } = action.payload;
      state.attachments = state.attachments.map((item) => {
        if (item.temp_id === temp_id) {
          return {
            ...action.payload,
            temp_id: null,
            uploading: false,
          };
        }

        return item;
      });
    });

    builder.addCase(
      previewMicroProjectAttachments.fulfilled,
      (state, action) => {
        state.attachments = action.payload;
      },
    );

    builder.addCase(removeMicroProjectAttachment.fulfilled, (state, action) => {
      const id = action.payload;
      state.attachments = state.attachments.filter((item) => item.id !== id);
    });

    builder.addCase(getApplicationStatistics.fulfilled, (state, action) => {
      state.applicationStatistics = action.payload;
    });
  },
});

export const {
  setPage,
  setPerPage,
  setKeywords,
  setSelectDistrict,
  setIsSearching,
  setSelectStatus,
  storeTempFiles,
  setUploadProgress,
  setSearchApplicationStatus,
  setResetPage,
  removeProgress,
} = microProjectSlices.actions;

export default microProjectSlices.reducer;
