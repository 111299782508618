import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select, { components } from "react-select";
import _, { isEmpty } from "lodash";
import CurrencyInput from "react-currency-input-field";
import numeral from "numeral";

// Icons
import { FaCheck } from "react-icons/fa";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Styles
import usePalette from "../../../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../../../components/common/customStyleSelect";

// Redux
import {
  createCeilingFlow,
  updateCeilingFlow,
  createCeilingFlowNote,
} from "../../../../../../../redux/slices/projects/financialDetailsSlice";

const CeilingFlowForm = ({
  openCeilingFlowForm,
  toggleCeilingFlowForm,
  selectedYear,
  data,
  isEdit,
  ceilingFlowInfo,
  handleNotification,
}) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [selectedOption, setSelectedOption] = useState({
    label: "Pilih...",
    value: "",
  });
  const [options, setOptions] = useState([]);
  const [costingInfo, setCostingInfo] = useState({});
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [negativeNum, setNegativeNum] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState({});

  const [calculateCeiling, setCalculateCeiling] = useState(0);

  const { btnLoading, user, financialDetails } = useSelector((state) => ({
    user: state.userReducer.user,
    financialDetails: state.projectReducer.project.financial_details,
    btnLoading: state.projectReducer.btnLoading,
  }));

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: (!isEmpty(data) && data.amount) || "",
      note: "",
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .required("Sila masukkan jumlah siling")
        .min(1, "Sila masukan jumlah siling"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          financial_detail_id: data.financial_detail_id,
          amount: values.amount,
        };
        await dispatch(updateCeilingFlow(newValues));
        handleNotification("edited", null);
        handleCloseModal();
      } else {
        const newValues = {
          financial_detail_id: costingInfo.id,
          year: selectedYear,
          amount: values.amount,
        };

        await dispatch(createCeilingFlow(newValues));
        handleNotification("created", null);
        handleCloseModal();
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    formik.handleSubmit();
    return false;
  };

  const handleChangeDescription = (item) => {
    const selected = financialDetails.find(
      (financial) => financial.cost_description === item.value,
    );

    const ceilingFlowAmount = selected.ceiling_flow_details.reduce(
      (acc, flow) => acc + parseFloat(flow.amount),
      0,
    );

    let num1 = 0;
    let num2 = 0;
    if (isEdit) {
      const isEditCeiling = ceilingFlowAmount - parseFloat(data.amount);
      const balanceCeiling =
        selected.cost_required - isEditCeiling - data.amount;
      num1 = isEditCeiling;
      num2 = balanceCeiling;
    } else {
      const totalCeling = parseFloat(selected.cost_required);

      const balanceCeiling = totalCeling - ceilingFlowAmount;

      num1 = ceilingFlowAmount;

      num2 = balanceCeiling;
    }

    setCalculateCeiling(num1);
    setBalanceAmount(num2);
    setCostingInfo(selected);

    const newValues = {
      label: selected.cost_description,
      value: selected.cost_description,
    };

    setSelectedOption(newValues);

    formik.resetForm();
  };

  const handleCloseModal = () => {
    formik.resetForm();
    setSelectedOption({ label: "Pilih...", value: "" });
    toggleCeilingFlowForm();
    setCostingInfo({});
    setBalanceAmount(0);
  };

  const handleCalculate = (value) => {
    let num = parseFloat(value);

    if (isEmpty(value)) {
      num = 0;
    }

    const total_amount = parseFloat(costingInfo.cost_required);

    const balance = total_amount - calculateCeiling;

    setBalanceAmount(balance - num);

    const isNegativeFigure = _.lt(balance - num, 0);

    if (isNegativeFigure) {
      setNegativeNum(true);
    } else {
      setNegativeNum(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(financialDetails)) {
      let selectedId = [];

      if (!isEmpty(ceilingFlowInfo)) {
        selectedId = ceilingFlowInfo.map((item) => item.financial_detail_id);
      }

      const newOptions = financialDetails.map((financial) => {
        const uniqueId = selectedId.find((id) => id === financial.id);
        let isDisabled = false;
        if (uniqueId) {
          isDisabled = true;
        }

        const newItem = {
          id: financial.id,
          label: financial.cost_description,
          value: financial.cost_description,
          isDisabled: isDisabled,
        };

        return newItem;
      });
      setOptions(newOptions);
    }

    if (!isEmpty(data)) {
      const item = {
        ...data,
        label: data.cost_description,
        value: data.cost_description,
      };
      handleChangeDescription(item);
    }
  }, [openCeilingFlowForm]);

  const { Option } = components;

  const CheckOption = (props) => {
    return (
      <Option {...props}>
        {props.label}
        {props.isDisabled && (
          <FaCheck color={palette.success} className='ms-3' size={16} />
        )}
      </Option>
    );
  };

  return (
    <Modal show={openCeilingFlowForm} onHide={handleCloseModal} centered>
      <Modal.Body>
        <Table size='sm' className='custom-border-table'>
          <thead>
            <tr>
              <th colSpan={2} className='text-center'>
                Maklumat Kewangan Projek
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='px-0'>
              <td className='text-end' style={{ width: "45%" }}>
                Keterangan Kos Dipohon
              </td>
              <td className='ps-3'>
                {costingInfo.cost_description ? (
                  <span className='fw-bold'>
                    {costingInfo.cost_description}
                  </span>
                ) : (
                  <span className='fst-italic'>Maklumat Belum Dipilih</span>
                )}
              </td>
            </tr>
            <tr className='px-0'>
              <td className='text-end' style={{ width: "30%" }}>
                Jumlah Keseluruhan Siling Dipohon
              </td>
              <td className='ps-3'>
                {costingInfo.cost_required ? (
                  <span>{`RM ${numeral(costingInfo.cost_required).format(
                    "0,0.00",
                  )}`}</span>
                ) : (
                  "RM 0.00"
                )}
              </td>
            </tr>
            <tr className='px-0'>
              <td className='text-end' style={{ width: "30%" }}>
                Baki Siling
              </td>
              <td className='ps-3'>
                <span className={`${negativeNum ? "text-error" : ""}`}>
                  {`RM ${numeral(balanceAmount).format("0,0.00")}`}
                </span>
                {negativeNum && (
                  <small
                    className={`${
                      negativeNum ? "text-error" : ""
                    } ms-3 fst-italic`}
                  >
                    Baki kos tidak boleh negatif.
                  </small>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <hr />

        <h4 className='m-0'>Keterangan Kos RMK Untuk Tahun {selectedYear}</h4>
        <p className='text-muted fw-bold'>
          <small>
            Pilihan Skop Kerja adalah mengikut pilihan Keseluruhan Kewangan
            Projek
          </small>
        </p>

        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Keterangan Kos Dipohon (Skop Kerja)</Form.Label>
            <Select
              isDisabled={isEmpty(data) ? false : true}
              name='ceiling_flow_description'
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              options={options}
              components={{ Option: CheckOption }}
              styles={customStylesForm}
              value={selectedOption}
              onChange={handleChangeDescription}
              noOptionsMessage={() => "Tiada Pilihan"}
            />
            {formik.touched.ceiling_flow_description &&
              formik.errors.ceiling_flow_description && (
                <p className='mt-1 custom-feedback-invalid'>
                  {formik.errors.ceiling_flow_description}
                </p>
              )}
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Jumlah Siling (RM)</Form.Label>
            <Form.Control
              disabled={selectedOption.value === "" ? true : false}
              as={CurrencyInput}
              name='amount'
              placeholder='0.00'
              decimalsLimit={2}
              value={formik.values.amount}
              onValueChange={(value) => {
                formik.setFieldValue("amount", value);
                handleCalculate(value);
              }}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.amount && !formik.errors.amount ? true : false
              }
              isInvalid={
                formik.touched.amount && formik.errors.amount ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.amount}
            </Form.Control.Feedback>
          </Form.Group>
          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              variant='light'
              onClick={handleCloseModal}
              disabled={btnLoading}
            >
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading || negativeNum}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CeilingFlowForm;
