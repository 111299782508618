import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchUpenSections = createAsyncThunk(
  "upenSections/fetchUpenSections",
  async (props) => {
    try {
      const { source } = props;

      const response = await axios.get(`/maintenance/upen-sections`, {
        cancelToken: source.token,
      });
      const data = await response.data;
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createUpenSection = createAsyncThunk(
  "upenSections/createUpenSection",
  async (values) => {
    const response = await axios.post(`/maintenance/upen-sections`, values);
    const data = await response.data;
    return data;
  },
);

export const updateUpenSection = createAsyncThunk(
  "upenSections/updateUpenSection",
  async (values) => {
    const { id } = values;
    const response = await axios.put(
      `/maintenance/upen-sections/${id}`,
      values,
    );
    const data = await response.data;
    return data;
  },
);

export const removeUpenSection = createAsyncThunk(
  "upenSections/removeUpenSection",
  async (id) => {
    const response = await axios.delete(`/maintenance/upen-sections/${id}`);
    const data = await response.data;
    return id;
  },
);

const initialState = {
  upenSections: [],
  isLoading: true,
  btnLoading: true,
};

export const upenSectionsSlice = createSlice({
  name: "upenSections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUpenSections.fulfilled, (state, action) => {
      state.upenSections = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createUpenSection.fulfilled, (state, action) => {
      state.upenSections.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateUpenSection.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.upenSections = state.upenSections.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.isLoading = false;
    });
    builder.addCase(removeUpenSection.fulfilled, (state, action) => {
      const id = action.payload;
      state.upenSections = state.upenSections.filter(
        (item) => item.id.toString() !== id.toString(),
      );
      state.isLoading = false;
    });
  },
});

export default upenSectionsSlice.reducer;
