import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import Img from "../../../../../../assets/img/illustrations/physical-info.png";

const ProjectInformation = () => {
  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  return (
    <React.Fragment>
      <Card className='flex-fill illustration'>
        <Card.Body className='position-relative'>
          <h5 className='illustration-text'>Maklumat projek</h5>
          <div className='mb-3'>
            <div className='text-sm'>Nama Projek</div>
            <Card.Text style={{ fontWeight: 500 }}>{project.name}</Card.Text>
          </div>
          <div className='mb-3'>
            <div className='text-sm'>RMK</div>
            <Card.Text style={{ fontWeight: 500 }}>
              {`${project.rmk?.name} (${project.rmk?.short_form}): ${project.rmk?.first_year} - ${project.rmk?.fifth_year}`}
            </Card.Text>
          </div>
          <div className='mb-3'>
            <div className='text-sm'>Kategori Projek</div>
            <Card.Text style={{ fontWeight: 500 }}>
              {project.project_category?.name}
            </Card.Text>
          </div>
          <div className='mb-3'>
            <div className='text-sm'>Jenis Projek</div>
            <Card.Text style={{ fontWeight: 500 }}>
              {project.project_type?.name}
            </Card.Text>
          </div>

          <div className='position-absolute' style={{ right: 0, bottom: 0 }}>
            <img
              style={{ width: 180, height: "auto" }}
              src={Img}
              alt='Customer Support'
              className='img-fluid illustration-img opacity-75'
            />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ProjectInformation;
