import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import numeral from "numeral";
import { Form, Pagination, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

const CeilingSettings = ({ handleRemoveItem }) => {
  const [tableData, setTableData] = useState([]);

  const { isLoading, ceilingLimits } = useSelector((state) => ({
    isLoading: state.ceilingLimitReducer.isLoading,
    ceilingLimits: state.ceilingLimitReducer.ceilingLimits,
  }));

  useEffect(() => {
    if (isEmpty(ceilingLimits)) return;

    const groupData = (data) => {
      const groupedData = data?.reduce((result, item) => {
        const ministry = item.ministry?.id;
        if (!result[ministry]) {
          result[ministry] = {
            total_ceiling: 0,
            ministry: item.ministry,
            rmk: item.rmk,
            children: [item],
          };
        } else {
          if (!isEmpty(item.department)) {
            result[ministry].children.push(item);
          } else {
            result[ministry].total_ceiling = item.ceiling;
          }
        }

        return result;
      }, {});
      return Object.values(groupedData);
    };

    const groupedCeilingLimits = groupData(ceilingLimits);

    setTableData(groupedCeilingLimits);

    return () => {
      setTableData([]);
    };
  }, [ceilingLimits]);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table hover size='sm'>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th className='text-center'>Bil.</th>
              <th>RMK</th>
              <th />
              <th className='text-center'>Siling (RM)</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody className='border-start border-end'>
            {isEmpty(ceilingLimits) ? (
              <tr>
                <td colSpan={5} className='text-center'>
                  Maklumat tidak tersedia
                </td>
              </tr>
            ) : (
              <React.Fragment>
                {tableData.map((group) => {
                  const { ministry, rmk, children, total_ceiling } = group;

                  return (
                    <React.Fragment key={ministry?.id}>
                      <tr className='custom-table-row'>
                        <td />
                        <td>{rmk.value}</td>
                        <td>
                          <span style={{ fontWeight: 600 }}>
                            {ministry?.value}
                          </span>
                        </td>
                        <td className='text-end'>
                          {" "}
                          <span className='me-6'>
                            {numeral(total_ceiling).format("0,0.00")}
                          </span>
                        </td>
                        <td />
                      </tr>
                      {children.map((item, idx) => {
                        return (
                          <tr key={item.id}>
                            <td className='text-center'>{idx + 1}.</td>
                            <td>{item?.rmk?.value}</td>
                            <td>{item?.department?.value}</td>
                            <td className='text-end'>
                              <span className='me-6'>
                                {numeral(item?.ceiling).format("0,0.00")}
                              </span>
                            </td>
                            <td>
                              <div className='d-flex justify-content-center'>
                                <Tooltip title='Edit' placement='top' arrow>
                                  <IconButton
                                    color='info'
                                    size='small'
                                    aria-label='Edit'
                                    // onClick={() => handleEditItem(item)}
                                  >
                                    <MdModeEditOutline fontSize='large' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title='Padam' placement='top' arrow>
                                  <IconButton
                                    color='error'
                                    size='small'
                                    aria-label='Padam'
                                    onClick={() => handleRemoveItem(item)}
                                    // disabled={!isSuperadminOrAdmin}
                                  >
                                    <MdDelete fontSize='large' />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            )}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default CeilingSettings;
