import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

import dashboardItems from "../components/sidebar/dashboardItems";
import { isEmpty } from "lodash";

import LoadingState from "../components/common/LoadingState";
import LoadingPage from "../components/common/LoadingPage";

const FirstTimeLoginLayout = ({ children }) => {
  const user = useSelector((state) => state.userReducer.user);
  const isLoading = useSelector((state) => state.userReducer.isLoading);

  if (isEmpty(user)) {
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#0288d1" }}>
          <LoadingState />
        </div>
      </React.Fragment>
    );
  }

  if (isLoading) return <LoadingPage />;

  return (
    <React.Fragment>
      <Wrapper>
        {/* <Sidebar items={dashboardItems} /> */}
        <Main>
          {/* <Navbar /> */}
          <Content>
            {children}
            <Outlet />
          </Content>
          {/* <Footer /> */}
        </Main>
      </Wrapper>
      <Settings />
    </React.Fragment>
  );
};

export default FirstTimeLoginLayout;
