import React, { useEffect } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Styles
import usePalette from "../../../../../../hooks/usePalette";
import { customStyles } from "../../../../../../components/common/customStyleSelect";

// Redux
import {
  createStateLegislativeAssembly,
  updateStateLegislativeAssembly,
} from "../../../../../../redux/slices/state-information/stateLegislativeAssembliesSlice";
import { isEmpty } from "lodash";

const StateLegislativeAssemblyForm = ({
  showForm,
  closeForm,
  isEdit,
  data,
  handleNotification,
}) => {
  const dispatch = useDispatch();

  const { btnLoading, parliaments } = useSelector((state) => ({
    btnLoading: state.stateLegislativeAssemblyReducer.btnLoading,
    parliaments: state.utilsReducer.dropdownItems.parliamentItems,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      code: data.code || "",
      parliament: data.parliament || null,
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          parliament_id: values.parliament?.id,
          name: values.name,
          code: values.code,
        };
        await dispatch(updateStateLegislativeAssembly(newValues));
        handleNotification("edited", newValues);
      } else {
        const newValues = {
          parliament_id: values.parliament?.id,
          name: values.name,
          code: values.code,
        };
        await dispatch(createStateLegislativeAssembly(newValues));
        handleNotification("created", newValues);
      }
      handleCloseModal();
    },
  });

  const handleCloseModal = () => {
    closeForm();
  };

  // useEffect(() => {
  //   if (!isEmpty(data)) {
  //     formik.setFieldValue("parliament", data.parliament);
  //   }
  // }, [data]);

  // console.log(formik.values.parliament);

  return (
    <Modal show={showForm} onHide={closeForm} centered>
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Dewan Undangan Negeri</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Nama Dewan Undangan Negeri</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kod</Form.Label>
            <Form.Control
              type='text'
              name='code'
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Dibawah Parlimen</Form.Label>
            <Select
              name='parliament'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              isClearable
              options={parliaments}
              styles={customStyles}
              placeholder='Pilih parlimen'
              noOptionsMessage={() => "Tiada Pilihan"}
              value={formik.values.parliament}
              onChange={(value) => formik.setFieldValue("parliament", value)}
            />
            {formik.errors.users && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.users}
              </p>
            )}
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              className='text-uppercase'
              variant='light'
              onClick={handleCloseModal}
            >
              Batal
            </Button>
            <Button
              className='px-3 text-uppercase'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default StateLegislativeAssemblyForm;
