import React from "react";
import { Offcanvas } from "react-bootstrap";

const ToggleColumn = ({ showCanvas, closeCanvas }) => {
  return (
    <React.Fragment>
      <Offcanvas show={showCanvas} onHide={closeCanvas} style={{ width: 310 }}>
        <Offcanvas.Body>
          <h5>Pilih maklumat yang diperlukan</h5>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default ToggleColumn;
