import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const LineGraph = () => {
  const [yearNow, setYearNow] = useState(new Date().getFullYear());
  const [fiftyPercent, setFiftyPercent] = useState(0);
  const [series, setSeries] = useState([]);

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  const options = {
    chart: {
      height: 350,
      type: "line",
      id: "areachart-2",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.4,
      },
    },
    legend: {
      position: "top",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       // y: initialAllocation / 2,
    //       borderColor: "#009688",
    //       label: {
    //         borderColor: "#009688",
    //         textAnchor: "start",
    //         position: "left",
    //         style: {
    //           color: "#fff",
    //           background: "#009688",
    //         },
    //         text: "50 %",
    //       },
    //     },
    //   ],
    // },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    yaxis: {
      show: true,
      title: {
        text: "Perbelanjaan (RM)",
        rotate: -90,
        style: {
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 90,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
        formatter: (value) => {
          return numeral(value).format("0.0 a");
        },
      },
      // max: initialAllocation,
    },
    xaxis: {
      categories: Array.from({ length: 12 }, (e, i) => {
        return new Date(null, i + 1, null).toLocaleDateString("ms", {
          month: "short",
        });
      }),
    },
    colors: ["#388e3c", "#303f9f", "#e64a19", "#ffb300"],
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: (value, opts) => {
        if (value === null || value === undefined) {
          return "";
        }
        const newValue = `RM ${numeral(value).format("0.0 a")}`;
        return newValue;
      },
      offsetY: -20,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 3,
    },
  };

  useEffect(() => {
    if (isEmpty(project)) return;

    const { reports, s3p_data } = project;
    const currentYear = new Date().getFullYear();

    const amountByMonth = Array(13).fill({
      terkini_raw: 0,
      bendahari: 0,
      lejar: 0,
      terkini: 0,
    });

    const agencyExpenditure = Array(13).fill(0);
    const cumulativeAmountByMonths = Array(13).fill({
      terkini_raw: null,
      bendahari: null,
      lejar: null,
      terkini: null,
    });
    const cumulativeAgencyExpenditure = Array(13).fill(null);

    const currentYearExpenditure = s3p_data?.find(
      (item) => item.year === currentYear,
    );

    currentYearExpenditure?.monthly_info?.forEach((item) => {
      const month = Number(item.month);

      const bendahari = parseFloat(item.bendahari);
      const terkini = parseFloat(item.terkini);
      const lejar = parseFloat(item.lejar);

      amountByMonth[month] ??= {
        terkini_raw: 0,
        bendahari: 0,
        lejar: 0,
        terkini: 0,
      };
      amountByMonth[month].terkini_raw += terkini;
      amountByMonth[month].bendahari += bendahari;
      amountByMonth[month].lejar += lejar;
      amountByMonth[month].terkini += lejar + terkini;

      cumulativeAmountByMonths[month] = {
        terkini_raw:
          cumulativeAmountByMonths[month - 1]?.terkini_raw + terkini ||
          terkini ||
          null,

        bendahari:
          cumulativeAmountByMonths[month - 1]?.bendahari + bendahari ||
          bendahari ||
          null,

        lejar:
          cumulativeAmountByMonths[month - 1]?.lejar + lejar || lejar || null,

        terkini:
          cumulativeAmountByMonths[month - 1]?.terkini + (lejar + terkini) ||
          lejar + terkini ||
          null,
      };
    });

    reports?.forEach((item) => {
      const month = Number(item.month);
      if (month && item.total_expenses_report_by_agency) {
        const total = parseFloat(item.total_expenses_report_by_agency);
        agencyExpenditure[month] += total;
        cumulativeAgencyExpenditure[month] =
          (cumulativeAgencyExpenditure[month - 1] || 0) +
          agencyExpenditure[month];
      }
    });

    const result = [
      {
        name: "PERBELANJAAN TERKINI",
        data: cumulativeAmountByMonths
          .slice(1)
          .map((item) => item?.terkini ?? null),
      },
      {
        name: "PERBELANJAAN S3P",
        data: cumulativeAmountByMonths
          .slice(1)
          .map((item) => item?.bendahari ?? null),
      },
      {
        name: "PERBELANJAAN SEBENAR",
        data: cumulativeAmountByMonths
          .slice(1)
          .map((item) => item?.lejar ?? null),
      },
      {
        name: "LAPORAN PERBELANJAAN DARI AGENSI",
        data: cumulativeAgencyExpenditure
          .slice(1)
          .map((item) => item?.terkini ?? null),
      },
    ];

    setSeries(result);
  }, [project]);

  return (
    <React.Fragment>
      <Card className='w-100 flex-fill'>
        <Card.Header>
          <Card.Title className='mb-0'>Prestasi Kewangan Tahun 2024</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className='d-flex justify-content-center align-items-center'>
            <ReactApexChart
              className='w-100 flex-fill'
              options={options}
              series={series}
              height={350}
            />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default LineGraph;
