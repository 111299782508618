import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MessageModal = ({ openModal, closeModal, message }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Modal
        show={openModal}
        onHide={closeModal}
        centered
        backdrop='static'
        keyboard={false}
      >
        <Modal.Body>
          <h5 className='text-center'>{message}</h5>
          <div className='d-flex justify-content-center'>
            <Button onClick={() => navigate(`/auth/sign-in`)}>Tutup</Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MessageModal;
