import {
  MdDashboard,
  MdOutlinePeopleAlt,
  MdTopic,
  MdRuleFolder,
  MdTableChart,
  MdChecklist,
  MdLogout,
  MdFolder,
  MdAnnouncement,
  MdGroups,
  MdFormatListNumbered,
} from "react-icons/md";
import {
  FaClipboardList,
  FaListUl,
  FaChartPie,
  FaFileInvoice,
  FaGlobeAsia,
  FaCogs,
} from "react-icons/fa";
import { GiPlanetCore } from "react-icons/gi";
import {
  RiOrganizationChart,
  RiFolderSettingsFill,
  RiFileList3Fill,
} from "react-icons/ri";
import { BiTransfer } from "react-icons/bi";

const dashboardSection = [
  {
    href: "/pengumuman",
    icon: MdAnnouncement,
    title: "Pengumuman",
    accessLevel: ["pengumuman", "superadmin"],
  },
  {
    href: "/dashboard",
    icon: MdDashboard,
    title: "Dashboard",
    accessLevel: ["dashboard", "superadmin"],
  },
  {
    href: process.env.REACT_APP_ECONOMIC_DATA,
    icon: MdDashboard,
    title: "Data Ekonomi",
    externalUrl: true,
    accessLevel: ["data_ekonomi", "superadmin"],
  },
];

const registrationSection = [
  {
    href: "/permohonan",
    icon: MdTopic,
    title: "Daftar Projek",
    accessLevel: [
      "daftar_projek_baharu",
      "daftar_projek_sambungan",
      "daftar_projek_sedia_ada",
      "status_permohonan",
      "superadmin",
    ],
    children: [
      {
        href: "/permohonan/projek/daftar-projek-rmk/baharu",
        title: "Baharu",
        accessLevel: ["daftar_projek_baharu", "superadmin"],
      },
      {
        href: "/permohonan/senarai-projek/sambungan",
        title: "Sambungan",
        accessLevel: ["daftar_projek_sambungan", "superadmin"],
      },
      {
        href: "/permohonan/senarai-projek/sedia-ada",
        title: "Sedia Ada",
        accessLevel: ["daftar_projek_sedia_ada", "superadmin"],
      },
      {
        href: "/senarai-projek/status-permohonan",
        title: "Status Permohonan Projek",
        accessLevel: ["status_permohonan", "superadmin"],
      },
    ],
  },
  {
    href: "/permohonan",
    icon: MdTopic,
    title: "JPPN",
    accessLevel: [
      "jppn_projek_baru",
      "jppn_kenaikan_siling",
      "jppn_perubahan_skop",
      "jppn_ubah_tajuk",
      "jppn_ubah_lokasi",
      "jppn_senarai_status_permohonan",
      "jppn_perakuan_jppn",
      "superadmin",
    ],
    children: [
      {
        href: "/senarai-projek/jppn/penyenaraian-projek-baharu",
        title: "Penyenaraian Projek Baru",
        accessLevel: ["jppn_projek_baru", "superadmin"],
      },
      {
        href: "/senarai-projek/jppn/kenaikan-siling",
        title: "Kenaikan Siling",
        accessLevel: ["jppn_kenaikan_siling", "superadmin"],
      },
      {
        href: "/senarai-projek/jppn/perubahan-skop",
        title: "Perubahan Skop",
        accessLevel: ["jppn_perubahan_skop", "superadmin"],
      },
      {
        href: "/senarai-projek/jppn/perubahan-nama-projek",
        title: "Perubahan Nama Projek",
        accessLevel: ["jppn_ubah_tajuk", "superadmin"],
      },
      {
        href: "/senarai-projek/jppn/ubah-lokasi",
        title: "Ubah Lokasi",
        accessLevel: ["jppn_ubah_lokasi", "superadmin"],
      },
      {
        href: "/senarai-projek/jppn/status-permohonan",
        title: "Senarai Permohonan",
        accessLevel: ["superadmin", "jppn_senarai_status_permohonan"],
      },
      {
        href: "/perakuan/jppn",
        title: "Perakuan JPPN",
        accessLevel: ["jppn_perakuan_jppn", "superadmin"],
      },
    ],
  },
  {
    href: "/perakuan/senarai-projek",
    icon: MdRuleFolder,
    title: "Perakuan",
    accessLevel: ["perakuan", "superadmin"],
  },
];

const monitoringSection = [
  {
    href: "/laporan/senarai-projek",
    icon: FaListUl,
    title: "Laporan Prestasi Projek",
    accessLevel: ["laporan_prestasi_projek", "superadmin"],
  },
];

const projectDetailsSection = [
  {
    href: "/senarai-projek/jadual-analisis-dalaman",
    icon: MdTableChart,
    title: "Jadual Analisis Dalaman",
    accessLevel: ["jadual_analisis_dalaman", "superadmin"],
  },
  {
    href: "/senarai-projek/",
    icon: MdFolder,
    title: "Ringkasan Projek",
    accessLevel: ["ringkasan_projek", "superadmin"],
  },
];

const adminSection = [
  {
    href: "/admin/maklumat-rmk",
    icon: RiFolderSettingsFill,
    title: "Maklumat RMK",
    accessLevel: ["superadmin"],
    children: [
      {
        href: "/maklumat-rmk/rancangan-malaysia",
        title: "Senarai RMK",
        accessLevel: ["superadmin"],
      },
      {
        href: "/maklumat-rmk/senarai-program-rmk",
        title: "Senarai Program",
        accessLevel: ["superadmin"],
      },
      {
        href: "/maklumat-rmk/senarai-projek-utama",
        title: "Senarai Projek Utama",
        accessLevel: ["superadmin"],
      },
      {
        href: "/maklumat-rmk/sektor-utama-negeri",
        title: "Sektor Utama Negeri",
        accessLevel: ["superadmin"],
      },
    ],
  },
  {
    href: "/admin/struktur-kerajaan",
    icon: RiOrganizationChart,
    title: "Struktur Kerajaan",
    accessLevel: ["superadmin"],
    children: [
      {
        href: "/admin/maklumat-negeri/daerah",
        title: "Daerah",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/maklumat-negeri/parlimen",
        title: "Parlimen",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/maklumat-negeri/dewan-undangan-negeri",
        title: "Dewan Undangan Negeri (DUN)",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/struktur-kerajaan/kementerian",
        title: "Kementerian",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/struktur-kerajaan/jabatan-dan-agensi",
        title: "Jabatan dan Agensi",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/struktur-kerajaan/jabatan/cawangan",
        title: "Cawangan Jabatan",
        accessLevel: ["superadmin"],
      },
    ],
  },
  {
    href: "/admin/senarai-pengguna",
    icon: MdOutlinePeopleAlt,
    title: "Senarai Pengguna",
    accessLevel: ["superadmin"],
  },
  {
    href: "/admin/senarai-peranan",
    icon: MdGroups,
    title: "Senarai Peranan",
    accessLevel: ["superadmin"],
  },
  {
    href: "/admin/penyelenggaraan",
    icon: FaCogs,
    title: "Penyelenggaraan",
    accessLevel: ["superadmin"],
    children: [
      {
        href: "/admin/penyelenggaraan/skop-projek",
        title: "Skop Projek",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/penyelenggaraan/skor-kredit-kriteria",
        title: "Skor Kredit Kriteria",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/penyelenggaraan/kumpulan-sasar",
        title: "Kumpulan Sasar",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/penyelenggaraan/seksyen-upen",
        title: "Seksyen UPEN",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/penyelenggaraan/maklumat-pembangunan-mampan",
        title: "Maklumat Pembangunan Mampan",
        subtitle: "Sustainable Development Goal (SDG)",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/penyelenggaraan/utiliti",
        title: "Utiliti",
        accessLevel: ["superadmin"],
      },
      {
        href: "/admin/penyelenggaraan/senarai-kod-projek",
        title: "Senarai Kod Projek",
        subtitle: "(sementara)",
        accessLevel: ["superadmin"],
      },
    ],
  },
  {
    href: "/admin/rekod-log-masuk",
    icon: RiFileList3Fill,
    title: "Rekod Log Masuk",
    accessLevel: ["superadmin"],
  },
  {
    href: "/admin/log-aktiviti",
    icon: RiFileList3Fill,
    title: "Log Aktiviti",
    accessLevel: ["superadmin"],
  },
];

const microProjectSection = [
  {
    href: "/projek-mikro/dashboard",
    icon: MdDashboard,
    title: "Dashboard",
    accessLevel: ["superadmin", "projek_mikro_dashboard"],
  },
  {
    href: "/projek-mikro/daftar-projek",
    icon: MdTopic,
    title: "Daftar Permohonan",
    accessLevel: ["superadmin", "projek_mikro_daftar_permohonan"],
  },
  {
    href: "/projek-mikro/senarai-projek",
    icon: FaListUl,
    title: "Senarai Projek",
    accessLevel: ["superadmin", "projek_mikro_senarai"],
  },
  {
    href: "/projek-mikro/status-senarai-projek",
    icon: FaListUl,
    title: "Status Senarai Projek",
    accessLevel: ["superadmin", "projek_mikro_status_senarai"],
  },
  {
    href: "/projek-mikro/jadual-analisis-dalaman",
    icon: MdTableChart,
    title: "Jadual Analisis Dalaman",
    accessLevel: ["superadmin", "projek_mikro_jad"],
  },
];

const maintainenceSection = [
  {
    href: "/penyelenggaraan/skop-projek",
    icon: MdFormatListNumbered,
    title: "Skop Projek",
    accessLevel: ["superadmin", "skop_projek"],
  },
];

const adminProjectMicroSection = [
  {
    href: "/projek-mikro/admin/senarai-pengguna",
    icon: MdOutlinePeopleAlt,
    title: "Senarai Pengguna",
    accessLevel: ["superadmin", "admin_projek_mikro"],
  },
  {
    href: "/projek-mikro/admin/seksyen",
    icon: MdOutlinePeopleAlt,
    title: "Senarai Seksyen",
    accessLevel: ["superadmin", "admin_projek_mikro"],
  },
];

const navItems = [
  {
    title: "DASHBOARD",
    pages: dashboardSection,
    showTo: ["pengumuman", "data_ekonomi", "dashboard", "superadmin"],
  },
  {
    title: "PERMOHONAN",
    pages: registrationSection,
    showTo: [
      "daftar_projek_baharu",
      "daftar_projek_sambungan",
      "daftar_projek_sedia_ada",
      "status_permohonan",
      "perakuan",
      "jppn_projek_baru",
      "jppn_kenaikan_siling",
      "jppn_perubahan_skop",
      "jppn_ubah_tajuk",
      "jppn_ubah_lokasi",
      "jppn_perakuan_jppn",
      "jppn_senarai_status_permohonan",
      "superadmin",
    ],
  },
  {
    title: "PEMANTAUAN",
    pages: monitoringSection,
    showTo: ["laporan_prestasi_projek", "superadmin"],
  },
  {
    title: "BUTIRAN PROJEK",
    pages: projectDetailsSection,
    showTo: ["jadual_analisis_dalaman", "ringkasan_projek", "superadmin"],
  },
  {
    title: "PROJEK MIKRO",
    pages: microProjectSection,
    showTo: [
      "projek_mikro_dashboard",
      "projek_mikro_daftar_permohonan",
      "projek_mikro_senarai",
      "projek_mikro_status_senarai",
      "projek_mikro_jad",
      "superadmin",
    ],
  },
  {
    title: "PENYELENGGARAAN",
    pages: maintainenceSection,
    showTo: ["superadmin", "skop_projek"],
  },
  {
    title: "SISTEM ADMIN",
    pages: adminSection,
    showTo: ["superadmin"],
  },
  {
    title: "SISTEM ADMIN (PROJEK MIKRO)",
    pages: adminProjectMicroSection,
    showTo: ["admin_projek_mikro", "superadmin"],
  },
];

export default navItems;
