import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import avatar from "../../assets/img/avatars/avatar.jpg";
import SabahLogo from "../../assets/img/photos/sabah-logo.png";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title='Log Masuk' />
    <div className='flex-fill w-100 d-flex  flex-column pt-4'>
      <div className='mt-5 w-100 px-5 mb-3'>
        <div className='mb-4 d-flex flex-column align-items-center'>
          <div className='mb-3'>
            <img src={SabahLogo} style={{ height: "auto", width: 95 }} />
          </div>
          <h2 className='mt-3 text-center' style={{ color: "#495057" }}>
            SISTEM MAKLUMAT EKONOMI DAN PEMBANGUNAN PROJEK SABAH (SMEPPS)
          </h2>
        </div>
        <SignIn />
      </div>
      <div className='mt-auto text-center pb-4'>
        <p className='mb-0'>
          &copy; {new Date().getFullYear()}
          <span className='ms-1'>Kerajaan Negeri Sabah</span>
        </p>
      </div>
    </div>
  </React.Fragment>
);

export default SignInPage;
