import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import numeral from "numeral";
import { Badge, Button, Table } from "react-bootstrap";

import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";

import "./styles.css";

import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

import { AG_GRID_LOCALE_MS } from "../../../../locales/locale";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  RowGroupingModule,
  CsvExportModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  SetFilterModule,
]);

const AnalysisTable = ({
  gridRef,
  onGridReady,
  rowData,
  setRowData,
  selectRmk,
  saveColumnState,
}) => {
  const [years, setYears] = useState([]);

  const { projects } = useSelector((state) => ({
    projects: state.analysisTableReducer.projects,
  }));

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "85vh", width: "100%" }), []);

  const colDefs = useMemo(() => {
    return [
      {
        headerName: "#",
        valueGetter: "node.rowIndex + 1",
      },
      {
        colId: "parent_project",
        headerName: "SAMBUNGAN",
        field: "parent_project",
        width: 130,
        suppressSizeToFit: true,
      },
      {
        colId: "rmk",
        headerName: "RMK",
        field: "rmk.number",
        suppressSizeToFit: true,
      },
      {
        colId: "ministry",
        headerName: "KEMENTERIAN",
        enableRowGroup: true,
        field: "ministry.short_form",
        sortable: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
      },
      {
        colId: "department",
        headerName: "JABATAN",
        enableRowGroup: true,
        field: "executing_agency.short_form",
        sortable: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
      },
      {
        colId: "branch",
        headerName: "CAWANGAN",
        enableRowGroup: true,
        field: "branch.name",
        sortable: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
      },
      {
        headerName: "BUTIRAN KEMENTERIAN",
        field: "ministry.code",
        filter: true,
      },
      {
        headerName: "NAMA PROGRAM",
        field: "program.name",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "BUTIRAN PROGRAM",
        field: "program.code",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "NAMA PROJEK UTAMA",
        field: "main_project.name",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "BUTIRAN PROJEK UTAMA",
        field: "main_project.code",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "KOD PROJEK",
        field: "sub_project_full_code",
        width: 350,
        filter: true,
      },
      {
        headerName: "NAMA PROJEK",
        field: "name",
        width: 500,
        filter: true,
      },
      {
        headerName: "KATEGORI PROJEK",
        field: "project_category.code",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "SEKSYEN UPEN",
        field: "upen_section.code",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "SDG",
      },
      {
        headerName: "SEKTOR",
        field: "main_sector.name",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "JENIS PROJEK",
        field: "project_type.name",
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: "KETERANGAN PROJEK",
        field: "description",
        width: 800,
      },
      {
        headerName: "JUSTIFIKASI",
        field: "justification",
        width: 800,
      },
      {
        headerName: "FAEDAH PROJEK",
        // hide: true,
        suppressToolPanel: true,
        field: "benefits",
        width: 800,
      },
      {
        headerName: "KELUASAN TAPAK",
      },
      {
        headerName: "REKA BENTUK",
      },
      {
        headerName: "TARIKH TENDER",
      },
      {
        headerName: "TARIKH MULA PROJEK",
      },
      {
        headerName: "TARIKH SIAP PROJEK",
      },
      {
        headerName: "TEMPOH PELAKSANAAN",
      },
      {
        headerName: "STATUS KEMAJUAN PROJEK",
        field: "current_progress_status.label",
        width: 230,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        cellRenderer: ({ data }) => {
          return (
            <Badge
              bg=''
              style={{ backgroundColor: data?.current_progress_status?.color }}
            >
              {data?.current_progress_status?.label}
            </Badge>
          );
        },
      },
      {
        headerName: "PRESTASI FIZIKAL",
        width: 230,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const getLatestProgress = (node) => {
            const latestReport = node?.data?.reports
              ?.filter((item) => item.is_sent === true)
              ?.sort((a, b) => {
                if (a.month > b.month) return -1;
                if (a.month < b.month) return 1;

                if (a.sent_at > b.sent_at) return -1;
                if (a.sent_at < b.sent_at) return 1;
                return 0;
              });
            return latestReport?.[0]?.progress || 0;
          };

          const rowValueA = getLatestProgress(nodeA);
          const rowValueB = getLatestProgress(nodeB);

          if (rowValueA === rowValueB) return 0;
          return rowValueA > rowValueB ? 1 : -1;
        },
        cellRenderer: ({ data }) => {
          const latestReport = data?.reports
            ?.filter((item) => item.is_sent === true)
            ?.toSorted((a, b) => {
              if (a.month > b.month) return -1;
              if (a.month < b.month) return 1;

              if (a.sent_at > b.sent_at) return -1;
              if (a.sent_at < b.sent_at) return 1;
              return 0;
            });

          return (
            <div className='d-flex justify-content-center'>
              {!isEmpty(latestReport) ? latestReport[0]?.progress : 0}
              <span className='ms-1'>%</span>
            </div>
          );
        },
      },
      {
        headerName: "SKOP PROJEK ASAL",
        width: 500,
        cellRenderer: ({ data }) => {
          return (
            <Table size='sm' bordered className='mb-0 pb-0'>
              <thead className='text-uppercase' style={{ fontSize: 12 }}>
                <tr>
                  <th>Skop Projek</th>
                  <th>Siling (RM)</th>
                </tr>
              </thead>
              <tbody>
                {data?.financial_details?.map((item) => {
                  return (
                    <tr>
                      <td width={300}>{item.cost_description}</td>
                      <td className='text-end'>
                        {numeral(item.ceiling).format("0,0.00")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          );
        },
      },
      {
        headerName: "SKOP PROJEK DISEMAK",
      },
      {
        headerName: "MAKLUMAT VO",
      },
      {
        headerName: "MAKLUMAT EOT",
      },
      {
        headerName: "MAKLUMAT KONTRAKTOR",
      },
      {
        headerName: "AGENSI PEMILIK",
        enableRowGroup: true,
      },
      {
        headerName: "AGENSI PELAKSANA",
        field: "executing_agency.name",
        enableRowGroup: true,
      },
      {
        headerName: "DAERAH",
        enableRowGroup: true,
        cellRenderer: ({ data }) => {
          const site = data?.site_information;
          return (
            <div className='d-flex justify-content-center'>
              {site?.daerah?.name}
            </div>
          );
        },
      },
      {
        headerName: "PARLIMEN",
        enableRowGroup: true,
        cellRenderer: ({ data }) => {
          const site = data?.site_information;
          return (
            <div className='d-flex justify-content-center'>
              {site?.parliament?.name} - {site?.parliament?.code}
            </div>
          );
        },
      },
      {
        headerName: "DUN",
        enableRowGroup: true,
        cellRenderer: ({ data }) => {
          const site = data?.site_information;
          return (
            <div className='d-flex justify-content-center'>
              {site?.state_legislative_assembly?.name} -{" "}
              {site?.state_legislative_assembly?.code}
            </div>
          );
        },
      },
      {
        headerName: "KOORDINAT GIS",
      },
      {
        headerName: "KOS KESELURUHAN",
      },
      {
        headerName: "KOS ASAL",
      },
      {
        headerName: "KOS DISEMAK",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT SILING ASAL ${selectRmk?.label} (RM)`;
        },
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const ceiling = data?.financial_details?.reduce(
            (acc, obj) => (acc += parseFloat(obj.ceiling)),
            0,
          );
          return numeral(ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING ASAL TAHUN ${selectRmk?.first_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 250,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const ceiling = data?.financial_details
            ?.flatMap((obj) => obj.ceiling_flow_details)
            ?.filter((obj) => obj.year === selectRmk.first_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj.amount)), 0);
          return numeral(ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING ASAL TAHUN ${selectRmk?.second_year}`;
        },
        suppressSizeToFit: true,
        width: 250,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const ceiling = data?.financial_details
            ?.flatMap((obj) => obj.ceiling_flow_details)
            ?.filter((obj) => obj.year === selectRmk.second_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj.amount)), 0);
          return numeral(ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING ASAL TAHUN ${selectRmk?.third_year}`;
        },
        suppressSizeToFit: true,
        width: 250,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const ceiling = data?.financial_details
            ?.flatMap((obj) => obj.ceiling_flow_details)
            ?.filter((obj) => obj.year === selectRmk.third_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj.amount)), 0);
          return numeral(ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING ASAL TAHUN ${selectRmk?.fourth_year}`;
        },
        suppressSizeToFit: true,
        width: 250,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const ceiling = data?.financial_details
            ?.flatMap((obj) => obj.ceiling_flow_details)
            ?.filter((obj) => obj.year === selectRmk.fourth_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj.amount)), 0);
          return numeral(ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING ASAL TAHUN ${selectRmk?.fifth_year}`;
        },
        suppressSizeToFit: true,
        width: 250,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const ceiling = data?.financial_details
            ?.flatMap((obj) => obj.ceiling_flow_details)
            ?.filter((obj) => obj.year === selectRmk.fifth_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj.amount)), 0);
          return numeral(ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING DIPINDA TAHUN ${selectRmk?.first_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const revised_ceiling = data?.financial_details
            ?.flatMap((obj) => obj?.ceiling_flow_details)
            ?.filter((obj) => obj?.year === selectRmk?.first_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj?.revised_amount)), 0);

          return numeral(revised_ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING DIPINDA TAHUN ${selectRmk?.second_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const revised_ceiling = data?.financial_details
            ?.flatMap((obj) => obj?.ceiling_flow_details)
            ?.filter((obj) => obj?.year === selectRmk?.second_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj?.revised_amount)), 0);

          return numeral(revised_ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING DIPINDA TAHUN ${selectRmk?.third_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const revised_ceiling = data?.financial_details
            ?.flatMap((obj) => obj?.ceiling_flow_details)
            ?.filter((obj) => obj?.year === selectRmk?.third_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj?.revised_amount)), 0);

          return numeral(revised_ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING DIPINDA TAHUN ${selectRmk?.fourth_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const revised_ceiling = data?.financial_details
            ?.flatMap((obj) => obj?.ceiling_flow_details)
            ?.filter((obj) => obj?.year === selectRmk?.fourth_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj?.revised_amount)), 0);

          return numeral(revised_ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `SILING DIPINDA TAHUN ${selectRmk?.fifth_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const revised_ceiling = data?.financial_details
            ?.flatMap((obj) => obj?.ceiling_flow_details)
            ?.filter((obj) => obj?.year === selectRmk?.fifth_year)
            ?.reduce((acc, obj) => (acc += parseFloat(obj?.revised_amount)), 0);

          return numeral(revised_ceiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN ASAL MOF TAHUN ${selectRmk?.first_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN ASAL MOF TAHUN ${selectRmk?.second_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN ASAL MOF TAHUN ${selectRmk?.third_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN ASAL MOF TAHUN ${selectRmk?.fourth_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const value = data?.s3p_data?.find(
            (item) => item.year === selectRmk?.fourth_year,
          );
          return numeral(value?.state_fund).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN ASAL MOF TAHUN ${selectRmk?.fifth_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT ASP TAHUN ${selectRmk?.first_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT PINDAHAN/VIREMENT (+/-) TAHUN ${selectRmk?.first_year}`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN DISEMAK MOF TAHUN ${selectRmk?.first_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT ASP TAHUN ${selectRmk?.second_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT PINDAHAN/VIREMENT (+/-) TAHUN ${selectRmk?.second_year}`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN DISEMAK MOF TAHUN ${selectRmk?.second_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT ASP TAHUN ${selectRmk?.third_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT PINDAHAN/VIREMENT (+/-) TAHUN ${selectRmk?.third_year}`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN DISEMAK MOF TAHUN ${selectRmk?.third_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT ASP TAHUN ${selectRmk?.fourth_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const aspAmount =
            data?.s3p_data
              ?.find((item) => item.year === selectRmk?.fourth_year)
              ?.financial_activities?.find((item) => item.type === "ASP")
              ?.amount || 0;
          return numeral(aspAmount).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT PINDAHAN/VIREMENT (+/-) TAHUN ${selectRmk?.fourth_year}`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const findYear = data?.s3p_data?.find(
            (item) => item.year === selectRmk?.fourth_year,
          );

          const calculate = (type) => {
            const financialActivities = findYear?.financial_activities
              .filter((item) => item.type === type)
              .reduce((acc, item) => acc + parseFloat(item.amount), 0);
            return financialActivities;
          };

          const virementIn = calculate("VI");
          const virementOut = calculate("VO");

          const virementInText = `(+) ${numeral(virementIn).format("0,0.00")}`;
          const virementOutText = `(-) ${numeral(virementOut).format(
            "0,0.00",
          )}`;

          return `${virementInText} / ${virementOutText}`;
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN DISEMAK MOF TAHUN ${selectRmk?.fourth_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const value = data?.s3p_data?.find(
            (item) => item.year === selectRmk?.fourth_year,
          );
          return numeral(value?.revised_total_allocation).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT ASP TAHUN ${selectRmk?.fifth_year} (RM)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT PINDAHAN/VIREMENT (+/-) TAHUN ${selectRmk?.fifth_year}`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERUNTUKAN DISEMAK MOF TAHUN ${selectRmk?.fifth_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN S3P TAHUN ${selectRmk?.first_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN SEBENAR TAHUN ${selectRmk?.first_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `LAPORAN PERBELANJAAN OLEH AGENSI TAHUN ${selectRmk?.first_year}`;
        },
        suppressSizeToFit: true,
        width: 400,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN S3P TAHUN ${selectRmk?.second_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN SEBENAR TAHUN ${selectRmk?.second_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `LAPORAN PERBELANJAAN OLEH AGENSI TAHUN ${selectRmk?.second_year}`;
        },
        suppressSizeToFit: true,
        width: 400,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN S3P TAHUN ${selectRmk?.third_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN SEBENAR TAHUN ${selectRmk?.third_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `LAPORAN PERBELANJAAN OLEH AGENSI TAHUN ${selectRmk?.third_year}`;
        },
        suppressSizeToFit: true,
        width: 400,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN S3P TAHUN ${selectRmk?.fourth_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const findYear = data?.s3p_data?.find(
            (item) => item.year === selectRmk?.fourth_year,
          );
          const lejer = findYear?.overall_financial_data_by_months.reduce(
            (acc, item) => (acc += parseFloat(item.bendahari)),
            0,
          );
          return numeral(lejer).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN TERKINI TAHUN ${selectRmk?.fourth_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const findYear = data?.s3p_data?.find(
            (item) => item.year === selectRmk?.fourth_year,
          );
          const terkini = findYear?.overall_financial_data_by_months.reduce(
            (acc, item) => (acc += parseFloat(item.terkini)),
            0,
          );
          const lejar = findYear?.overall_financial_data_by_months.reduce(
            (acc, item) => (acc += parseFloat(item.lejar)),
            0,
          );
          return numeral(terkini + lejar).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN SEBENAR TAHUN ${selectRmk?.fourth_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const findYear = data?.s3p_data?.find(
            (item) => item.year === selectRmk?.fourth_year,
          );
          const lejer = findYear?.overall_financial_data_by_months.reduce(
            (acc, item) => (acc += parseFloat(item.lejar)),
            0,
          );
          return numeral(lejer).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `LAPORAN PERBELANJAAN OLEH AGENSI TAHUN ${selectRmk?.fourth_year}`;
        },
        suppressSizeToFit: true,
        width: 400,
        cellClass: "ag-right-aligned-cell",
        // valueGetter: ({ data }) => {
        //   console.log(data);
        //   if (!isEmpty(data.reports)) {
        //     const findYear = data.reports?.filter(
        //       (item) =>
        //         item.year === selectRmk?.fourth_year && item.is_sent === true,
        //     );
        //     const agencyExpenditure =
        //       findYear?.reduce(
        //         (acc, item) =>
        //           (acc += parseFloat(item.total_expenses_report_by_agency)),
        //         0,
        //       ) || 0;
        //     return numeral(agencyExpenditure).format("0,0.00");
        //   } else {
        //     return 0;
        //   }
        // },
        // aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN S3P TAHUN ${selectRmk?.fifth_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const findYear = data?.s3p_data?.find(
            (item) => item.year === selectRmk?.fifth_year,
          );
          const lejer = findYear?.overall_financial_data_by_months.reduce(
            (acc, item) => (acc += parseFloat(item.bendahari)),
            0,
          );
          return numeral(lejer).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `PERBELANJAAN SEBENAR TAHUN ${selectRmk?.fifth_year}`;
        },
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `LAPORAN PERBELANJAAN OLEH AGENSI TAHUN ${selectRmk?.fifth_year}`;
        },
        suppressSizeToFit: true,
        width: 400,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `BAKI SILING TERKINI TAHUN ${selectRmk?.fourth_year} (SELEPAS DITOLAK PEMBAYARAN E-LEJER TERKINI)`;
        },
        suppressSizeToFit: true,
        width: 300,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        headerValueGetter: (params) => {
          return `JPPN (+/-) (RM)`;
        },
        suppressSizeToFit: true,
        width: 200,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          return numeral(0).format("0,0.00");
        },
      },
      {
        headerValueGetter: (params) => {
          return `MAKLUMAT SILING DISEMAK ${selectRmk?.label} (RM)`;
        },
        suppressSizeToFit: true,
        width: 350,
        sortable: true,
        sort: "desc",
        cellClass: "ag-right-aligned-cell",
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const getLatestProgress = (node) => {
            const revisedCeiling = node?.data?.financial_details?.reduce(
              (acc, item) => (acc += parseFloat(item.revised_ceiling || 0)),
              0,
            );

            return revisedCeiling;
          };

          const rowValueA = getLatestProgress(nodeA);
          const rowValueB = getLatestProgress(nodeB);

          if (rowValueA === rowValueB) return 0;
          return rowValueA > rowValueB ? 1 : -1;
        },
        valueGetter: ({ data }) => {
          const revisedCeiling = data?.financial_details?.reduce(
            (acc, item) => (acc += parseFloat(item.revised_ceiling || 0)),
            0,
          );
          return numeral(revisedCeiling).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
    ];
  }, [selectRmk]);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_MS;
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      cellDataType: false,
      minWidth: 100,
      suppressSizeToFit: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      resizable: true,
      cellStyle: {
        lineHeight: "25px",
        wordBreak: "normal",
      },
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      pinned: "left",
    };
  }, []);

  const aggFuncs = useMemo(() => {
    return {
      // this overrides the grids built in sum function
      jumlah: ({ values }) => {
        const total = values
          .map((val) => numeral(val).value())
          .reduce((acc, val) => (acc += parseFloat(val)), 0);
        return numeral(total).format("0,0.00");
      },
      // this adds another function called 'abc'
      // 123: oneTwoThreeFunc,
      // and again xyz
      // xyz: xyzFunc,
    };
  }, []);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      // Example of using the API
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [rowData]);

  return (
    <React.Fragment>
      <div style={containerStyle}>
        <div className='grid-wrapper'>
          <div style={gridStyle} className={"ag-theme-alpine"}>
            <AgGridReact
              // localeText={localeText}
              ref={gridRef}
              rowData={rowData}
              aggFuncs={aggFuncs}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              sideBar={true}
              onGridReady={onGridReady}
              groupTotalRow={"bottom"}
              autoGroupColumnDef={autoGroupColumnDef}
              suppressDragLeaveHidesColumns={true}
              suppressMakeColumnVisibleAfterUnGroup={true}
              suppressRowGroupHidesColumns={true}
              rowGroupPanelShow={"always"}
              groupIncludeFooter={true}
              groupIncludeTotalFooter={true}
              suppressAggFuncInHeader={true}
              onColumnMoved={saveColumnState}
              onColumnVisible={saveColumnState}
              onColumnPinned={saveColumnState}
              onColumnResized={saveColumnState}
              onSortChanged={saveColumnState}
              onColumnRowGroupChanged={saveColumnState}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AnalysisTable;
