import React, { useContext, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FourTeen } from "../../../../../components/common/tableLoading";
import { Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { ms } from "date-fns/locale";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiCheckBold, mdiCloseThick, mdiTrashCan } from "@mdi/js";

// Components
import RejectedCommentModal from "./RejectedCommentModal";
import ModalDelete from "../../../../../components/common/ModalDelete";

// Redux
import {
  userRegistrationResponse,
  removeUser,
} from "../../../../../redux/slices/user-management/userManagementSlice";

const Users = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [showForm, setShowForm] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [data, setData] = useState(null);

  const { user, isLoading, users, btnLoading } = useSelector((state) => ({
    isLoading: state.userManagementReducer.isLoading,
    btnLoading: state.userManagementReducer.btnLoading,
    users: state.userManagementReducer.users,
    user: state.userReducer.user,
  }));

  const handleApproveUser = (user) => {
    const newValues = {
      user_id: user.id,
      response: 1,
    };
    dispatch(userRegistrationResponse(newValues));
  };

  const handleRejectUser = (item) => {
    setData(item);
    setShowForm(true);
  };

  const handleRemoveUser = (item) => {
    setData(item);
    setOpenRemove(true);
  };

  const handleConfirmRemove = async (item) => {
    await dispatch(removeUser(item)).then(async (res) => {
      const { payload } = await res;
      const { name, nric } = await payload;
      const values = {
        name: name,
        nric: nric,
      };
      showNotification(values);
    });
    setOpenRemove(false);
  };

  const showNotification = (values) => {
    // Success
    notyf.open({
      type: "error",
      message: `Pengguna ${values.name}, No. MyKad ${values.nric} telah berjaya dipadam dari sistem`,
      duration: 4000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <React.Fragment>
      <RejectedCommentModal
        showForm={showForm}
        closeForm={() => setShowForm(false)}
        data={data}
      />
      <ModalDelete
        showRemove={openRemove}
        closeRemove={() => {
          setOpenRemove(false);
          setData(null);
        }}
        data={data}
        title='Adakah anda pasti untuk memadam pengguna ini?'
        confirmRemove={handleConfirmRemove}
        message='Data yang dipadam tidak dapat dikembalikan.'
        btnLoading={btnLoading}
      />
      <div className='table-responsive'>
        <Table size='sm' style={{ width: "100" }}>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 11 }}
          >
            <tr>
              <th rowSpan={2} className='text-center' style={{ width: "5%" }}>
                Bil.
              </th>
              <th style={{ width: "35%" }}>Nama</th>
              <th>No. MyKad</th>
              <th>Emel</th>
              <th>Jawatan</th>
              <th>Kementerian</th>
              <th>Jabatan</th>
              <th>Pegawai Daerah</th>
              <th>Didaftarkan Oleh</th>
              <th className='text-center'>Tarikh Daftar</th>
              <th className='text-center'>Status</th>
              <th className='text-center'>Tindakan Oleh</th>
              <th className='text-center'>Tarikh Tindakan</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading ? (
            <FourTeen />
          ) : (
            <tbody>
              {isEmpty(users) ? (
                <tr>
                  <td colSpan={14} className='text-center'>
                    Maklumat tidak tersedia
                  </td>
                </tr>
              ) : (
                <>
                  {users?.map((item, idx) => {
                    let badgeType = {
                      color: "light",
                      label: item?.registration_status,
                      text: "dark",
                    };

                    switch (item?.status) {
                      case 2:
                        badgeType = {
                          ...badgeType,
                          color: "primary",
                          text: "",
                        };
                        break;
                      case 3:
                        badgeType = {
                          ...badgeType,
                          color: "warning",
                          text: "dark",
                        };
                        break;
                      case 4:
                        badgeType = {
                          ...badgeType,
                          color: "success",
                          text: "",
                        };
                        break;
                      case 5:
                        badgeType = {
                          ...badgeType,
                          color: "danger",
                          text: "",
                        };
                        break;
                    }

                    return (
                      <tr key={item?.id}>
                        <td className='text-center'>{idx + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.nric}</td>
                        <td>{item?.email}</td>
                        <td>
                          {!isEmpty(item.position)
                            ? item.position
                            : "Tidak berkaitan"}
                        </td>
                        <td>
                          {item.ministry_id
                            ? item.ministry.name
                            : "Tidak berkaitan"}
                        </td>
                        <td>
                          {item.department_id
                            ? item.department.name
                            : "Tidak berkaitan"}
                        </td>
                        <td>{item.district_id || "Tidak berkaitan"}</td>
                        <td className='text-center'>
                          {item?.registered_by?.name}
                        </td>
                        <td className='text-center text-truncate'>
                          {format(
                            new Date(item?.registered_at),
                            "dd/MM/yyyy, hh:mm a",
                            { locale: ms },
                          )}
                        </td>
                        <td>
                          <div className='d-flex justify-content-center'>
                            <Badge
                              className={`text-uppercase text-wrap container`}
                              bg={badgeType.color}
                              text={badgeType.text}
                            >
                              {badgeType.label}
                            </Badge>
                          </div>
                        </td>
                        <td className='text-center'>
                          {item?.action_by?.name || "-"}
                        </td>
                        <td>-</td>

                        <td>
                          <div className='d-flex justify-content-center gap-2'>
                            <Tooltip title='Lulus' placement='top' arrow>
                              <span>
                                <Button
                                  size='sm'
                                  variant='success'
                                  onClick={() => handleApproveUser(item)}
                                  disabled={item.status === 3 ? false : true}
                                >
                                  <Icon path={mdiCheckBold} size='14px' />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title='Tolak' placement='top' arrow>
                              <span>
                                <Button
                                  size='sm'
                                  variant='danger'
                                  onClick={() => handleRejectUser(item)}
                                  disabled={item.status === 3 ? false : true}
                                >
                                  <Icon path={mdiCloseThick} size='14px' />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title='Padam' placement='top' arrow>
                              <span>
                                <Button
                                  size='sm'
                                  variant='danger'
                                  onClick={() => handleRemoveUser(item)}
                                >
                                  <Icon path={mdiTrashCan} size='14px' />
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          )}
        </Table>
      </div>
    </React.Fragment>
  );
};

export default Users;
