import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { IconButton, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

//Styles
import { customStyles } from "../../../../components/common/customStyleSelect";

// Icons
import { MdSearch } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

const Filters = ({ filterItem, handleSearch, handleShowCanvas }) => {
  const routeParams = useParams();
  const { applicationType } = routeParams;

  const [showRmkFilter, setShowRmkFilter] = useState(false);

  useEffect(() => {
    if (applicationType === "sambungan") {
      setShowRmkFilter(true);
    } else {
      setShowRmkFilter(false);
    }
  }, [applicationType]);

  return (
    <div className='d-flex align-items-center gap-2 mb-3 mt-3'>
      <motion.div variants={filterItem} className='remove-outline-item'>
        <Tooltip title='Lebih Pilihan' placement='top' arrow>
          <Button variant='secondary' onClick={handleShowCanvas}>
            <Icon path={mdiFilterVariant} size={0.8} />
          </Button>
        </Tooltip>
      </motion.div>

      <motion.div variants={filterItem}>
        <div className='search-box-custom'>
          <div className='position-relative'>
            <label htmlFor='search-bar-0' className='search-label-custom'>
              <input
                type='text'
                className='form-control'
                placeholder='Cari...'
                // value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: 280 }}
              />
            </label>
            <MdSearch className='search-icon-2' size={18} />
          </div>
        </div>
      </motion.div>
      {showRmkFilter && (
        <motion.div variants={filterItem}>
          <Form.Group>
            <Select
              placeholder='RMK'
              styles={customStyles}
              className='react-select-container'
              classNamePrefix='react-select'
              options={[
                {
                  id: 1,
                  label: "SEMUA",
                  value: "",
                },
                {
                  id: 2,
                  label: "RMK 12",
                  value: "RMK 12",
                },
                {
                  id: 3,
                  label: "RMK 11",
                  value: "RMK 11",
                },
              ]}
            />
          </Form.Group>
        </motion.div>
      )}
    </div>
  );
};

export default Filters;
