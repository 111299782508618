import { StyleSheet, Font } from "@react-pdf/renderer";
import TimesNewRoman from "./Times-New-Roman.ttf";

// Register font
Font.register({ family: "Times-New-Roman", src: TimesNewRoman });

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 70,
    paddingHorizontal: 65,
  },
  confidential: {
    fontSize: 14,
    textTransform: "uppercase",
    marginBottom: "20px",
    fontFamily: "Times-New-Roman",
    paddingLeft: 10,
  },
  confidentialBottom: {
    position: "absolute",
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 14,
    bottom: 30,
    left: "100%",
    right: 0,
    textAlign: "center",
    fontFamily: "Times-New-Roman",
  },
  headerSection: {
    marginBottom: "20px",
  },
  header: {
    fontSize: 14,
    textAlign: "center",
    lineHeight: 1.5,
    fontFamily: "Times-New-Roman",
    fontWeight: 500,
    flexWrap: "nowrap",
  },
  mainTitleSection: {
    marginBottom: "20px",
    textOverflow: "ellipsis",
  },
  mainTitle: {
    fontSize: 14,
    textAlign: "center",
    textTransform: "uppercase",
    lineHeight: 1.5,
    fontFamily: "Times-New-Roman",
    fontWeight: 500,
  },
  titleSection: {
    display: "flex",
    flexWrap: "nowrap",
  },
  indexing: {
    flex: 1,
    fontSize: 14,
    textTransform: "uppercase",
    fontFamily: "Times-New-Roman",
    fontWeight: 500,
  },
  title: {
    fontSize: 14,
    textTransform: "uppercase",
    fontFamily: "Times-New-Roman",
    fontWeight: 500,
    paddingLeft: "39px",
  },
  contentSection: {
    flexDirection: "row",
    fontSize: 14,
    fontFamily: "Times-New-Roman",
    paddingTop: 15,
    paddingLeft: 39,
    paddingBottom: 30,
  },
  contentSubSection: {
    flexDirection: "row",
    fontSize: 14,
    fontFamily: "Times-New-Roman",
    paddingLeft: 69,
    paddingBottom: 30,
  },
  contentIndexing: {
    fontFamily: "Times-New-Roman",
  },
  text: {
    textAlign: "justify",
    paddingLeft: 25,
    lineHeight: 1.5,
    fontFamily: "Times-New-Roman",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 14,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    fontFamily: "Times-New-Roman",
  },
  rmkWord: {
    textTransform: "uppercase",
    textOverflow: "ellipsis",
  },
  tableContainer: {
    marginTop: 15,
    fontSize: 14,
    borderColor: "#000000",
    fontFamily: "Times-New-Roman",
    paddingBottom: 30,
  },
  fontBold: {
    fontSize: 14,
    fontFamily: "Times-New-Roman",
    fontWeight: 700,
    marginHorizontal: 10,
  },
});

export { styles };
