import React, { useState, useContext, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";
import { isEmpty } from "lodash";
import { useDropzone } from "react-dropzone";
import {
  CardMedia,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from "@mui/material";

// Styles
import NotyfContext from "../../../../../contexts/NotyfContext";
import usePalette from "../../../../../hooks/usePalette";

// Components
import ImplementationSchedule from "../tables/ImplementationSchedule";

// Icons
import { MdCheckCircle } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiInformation, mdiTrashCan, mdiFile } from "@mdi/js";
import PDF from "../../../../../assets/img/icons/pdf.svg";

// Redux
import { updatePhysicalDetails } from "../../../../../redux/slices/projects/physicalDetailsSlice";
import {
  storeAttachment,
  deleteAttachment,
  previewProjectDesigns,
} from "../../../../../redux/slices/projects/projectSlice";

const ProjectScopeForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  projectId,
  enableBtnSave,
}) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const palette = usePalette();

  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [designValue, setDesignValue] = useState("");
  const [implementationPeriod, setImplementationPeriod] = useState({
    total: "",
    type: "",
  });

  const [files, setFiles] = useState([]);
  const [showBtn, setShowBtn] = useState(null);

  const { project } = useSelector((state) => ({
    project: state.projectReducer.project,
  }));

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      proposed_land_in_accordance_with_local_plans:
        !isEmpty(project?.physical_details) &&
        project.physical_details.proposed_land_in_accordance_with_local_plans,
      proposed_land_discussed_with_upen:
        !isEmpty(project?.physical_details) &&
        project.physical_details.proposed_land_discussed_with_upen,
      proposed_utility:
        !isEmpty(project?.physical_details) &&
        project?.physical_details.proposed_utility,
      expected_tender_date:
        (!isEmpty(project?.physical_details?.expected_tender_date) &&
          format(
            new Date(project?.physical_details?.expected_tender_date),
            "yyyy-MM-dd",
          )) ||
        "",
      expected_start_date:
        (!isEmpty(project?.physical_details?.expected_start_date) &&
          format(
            new Date(project?.physical_details?.expected_start_date),
            "yyyy-MM-dd",
          )) ||
        "",
      expected_completion_date:
        (!isEmpty(project?.physical_details?.expected_completion_date) &&
          format(
            new Date(project?.physical_details?.expected_completion_date),
            "yyyy-MM-dd",
          )) ||
        "",
      site_width:
        (!isEmpty(project?.physical_details?.expected_completion_date) &&
          project?.physical_details.site_width) ||
        "",
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        id: project.physical_details.id,
      };
      await dispatch(updatePhysicalDetails(newValues));
      handleNotification("success");
      handleChecked(3);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    try {
      acceptedFiles.forEach((acceptedFile) => {
        const newValues = {
          project_id: projectId,
          collection_type: "project_design",
          file: acceptedFile,
        };
        dispatch(storeAttachment(newValues)).then(({ payload }) => {
          const { data } = payload;
          setFiles((prev) => [...prev, data]);
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxFiles: 5,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const handleMouseEnter = (idx) => {
    setShowBtn(idx);
  };

  const handleViewAttachment = (item) => {
    console.log(item);
  };

  const handleRemoveAttachment = async (item) => {
    const { id } = item;
    await dispatch(deleteAttachment(id)).then(({ payload }) => {
      const id = payload;
      setFiles(files.filter((item) => item.id !== id));
    });
    setShowBtn(null);
  };

  const handleChangeRadio = (value) => {
    setDesignValue(value);
    if (value === 3) {
      setShowUploadBtn(true);
    } else {
      setShowUploadBtn(false);
    }

    formik.setFieldValue("project_design_status", value);
  };

  // Notification
  const handleNotification = (type, values) => {
    // Errors
    if (type === "danger") {
      const errors = Object.values(values);

      errors.map((item) => {
        const strMsg = item;

        notyf.open({
          type: type,
          message: strMsg,
          duration: 7000,
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "top",
          },
        });
      });
      return false;
    }

    // Success
    notyf.open({
      type: type,
      message: "Maklumat skop projek berjaya disimpan.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    if (
      !isEmpty(formik.values.expected_completion_date) &&
      !isEmpty(formik.values.expected_tender_date)
    ) {
      const result = formatDistanceStrict(
        new Date(formik.values.expected_tender_date),
        new Date(formik.values.expected_completion_date),
        {
          addSuffix: false,
          locale: ms,
        },
      );
      const splitText = result.split(" ");
      const upperCaseFirstLetter =
        splitText[1].charAt(0).toLocaleUpperCase() + splitText[1].slice(1);
      setImplementationPeriod({
        total: splitText[0],
        type: upperCaseFirstLetter,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [
    formik.values.expected_tender_date,
    formik.values.expected_completion_date,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    if (isEmpty(project)) return;
    if (isEmpty(projectId)) return;

    handleChangeRadio(project.physical_details?.project_design_status);
    dispatch(previewProjectDesigns(projectId)).then(({ payload }) =>
      setFiles(payload),
    );

    return () => {
      setFiles([]);
      isMounted = false;
    };
  }, [project]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(3)}
      >
        {(passedSteps || []).includes(3) ? (
          <MdCheckCircle className='me-2' size={29} color={palette.success} />
        ) : (
          <span className='number'>3.</span>
        )}
        Maklumat Skop Projek
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Body className='m-0'>
            <Card.Text className='text-sm'>
              Sila isi maklumat skop projek.
            </Card.Text>
            <ImplementationSchedule enableBtnSave={enableBtnSave} />
            <Form
              noValidate
              autoComplete='off'
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Reka Bentuk Projek *
                  <div>
                    <Form.Text className='text-muted fst-italic'>
                      Sila pilih salah satu
                    </Form.Text>
                  </div>
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    <Form.Check
                      inline
                      type='radio'
                      id='not_needed'
                      name='project_design_status'
                      label='Tidak Diperlukan'
                      className='mb-2'
                      value={1}
                      checked={designValue === 1}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleChangeRadio(value);
                      }}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='not_ready'
                      name='project_design_status'
                      label='Belum Disediakan'
                      className='mb-2'
                      value={2}
                      checked={designValue === 2}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleChangeRadio(value);
                      }}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='ready'
                      name='project_design_status'
                      label='Telah Disediakan'
                      className='mb-2'
                      value={3}
                      checked={designValue === 3}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleChangeRadio(value);
                      }}
                    />
                  </div>
                </Col>
              </Form.Group>
              {showUploadBtn && (
                <Form.Group as={Row} className='mb-3 align-items-center'>
                  <Col sm={3} className='text-sm'>
                    <Form.Label className='m-0'>
                      Muat Naik Reka Bentuk Projek
                    </Form.Label>
                    <Form.Text as='div' className='m-0 fst-italic'>
                      Sila muat naik reka bentuk yang telah disediakan
                    </Form.Text>
                  </Col>
                  <Col sm={9}>
                    <div className='d-flex align-items-center'>
                      <Button
                        className='me-3'
                        variant='secondary'
                        onClick={open}
                        style={{ fontSize: 11 }}
                      >
                        MUAT NAIK
                      </Button>
                      <div>
                        <ImageList className='mb-0' cols={5}>
                          {files.map((item, idx) => {
                            return (
                              <ImageListItem
                                key={idx}
                                className='position-relative'
                                onMouseEnter={() => handleMouseEnter(idx)}
                                onMouseLeave={() => setShowBtn(null)}
                              >
                                <img
                                  className={`${
                                    idx === showBtn ? "opacity-25" : ""
                                  } pb-b mb-1`}
                                  src={PDF}
                                  alt='Fail tidak ditemui'
                                  loading='lazy'
                                  style={{ height: 60, objectFit: "fill" }}
                                />
                                {idx === showBtn && (
                                  <Tooltip title='Lihat' arrow>
                                    <IconButton
                                      className='position-absolute'
                                      size='small'
                                      sx={{ right: "50%", top: "20%" }}
                                      color='primary'
                                      onClick={() => handleViewAttachment(item)}
                                    >
                                      <Icon path={mdiFile} size={1} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {idx === showBtn && (
                                  <Tooltip title='Padam' arrow>
                                    <IconButton
                                      className='position-absolute'
                                      size='small'
                                      sx={{ right: "15%", top: "20%" }}
                                      color='error'
                                      onClick={() =>
                                        handleRemoveAttachment(item)
                                      }
                                    >
                                      <Icon path={mdiTrashCan} size={1} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <ImageListItemBar
                                  className={`${
                                    item.id === showBtn ? "opacity-25" : ""
                                  } text-center`}
                                  subtitle={item.file_name}
                                  position='below'
                                  sx={{ width: "6rem" }}
                                />
                              </ImageListItem>
                            );
                          })}
                        </ImageList>
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tarikh Projek Dijangka Tender
                </Form.Label>
                <Col sm={9}>
                  <div className=''>
                    <Form.Control
                      name='expected_tender_date'
                      type='date'
                      style={{ width: 200 }}
                      value={formik.values.expected_tender_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tarikh Projek Dijangka Mula
                </Form.Label>
                <Col sm={9}>
                  <div className=''>
                    <Form.Control
                      name='expected_start_date'
                      type='date'
                      style={{ width: 200 }}
                      value={formik.values.expected_start_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tarikh Projek Dijangka Siap
                </Form.Label>
                <Col sm={9}>
                  <div className=''>
                    <Form.Control
                      name='expected_completion_date'
                      type='date'
                      style={{ width: 200 }}
                      value={formik.values.expected_completion_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Tempoh Perlaksanaan
                </Form.Label>
                <Col sm={9}>
                  <InputGroup className='' style={{ width: 200 }}>
                    <Form.Control
                      type='number'
                      aria-describedby='month'
                      min={0}
                      name='implementation_period'
                      value={implementationPeriod.total}
                      style={{ fontWeight: 500 }}
                      disabled
                    />
                    <InputGroup.Text id='month'>
                      {implementationPeriod.type}
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Keluasan Tapak
                </Form.Label>
                <Col sm={9}>
                  <InputGroup className='' style={{ width: 200 }}>
                    <Form.Control
                      name='site_width'
                      type='text'
                      min={0}
                      value={formik.values.site_width}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {/* <InputGroup.Text className='p-0' id='month'>
                      <Form.Select
                        className='border-0'
                        style={{ backgroundColor: "transparent" }}
                      >
                        <option value="EKAR">aaa</option>
                        <option value="KAKI PERSEGI">aaa</option>
                        <option>aaa</option>
                      </Form.Select>
                    </InputGroup.Text> */}
                  </InputGroup>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm'>
                  Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    <Form.Check
                      inline
                      type='radio'
                      id='sync_yes'
                      name='proposed_land_in_accordance_with_local_plans'
                      label='Ya'
                      className='mb-2'
                      value={true}
                      checked={
                        formik.values
                          .proposed_land_in_accordance_with_local_plans === true
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          "proposed_land_in_accordance_with_local_plans",
                          JSON.parse(e.target.value),
                        );
                      }}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='sync_no'
                      name='proposed_land_in_accordance_with_local_plans'
                      label='Tidak'
                      className='mb-2'
                      value={false}
                      checked={
                        formik.values
                          .proposed_land_in_accordance_with_local_plans ===
                        false
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          "proposed_land_in_accordance_with_local_plans",
                          JSON.parse(e.target.value),
                        );
                      }}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className='mb-3 d-flex align-items-center'
                controlId='radio1'
              >
                <Form.Label column sm={3} className='text-sm'>
                  Cadangan Berkaitan Utiliti
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    <Form.Check
                      inline
                      type='radio'
                      id='utility_yes'
                      name='proposed_utility'
                      label='Ya'
                      className='mb-2'
                      value={true}
                      checked={formik.values.proposed_utility === true}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "proposed_utility",
                          JSON.parse(e.target.value),
                        )
                      }
                    />
                    <Form.Check
                      inline
                      type='radio'
                      id='utility_no'
                      name='proposed_utility'
                      label='Tidak'
                      className='mb-2'
                      value={false}
                      checked={formik.values.proposed_utility === false}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "proposed_utility",
                          JSON.parse(e.target.value),
                        )
                      }
                    />
                  </div>
                </Col>
              </Form.Group>

              {enableBtnSave && (
                <div className='d-flex justify-content-end'>
                  <Button
                    type='submit'
                    variant='primary'
                    // disabled={(passedSteps || []).includes(2) ? false : true}
                  >
                    Simpan
                  </Button>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectScopeForm;
