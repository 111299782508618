import React from "react";
import { Badge, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import usePalette from "../../../../hooks/usePalette";
import { IconButton } from "@mui/material";

// icons
import Icon from "@mdi/react";
import { mdiPencil, mdiDotsHorizontal } from "@mdi/js";
import { isEmpty } from "lodash";

const MainProjects = ({ openForm }) => {
  const palette = usePalette();

  const { mainProjects } = useSelector((state) => ({
    mainProjects: state.utilsReducer.dropdownItems.mainProjects,
  }));

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table>
          <thead>
            <tr>
              <th className='text-center'>Bil.</th>
              <th>Nama Projek Utama</th>
              <th>Pegawai</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {isEmpty(mainProjects) ? (
              <tr>
                <td colSpan={4} className='text-center'>
                  Tiada maklumat tersedia
                </td>
              </tr>
            ) : (
              <>
                {mainProjects?.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td className='text-center'>{idx + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        {item?.officers?.slice(0, 7)?.map((officer) => (
                          <Badge key={officer?.id} className='me-1'>
                            {officer?.name}
                          </Badge>
                        ))}
                        {item?.officers?.length === 8 && (
                          <Icon
                            path={mdiDotsHorizontal}
                            color={palette.primary}
                            size={1}
                          />
                        )}
                      </td>
                      <td>
                        <div className='d-flex justify-content-center gap-2'>
                          <IconButton
                            size='small'
                            onClick={() => openForm(item)}
                          >
                            <Icon
                              path={mdiPencil}
                              color={palette.primary}
                              size='18px'
                            />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default MainProjects;
