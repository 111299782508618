import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "../../../../utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

// Icons
import Icon from "@mdi/react";
import { mdiCircleMedium } from "@mdi/js";

import SabahLogo from "../../../../assets/img/photos/sabah-logo.png";

const MainPage = () => {
  const routeParams = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [maskNric, setMaskNric] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      last_four_number: "",
    },
    validationSchema: Yup.object().shape({
      last_four_number: Yup.string()
        .max(4, "maksimum nombor ialah 4 angka")
        .min(4, "minimum nombor ialah 4 angka")
        .required("4 digit terakhir nombor MyKad diperlukan"),
    }),
    onSubmit: async (values) => {
      try {
        const newValues = {
          last_four_number: values.last_four_number,
        };
        const response = await axios.post(
          `/user-acknowledge-email/${routeParams.code}`,
          newValues,
        );

        const data = await response.data;

        if (data.status === 200) {
          navigate(`/pengesahan-pengguna/berjaya`);
        } else {
          formik.setErrors({
            last_four_number:
              "pengesahan 4 nombor terakhir salah, sila semak semula",
          });
        }
      } catch (error) {}
    },
  });

  useEffect(() => {
    const initilize = async () => {
      const uniqueCode = routeParams.code;

      try {
        const response = await axios.get(
          `/acknowledge-email/get-user-info/${uniqueCode}`,
        );

        const data = await response.data;

        setUser(data);

        const maskedNumber = `${data.nric.substring(0, 3)}${"*".repeat(
          data.nric.length - 7,
        )}`;

        setMaskNric(maskedNumber);
      } catch (error) {
        console.error(error);
      }
    };

    initilize();
  }, []);

  return (
    <React.Fragment>
      <Container
        fluid
        className='p-0 h-100 d-flex justify-content-center align-items-center'
      >
        <div className='' style={{ width: "45%" }}>
          <div className='d-flex justify-content-center mb-3'>
            <img
              src={SabahLogo}
              alt='tiada gambar'
              style={{ height: 120, width: "auto" }}
            />
          </div>
          <div className='d-flex justify-content-center mb-3'>
            <h3 className='text-uppercase fw-bolder'>
              Sistem Maklumat Ekonomi dan Pembangunan Projek
            </h3>
          </div>
          <Card>
            <Card.Body>
              <div className='mb-3'>
                Sila pastikan maklumat anda betul dan isi
                <span className='mx-1 fw-bolder'>
                  4 nombor terakhir MyKad anda
                </span>
                untuk membuat pengesahan
              </div>
              <Table size='sm' style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }}>Nama</td>
                    <td>{user?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Emel</td>
                    <td>{user?.email}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Kementerian</td>
                    <td>
                      {user?.ministry?.name || (
                        <span className='fst-italic'>Tidak berkaitan</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Jabatan</td>
                    <td>
                      {user?.department?.name || (
                        <span className='fst-italic'>Tidak berkaitan</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Pegawai Daerah</td>
                    <td>
                      {user?.district?.name || (
                        <span className='fst-italic'>Tidak berkaitan</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();

                  return;
                }}
              >
                <Form.Label htmlFor='basic-url'>
                  Masukan 4 nombor terakhir MyKad anda.
                </Form.Label>
                <InputGroup className='mb-3'>
                  <InputGroup.Text id='4-digits'>{maskNric}</InputGroup.Text>
                  <Form.Control
                    name='last_four_number'
                    id='last-four-digits'
                    aria-describedby='4-digits'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_four_number}
                    isInvalid={
                      formik.touched.last_four_number &&
                      formik.errors.last_four_number
                        ? true
                        : false
                    }
                    maxLength={4}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.last_four_number}
                  </Form.Control.Feedback>
                </InputGroup>
                <div className='d-flex justify-content-center'>
                  <Button type='submit'>Hantar</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <div className='position-fixed' style={{ bottom: 30, right: "50%" }}>
            <p className='mb-1'>
              Pautan Unit Perancang Ekonomi Negeri Sabah: link
            </p>
            <p className='mb-1'>Nombor Pejabat:</p>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
