import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import numeral from "numeral";

const Reports = () => {
  const [financialReports, setFinancialReports] = useState([]);
  const [totalExpenditure, setTotalExpenditure] = useState({
    agency: 0,
    bendahari: 0,
    committed: 0,
  });

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  useEffect(() => {
    if (!isEmpty(project)) {
      const { reports, s3p_data } = project;
      const currentYear = new Date().getFullYear();

      const expensesReportByMinistryCurrentYear = reports?.filter(
        (item) => item.year === currentYear,
      );

      const groupDataByMonth = () => {
        return expensesReportByMinistryCurrentYear.reduce((acc, curr) => {
          const month = curr.month;
          if (!acc[month]) {
            acc[month] = [];
          }
          acc[month].push(curr);
          return acc;
        }, {});
      };

      const groupedData = groupDataByMonth();

      const sumExpensesByMonth = () => {
        const totals = new Array(12).fill(0);

        Object.keys(groupedData).forEach((month) => {
          let total = 0;

          groupedData[month].forEach((item) => {
            // Parse the expense as integer before adding to total
            const expense = parseFloat(
              item.total_expenses_report_by_agency || 0,
            );

            total += expense;
          });

          totals[parseInt(month) - 1] = total;
        });

        return totals;
      };

      const expensesByMonth = sumExpensesByMonth();

      const s3pReportCurrentYear = s3p_data?.find(
        (item) => item.year === currentYear || null,
      );

      const terkini = s3pReportCurrentYear?.monthly_info
        ?.toSorted((a, b) => a.month - b.month)
        .map((item) => parseFloat(item?.terkini) || 0);

      const bendahari = s3pReportCurrentYear?.monthly_info
        ?.toSorted((a, b) => a.month - b.month)
        .map((item) => parseFloat(item.bendahari));

      const lejar = s3pReportCurrentYear?.monthly_info
        ?.toSorted((a, b) => a.month - b.month)
        .map((item) => parseFloat(item.lejar));

      const combinedData = [];

      for (let i = 0; i < expensesByMonth.length; i++) {
        const month = (i + 1).toString().padStart(2, "0");
        combinedData.push({
          month: month,
          latestExpenditure: terkini ? terkini[i] + lejar[i] : 0,
          reportByBendahari: bendahari ? bendahari[i] : 0,
          committed: lejar ? lejar[i] : 0,
          reportByAgency: expensesByMonth ? expensesByMonth[i] : 0,
        });
      }

      setFinancialReports(combinedData);

      const totalAgency =
        expensesByMonth?.reduce((acc, item) => (acc += parseFloat(item)), 0) ||
        0;
      const totalBendahari =
        bendahari?.reduce((acc, item) => (acc += parseFloat(item)), 0) || 0;
      const totalComitted =
        lejar?.reduce((acc, item) => (acc += parseFloat(item)), 0) || 0;
      const totalLatestExpenditure =
        terkini?.reduce((acc, item) => (acc += parseFloat(item)), 0) || 0;

      setTotalExpenditure({
        latestExpenditure: totalLatestExpenditure,
        bendahari: totalBendahari,
        committed: totalComitted,
        agency: totalAgency,
      });
    }
  }, [project]);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm' bordered>
          <thead
            className='custom-table-head text-uppercase text-center'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th>Bulan</th>
              <th>
                Perbelanjaan Terkini<div>(RM)</div>
              </th>
              <th>
                Perbelanjaan S3P<div>(RM)</div>
              </th>
              <th>
                Perbelanjaan Sebenar<div>(RM)</div>
              </th>
              <th>
                Laporan Perbelanjaan dari Agensi
                <div>(RM)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isEmpty(financialReports) ? (
              <tr>
                <td colSpan={5} className='text-center'>
                  Maklumat tidak tersedia.
                </td>
              </tr>
            ) : (
              <>
                {financialReports?.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td className='text-center'>{item.month}</td>
                      <td className='text-end'>
                        {numeral(item.latestExpenditure).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(item.reportByBendahari).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(item.committed).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(item.reportByAgency).format("0,0.00")}
                      </td>
                    </tr>
                  );
                })}
                <tr style={{ backgroundColor: "#eceff1" }}>
                  <td className='text-end text-uppercase'>Jumlah</td>
                  <td className='text-end'>
                    {numeral(totalExpenditure.latestExpenditure).format(
                      "0,0.00",
                    )}
                  </td>
                  <td className='text-end'>
                    {numeral(totalExpenditure.bendahari).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(totalExpenditure.committed).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(totalExpenditure.agency).format("0,0.00")}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default Reports;
