import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const ExecutingAgency = () => {
  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  return (
    <React.Fragment>
      <Card className='flex-fill w-100'>
        <Card.Header className='pb-0'>
          <h5 className='text-uppercase'>Agensi Pelaksana</h5>
        </Card.Header>
        <Card.Body className='d-flex pt-0 justify-content-center align-items-center'>
          <div className=''>
            <p
              className='text-center text-uppercase mb-0'
              style={{ fontSize: 17, fontWeight: 700 }}
            >
              {project?.executing_agency?.value}
            </p>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ExecutingAgency;
