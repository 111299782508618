import React, { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Settings as Sets, User } from "react-feather";

import avatar from "../../assets/img/avatars/default-avatar.jpeg";

import Settings from "../Settings";
import { AuthContext } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";

const NavbarUser = () => {
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);

  const user = useSelector((state) => state.userReducer.user);

  const handleSignOut = async () => {
    await signOut();
  };

  const handleUserProfile = () => {
    navigate(`/profil/${user.id}`);
  };

  const handleSettings = () => {
    setIsOpen(true);
  };

  const handleChangePassword = () => {
    navigate("/ubah-kata-laluan");
  };
  return (
    <React.Fragment>
      <Settings isOpen={isOpen} toggleClose={() => setIsOpen(false)} />

      <Dropdown className='nav-item' align='end'>
        <span className='d-inline-block d-sm-none'>
          <Dropdown.Toggle as='a' className='nav-link'>
            <Settings size={18} className='align-middle' />
          </Dropdown.Toggle>
        </span>
        <span className='d-none d-sm-inline-block'>
          <Dropdown.Toggle as='a' className='nav-link'>
            {/* <img
              src={avatar}
              className='avatar img-fluid rounded-circle me-2'
              alt=''
            /> */}
            <span className='text-dark me-1'>{user?.name}</span>
          </Dropdown.Toggle>
        </span>
        <Dropdown.Menu drop='end'>
          <Dropdown.Item onClick={handleUserProfile}>
            <User size={18} className='align-middle me-2' />
            Profil Pengguna
          </Dropdown.Item>
          {/* <Dropdown.Item onClick={handleChangePassword}>
            <span style={{ marginLeft: "1.65rem" }}>Ubah Kata Laluan</span>
          </Dropdown.Item> */}
          <Dropdown.Item onClick={handleSettings}>
            <Sets size={18} className='align-middle me-2' />
            Tetapan
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleSignOut}>Log Keluar</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NavbarUser;
