import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

//Styles
import { customStyles } from "../../../../../../components/common/customStyleSelect";

// Icons
import { MdSearch } from "react-icons/md";

// Redux
import {
  setKeywords,
  setPage,
} from "../../../../../../redux/slices/dashboard/dashboardSlice";

const Filters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [keyword, setKeyword] = useState("");

  const keywords = useSelector(
    (state) => state.dashboardReducer.overall.projects.keywords,
  );

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      const newParams = { ...params, keyword };
      const newSearch = queryString.stringify(newParams);

      // Update the URL
      navigate(`${location.pathname}?${newSearch}`, { replace: true });
      dispatch(setKeywords(keyword));
      dispatch(setPage(1));
    }
  };

  useEffect(() => {
    setKeyword(params.keyword || "");
  }, [location.search]);

  return (
    <div className='d-flex align-items-center gap-2'>
      <div className='search-box-custom'>
        <div className='position-relative'>
          <label htmlFor='search-bar-0' className='search-label-custom'>
            <input
              type='text'
              className='form-control'
              placeholder='Cari nama projek...'
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={onKeyDown}
              style={{ width: 280 }}
            />
          </label>
          <MdSearch className='search-icon-2' size={18} />
        </div>
      </div>
    </div>
  );
};

export default Filters;
