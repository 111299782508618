import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Redux
import {
  getLastCode,
  createProgram,
  updateProgram,
} from "../../../../../redux/slices/rmk-information/programsSlice";

const ProgramForm = ({
  showForm,
  closeForm,
  isEdit,
  rmkList,
  btnLoading,
  data,
  handleNotification,
  programs,
}) => {
  const dispatch = useDispatch();

  const [rmkOptions, setRmkOptions] = useState([]);
  const [rmkValue, setRmkValue] = useState("");
  const [ministryValue, setMinistryValue] = useState(null);
  const [showSelectMinistry, setShowSelectMinistry] = useState(false);
  const [lastCode, setLastCode] = useState("");

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { ministries, departments, lastCodeState } = useSelector((state) => ({
    ministries: state.utilsReducer.dropdownItems.ministries,
    departments: state.utilsReducer.dropdownItems.departments,
    lastCodeState: state.programReducer.lastCode,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rmk_id: rmkValue?.number,
      code: data.code || "",
      name: data.name || "",
      description: data.description || "",
      ministry: ministryValue || null,
      department: data.department || null,
    },
    validationSchema: Yup.object().shape({
      rmk_id: Yup.number().required("Sila isi rmk"),
      name: Yup.string().required("Sila isi nama program"),
      code: Yup.string()
        .required("Sila isi kod program")
        .min(6, "Kod tidak boleh kurang daripada 6 aksara")
        .max(6, "Kod tidak boleh lebih dari 6 aksara"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          rmk_id: values.rmk_id,
          code: values.code,
          name: values.name,
          description: values.description,
          ministry_id: Number(values.ministry?.code) || null,
          department_id: Number(values.department?.id) || null,
        };
        await dispatch(updateProgram(newValues));
        handleNotification("edited", newValues);
      } else {
        try {
          const newValues = {
            rmk_id: values.rmk_id,
            prefix_type: "D",
            code: values.code,
            name: values.name,
            description: values.description,
            ministry_id: Number(values.ministry?.code) || null,
            department_id: Number(values.department?.id) || null,
          };
          await dispatch(createProgram(newValues));
          handleNotification("created", newValues);
        } catch (error) {
          console.log(error);
        }
      }
      handleCloseModal();
    },
  });

  const handleCloseModal = () => {
    closeForm();
    setRmkValue("");
    setShowSelectMinistry(false);
    setMinistryValue(null);
    setLastCode("");
    formik.resetForm();
  };

  const handleSelectMinistry = async (value) => {
    formik.setFieldValue("ministry", value);
    if (isEmpty(value)) {
      setDepartmentOptions([]);
      return;
    }

    const newOptions = departments.filter(
      (dept) => dept.ministry_code.toString() === value.code.toString(),
    );

    setDepartmentOptions(newOptions);

    if (!isEdit) {
      const params = {
        ministry_id: Number(value.code),
      };

      await dispatch(getLastCode(params));
    }
  };

  const handleSelectDepartment = (value) => {
    formik.setFieldValue("department", value);
  };

  useEffect(() => {
    if (!isEmpty(formik.values.ministry) && !isEmpty(lastCodeState)) {
      const suggestedCode = String(parseInt(lastCodeState, 10) + 100).padStart(
        lastCode.length,
        "0",
      );
      formik.setFieldValue("code", suggestedCode);
    }
  }, [formik.values.ministry, lastCodeState]);

  useEffect(() => {
    if (!isEmpty(rmkList)) {
      const options = rmkList.map((rmk) => {
        const newValues = {
          ...rmk,
          label: rmk.short_form,
          value: rmk.short_form,
        };

        return newValues;
      });

      setRmkOptions(options.toSorted((a, b) => b.first_year - a.first_year));
    }
  }, [rmkList]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const rmkValue = {
        ...data.rmk,
        label: data.rmk?.short_form,
        value: data.rmk?.short_form,
      };
      setRmkValue(rmkValue);
      formik.setFieldValue("rmk_id", rmkValue?.id);

      const ministryValue = {
        ...data.ministry,
        label: data.ministry?.name,
        value: data.ministry?.name,
      };
      setMinistryValue(ministryValue);
    }
  }, [data]);

  useEffect(() => {
    if (isEmpty(programs)) return;

    const sortedData = programs.toSorted((a, b) => a.code - b.code);

    // Extracting the last code from the sorted data
    const lastCode =
      sortedData.length > 0 ? sortedData[sortedData.length - 1].code : null;
    const number = Number(lastCode) + 100;

    const numString = number.toString();
    const zerosToAdd = Math.max(0, 6 - numString.length);
    const fullCode = "0".repeat(zerosToAdd) + numString;
    setLastCode(fullCode);
  }, [programs, showForm]);

  return (
    <Modal
      show={showForm}
      onHide={handleCloseModal}
      centered
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Program</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoCorrect='off'
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Rancangan Malaysia Ke (RMK)</Form.Label>
            <Select
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              styles={customStylesForm}
              options={rmkOptions}
              isClearable
              value={rmkValue}
              onChange={(value) => {
                formik.setFieldValue("rmk_id", value?.id);
                setRmkValue(value);
              }}
            />
            {formik.errors.rmk_id && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.rmk_id}
              </p>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kementerian</Form.Label>
            <Select
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              isClearable
              styles={customStylesForm}
              options={ministries}
              value={formik.values.ministry}
              onChange={(value) => handleSelectMinistry(value)}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Jabatan / Agensi (Jika berkaitan)</Form.Label>
            <Select
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              styles={customStylesForm}
              options={
                !isEmpty(departmentOptions) ? departmentOptions : departments
              }
              isClearable
              value={formik.values.department}
              onChange={(value) => handleSelectDepartment(value)}
              noOptionsMessage={() => "Tiada Piihan"}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kod Program</Form.Label>
            <Form.Control
              type='text'
              name='code'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              isInvalid={
                formik.touched.code && formik.errors.code ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.code}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Nama Program</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='name'
              style={{ minHeight: 75, maxHeight: 105 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              isInvalid={
                formik.touched.name && formik.errors.name ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Keterangan Program</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='description'
              style={{ minHeight: 75, maxHeight: 105 }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleCloseModal}>
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProgramForm;
