import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getProjectScopesChangesApplications = createAsyncThunk(
  "jppnProjectScopesChangesSlice/getProjectScopesChangesApplications",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/project-scopes-changes`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createProjectScopesChangesApplication = createAsyncThunk(
  "jppnProjectScopesChangesSlice/createProjectScopesChangesApplication",
  async (values) => {
    try {
      const response = await axios.post(`jppn/project-scopes-changes`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProjectScopesChangesApplication = createAsyncThunk(
  "jppnProjectScopesChangesSlice/getProjectScopesChangesApplication",
  async (params) => {
    try {
      const { id, source } = params;
      const response = await axios.get(`jppn/project-scopes-changes/${id}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const updateProjectScopesChangesApplication = createAsyncThunk(
  "jppnProjectScopesChangesSlice/updateProjectScopesChangesApplication",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(
        `jppn/project-scopes-changes/${id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const deleteProjectScopesApplication = createAsyncThunk(
  "jppnProjectScopesChangesSlice/deleteProjectScopesApplication",
  async (id) => {
    try {
      const response = await axios.delete(`jppn/project-scopes-changes/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const approveEditScopesApplication = createAsyncThunk(
  "jppnProjectScopesChangesSlice/approveEditScopesApplication",
  async (id) => {
    try {
      const response = await axios.put(
        `jppn/project-scopes-changes/approve/${id}`,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const cancelProjectScopesApplication = createAsyncThunk(
  "jppnProjectScopesChangesSlice/cancelProjectScopesApplication",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(
        `jppn/project-scopes-changes/cancel/${id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
