import React from "react";
import { Button, Modal, Table } from "react-bootstrap";

const SectionUpenGuide = ({ show, handleClose }) => {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop='static'
        keyboard={false}
      >
        <Modal.Body>
          <h5>
            Panduan untuk memilih seksyen UPEN bagi projek yang hendak
            didaftarkan.
          </h5>

          <Table size='sm' striped></Table>

          <div className='d-flex justify-content-end '>
            <Button variant='light' onClick={handleClose}>
              TUTUP
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SectionUpenGuide;
