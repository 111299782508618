import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

// Redux
import { updateCode } from "../../../../../redux/slices/others/projectCodeSlice";

const EditForm = ({ showForm, hideForm, data }) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);

  const btnLoading = useSelector(
    (state) => state.projectCodeReducer.btnLoading,
  );

  const handleHideForm = () => {
    hideForm();
  };

  const handleChangeCode = (e, id) => {
    const value = e.target.value;

    const updateItems = projects.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          code: value,
        };
      }
      return item;
    });

    setProjects(updateItems);
  };

  const handleSubmit = async () => {
    await dispatch(updateCode(projects));
    handleHideForm();
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      setProjects(data.projects);
    }
  }, [data]);

  return (
    <Modal
      show={showForm}
      onHide={handleHideForm}
      centered
      size='lg'
      keyboard={false}
      backdrop='static'
    >
      <Modal.Body>
        <div className=''>{`${data?.code}`}</div>
        <div className='mb-3'>{`${data?.name}`}</div>
        <Table bordered style={{ width: "100%" }}>
          <tbody>
            {projects.map((project) => {
              return (
                <tr key={project.id}>
                  <td className='p-2' style={{ width: "20%" }}>
                    <Form.Group className='p-0 m-0'>
                      <Form.Control
                        type='text'
                        value={project.code}
                        onChange={(e) => handleChangeCode(e, project.id)}
                      />
                    </Form.Group>
                  </td>
                  <td>{project.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className='d-flex justify-content-end gap-2'>
          <Button
            variant='light'
            onClick={handleHideForm}
            disabled={btnLoading}
          >
            Batal
          </Button>
          <Button
            variant='success'
            onClick={handleSubmit}
            disabled={btnLoading}
          >
            Simpan
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditForm;
