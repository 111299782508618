import React from "react";
import { Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { isEmpty } from "lodash";

const MainSectors = ({ mainSectorList, handleEditItem, handleRemoveItem }) => {
  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead>
            <tr>
              <th className='text-center'>Bil.</th>
              <th>Sektor Utama Negeri</th>
              <th>Keterangan Sektor Utama Negeri</th>
              <th>Senarai Halatuju Strategi Mengikut Sektor Utama Negeri</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {isEmpty(mainSectorList) ? (
              <tr>
                <td colSpan={5} className='text-center'>
                  Tiada Maklumat Tersedia
                </td>
              </tr>
            ) : (
              <>
                {mainSectorList.map((row, idx) => (
                  <tr key={row.id}>
                    <td className='text-center'>{idx + 1}.</td>
                    <td>{row.name}</td>
                    <td>{row.description}</td>
                    <td>
                      <ul>
                        {row.strategic_directions?.map((item) => (
                          <li key={item.id}>{item.name}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Tooltip title='Edit' placement='top' arrow>
                          <IconButton
                            color='info'
                            size='small'
                            aria-label='Edit'
                            onClick={() => handleEditItem(row)}
                          >
                            <MdModeEditOutline fontSize='large' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Padam' placement='top' arrow>
                          <IconButton
                            color='error'
                            size='small'
                            aria-label='Padam'
                            onClick={() => handleRemoveItem(row)}
                          >
                            <MdDelete fontSize='large' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default MainSectors;
