import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

//Styles
import { customStyles } from "../../../../components/common/customStyleSelect";

// Icons
import { MdFilterList, MdSearch } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";
import Icon from "@mdi/react";
import { mdiFilterVariant, mdiMagnify } from "@mdi/js";

// Redux
import { setKeywords } from "../../../../redux/slices/projects/projectsSlice";

const Filters = ({ handleShowCanvas, handleSearch }) => {
  const dispatch = useDispatch();

  const keywords = useSelector((state) => state.projectReducer.keywords);

  // const handleSearch = debounce((text) => {
  //   dispatch(setKeywords(text));
  // }, 900);

  return (
    <div className='d-flex align-items-center gap-2 mb-3 mt-3'>
      <div className='remove-outline-item'>
        <Tooltip title='Lebih Pilihan' placement='top' arrow>
          <Button variant='secondary' onClick={handleShowCanvas}>
            <Icon path={mdiFilterVariant} size={0.8} />
          </Button>
        </Tooltip>
      </div>
      <div className='search-box-custom'>
        <div className='position-relative'>
          <label htmlFor='search-bar-0' className='search-label-custom'>
            <input
              type='text'
              className='form-control'
              placeholder='Cari nama sub projek'
              value={keywords}
              // onChange={(e) => handleSearch(e.target.value)}
              onChange={(e) => dispatch(setKeywords(e.target.value))}
              style={{ width: 280 }}
            />
          </label>
          <MdSearch className='search-icon-2' size={18} />
        </div>
      </div>
      <Button variant='secondary' onClick={handleSearch}>
        <Icon path={mdiMagnify} size='20px' />
      </Button>
    </div>
  );
};

export default Filters;
