import React from "react";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";
import { isEmpty } from "lodash";
import { Button, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import numeral from "numeral";

// Icons
import Icon from "@mdi/react";
import { MdAttachment, MdMap } from "react-icons/md";

const ProjectProfile = ({ showProjectProfile, closeProjectProfile }) => {
  const colSpan = 5;
  const { project } = useSelector((state) => ({
    project: state.certifyReducer.project.data,
  }));

  return (
    <React.Fragment>
      <Modal
        show={showProjectProfile}
        onHide={closeProjectProfile}
        centered
        keyboard={false}
        backdrop='static'
        size='lg'
      >
        <Modal.Body>
          <h5 className='text-center mb-1 mt-2'>
            {`Keterangan Mengenai Maklumat Projek`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-1'>
            {`${project.rmk?.name} : ${project.rmk?.first_year}-${project?.rmk?.fifth_year}`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-3'>
            {"(Pembiayaan Kerajaan Negeri)".toLocaleUpperCase()}
          </h5>
          <Table bordered size='sm' style={{ fontSize: 12 }}>
            <tbody>
              {/* Project Profile */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='text-center' style={{ width: "5%" }}>
                  Bil.
                </td>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Profil
                </th>
                <th className='border-start-0' colSpan={colSpan}></th>
              </tr>
              <tr>
                <td className='text-center'>1</td>
                <td className='fw-bold'>Kementerian</td>
                <td colSpan={colSpan}>{project?.ministry?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>2</td>
                <td className='fw-bold'>Jabatan</td>
                <td colSpan={colSpan}>{project?.department?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>3</td>
                <td className='fw-bold'>Program</td>
                <td colSpan={colSpan}>{project?.program?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>4</td>
                <td className='fw-bold'>Projek Utama</td>
                <td colSpan={colSpan}>{project?.main_project?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>5</td>
                <td className='fw-bold'>Nama Projek</td>
                <td colSpan={colSpan}>{project?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>6</td>
                <td className='fw-bold'>Keterangan Projek</td>
                <td colSpan={colSpan}>{project?.description}</td>
              </tr>
              <tr>
                <td className='text-center'>6</td>
                <td className='fw-bold'>Justifikasi Projek</td>
                <td colSpan={colSpan}>{project?.justification}</td>
              </tr>
              <tr>
                <td className='text-center'>9</td>
                <td className='fw-bold'>KPI Program dan Projek</td>
                <td colSpan={colSpan}>{project?.key_performance_indicator}</td>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  11
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  Matlamat Pembangunan Mampan
                  <p className='mb-0 text-sm fst-italic'>
                    Sustainable Development Goal (SDG)
                  </p>
                </td>
              </tr>
              {/* Sustainable Development Goal */}
              {project?.strategic_directions?.map((item, idx) => {
                const lastIndex = project?.strategic_directions.length - 1;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      lastIndex === idx ? "" : "border-bottom-0"
                    } border-top-0`}
                  >
                    <td className='ps-0' colSpan={colSpan}></td>
                  </tr>
                );
              })}

              <tr>
                <td className='text-center'>10</td>
                <td className='fw-bold'>Sektor Utama</td>
                <td colSpan={colSpan}>{project?.main_sector?.name}</td>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  11
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  Halatuju Strategi
                </td>
              </tr>
              {/* Strategic directions */}
              {project?.strategic_directions?.map((item, idx) => {
                const lastIndex = project?.strategic_directions.length - 1;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      lastIndex === idx ? "" : "border-bottom-0"
                    } border-top-0`}
                  >
                    <td className='ps-0' colSpan={colSpan}>
                      <ul className='mb-0 pe-0 ps-4'>
                        <li>{item.data?.name}</li>
                      </ul>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className='text-center'>12</td>
                <td className='fw-bold'>Kategori Projek</td>
                <td colSpan={colSpan}>
                  {`${project?.project_category?.value} (${project?.project_category?.code})`}
                </td>
              </tr>
              <tr>
                <td className='text-center'>13</td>
                <td className='fw-bold'>Jenis Projek</td>
                <td colSpan={colSpan}>{project?.project_type?.name}</td>
              </tr>

              <tr>
                <td className='text-center'>16</td>
                <td className='fw-bold'>Sumber Pembiayaan</td>
                <td colSpan={colSpan}>{project?.funding_sources}</td>
              </tr>

              {/* Physical Information  */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Fizikal
                </th>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.financial_details?.length + 1 || 0}
                >
                  17
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.financial_details?.length + 1 || 0}
                >
                  Skop Projek
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Skop
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Mula (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Siap (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tempoh
                </td>
              </tr>
              {project?.financial_details?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={2}>{item.cost_description}</td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_start_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_completion_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {item.project_scope_details?.expected_completion_date &&
                      formatDistanceStrict(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        {
                          addSuffix: false,
                          locale: ms,
                        },
                      )}
                  </td>
                </tr>
              ))}

              <tr>
                <td className='text-center'>18</td>
                <td className='fw-bold'>Tarikh Tender (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_tender_date) &&
                    format(
                      new Date(project.physical_details?.expected_tender_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>19</td>
                <td className='fw-bold'>Tarikh Mula Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_start_date) &&
                    format(
                      new Date(project.physical_details?.expected_start_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>20</td>
                <td className='fw-bold'>Tarikh Siap Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(
                    project.physical_details?.expected_completion_date,
                  ) &&
                    format(
                      new Date(
                        project.physical_details?.expected_completion_date,
                      ),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>21</td>
                <td className='fw-bold'>Keluasan Tapak</td>
                <td colSpan={colSpan}>
                  {numeral(project.physical_details?.site_width).format(
                    "0,0.00",
                  )}{" "}
                  ekar
                </td>
              </tr>

              <tr>
                <td className='text-center'>22</td>
                <td className='fw-bold'>
                  Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
                </td>
                <td colSpan={colSpan}>
                  {project.physical_details
                    ?.proposed_land_in_accordance_with_local_plans
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>

              <tr>
                <td className='text-center'>23</td>
                <td className='fw-bold'>Cadangan Berkaitan Utiliti</td>
                <td colSpan={colSpan}>
                  {project.physical_details?.proposed_utility ? "Ya" : "Tidak"}
                </td>
              </tr>

              <tr>
                <td className='text-center'>24</td>
                <td className='fw-bold'>
                  Cadangan kegunaan tanah telah dibincang ke Jawatankuasa
                  Perancang Negeri
                </td>
                <td colSpan={colSpan}>
                  {project.physical_details?.proposed_land_discussed_with_upen
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>

              {/* Others */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Lain
                </th>
              </tr>

              <tr>
                <td className='text-center' rowSpan={4}>
                  25
                </td>
                <td className='fw-bold' rowSpan={4}>
                  Matriks Rangka Kerja Logik
                </td>
              </tr>

              <tr>
                <td colSpan={colSpan}>
                  <h5>Petunjuk Utama Prestasi</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.key_performance_indicators)} */}
                </td>
              </tr>
              <tr>
                <td colSpan={colSpan}>
                  <h5>Kaedah Pengesahan</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.validation_method)} */}
                </td>
              </tr>
              <tr>
                <td colSpan={colSpan}>
                  <h5>Andaian</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.assumption)} */}
                </td>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={
                    project?.human_resources_requirements?.length + 1 || 0
                  }
                >
                  26
                </td>
                <td
                  className='fw-bold'
                  rowSpan={
                    project?.human_resources_requirements?.length + 1 || 0
                  }
                >
                  Keperluan Sumber Manusia
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Bilangan Staff
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Nama Jawatan
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Gred Jawatan
                </td>
              </tr>
              {project?.human_resources_requirements?.map((item) => (
                <tr key={item.id}>
                  <td className='text-center'>{item.number_of_staff}</td>
                  <td colSpan={2}>{item.position_name}</td>
                  <td className='text-center' colSpan={2}>
                    {item.position_gred}
                  </td>
                </tr>
              ))}
              <tr>
                <td className='text-center'>27</td>
                <td className='fw-bold'>Seksyen UPEN</td>
                <td colSpan={colSpan}>{project?.upen_section?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>28</td>
                <td className='fw-bold'>Agensi Permohonan</td>
                <td colSpan={colSpan}>{project?.applicant_agency?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>29</td>
                <td className='fw-bold'>Agensi Pemilik</td>
                <td colSpan={colSpan}>{project?.owner_agency?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>30</td>
                <td className='fw-bold'>Agensi Bertanggungjawab (Kewangan)</td>
                <td colSpan={colSpan}>
                  {project?.financially_responsible_agency?.value}
                </td>
              </tr>
              <tr>
                <td className='text-center'>31</td>
                <td className='fw-bold'>Agensi Pelaksana</td>
                <td colSpan={colSpan}>{project?.executing_agency?.value}</td>
              </tr>
            </tbody>
          </Table>
          <div className='d-flex justify-content-end'>
            <Button variant='light' onClick={closeProjectProfile}>
              Tutup
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ProjectProfile;
