import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getFinancialDetails = createAsyncThunk(
  "financialDetails/getFinancialDetails",
  async () => {
    try {
      const response = await axios.get(`/financial-details`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getComponent = createAsyncThunk(
  "financialDetails/getComponent",
  async (id) => {
    try {
      const response = await axios.get(`/financial-details/${id}`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createFinancialDetails = createAsyncThunk(
  "financialDetails/createFinancialDetails",
  async (values) => {
    try {
      const response = await axios.post(`/financial-details`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateFinancialDetails = createAsyncThunk(
  "financialDetails/updateFinancialDetails",
  async (values) => {
    const { id } = values;

    try {
      const response = await axios.put(`/financial-details/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeFinancialDetails = createAsyncThunk(
  "financialDetails/removeFinancialDetails",
  async (id) => {
    try {
      const response = await axios.delete(`/financial-details/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

// Financial Detail Notes
export const createFinancialDetailNote = createAsyncThunk(
  "financialDetails/createFinancialDetailNote",
  async (values) => {
    try {
      const response = await axios.post(`/financial-detail-notes`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateFinancialDetailNote = createAsyncThunk(
  "financialDetails/updateFinancialDetailNote",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/financial-detail-notes/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeFinancialDetailNote = createAsyncThunk(
  "financialDetails/removeFinancialDetailNote",
  async (values) => {
    try {
      const response = await axios.delete(
        `/financial-detail-notes/${values.id}`,
      );

      return values;
    } catch (error) {
      console.log(error);
    }
  },
);

// Ceiling Flow CRUD
export const createCeilingFlow = createAsyncThunk(
  "financialDetails/createCeilingFlow",
  async (values) => {
    try {
      const response = await axios.post(`/ceiling-flow-details`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateCeilingFlow = createAsyncThunk(
  "financialDetails/updateCeilingFlow",
  async (values) => {
    try {
      const { id } = values;

      const response = await axios.put(`/ceiling-flow-details/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeCeilingFlow = createAsyncThunk(
  "financialDetails/removeCeilingFlow",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.delete(`/ceiling-flow-details/${id}`);

      const data = await response.data;

      return values;
    } catch (error) {
      console.log(error);
    }
  },
);

// Ceiling Flow Note
export const createCeilingFlowNote = createAsyncThunk(
  "ceilingFlow/createNote",
  async (values) => {
    try {
      const content = {
        ceiling_flow_detail_id: values.ceiling_flow_detail_id,
        note: values.note,
        write_by: values.write_by,
      };

      const response = await axios.post(`/ceiling-flow-detail-notes`, content);

      const data = await response.data;

      const newValues = {
        ...data,
        financial_detail_id: values.financial_detail_id,
      };

      return newValues;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateCeilingFlowNote = createAsyncThunk(
  "ceilingFlow/updateNote",
  async (values) => {
    try {
      const { id, financial_detail_id, ...rest } = values;
      const response = await axios.put(
        `/ceiling-flow-detail-notes/${id}`,
        rest,
      );

      const data = await response.data;

      return {
        ...data,
        financial_detail_id: financial_detail_id,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeCeilingFlowNote = createAsyncThunk(
  "ceilingFlow/removeNote",
  async (values) => {
    try {
      const response = await axios.delete(
        `/ceiling-flow-detail-notes/${values.id}`,
      );

      return values;
    } catch (error) {
      console.log(error);
    }
  },
);
