import React, { useEffect, useState } from "react";
import {
  Card,
  Accordion,
  Table,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import usePalette from "../../../../../hooks/usePalette";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { format, intervalToDuration, formatDuration } from "date-fns";
import { Link } from "react-router-dom";
import { ms } from "date-fns/locale";
import ReactDatePicker from "react-datepicker";

// Icons
import Icon from "@mdi/react";
import { mdiPencil, mdiCheckBold, mdiCloseThick } from "@mdi/js";
import { MdAttachment } from "react-icons/md";

// redux
import {
  updatePhysicalDetails,
  updateProjectScopeDetails,
} from "../../../../../redux/slices/projects/physicalDetailsSlice";

const ProjectScope = ({ project, isAdminLevel, showNotification }) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [isEdit, setIsEdit] = useState({
    site_width: false,
    utility_related: false,
    accordance_with_local_plans: false,
    expected_tender_date: false,
    expected_start_date: false,
    expected_completion_date: false,
  });
  const [projectScope, setProjectScope] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [implementationPeriod, setImplementationPeriod] = useState("-");

  const { financial_details, physical_details, target_groups } = project;

  const { btnLoading, user } = useSelector((state) => ({
    user: state.userReducer.user,
    btnLoading: state.projectReducer.btnLoading,
  }));

  const monthOptions = [
    "Januari",
    "Februari",
    "Mac",
    "April",
    "Mei",
    "Jun",
    "Julai",
    "Ogos",
    "September",
    "Oktober",
    "November",
    "Disember",
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      site_width: project?.physical_details?.site_width || "",
      utility_related: project?.physical_details?.utility_related,
      accordance_with_local_plans:
        project?.physical_details?.accordance_with_local_plans,
      expected_tender_date: isEmpty(
        project?.physical_details?.expected_tender_date,
      )
        ? null
        : new Date(project?.physical_details?.expected_tender_date),
      expected_start_date: isEmpty(
        project?.physical_details?.expected_start_date,
      )
        ? null
        : new Date(project?.physical_details?.expected_start_date),
      expected_completion_date: isEmpty(
        project?.physical_details?.expected_completion_date,
      )
        ? null
        : new Date(project?.physical_details?.expected_completion_date),
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        id: project?.physical_details?.id,
        expected_tender_date: isNull(values.expected_tender_date)
          ? null
          : format(new Date(values.expected_tender_date), "yyyy-MM-dd"),
        expected_start_date: isNull(values.expected_start_date)
          ? null
          : format(new Date(values.expected_start_date), "yyyy-MM-dd"),
        expected_completion_date: isNull(values.expected_completion_date)
          ? null
          : format(new Date(values.expected_completion_date), "yyyy-MM-dd"),
      };

      await dispatch(updatePhysicalDetails(newValues));

      const hideInput = async (field) => {
        await new Promise((resolve) => {
          setIsEdit((prevIsEdit) => ({
            ...prevIsEdit,
            [field]: false,
          }));
          resolve();
        });
      };

      const keys = Object.keys(values);

      const hideAllInputs = async () => {
        for (const key of keys) {
          await hideInput(key);
        }
      };

      hideAllInputs();
      showNotification();
    },
  });

  const handleEditField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: true,
    });
  };

  const handleCloseField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: false,
    });
  };

  const handleSaveForm = () => {
    formik.handleSubmit();
  };

  const handleOpenDesign = () => {
    console.log(project);
  };

  const handleEditProjectScope = (item) => {
    const isNullTrue = isEmpty(item.project_scope_details);
    let values = null;
    if (isNullTrue) {
      values = {
        ...item,
        project_scope_details: {
          financial_detail_id: item.id,
          expected_start_date: null,
          expected_completion_date: null,
          actual_start_date: null,
          actual_completion_date: null,
        },
      };
    } else {
      values = item;
    }
    setProjectScope((prev) => [...prev, values]);
  };

  const handleCancelEdit = (item) => {
    const removeId = projectScope.filter((obj) => obj.id !== item.id);
    setProjectScope(removeId);
  };

  const handleSaveProjectScopeRow = async (item) => {
    const newValues = projectScope.find(
      (obj) => obj.id === item.id,
    ).project_scope_details;

    await dispatch(updateProjectScopeDetails(newValues));

    const removeId = projectScope.filter((obj) => obj.id !== item.id);
    setProjectScope(removeId);
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      setYearOptions(Array.from({ length: 60 }, (_, index) => 1991 + index));

      const expectedStartDate = project?.physical_details?.expected_start_date;
      const expectedCompletionDate =
        project?.physical_details?.expected_completion_date;

      const interval = intervalToDuration({
        start: expectedStartDate ? new Date(expectedStartDate) : new Date(),
        end: expectedCompletionDate
          ? new Date(expectedCompletionDate)
          : new Date(),
      });
      let duration = formatDuration(interval, {
        format: ["years", "months", "days"],
        locale: ms,
      });

      setImplementationPeriod(duration);
    }
  }, [project]);

  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>SKOP PROJEK</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <Card className='mb-0'>
          <Card.Body>
            <div className='px-3 py-2 mb-3 table-responsive'>
              <Table size='sm' striped className='m-0 project-overview'>
                <tbody>
                  <tr>
                    <th>Keluasan Tapak</th>
                    <td>
                      {isEdit.site_width ? (
                        <>
                          <InputGroup size='sm'>
                            <Form.Control
                              type='text'
                              name='site_width'
                              value={formik.values.site_width}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <Button
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() => handleCloseField("site_width")}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </InputGroup>
                        </>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {project?.physical_details?.site_width || "-"}

                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah Keluasan Tapak'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() =>
                                      handleEditField("site_width")
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Reka Bentuk</th>
                    <td>
                      Telah Disediakan
                      <Tooltip
                        arrow
                        title='Lihat Reka Bentuk'
                        placement='right'
                      >
                        <span>
                          <Link to='#' onClick={() => handleOpenDesign()}>
                            <MdAttachment
                              className='ms-3'
                              size={24}
                              color='#42a5f5'
                            />
                          </Link>
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
                    </th>
                    <td>
                      {isEdit.accordance_with_local_plans ? (
                        <InputGroup className='d-flex justify-content-between'>
                          <div className='custom-controls-stacked'>
                            <Form.Check
                              inline
                              type='radio'
                              id='sync_yes'
                              name='accordance_with_local_plans'
                              label='Ya'
                              value={true}
                              checked={
                                formik.values.accordance_with_local_plans ===
                                true
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "accordance_with_local_plans",
                                  JSON.parse(e.target.value),
                                );
                              }}
                            />
                            <Form.Check
                              inline
                              type='radio'
                              id='sync_no'
                              name='accordance_with_local_plans'
                              label='Tidak'
                              value={false}
                              checked={
                                formik.values.accordance_with_local_plans ===
                                false
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "accordance_with_local_plans",
                                  JSON.parse(e.target.value),
                                );
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              size='sm'
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              size='sm'
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("accordance_with_local_plans")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </div>
                        </InputGroup>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {project?.physical_details
                            ?.accordance_with_local_plans
                            ? "Ya"
                            : "Tidak"}
                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah Cdangan Kegunaan Tanah'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() =>
                                      handleEditField(
                                        "accordance_with_local_plans",
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Cadangan Berkaitan Utiliti</th>
                    <td>
                      {isEdit.utility_related ? (
                        <InputGroup className='d-flex justify-content-between'>
                          <div className='custom-controls-stacked'>
                            <Form.Check
                              inline
                              type='radio'
                              id='sync_yes'
                              name='utility_related'
                              label='Ya'
                              value={true}
                              checked={formik.values.utility_related === true}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "utility_related",
                                  JSON.parse(e.target.value),
                                );
                              }}
                            />
                            <Form.Check
                              inline
                              type='radio'
                              id='sync_no'
                              name='utility_related'
                              label='Tidak'
                              value={false}
                              checked={formik.values.utility_related === false}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "utility_related",
                                  JSON.parse(e.target.value),
                                );
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              size='sm'
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              size='sm'
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("utility_related")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </div>
                        </InputGroup>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {project?.physical_details?.utility_related
                            ? "Ya"
                            : "Tidak"}
                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah cadangan berkaitan utiliti'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() =>
                                      handleEditField("utility_related")
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Tarikh Tender (Jangkaan)</th>
                    <td>
                      {isEdit.expected_tender_date ? (
                        <InputGroup className='d-flex justify-content-between align-items-center'>
                          <Form.Control
                            as={ReactDatePicker}
                            portalId='root-portal'
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div className='d-flex justify-content-center mx-1'>
                                <Button
                                  size='sm'
                                  className='me-2'
                                  variant='secondary'
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  {"<"}
                                </Button>

                                <Form.Select
                                  style={{ width: 120 }}
                                  value={
                                    monthOptions[new Date(date).getMonth()]
                                  }
                                  onChange={({ target: { value } }) =>
                                    changeMonth(monthOptions.indexOf(value))
                                  }
                                >
                                  {monthOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Form.Select
                                  style={{ width: 80 }}
                                  className='me-2'
                                  value={new Date(date).getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {yearOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Button
                                  size='sm'
                                  className='ms-2'
                                  variant='secondary'
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  {">"}
                                </Button>
                              </div>
                            )}
                            selected={formik.values.expected_tender_date}
                            onChange={(date) => {
                              formik.setFieldValue(
                                "expected_tender_date",
                                date,
                              );
                            }}
                            placeholderText='Pilih Tarikh'
                            dateFormat='dd/MM/yyyy'
                          />
                          <div>
                            <Button
                              size='sm'
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              size='sm'
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("expected_tender_date")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </div>
                        </InputGroup>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {isEmpty(
                            project?.physical_details?.expected_tender_date,
                          )
                            ? "-"
                            : format(
                                new Date(
                                  project?.physical_details?.expected_tender_date,
                                ),
                                "dd/MM/yyyy",
                              )}
                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah tarikh tender (jangkaan)'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() =>
                                      handleEditField("expected_tender_date")
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Tarikh Mula Projek (Jangkaan)</th>
                    <td>
                      {isEdit.expected_start_date ? (
                        <InputGroup className='d-flex justify-content-between align-items-center'>
                          <Form.Control
                            as={ReactDatePicker}
                            portalId='root-portal'
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div className='d-flex justify-content-center mx-1'>
                                <Button
                                  size='sm'
                                  className='me-2'
                                  variant='secondary'
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  {"<"}
                                </Button>

                                <Form.Select
                                  style={{ width: 120 }}
                                  value={
                                    monthOptions[new Date(date).getMonth()]
                                  }
                                  onChange={({ target: { value } }) =>
                                    changeMonth(monthOptions.indexOf(value))
                                  }
                                >
                                  {monthOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Form.Select
                                  style={{ width: 80 }}
                                  className='me-2'
                                  value={new Date(date).getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {yearOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Button
                                  size='sm'
                                  className='ms-2'
                                  variant='secondary'
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  {">"}
                                </Button>
                              </div>
                            )}
                            selected={formik.values.expected_start_date}
                            onChange={(date) => {
                              formik.setFieldValue("expected_start_date", date);
                            }}
                            placeholderText='Pilih Tarikh'
                            dateFormat='dd/MM/yyyy'
                          />
                          <div>
                            <Button
                              size='sm'
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              size='sm'
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("expected_start_date")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </div>
                        </InputGroup>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {isEmpty(
                            project?.physical_details?.expected_start_date,
                          )
                            ? "-"
                            : format(
                                new Date(
                                  project?.physical_details?.expected_start_date,
                                ),
                                "dd/MM/yyyy",
                              )}
                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah tarikh mula (jangkaan)'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() =>
                                      handleEditField("expected_start_date")
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Tarikh Siap Projek (Jangkaan)</th>
                    <td>
                      {isEdit.expected_completion_date ? (
                        <InputGroup className='d-flex justify-content-between align-items-center'>
                          <Form.Control
                            as={ReactDatePicker}
                            portalId='root-portal'
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div className='d-flex justify-content-center mx-1'>
                                <Button
                                  size='sm'
                                  className='me-2'
                                  variant='secondary'
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  {"<"}
                                </Button>

                                <Form.Select
                                  style={{ width: 120 }}
                                  value={
                                    monthOptions[new Date(date).getMonth()]
                                  }
                                  onChange={({ target: { value } }) =>
                                    changeMonth(monthOptions.indexOf(value))
                                  }
                                >
                                  {monthOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Form.Select
                                  style={{ width: 80 }}
                                  className='me-2'
                                  value={new Date(date).getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {yearOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Button
                                  size='sm'
                                  className='ms-2'
                                  variant='secondary'
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  {">"}
                                </Button>
                              </div>
                            )}
                            selected={formik.values.expected_completion_date}
                            onChange={(date) => {
                              formik.setFieldValue(
                                "expected_completion_date",
                                date,
                              );
                            }}
                            placeholderText='Pilih Tarikh'
                            dateFormat='dd/MM/yyyy'
                          />
                          <div>
                            <Button
                              size='sm'
                              className='mx-1'
                              variant='success'
                              onClick={() => handleSaveForm()}
                              disabled={btnLoading}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                            <Button
                              size='sm'
                              variant='secondary'
                              onClick={() =>
                                handleCloseField("expected_completion_date")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </div>
                        </InputGroup>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {isEmpty(
                            project?.physical_details?.expected_completion_date,
                          )
                            ? "-"
                            : format(
                                new Date(
                                  project?.physical_details?.expected_completion_date,
                                ),
                                "dd/MM/yyyy",
                              )}
                          {isAdminLevel && (
                            <>
                              <Tooltip
                                title='Ubah tarikh siap (jangkaan)'
                                placement='left'
                                arrow
                              >
                                <span>
                                  <Icon
                                    path={mdiPencil}
                                    size={0.7}
                                    color={palette.primary}
                                    onClick={() =>
                                      handleEditField(
                                        "expected_completion_date",
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Tempoh Pelaksanaan Projek</th>
                    <td>{implementationPeriod}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className='px-3 py-2 mb-3 table-responsive'>
              <Table size='sm' striped className='m-0 project-overview'>
                <thead>
                  <tr>
                    <th className='text-center' style={{ width: "5%" }}>
                      Bil.
                    </th>
                    <th>Skop Projek</th>
                    <th className='text-center'>Tarikh Jangka Mula</th>
                    <th className='text-center'>Tarikh Jangka Siap</th>
                    <th>Tempoh</th>
                    <th className='text-center'>Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  {project?.financial_details?.map((item, idx) => {
                    const expectedStartDate =
                      item.project_scope_details?.expected_start_date;
                    const expectedCompletionDate =
                      item.project_scope_details?.expected_completion_date;

                    const interval = intervalToDuration({
                      start: expectedStartDate
                        ? new Date(expectedStartDate)
                        : new Date(),
                      end: expectedCompletionDate
                        ? new Date(expectedCompletionDate)
                        : new Date(),
                    });
                    let duration = formatDuration(interval, {
                      format: ["years", "months", "days"],
                      locale: ms,
                    });

                    const editTrue = projectScope.some(
                      (obj) => obj.id === item.id,
                    );

                    const findRow = projectScope.find(
                      (obj) => obj.id === item.id,
                    );

                    const expectedStartDateEdit =
                      findRow?.project_scope_details?.expected_start_date;
                    const expectedCompletionDateEdit =
                      findRow?.project_scope_details?.expected_completion_date;

                    const intervalEdit = intervalToDuration({
                      start: expectedStartDateEdit
                        ? new Date(expectedStartDateEdit)
                        : new Date(),
                      end: expectedCompletionDateEdit
                        ? new Date(expectedCompletionDateEdit)
                        : new Date(),
                    });
                    let durationEdit = formatDuration(intervalEdit, {
                      format: ["years", "months", "days"],
                      locale: ms,
                    });

                    return (
                      <tr key={idx}>
                        <td className='text-center'>{idx + 1}</td>
                        <th>{item.cost_description}</th>
                        <td className='text-center'>
                          {editTrue ? (
                            <Form.Control
                              as={ReactDatePicker}
                              portalId='root-portal'
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div className='d-flex justify-content-center mx-1'>
                                  <Button
                                    size='sm'
                                    className='me-2'
                                    variant='secondary'
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                  >
                                    {"<"}
                                  </Button>

                                  <Form.Select
                                    style={{ width: 120 }}
                                    value={
                                      monthOptions[new Date(date).getMonth()]
                                    }
                                    onChange={({ target: { value } }) =>
                                      changeMonth(monthOptions.indexOf(value))
                                    }
                                  >
                                    {monthOptions.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Select>

                                  <Form.Select
                                    style={{ width: 80 }}
                                    className='me-2'
                                    value={new Date(date).getFullYear()}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {yearOptions.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Select>

                                  <Button
                                    size='sm'
                                    className='ms-2'
                                    variant='secondary'
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                  >
                                    {">"}
                                  </Button>
                                </div>
                              )}
                              selected={
                                isEmpty(
                                  findRow?.project_scope_details
                                    .expected_start_date,
                                )
                                  ? null
                                  : new Date(
                                      findRow?.project_scope_details.expected_start_date,
                                    )
                              }
                              onChange={(date) => {
                                const updateItem = projectScope.map((obj) => {
                                  if (editTrue) {
                                    return {
                                      ...obj,
                                      project_scope_details: {
                                        ...obj.project_scope_details,
                                        expected_start_date: format(
                                          new Date(date),
                                          "yyyy/MM/dd",
                                        ),
                                      },
                                    };
                                  }
                                  return obj;
                                });
                                setProjectScope(updateItem);
                              }}
                              placeholderText='Pilih Tarikh'
                              dateFormat='dd/MM/yyyy'
                            />
                          ) : (
                            <>
                              {item.project_scope_details &&
                              item.project_scope_details.expected_start_date
                                ? format(
                                    new Date(
                                      item.project_scope_details.expected_start_date,
                                    ),
                                    "dd/MM/yyyy",
                                  )
                                : "-"}
                            </>
                          )}
                        </td>
                        <td className='text-center'>
                          {editTrue ? (
                            <Form.Control
                              as={ReactDatePicker}
                              portalId='root-portal'
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div className='d-flex justify-content-center mx-1'>
                                  <Button
                                    size='sm'
                                    className='me-2'
                                    variant='secondary'
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                  >
                                    {"<"}
                                  </Button>
                                  <Form.Select
                                    style={{ width: 80 }}
                                    className='me-2'
                                    value={new Date(date).getFullYear()}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {yearOptions.map((option, idx) => (
                                      <option key={idx} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Select>

                                  <Form.Select
                                    style={{ width: 120 }}
                                    value={
                                      monthOptions[new Date(date).getMonth()]
                                    }
                                    onChange={({ target: { value } }) =>
                                      changeMonth(monthOptions.indexOf(value))
                                    }
                                  >
                                    {monthOptions.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Select>

                                  <Button
                                    size='sm'
                                    className='ms-2'
                                    variant='secondary'
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                  >
                                    {">"}
                                  </Button>
                                </div>
                              )}
                              selected={
                                isEmpty(
                                  findRow?.project_scope_details
                                    .expected_completion_date,
                                )
                                  ? null
                                  : new Date(
                                      findRow?.project_scope_details.expected_completion_date,
                                    )
                              }
                              onChange={(date) => {
                                const updateItem = projectScope.map((obj) => {
                                  if (editTrue) {
                                    return {
                                      ...obj,
                                      project_scope_details: {
                                        ...obj.project_scope_details,
                                        expected_completion_date: format(
                                          new Date(date),
                                          "yyyy/MM/dd",
                                        ),
                                      },
                                    };
                                  }
                                  return obj;
                                });
                                setProjectScope(updateItem);
                              }}
                              placeholderText='Pilih Tarikh'
                              dateFormat='dd/MM/yyyy'
                            />
                          ) : (
                            <>
                              {item.project_scope_details &&
                              item.project_scope_details
                                .expected_completion_date
                                ? format(
                                    new Date(
                                      item.project_scope_details.expected_completion_date,
                                    ),
                                    "dd/MM/yyyy",
                                  )
                                : "-"}
                            </>
                          )}
                        </td>
                        <td className='text-truncate'>
                          {editTrue ? durationEdit : duration}
                        </td>
                        <td>
                          <div className='d-flex justify-content-center gap-1'>
                            {editTrue ? (
                              <>
                                <IconButton
                                  size='small'
                                  onClick={() =>
                                    handleSaveProjectScopeRow(item)
                                  }
                                >
                                  <Icon
                                    path={mdiCheckBold}
                                    size='18px'
                                    color={palette.success}
                                  />
                                </IconButton>

                                <IconButton
                                  size='small'
                                  onClick={() => handleCancelEdit(item)}
                                >
                                  <Icon
                                    path={mdiCloseThick}
                                    size='18px'
                                    color={palette.secondary}
                                  />
                                </IconButton>
                              </>
                            ) : (
                              <Icon
                                path={mdiPencil}
                                size='18px'
                                color={palette.primary}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEditProjectScope(item)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectScope;
