import React from "react";
import { Card } from "react-bootstrap";
import numeral from "numeral";
import { useSelector } from "react-redux";

import Icon from "@mdi/react";
import { mdiCurrencyUsd } from "@mdi/js";

import Expenditure from "../../../../../../assets/img/photos/expenditure.png";

const LatestExpenditure = () => {
  const { selectYear, latestExpenditure } = useSelector((state) => ({
    latestExpenditure: state.dashboardReducer.overall.cards.latestExpenditure,
    selectYear: state.dashboardReducer.selectYear,
  }));
  return (
    <React.Fragment>
      <Card className='w-100 flex-fill'>
        <Card.Body className='d-flex'>
          <div className='flex-grow-1'>
            <p
              className='mb-2 text-uppercase'
              style={{ fontSize: 14, fontWeight: 700 }}
            >
              Perbelanjaan Terkini {selectYear && `Tahun ${selectYear.label}`}
            </p>
            <p
              className='mb-0 text-truncate'
              style={{ fontWeight: 700, fontSize: 14 }}
            >
              {`RM ${numeral(latestExpenditure).format("0,0.00")}`}
            </p>
          </div>
          <div className='align-self-center p-1' style={{ borderRadius: 42 }}>
            <img src={Expenditure} style={{ height: "100%", width: 50 }} />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default LatestExpenditure;
