import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { format } from "date-fns";
import { debounce } from "lodash";

// Content
import PageContent from "./PageContent";

// Redux
import { getActivities } from "../../../redux/slices/admin/activityLogSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  const [isTrueSearch, setIsTrueSearch] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [perPage, setPerPage] = useState(30);
  const [page, setPage] = useState(1);

  const handleApiCall = async (props) => {
    await dispatch(getActivities(props));
    setIsTrueSearch(false);
  };

  const handleSearch = () => {
    setIsTrueSearch(true);
  };

  const goToPage = debounce((value) => {
    setPage(value);
  }, 800);

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToLastPage = (page) => {
    setPage(page);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const handleChangePerPage = (e) => {
    setPerPage(e.target.value);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        startDate: startDate ? format(new Date(startDate), "yyyy-MM-dd") : null,
        endDate: endDate ? format(new Date(endDate), "yyyy-MM-dd") : null,
        page: page,
        perPage: perPage,
      },
    };

    handleApiCall(props);

    return () => {
      source.cancel();
    };
  }, [dispatch, isTrueSearch, page, perPage]);

  return (
    <React.Fragment>
      <Helmet title='Senarai Pengguna' />
      <Container fluid className='p-0'>
        <h4>Log Aktiviti</h4>
        <PageContent
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleSearch={handleSearch}
          perPage={perPage}
          page={page}
          goToPage={goToPage}
          goToFirstPage={goToFirstPage}
          previousPage={previousPage}
          nextPage={nextPage}
          goToLastPage={goToLastPage}
          handleChangePerPage={handleChangePerPage}
        />
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
