import React, { useContext } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch } from "react-redux";

// Styles
import usePalette from "../../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../../components/common/customStyleSelect";

// Redux
import {
  createMinistry,
  updateMinistry,
} from "../../../../../../redux/slices/government-structure/ministrySlice";

const MinistryForm = ({
  showForm,
  closeForm,
  edit,
  data,
  handleNotification,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      short_form: data.short_form || "",
      code: data.code || "",
    },
    onSubmit: (values) => {
      if (edit) {
        try {
          const newValues = {
            id: data.id,
            ...values,
          };
          dispatch(updateMinistry(newValues)).then((res) => {
            const data = res.payload;
            handleNotification("edited", data);
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          dispatch(createMinistry(values)).then((res) => {
            const data = res.payload;
            handleNotification("created", data);
          });
        } catch (error) {
          console.log(error);
        }
      }

      closeForm();
      formik.resetForm();
    },
  });

  const handleCloseModal = () => {
    closeForm();
  };

  return (
    <Modal show={showForm} onHide={closeForm} centered>
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Kementerian</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Nama Kementerian</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Singkatan</Form.Label>
            <Form.Control
              type='text'
              name='short_form'
              value={formik.values.short_form}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kod</Form.Label>
            <Form.Control
              type='text'
              name='code'
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleCloseModal}>
              Batal
            </Button>
            <Button variant='success' type='submit'>
              Simpan
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MinistryForm;
