/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, forwardRef } from "react";
import { Link, NavLink } from "react-router-dom";

import { Badge, Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";

const CustomRouterLink = forwardRef((props, ref) => (
  <React.Fragment ref={ref}>
    <NavLink {...props} />
  </React.Fragment>
));

const SidebarNavListItem = (props) => {
  const {
    title,
    subtitle,
    href,
    depth = 0,
    children,
    externalUrl,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <li className={`sidebar-item ${open ? "active" : ""}`}>
        <a
          className={`sidebar-link ${open ? "" : "collapsed"}`}
          data-bs-toggle='collapse'
          aria-expanded={open ? "true" : "false"}
          depth={depth}
          onClick={handleToggle}
        >
          {Icon && <Icon className='feather align-middle' />}{" "}
          <span className='align-middle' depth={depth}>
            {title}
          </span>
          {badge && (
            <Badge className='badge-sidebar-primary' bg='' size={18}>
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>
        <Collapse in={open}>
          <ul className='sidebar-dropdown list-unstyled'>{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li className='sidebar-item'>
      {externalUrl ? (
        <Link
          to='#'
          depth={depth}
          className='sidebar-link'
          onClick={(e) => {
            e.preventDefault();
            window.open(href, "_blank");
          }}
        >
          <div className=''>
            {Icon && <Icon className='feather align-middle' />}{" "}
            <span className='align-middle' depth={depth}>
              {title}
            </span>
            <br />
            <span
              className='align-middle'
              depth={depth}
              style={{ fontSize: "0.7rem" }}
            >
              {subtitle && subtitle}
            </span>
            {badge && (
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip>Projek perlu dikemaskini</Tooltip>}
              >
                <Badge className='badge-sidebar-primary' bg='' size={18}>
                  {badge}
                </Badge>
              </OverlayTrigger>
            )}
          </div>
        </Link>
      ) : (
        <CustomRouterLink
          depth={depth}
          to={href}
          activeclassname='active'
          className='sidebar-link'
        >
          <div className=''>
            {Icon && <Icon className='feather align-middle' />}{" "}
            <span className='align-middle' depth={depth}>
              {title}
            </span>
            <br />
            <span
              className='align-middle'
              depth={depth}
              style={{ fontSize: "0.7rem" }}
            >
              {subtitle && subtitle}
            </span>
            {badge && (
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip>Projek perlu dikemaskini</Tooltip>}
              >
                <Badge className='badge-sidebar-primary' bg='' size={18}>
                  {badge}
                </Badge>
              </OverlayTrigger>
            )}
          </div>
        </CustomRouterLink>
      )}
    </li>
  );
};

export default SidebarNavListItem;
