import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchPrograms = createAsyncThunk(
  "programs/fetchPrograms",
  async (props) => {
    try {
      const { params, source } = props;

      const response = await axios.get(`/programs`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createProgram = createAsyncThunk(
  "programs/createProgram",
  async (values) => {
    const response = await axios.post(`/programs`, values);

    const data = await response.data;

    return data;
  },
);

export const updateProgram = createAsyncThunk(
  "programs/updateProgram",
  async (values) => {
    const response = await axios.put(`/programs/${values.id}`, values);

    const data = await response.data;

    return data;
  },
);

export const removeProgram = createAsyncThunk(
  "programs/removeProgram",
  async (id) => {
    const response = await axios.delete(`/programs/${id}/delete`);

    return id;
  },
);

export const getLastCode = createAsyncThunk(
  "programs/getLastCode",
  async (params) => {
    const response = await axios.get(`/programs/get-last-code`, { params });

    const data = await response.data;

    return data;
  },
);

const initialState = {
  programs: [],
  meta: {},
  links: {},
  isLoading: false,
  btnLoading: false,
  lastCode: "",
};

export const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPrograms.fulfilled, (state, action) => {
      state.programs = action.payload.data;
      state.meta = action.payload.meta;
      state.links = action.payload.links;
      state.isLoading = false;
    });

    builder.addCase(getLastCode.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(getLastCode.fulfilled, (state, action) => {
      state.lastCode = action.payload;
      state.btnLoading = false;
    });

    builder.addCase(createProgram.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createProgram.fulfilled, (state, action) => {
      state.programs.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateProgram.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateProgram.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.programs = state.programs.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeProgram.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeProgram.fulfilled, (state, action) => {
      state.programs = state.programs.filter(
        (item) => item.id !== action.payload,
      );
      state.btnLoading = false;
    });
  },
});

export default programsSlice.reducer;
