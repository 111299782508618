import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useSelector } from "react-redux";
import numeral from "numeral";

const ProjectsProgressStatus = ({ itemLeft }) => {
  const { progressStatus } = useSelector((state) => ({
    // progressStatus: state.dashboardReducer.combine.projectsProgressStatus,
    progressStatus:
      state.dashboardReducer.overall.charts.projectsProgressStatus,
  }));

  return (
    <React.Fragment>
      <Card
        as={motion.div}
        variants={itemLeft}
        transition={{ duration: 0.3 }}
        className='flex-fill w-100'
      >
        <Card.Header className='d-flex align-items-center text-center pb-2'>
          <h4 className='text-uppercase'>Bilangan Status Kemajuan Projek</h4>
        </Card.Header>
        <Card.Body className='pt-0 d-flex flex-column align-items-center justify-content-center gap-1'>
          {progressStatus?.map((item, idx) => (
            <div
              key={idx}
              className='border flex-fill w-100 d-flex align-items-center text-white fw-bolder px-4 py-2 justify-content-between'
              style={{ backgroundColor: item.color }}
            >
              <div className='ms-5 ps-5'>
                {item.label}{" "}
                {`(${item.label
                  .split(" ")
                  .map((word) => word.charAt(0))
                  .join("")})`}
              </div>
              <div className='me-5 pe-5'>{item.count}</div>
            </div>
          ))}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ProjectsProgressStatus;
