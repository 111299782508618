import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchParliaments = createAsyncThunk(
  "parliaments/fetchParliaments",
  async () => {
    try {
      const response = await axios.get(`/parliaments`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createParliament = createAsyncThunk(
  "parliaments/createParliament",
  async (values) => {
    const response = await axios.post(`/parliaments`, values);

    const data = await response.data;

    return data;
  },
);

export const updateParliament = createAsyncThunk(
  "parliaments/updateParliament",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/parliaments/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeParliament = createAsyncThunk(
  "parliaments/removeParliament",
  async (id) => {
    try {
      const response = await axios.delete(`/parliaments/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  parliaments: [],
  isLoading: false,
  btnLoading: false,
};

export const parliamentsSlice = createSlice({
  name: "parliaments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchParliaments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchParliaments.fulfilled, (state, action) => {
      state.parliaments = action.payload;
      state.isLoading = false;
    });

    builder.addCase(createParliament.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createParliament.fulfilled, (state, action) => {
      state.parliaments.push(action.payload);
      state.isLoading = false;
    });

    builder.addCase(updateParliament.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateParliament.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.parliaments = state.parliaments.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeParliament.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(removeParliament.fulfilled, (state, action) => {
      state.parliaments = state.parliaments.filter(
        (item) => item.id !== action.payload,
      );

      state.btnLoading = false;
    });
  },
});

export default parliamentsSlice.reducer;
