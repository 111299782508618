import React, { useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import NotyfContext from "../../../../../contexts/NotyfContext";

import esriConfig from "@arcgis/core/config";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";

// Widgets
import Sketch from "@arcgis/core/widgets/Sketch";
import Search from "@arcgis/core/widgets/Search";

const WebMap = () => {
  const mapRefTest = useRef();
  const notyf = useContext(NotyfContext);

  const { user, project } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.projectReducer.project,
  }));

  esriConfig.apiKey = process.env.REACT_APP_ARCGIS_API_KEY;

  // Notification
  const handleNotification = () => {
    notyf.open({
      type: "success",
      message: `Lokasi projek berjaya dikemaskini.`,
      duration: 3000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    // Symbols
    const rendererPoint = {
      type: "simple",
      symbol: {
        type: "picture-marker",
        url: "http://static.arcgis.com/images/Symbols/Basic/BlackStickpin.png",
        width: "20px",
        height: "20px",
      },
    };

    // const pointLayer = new FeatureLayer({
    //   renderer: rendererPoint,
    // });

    const graphicsLayer = new GraphicsLayer();

    const webMap = new Map({
      basemap: "osm-standard",
      layers: [graphicsLayer],
    });

    const mapView = new MapView({
      container: mapRefTest.current,
      map: webMap,
      center: [116.091528, 5.985887],
      zoom: 13,
    });

    // Search function
    const searchWidget = new Search({
      view: mapView,
      allPlaceholder: "Daerah",
      includeDefaultSources: false,
      // sources: [
      //   {
      //     layer: districtLayer,
      //     searchFields: ["Dist_Name"],
      //     displayField: "Dist_Name",
      //     exactMatch: false,
      //     placeholder: "cth: Kota Kinabalu",
      //   },
      // ],
    });

    mapView.ui.add(searchWidget, {
      position: "top-right",
      index: 0,
    });

    const sketch = new Sketch({
      layer: graphicsLayer,
      view: mapView,
      creationMode: "single",
      layout: "vertical",
      availableCreateTools: ["point", "polygon"],
      defaultUpdateOptions: {
        enableRotation: false,
        enableScaling: false,
        enableZ: false,
        multipleSelectionEnabled: false,
        toggleToolOnClick: false,
      },
    });

    mapView.ui.add(sketch, {
      position: "top-right",
      visibleElements: {
        createTools: {
          circle: false,
        },
      },
    });

    sketch.on("create", (event) => {
      console.log(event);
      if (event.state === "complete") {
        //   const { graphic } = event;

        //   const newValues = {
        //     ...graphic,
        //     symbol: graphic.symbol,
        //     geometry: graphic.geometry,
        //     attributes: {
        //       OBJECTID: 1604,
        //       Daerah: null,
        //       created_user: user.name,
        //       created_date: new Date(),
        //       last_edited_user: user.name,
        //       last_edited_date: new Date(),
        //       Project_name: project.name,
        //       project_id: project.id,
        //     },
        //   };
        //   const edits = {
        //     addFeatures: [newValues],
        //   };
        //   console.log(edits);
        //   graphicsLayer.remove(graphic);
        handleNotification();
      }
    });

    mapView.when(() => {
      reactiveUtils.on(
        () => mapView.popup,
        "trigger-action",
        (e) => {
          if (e.action.id === "remove-this") {
            const selectedFeature = mapView.popup.selectedFeature;
            if (selectedFeature) {
              const selectedLayer = selectedFeature.layer;
              const featureAttributes = selectedFeature.attributes;
              const objectIdToDelete = featureAttributes.OBJECTID;
              console.log(selectedFeature);
              // handleRemove(selectedFeature, selectedLayer.geometryType);
            }
          }
        },
      );
    });

    return () => {
      if (mapView) {
        mapView.container = null;
      }
    };
  }, []);

  return (
    <React.Fragment>
      <div
        ref={mapRefTest}
        style={{ height: "100%", width: "100%", padding: 0, margin: 0 }}
      />
    </React.Fragment>
  );
};

export default WebMap;
