import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Form,
  Row,
  Accordion,
  Button,
  Spinner,
} from "react-bootstrap";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Checkbox,
  Divider,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import _, { isEmpty } from "lodash";

// Styles
import {
  customStyles,
  customStylesForm,
} from "../../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiHelpCircle } from "@mdi/js";

// Components
import LoadingState from "../../../../../../components/common/LoadingState";

// Redux
import {
  createProject,
  updateProject,
} from "../../../../../../redux/slices/projects/projectSlice";
import { createNewProjectApplication } from "../../../../../../redux/slices/jppn/jppnApplyNewProjectSlice";

const BasicInfo = ({ mainSectorList, enableBtnSave, projectId }) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const routeParams = useParams();
  const navigate = useNavigate();

  const { type, applicationCategory, applicationType } = routeParams;

  const [checked, setChecked] = useState([1]);
  const [isEdit, setIsEdit] = useState(false);
  const [showDepartmentInput, setShowDepartmentInput] = useState(false);
  const [showBranchInput, setShowBranchInput] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [colFormGroup, setColFormGroup] = useState({
    ministry: 6,
    department: 6,
    branch: 0,
  });

  const [programOptions, setProgramOptions] = useState([]);
  const [programValue, setProgramValue] = useState(null);
  const [mainProjectOptions, setMainProjectOptions] = useState([]);
  const [mainProjectValue, setMainProjectValue] = useState(null);
  const [goalValues, setGoalValues] = useState([]);
  const [noSubProject, setNoSubProject] = useState(false);

  const [resetProgramValue, setResetProgramValue] = useState(true);

  const [mainSectorOptions, setMainSectorOptions] = useState([]);
  const [strategicDirectionOptions, setStrategicDirectionOptions] = useState(
    [],
  );
  const [disabledInput, setDisabledInput] = useState(false);

  const {
    isLoading,
    btnLoading,
    applicationStatus,
    user,
    programs,
    mainProjects,
    project,
    ministries,
    departments,
    categories,
    activeRmk,
    goals,
  } = useSelector((state) => ({
    user: state.userReducer.user,

    isLoading: state.projectReducer.isLoading,
    btnLoading: state.projectReducer.btnLoading,

    project: state.projectReducer.project,
    applicationStatus: state.projectReducer.applicationStatus,

    ministries: state.utilsReducer.dropdownItems.ministries,
    departments: state.utilsReducer.dropdownItems.departments,
    categories: state.utilsReducer.dropdownItems.categories,
    programs: state.utilsReducer.dropdownItems.programs,
    mainProjects: state.utilsReducer.dropdownItems.mainProjects,

    goals: state.utilsReducer.checkBoxItems.sustainableDevelopmentGoals,

    activeRmk: state.rmkReducer.activeRmk,
  }));

  const isSuperAdmin = user.roles.includes("superadmin");
  const isUser = user.roles.includes("user");

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      approval_stages_to: project.approval_stages_to || "JPPN",
      project_category_id: project.project_category || null,
      key_performance_indicator: project.key_performance_indicator || "",
      goals: project.sustainable_development_goals || [],
      ministry_id: project.ministry || null,
      department_id: project.department || null,
      project_category_id: project.project_category || null,
      program: project.program || programValue,
      name: project.name || "",
    },
    validationSchema: Yup.object().shape({
      program: Yup.object().shape().required("Sila pilih program").nullable(),
      main_sector_id: Yup.object()
        .shape()
        .required("Sila pilih sektor")
        .nullable(),
      strategic_directions: Yup.array()
        .min(1, "Sila pilih sekurang-kurangnya 1 pilihan")
        .required("Sila pilih halatuju strategi")
        .nullable(),
      // key_performance_indicator: Yup.string()
      //   .required("Sila isi KPI program dan projek")
      //   .min(20, "Mesti melebihi 20 perkataan"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          ...values,
          id: project.uuid,
          strategic_directions: values.strategic_directions,
          key_performance_indicator: values.key_performance_indicator,
          main_sector_id: values.main_sector_id.id,
          ministry_id: Number(values.ministry_id.code),
          department_id: values.department_id.id,
          project_category_id: values.project_category_id.id,
          sustainable_development_goals: values.goals,
          program_id: values.program.id,
          main_project_id: values.main_project?.id,
        };
        await dispatch(updateProject(newValues));
        handleNotification("edited", newValues);
      } else {
        const newValues = {
          ...values,
          rmk_id: activeRmk.number,
          program_id: values.program.id,
          application_status: "1",
          project_category_id: values.project_category_id.id,
          strategic_directions: values.strategic_directions,
          main_sector_id: values.main_sector_id.id,
          ministry_id: Number(values.ministry_id?.code),
          department_id: values.department_id?.id,
          sustainable_development_goals: values.goals,
          main_project_id: values.main_project?.id,
        };
        let getUuid = null;

        await dispatch(createProject(newValues)).then(async (res) => {
          const { uuid, id } = res.payload;
          getUuid = uuid;

          const projectParams = {
            project_id: id,
          };

          await dispatch(createNewProjectApplication(projectParams));
        });

        handleNotification("created", newValues);
      }
    },
  });

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat projek berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat projek berjaya dikemaskini.`;
        break;

      default:
        msg = `Projek Profil berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleChangeMinistry = (value) => {
    setProgramOptions([]);
    setDepartmentValue(null);

    formik.setFieldValue("ministry_id", value);
    if (!resetProgramValue) formik.setFieldValue("program_id", null);

    const { code } = value;
    const departmentOptions = value.departments;

    // const filteredDepartment = departments.filter(
    //   (item) => item.ministry_code === code,
    // );

    setDepartmentOptions(departmentOptions);

    const filteredPrograms = programs.filter(
      (program) =>
        program.ministry_code === code || program.ministry_code === null,
    );

    setProgramOptions(filteredPrograms);
  };

  const handleChangeDepartment = (value) => {
    if (!resetProgramValue) formik.setFieldValue("program_id", null);

    setDepartmentValue(value);
    formik.setFieldValue("department_id", value);

    if (isEmpty(value)) {
      setBranchOptions([]);
    } else {
      setBranchOptions(value.branches);
    }

    if (!isEmpty(project.department_id)) {
      setProgramOptions([]);

      const filteredPrograms = programs.filter(
        (program) =>
          program.department_id === value.id ||
          program.ministry_code.toString() === null,
      );
      setProgramOptions(filteredPrograms);
    } else {
      const filteredPrograms = programs.filter(
        (program) =>
          program.department_id === value.id ||
          program.ministry_code.toString() === null,
      );
      setProgramOptions(filteredPrograms);
    }
  };

  const handleSelectBranch = (value) => {
    if (isEmpty(value)) {
      formik.setFieldValue("main_project", "");
      return;
    }

    formik.setFieldValue("branch", value);

    const filteredMainProjects = mainProjects.filter((item) => {
      return item?.branch_id === value?.id;
    });

    const groupedData = _.groupBy(filteredMainProjects, "program_id");

    const programId = Object.keys(groupedData)[0];
    const filteredPrograms = programOptions.filter(
      (program) => program.id === Number(programId),
    );

    setProgramOptions(filteredPrograms);
    setMainProjectOptions(filteredMainProjects);
  };

  const handleSelectProgram = (value) => {
    formik.setFieldValue("program", value);

    const { branch } = user.profile;
    const isBranchTrue = !isEmpty(branch) || !isEmpty(formik.values?.branch);

    let filteredMainProjects = mainProjects.filter((item) => {
      if (isBranchTrue) {
        return (
          item?.branch_id === branch?.id ||
          item?.branch_id === formik.values?.branch?.id
        );
      } else {
        return item?.program_id === value?.id;
      }
    });

    if (filteredMainProjects.length === 1) {
      handleSelectMainProject(filteredMainProjects[0]);
    } else {
      setMainProjectOptions(filteredMainProjects);
    }
  };

  const handleSelectMainProject = (value) => {
    formik.setFieldValue("main_project", value);
  };

  const handleSelectGoal = (e) => {
    const value = Number(e.target.value);

    const isExisted = formik.values.goals.includes(value);

    if (isExisted) {
      formik.setFieldValue(
        "goals",
        formik.values.goals.filter((item) => item !== value),
      );
    } else {
      const values = [...formik.values.goals, value];

      formik.setFieldValue("goals", values);
    }
  };

  useEffect(() => {
    if (!user) return;

    const { ministry, department, roles, profile } = user;
    const { branch } = profile;
    const isSuperadmin = roles.some((role) => role === "superadmin");

    if (isSuperadmin) {
      setShowDepartmentInput(true);
      setShowBranchInput(true);
      setColFormGroup({
        ministry: 5,
        department: 4,
        branch: 3,
      });
    } else {
      setShowDepartmentInput(!isEmpty(department));
      setShowBranchInput(!isEmpty(branch));
      setColFormGroup({
        ministry: isEmpty(department) ? 12 : isEmpty(branch) ? 6 : 5,
        department: isEmpty(department) ? 0 : isEmpty(branch) ? 6 : 4,
        branch: isEmpty(branch) ? 0 : 3,
      });
    }

    // formik.setFieldValue("ministry_id", ministry);
    // formik.setFieldValue("department_id", department);

    setResetProgramValue(isSuperadmin && !isEmpty(projectId));

    if (!isEmpty(project)) {
      const { ministry, department } = project;
      handleChangeMinistry(ministry);
      handleChangeDepartment(department);
      handleSelectMainProject(project.main_project);
      handleSelectProgram(project.program);
    } else {
      let filteredMainProjects = mainProjects.filter((item) => {
        return showBranchInput
          ? item.branch_id === branch?.id
          : item.department_id === department?.id;
      });
      setMainProjectOptions(filteredMainProjects);

      let filteredPrograms = programs.filter((item) => {
        return showBranchInput
          ? item.id === filteredMainProjects[0]?.program_id
          : item.ministry_code === ministry?.code;
      });

      if (filteredPrograms.length === 1) {
        handleSelectProgram(filteredPrograms[0]);
      }

      setProgramOptions(filteredPrograms);
    }
  }, [user, programs, mainProjects, projectId, project]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (!isEmpty(project)) {
      const selectedOption = mainSectorOptions.find(
        (item) => item.id === project.main_sector?.id,
      );

      setStrategicDirectionOptions(selectedOption?.strategic_directions);

      const newValue = {
        ...selectedOption,
        label: selectedOption?.name,
        value: selectedOption?.name,
      };

      formik.setFieldValue("main_sector_id", newValue);

      const items = project.strategic_directions?.map(
        (item) => item.strategic_direction_id,
      );

      formik.setFieldValue("strategic_directions", items);

      setIsEdit(true);
    }

    return () => {
      isMounted = false;
      setIsEdit(false);
    };
  }, [mainSectorOptions, project]);

  useEffect(() => {
    if (
      !isEmpty(applicationCategory) &&
      !isEmpty(type) &&
      !isEmpty(categories)
    ) {
      const splitTitle = applicationCategory.split("-");
      const categoryTitle = splitTitle
        .map((title) => title.charAt(0).toUpperCase())
        .join("");
      const splitType = type.split("-");
      const categoryType = splitType
        .map((type) => `${type.charAt(0).toUpperCase()}${type.slice(1)}`)
        .join(" ");
      const value =
        categoryTitle === "KSP"
          ? `${categoryType} (${categoryTitle})`
          : categoryType;

      const category = categories.find((item) => item.value === value);

      formik.setFieldValue("project_category_id", category);
    }
  }, [applicationCategory, type, categories]);

  useEffect(() => {
    if (!isEmpty(mainSectorList)) {
      const options = mainSectorList.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.name,
        };
      });
      setMainSectorOptions(options);
    }
  }, [mainSectorList]);

  useEffect(() => {
    if (isEmpty(project)) return;
    const isApproved = project?.application_status.toString() === "4";
    setDisabledInput(isApproved);
  }, [project]);

  return (
    <Card
      className='mb-0 remove-outline-item'
      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
    >
      <Card.Header className='pb-0'>
        <Card.Title>Maklumat Asas</Card.Title>
      </Card.Header>
      <Card.Body className='pt-2'>
        <Form
          noValidate
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md={colFormGroup.ministry}>
              <Form.Group className='mb-3'>
                <Form.Label>Kementerian *</Form.Label>
                {/* {isSuperAdmin  ? ( */}
                <Select
                  name='ministry_id'
                  isSearchable
                  options={ministries}
                  placeholder='Pilih Kementerian...'
                  value={formik.values.ministry_id}
                  onChange={(value) => handleChangeMinistry(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                  isDisabled={disabledInput}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                  }}
                />
                {/* ) : (
                  <Form.Control
                    type='text'
                    name='ministry_id'
                    defaultValue={
                      !isEmpty(project)
                        ? `${project?.ministry?.value} (${project?.ministry?.short_form})`
                        : `${user.ministry?.value} (${user.ministry?.short_form})`
                    }
                    disabled
                  />
                )} */}
              </Form.Group>
            </Col>
            <Col md={colFormGroup.department}>
              {/* {showDepartmentInput && ( */}
              <Form.Group className='mb-3'>
                <Form.Label>Jabatan *</Form.Label>
                {/* {isSuperAdmin  ? ( */}
                <Select
                  name='department_id'
                  isSearchable
                  isClearable
                  options={departmentOptions}
                  placeholder='Pilih Jabatan'
                  value={departmentValue}
                  onChange={(value) => handleChangeDepartment(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                  isDisabled={disabledInput}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                  }}
                />
                {/* ) : (
                    <Form.Control
                      type='text'
                      name='department_id'
                      defaultValue={
                        !isEmpty(project)
                          ? `${project.department?.value} (${project.department?.short_form})`
                          : `${user.department?.value} (${user.department?.short_form})`
                      }
                      disabled
                    />
                  )} */}
              </Form.Group>
              {/* )} */}
            </Col>
            {showBranchInput && (
              <Col md={colFormGroup.branch}>
                <Form.Group className='mb-3'>
                  <Form.Label>Cawangan (Jika berkaitan)</Form.Label>
                  {isSuperAdmin ? (
                    <Select
                      name='branch_id'
                      isSearchable
                      isClearable
                      options={branchOptions}
                      placeholder='Pilih Cawangan'
                      value={formik.values.branch}
                      onChange={(value) => handleSelectBranch(value)}
                      noOptionsMessage={() => "Tiada pilihan"}
                      isDisabled={disabledInput}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#ced4da",
                          backgroundColor: state.isDisabled
                            ? "#e2e8ee"
                            : "#FFF",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                      }}
                    />
                  ) : (
                    <Form.Control
                      type='text'
                      name='branch_id'
                      defaultValue={
                        !isEmpty(project)
                          ? `${project.department?.value} (${project.department?.short_form})`
                          : `${user.profile?.branch.value}`
                      }
                      disabled
                    />
                  )}
                </Form.Group>
              </Col>
            )}
          </Row>

          <Form.Group className='mb-3'>
            <Form.Label>
              Program{" "}
              <span className='text-sm'>
                (Hanya memaparkan program dibawah RMK semasa)
              </span>
            </Form.Label>

            <Select
              name='program'
              isSearchable
              options={programOptions}
              placeholder='Pilih Program...'
              value={formik.values.program}
              onChange={(value) => handleSelectProgram(value)}
              noOptionsMessage={() => "Tiada pilihan"}
              isDisabled={disabledInput}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#ced4da",
                  backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                }),
              }}
            />

            {formik.errors.program_id && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.program_id}
              </p>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Projek Utama *</Form.Label>
            <Select
              name='main_project'
              isSearchable
              options={mainProjectOptions}
              placeholder='Pilih Projek Utama...'
              value={formik.values.main_project}
              onChange={(value) => handleSelectMainProject(value)}
              noOptionsMessage={() => "Tiada pilihan"}
              isDisabled={disabledInput}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#ced4da",
                  backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                }),
              }}
            />
            {formik.errors.main_project && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.main_project}
              </p>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <div className='mb-2'>
              <Form.Label className='mb-0'>Nama Projek *</Form.Label>
              <Form.Group controlId='project_name_checkbox'>
                <Form.Check
                  id='project_name_checkbox'
                  type='checkbox'
                  label={
                    <span className='text-sm'>
                      Sila tandakan jika projek utama adalah nama projek
                    </span>
                  }
                  disabled={disabledInput}
                  checked={noSubProject}
                  onChange={() => {
                    setNoSubProject(!noSubProject);
                    if (noSubProject) {
                      formik.setFieldValue("name", "");
                    } else {
                      formik.setFieldValue(
                        "name",
                        formik.values.main_project?.value,
                      );
                    }
                  }}
                />
              </Form.Group>
            </div>
            <Form.Control
              as='textarea'
              name='name'
              style={{
                minHeight: 75,
                maxHeight: 95,
              }}
              value={formik.values.name || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.name && !formik.errors.name ? true : false
              }
              isInvalid={
                formik.touched.name && formik.errors.name ? true : false
              }
              disabled={noSubProject || disabledInput}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col md={6} className='d-flex'>
              <Form.Group className='mb-3 flex-fill w-100'>
                <Form.Label>Kategori Permohonan Projek</Form.Label>
                <Select
                  name='project_category_id'
                  isSearchable
                  options={categories.filter(
                    (item) => item.id === 4 || item.id === 5 || item.id === 6,
                  )}
                  styles={customStylesForm}
                  placeholder='Pilih kategori projek...'
                  value={formik.values.project_category_id}
                  onChange={(value) =>
                    formik.setFieldValue("project_category_id", value)
                  }
                  noOptionsMessage={() => "Tiada pilihan"}
                  isDisabled={disabledInput || (isSuperAdmin ? false : true)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className='d-flex'>
              <Form.Group className='mb-3 flex-fill w-100'>
                <Form.Label>Kelulusan Projek</Form.Label>
                <Form.Control
                  style={{ minHeight: "38px" }}
                  type='text'
                  name='approval_stages_to'
                  value={formik.values.approval_stages_to}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <div className='mb-3'>
            <Form.Label>Matlamat Pembangunan Mampan (SDG)</Form.Label>
            <Row>
              {Array.from({ length: Math.ceil(goals.length / 6) }).map(
                (_, colIdx) => (
                  <Col md={4} key={colIdx}>
                    {goals
                      .slice(colIdx * 6, colIdx * 6 + 6)
                      .map((item, idx) => (
                        <Form.Group
                          className='d-flex align-items-center'
                          controlId={item.id}
                          key={item.id}
                        >
                          <Form.Check
                            className='me-2'
                            id={item.id}
                            name='sustainable_development_goals'
                            label={
                              <span>{`${colIdx * 6 + idx + 1}) ${
                                item.goal
                              }`}</span>
                            }
                            value={item.id}
                            type='checkbox'
                            onChange={(e) => handleSelectGoal(e)}
                            checked={(formik.values.goals || []).includes(
                              item.id,
                            )}
                            disabled={disabledInput}
                          />
                          <Tooltip
                            title={item.description}
                            placement='top-start'
                            arrow
                          >
                            <Icon path={mdiHelpCircle} size={0.6} />
                          </Tooltip>
                        </Form.Group>
                      ))}
                  </Col>
                ),
              )}
            </Row>
          </div>

          <Form.Group className='mb-3'>
            <Form.Label>KPI Program dan Projek *</Form.Label>
            <Form.Control
              as='textarea'
              name='key_performance_indicator'
              style={{
                minHeight: 70,
                maxHeight: 100,
              }}
              value={formik.values.key_performance_indicator}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.key_performance_indicator &&
                !formik.errors.key_performance_indicator
                  ? true
                  : false
              }
              isInvalid={
                formik.touched.key_performance_indicator &&
                formik.errors.key_performance_indicator
                  ? true
                  : false
              }
              disabled={disabledInput}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.key_performance_indicator}
            </Form.Control.Feedback>
          </Form.Group>

          <div className='mt-3'>
            <Form.Group className='mb-3'>
              <Form.Label>Sektor-sektor *</Form.Label>
              <Select
                name='main_sector_id'
                isSearchable
                isClearable
                options={mainSectorOptions}
                placeholder='Pilih sektor'
                value={formik.values.main_sector_id}
                onChange={(value) => {
                  formik.setFieldValue("main_sector_id", value);
                  if (isEmpty(value)) {
                    setStrategicDirectionOptions([]);
                  } else {
                    setStrategicDirectionOptions(value.strategic_directions);
                    formik.setFieldValue("strategic_directions", []);
                  }
                }}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled={disabledInput}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                    backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                  }),
                }}
              />
              {formik.errors?.main_sector_id && (
                <p className='mt-1 custom-feedback-invalid'>
                  {formik.errors?.main_sector_id}
                </p>
              )}
            </Form.Group>

            <Form.Label className='mb-0'>
              Senarai Halatuju Strategi *
            </Form.Label>
            {formik.touched.strategic_directions &&
              formik.errors.strategic_directions && (
                <p className='my-0 custom-feedback-invalid'>
                  {formik.errors.strategic_directions}
                </p>
              )}
            {!isEmpty(strategicDirectionOptions) ? (
              <>
                {strategicDirectionOptions.map((item, idx) => {
                  const isChecked = project?.strategic_directions?.some(
                    (item2) => item.id === item2.strategic_direction_id,
                  );

                  return (
                    <Form.Group
                      key={item.id}
                      controlId={item.id}
                      className='mt-2'
                    >
                      <Form.Check
                        name='strategic_directions'
                        label={item.name}
                        value={item.id}
                        type='checkbox'
                        disabled={disabledInput}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);

                          const check =
                            formik.values.strategic_directions.includes(value);

                          if (!check) {
                            const values = [
                              ...formik.values.strategic_directions,
                              value,
                            ];

                            formik.setFieldValue(
                              "strategic_directions",
                              values,
                            );
                          } else {
                            formik.setFieldValue(
                              "strategic_directions",
                              formik.values.strategic_directions.filter(
                                (item) => item !== value,
                              ),
                            );
                          }
                        }}
                        checked={(
                          formik.values.strategic_directions || []
                        ).includes(item.id)}
                      />
                    </Form.Group>
                  );
                })}
              </>
            ) : (
              <p className='mb-0 custom-feedback-invalid'>
                Sila pilih sektor-sektor
              </p>
            )}
          </div>

          {enableBtnSave && (
            <div className='d-flex justify-content-end'>
              {isEmpty(project) && (
                <div className='me-2 align-self-center'>
                  <Tooltip
                    title={
                      <div className='text-center'>
                        sila click butang cipta profil untuk isi maklumat projek
                        selebihnya
                      </div>
                    }
                    placement='left'
                    arrow
                  >
                    <span>
                      <Icon path={mdiHelpCircle} size={0.6} className='m-0' />
                    </span>
                  </Tooltip>
                </div>
              )}

              {!disabledInput && (
                <Button
                  className='align-center px-3'
                  type='submit'
                  variant='primary'
                  disabled={btnLoading ? true : false}
                >
                  {btnLoading ? (
                    <Icon
                      className=''
                      path={mdiLoading}
                      size={0.8}
                      spin={true}
                    />
                  ) : (
                    <span style={{ fontSize: 12 }}>
                      {isEdit ? "Kemas Kini" : "Cipta Profil"}
                    </span>
                  )}
                </Button>
              )}
            </div>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default BasicInfo;
