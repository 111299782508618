import React, { useEffect, useState } from "react";
import {
  Card,
  Accordion,
  Table,
  Tab,
  Nav,
  Form,
  Col,
  Row,
  Pagination,
} from "react-bootstrap";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

// components
import ProjectHistoriesPagination from "./pagination/ProjectHistoriesPagination";

const ProjectHistory = ({ project }) => {
  const [histories, setHistories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const { rmk } = useSelector((state) => ({
    rmk: state.rmkReducer.activeRmk,
  }));

  const indexOfLastPage = currentPage * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const data = histories.slice(indexOfFirstPage, indexOfLastPage);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setHistories(project?.projectHistories);
  }, [project]);

  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>MAKLUMAT SEJARAH PROJEK</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <Card className='mb-0'>
          <Card.Body>
            <div className='table-responsive'>
              <Table size='sm'>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th className='text-center'>Bil</th>
                    <th className='text-center'>RMK</th>
                    <th className='text-center'>Tarikh</th>
                    <th className='text-center'>Tajuk</th>
                    <th className='text-center'>Data Baru</th>
                    <th className='text-center'>Data Lama</th>
                    <th className='text-center'>Dikemas kini oleh</th>
                  </tr>
                </thead>
                <tbody>
                  {isEmpty(data) ? (
                    <tr>
                      <td colSpan={5} className='text-center'>
                        Tiada maklumat tersedia
                      </td>
                    </tr>
                  ) : (
                    <>
                      {data.map((item, idx) => {
                        return (
                          <tr key={item.id}>
                            <td className='text-center'>
                              {indexOfFirstPage + idx + 1}
                            </td>
                            <td className='text-center'>{item.rmk_number}</td>
                            <td className='text-center'>
                              <div>
                                {format(
                                  new Date(item.created_at),
                                  "dd/MM/yyyy, hh:mm a",
                                )}
                              </div>
                            </td>
                            <td className='text-center'>{item.subject_type}</td>
                            <td className='text-center'>{item.new_data}</td>
                            <td className='text-center'>{item.old_data}</td>
                            <td className='text-center'>
                              <div className='fw-bolder'>
                                {item.action_by?.name}
                              </div>
                              <div>
                                {item.action_by?.user_profile?.position}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </Table>
              <ProjectHistoriesPagination
                perPage={perPage}
                totalProjects={histories.length}
                currentPage={currentPage}
                paginate={paginate}
              />
            </div>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectHistory;
