import React from "react";
import { Card } from "react-bootstrap";
import numeral from "numeral";
import { useSelector } from "react-redux";

import Budget from "../../../../../../assets/img/photos/budget.png";

import Icon from "@mdi/react";
import { mdiCurrencyUsd, mdiCircleDouble } from "@mdi/js";

const InitialAllocation = () => {
  const { selectYear, initialAllocation } = useSelector((state) => ({
    initialAllocation: state.dashboardReducer.overall.cards.initialAllocation,
    selectYear: state.dashboardReducer.selectYear,
  }));

  return (
    <React.Fragment>
      <Card className='w-100 flex-fill position-relative'>
        <Card.Body className='d-flex'>
          <div className='flex-grow-1'>
            <p
              className='mb-2 text-uppercase'
              style={{
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              Peruntukan Asal {selectYear && `Tahun ${selectYear.label}`}
            </p>
            <p
              className='mb-0 text-truncate'
              style={{ fontWeight: 700, fontSize: 14 }}
            >
              {`RM ${numeral(initialAllocation).format("0,0.00")}`}
            </p>
          </div>
          <div className='align-self-center p-1' style={{ borderRadius: 42 }}>
            <img src={Budget} style={{ height: "100%", width: 50 }} />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default InitialAllocation;
