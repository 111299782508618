import Axios from "axios";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const authCheck = localStorage.getItem("accessToken");
  if (authCheck) return authCheck;
  return null;
};

const token = getLoggedInUser();

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BACKEND_URL,
  withCredentials: true,
});

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default axios;
