import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";

// Icon
import { MdPhotoCamera, MdDelete } from "react-icons/md";

// Img
import defaultAvatar from "../../../assets/img/avatars/default-avatar.jpeg";

// Redux
import { updateUser } from "../../../redux/slices/userSlice";

const stringAvatar = (name) => {
  const nameSplit = name.split(" ");

  if (nameSplit.length == 1) {
    return { children: `${name.split(" ")[0][0]}` };
  }

  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

const BasicInfo = ({ isEdit, handleCloseEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [avatar, setAvatar] = useState(null);
  const [showUploadImg, setShowUploadImg] = useState(false);
  const [agency, setAgency] = useState("");

  const { user } = useSelector((state) => ({
    user: state.userReducer.user,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      position: user?.profile?.position || "",
      grade: user?.profile?.grade || "",
      phone_number: user?.profile?.phone_number || "",
      office_phone_number: user?.profile?.office_phone_number || "",
      office_address: user?.profile?.office_address || "",
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        id: user.id,
      };
      await dispatch(updateUser(newValues));
      handleCloseEdit();
    },
  });

  const handleSave = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return;
  };

  const handleChangePassword = () => {
    navigate("/ubah-kata-laluan");
  };

  useEffect(() => {
    if (user.department) {
      setAgency(user?.department?.value);
    } else {
      setAgency(user?.ministry?.value);
    }
  }, [user]);

  return (
    <React.Fragment>
      <div>
        <Table borderless size='sm'>
          <tbody>
            <tr>
              <th style={{ width: "25%" }}>Nama</th>
              <td>{user?.name}</td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>No. MyKad</th>
              <td>{user?.nric}</td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>Emel</th>
              <td>{user?.email}</td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>Kementerian/Jabatan/Agensi</th>
              <td>{agency}</td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>Jawatan</th>
              <td>
                {!isEdit ? (
                  <>{user?.profile?.position || "-"}</>
                ) : (
                  <Form.Group>
                    <Form.Control
                      name='position'
                      type='text'
                      value={formik.values.position}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                )}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>Gred</th>
              <td>
                {!isEdit ? (
                  <>{user?.profile?.grade || "-"}</>
                ) : (
                  <Form.Group>
                    <Form.Control
                      name='grade'
                      type='text'
                      value={formik.values.grade}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                )}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>No. Telefon </th>
              <td>
                {!isEdit ? (
                  <>{user?.profile?.phone_number || "-"}</>
                ) : (
                  <Form.Group>
                    <Form.Control
                      name='phone_number'
                      type='text'
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                )}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>No. Telefon Pejabat</th>
              <td>
                {!isEdit ? (
                  <>{user?.profile?.office_phone_number || "-"}</>
                ) : (
                  <Form.Group>
                    <Form.Control
                      name='office_phone_number'
                      type='text'
                      value={formik.values.office_phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                )}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%" }}>Alamat Pejabat</th>
              <td>
                {!isEdit ? (
                  <>{user?.profile?.office_address || "-"}</>
                ) : (
                  <Form.Group>
                    <Form.Control
                      name='office_address'
                      type='text'
                      as='textarea'
                      style={{ maxHeight: 180, height: 60 }}
                      value={formik.values.office_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                )}
              </td>
            </tr>
          </tbody>
        </Table>

        <div className='d-flex justify-content-between gap-3 mt-3'>
          <Button variant='secondary' onClick={handleChangePassword}>
            Tukar Kata Laluan
          </Button>
          {isEdit && (
            <div>
              <Button
                className='me-3'
                variant='success'
                onClick={(e) => handleSave(e)}
              >
                Simpan
              </Button>
              <Button variant='light' onClick={handleCloseEdit}>
                Batal
              </Button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicInfo;
