const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: "2rem",
    height: "2rem",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    height: "2rem",
    padding: "0 6px",
  }),

  input: (baseStyles, state) => ({
    ...baseStyles,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    height: "2rem",
  }),

  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: (base) => ({
    ...base,
    fontSize: "11px",
  }),
  option: (base) => ({
    ...base,
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
  }),
};

const customStylesForm = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: "8.4mm",
    height: "8.4mm",
    boxShadow: state.isFocused ? null : null,
    borderColor: state.hasValue ? "#4BBF73" : "#CED4DA",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: "200px", // your desired height
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    height: "8.4mm",
    padding: "0 6px 1px",
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    height: "8.4mm",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

// Color styles
const badge = (color = "transparent") => ({
  backgroundColor: color,
  borderRadius: "4px",
  color: color === "blue" ? "white" : "black",
  display: "inline-block",
  minHeight: "1.5rem",
  height: "1.5rem",
});

export { customStyles, customStylesForm };
