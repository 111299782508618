import React from "react";
import { Badge, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { EightColumns } from "../../../../../../components/common/tableLoading";
import usePalette from "../../../../../../hooks/usePalette";
import { ms } from "date-fns/locale";
// Icons
import Icon from "@mdi/react";
import { mdiFile, mdiAttachment, mdiAccount } from "@mdi/js";
import { MdDelete, MdEdit } from "react-icons/md";
// Redux
import { removeReport } from "../../../../../../redux/slices/reports/projectReportSlice";
import { MONTH_OPTIONS, PROGRESS_STATUS } from "../../../../../../constants";

const Reports = ({ handleItemReport }) => {
  const palette = usePalette();
  const dispatch = useDispatch();
  let idx = 0;
  const { project, isLoading, user } = useSelector((state) => ({
    project: state.reportReducer.project,
    isLoading: state.reportReducer.isLoading.report,
    user: state.userReducer.user,
  }));

  const { reports } = project;

  const handleRemoveItem = async (item) => {
    await dispatch(removeReport(item));
  };

  const isSuperadmin = user.roles.includes("superadmin");

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table hover size='sm'>
          <thead
            className='custom-table-head text-uppercase'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th className='text-center'>No.</th>
              <th>Laporan Bulan</th>
              <th className='text-center'>Kemajuan Semasa (%)</th>
              <th className='text-center'>Status</th>
              <th>Tarikh Dikemas Kini</th>
              <th className='text-center'>Dikemas Kini Oleh</th>
              <th>Lampiran</th>
              <th></th>
            </tr>
          </thead>
          {isEmpty(reports) ? (
            <tbody>
              <tr>
                <td colSpan={8} className='text-center'>
                  Maklumat tidak tersedia
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              {isLoading ? (
                <EightColumns />
              ) : (
                <tbody>
                  <>
                    {reports
                      ?.toSorted((a, b) => b.month - a.month)
                      .map((item) => {
                        const { attachments } = item;

                        let badgeColor = null;
                        let showBadge = null;

                        switch (attachments.length) {
                          case 0:
                            badgeColor = palette.secondary;
                            showBadge = false;
                            break;

                          default:
                            badgeColor = palette.info;
                            showBadge = true;
                            break;
                        }

                        const sumProgressNumber =
                          item.project_scope_progress_reports.reduce(
                            (acc, obj) => acc + parseFloat(obj.progress),
                            0,
                          );

                        const currentStatus = item.current_progress_status;

                        return (
                          <React.Fragment key={item.id}>
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() => handleItemReport(item)}
                            >
                              <td
                                className='text-center'
                                style={{ fontWeight: 700 }}
                              >
                                {item.is_sent ? (
                                  <span>{++idx}</span>
                                ) : (
                                  <Badge className='bg-secondary px-2 text-uppercase'>
                                    Draf
                                  </Badge>

                                  // <Tooltip
                                  //   title={
                                  //     <span style={{ fontSize: 10 }}>DRAF</span>
                                  //   }
                                  //   placement='right'
                                  //   arrow
                                  // >
                                  //   <span>
                                  //     <Icon path={mdiFile} size={1} />
                                  //   </span>
                                  // </Tooltip>
                                )}
                              </td>
                              <td>
                                {!isEmpty(item.sent_at) ? (
                                  <span className='text-uppercase'>
                                    <span className='me-1'>
                                      {
                                        MONTH_OPTIONS.find(
                                          (obj) =>
                                            obj.no.toString() ===
                                            item.month.toString(),
                                        ).value
                                      }
                                    </span>
                                    <span>{item.year}</span>
                                  </span>
                                ) : (
                                  "Maklumat tidak tersedia"
                                )}
                              </td>
                              <td className='text-center'>
                                <span style={{ fontWeight: 700 }}>
                                  {item?.progress || "-"}
                                </span>
                              </td>
                              <td className='text-center'>
                                <Badge
                                  bg=''
                                  className={`container-fluid w-100 text-uppercase`}
                                  style={{
                                    backgroundColor: currentStatus?.color,
                                  }}
                                >
                                  {currentStatus?.label}
                                </Badge>
                              </td>
                              <td>
                                <span>
                                  {!isEmpty(item.updated_at)
                                    ? format(
                                        new Date(item.updated_at),
                                        "dd-MM-yyyy, hh:mm a",
                                      )
                                    : "-"}
                                </span>
                              </td>
                              <td className='text-center text-truncate'>
                                {item.updated_by?.name && (
                                  <>
                                    <Icon path={mdiAccount} size='18px' />
                                    <span className='ms-1'>
                                      {item.updated_by?.name}
                                    </span>
                                  </>
                                )}
                              </td>
                              <td className='text-center'>
                                <div className='d-inline-flex position-relative'>
                                  <Icon
                                    path={mdiAttachment}
                                    color={badgeColor}
                                    size={1}
                                  />
                                  {showBadge && (
                                    <>
                                      <Badge
                                        className='position-absolute badge-soft-info'
                                        style={{ top: -3, left: 18 }}
                                        pill
                                        bg=''
                                      >
                                        <span
                                          style={{
                                            fontSize: 9,
                                            fontWeight: 700,
                                          }}
                                        >
                                          {attachments?.length}
                                        </span>
                                      </Badge>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td
                                className='px-0'
                                onClick={(e) => e.stopPropagation()}
                              >
                                {/* {(!item.is_sent || isSuperadmin) && ( */}
                                <div className='d-flex justify-content-center'>
                                  <IconButton
                                    size='small'
                                    onClick={() => handleRemoveItem(item)}
                                  >
                                    <MdDelete
                                      fontSize='large'
                                      color={palette.danger}
                                    />
                                  </IconButton>
                                </div>
                                {/* )} */}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                  </>
                </tbody>
              )}
            </>
          )}
        </Table>
      </div>
    </React.Fragment>
  );
};

export default Reports;
