import React, { useEffect, useState } from "react";
import Content from "./components/Content";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiBullhorn, mdiPlus } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";

const PageContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showButton, setShowButton] = useState(false);

  const handleOpenForm = (type) => {
    navigate(`/pengumuman/${type}`);
  };

  const { user } = useSelector((state) => ({
    user: state.userReducer.user,
  }));

  const handleEditAnnouncement = (item) => {
    navigate(`/pengumuman/ubah/${item.uuid}`);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      const isSuperadminOrAdmin =
        user.roles.includes("superadmin") || user.roles.includes("admin");
      setShowButton(isSuperadminOrAdmin);
    }
  }, [user]);

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between'>
        <div className='d-inline-flex'>
          <Icon className='me-2' path={mdiBullhorn} size={1} />
          <h1 className='h3 mb-3 text-uppercase'>Pengumuman</h1>
        </div>
        <div>
          {showButton && (
            <Button variant='secondary' onClick={() => handleOpenForm("baru")}>
              <Icon path={mdiPlus} size={0.8} />
              <span className='ms-1'>Pengumuman</span>
            </Button>
          )}
        </div>
      </div>
      <div className='d-flex flex-column align-items-center'>
        <Content handleEditAnnouncement={handleEditAnnouncement} />
      </div>
    </React.Fragment>
  );
};

export default PageContent;
