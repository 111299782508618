import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

// Redux
import {
  setSearchDepartments,
  setSearchMainProjects,
  setResetSearchInput,
} from "../../../../redux/slices/projects/projectsSlice";

const MoreSearchField = ({
  showCanvas,
  closeCanvas,
  selectedMinistries,
  rmkValue,
  handleChangeRmk,
}) => {
  const dispatch = useDispatch();
  // const [rmkValue, setRmkValue] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [ministryValues, setMinistryValues] = useState([]);
  const [departmentValues, setDepartmentValues] = useState([]);
  const [programValues, setProgramValues] = useState([]);
  const [mainProjectOptions, setMainProjectOptions] = useState([]);
  const [mainProjectValues, setMainProjectValues] = useState([]);

  const {
    rmkOptions,
    ministries,
    programs,
    mainProjects,
    projects,
    searchMinistries,
  } = useSelector((state) => ({
    rmkOptions: state.utilsReducer.dropdownItems.rmkItems,
    ministries: state.utilsReducer.dropdownItems.ministries,
    programs: state.utilsReducer.dropdownItems.programs,
    mainProjects: state.utilsReducer.dropdownItems.mainProjects,

    projects: state.projectReducer.projects,

    searchMinistries: state.projectReducer.searchMinistries,
  }));

  const handleClose = () => {
    closeCanvas();
  };

  const handleSearch = () => {
    const departmentIds = departmentValues.map((item) => item.id);
    const mainProjectIds = mainProjectValues.map((item) => item.id);
    dispatch(setSearchDepartments(departmentIds));
    dispatch(setSearchMainProjects(mainProjectIds));
  };

  const handleResetField = () => {
    setMinistryValues([]);
    setDepartmentValues([]);
    dispatch(setResetSearchInput());
  };

  const handleFilterMinistry = (value) => {
    const departmentOptions = value.reduce((acc, ministry) => {
      return acc.concat(ministry.departments);
    }, []);

    setDepartments(departmentOptions);
    setMinistryValues(value);
  };

  const handleFilterDepartment = (value) => {
    setDepartmentValues(value);
  };

  const handleFilterMainProject = (value) => {
    setMainProjectValues(value);
  };

  useEffect(() => {
    if (!isEmpty(ministries)) {
      const departmentItems = ministries.flatMap((item) => item.departments);

      let deptOptions = [];
      let mainPOptions = [];

      if (isEmpty(searchMinistries)) {
        deptOptions = departmentItems;
        mainPOptions = mainProjects;
      } else {
        deptOptions = departmentItems.filter((item) =>
          searchMinistries.includes(item.ministry_code),
        );
        const findPrograms = programs.filter((item) =>
          searchMinistries.includes(item.ministry_code.toString()),
        );

        mainPOptions = mainProjects.filter((item) =>
          findPrograms.map((obj) => obj.id).includes(item.program_id),
        );
      }

      setDepartments(deptOptions);
      setMainProjectOptions(mainPOptions);
    }
  }, [ministries, searchMinistries]);

  return (
    <Offcanvas show={showCanvas} onHide={handleClose} style={{ width: 310 }}>
      <Offcanvas.Body>
        <Offcanvas.Title className='mb-3'>Carian Tambahan</Offcanvas.Title>
        <Form autoComplete='off' noValidate style={{ fontSize: 12 }}>
          <Form.Group className='mb-3'>
            <Form.Label>RMK</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              options={rmkOptions}
              value={rmkValue}
              onChange={handleChangeRmk}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Jabatan/Agensi</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              isMulti
              options={departments}
              value={departmentValues}
              onChange={(value) => handleFilterDepartment(value)}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Projek Utama</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              isMulti
              options={mainProjectOptions}
              value={mainProjectValues}
              onChange={(value) => handleFilterMainProject(value)}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>

          <div className='d-flex flex-column gap-3'>
            <Button
              variant='primary'
              className='flex-fill'
              onClick={handleSearch}
            >
              Cari
            </Button>
            <Button
              variant='light'
              className='flex-fill'
              onClick={handleResetField}
            >
              Set Semula
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MoreSearchField;
