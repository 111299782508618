import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchMinistries = createAsyncThunk(
  "ministry/fetchMinistries",
  async (params) => {
    const response = await axios.get(`/ministries`, {
      params,
    });
    const data = await response.data;

    return data;
  },
);

export const createMinistry = createAsyncThunk(
  "ministry/createMinistry",
  async (ministry) => {
    const response = await axios.post(`/ministries`, ministry);

    const data = await response.data;

    return data;
  },
);

export const updateMinistry = createAsyncThunk(
  "ministry/updateMinistry",
  async (ministry) => {
    const response = await axios.put(`/ministries/${ministry.id}`, ministry);

    const data = await response.data;

    return data;
  },
);

export const removeMinistry = createAsyncThunk(
  "ministry/removeMinistry",
  async (id) => {
    const response = await axios.delete(`/ministries/${id}`);

    return id;
  },
);

const initialState = {
  ministries: [],
  isLoading: false,
};

export const ministrySlice = createSlice({
  name: "ministry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMinistries.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMinistries.fulfilled, (state, action) => {
      state.ministries = action.payload.data;
      state.isLoading = true;
    });

    builder.addCase(createMinistry.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createMinistry.fulfilled, (state, action) => {
      state.ministries.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateMinistry.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.ministries = state.ministries.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.isLoading = false;
    });
    builder.addCase(removeMinistry.fulfilled, (state, action) => {
      state.ministries = state.ministries.filter(
        (item) => item.id !== action.payload,
      );
    });
  },
});

export default ministrySlice.reducer;
