import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchMainProjects = createAsyncThunk(
  "mainProjects/fetchMainProjects",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(`/main-projects`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createMainProject = createAsyncThunk(
  "mainProjects/createMainProject",
  async (values) => {
    const response = await axios.post(`/main-projects`, values);

    const data = await response.data;

    return data;
  },
);

export const updateMainProject = createAsyncThunk(
  "mainProjects/updateMainProject",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/main-projects/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeMainProjects = createAsyncThunk(
  "mainProjects/removeMainProjects",
  async (id) => {
    const response = await axios.delete(`/main-projects/${id}/delete`);

    const data = await response.data;

    return id;
  },
);

export const getLastCode = createAsyncThunk(
  "mainProjects/getLastCode",
  async (params) => {
    const response = await axios.get(`/main-projects/get-last-code`, {
      params,
    });

    const data = await response.data;

    return data;
  },
);

const initialState = {
  mainProjects: [],
  lastCode: "",
  meta: {},
  links: {},
  isLoading: false,
  btnLoading: false,
};

export const mainProjectsSlice = createSlice({
  name: "mainProjects",
  initialState,
  reducers: {
    resetLastCode: (state) => {
      state.lastCode = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMainProjects.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMainProjects.fulfilled, (state, action) => {
      state.mainProjects = action.payload.data;
      state.meta = action.payload.meta;
      state.links = action.payload.links;
      state.isLoading = false;
    });

    builder.addCase(getLastCode.pending, (state) => {
      state.btnLoading = true;
    });

    builder.addCase(getLastCode.fulfilled, (state, action) => {
      state.lastCode = action.payload;
      state.btnLoading = false;
    });

    builder.addCase(createMainProject.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createMainProject.fulfilled, (state, action) => {
      state.mainProjects.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateMainProject.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateMainProject.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.mainProjects = state.mainProjects.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeMainProjects.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeMainProjects.fulfilled, (state, action) => {
      state.mainProjects = state.mainProjects.filter(
        (item) => item.id !== action.payload,
      );
      state.btnLoading = false;
    });
  },
});

export const { resetLastCode } = mainProjectsSlice.actions;

export default mainProjectsSlice.reducer;
