import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getCompletedProjectsWithCeilingBalance = createAsyncThunk(
  "jppnApplyNewProjectSlice/getCompletedProjectsWithCeilingBalance",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(
        `jppn/get-completed-projects-with-ceiling-balance`,
        {
          params,
          cancelToken: source.token,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getNewProjectApplications = createAsyncThunk(
  "jppnApplyNewProjectSlice/getNewProjectApplications",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/apply-new-projects`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createNewProjectApplication = createAsyncThunk(
  "jppnApplyNewProjectSlice/createNewProjectApplication",
  async (values) => {
    try {
      const response = await axios.post(`jppn/apply-new-projects`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const updateNewProjectApplication = createAsyncThunk(
  "jppnApplyNewProjectSlice/updateNewProjectApplication",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`jppn/apply-new-projects/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getNewProjectApplication = createAsyncThunk(
  "jppnApplyNewProjectSlice/getNewProjectApplication",
  async (props) => {
    try {
      const { id, source } = props;

      const response = await axios.get(`jppn/apply-new-projects/${id}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const deleteNewProjectApplication = createAsyncThunk(
  "jppnApplyNewProjectSlice/deleteNewProjectApplication",
  async (id) => {
    try {
      const response = await axios.delete(`jppn/apply-new-projects/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
