import React from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Col, Row } from "react-bootstrap";

// components
import RevisedAllocation from "./components/cards/RevisedAllocation";
import LatestExpenditure from "./components/cards/LatestExpenditure";
import ProjectsProgressStatus from "./components/cards/ProjectsProgressStatus";
import ProjectsProgressStatusChart from "./components/charts/ProjectsProgressStatusChart";

const CombineReport = () => {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };
  const itemTop = {
    hidden: { opacity: 0, y: -20 },
    show: { opacity: 1, y: 0 },
  };
  const itemLeft = {
    hidden: { opacity: 0, x: -30 },
    show: { opacity: 1, x: 0 },
  };

  return (
    <React.Fragment>
      <motion.div variants={container} initial='hidden' animate='show'>
        <Row>
          <Col md={6} className='d-flex'>
            <div className='flex-fill w-100'>
              <RevisedAllocation itemLeft={itemLeft} />
              <LatestExpenditure itemLeft={itemLeft} />
            </div>
          </Col>
          <Col md={6} className='d-flex'>
            <ProjectsProgressStatus itemLeft={itemLeft} />
          </Col>
        </Row>
        <ProjectsProgressStatusChart />
      </motion.div>
    </React.Fragment>
  );
};

export default CombineReport;
