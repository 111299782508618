import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Redux
import {
  createUpenSection,
  updateUpenSection,
} from "../../../../../redux/slices/maintenance/upenSectionsSlice";
import { fetchMinistries } from "../../../../../redux/slices/government-structure/ministrySlice";

const UpenSectionForm = ({
  showForm,
  closeForm,
  isEdit,
  data,
  handleNotification,
}) => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const [selectedMinistries, setSelectedMinistries] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const { btnLoading, ministries, upenOfficers } = useSelector((state) => ({
    ministries: state.ministryReducer.ministries,
    btnLoading: state.departmentReducer.btnLoading,
    upenOfficers: state.utilsReducer.dropdownItems.upenOfficers,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      code: data.code || "",
      ministries: data.ministries || null,
      users: data.users || null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Sila isi nama seksyen"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          name: values.name,
          code: values.code,
          users: values.users,
          ministries: values.ministries,
        };

        await dispatch(updateUpenSection(newValues)).then((res) => {
          const data = res.payload;
          handleNotification("edited", data);
        });
      } else {
        const newValues = {
          name: values.name,
          code: values.code,
          users: values.users,
          ministries: values.ministries,
        };
        await dispatch(createUpenSection(newValues)).then((res) => {
          const data = res.payload;
          handleNotification("created", data);
        });
      }
      handleCloseModal();
    },
  });

  const handleSelectMinistry = (ministry) => {
    const { id } = ministry;

    const isExisted = selectedMinistries.includes(id);

    if (isExisted) {
      setSelectedMinistries(selectedMinistries.filter((item) => item !== id));
    } else {
      const values = [...selectedMinistries, id];

      setSelectedMinistries(values);
    }
  };

  const handleSelectDepartment = (dept) => {
    setSelectedDepartment(dept);
  };

  useEffect(() => {
    dispatch(fetchMinistries());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(ministries)) {
      const result = ministries.map((ministry) => {
        const newValues = {
          ...ministry,
          label: ministry.short_form,
          value: ministry.name,
        };
        return newValues;
      });

      setOptions(result);
    }
  }, [ministries]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const newValue = {
        ...data.ministry,
        label: data.ministry?.short_form,
        value: data.ministry?.name,
      };
      formik.setFieldValue("ministry", newValue);
    }
  }, [data]);

  useEffect(() => {
    if (!btnLoading) {
      handleCloseModal();
    }
  }, [btnLoading]);

  const handleCloseModal = () => {
    closeForm();
    setSelectedDepartment([]);
    setSelectedMinistries([]);
    formik.resetForm();
  };

  return (
    <Modal size='lg' show={showForm} onHide={handleCloseModal} centered>
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Seksyen</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Nama Seksyen</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.name && !formik.errors.name ? true : false
              }
              isInvalid={
                formik.touched.name && formik.errors.name ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Singkatan</Form.Label>
            <Form.Control
              type='text'
              name='code'
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.code && !formik.errors.code ? true : false
              }
              isInvalid={
                formik.touched.code && formik.errors.code ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.code}
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className='mb-3'>
            <Form.Label>Kementerian</Form.Label>
            <Select
              name='ministries'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              isMulti
              options={options}
              styles={customStylesForm}
              placeholder='Sila pilih kementerian...'
              noOptionsMessage={() => "Tiada Pilihan"}
              value={formik.values.ministries}
              onChange={(value) => formik.setFieldValue("ministries", value)}
            />
            {formik.errors.ministries && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.ministries}
              </p>
            )}
          </Form.Group> */}

          <Form.Group className='mb-3'>
            <Form.Label>Pegawai Bertugas</Form.Label>
            <Select
              name='users'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              isMulti
              options={upenOfficers}
              styles={customStylesForm}
              placeholder='Pilih pegawai bertugas...'
              noOptionsMessage={() => "Tiada Pilihan"}
              value={formik.values.users}
              onChange={(value) => formik.setFieldValue("users", value)}
            />
            {formik.errors.users && (
              <p className='mt-1 custom-feedback-invalid'>
                {formik.errors.users}
              </p>
            )}
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              variant='light'
              onClick={handleCloseModal}
              disabled={btnLoading}
            >
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpenSectionForm;
