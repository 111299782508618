import React, { useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../utils/axios";
import { isEmpty } from "lodash";

// redux
import {
  setBendahari,
  setUpdateBendahari,
} from "../../../redux/slices/utilsSlice";

const TableUlitities = () => {
  const dispatch = useDispatch();

  const { ministries, bendahari } = useSelector((state) => ({
    ministries: state.utilsReducer.dropdownItems.ministries,
    bendahari: state.utilsReducer.bendahari,
  }));

  const handleGetDataFromBendahari = async (item) => {
    const loadingData = {
      ...item,
      isDisabled: true,
      api_status: "4",
    };

    await dispatch(setUpdateBendahari(loadingData));

    const values = {
      year: new Date().getFullYear(),
      ministry_code: item.code,
    };

    try {
      const response = await axios.post(`/acc-api-sub-project/store`, values);
      const data = await response.data;

      const updatedData = {
        ...item,
        isDisabled: false,
        api_status: "2",
      };

      await dispatch(setUpdateBendahari(updatedData));
    } catch (error) {
      const updatedData = {
        ...item,
        isDisabled: false,
        api_status: "3",
      };
      await dispatch(setUpdateBendahari(updatedData));
    }
  };

  const updateFullCode = async () => {
    try {
      const response = await axios.post(`/projects/full-code/store`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isEmpty(ministries)) {
      const newArray = ministries.map((item) => ({
        ...item,
        api_status: "1",
        isDisabled: false,
      }));
      dispatch(setBendahari(newArray));
    }
  }, [ministries]);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table bordered size='sm'>
          <tbody>
            <tr style={{ backgroundColor: "#cfd8dc", fontWeight: 700 }}>
              <td />
              <td colSpan={5}>Get Data from Jabatan Bendahari System (S3P)</td>
            </tr>
            {bendahari?.map((item, idx) => {
              let text = "GET";
              let color = "primary";
              switch (item.api_status) {
                case "2":
                  text = "Success";
                  color = "success";
                  break;

                case "3":
                  text = "Error";
                  color = "danger";
                  break;

                case "4":
                  text = "Loading";
                  color = "warning";
                  break;

                default:
                  break;
              }
              return (
                <tr key={item.code}>
                  <td className='text-center'>{idx + 1}</td>
                  <td className=''>{item.value}</td>
                  <td className='text-center'>
                    <Badge bg={color}>{text}</Badge>
                  </td>
                  <td className='text-center'>
                    <Button
                      size='sm'
                      onClick={() => handleGetDataFromBendahari(item)}
                      disabled={item.isDisabled}
                    >
                      GET DATA
                    </Button>
                  </td>
                </tr>
              );
            })}
            <tr style={{ backgroundColor: "#cfd8dc", fontWeight: 700 }}>
              <td />
              <td colSpan={5}>Generate full code</td>
            </tr>
            <tr>
              <td></td>
              <td>Generate Project Full Code</td>
              <td className='text-center'>
                <Badge bg='primary'>TEST</Badge>
              </td>
              <td className='text-center'>
                <Button
                  size='sm'
                  onClick={updateFullCode}
                  // disabled={item.isDisabled}
                >
                  GET DATA
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default TableUlitities;
