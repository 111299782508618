import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";

// Components
import ProjectCodeList from "./components/tables/ProjectCodeList";
import MinistryOptions from "./components/filters/MinistryOptions";

// Redux
import { fetchMinistries } from "../../../redux/slices/others/projectCodeSlice";
import { getOptions } from "../../../redux/slices/utilsSlice";
import EditForm from "./components/modals/EditForm";

const MainPage = () => {
  const dispatch = useDispatch();

  const [selectedMinistries, setSelectedMinistries] = useState(() => []);
  const [openForm, setOpenForm] = useState(false);
  const [data, setData] = useState(null);

  const handleSelectMinistries = (e, ministry) => {
    setSelectedMinistries(ministry);
  };

  const handleEditItem = (items) => {
    setData(items);
    setOpenForm(true);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
      params: {
        ministry_ids: selectedMinistries,
      },
    };

    const initialize = async () => {
      await dispatch(fetchMinistries(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch, selectedMinistries]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
      };

      await dispatch(getOptions(props));
    };

    initialize();
    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <EditForm
        showForm={openForm}
        hideForm={() => {
          setData(null);
          setOpenForm(false);
        }}
        data={data}
      />
      <Container fluid className='p-0'>
        <MinistryOptions
          handleSelectMinistries={handleSelectMinistries}
          selectedMinistries={selectedMinistries}
        />
        <Card>
          <Card.Body>
            <ProjectCodeList handleEditItem={handleEditItem} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
