import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { isEmpty, debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import axios from "axios";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import ModalDelete from "../../../components/common/ModalDelete";
import deepSearchFunction from "../../../components/common/SearchFunction";
import LoadingState from "../../../components/common/LoadingState";
import Users from "./components/tables/Users";

// API CALL
import {
  fetchUsers,
  removeUser,
  setSearchUser,
  updateUser,
  setKeywords,
  setPage,
} from "../../../redux/slices/usersSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showRemove, setShowRemove] = useState(false);
  const [item, setItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [notActive, setNotActive] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  const [isSearching, setIsSearching] = useState(true);

  const { page, perPage, keywords, users, btnLoading } = useSelector(
    (state) => ({
      btnLoading: state.usersReducer.btnLoading,
      users: state.usersReducer.users,
      page: state.usersReducer.page,
      perPage: state.usersReducer.perPage,
      keywords: state.usersReducer.keywords,
    }),
  );

  const handleSearch = debounce((text) => {
    dispatch(setSearchUser(text));
  }, 200);

  const handleAddUser = () => {
    navigate("/admin/pengguna/baru");
  };

  const handleEditUser = (user) => {
    navigate(`/admin/pengguna/kemaskini/${user.id}`);
  };

  const handleResetState = () => {
    setIsEdit(false);
    setItem(null);
  };

  const handleRemoveUser = (user) => {
    setShowRemove(true);
    setItem(user);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    handleResetState();
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeUser(data));
    handleCloseRemove();
  };

  const handleViewNotActive = () => {
    if (!notActive) {
      setNotActive(true);
    } else {
      setNotActive(false);
    }
  };

  const handleActivatedUser = (user) => {
    const newValue = {
      ...user,
      is_deleted: !user.is_deleted,
      group_role: user.group_role.id,
    };
    dispatch(updateUser(newValue));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source.token,
        params: {
          page: page,
          per_page: perPage,
          keywords: keywords,
          not_active: notActive,
        },
      };

      await dispatch(fetchUsers(props));
    };

    initialize();

    setGoToPage(page);

    return () => {
      source.cancel();
    };
  }, [dispatch, page, perPage, notActive, keywords]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
        module='user'
      />

      <Helmet title='Senarai Pengguna' />
      <Container fluid className='p-0'>
        <Breadcrumbs title='Sistem Admin' breadcrumbItem='Senarai Pengguna' />
        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleAddUser}>Daftar Pengguna</Button>
            </div>
            <div className='d-flex align-items-center gap-3'>
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari...'
                      value={keywords}
                      onChange={(e) => {
                        dispatch(setKeywords(e.target.value));
                        dispatch(setPage(1));
                      }}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>
              <div>
                <Tooltip title='Cari' arrow>
                  <Button
                    variant='secondary'
                    onClick={() => setIsSearching(true)}
                  >
                    <MdSearch className='search-icon-2' size={18} />
                  </Button>
                </Tooltip>
              </div>
              <div>
                <Tooltip title='Tunjuk pengguna tidak aktif' arrow>
                  <Button
                    variant={`${notActive ? "danger" : "outline-danger"}`}
                    onClick={handleViewNotActive}
                  >
                    Tidak Aktif
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Users
              handleRemoveUser={handleRemoveUser}
              handleEditUser={handleEditUser}
              handleActivatedUser={handleActivatedUser}
              goToPage={goToPage}
              setGoToPage={setGoToPage}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
