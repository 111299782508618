import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import axios from "axios";

//Styles
import { customStylesForm } from "../../../components/common/customStyleSelect";
import NotyfContext from "../../../contexts/NotyfContext";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

// Components
import Programs from "./components/tables/Programs";
import ProgramForm from "./components/modals/ProgramForm";
import MoreSearchField from "./components/MoreSearchField";
import ModalDelete from "../../../components/common/ModalDelete";

// Redux
import {
  fetchPrograms,
  removeProgram,
} from "../../../redux/slices/rmk-information/programsSlice";
import { fetchRmkList } from "../../../redux/slices/rmk-information/rmkSlice";
import {
  getMinistries,
  getDepartments,
  getRmkItems,
  getOptions,
} from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [page, setPage] = useState(1);
  const [keywords, setKeywords] = useState("");
  const [departmentId, setDepartmentId] = useState([]);
  const [ministryId, setMinistryId] = useState([]);
  const [categoryProgram, setCategoryProgram] = useState("");

  const [rmkFilter, setRmkFilter] = useState(null);
  const [searchFields, setSearchFields] = useState(null);

  const { programs, btnLoading, rmkList, rmkItems } = useSelector((state) => ({
    programs: state.programReducer.programs,
    btnLoading: state.programReducer.btnLoading,
    rmkList: state.rmkReducer.rmkList,
    rmkItems: state.utilsReducer.dropdownItems.rmkItems,
  }));

  const handleCloseForm = () => {
    setOpenForm(false);
    handleResetState();
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleHideCanvas = () => {
    setShowCanvas(false);
  };

  const handleResetState = () => {
    setIsEdit(false);
    setItem({});
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    handleResetState();
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeProgram(data.id));
    handleNotification("error", data);
  };

  const goToPage = (value) => {
    setPage(value);
  };

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToLastPage = (page) => {
    setPage(page);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const handleSearch = debounce((text) => {
    setKeywords(text);
  }, 800);

  const handleSearchCategory = debounce((category) => {
    setCategoryProgram(category);
  }, 500);

  const handleFilterRmk = (value) => {
    setRmkFilter(value);
  };

  const handleSearchMoreField = (values) => {
    setSearchFields(values);
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    if (!btnLoading) {
      handleCloseRemove();
    }
  }, [btnLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
      params: {
        page: page,
        keywords: keywords,
        rmk_id: rmkFilter?.number,
        ministry_ids: searchFields?.ministries_code || [],
      },
    };

    Promise.all([dispatch(fetchRmkList()), dispatch(fetchPrograms(props))])
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    return () => {
      source.cancel();
    };
  }, [dispatch, page, keywords, rmkFilter, searchFields]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };
    // dispatch(getMinistries(props));
    // dispatch(getDepartments(props));
    // dispatch(getRmkItems(props));

    dispatch(getOptions(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <ProgramForm
        showForm={openForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        rmkList={rmkList}
        btnLoading={btnLoading}
        data={item}
        handleNotification={handleNotification}
        programs={programs}
      />
      <MoreSearchField
        showCanvas={showCanvas}
        closeCanvas={handleHideCanvas}
        searchMoreField={handleSearchMoreField}
      />

      <Helmet title='Senarai Program' />
      <Container fluid className='p-0'>
        <Breadcrumbs title='Maklumat RMK' breadcrumbItem='Senarai Program' />

        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Program
              </Button>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <Tooltip title='Lebih Pilihan' placement='top' arrow>
                <Button variant='secondary' onClick={handleShowCanvas}>
                  <Icon path={mdiFilterVariant} size={0.8} />
                </Button>
              </Tooltip>
              <Form.Group>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  isClearable
                  options={rmkItems}
                  styles={customStylesForm}
                  placeholder='Pilih RMK...'
                  value={rmkFilter}
                  onChange={(value) => handleFilterRmk(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                />
              </Form.Group>

              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari Nama Program...'
                      // value={searchText}
                      onChange={(e) => handleSearch(e.target.value)}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Programs
              programs={programs}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              page={page}
              goToPage={goToPage}
              goToFirstPage={goToFirstPage}
              goToLastPage={goToLastPage}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
