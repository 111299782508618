import axios from "../../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchUsers = createAsyncThunk(
  "userManagement/fetchUsers",
  async (props) => {
    try {
      const { params, source } = props;

      const response = await axios.get(`/user-management`, {
        cancelToken: source.token,
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

export const newUser = createAsyncThunk(
  "userManagement/newUser",
  async (values) => {
    try {
      const response = await axios.post(`/user-management`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  },
);

export const userRegistrationResponse = createAsyncThunk(
  "userManagement/userRegistrationResponse",
  async (values) => {
    try {
      const response = await axios.post(`/user-registration-response`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  },
);

export const removeUser = createAsyncThunk(
  "userManagement/removeUser",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.delete(`/user-management/${id}`);

      const data = await response.data;

      return values;
    } catch (error) {
      console.error("Error:", error);
    }
  },
);
