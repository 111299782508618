import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const MinistryOptions = ({ handleSelectMinistries, selectedMinistries }) => {
  const { ministries } = useSelector((state) => ({
    ministries: state.utilsReducer.dropdownItems.ministries,
  }));

  return (
    <Card className='mb-3'>
      <Card.Header className='pb-0 px-3'>
        Sila pilih kod kementerian untuk melihat senarai projek dibawah
        kementerian tersebut.
      </Card.Header>
      <Card.Body className='p-2'>
        <StyledToggleButtonGroup
          className='d-flex'
          size='small'
          value={selectedMinistries}
          aria-label='select ministries'
          onChange={handleSelectMinistries}
        >
          {ministries?.map((item) => (
            <ToggleButton
              key={item.code}
              className='flex-fill fw-bolder'
              value={item.code}
              color='primary'
            >
              <div>
                <div className='m-0'>D{item.code}</div>
                <div className='m-0'>{item.short_form}</div>
              </div>
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Card.Body>
    </Card>
  );
};

export default MinistryOptions;
