import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getFinancialReport = createAsyncThunk(
  "dashboard/overallReport/getFinancialReport",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`dashboard/financial-report/get`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getOverallExpenditure = createAsyncThunk(
  "dashboard/overallReport/getOverallExpenditure",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`dashboard/overall-expenditure/get`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getFinancialReportByMinistries = createAsyncThunk(
  "dashboard/overallReport/getFinancialReportByMinistries",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/financial-report-by-ministries/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getFinancialReportByDepartments = createAsyncThunk(
  "dashboard/overallReport/getFinancialReportByDepartments",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/financial-report-by-departments/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getFinancialReportByPrograms = createAsyncThunk(
  "dashboard/overallReport/getFinancialReportByPrograms",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/financial-report-by-programs/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getFinancialReportByMainProjects = createAsyncThunk(
  "dashboard/overallReport/getFinancialReportByMainProjects",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/financial-report-by-main-projects/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProjectsProgressStatus = createAsyncThunk(
  "dashboard/overallReport/getProjectsProgressStatus",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `/dashboard/projects-progress-status/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProjects = createAsyncThunk(
  "dashboard/overallReport/getProjects",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`/dashboard/projects/get`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
