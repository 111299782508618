import { configureStore, combineReducers } from "@reduxjs/toolkit";

import userReducer from "./slices/userSlice";

// DASHBOARD
import dashboardReducer from "./slices/dashboard/dashboardSlice";

// Projects
import projectReducer from "./slices/projects/projectsSlice";
import projectStatisticsReducer from "./slices/project-statistics/totalProjectSlice";

// Others
import ceilingBalanceReducer from "./slices/ceilingBalanceSlice";

// Users
import usersReducer from "./slices/usersSlice";

// Analysis Table
import analysisTableReducer from "./slices/projects/analysisTableSlice";

// Certify
import certifyReducer from "./slices/certify/certifySlice";

// RMK INFORMATION
import ceilingLimitReducer from "./slices/rmk-information/ceilingLimitsSlice";
import programReducer from "./slices/rmk-information/programsSlice";
import rmkReducer from "./slices/rmk-information/rmkSlice";
import mainSectorReducer from "./slices/rmk-information/stateMainSectorSlice";
import mainProjectReducer from "./slices/rmk-information/mainProjectsSlice";

// GOVERNMENT STRUCTURE
import ministryReducer from "./slices/government-structure/ministrySlice";
import departmentReducer from "./slices/government-structure/departmentSlice";
import branchReducer from "./slices/government-structure/branchSlice";
import executingAgencyReducer from "./slices/government-structure/executingAgencySlice";

// STATE INFORMATION
import parliamentReducer from "./slices/state-information/parliamentsSlice";
import stateLegislativeAssemblyReducer from "./slices/state-information/stateLegislativeAssembliesSlice";
import districtReducer from "./slices/state-information/districtsSlice";

//Dropdown Items
import dropdownReducer from "./slices/filter-list/dropdownItemsSlice";

// Maintenance
import projectScopeReducer from "./slices/maintenance/projectScopeSlice";
import upenSectionReducer from "./slices/maintenance/upenSectionsSlice";
import targetGroupReducer from "./slices/maintenance/targetGroupSlice";
import roleReducer from "./slices/maintenance/rolesSlice";
import sdgReducer from "./slices/maintenance/sustainableDevelopmentGoalsSlice";
import creditScoreCriteriaReducer from "./slices/maintenance/creditScoreCriteriaSlice";

// Utils
import utilsReducer from "./slices/utilsSlice";

// Reports
import reportReducer from "./slices/reports/projectReportsSlice";

// Activity Log
import activityReducer from "./slices/admin/activityLogSlice";

// Announcement
import announcementsReducer from "./slices/admin/announcementsSlice";

// Other
import projectCodeReducer from "./slices/others/projectCodeSlice";

// User Management
import userManagementReducer from "./slices/user-management/usersManagementSlice";

// S3P
import s3pReducer from "./slices/s3p/s3pDataSlice";

// JPPN
import jppnReducer from "./slices/jppn/jppnModuleSlice";

// Micro Project
import microProjectReducer from "./slices/micro-project/microProjectSlices";
import microProjectUserReducer from "./slices/admin-micro-project/usersSlice";

const reducer = combineReducers({
  userReducer,
  announcementsReducer,
  dashboardReducer,
  usersReducer,
  analysisTableReducer,
  certifyReducer,
  dropdownReducer,
  ceilingBalanceReducer,
  programReducer,
  mainProjectReducer,
  rmkReducer,
  ceilingLimitReducer,
  ministryReducer,
  departmentReducer,
  branchReducer,
  executingAgencyReducer,
  mainSectorReducer,
  projectReducer,
  reportReducer,
  projectStatisticsReducer,
  districtReducer,
  parliamentReducer,
  stateLegislativeAssemblyReducer,
  projectScopeReducer,
  upenSectionReducer,
  targetGroupReducer,
  creditScoreCriteriaReducer,
  roleReducer,
  sdgReducer,
  activityReducer,
  projectCodeReducer,
  userManagementReducer,
  utilsReducer,
  s3pReducer,
  jppnReducer,
  microProjectReducer,
  microProjectUserReducer,
});

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.REACT_APP_ENV !== "production",
});
