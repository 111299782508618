import React from "react";
import { Accordion, Card, Nav, Tab } from "react-bootstrap";

// tab pane
import EditProjectName from "./tab-content/jppn/EditProjectName";
import { isEmpty } from "lodash";
import ApplyNewProject from "./tab-content/jppn/ApplyNewProject";

const JPPNActivities = ({ project }) => {
  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>JPPN AKTIVITI</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <div className='tab mb-0'>
          <Tab.Container>
            <Nav variant='tabs' fill>
              <Nav.Item>
                <Nav.Link eventKey='apply-new-project'>
                  Penyenaraian Projek Baharu
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='edit-project-scopes'>
                  Perubahan Skop
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='edit-project-name'>Ubah Tajuk</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey='location-changes'>Ubah Lokasi</Nav.Link>
              </Nav.Item> */}
            </Nav>
            <Tab.Content>
              <ApplyNewProject eventKey='apply-new-project' project={project} />
              <EditProjectName eventKey='edit-project-name' project={project} />
            </Tab.Content>
          </Tab.Container>
        </div>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default JPPNActivities;
