import React, { useContext, useState, useRef } from "react";
import MainAccordion from "./MainAccordion";
import { Card } from "react-bootstrap";
// Styles
import NotyfContext from "../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiFileDocumentOutline, mdiMenuLeft } from "@mdi/js";

// Components
import ProjectInfo from "./ProjectInfo";
import InformationCard from "./InformationCard";
import ProjectProfile from "./modals/ProjectProfile";

const PageContent = ({ paginations, filters }) => {
  const notyf = useContext(NotyfContext);
  const scopeProjectRef = useRef(null);
  const ceilingFlowRef = useRef(null);

  const [showProjectProfile, setShowProjectProfile] = useState(false);

  const handleViewProject = () => {
    setShowProjectProfile(true);
  };

  const handleGoToScopeProject = () => {
    scopeProjectRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleGoToCeilingFlow = () => {
    ceilingFlowRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Notification
  const handleNotification = (values) => {
    const { type, message } = values;

    // Success
    notyf.open({
      type: type,
      message: message,
      duration: 3000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <React.Fragment>
      <ProjectInfo />
      <InformationCard />
      <ProjectProfile
        showProjectProfile={showProjectProfile}
        closeProjectProfile={() => setShowProjectProfile(false)}
      />
      <MainAccordion
        scopeProjectRef={scopeProjectRef}
        ceilingFlowRef={ceilingFlowRef}
        handleNotification={handleNotification}
        paginations={paginations}
        filters={filters}
      />
      <div className='position-fixed' style={{ top: 145, right: 0 }}>
        <div
          className='bg-info'
          style={{
            padding: "0.47rem",
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        >
          <div
            className='d-flex align-items-center certify-button'
            onClick={handleViewProject}
            style={{ fontSize: 12 }}
          >
            <Icon path={mdiFileDocumentOutline} size={1} />
            <span className='mt-2'>PROJEK PROFIL</span>
          </div>
        </div>
      </div>

      <div className='position-fixed' style={{ top: 325, right: 0 }}>
        <div
          className='bg-info'
          style={{
            padding: "0.56rem",
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        >
          <div
            className='d-flex align-items-center certify-button'
            onClick={handleGoToScopeProject}
            style={{ fontSize: 12 }}
          >
            <Icon path={mdiMenuLeft} size={0.7} />
            <span className='mt-1'>SKOP PROJEK</span>
          </div>
        </div>
      </div>

      <div className='position-fixed' style={{ top: 450, right: 0 }}>
        <div
          className='bg-info'
          style={{
            padding: "0.56rem",
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        >
          <div
            className='d-flex align-items-center certify-button'
            onClick={handleGoToCeilingFlow}
            style={{ fontSize: 12 }}
          >
            <Icon path={mdiMenuLeft} size={0.7} />
            <span className='mt-1'>ALIRAN SILING</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageContent;
