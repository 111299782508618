import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import usePalette from "../../../../../../hooks/usePalette";
import CurrencyInput from "react-currency-input-field";

// Icons
import Icon from "@mdi/react";
import { mdiMinusCircle, mdiNoteTextOutline, mdiCircle } from "@mdi/js";

const TableBody = ({
  showColumn,
  handleCostSingleId,
  checkedCostId,
  handleRemoveComponent,
  data,
  handleOpenNoteSection,
  handleInitialCost,
  costValue,
  errors,
  viewColumns,
}) => {
  const palette = usePalette();

  const { project } = useSelector((state) => ({
    project: state.certifyReducer.project.data,
  }));

  const totalRequestedCostByApplicant = project?.financial_details?.reduce(
    (total, obj) => {
      return (total += parseFloat(obj.cost_required, 0));
    },
    0,
  );

  const totalRequestedCeilingByApplicant = project?.financial_details?.reduce(
    (total, obj) => {
      return (total += parseFloat(obj.cost_required, 0));
    },
    0,
  );

  const handleCalculateTotalEdited = () => {
    const combinedChild = data.flatMap((obj) => obj.items);

    const totalEdited = combinedChild.reduce(
      (total, obj) => total + parseFloat(obj.amount),
      0,
    );

    const editedCeiling = data.flatMap((obj) => obj.items);

    const totalFinalCeiling = editedCeiling.reduce(
      (total, obj) => total + parseFloat(obj.amount),
      0,
    );

    let totalValues = {
      totalCost: 0,
      totalCeiling: totalFinalCeiling,
    };

    if (!isEmpty(costValue)) {
      const filteredData2 = combinedChild.filter(
        (item2) =>
          !costValue.some((item1) => item2.financial_detail_id === item1.id),
      );

      const sumInitialAmount = filteredData2.reduce(
        (sum, item) => sum + parseFloat(item.amount),
        0,
      );
      const sumEditedAmount = costValue.reduce(
        (sum, item) => sum + parseFloat(item.cost_required),
        0,
      );
      const combinedSum = sumInitialAmount + sumEditedAmount;

      return {
        ...totalValues,
        totalCost: combinedSum,
      };
    }

    return {
      ...totalValues,
      totalCost: totalEdited,
    };
  };

  const totalEditedValue = handleCalculateTotalEdited();

  const handleCertifyStages = (stages, status) => {
    const certifyStages = project?.financial_details?.flatMap(
      (obj) => obj.financial_detail_stages,
    );

    const findStages = certifyStages?.filter((obj) => {
      return (
        obj.stages === stages.toString() && obj.status === status.toString()
      );
    });

    const totalCost = findStages?.reduce(
      (total, obj) => total + parseFloat(obj.cost_required),
      0,
    );

    const ceilingStages = project?.financial_details
      ?.flatMap((obj1) => obj1.ceiling_flow_details)
      ?.flatMap((obj2) => obj2.ceiling_flow_detail_stages);

    const handleCalculateCeiling = (status) => {
      const sum = ceilingStages
        ?.filter(
          (item) =>
            item.status === status.toString() &&
            item.stages === stages.toString(),
        )
        ?.reduce(
          (accumulator, currentValue) =>
            parseFloat(accumulator) + parseFloat(currentValue.amount),
          0,
        );
      return sum;
    };

    const totalCeiling = handleCalculateCeiling(status);

    return {
      total_cost_required: totalCost,
      total_ceiling_required: totalCeiling,
    };
  };

  const totalInitialRequested = handleCertifyStages(4, 2);
  const totalCertifiedByUpen = handleCertifyStages(5, 3);
  const totalCertifiedByJppn = handleCertifyStages(6, 3);

  return (
    <tbody>
      {data.map((component, idx) => {
        const certifyingAmount = component.items.reduce(
          (acc, item) => acc + parseFloat(item?.amount),
          0,
        );

        const findInitialCost = costValue.find(
          (item) => item.id === component.financial_detail_id,
        );

        const initialCost = findInitialCost
          ? findInitialCost.cost_required
          : "";

        const handleFindInitialScope = (scopeId, stages, status) => {
          const findScope = project?.financial_details?.find(
            (obj) => obj.id === scopeId,
          );

          const totalCeilingByScope = findScope?.ceiling_flow_details
            .flatMap((obj1) => obj1.ceiling_flow_detail_stages)
            .filter(
              (obj2) =>
                obj2.status === status.toString() &&
                obj2.stages === stages.toString(),
            )
            .reduce(
              (accumulator, currentValue) =>
                parseFloat(accumulator) + parseFloat(currentValue.amount),
              0,
            );

          const findStages = findScope?.financial_detail_stages?.find(
            (obj) =>
              obj.stages === stages.toString() &&
              obj.status === status.toString(),
          );

          const result = {
            ...findStages,
            ceiling: totalCeilingByScope,
          };

          return result;
        };

        const findInitialInfo = handleFindInitialScope(
          component.financial_detail_id,
          4,
          2,
        );

        const handleFindScope = (scopeId, stages, status) => {
          const findScope = project?.financial_details?.find(
            (obj) => obj.id === scopeId,
          );
          const findStages = findScope?.financial_detail_stages?.find(
            (obj) =>
              obj.stages === stages.toString() &&
              obj.status === status.toString(),
          );

          return findStages;
        };

        const findUpenStages = handleFindScope(
          component.financial_detail_id,
          5,
          3,
        );
        const findJppnStages = handleFindScope(
          component.financial_detail_id,
          6,
          3,
        );

        return (
          <tr key={component.financial_detail_id}>
            <td className='text-center'>{idx + 1}.</td>
            <td className='border-end-0'>
              {component.cost_description.toLocaleUpperCase()}
            </td>
            <td className='border-start-0 text-center'>
              <div>
                <Tooltip
                  title='Lhat catatan'
                  placement='top'
                  followCursor
                  arrow
                  className='p-0'
                >
                  <IconButton
                    onClick={() => handleOpenNoteSection(component)}
                    className='button-animated position-relative'
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Icon
                      className=''
                      path={mdiNoteTextOutline}
                      size={0.8}
                      color={palette.primary}
                    />
                    {project.financial_details?.find(
                      (item) => item.id === component.financial_detail_id,
                    )?.financial_detail_notes.length !== 0 && (
                      <Icon
                        className='position-absolute'
                        path={mdiCircle}
                        size={0.45}
                        style={{ top: -2, right: -2 }}
                        color='#ef5350'
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </td>

            {viewColumns.dipohon.checked && (
              <>
                <td className='text-end text-truncate'>
                  {`${numeral(findInitialInfo?.cost_required).format(
                    "0,0.00",
                  )}`}
                </td>
                <td className='text-end text-truncate'>
                  {`${numeral(findInitialInfo?.ceiling).format("0,0.00")}`}
                </td>
              </>
            )}

            {/* UPEN SECTION  */}
            {viewColumns.upen.checked && (
              <>
                <td className='border-end-0'>
                  <div className='d-flex flex-row gap-4 justify-content-end text-truncate'>
                    {checkedCostId.includes(component.financial_detail_id) &&
                    !showColumn.jppn ? (
                      <React.Fragment>
                        <Form.Group className='w-100'>
                          <Form.Control
                            className={`text-end text-truncate`}
                            as={CurrencyInput}
                            min={0}
                            name='amount'
                            autoComplete='off'
                            decimalsLimit={2}
                            value={initialCost}
                            onValueChange={(value) =>
                              handleInitialCost(
                                value,
                                findInitialCost,
                                certifyingAmount,
                              )
                            }
                            isInvalid={errors.some(
                              (item) => item === component.financial_detail_id,
                            )}
                          />
                          <Form.Control.Feedback type='invalid'>
                            Kos tidak boleh kurang dari siling
                          </Form.Control.Feedback>
                        </Form.Group>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {showColumn.jppn ? (
                          <>
                            {`${numeral(findUpenStages?.cost_required).format(
                              "0,0.00",
                            )}`}
                          </>
                        ) : (
                          <>{`${numeral(certifyingAmount).format("0,0.00")}`}</>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </td>

                <td className='border-start-0'>
                  {!showColumn.jppn && (
                    <div className='d-flex justify-content-center'>
                      <Tooltip
                        title={
                          <>
                            <div className='text-center' style={{ width: 140 }}>
                              Tandakan untuk mengekalkan kos yang
                              dipohon/diperaku atau ubah jika perlu
                            </div>
                          </>
                        }
                        followCursor
                        arrow
                      >
                        <Form.Group controlId='check_change'>
                          <Form.Check
                            className='check-box-lg'
                            type='checkbox'
                            id='check_change'
                            onChange={() => handleCostSingleId(component)}
                            checked={
                              checkedCostId.includes(
                                component.financial_detail_id,
                              )
                                ? true
                                : false
                            }
                          />
                        </Form.Group>
                      </Tooltip>
                    </div>
                  )}
                </td>

                <td className='text-end text-truncate'>
                  {showColumn.jppn ? (
                    <>{`${numeral(findUpenStages?.ceiling).format(
                      "0,0.00",
                    )}`}</>
                  ) : (
                    <>{`${numeral(certifyingAmount).format("0,0.00")}`}</>
                  )}
                </td>
              </>
            )}

            {/* JPPN SECTION  */}

            {showColumn.jppn && (
              <React.Fragment>
                {viewColumns.jppn.checked && (
                  <>
                    <td className='border-end-0'>
                      <div className='d-flex flex-row gap-4 justify-content-end text-truncate'>
                        {checkedCostId.includes(
                          component.financial_detail_id,
                        ) && !showColumn.cabinet ? (
                          <React.Fragment>
                            <Form.Group className='w-100'>
                              <Form.Control
                                className={`text-end text-truncate`}
                                as={CurrencyInput}
                                min={0}
                                name='amount'
                                autoComplete='off'
                                decimalsLimit={2}
                                value={initialCost}
                                onValueChange={(value) =>
                                  handleInitialCost(
                                    value,
                                    findInitialCost,
                                    certifyingAmount,
                                  )
                                }
                                isInvalid={errors.some(
                                  (item) =>
                                    item === component.financial_detail_id,
                                )}
                              />
                              <Form.Control.Feedback type='invalid'>
                                Kos tidak boleh kurang dari siling
                              </Form.Control.Feedback>
                            </Form.Group>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {showColumn.cabinet ? (
                              <>
                                {`${numeral(
                                  findJppnStages?.cost_required,
                                ).format("0,0.00")}`}
                              </>
                            ) : (
                              <>{`${numeral(certifyingAmount).format(
                                "0,0.00",
                              )}`}</>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </td>
                    <td className='border-start-0'>
                      {!showColumn.cabinet && (
                        <div className='d-flex justify-content-center'>
                          <Tooltip
                            title='Tandakan untuk memperakui kos dipohon yang asal'
                            followCursor
                            arrow
                          >
                            <Form.Group controlId='check_change'>
                              <Form.Check
                                className='check-box-lg'
                                type='checkbox'
                                id='check_change'
                                onChange={() => handleCostSingleId(component)}
                                checked={
                                  checkedCostId.includes(
                                    component.financial_detail_id,
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </Form.Group>
                          </Tooltip>
                        </div>
                      )}
                    </td>
                    <td className='text-end text-truncate'>
                      {`${numeral(certifyingAmount).format("0,0.00")}`}
                    </td>
                  </>
                )}
              </React.Fragment>
            )}

            {/* CABINET SECTION  */}
            {showColumn.cabinet && (
              <React.Fragment>
                <td className='border-end-0'>
                  <div className='d-flex flex-row gap-4 justify-content-end text-truncate'>
                    {checkedCostId.includes(component.financial_detail_id) ? (
                      <React.Fragment>
                        <Form.Group className='w-100'>
                          <Form.Control
                            className={`text-end text-truncate`}
                            as={CurrencyInput}
                            min={0}
                            name='amount'
                            autoComplete='off'
                            decimalsLimit={2}
                            value={initialCost}
                            onValueChange={(value) =>
                              handleInitialCost(
                                value,
                                findInitialCost,
                                certifyingAmount,
                              )
                            }
                            isInvalid={errors.some(
                              (item) => item === component.financial_detail_id,
                            )}
                          />
                          <Form.Control.Feedback type='invalid'>
                            Kos tidak boleh kurang dari siling
                          </Form.Control.Feedback>
                        </Form.Group>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {`${numeral(certifyingAmount).format("0,0.00")}`}
                      </React.Fragment>
                    )}
                  </div>
                </td>
                <td className='border-start-0'>
                  <div className='d-flex justify-content-center'>
                    <Tooltip
                      title='Tandakan untuk memperakui kos dipohon yang asal'
                      followCursor
                      arrow
                    >
                      <Form.Group controlId='check_change'>
                        <Form.Check
                          className='check-box-lg'
                          type='checkbox'
                          id='check_change'
                          onChange={() => handleCostSingleId(component)}
                          checked={
                            checkedCostId.includes(
                              component.financial_detail_id,
                            )
                              ? true
                              : false
                          }
                        />
                      </Form.Group>
                    </Tooltip>
                  </div>
                </td>
                <td className='text-end text-truncate'>
                  {`${numeral(certifyingAmount).format("0,0.00")}`}
                </td>
              </React.Fragment>
            )}

            <td>
              {project.stages === "4" && (
                <div className='d-flex justify-content-center'>
                  <Tooltip title='Keluarkan komponen' arrow followCursor>
                    <span>
                      <IconButton
                        className='p-0'
                        onClick={() => handleRemoveComponent(component)}
                        color='error'
                        disabled={data.length === 1}
                      >
                        <Icon path={mdiMinusCircle} size={0.6} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              )}
            </td>
          </tr>
        );
      })}
      <tr>
        <td
          colSpan={3}
          className='text-end text-uppercase'
          style={{ fontWeight: 700 }}
        >
          Jumlah
        </td>

        {viewColumns.dipohon.checked && (
          <>
            <td className='text-end text-uppercase' style={{ fontWeight: 700 }}>
              {numeral(totalInitialRequested.total_cost_required).format(
                "0,0.00",
              )}
            </td>
            <td className='text-end text-uppercase' style={{ fontWeight: 700 }}>
              {numeral(totalInitialRequested.total_ceiling_required).format(
                "0,0.00",
              )}
            </td>
          </>
        )}

        {/* UPEN COLUMN */}
        {viewColumns.upen.checked && (
          <>
            <td
              className='text-end text-uppercase border-end-0'
              style={{ fontWeight: 700 }}
            >
              {showColumn.jppn ? (
                <>
                  {numeral(totalCertifiedByUpen.total_cost_required).format(
                    "0,0.00",
                  )}
                </>
              ) : (
                <>{numeral(totalEditedValue.totalCost).format("0,0.00")}</>
              )}
            </td>
            <td className='border-start-0' />

            <td className='text-end text-uppercase' style={{ fontWeight: 700 }}>
              {showColumn.jppn ? (
                <>
                  {numeral(totalCertifiedByUpen.total_ceiling_required).format(
                    "0,0.00",
                  )}
                </>
              ) : (
                <>{numeral(totalEditedValue.totalCeiling).format("0,0.00")}</>
              )}
            </td>
          </>
        )}
        {/* JPPN COLUMN */}
        {showColumn.jppn && (
          <>
            {viewColumns.jppn.checked && (
              <>
                <td
                  className='text-end text-uppercase border-end-0'
                  style={{ fontWeight: 700 }}
                >
                  {showColumn.cabinet ? (
                    <>
                      {numeral(totalCertifiedByJppn.total_cost_required).format(
                        "0,0.00",
                      )}
                    </>
                  ) : (
                    <>{numeral(totalEditedValue.totalCost).format("0,0.00")}</>
                  )}
                </td>
                <td className='border-start-0' />

                <td
                  className='text-end text-uppercase'
                  style={{ fontWeight: 700 }}
                >
                  {showColumn.cabinet ? (
                    <>
                      {numeral(
                        totalCertifiedByUpen.total_ceiling_required,
                      ).format("0,0.00")}
                    </>
                  ) : (
                    <>
                      {numeral(totalEditedValue.totalCeiling).format("0,0.00")}
                    </>
                  )}
                </td>
              </>
            )}
          </>
        )}

        {/* CABINET COLUMN */}
        {showColumn.cabinet && (
          <>
            <td
              className='text-end text-uppercase border-end-0'
              style={{ fontWeight: 700 }}
            >
              <>{numeral(totalEditedValue.totalCost).format("0,0.00")}</>
            </td>
            <td className='border-start-0' />

            <td className='text-end text-uppercase' style={{ fontWeight: 700 }}>
              <>{numeral(totalEditedValue.totalCeiling).format("0,0.00")}</>
            </td>
          </>
        )}

        {/* Last column */}
        <td />
      </tr>
    </tbody>
  );
};

export default TableBody;
