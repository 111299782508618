import { createContext, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import axios from "../utils/axios";
import { setSession } from "../utils/token";

import { getActiveRmk } from "../redux/slices/rmk-information/rmkSlice";
import { fetchUser, resetUserReducer } from "../redux/slices/userSlice";
import { resetProjectsReducer } from "../redux/slices/projects/projectsSlice";
import { resetUsersReducer } from "../redux/slices/usersSlice";
import { resetDashboardReducer } from "../redux/slices/dashboard/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading } from "../redux/slices/userSlice";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          setSession(accessToken);
          const response = await axios.get("/user", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const { user } = response.data;

          const roles = user.roles || [];

          dispatch(setIsLoading(true));

          setState({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
          const userAuth = {
            ...user,
          };

          await dispatch(fetchUser(userAuth));
          await dispatch(getActiveRmk());

          let redirectPath = "/auth/404"; // Default path

          if (location.pathname === "/") {
            if (roles.includes("superadmin")) {
              redirectPath = "/pengumuman";
            }
            if (roles.includes("dashboard")) {
              redirectPath = "/pengumuman";
            }
            if (roles.includes("laporan_prestasi_projek")) {
              redirectPath = "/pengumuman";
            }
            if (roles.includes("projek_mikro_dashboard")) {
              redirectPath = "/projek-mikro/dashboard";
            }
            if (roles.includes("projek_mikro_senarai")) {
              redirectPath = "/projek-mikro/senarai-projek";
            }
            if (roles.includes("projek_mikro_status_senarai")) {
              redirectPath = "/projek-mikro/status-senarai-projek";
            }
            if (roles.includes("admin_projek_mikro")) {
              redirectPath = "/projek-mikro/admin/senarai-pengguna";
            }
          } else {
            redirectPath = location.pathname;
          }

          setTimeout(() => {
            dispatch(setIsLoading(false));
            navigate(redirectPath);
          }, 2000);
        } else {
          setState({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        setState({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();

    return () => {
      setState({});
    };
  }, [dispatch]);

  const signIn = async (nric, password) => {
    const getBrowserName = () => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("Chrome") !== -1) {
        return "Chrome";
      } else if (userAgent.indexOf("Firefox") !== -1) {
        return "Firefox";
      } else if (userAgent.indexOf("Safari") !== -1) {
        return "Safari";
      } else if (userAgent.indexOf("Edge") !== -1) {
        return "Edge";
      } else if (
        userAgent.indexOf("IE") !== -1 ||
        userAgent.indexOf("Trident/") !== -1
      ) {
        return "Internet Explorer";
      } else {
        return "Unknown";
      }
    };

    const response = await axios.post("/login", {
      nric,
      password,
      remember: true,
      browser: getBrowserName(), // todo use user browser name
    });

    const { token: accessToken, user } = response.data;

    setSession(accessToken);
    setState({
      type: SIGN_IN,
      payload: {
        user,
      },
    });

    const userAuth = {
      ...user,
    };

    await dispatch(fetchUser(userAuth));
    await dispatch(getActiveRmk());

    return userAuth;
  };

  const handleResetReducer = () => {
    dispatch(resetUserReducer());
    dispatch(resetProjectsReducer());
    dispatch(resetUsersReducer());
    dispatch(resetDashboardReducer());
  };

  const signOut = async () => {
    const getBrowserName = () => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("Chrome") !== -1) {
        return "Chrome";
      } else if (userAgent.indexOf("Firefox") !== -1) {
        return "Firefox";
      } else if (userAgent.indexOf("Safari") !== -1) {
        return "Safari";
      } else if (userAgent.indexOf("Edge") !== -1) {
        return "Edge";
      } else if (
        userAgent.indexOf("IE") !== -1 ||
        userAgent.indexOf("Trident/") !== -1
      ) {
        return "Internet Explorer";
      } else {
        return "Unknown";
      }
    };

    const response = await axios.post("/logout", {
      browser: getBrowserName(),
    });

    setSession(null);
    setState({ type: SIGN_OUT });
    handleResetReducer();
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
