import { useFormik } from "formik";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// Redux
import { userRegistrationResponse } from "../../../../../redux/slices/user-management/userManagementSlice";

const RejectedCommentModal = ({ showForm, closeForm, data, confirmReject }) => {
  const dispatch = useDispatch();

  const { btnLoading } = useSelector((state) => ({
    btnLoading: state.userManagementReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: "",
    },
    onSubmit: async (values) => {
      const newValues = {
        user_id: data.id,
        response: 0,
        comment: values.comment,
      };
      await dispatch(userRegistrationResponse(newValues));
      closeForm();
      formik.resetForm();
    },
  });

  return (
    <Modal show={showForm} onHide={closeForm} size='sm' centered>
      <Modal.Body>
        <div className='mb-3'>
          Anda pasti untuk menolak pendaftaran {data?.name} sebagai pengguna
          SMEPPS?
        </div>
        <Form>
          <Form.Group className='mb-3'>
            <Form.Label>Nyatakan sebab-sebab ditolak.</Form.Label>
            <Form.Control
              name='comment'
              as='textarea'
              type='text'
              style={{ maxHeight: 200, minHeight: 150, height: 150 }}
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
        </Form>
        <div className='d-flex justify-content-end gap-2'>
          <Button variant='light' disabled={btnLoading} onClick={closeForm}>
            Batal
          </Button>
          <Button
            variant='danger'
            disabled={btnLoading}
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return;
            }}
          >
            Tolak pendaftaran pengguna
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RejectedCommentModal;
