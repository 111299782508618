import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  ceilingBalance: [],
};

export const createProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    addCeilingBalance: (state, action) => {
      state.ceilingBalance.push(action.payload);
    },

    updateCeilingBalance: (state, action) => {
      const { id } = action.payload;
      state.ceilingBalance = state.ceilingBalance.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
    },

    removeCeilingBalance: (state, action) => {
      state.ceilingBalance = state.ceilingBalance.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {},
});

export const { addCeilingBalance, updateCeilingBalance, removeCeilingBalance } =
  createProjectSlice.actions;

export default createProjectSlice.reducer;
