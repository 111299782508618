import React from 'react'

const TableHead = () => {
  return (
    <thead className="text-center align-middle custom-table-head-danger">
      <tr>
        <th className='text-center' style={{ width: 50 }}>BIL.</th>
        <th>KOMPONEN PROJEK</th>
        <th>KOS DIPOHON</th>
        <th>TINDAKAN OLEH</th>
        <th></th>
      </tr>
    </thead>
  )
}

export default TableHead