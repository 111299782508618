import React from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";

// Styles
import usePalette from "../../../../../../../hooks/usePalette";

// Redux
import {
  createHumanResourcesRequirement,
  updatedHumanResourcesRequirement,
} from "../../../../../../../redux/slices/projects/humanResourcesRequirementSlice";

const HrRequirementForm = ({ openForm, closeForm, data, isEdit }) => {
  const palette = usePalette();

  const dispatch = useDispatch();

  const { project, btnLoading } = useSelector((state) => ({
    project: state.projectReducer.project,
    btnLoading: state.projectReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      number_of_staff: data.number_of_staff || "",
      position_name: data.position_name || "",
      position_gred: data.position_gred || "",
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          ...values,
        };
        await dispatch(updatedHumanResourcesRequirement(newValues));
      } else {
        const newValues = {
          project_id: project.id,
          ...values,
        };
        await dispatch(createHumanResourcesRequirement(newValues));
      }

      formik.resetForm();
      handleCloseModal();
    },
  });

  const handleCloseModal = () => {
    closeForm();
    formik.resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  return (
    <Modal show={openForm} onHide={handleCloseModal} centered>
      <Modal.Body>
        <div>
          <p className='fw-bold fs-4'>Tambah Keperluan Sumber Manusia</p>
        </div>
        <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Bilangan Staff</Form.Label>
            <Form.Control
              name='number_of_staff'
              type='number'
              value={formik.values.number_of_staff}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Nama Jawatan</Form.Label>
            <Form.Control
              name='position_name'
              type='text'
              value={formik.values.position_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Gred Jawatan</Form.Label>
            <Form.Control
              name='position_gred'
              type='text'
              value={formik.values.position_gred}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              variant='light'
              onClick={handleCloseModal}
              disabled={btnLoading}
            >
              Batal
            </Button>
            <Button variant='success' type='submit' disabled={btnLoading}>
              Simpan
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default HrRequirementForm;
