import React from "react";
import { Col, Row } from "react-bootstrap";

// component
import TotalCostApproved from "./components/TotalCostApproved";
import ApprovedApplication from "./components/ApprovedApplication";
import DistrictGraphOne from "./components/DistrictGraphOne";
import DUNGraph from "./components/DUNGraph";
import ParliamentGraph from "./components/ParliamentGraph";

const PageContent = () => {
  return (
    <React.Fragment>
      <Row>
        <Col md={6} className='d-flex'>
          <TotalCostApproved />
        </Col>
        <Col md={6} className='d-flex'>
          <ApprovedApplication />
        </Col>
      </Row>

      <DistrictGraphOne />
      <DUNGraph />
      <ParliamentGraph />
    </React.Fragment>
  );
};

export default PageContent;
