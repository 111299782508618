import React from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch } from "react-redux";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Redux
import {
  createScope,
  updateScope,
} from "../../../../../redux/slices/maintenance/projectScopeSlice";

const ProjectScopeForm = ({ showForm, closeForm, isEdit, item }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: item.name || "",
      description: item.description || "",
    },
    onSubmit: async (values) => {
      try {
        if (isEdit) {
          const newValues = {
            ...values,
            id: item.id,
          };
          dispatch(updateScope(newValues));
          handleSuccess();
        } else {
          await dispatch(createScope(values));
          handleSuccess();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleCloseModal = () => {
    closeForm();
  };

  const handleSuccess = () => {
    formik.resetForm();
    handleCloseModal();
  };

  return (
    <Modal show={showForm} onHide={closeForm} centered>
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Skop Projek</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Nama Skop</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Keterangan Skop</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='description'
              style={{ minHeight: 75, maxHeight: 105 }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleCloseModal}>
              Batal
            </Button>
            <Button variant='success' type='submit'>
              Simpan
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectScopeForm;
