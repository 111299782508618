import React from "react";
import { Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

const TargetGroup = () => {

  const { groups, isLoading } = useSelector((state) => ({
    groups: state.targetGroupReducer.groups,
    isLoading: state.targetGroupReducer.isLoading
  }))

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table size="sm">
          <thead>
            <tr>
              <th className="text-center">Bil.</th>
              <th>Nama Kumpulan</th>
              <th>Keterangan Kumpulan</th>
              <th className="text-center">Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, idx) => (
              <tr key={group.id}>
                <td className="text-center">{idx + 1}</td>
                <td>{group.name}</td>
                <td>{group.description}</td>
                <td>
                  <div className="d-flex justify-content-center">
                    <Tooltip title="Edit" placement="top" arrow>
                      <IconButton
                        color="info"
                        size="small"
                        aria-label="Edit"
                      // onClick={() => handleEditRow(row)}
                      >
                        <MdModeEditOutline fontSize="large" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Padam" placement="top" arrow>
                      <IconButton
                        color="error"
                        size="small"
                        aria-label="Padam"
                      // onClick={() => handleRemoveRow(row.id)}
                      >
                        <MdDelete fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default TargetGroup;
