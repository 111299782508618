import React from "react";
import { Button, Modal } from "react-bootstrap";

const JPPNNewProject = ({ show, onHide, project }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      keyboard='false'
      backdrop='static'
    >
      <Modal.Body>
        <div className='mb-3'></div>
        <div className='d-flex justify-content-end'>
          <Button variant='light' onClick={onHide}>
            Tutup
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JPPNNewProject;
