import { isEmpty } from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const FinancialReportByScope = ({ s3pData, yearValue }) => {
  const [totalExpensesByAgency, setTotalExpensesByAgency] = useState(0);
  const [totalCeilingByYear, setTotalCeilingByYear] = useState(0);

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  useEffect(() => {
    if (!isEmpty(project)) {
      const projectFinancial = project.financial_details;
      const reports = project.reports;

      const totalExpenses = reports
        .filter((item) => item.year == yearValue)
        .reduce(
          (acc, item) =>
            (acc += parseFloat(item.total_expenses_report_by_agency || 0)),
          0,
        );

      setTotalExpensesByAgency(totalExpenses);

      const totalCeiling = projectFinancial
        .flatMap((item) => item.ceiling_flow_details)
        .find((item) => item.year == yearValue);

      setTotalCeilingByYear(totalCeiling?.amount);
    }
  }, [project]);

  return (
    <React.Fragment>
      <div className='py-3 mt-1'>
        <div className='mb-3'>
          <h5>Senarai skop projek</h5>
        </div>
        <Table size='sm' style={{ fontSize: 11 }}>
          <thead className='text-uppercase custom-table-head'>
            <tr>
              <th className='align-middle ps-2'>Skop</th>
              <th className='text-end'>
                Laporan Perbelanjaan Dari Agensi (Terkumpul)
                <div>(RM)</div>
              </th>
              <th className='text-end'>
                Siling<div>(RM)</div>
              </th>
              {/* <th className='text-end'>
                Baki Semasa<div>(RM)</div>
              </th> */}
            </tr>
          </thead>
          <tbody>
            {project?.financial_details?.map((item) => {
              const cumulativeExpensesByAgency =
                item.project_scope_progress_reports.reduce(
                  (acc, obj) =>
                    (acc += parseFloat(obj.expenses_report_by_agency || 0)),
                  0,
                );

              const ceilingByYear = item.ceiling_flow_details.find(
                (obj) => obj.year === Number(yearValue),
              );

              return (
                <tr key={item.id}>
                  <td className='text-uppercase fw-bold ps-2'>
                    {item.cost_description}
                  </td>
                  <td className='text-end'>
                    {/* {numeral(cumulativeExpensesByAgency).format("0,0.00")} */}
                  </td>
                  <td className='text-end'>
                    {numeral(ceilingByYear?.amount || 0).format("0,0.00")}
                  </td>
                </tr>
              );
            })}
            <tr style={{ borderTop: "double" }}>
              <td className='ps-2 fw-bold'>KESELURUHAN</td>
              <td className='text-end'>
                {numeral(totalExpensesByAgency).format("0,0.00")}
              </td>
              <td className='text-end'>
                {numeral(totalCeilingByYear).format("0,0.00")}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default FinancialReportByScope;
