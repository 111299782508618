import React, { useEffect, useState } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";

//components
import GroupRoles from "./components/GroupRoles";
import GroupRoleForm from "./components/GroupRoleForm";
import { useDispatch } from "react-redux";

// Redux
import {
  fetchGroupRoles,
  fetchRoles,
  deleteGroupRoles,
} from "../../../redux/slices/maintenance/rolesSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState(null);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleEditItem = (item) => {
    setData(item);
    setShowForm(true);
    setIsEdit(true);
  };

  const handleRemoveItem = async (id) => {
    await dispatch(deleteGroupRoles(id));
  };

  useEffect(() => {
    dispatch(fetchGroupRoles());
    dispatch(fetchRoles());
  }, [dispatch]);

  return (
    <React.Fragment>
      <GroupRoleForm
        show={showForm}
        hide={() => {
          setShowForm(false);
          setData(null);
          setIsEdit(false);
        }}
        data={data}
        isEdit={isEdit}
      />

      <Helmet title='Senarai Peranan' />
      <Container fluid className='p-0'>
        <Breadcrumbs title='Admin' breadcrumbItem='Senarai Peranan' />
        <Card className='mt-3'>
          <Card.Header>
            <div className='d-flex justify-content-end'>
              <Button onClick={handleShowForm}>+ Peranan</Button>
            </div>
          </Card.Header>
          <Card.Body>
            <GroupRoles
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
