import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchBranches = createAsyncThunk(
  "branches/fetchBranches",
  async (params) => {
    try {
      const response = await axios.get(`/branches`, { params });
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createBranch = createAsyncThunk(
  "branches/createBranch",
  async (values) => {
    try {
      const response = await axios.post(`/branches`, values);
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateBranch = createAsyncThunk(
  "branches/updateBranch",
  async (values) => {
    try {
      const { id } = values;

      const response = await axios.put(`/branches/${id}`, values);
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeBranch = createAsyncThunk(
  "branches/removeBranch",
  async (id) => {
    try {
      const response = await axios.delete(`/branches/${id}`);
      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  branches: [],
  links: {},
  meta: {},
  isLoading: false,
  btnLoading: false,
};

export const branchSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBranches.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      const { meta, links, data } = action.payload;
      state.branches = data;
      state.links = links;
      state.meta = meta;
      state.isLoading = false;
    });

    builder.addCase(createBranch.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createBranch.fulfilled, (state, action) => {
      state.branches.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateBranch.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateBranch.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.branches = state.branches.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeBranch.pending, (state, action) => {
      state.btnLoading = true;
    });

    builder.addCase(removeBranch.fulfilled, (state, action) => {
      state.branches = state.branches.filter(
        (item) => item.id !== action.payload,
      );

      state.btnLoading = false;
    });
  },
});

export default branchSlice.reducer;
