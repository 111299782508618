import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";

import ResetPassword from "../../components/auth/ResetPassword";

const ResetPasswordPage = () => (
  <React.Fragment>
    <Helmet title='Lupa Kata Laluan' />

    <Container>
      <div className='text-center mt-4'>
        <h1 className='h2'>Terlupa Kata Laluan</h1>
      </div>

      <Card>
        <Card.Body>
          <div className='m-sm-4'>
            <ResetPassword />
          </div>
        </Card.Body>
      </Card>
    </Container>
  </React.Fragment>
);

export default ResetPasswordPage;
