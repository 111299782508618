import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Card, Pagination, Tab, Table } from "react-bootstrap";

const EditProjectName = ({ eventKey, project }) => {
  const [currentPage, setCurrentPage] = useState(1); // State to keep track of the current page

  // Handle page change
  const handlePageChange = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      // Fetch new data based on the selected page
      // Assuming you have a function to fetch the data from the API
      // fetchEditNameActivities(page);
    }
  };

  // Assuming pagination information is available in the project data
  const totalPages = project?.edit_name_activities?.last_page || 1;

  return (
    <Tab.Pane eventKey={eventKey}>
      <div className='mb-3'>
        <div className='table-responsive'>
          <Table size='sm'>
            <thead className='text-uppercase'>
              <tr>
                <th className='text-center'>Bil.</th>
                <th>Rujukan</th>
                <th>Nama Asal</th>
                <th>Nama Baharu</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(project?.edit_name_activities?.data) && (
                <tr>
                  <td colSpan={4} className='text-center'>
                    Tiada Maklumat Tersedia
                  </td>
                </tr>
              )}
              {!isEmpty(project?.edit_name_activities?.data) && (
                <>
                  {project?.edit_name_activities?.data?.map((item, idx) => {
                    const projectName = JSON.parse(item?.project_name);

                    return (
                      <tr key={item.id}>
                        <td className='text-center'>{idx + 1}.</td>
                        <td>{item?.reference_no}</td>
                        <td>{projectName?.old}</td>
                        <td>{projectName?.new}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </Tab.Pane>
  );
};

export default EditProjectName;
