import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select, { components } from "react-select";
import CurrencyInput from "react-currency-input-field";
import _, { isEmpty } from "lodash";

// Icons
import { FaCheck, FaQuestionCircle } from "react-icons/fa";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";

// Redux
import {
  addCeilingBalance,
  updateCeilingBalance,
} from "../../../../../redux/slices/ceilingBalanceSlice";
import {
  createFinancialDetails,
  updateFinancialDetails,
} from "../../../../../redux/slices/projects/financialDetailsSlice";

const IconToolTip = React.forwardRef(function MyComponent(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <div {...props} ref={ref} className='d-inline-flex align-self-center'>
      <FaQuestionCircle />
    </div>
  );
});

const RequestedCeilingForm = ({
  openRequestCeilingForm,
  toggleRequestCeilingForm,
  data,
  project,
  handleNotification,
  btnLoading,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [showAllCostType, setShowAllCostType] = useState(false);
  const [options, setOptions] = useState([]);

  const { user, selectedItems, projectScopeItems } = useSelector((state) => ({
    user: state.userReducer.user,
    selectedItems: state.projectReducer.project.financial_details,
    projectScopeItems: state.utilsReducer.dropdownItems.projectScopeItems,
  }));

  const handleClose = () => {
    toggleRequestCeilingForm();
    setShowAllCostType(false);
    formik.resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cost_description: "",
      cost_required: data.cost_required || "",
    },
    validationSchema: Yup.object().shape({
      cost_description: Yup.object().required(
        "Sila masukkan keterangan kos dipohon",
      ),
      cost_required: Yup.string().required("Sila masukkan jumlah kos dipohon"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          ...values,
          id: data.id,
          cost_description: values.cost_description.value,
          ceiling: values.cost_required,
        };
        await dispatch(updateFinancialDetails(newValues));
        handleNotification("edited", newValues);
        handleClose();
      } else {
        const newValues = {
          ...values,
          project_id: project.id,
          cost_description: values.cost_description.value,
          ceiling: values.cost_required,
        };
        await dispatch(createFinancialDetails(newValues));
        handleNotification("created", newValues);
        handleClose();
      }
    },
  });

  const handleChangeCheckBox = () => {
    if (showAllCostType) {
      setShowAllCostType(false);
    } else {
      setShowAllCostType(true);
      formik.setFieldValue("cost_description", "Kos Dipohon");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  const handleChangeDescription = (e) => {
    formik.setFieldValue("cost_description", e);
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const findItem = options?.find(
        (item) => item.label === data.cost_description,
      );
      formik.setFieldValue("cost_description", findItem);
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    setOptions(projectScopeItems);
  }, [projectScopeItems]);

  useEffect(() => {
    if (!isEmpty(selectedItems)) {
      const selectedArray = selectedItems.map((item) => item.cost_description);

      const newArr = options.map((obj1) => {
        const obj2 = selectedArray.some((obj2) => obj2 === obj1.label);
        if (obj2) {
          return { ...obj1, isDisabled: true };
        } else {
          return { ...obj1, isDisabled: false };
        }
      });
      setOptions(newArr);
    }
  }, [selectedItems, openRequestCeilingForm]);

  const { Option } = components;

  const CheckOption = (props) => {
    return (
      <Option {...props}>
        {props.data.label}
        {props.data.isDisabled && (
          <FaCheck color={palette.success} className='ms-3' size={16} />
        )}
      </Option>
    );
  };

  return (
    <Modal show={openRequestCeilingForm} onHide={handleClose} centered>
      <Modal.Header closeButton>Maklumat Kewangan</Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Keterangan Kos Dipohon</Form.Label>
            <Select
              name='cost_description'
              placeholder='Pilih...'
              className='react-select-container'
              classNamePrefix='react-select'
              options={options}
              components={{ Option: CheckOption }}
              styles={customStylesForm}
              value={formik.values.cost_description}
              onChange={handleChangeDescription}
              noOptionsMessage={() => "Tiada pilihan"}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.cost_description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Jumlah Kos Dipohon</Form.Label>
            <Form.Control
              as={CurrencyInput}
              name='cost_required'
              placeholder='0.00'
              decimalsLimit={2}
              value={formik.values.cost_required}
              onValueChange={(value) =>
                formik.setFieldValue("cost_required", value)
              }
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.cost_required && !formik.errors.cost_required
                  ? true
                  : false
              }
              isInvalid={
                formik.touched.cost_required && formik.errors.cost_required
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.cost_required}
            </Form.Control.Feedback>
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleClose} disabled={btnLoading}>
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RequestedCeilingForm;
