import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Table, Button, Card } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

import ImplementationForm from "../modals/ImplementationForm";

const ImplementationSchedule = ({ enableBtnSave }) => {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [rowData, setRowData] = useState({});
  const [sortData, setSortData] = useState([]);

  const { project } = useSelector((state) => ({
    project: state.projectReducer.project,
  }));

  const handleCloseForm = () => {
    setShowForm(false);
    setRowData({});
  };

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleRemoveRow = (id) => {
    // dispatch(removeImplementationActivity(id));
  };

  const handleEditRow = (row) => {
    setShowForm(true);
    setRowData(row);
  };

  return (
    <React.Fragment>
      <ImplementationForm
        openForm={showForm}
        closeForm={handleCloseForm}
        data={rowData}
      />

      <div className='mb-3'>
        <Card.Title>Jadual Pelaksanaan *</Card.Title>
        <div className='table-responsive'>
          <Table style={{ fontSize: 12 }} size='sm'>
            <thead>
              <tr>
                <th>
                  <p className='m-0'>Aktiviti</p>
                  <p
                    className='m-0 fw-normal fst-italic'
                    style={{ fontSize: 10 }}
                  >
                    Pilihan yang dibuat melalui maklumat kewangan
                  </p>
                </th>
                <th className='text-end'>Tarikh Jangka Mula</th>
                <th className='text-end'>Tarikh Jangka Siap</th>
                <th className='text-center'>Tempoh</th>
                {/* <th>Catatan</th> */}
                <th className='text-center'>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(project.financial_details) ? (
                <tr>
                  <td colSpan={7} className='text-center'>
                    Tiada Maklumat
                  </td>
                </tr>
              ) : (
                <>
                  {project.financial_details.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>{row.cost_description}</td>
                        <td className='text-end'>
                          {!isEmpty(
                            row.project_scope_details?.expected_start_date,
                          ) &&
                            format(
                              new Date(
                                row.project_scope_details.expected_start_date,
                              ),
                              "dd MMM yyyy",
                            )}
                        </td>
                        <td className='text-end'>
                          {!isEmpty(
                            row.project_scope_details?.expected_completion_date,
                          ) &&
                            format(
                              new Date(
                                row.project_scope_details?.expected_completion_date,
                              ),
                              "dd MMM yyyy",
                            )}
                        </td>
                        <td className='text-center'>
                          {row.project_scope_details
                            ?.expected_completion_date &&
                            formatDistanceStrict(
                              new Date(
                                row.project_scope_details?.expected_start_date,
                              ),
                              new Date(
                                row.project_scope_details?.expected_completion_date,
                              ),
                              {
                                addSuffix: false,
                                locale: ms,
                              },
                            )}
                        </td>
                        <td>
                          <div className='d-flex justify-content-center'>
                            <Tooltip title='Edit' placement='top' arrow>
                              <span>
                                <IconButton
                                  color='info'
                                  size='small'
                                  aria-label='padam'
                                  onClick={() => handleEditRow(row)}
                                  disabled={!enableBtnSave}
                                >
                                  <MdModeEditOutline fontSize='large' />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImplementationSchedule;
