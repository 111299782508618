import React from 'react'
import numeral from "numeral";
import { IconButton, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';

// Icons
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';

const TableBody = ({
  data,
  handleAddComponentBack,
}) => {

  return (
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td colSpan={5} className='text-center fst-italic'>
            Maklumat tidak tersedia
          </td>
        </tr>
      ) : (
        <React.Fragment>
          {data.map((item, idx) => {
            return (
              <tr key={item.id}>
                <td className='text-center'>
                  {idx + 1}.
                </td>
                <td>
                  {item.cost_description.toLocaleUpperCase()}
                </td>
                <td className='text-end'>
                  {`RM ${numeral(item.cost_required).format("0,0.00")}`}
                </td>
                <td>

                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <Tooltip title="Masukkan semula komponen" arrow followCursor>
                      <IconButton
                        className="p-0"
                        onClick={() => handleAddComponentBack(item)}
                        color="success">
                        <Icon path={mdiCheckCircle} size={0.7} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            )
          })}
        </React.Fragment>
      )}



    </tbody>
  )
}

export default TableBody