import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Reports from "./components/tables/Reports";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import numeral from "numeral";

const FinancialReport = () => {
  const [fund, setFund] = useState(0);

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  useEffect(() => {
    if (isEmpty(project)) return;

    const amount = project?.s3p_data?.find(
      (item) => item.year === new Date().getFullYear(),
    );

    setFund(amount.revised_total_allocation);
  }, [project]);

  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <Table className='mb-0' size='sm' borderless>
            <tbody>
              <tr>
                <td style={{ width: 120, fontWeight: 700 }}>Program</td>
                <td>{project.program?.label}</td>
              </tr>
              <tr>
                <td style={{ width: 120, fontWeight: 700 }}>Projek Utama</td>
                <td>
                  {project.main_project?.code} {project.main_project?.label}
                </td>
              </tr>
              <tr>
                <td style={{ width: 120, fontWeight: 700 }}>Nama Projek</td>
                <td>
                  {project.code} {project?.name}
                </td>
              </tr>
              <tr>
                <td style={{ width: 120, fontWeight: 700 }}>
                  Peruntukan Semasa (RM)
                </td>
                <td>{numeral(fund).format("0,0.00")}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Header>
        <Card.Body>
          <Reports />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default FinancialReport;
