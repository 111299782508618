import React from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { setSuccessCreated } from "../../../../redux/slices/usersSlice";

const SuccessCreateUser = ({ closeSuccessCreated }) => {
  const dispatch = useDispatch();

  const { name, password, successCreated } = useSelector((state) => ({
    name: state.usersReducer.temp_name,
    password: state.usersReducer.temp_password,
    successCreated: state.usersReducer.successCreated,
  }));
  return (
    <Modal
      size='sm'
      show={successCreated}
      onHide={closeSuccessCreated}
      centered
    >
      <Modal.Body>
        <Card className='bg-success p-4 text-white fw-bold text-center'>
          Pengguna {name && name} berjaya ditambah.
        </Card>
        <div className='fw-bold mb-3'>Kata Laluan : {password && password}</div>
        <div className='d-flex justify-content-end'>
          <Button
            variant='light'
            onClick={() => {
              dispatch(setSuccessCreated(false));
              closeSuccessCreated();
            }}
          >
            Tutup
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessCreateUser;
