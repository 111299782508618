import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";

const MoreSearchField = ({ showCanvas, closeCanvas, searchMoreField }) => {
  const { ministries } = useSelector((state) => ({
    ministries: state.utilsReducer.dropdownItems.ministries,
  }));

  const handleClose = () => {
    closeCanvas();
  };

  const handleSearch = () => {
    searchMoreField();
  };

  const handleResetField = () => {};

  const handleSelectFederalProject = (value) => {
    localStorage.setItem("federalProjects", JSON.stringify(value));
  };

  return (
    <Offcanvas show={showCanvas} onHide={handleClose} style={{ width: 310 }}>
      <Offcanvas.Body>
        <Offcanvas.Title className='mb-3'>Carian Tambahan</Offcanvas.Title>
        <Form autoComplete='off' noValidate style={{ fontSize: 12 }}>
          <Form.Group className='mb-3'>
            <Form.Label>Projek Persekutuan</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              options={[
                {
                  label: "Ya",
                  value: true,
                },
                {
                  label: "Tidak",
                  value: false,
                },
              ]}
              value={
                JSON.parse(localStorage.getItem("federalProjects")) || {
                  label: "Tidak",
                  value: false,
                }
              }
              onChange={(value) => handleSelectFederalProject(value)}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>
          <div className='d-flex flex-column gap-3'>
            <Button
              variant='primary'
              className='flex-fill'
              onClick={handleSearch}
            >
              Cari
            </Button>
            <Button
              variant='light'
              className='flex-fill'
              onClick={handleResetField}
            >
              Set Semula
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MoreSearchField;
