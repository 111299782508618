import React, { useEffect, useState, useContext } from "react";
import { Accordion, Button, Card, Table } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import parse from "html-react-parser";

// Icons
import { MdDelete, MdEdit, MdCheckCircle } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";

// Styles
import NotyfContext from "../../../../../contexts/NotyfContext";
import usePalette from "../../../../../hooks/usePalette";

// Redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";

const FrameworkForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  projectId,
  enableBtnSave,
}) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const palette = usePalette();

  const [openForm, setOpenForm] = useState(false);
  const [rowName, setRowName] = useState("");
  const [rowNum, setRowNum] = useState(null);
  const [value, setValue] = useState("");
  const [keyPerformanceIndicators, setKeyPerformanceIndicators] = useState("");
  const [validationMethod, setValidationMethod] = useState("");
  const [assumption, setAssumption] = useState("");

  const { btnLoading, project } = useSelector((state) => ({
    project: state.projectReducer.project,
    btnLoading: state.projectReducer.btnLoading,
  }));

  const handleOpenForm = (num, name) => {
    if (rowNum === num) {
      setRowNum(null);
      setRowName("");
    } else {
      setRowNum(num);
      setRowName(name);

      const value = project[name];

      formik.setFieldValue("description", value);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      key_performance_indicators: "",
      validation_method: "",
      assumption: "",
    },
    onSubmit: async () => {
      let valueKPI = null;
      let valueVM = null;
      let valueAssumption = null;

      if (keyPerformanceIndicators !== "<p><br></p>") {
        valueKPI = keyPerformanceIndicators;
      }
      if (validationMethod !== "<p><br></p>") {
        valueVM = validationMethod;
      }
      if (assumption !== "<p><br></p>") {
        valueAssumption = assumption;
      }

      const newValues = {
        id: projectId,
        key_performance_indicators: valueKPI,
        validation_method: valueVM,
        assumption: valueAssumption,
      };

      await dispatch(updateProject(newValues));

      setRowName("");
      setRowNum(null);
      formik.resetForm();
    },
  });

  const handleSave = () => {
    formik.handleSubmit();
    return false;
  };

  const handleCancel = () => {
    setRowName("");
    setRowNum(null);
  };

  const handleSubmit = () => {
    handleNotification("success");
    handleChecked(5);
    handleExpand(6);
  };

  // Notification
  const handleNotification = (type, values) => {
    // Errors
    if (type === "danger") {
      const errors = Object.values(values);

      errors.map((item) => {
        const strMsg = item;

        notyf.open({
          type: type,
          message: strMsg,
          duration: 7000,
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "top",
          },
        });
      });
      return false;
    }

    // Success
    notyf.open({
      type: type,
      message: "Maklumat matriks rangka kerja logik berjaya disimpan.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(project?.framework_matrix)) {
      const frameworkMatrix = JSON.parse(project?.framework_matrix);

      setKeyPerformanceIndicators(frameworkMatrix.key_performance_indicators);
      setValidationMethod(frameworkMatrix.validation_method);
      setAssumption(frameworkMatrix.assumption);
    }
  }, [project.framework_matrix]);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(5)}
      >
        {(passedSteps || []).includes(5) ? (
          <MdCheckCircle className='me-2' size={29} color={palette.success} />
        ) : (
          <span className='number'>5.</span>
        )}
        Maklumat Matriks Rangka Kerja Logik
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Header className='pb-0'>
            <Card.Text className='text-sm'>
              Sila isi matriks rangka kerja logik.
            </Card.Text>
          </Card.Header>
          <Card.Body className='m-0'>
            <div className='table-responsive mb-3'>
              <Table bordered size='sm' style={{ fontSize: 12 }}>
                <tbody>
                  <tr className='custom-table-head'>
                    <td
                      className='fw-bold'
                      rowSpan={2}
                      style={{ width: "20%" }}
                    >
                      Keterangan Projek
                    </td>
                    <th className='text-center'>Matlamat</th>
                    <th className='text-center'>Tujuan</th>
                    <th className='text-center'>KPI</th>
                    <th className='text-center'>Aktiviti</th>
                  </tr>
                  <tr style={{ borderBottom: "double" }}>
                    <td className='text-center p-4'>Maklumat tidak tersedia</td>
                    <td className='text-center p-4'>Maklumat tidak tersedia</td>
                    <td className='text-center p-4'>
                      {project.key_performance_indicator
                        ? project.key_performance_indicator
                        : "Maklumat tidak tersedia"}
                    </td>
                    <td className='text-center p-4'>Maklumat tidak tersedia</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Petunjuk Utama Prestasi</td>
                    <td colSpan={4} className='p-0'>
                      <div className='position-relative'>
                        <div
                          className={`position-absolute`}
                          style={{ top: 0, right: 0, zIndex: 1 }}
                        >
                          <Tooltip title='Edit' placement='top' arrow>
                            <span>
                              <IconButton
                                color='primary'
                                size='small'
                                aria-label='Edit'
                                onClick={() =>
                                  handleOpenForm(
                                    1,
                                    "key_performance_indicators",
                                  )
                                }
                              >
                                <MdEdit fontSize='large' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                        {rowNum === 1 ? (
                          <div className='position-relative'>
                            <ReactQuill
                              name='key_performance_indicators'
                              placeholder='Tambah Maklumat'
                              value={keyPerformanceIndicators}
                              onChange={(value) =>
                                setKeyPerformanceIndicators(value)
                              }
                            />
                            <div
                              className='position-absolute d-flex gap-3'
                              style={{ bottom: 10, right: 10, zIndex: 1 }}
                            >
                              <Button
                                size='sm'
                                variant='light'
                                onClick={handleCancel}
                                disabled={btnLoading}
                              >
                                Batal
                              </Button>
                              <Button
                                size='sm'
                                onClick={handleSave}
                                disabled={btnLoading}
                              >
                                Simpan
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {!isEmpty(keyPerformanceIndicators) ? (
                              <div className='p-3'>
                                {parse(keyPerformanceIndicators)}
                              </div>
                            ) : (
                              <div className='p-4 text-center'>
                                Maklumat tidak tersedia
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Kaedah Pengesahan</td>
                    <td colSpan={4} className='p-0'>
                      <div className='position-relative'>
                        <div
                          className={`position-absolute`}
                          style={{ top: 0, right: 0, zIndex: 1 }}
                        >
                          <Tooltip title='Edit' placement='top' arrow>
                            <span>
                              <IconButton
                                color='primary'
                                size='small'
                                aria-label='Edit'
                                onClick={() =>
                                  handleOpenForm(2, "validation_method")
                                }
                              >
                                <MdEdit fontSize='large' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                        {rowNum === 2 ? (
                          <div className='position-relative'>
                            <ReactQuill
                              name='validation_method'
                              placeholder='Tambah Maklumat'
                              value={validationMethod}
                              onChange={(value) => setValidationMethod(value)}
                            />
                            <div
                              className='position-absolute d-flex gap-3'
                              style={{ bottom: 10, right: 10, zIndex: 1 }}
                            >
                              <Button
                                size='sm'
                                variant='light'
                                onClick={handleCancel}
                                disabled={btnLoading}
                              >
                                Batal
                              </Button>
                              <Button
                                size='sm'
                                onClick={handleSave}
                                disabled={btnLoading}
                              >
                                Simpan
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {!isEmpty(validationMethod) ? (
                              <div className='p-3'>
                                {parse(validationMethod)}
                              </div>
                            ) : (
                              <div className='p-4 text-center'>
                                Maklumat tidak tersedia
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Andaian</td>
                    <td colSpan={4} className='p-0'>
                      <div className='position-relative'>
                        <div
                          className={`position-absolute`}
                          style={{ top: 0, right: 0, zIndex: 1 }}
                        >
                          <Tooltip title='Edit' placement='top' arrow>
                            <span>
                              <IconButton
                                color='primary'
                                size='small'
                                aria-label='Edit'
                                onClick={() => handleOpenForm(3, "assumption")}
                              >
                                <MdEdit fontSize='large' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                        {rowNum === 3 ? (
                          <div className='position-relative'>
                            <ReactQuill
                              name='assumption'
                              placeholder='Tambah Maklumat'
                              value={assumption}
                              onChange={(value) => setAssumption(value)}
                            />
                            <div
                              className='position-absolute d-flex gap-3'
                              style={{ bottom: 10, right: 10, zIndex: 1 }}
                            >
                              <Button
                                size='sm'
                                variant='light'
                                onClick={handleCancel}
                                disabled={btnLoading}
                              >
                                Batal
                              </Button>
                              <Button
                                size='sm'
                                onClick={handleSave}
                                disabled={btnLoading}
                              >
                                Simpan
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {!isEmpty(assumption) ? (
                              <div className='p-3'>{parse(assumption)}</div>
                            ) : (
                              <div className='p-4 text-center'>
                                Maklumat tidak tersedia
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {enableBtnSave && (
              <div className='d-flex justify-content-end'>
                <Button
                  onClick={handleSubmit}
                  // disabled={(passedSteps || []).includes(4) ? false : true}
                >
                  Simpan
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default FrameworkForm;
