import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingState from "../../../../../components/common/LoadingState";
import { isEmpty } from "lodash";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";
import parse from "html-react-parser";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiSend, mdiClose } from "@mdi/js";
import { MdAttachment, MdMap } from "react-icons/md";

// Options
import { fundingSourceOptions } from "../../../../../utils/common/options-data";

const SummaryView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);
  const [ceilingFlow, setCeilingFlow] = useState([]);
  const [stages, setStages] = useState(0);
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [fundingSources, setFundingSources] = useState([]);

  const colSpan = 5;

  const { isLoading, project } = useSelector((state) => ({
    project: state.projectReducer.project,
    isLoading: state.projectReducer.isLoading,
  }));

  useEffect(() => {
    let isMounted = true;
    if (!isEmpty(project) && isMounted) {
      const { financial_details, rmk, funding_sources } = project;

      const updatedArray = financial_details?.map((financial) => {
        const updatedChildren = financial.ceiling_flow_details?.map((flow) => ({
          ...flow,
          financial_detail_id: financial.id,
          cost_description: financial.cost_description,
        }));
        return { ...financial, ceiling_flow_details: updatedChildren };
      });
      const childrenArray = updatedArray
        ?.map((financial) => financial.ceiling_flow_details)
        ?.reduce((acc, flow) => acc.concat(flow), []);

      const groupedByYear = childrenArray?.reduce(
        (acc, item) => {
          const { year } = item;

          if (year === rmk?.first_year) {
            acc[rmk?.first_year].push(item);
          } else if (year === rmk?.second_year) {
            acc[rmk?.second_year].push(item);
          } else if (year === rmk?.third_year) {
            acc[rmk?.third_year].push(item);
          } else if (year === rmk?.fourth_year) {
            acc[rmk?.fourth_year].push(item);
          } else if (year === rmk?.fifth_year) {
            acc[rmk?.fifth_year].push(item);
          }
          return acc;
        },
        {
          [rmk?.first_year]: [],
          [rmk?.second_year]: [],
          [rmk?.third_year]: [],
          [rmk?.fourth_year]: [],
          [rmk?.fifth_year]: [],
        },
      );

      const ceilingFlow = Object.entries(groupedByYear).map(([year, data]) => ({
        year: year,
        data,
      }));

      setCeilingFlow(ceilingFlow);

      let fundingSourcesValue = "";

      if (project.funding_sources === 3) {
        fundingSourcesValue = fundingSourceOptions
          .map((item) => item.label)
          .join(", ");
      } else {
        fundingSourcesValue = fundingSourceOptions.find(
          (item) => item.value === project?.funding_sources,
        )?.label;
      }

      setFundingSources(fundingSourcesValue);
    }

    return () => {
      isMounted = false;
    };
  }, [project]);

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <h5 className='text-center mb-1 mt-2'>
            {`Keterangan Mengenai Maklumat Program/Projek`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-1'>
            {`${project.rmk?.name}(${project.rmk?.short_form})`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-3'>
            {"(Pembiyaan Kerajaan Negeri)".toLocaleUpperCase()}
          </h5>

          <Table bordered size='sm' style={{ fontSize: 12 }}>
            <tbody>
              {/* Project Profile */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='text-center' style={{ width: "5%" }}>
                  Bil.
                </td>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Profil
                </th>
                <th className='border-start-0' colSpan={colSpan}></th>
              </tr>
              <tr>
                <td className='text-center'>1</td>
                <td className='fw-bold'>Kod Projek</td>
                <td colSpan={colSpan}>
                  {`${project.program?.prefix}${
                    project.program?.ministry_code
                  }-${project.executing_agency?.code}-000000-${
                    project.program?.code
                  }-${project.main_project?.code}${
                    project.code ? project.code : "0000"
                  }-00000`}
                </td>
              </tr>
              <tr>
                <td className='text-center'>2</td>
                <td className='fw-bold'>Kementerian</td>
                <td colSpan={colSpan}>
                  {project?.ministry?.value}({project?.ministry?.short_form})
                </td>
              </tr>
              <tr>
                <td className='text-center'>3</td>
                <td className='fw-bold'>Jabatan / Agensi</td>
                <td colSpan={colSpan}>{project?.department?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>4</td>
                <td className='fw-bold'>Seksyen UPEN</td>
                <td colSpan={colSpan}>{project?.upen_section?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>5</td>
                <td className='fw-bold'>Program</td>
                <td colSpan={colSpan}>{project?.program?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>6</td>
                <td className='fw-bold'>Nama Projek</td>
                <td colSpan={colSpan}>{project?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>7</td>
                <td className='fw-bold'>Keterangan Projek</td>
                <td colSpan={colSpan}>{project?.description}</td>
              </tr>
              <tr>
                <td className='text-center'>8</td>
                <td className='fw-bold'>Justifikasi Projek</td>
                <td colSpan={colSpan}>{project?.justification}</td>
              </tr>
              <tr>
                <td className='text-center'>9</td>
                <td className='fw-bold'>KPI Program dan Projek</td>
                <td colSpan={colSpan}>{project?.key_performance_indicator}</td>
              </tr>
              <tr>
                <td className='text-center'>10</td>
                <td className='fw-bold'>Sektor Utama</td>
                <td colSpan={colSpan}>{project?.main_sector?.name}</td>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.strategic_directions?.length + 1}
                >
                  11
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.strategic_directions?.length + 1}
                >
                  Halatuju Strategi
                </td>
              </tr>
              {/* Strategic directions */}
              {project?.strategic_directions?.map((item, idx) => {
                const lastIndex = project?.strategic_directions.length - 1;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      lastIndex === idx ? "" : "border-bottom-0"
                    } border-top-0`}
                  >
                    <td className='ps-0' colSpan={colSpan}>
                      <ul className='mb-0 pe-0 ps-4'>
                        <li>{item.data?.name}</li>
                      </ul>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className='text-center'>12</td>
                <td className='fw-bold'>Kategori Projek</td>
                <td colSpan={colSpan}>
                  {`${project?.project_category?.value} (${project?.project_category?.code})`}
                </td>
              </tr>
              <tr>
                <td className='text-center'>13</td>
                <td className='fw-bold'>Jenis Projek</td>
                <td colSpan={colSpan}>{project?.project_type?.name}</td>
              </tr>

              {/* Financial Infomation */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Kewangan
                </th>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.financial_details?.length + 2}
                >
                  14
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.financial_details?.length + 2}
                >
                  Skop dan Kos Dipohon
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={3}
                >
                  Skop
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Nilai
                </td>
              </tr>
              {project?.financial_details?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={3}>{item.cost_description}</td>
                  <td colSpan={2} className='text-center'>
                    {`RM ${numeral(item.cost_required).format("0,0.00")}`}
                  </td>
                </tr>
              ))}
              <tr>
                <td className='text-end' colSpan={3}>
                  Jumlah
                </td>
                <td className='text-center' colSpan={2}>
                  {`RM ${numeral(
                    project?.financial_details?.reduce(
                      (acc, financial) =>
                        acc + parseFloat(financial.cost_required),
                      0,
                    ),
                  ).format("0,0.00")}`}
                </td>
              </tr>
              <tr>
                <td className='text-center' rowSpan={2}>
                  15
                </td>
                <td className='fw-bold' rowSpan={2}>
                  Aliran Siling
                </td>
                {ceilingFlow?.map((item) => (
                  <td
                    key={item.year}
                    className='text-center'
                    style={{ backgroundColor: "#eff2f7" }}
                  >
                    Tahun {item.year}
                  </td>
                ))}
              </tr>
              <tr>
                {ceilingFlow?.map((item) => {
                  const costRequired = item.data?.reduce(
                    (acc, flow) => acc + parseFloat(flow.amount),
                    0,
                  );
                  return (
                    <td key={item.year} className='text-center'>
                      {`RM ${numeral(costRequired).format("0,0.00")}`}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <td className='text-center'>16</td>
                <td className='fw-bold'>Sumber Pembiyaan</td>
                <td colSpan={colSpan}>{fundingSources}</td>
              </tr>

              {/* Physical Information  */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Fizikal
                </th>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.financial_details?.length + 1}
                >
                  17
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.financial_details?.length + 1}
                >
                  Skop Projek
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Skop
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Mula (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Siap (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tempoh
                </td>
              </tr>
              {project?.financial_details?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={2}>{item.cost_description}</td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_start_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_completion_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {item.project_scope_details?.expected_completion_date &&
                      formatDistanceStrict(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        {
                          addSuffix: false,
                          locale: ms,
                        },
                      )}
                  </td>
                </tr>
              ))}

              <tr>
                <td className='text-center'>18</td>
                <td className='fw-bold'>Tarikh Tender (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_tender_date) &&
                    format(
                      new Date(project.physical_details?.expected_tender_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>19</td>
                <td className='fw-bold'>Tarikh Mula Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_start_date) &&
                    format(
                      new Date(project.physical_details?.expected_start_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>20</td>
                <td className='fw-bold'>Tarikh Siap Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(
                    project.physical_details?.expected_completion_date,
                  ) &&
                    format(
                      new Date(
                        project.physical_details?.expected_completion_date,
                      ),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>21</td>
                <td className='fw-bold'>Keluasan Tapak</td>
                <td colSpan={colSpan}>
                  {numeral(project.physical_details?.site_width).format(
                    "0,0.00",
                  )}{" "}
                  ekar
                </td>
              </tr>

              <tr>
                <td className='text-center'>22</td>
                <td className='fw-bold'>
                  Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
                </td>
                <td colSpan={colSpan}>
                  {project.physical_details
                    ?.proposed_land_in_accordance_with_local_plans
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>

              <tr>
                <td className='text-center'>23</td>
                <td className='fw-bold'>Cadangan Berkaitan Utiliti</td>
                <td colSpan={colSpan}>
                  {project.physical_details?.proposed_utility ? "Ya" : "Tidak"}
                </td>
              </tr>

              {/* Others */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Lain
                </th>
              </tr>

              <tr>
                <td className='text-center' rowSpan={4}>
                  25
                </td>
                <td className='fw-bold' rowSpan={4}>
                  Matriks Rangka Kerja Logik
                </td>
              </tr>

              <tr>
                <td colSpan={colSpan}>
                  <h5>Petunjuk Utama Prestasi</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.key_performance_indicators)} */}
                </td>
              </tr>
              <tr>
                <td colSpan={colSpan}>
                  <h5>Kaedah Pengesahan</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.validation_method)} */}
                </td>
              </tr>
              <tr>
                <td colSpan={colSpan}>
                  <h5>Andaian</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.assumption)} */}
                </td>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.human_resources_requirements?.length + 2}
                >
                  26
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.human_resources_requirements?.length + 2}
                >
                  Keperluan Sumber Manusia
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Bilangan Staff
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Nama Jawatan
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Gred Jawatan
                </td>
              </tr>
              {project?.human_resources_requirements?.map((item) => (
                <tr key={item.id}>
                  <td className='text-center'>{item.number_of_staff}</td>
                  <td colSpan={2}>{item.position_name}</td>
                  <td className='text-center' colSpan={2}>
                    {item.position_gred}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SummaryView;
