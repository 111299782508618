import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const DistrictGraphOne = () => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);

  const { data } = useSelector((state) => ({
    data: state.microProjectReducer.dashboards.byDistricts,
  }));

  const options = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: labels,
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
    title: {
      text: "Bilangan Projek Diluluskan Mengikut Daerah",
      floating: false,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };

  useEffect(() => {
    if (isEmpty(data)) return;
    setLabels(data?.labels);
    setSeries(data?.series);
  }, [data]);

  return (
    <React.Fragment>
      <Card>
        <Card.Body className='p-4'>
          <div id='chart'>
            <ReactApexChart
              options={options}
              series={series}
              type='bar'
              height={350}
            />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default DistrictGraphOne;
