import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

// Redux
import {
  setSearchMinistry,
  setDepartmentOptions,
  setSearchDepartment,
} from "../../../../../redux/slices/user-management/usersManagementSlice";

const Filters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { user, ministryOptions, departments, districtOptions } = useSelector(
    (state) => ({
      user: state.userReducer.user,
      ministryOptions: state.utilsReducer.dropdownItems.ministries,
      departments: state.utilsReducer.dropdownItems.departments,
      districtOptions: state.utilsReducer.dropdownItems.districtItems,
    }),
  );

  const handleChangeMinistry = (e) => {
    const value = e.target.value;

    if (!isEmpty(value)) {
      const newDepartmentOptions = departments.filter(
        (item) => item.ministry_code === value,
      );

      setDepartmentOptions(newDepartmentOptions);
    } else {
      setDepartmentOptions(departments);
    }

    dispatch(setSearchMinistry(value));
  };

  const goToRegistrationForm = () => {
    navigate(`/pengurusan-pengguna/daftar-pengguna/baru`);
  };

  useEffect(() => {
    if (!isEmpty(ministryOptions)) {
      setDepartmentOptions([]);
    }
  }, [ministryOptions]);

  return (
    <React.Fragment>
      <div className='mb-3 d-flex justify-content-between gap-3'>
        <div className='d-flex gap-3'>
          <Form.Group style={{ width: "25%" }}>
            <Form.Select onChange={(e) => handleChangeMinistry(e)}>
              <option value=''>Semua Kementerian</option>
              {ministryOptions.map((item) => (
                <option key={item.id} value={item.code}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group style={{ width: "25%" }}>
            <Form.Select
              onChange={(e) => {
                const value = e.target.value;
                dispatch(setSearchDepartment(value));
              }}
            >
              <option value=''>Semua Jabatan</option>
              {!isEmpty(departmentOptions) ? (
                <>
                  {departmentOptions.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </>
              ) : (
                <>
                  {departments.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Select>
              <option value=''>Semua Daerah</option>
              {districtOptions.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        {user.roles.includes("superadmin") && (
          <div>
            <Button className='text-truncate' onClick={goToRegistrationForm}>
              Daftar Pengguna Baru
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Filters;
