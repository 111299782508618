import React, { useState } from "react";
import { Table, Pagination, Form, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { format } from "date-fns";

const ActivityLog = ({
  page,
  goToPage,
  perPage,
  handleChangePerPage,
  goToFirstPage,
  previousPage,
  nextPage,
  goToLastPage,
}) => {
  const [pageValue, setPageValue] = useState("1");

  const { activities, meta, links } = useSelector((state) => ({
    activities: state.activityReducer.activities,
    meta: state.activityReducer.meta,
    links: state.activityReducer.links,
  }));

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm' style={{ fontSize: 11 }}>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th>Bil.</th>
              <th>Tarikh</th>
              <th>Keterangan</th>
              <th>Maklumat Baru</th>
              <th>Maklumat Lama</th>
              <th>Tindakan oleh</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((item, idx) => {
              let oldData = null;
              let newData = null;

              if (!isEmpty(item.properties.old)) {
                oldData = Object.entries(item.properties?.old);
              }
              if (!isEmpty(item.properties.attributes)) {
                newData = Object.entries(item.properties?.attributes);
              }

              return (
                <tr key={item.id}>
                  <td>{(page - 1) * perPage + idx + 1}</td>
                  <td className='text-truncate'>
                    {format(new Date(item.updated_at), "dd-MM-yyyy, hh:mm a")}
                  </td>
                  <td>
                    <div>{item.description}</div>
                    <div>
                      <span className='me-1'>SUBJEK ID :</span>
                      {item.subject_id}
                    </div>
                  </td>

                  <td>
                    <ListGroup>
                      {newData?.map(([key, value]) => {
                        let newValue = "";
                        let obj = null;

                        if (Object.prototype.isPrototypeOf(value)) {
                          obj = value;
                        } else {
                          newValue = value;
                        }
                        return (
                          <li key={key} className='d-flex'>
                            <p className='mb-0'>{key}</p>
                            <p className='mb-0'> : {obj?.name || newValue}</p>
                          </li>
                        );
                      })}
                    </ListGroup>
                  </td>
                  <td>
                    <ListGroup>
                      {oldData?.map(([key, value]) => {
                        let newValue = "";
                        let obj = null;

                        if (Object.prototype.isPrototypeOf(value)) {
                          obj = value;
                        } else {
                          newValue = value;
                        }
                        return (
                          <li key={key} className='d-flex'>
                            <p className='mb-0'>{key} :</p>
                            <p className='mb-0'>{obj?.name || newValue}</p>
                          </li>
                        );
                      })}
                    </ListGroup>
                  </td>
                  <td>
                    {item.action_by ? item.action_by.name : "Tiada maklumat"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className='d-flex justify-content-between pt-3'>
        <div>
          <span className=''>
            Halaman{" "}
            <strong>
              {meta?.current_page} daripada {meta?.last_page}
            </strong>
          </span>
          <span className='ms-3 me-2'>Pergi ke halaman:</span>
          <Form.Control
            className='d-inline-block'
            value={pageValue}
            onChange={(e) => {
              const value = Number(e.target.value);
              setPageValue(e.target.value);
              if (
                isEmpty(e.target.value) ||
                value <= 0 ||
                value > meta.last_page ||
                isNaN(value)
              )
                return false;

              goToPage(value);
            }}
            style={{ width: "75px" }}
          />
          <span className='ms-3 me-2'>Tunjuk:</span>
          <Form.Select
            className='d-inline-block w-auto'
            value={perPage}
            onChange={(e) => handleChangePerPage(e)}
          >
            {[15, 30, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>
        </div>
        <div>
          <Pagination className='float-end remove-outline-item'>
            <Pagination.First
              onClick={() => goToFirstPage()}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={links?.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => goToLastPage(meta.last_page)}
              disabled={links?.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActivityLog;
