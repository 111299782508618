import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

import {
  fetchMicroProjectUsers,
  createUser,
  getUser,
  updateUser,
  removeUser,
} from "./userSlice";

const initialState = {
  users: {
    data: [],
    meta: {},
    links: {},
    page: 1,
    perPage: 30,
    keywords: "",
  },
  user: null,
  temp_password: "",
  temp_name: "",
  isLoading: false,
  btnLoading: false,
};

export const usersSlice = createSlice({
  name: "microProjectUsers",
  initialState,
  reducers: {
    resetUserForm: (state) => {
      state.user = null;
    },
    setPage: (state, action) => {
      state.users.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.users.perPage = action.payload;
    },
    setKeywords: (state, action) => {
      state.users.keywords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMicroProjectUsers.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMicroProjectUsers.fulfilled, (state, action) => {
      state.users.data = action.payload?.data;
      state.users.meta = action.payload?.meta;
      state.users.links = action.payload?.links;

      state.isLoading = false;
    });

    builder.addCase(createUser.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      const { user, password } = action.payload;
      state.users.data.push(user);
      state.temp_password = password;
      state.temp_name = user.name;
      state.btnLoading = false;
    });

    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.btnLoading = false;
    });

    builder.addCase(removeUser.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      const data = action.payload;
      const id = data.id;

      if (data.is_deleted) {
        state.users.data = state.users.data.filter(
          (item) => item.id.toString() !== id.toString(),
        );
      } else {
        state.users.data = state.users.data.map((user) => {
          if (id === user.id) {
            return {
              ...data,
              is_deleted: true,
            };
          }
          return user;
        });
      }

      state.btnLoading = false;
    });
  },
});

export const { resetUserForm, setPage, setPerPage, setKeywords } =
  usersSlice.actions;

export default usersSlice.reducer;
