import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Card, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiCheckCircle } from "@mdi/js";
import { IoAlert } from "react-icons/io5";

// Components
import TableFinancialList from "../tables/TableFinancialList";
import TableCeilingFlowInfo from "../tables/TableCeilingFlowInfo";
import { fundingSourceOptions } from "../../../../../utils/common/options-data";

// Redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";

const FinancialForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  activeRmk,
  project,
  projectId,
  btnLoading,
  enableBtnSave,
}) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const notyf = useContext(NotyfContext);

  const [errors, setErrors] = useState({});
  const [fundingSourceValues, setFundingSourceValues] = useState([]);

  const { financialDetails } = useSelector((state) => ({
    financialDetails: state.projectReducer.project.financial_details,
  }));
  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    // validationSchema: Yup.object().shape({
    //   funding_sources: Yup.string().required("Sila pilih sumber pembiayaan"),
    // }),
    onSubmit: async (values) => {
      const newValues = {
        funding_sources: !isEmpty(fundingSourceValues)
          ? fundingSourceValues
          : [],
        id: project.id,
      };

      await dispatch(updateProject(newValues));
      setFundingSourceValues([]);
      handleNotification("success", null);
      handleChecked(2);
      handleExpand(3);
    },
  });

  const handleSubmit = () => {
    let errors = {};

    const totalFinancial = financialDetails.reduce(
      (acc, financial) => acc + parseFloat(financial.cost_required),
      0,
    );

    const ceilingFlowArray = financialDetails
      .map((obj) => obj.ceiling_flow_details)
      .reduce((acc, curr) => acc.concat(curr), []);

    const totalCeilingFlowAmount = ceilingFlowArray.reduce(
      (acc, flow) => acc + parseFloat(flow.amount),
      0,
    );

    const balance = totalFinancial - totalCeilingFlowAmount;

    if (balance !== 0) {
      errors.balance =
        "Jumlah siling dipohon dan jumlah aliran siling tidak sama, sila semak semula nilai aliran siling";
    }

    if (isEmpty(financialDetails)) {
      errors.financial = "Sila isi maklumat keterangan kos dan jumlah nilai";
    }

    if (isEmpty(ceilingFlowArray)) {
      errors.ceiling_flow = "Sila isi maklumat aliran siling";
    }

    // if (!isEmpty(errors)) {
    //   handleNotification("error", errors);
    //   setErrors(errors);
    //   return false;
    // } else {
    //   setErrors({});
    // }

    formik.handleSubmit();
  };

  const handleCheckFundingSources = (value) => {
    const num = Number(value);
    if (fundingSourceValues?.includes(num)) {
      const removeItem = fundingSourceValues.filter((item) => item != num);
      setFundingSourceValues(removeItem);
    } else {
      setFundingSourceValues((prev) => [...prev, num]);
    }
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        const errors = Object.values(values);

        errors.map((error) => {
          notyf.open({
            type: type,
            message: error,
            duration: 7000,
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "top",
            },
          });
        });
        return false;
        break;

      case "edited":
        msg = `Maklumat kewangan berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat kewangan berjaya ditambah.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 2000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    if (isEmpty(project)) return;

    const errorMessages = {};

    if (isEmpty(financialDetails)) {
      errorMessages.financial_details = "Sila isi maklumat kewangan";
    } else {
      delete errors.financial_details;
    }

    const isCeilingFlowEmpty = financialDetails.some(
      (item) => isEmpty(item.ceiling_flow_details) === true,
    );

    if (isCeilingFlowEmpty) {
      errorMessages.ceiling_flow = "Sila isi maklumat aliran tunai";
    } else {
      delete errors.ceiling_flow;
    }

    const totalFinancial = financialDetails.reduce(
      (acc, financial) => acc + parseFloat(financial.cost_required),
      0,
    );

    const ceilingFlowArray = financialDetails
      .map((obj) => obj.ceiling_flow_details)
      .reduce((acc, curr) => acc.concat(curr), []);

    const totalCeilingFlowAmount = ceilingFlowArray.reduce(
      (acc, flow) => acc + parseFloat(flow.amount),
      0,
    );

    const balance = totalFinancial - totalCeilingFlowAmount;
    // if (balance !== 0) {
    //   errors.balance =
    //     "Jumlah siling dipohon dan jumlah aliran siling tidak sama, sila semak semula nilai aliran siling";
    // } else {
    //   delete errors.balance;
    // }

    setErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));

    return () => {
      isMounted = false;
    };
  }, [financialDetails]);

  useEffect(() => {
    if (!isEmpty(project)) {
      setFundingSourceValues(project.funding_sources);
    }
  }, [project]);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(2)}
      >
        {isEmpty(errors) && isEmpty(formik.errors) ? (
          <Icon
            className='me-1'
            path={mdiCheckCircle}
            color={palette.success}
            style={{ height: 28, width: 28 }}
          />
        ) : (
          <>
            <span
              className='number'
              style={{ color: palette.danger, borderColor: palette.danger }}
            >
              2.
            </span>
          </>
        )}
        Maklumat Kewangan
        {!isEmpty(errors) && <IoAlert size={24} color={palette.danger} />}
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <TableFinancialList
            notifyError={errors.financial}
            project={project}
            projectId={projectId}
            handleNotification={handleNotification}
            btnLoading={btnLoading}
            enableBtnSave={enableBtnSave}
          />

          <TableCeilingFlowInfo
            notifyError={errors}
            project={project}
            projectId={projectId}
            activeRmk={activeRmk}
            handleNotification={handleNotification}
            enableBtnSave={enableBtnSave}
          />
          <Card.Body>
            <Form noValidate autoComplete='off'>
              <Form.Group as={Row} className='mb-3 d-flex align-items-center'>
                <Form.Label column sm={3} className='text-sm-end'>
                  Sumber Pembiayaan :
                  {formik.touched.funding_sources &&
                    formik.errors.funding_sources && (
                      <p className='mt-1 custom-feedback-invalid'>
                        {formik.errors.funding_sources}
                      </p>
                    )}
                </Form.Label>
                <Col sm={9}>
                  <div className='custom-controls-stacked'>
                    {fundingSourceOptions.map((item) => {
                      return (
                        <Form.Check
                          key={item.id}
                          inline
                          type='checkbox'
                          id={item.id}
                          name='funding_sources'
                          label={item.value}
                          value={item.id}
                          checked={
                            fundingSourceValues?.includes(item.id) || null
                          }
                          onChange={(e) =>
                            handleCheckFundingSources(e.target.value)
                          }
                          isInvalid={
                            formik.touched.funding_sources &&
                            formik.errors.funding_sources
                              ? true
                              : false
                          }
                        />
                      );
                    })}
                  </div>
                </Col>
              </Form.Group>

              {enableBtnSave && (
                <div className='d-flex justify-content-end'>
                  <Button
                    onClick={handleSubmit}
                    variant='primary'
                    disabled={
                      (passedSteps || []).includes(1) || !isEmpty(project)
                        ? false
                        : true
                    }
                  >
                    Simpan maklumat kewangan
                  </Button>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default FinancialForm;
