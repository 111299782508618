import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchAnnouncements = createAsyncThunk(
  "announcements/fetchAnnouncements",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/announcements?with=query`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createAnnouncement = createAsyncThunk(
  "announcements/createAnnouncement",
  async (values) => {
    try {
      const response = await axios.post(`/announcements`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getAnnouncement = createAsyncThunk(
  "announcements/getAnnouncement",
  async (props) => {
    const { uuid, source } = props;
    try {
      const response = await axios.get(`/announcements/${uuid}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const updateAnnouncement = createAsyncThunk(
  "announcements/updateAnnouncement",
  async (values) => {
    const { uuid } = values;
    try {
      const response = await axios.put(`/announcements/${uuid}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const removeAnnouncement = createAsyncThunk(
  "announcements/removeAnnouncement",
  async (uuid) => {
    try {
      const response = await axios.delete(`/announcements/${uuid}`);

      return uuid;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

const initialState = {
  announcements: [],
  announcement: null,
  isLoading: false,
  btnLoading: false,
};

export const annoucementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    resetValues: (state, action) => {
      state.announcement = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnnouncements.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAnnouncements.fulfilled, (state, action) => {
      state.announcements = action.payload;
      state.isLoading = false;
    });

    builder.addCase(createAnnouncement.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(createAnnouncement.fulfilled, (state, action) => {
      state.announcements.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(getAnnouncement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAnnouncement.fulfilled, (state, action) => {
      state.announcement = action.payload;
      state.isLoading = false;
    });

    builder.addCase(updateAnnouncement.fulfilled, (state, action) => {
      const data = action.payload;
      state.announcements = state.announcements.map((item) => {
        if (item.uuid === data.uuid) {
          return data;
        }
        return item;
      });
    });

    builder.addCase(removeAnnouncement.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeAnnouncement.fulfilled, (state, action) => {
      state.announcements = state.announcements.filter(
        (item) => item.uuid.toString() !== action.payload.toString(),
      );
      state.btnLoading = false;
    });
  },
});

export const { resetValues } = annoucementsSlice.actions;

export default annoucementsSlice.reducer;
