import React from "react";
import { Skeleton } from "@mui/material";

const ThreeColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const FourColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const FiveColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const SixColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const SevenColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const EightColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const NineColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const TenColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const ElevenColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const TweleColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const ThirdteenColumns = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const FourTeen = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5].map((num) => (
        <tr key={num}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((row) => (
            <td key={row}>
              <Skeleton width='100%' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export {
  ThreeColumns,
  FourColumns,
  FiveColumns,
  SixColumns,
  SevenColumns,
  EightColumns,
  NineColumns,
  TenColumns,
  ElevenColumns,
  TweleColumns,
  ThirdteenColumns,
  FourTeen,
};
