import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
// Icons
import Icon from "@mdi/react";
import {
  mdiSwapVerticalBold,
  mdiNoteTextOutline,
  mdiCircle,
  mdiCog,
} from "@mdi/js";

// Components
import NotesSection from "../../modals/NotesSection";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

// Redux
import {
  removeComponent,
  updateCertifyFinancialItem,
} from "../../../../../../redux/slices/utilsSlice";
import { getComponent } from "../../../../../../redux/slices/projects/financialDetailsSlice";

const FinancialDetails = ({
  showColumn,
  stages,
  handleCertifyCost,
  handleIsError,
  handleChangeInitialCost,
  scopeProjectRef,
}) => {
  const dispatch = useDispatch();

  const [openNotes, setOpenNotes] = useState(false);
  const [item, setItem] = useState({});

  const [viewColumns, setViewColumns] = useState({
    dipohon: {
      checked: true,
      disabled: false,
    },
    upen: {
      checked: true,
      disabled: false,
    },
    jppn: {
      checked: true,
      disabled: false,
    },
  });

  const [components, setComponents] = useState([]);
  const [columnTitle, setColumnTitle] = useState({
    upen: "PERAKUAN",
    jppn: "PERAKUAN",
    cabinet: "KELULUSAN",
  });
  const [checkedCostId, setCheckCostId] = useState([]);
  const [isIndeterminateCost, setIsIndeterminateCost] = useState(false);
  const [costValue, setCostValue] = useState([]);
  const [costValueErrors, setCostValueErrors] = useState([]);

  const { project, ceilingFlowState } = useSelector((state) => ({
    project: state.certifyReducer.project.data,
    ceilingFlowState: state.utilsReducer.certifyFinancial,
  }));

  const handleCheckAllCost = () => {
    if (components?.length === checkedCostId.length) {
      return setCheckCostId([]);
    }

    const arr = components.map((item) => item.financial_detail_id);
    setCheckCostId(arr);
  };

  const handleCheckSingleId = (component) => {
    const { financial_details } = project;
    const { financial_detail_id } = component;
    const findInitialCost = financial_details.find(
      (item) => item.id === financial_detail_id,
    );
    const { cost_required } = findInitialCost;

    const cost = {
      id: component.financial_detail_id,
      cost_required: parseFloat(cost_required),
    };
    handleCertifyCost(cost);
    const isExist =
      checkedCostId.includes(financial_detail_id) &&
      costValue.some((item) => item.id === financial_detail_id);
    if (isExist) {
      const removeId = checkedCostId.filter(
        (item) => item !== financial_detail_id,
      );
      setCheckCostId(removeId);
      const removeItem = costValue.filter(
        (item) => item.id !== financial_detail_id,
      );
      setCostValue(removeItem);
      handleIsError(false);
    } else {
      const addId = [...checkedCostId, financial_detail_id];
      setCheckCostId(addId);
      setCostValue((prev) => [...prev, cost]);
    }
  };

  const handleInitialCost = (value, obj, certifyingAmount) => {
    const { id } = obj;

    const findIntialCost = costValue.find((item) => item.id === id);

    const newValue = {
      ...findIntialCost,
      cost_required: value,
    };

    setCostValue((prev) =>
      costValue.map((item) => {
        if (item.id === id) {
          return newValue;
        }
        return item;
      }),
    );

    handleChangeInitialCost(newValue);

    if (certifyingAmount > value) {
      const isTrue = costValueErrors.some((item) => item === id);
      if (!isTrue) setCostValueErrors((prev) => [...prev, id]);
      handleIsError(true);
    } else {
      setCostValueErrors((prev) =>
        costValueErrors.filter((item) => item !== id),
      );
      handleIsError(false);
    }
  };

  const handleRemoveComponent = async (component) => {
    await dispatch(getComponent(component.financial_detail_id));
    await dispatch(removeComponent(component.financial_detail_id));
  };

  const handleOpenNoteSection = (item, section) => {
    const newValues = {
      id: item.financial_detail_id,
    };
    setOpenNotes(true);
    setItem(newValues);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
    setItem({});
  };

  const handleViewColumn = (e) => {
    const value = e.target.value;
    const column = viewColumns[value];
    setViewColumns({
      ...viewColumns,
      [value]: {
        ...column,
        checked: !column.checked,
      },
    });
  };

  useEffect(() => {
    let isCheck = true;
    switch (true) {
      case checkedCostId.length === 0:
        isCheck = false;
        break;

      case components.length === checkedCostId.length:
        isCheck = false;
        break;
    }

    setIsIndeterminateCost(isCheck);
  }, [checkedCostId]);

  useEffect(() => {
    let isMounted = true;

    if (!isMounted) return;

    if (!isEmpty(ceilingFlowState)) {
      const groupedData = ceilingFlowState.reduce((acc, obj) => {
        const key = `${obj.financial_detail_id}_${obj.cost_description}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      const financialDetails = Object.entries(groupedData).map(
        ([uniqueId, items]) => {
          const splitText = uniqueId.split("_");

          return {
            financial_detail_id: parseInt(splitText[0]),
            cost_description: splitText[1],
            items,
          };
        },
      );
      setComponents(financialDetails);
    }
    return () => {
      isMounted = false;
    };
  }, [ceilingFlowState]);

  useEffect(() => {
    if (stages === "4") {
      setViewColumns({
        ...viewColumns,
        ["jppn"]: {
          checked: false,
          disabled: true,
        },
      });
    }

    return () => {};
  }, [stages]);

  return (
    <React.Fragment>
      <NotesSection
        openNotes={openNotes}
        handleCloseNotes={handleCloseNotes}
        data={item}
        section='financial'
      />

      <div className='mb-3' ref={scopeProjectRef}>
        <div className='d-flex justify-content-between mb-2'>
          <h5>SENARAI SKOP PROJEK YANG TELAH DIPOHON</h5>

          <div className='d-flex align-items-center' style={{ fontSize: 12 }}>
            <Tooltip title='Sembunyi kolum' placement='top' arrow>
              <Icon className='me-2' path={mdiCog} size={0.8} />
            </Tooltip>
            {Object.keys(viewColumns)
              .map((key) => ({ label: key, ...viewColumns[key] }))
              .map((item) => (
                <Form.Group
                  key={item.label}
                  className='me-3 text-uppercase'
                  controlId={item.label}
                >
                  <Form.Check
                    label={item.label}
                    value={item.label}
                    onChange={(e) => handleViewColumn(e)}
                    checked={viewColumns[item.label].checked}
                    disabled={viewColumns[item.label].disabled}
                  />
                </Form.Group>
              ))}
          </div>
        </div>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ fontSize: 11 }}>
            <TableHead
              showColumn={showColumn}
              columnTitle={columnTitle}
              isIndeterminateCost={isIndeterminateCost}
              checkAllCost={handleCheckAllCost}
              checkedCostId={checkedCostId}
              components={components}
              viewColumns={viewColumns}
            />
            <TableBody
              data={components}
              showColumn={showColumn}
              handleCostSingleId={handleCheckSingleId}
              checkedCostId={checkedCostId}
              handleRemoveComponent={handleRemoveComponent}
              handleOpenNoteSection={handleOpenNoteSection}
              handleInitialCost={handleInitialCost}
              costValue={costValue}
              errors={costValueErrors}
              viewColumns={viewColumns}
            />
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinancialDetails;
