import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingState from "../../../../../components/common/LoadingState";
import usePalette from "../../../../../hooks/usePalette";

// icons
import Icon from "@mdi/react";
import { mdiCloseCircle, mdiArrowRightBold } from "@mdi/js";

// Redux
import { setNewProjectName } from "../../../../../redux/slices/jppn/jppnModuleSlice";

const ProjectNameChanges = ({ nextPage }) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const routeParams = useParams();

  const [projectCode, setProjectCode] = useState("");
  const [executingAgency, setExecutingAgency] = useState("");
  const [projectCategory, setProjectCategory] = useState("");

  const {
    projectIsLoading,
    currentRmk,
    project,

    isLoading,

    application,
    draft,
  } = useSelector((state) => ({
    projectIsLoading: state.projectReducer.isLoading,
    currentRmk: state.rmkReducer.activeRmk,
    project: state.projectReducer.project,
    isLoading: state.jppnReducer.virement.isLoading,

    application: state.jppnReducer.application,
    draft: state.jppnReducer.draft,
  }));

  useEffect(() => {
    if (isEmpty(project)) return false;

    const newValues = {
      name: "old",
      value: project?.name,
    };

    dispatch(setNewProjectName(newValues));
  }, [project]);

  useEffect(() => {
    const { type, category } = routeParams;
    if (type === "kemas-kini") {
      if (category === "kenaikan-siling") {
        setProjectCode(
          application?.virement_projects[0]?.project?.sub_project_full_code,
        );
        setExecutingAgency(
          `${application?.virement_projects[0]?.project?.executing_agency?.name} (${application?.virement_projects[0]?.project?.executing_agency?.short_form})`,
        );
      }
      if (category === "perubahan-nama-projek") {
        setProjectCode(application?.project?.name);
        setExecutingAgency(
          `${application?.project?.executing_agency?.name} (${application?.project?.executing_agency?.short_form})`,
        );
        setProjectCategory(application?.project?.project_category?.name);
      }
    } else {
      setProjectCode(project?.sub_project_full_code);
      setExecutingAgency(
        `${project?.executing_agency?.value} (${project?.executing_agency?.short_form})`,
      );
      setProjectCategory(project?.project_category?.value);
    }
  }, [routeParams, application, project]);

  if (projectIsLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <div className='mb-3'>
        <h4 className='mb-3'>Maklumat Perubahan Nama Projek</h4>
        <div className='table-responsive'>
          <Table bordered size='sm' className='mb-0'>
            <tbody>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Kod
                </th>
                <td colSpan={4}>
                  {/* {routeParams.type === "kemas-kini"
                    ? application?.virement_projects[0]?.project
                        ?.sub_project_full_code
                    : project?.sub_project_full_code} */}
                  {projectCode}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Agensi Pelaksana
                </th>
                <td colSpan={4}>
                  {/* {routeParams.type === "kemas-kini"
                    ? `${application?.virement_projects[0]?.project?.executing_agency?.name} (${application?.virement_projects[0]?.project?.executing_agency?.short_form})`
                    : `${project?.executing_agency?.value} (${project?.executing_agency?.short_form})`} */}
                  {executingAgency}
                </td>
              </tr>

              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Kategori Projek
                </th>
                <td colSpan={4}>
                  {/* {routeParams.type === "kemas-kini"
                    ? application?.virement_projects[0]?.project
                        ?.project_category?.name
                    : project?.project_category?.value} */}
                  {projectCategory}
                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered size='sm'>
            <tbody>
              <tr style={{ backgroundColor: "#e0e0e0" }}>
                <th colSpan={5}>Perubahan Nama Projek</th>
              </tr>
              <tr style={{ backgroundColor: "#e0e0e0" }}>
                <th>Nama Projek Asal</th>
                <th />
                <th>Nama Projek Baharu</th>
              </tr>
              <tr>
                <td>{draft?.project_name?.old}</td>
                <td className='align-center text-center'>
                  <Icon path={mdiArrowRightBold} size={1} />
                </td>
                <td>
                  <Form.Control
                    name='new_project_name'
                    type='text'
                    as='textarea'
                    style={{ minHeight: 80, maxHeight: 180 }}
                    value={draft?.project_name?.new}
                    onChange={(e) => {
                      const newValues = {
                        name: "new",
                        value: e.target.value,
                      };

                      dispatch(setNewProjectName(newValues));
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button onClick={nextPage}>Seterusnya</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectNameChanges;
