import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import { annoucementType } from "../../../utils/common/options-data";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";

// Redux
import {
  createAnnouncement,
  getAnnouncement,
  updateAnnouncement,
  resetValues,
} from "../../../redux/slices/admin/announcementsSlice";

const AnnouncementForm = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const navigate = useNavigate();

  const { type, id } = routeParams;

  const [contentValue, setContentValue] = useState("");

  const { btnLoading, announcement } = useSelector((state) => ({
    btnLoading: state.announcementsReducer.btnLoading,
    announcement: state.announcementsReducer.announcement,
  }));

  const toolbar = [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "blockquote",
    "underline",
    { list: "ordered" },
    { list: "bullet" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: announcement?.title || "",
      type: announcement?.type || "",
      showed_at: !isEmpty(announcement)
        ? format(new Date(announcement?.showed_at), "yyyy-MM-dd")
        : "",
      ended_at: !isEmpty(announcement)
        ? format(new Date(announcement?.ended_at), "yyyy-MM-dd")
        : "",
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Sila pilih jenis"),
      title: Yup.string().required("Sila isi tajuk"),
      showed_at: Yup.string().required("Sila masukkan tarikh"),
      ended_at: Yup.string().required("Sila masukkan tarikh"),
    }),
    onSubmit: async (values) => {
      if (routeParams.type === "ubah") {
        const newValues = {
          ...values,
          uuid: routeParams.uuid,
          content: contentValue,
        };
        await dispatch(updateAnnouncement(newValues));
      } else {
        const newValues = {
          ...values,
          content: DOMPurify.sanitize(contentValue),
        };
        await dispatch(createAnnouncement(newValues));
      }

      navigate(-1);
    },
  });

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        uuid: routeParams.uuid,
      };

      await dispatch(getAnnouncement(props));
    };

    if (routeParams.type === "ubah") {
      initialize();
    }

    return () => {
      source.cancel();
      formik.resetForm();
      setContentValue("");
      dispatch(resetValues());
    };
  }, [dispatch, routeParams]);

  useEffect(() => {
    if (!isEmpty(announcement)) {
      setContentValue(announcement.content);
    }
  }, [announcement]);

  return (
    <React.Fragment>
      <Container fluid className='p-0'>
        <h4>Pengumuman {type}</h4>
        <Card>
          <Card.Body>
            <Card.Text>Sila isi maklumat yang diperlukan</Card.Text>
            <Form
              autoComplete='off'
              autoCorrect='off'
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Form.Group className='mb-3'>
                <Form.Label>Jenis Pengumuman</Form.Label>
                <Form.Select
                  name='type'
                  value={formik.values.type}
                  onChange={(e) => formik.setFieldValue("type", e.target.value)}
                  onBlur={formik.handleBlur}
                  isInvalid={formik?.errors?.type && formik.touched.type}
                  isValid={!formik?.errors?.type && formik.touched.type}
                >
                  {annoucementType.map((item) => (
                    <option
                      key={item.id}
                      value={item.value}
                      className='text-uppercase'
                    >
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Tajuk</Form.Label>
                <Form.Control
                  name='title'
                  type='text'
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.title && formik.touched.title}
                  isValid={!formik.errors.title && formik.touched.title}
                />
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Tarikh Mula (Memaparkan)</Form.Label>
                    <Form.Control
                      name='showed_at'
                      type='date'
                      value={formik.values.showed_at}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.showed_at && formik.touched.showed_at
                      }
                      isValid={
                        !formik.errors.showed_at && formik.touched.showed_at
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Tarikh Tamat (Memaparkan)</Form.Label>
                    <Form.Control
                      name='ended_at'
                      type='date'
                      value={formik.values.ended_at}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.ended_at && formik.touched.ended_at
                      }
                      isValid={
                        !formik.errors.ended_at && formik.touched.ended_at
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className='mb-3'>
                <Form.Label>Mesej</Form.Label>
                <ReactQuill
                  modules={{ toolbar: toolbar }}
                  value={contentValue}
                  onChange={(value) => {
                    const clean = DOMPurify.sanitize(value);
                    setContentValue(clean);
                  }}
                />
              </Form.Group>

              <div className='d-flex justify-content-end gap-3 mt-3'>
                <Button
                  variant='light'
                  disabled={btnLoading}
                  onClick={() => navigate(-1)}
                >
                  Batal
                </Button>
                <Button variant='success' type='submit' disabled={btnLoading}>
                  Simpan
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default AnnouncementForm;
