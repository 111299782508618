import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

// components
import MinistriesPerformance from "./MinistriesPerformance";
import DepartmentsPerformance from "./DepartmentsPerformance";
import ProgramsPerformance from "./ProgramsPerformance";
import MainProjectsPerformance from "./MainProjectsPerformance";

// icons
import Icon from "@mdi/react";
import { mdiArrowExpand } from "@mdi/js";

// redux
import {
  setNavFilter,
  setSelectDepartment,
  setSelectMinistry,
  setSelectProgram,
} from "../../../../../../redux/slices/dashboard/dashboardSlice";
import ExpandFinancialDetails from "../modals/ExpandFinancialDetails";

const FinancialPerformance = ({ itemLeft }) => {
  const dispatch = useDispatch();

  const [label, setLabel] = useState("kementerian");
  const [expand, setExpand] = useState(false);

  const { selectYear, navFilter, selectMinistry } = useSelector((state) => ({
    selectYear: state.dashboardReducer.selectYear,
    navFilter: state.dashboardReducer.navFilter,
    selectMinistry: state.dashboardReducer.selectMinistry,
  }));

  const handleClickMinistry = (item) => {
    const code = Number(item.replace(/\D/g, ""));
    dispatch(setNavFilter("department"));
    dispatch(setSelectMinistry(code));
  };

  const handleClickDepartment = (item) => {
    dispatch(setNavFilter("program"));
    dispatch(setSelectDepartment(item));
  };

  const handleClickProgram = (item) => {
    dispatch(setNavFilter("main-project"));
    dispatch(setSelectProgram(item));
  };

  useEffect(() => {
    if (navFilter === "minitry") {
      setLabel("Kementerian");
    }
    if (navFilter === "department") {
      setLabel("jabatan");
    }
    if (navFilter === "program") {
      setLabel("program");
    }
    if (navFilter === "main-project") {
      setLabel("Projek Utama");
    }
  }, [navFilter]);

  return (
    <React.Fragment>
      <ExpandFinancialDetails show={expand} onHide={() => setExpand(false)} />

      <Card
        as={motion.div}
        variants={itemLeft}
        className='flex-fill w-100 position-relative'
      >
        <Card.Header className='pb-0 d-flex justify-content-between'>
          <h4 className='text-uppercase' style={{ fontWeight: 700 }}>
            <span className='me-1'>Prestasi kewangan bagi setiap {label}</span>
            {selectYear && `Tahun ${selectYear.label}`}
          </h4>
          <div
            className='position-absolute'
            style={{ top: 16, right: 16, cursor: "pointer" }}
            onClick={() => setExpand(true)}
          >
            <Icon path={mdiArrowExpand} size='18px' />
          </div>
        </Card.Header>
        <Card.Body>
          <div className='table-responsive'>
            {navFilter === "ministry" && (
              <MinistriesPerformance onClickMinistry={handleClickMinistry} />
            )}
            {navFilter === "department" && (
              <DepartmentsPerformance
                onClickDepartment={handleClickDepartment}
              />
            )}
            {navFilter === "program" && (
              <ProgramsPerformance onClickProgram={handleClickProgram} />
            )}
            {navFilter === "main-project" && <MainProjectsPerformance />}
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default FinancialPerformance;
