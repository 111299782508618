import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchScopeList = createAsyncThunk(
  "projectScope/fetchScopeList",
  async () => {
    const response = await axios.get(`/project-scope`);
    const data = await response.data;
    return data;
  },
);

export const createScope = createAsyncThunk(
  "projectScope/createScope",
  async (values) => {
    const response = await axios.post(`/project-scope`, values);
    const data = await response.data;
    return data;
  },
);

export const updateScope = createAsyncThunk(
  "projectScope/updateScope",
  async (values) => {
    const { id } = values;
    const response = await axios.put(`/project-scope/${id}`, values);
    const data = await response.data;
    return data;
  },
);

export const removeScope = createAsyncThunk(
  "projectScope/removeScope",
  async (id) => {
    const response = await axios.delete(`/project-scope/${id}`);
    const data = await response.data;
    return id;
  },
);

const initialState = {
  scopeList: [],
  isLoading: true,
  btnLoading: true,
};

export const projectScopeSlice = createSlice({
  name: "projectScope",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchScopeList.fulfilled, (state, action) => {
      state.scopeList = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(createScope.fulfilled, (state, action) => {
      state.scopeList.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateScope.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.scopeList = state.scopeList.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.isLoading = false;
    });
    builder.addCase(removeScope.fulfilled, (state, action) => {
      const id = action.payload;
      state.scopeList = state.scopeList.filter(
        (item) => item.id.toString() !== id.toString(),
      );
      state.isLoading = false;
    });
  },
});

export default projectScopeSlice.reducer;
