import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const storeExpenditureData = createAsyncThunk(
  "project/storeExpenditureData",
  async (params) => {
    try {
      const { project_id } = params;
      const response = await axios.post(
        `/project/store-project-expenditure-data/${project_id}`,
        params,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);
