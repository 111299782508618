import { isEmpty } from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const SourceOfCeiling = () => {
  const [virementOutProjects, setVirementOutProjects] = useState([]);
  const [virementInProjects, setVirementInProjects] = useState([]);

  const { application } = useSelector((state) => ({
    application: state.jppnReducer.application,
  }));

  useEffect(() => {
    const vireOutProjects = application?.virement_projects?.filter(
      (item) => item?.virement_type === "VO",
    );
    const vireInProjects = application?.virement_projects?.filter(
      (item) => item?.virement_type === "VI",
    );

    setVirementOutProjects(vireOutProjects);
    setVirementInProjects(vireInProjects);
  }, [application]);

  return (
    <React.Fragment>
      <h4 className='text-center'>Jadual 1 :</h4>
      <h4 className='text-center px-4 mb-3'>
        {/* Kedudukan Siling {`${virementSource?.name} dan ${project?.name}`}{" "}
        Selepas Pindahan Siling */}
      </h4>
      <div className='table-responsive'>
        <Table bordered size='sm'>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center align-middle' style={{ width: "40%" }}>
                Nama Projek
              </th>
              <th>
                Siling Asal
                <br />
                <span
                  className='text-truncate me-1'
                  style={{ textTransform: "none" }}
                >
                  {/* {currentRmk?.short_form} */}
                </span>
                (RM)
              </th>
              <th>Siling Terkini (RM)</th>
              <th className='text-center'>
                Siling dikurangkan(-) atau ditambah(+) <br /> (RM)
              </th>
              <th>Siling Disemak (RM)</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(virementOutProjects) && (
              <tr>
                <td colSpan={5} style={{ fontWeight: 700 }}>
                  Daripada :
                </td>
              </tr>
            )}

            {virementOutProjects?.map((item) => {
              const code = item?.project?.sub_project_full_code;
              const name = item?.project?.name;
              const ceilingByRmk = item?.project?.financial_details?.reduce(
                (acc, obj) => (acc += parseFloat(obj?.ceiling)),
                0,
              );
              const lejerByRmk = item?.project?.s3p_data
                ?.flatMap((obj) => obj?.overall_financial_data_by_months)
                .reduce((acc, obj) => (acc += parseFloat(obj?.lejar)), 0);

              const currentCeiling = ceilingByRmk - lejerByRmk;

              const vireAmount = item?.virement_details?.reduce(
                (acc, obj) => (acc += parseFloat(obj?.virement_amount)),
                0,
              );

              return (
                <tr>
                  <td>
                    {name}
                    <br />
                    {`(${code})`}
                  </td>
                  <td className='text-end'>
                    {numeral(ceilingByRmk).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(currentCeiling).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(vireAmount).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(currentCeiling - vireAmount).format("0,0.00")}
                  </td>
                </tr>
              );
            })}

            {!isEmpty(virementOutProjects) && (
              <tr>
                <td colSpan={5} style={{ fontWeight: 700 }}>
                  Kepada :
                </td>
              </tr>
            )}

            {virementInProjects?.map((item) => {
              const code = item?.project?.sub_project_full_code;
              const name = item?.project?.name;
              const ceilingByRmk = item?.project?.financial_details?.reduce(
                (acc, obj) => (acc += parseFloat(obj?.ceiling)),
                0,
              );
              const lejerByRmk = item?.project?.s3p_data
                ?.flatMap((obj) => obj?.overall_financial_data_by_months)
                .reduce((acc, obj) => (acc += parseFloat(obj?.lejar)), 0);

              const currentCeiling = ceilingByRmk - lejerByRmk;

              const vireAmount = item?.virement_details?.reduce(
                (acc, obj) => (acc += parseFloat(obj?.virement_amount)),
                0,
              );

              return (
                <tr>
                  <td>
                    {name}
                    <br />
                    {`(${code})`}
                  </td>
                  <td className='text-end'>
                    {numeral(ceilingByRmk).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(currentCeiling).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(vireAmount).format("0,0.00")}
                  </td>
                  <td className='text-end'>
                    {numeral(currentCeiling + vireAmount).format("0,0.00")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default SourceOfCeiling;
