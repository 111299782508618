import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch } from "react-redux";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import ProjectScopes from "./components/tables/ProjectScopes";
import ProjectScopeForm from "./components/modals/ProjectScopeForm";

// Redux
import {
  fetchScopeList,
  removeScope,
} from "../../../redux/slices/maintenance/projectScopeSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});

  const handleCloseForm = () => {
    setOpenForm(false);
    setIsEdit(false);
    setItem({});
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (id) => {
    dispatch(removeScope(id));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(fetchScopeList());
    }
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <ProjectScopeForm
        showForm={openForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        item={item}
      />

      <Helmet title="Senarai Skop Projek" />
      <Container fluid className="p-0">
        <Breadcrumbs
          title="Penyelenggaraan"
          breadcrumbItem="Senarai Skop Projek"
        />
        <Card className="mt-3">
          <Card.Header>
            <div className="card-actions float-end">
              <Button onClick={handleOpenForm}>
                <MdAdd /> Skop Projek
              </Button>
            </div>
            {/* <div className="d-flex align-items-center gap-2">
              <div className="search-box-custom">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label-custom">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Cari skop projek..."
                      // value={searchText}
                      // onChange={(e) => setSearchText(e.target.value)}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className="search-icon-2" size={18} />
                </div>
              </div>
            </div> */}
          </Card.Header>
          <Card.Body>
            <ProjectScopes
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
