import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProjectInfo = () => {
  const { project } = useSelector((state) => ({
    project: state.certifyReducer.project.data,
  }));

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className='text-sm'>Nama Projek</div>
          <h4 className='fw-bolder'>{project?.name}</h4>
          <div className='text-sm'>Program</div>
          <h4 className='fw-bolder'>{project?.program?.name}</h4>
          <Row>
            <Col md={6}>
              <div className='mb-1'>
                <div className='text-sm'>Jenis Projek</div>
                <Card.Text className='fw-bolder'>
                  {project?.project_type?.name}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>Kategori Projek</div>
                <Card.Text className='fw-bolder'>
                  {project?.project_category?.label}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>Keutamaan Projek</div>
                <Card.Text className='fw-bolder'>{project?.priority}</Card.Text>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-1'>
                <div className='text-sm'>Kementerian</div>
                <Card.Text className='fw-bolder'>
                  {project?.ministry?.label}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>Jabatan atau Agensi</div>
                <Card.Text className='fw-bolder'>
                  {project?.department?.label}
                </Card.Text>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ProjectInfo;
