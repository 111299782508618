import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import SidebarNavList from "./SidebarNavList";

const SidebarNavSection = (props) => {
  const { title, pages, className, showTo, user, ...rest } = props;

  let isTrue = false;

  if (!isEmpty(user)) {
    const arr1 = showTo;
    const arr2 = user.roles;

    function checkRoles(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        if (arr2.includes(arr1[i])) {
          return true;
        }
      }
      return false;
    }
    isTrue = checkRoles(arr1, arr2);
  }

  return (
    <React.Fragment {...rest}>
      {isTrue && (
        <React.Fragment>
          {title && (
            <li className='sidebar-header fw-bolder' style={{ fontSize: 11 }}>
              {title}
            </li>
          )}
        </React.Fragment>
      )}
      <SidebarNavList pages={pages} depth={0} />
    </React.Fragment>
  );
};

export default SidebarNavSection;
