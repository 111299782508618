import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const createHumanResourcesRequirement = createAsyncThunk(
  "humanResourcesRequirement/createHumanResourcesRequirement",
  async (values) => {
    try {
      const response = await axios.post(`/human-resources-requirement`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updatedHumanResourcesRequirement = createAsyncThunk(
  "humanResourcesRequirement/updatedHumanResourcesRequirement",
  async (values) => {
    try {
      const { id } = values;

      const response = await axios.put(
        `/human-resources-requirement/${id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeHumanResourcesRequirement = createAsyncThunk(
  "humanResourcesRequirement/removeHumanResourcesRequirement",
  async (id) => {
    try {
      const response = await axios.delete(`/human-resources-requirement/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);
