import React, { useState } from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { SixColumns } from "../../../../../components/common/tableLoading";
import { isEmpty } from "lodash";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

const Programs = ({
  programs,
  handleEditItem,
  handleRemoveItem,
  page,
  goToPage,
  goToFirstPage,
  goToLastPage,
  previousPage,
  nextPage,
}) => {
  const [perPage, setPerPage] = useState(30);

  const { isLoading } = useSelector((state) => ({
    isLoading: state.programReducer.isLoading,
  }));

  const groupByMinistry = (data) => {
    const groupedData = data.reduce((accumulator, currentItem) => {
      const ministryId = currentItem.ministry_id;
      if (!accumulator[ministryId]) {
        accumulator[ministryId] = {
          ministry: currentItem.ministry,
          programs: [currentItem],
        };
      } else {
        accumulator[ministryId].programs.push(currentItem);
      }
      return accumulator;
    }, {});
    return Object.values(groupedData);
  };
  const groupedMinistry = groupByMinistry(programs);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table hover size='sm'>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th className='text-center'>VOTHEAD & Kod Program</th>
              <th className='text-center'>RMK</th>
              <th>Nama Program</th>
              <th>Kementerian</th>
              <th>Jabatan</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading ? (
            <SixColumns />
          ) : (
            <tbody className='border-start border-end'>
              {isEmpty(programs) ? (
                <tr>
                  <td colSpan={8} className='text-center'>
                    Maklumat tidak ditemui
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {groupedMinistry
                    .toSorted((a, b) => a.ministry.code - b.ministry.code)
                    .map((group) => {
                      const { ministry, programs } = group;
                      return (
                        <React.Fragment key={ministry.id}>
                          <tr className='custom-table-row'>
                            <td
                              colSpan={4}
                              className=''
                              style={{ fontWeight: 700 }}
                            >
                              <span className='ms-2 me-1'>
                                D{ministry.code}
                              </span>
                              <span>{ministry.name}</span>
                            </td>
                            <td colSpan={2} />
                          </tr>
                          {programs
                            .toSorted((a, b) => a.code - b.code)
                            .map((program, idx) => (
                              <tr key={program.id}>
                                <td className='text-center px-2'>
                                  <div
                                    className='container-fluid rounded-1 illustration'
                                    style={{ fontWeight: 700 }}
                                  >
                                    <span>{program.prefix}</span>
                                    <span className='me-1'>
                                      {program.ministry.code}
                                    </span>
                                    <span>
                                      {program.code
                                        ?.toString()
                                        .padStart(3, "0") || "000"}
                                    </span>
                                  </div>
                                </td>
                                <td className='text-center'>
                                  {!isEmpty(program.rmk)
                                    ? program.rmk.short_form
                                    : "-"}
                                </td>
                                <td>{program.name}</td>
                                <td>
                                  {!isEmpty(program.ministry)
                                    ? program.ministry.name
                                    : "Semua"}
                                </td>
                                <td>{program.department?.value}</td>
                                <td>
                                  <div className='d-flex justify-content-center'>
                                    <Tooltip title='Edit' placement='top' arrow>
                                      <IconButton
                                        color='info'
                                        size='small'
                                        aria-label='Edit'
                                        onClick={() => handleEditItem(program)}
                                      >
                                        <MdModeEditOutline fontSize='large' />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title='Padam'
                                      placement='top'
                                      arrow
                                    >
                                      <IconButton
                                        color='error'
                                        size='small'
                                        aria-label='Padam'
                                        onClick={() =>
                                          handleRemoveItem(program)
                                        }
                                      >
                                        <MdDelete fontSize='large' />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              )}
            </tbody>
          )}
        </Table>
      </div>
    </React.Fragment>
  );
};

export default Programs;
