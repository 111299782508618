import React, { forwardRef, useEffect, useRef } from "react";
import { Tooltip } from "@mui/material";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Form.Check type='checkbox' ref={resolvedRef} {...rest} />;
});

const TableHead = ({
  showColumn,
  columnTitle,
  checkAllCost,
  isIndeterminateCost,
  checkedCostId,
  components,
  viewColumns,
}) => {
  return (
    <thead className='text-center align-middle custom-table-head'>
      <tr>
        <th className='text-center' rowSpan={2} style={{ width: 50 }}>
          BIL.
        </th>
        <th rowSpan={2} className='border-end-0'>
          KOMPONEN PROJEK
        </th>
        <th rowSpan={2} className='border-start-0' />

        {viewColumns.dipohon.checked && (
          <>
            <th className='text-center' rowSpan={2}>
              KOS DIPOHON (RM)
            </th>
            <th className='text-center' rowSpan={2}>
              SILING DIPOHON (RM)
            </th>
          </>
        )}

        {viewColumns.upen.checked && (
          <th className={`text-center`} colSpan={3}>
            <p className='mb-0'>{columnTitle?.upen} UPEN</p>
          </th>
        )}
        {viewColumns.jppn.checked && (
          <th
            className={`${showColumn?.jppn ? "" : "hide-column"} text-center`}
            colSpan={3}
          >
            <p className='mb-0'>{columnTitle?.jppn} JPPN</p>
          </th>
        )}
        <th
          className={`${showColumn?.cabinet ? "" : "hide-column"} text-center`}
          colSpan={3}
        >
          <p className='mb-0'>{columnTitle?.cabinet} KABINET</p>
        </th>
        <th className='text-center' rowSpan={2}>
          {/* <Tooltip title="Tanda semua" placement="top" arrow followCursor>
            <Form.Group controlId="check_all">
              <IndeterminateCheckbox
                className="check-box-lg"
                type="checkbox"
                id="check_all"
                indeterminate={isIndeterminate}
                onChange={handleCheckAll}
                checked={
                  project.financial_details?.length === certifiedId.length
                    ? true
                    : false
                }
              />
            </Form.Group>
          </Tooltip> */}
        </th>
      </tr>
      <tr>
        {/* UPEN SECTION */}
        {viewColumns.upen.checked && (
          <>
            <th className='border-end-0'>KOS (RM)</th>
            <th className='border-start-0'>
              {/* {!showColumn.jppn && (
            <div className='d-flex justify-content-center'>
              <Tooltip title='Tanda Semua' followCursor arrow>
                <Form.Group controlId='check_change'>
                  <IndeterminateCheckbox
                    className='check-box-lg'
                    type='checkbox'
                    id='check_change'
                    indeterminate={isIndeterminateCost}
                    onChange={checkAllCost}
                    checked={
                      components?.length === checkedCostId.length ? true : false
                    }
                  />
                </Form.Group>
              </Tooltip>
            </div>
          )} */}
            </th>
            <th>SILING (RM)</th>
          </>
        )}

        {/* JPPN SECTION */}
        {viewColumns.jppn.checked && (
          <>
            <th
              className={`${
                showColumn.jppn ? "" : "hide-column"
              } text-center border-end-0`}
            >
              KOS (RM)
            </th>
            <th
              className={`${
                showColumn.jppn ? "" : "hide-column"
              } text-center border-start-0`}
            >
              {/* {!showColumn.cabinet && (
            <div className='d-flex justify-content-center'>
              <Tooltip title='Tanda Semua' followCursor arrow>
                <Form.Group controlId='check_change'>
                  <IndeterminateCheckbox
                    className='check-box-lg'
                    type='checkbox'
                    id='check_change'
                    indeterminate={isIndeterminateCost}
                    onChange={checkAllCost}
                    checked={
                      components?.length === checkedCostId.length ? true : false
                    }
                  />
                </Form.Group>
              </Tooltip>
            </div>
          )} */}
            </th>
            <th
              className={`${showColumn.jppn ? "" : "hide-column"} text-center`}
            >
              SILING (RM)
            </th>
          </>
        )}

        {/* CABINET SECTION */}
        <th
          className={`${
            showColumn.cabinet ? "" : "hide-column"
          } text-center border-end-0`}
        >
          KOS (RM)
        </th>
        <th
          className={`${
            showColumn.cabinet ? "" : "hide-column"
          } text-center border-start-0`}
        >
          {/* <div className='d-flex justify-content-center'>
            <Tooltip title='Tanda Semua' followCursor arrow>
              <Form.Group controlId='check_change'>
                <IndeterminateCheckbox
                  className='check-box-lg'
                  type='checkbox'
                  id='check_change'
                  indeterminate={isIndeterminateCost}
                  onChange={checkAllCost}
                  checked={
                    components?.length === checkedCostId.length ? true : false
                  }
                />
              </Form.Group>
            </Tooltip>
          </div> */}
        </th>
        <th
          className={`${showColumn.cabinet ? "" : "hide-column"} text-center`}
        >
          SILING (RM)
        </th>
      </tr>
    </thead>
  );
};

export default TableHead;
