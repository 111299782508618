import React, { useEffect, useRef, useState } from "react";
import { Modal, Card, ModalFooter, Button } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useNavigate } from "react-router-dom";

const SuccessNotification = ({ showSuccess, handleCloseSuccess, primaryMessage, secondaryMessage, goBackUrl }) => {
  const isMountedRef = useRef(false);
  const navigate = useNavigate();

  const [redirectTimer, setRedirectTimer] = useState(3);
  const [primaryMsg, setPrimaryMsg] = useState("");
  const [secondaryMsg, setSecondaryMsg] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    // Skip the first render
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }

    const countdown = setTimeout(() => {
      if (redirectTimer > 0) {
        setRedirectTimer(redirectTimer - 1);
      } else {
        handleCloseSuccess();
        navigate(url);
      }
    }, 1000);
    return () => clearTimeout(countdown);
  }, [redirectTimer, showSuccess]);

  useEffect(() => {
    setPrimaryMsg(secondaryMessage)
    setSecondaryMsg(secondaryMessage)
    setUrl(goBackUrl)
  }, [showSuccess]);

  return (
    <AnimatePresence>
      {showSuccess && (
        <>
          <div className="background-popup" />
          <motion.div
            initial={{ scale: 0, y: "-50%", x: "-50%" }}
            animate={{
              scale: 1,
              y: "-50%",
              x: "-50%",
              transition: { duration: 0.3 },
            }}
            exit={{ scale: 0 }}
            className="modal-div"
            style={{ borderRadius: 6 }}
          >
            <div className="modal-dialog mt-0">
              <div className="modal-content">
                <Modal.Body className="mt-3">
                  <h3 className="text-center mb-0">Berjaya!</h3>
                  <div className="success-animation">
                    <div className="wrapper">
                      <svg
                        className="checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                      >
                        <circle
                          className="checkmark__circle"
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        />
                        <path
                          className="checkmark__check"
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="mb-0">
                      {primaryMessage}
                    </p>
                    <p className="mb-0">
                      Kembali ke {secondaryMsg} dalam {redirectTimer} saat...
                    </p>
                  </div>
                </Modal.Body>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default SuccessNotification;
