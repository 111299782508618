import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, ListGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { format } from "date-fns";
import { isEmpty } from "lodash";
// Icons
import Icon from "@mdi/react";
import { mdiClose, mdiCheckBold } from "@mdi/js";

// redux
import { acknowledgedItem } from "../../../../../../../redux/slices/projects/returnApplicationSlice";

const ReturnApplicationContent = ({
  showReturnApplicationContent,
  closeReturnApplicationContent,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { user, project } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.projectReducer.project,
  }));

  const closeModal = () => {
    closeReturnApplicationContent();
  };

  const handleAcknowledge = async (id) => {
    const newValues = {
      id: id,
      acknowledged_by: user.id,
      acknowledged_at: format(new Date(), "yyyy-MM-dd, hh:mm a"),
    };
    await dispatch(acknowledgedItem(newValues));

    closeModal();
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const { return_applications } = project;

      setData(return_applications);
    }

    return () => {};
  }, [project]);

  return (
    <React.Fragment>
      <Modal
        show={showReturnApplicationContent}
        onHide={closeModal}
        centered
        keyboard={false}
        backdrop='static'
      >
        <Modal.Body>
          <div className='d-flex justify-content-between mb-3'>
            <h5>Senarai komen</h5>
            <IconButton className='p-0' onClick={closeModal}>
              <Icon path={mdiClose} size={1} />
            </IconButton>
          </div>
          <div
            className='card-scroll px-2'
            style={{ maxHeight: 600, overflow: "auto" }}
          >
            {data?.map((item) => {
              return (
                <Card key={item.id} className='border mb-2 shadow-sm'>
                  <Card.Body className='p-1'>
                    <div className='mb-3 px-2 pt-2'>
                      <ListGroup className='text-sm'>
                        <ListGroup.Item className='p-0 border-0 d-flex'>
                          <div style={{ width: 120 }}>
                            Tarikh permohonan dikembalikan
                          </div>
                          <div className='ms-2'>
                            :
                            <span className='ms-2'>
                              {format(
                                new Date(item.return_at),
                                "dd-MM-yyy, hh:mm a",
                              )}
                            </span>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className='p-0 border-0 d-flex'>
                          <div style={{ width: 120 }}>Arahan</div>
                          <div className='ms-2 d-flex'>
                            :
                            <div className='ms-2'>
                              <div>{item?.return_by?.name}</div>
                              <div>
                                <p className='mb-0'>
                                  {item?.return_by?.profile?.position}
                                </p>
                                {isEmpty(item.return_by.department) ? (
                                  <p className='mb-0'>
                                    {item?.return_by?.ministry?.value}
                                  </p>
                                ) : (
                                  <p className='mb-0'>
                                    {item?.return_by?.department?.value}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                      <div
                        className={`${
                          item.is_acknowledged
                            ? "text-decoration-line-through"
                            : ""
                        } mt-3`}
                      >
                        {!isEmpty(item.reason) && parse(item.reason)}
                      </div>
                    </div>
                    {!item.is_acknowledged && (
                      <div className='d-flex justify-content-end'>
                        <Tooltip placement='top' title='Tekan untuk akui' arrow>
                          <IconButton
                            size='small'
                            color='success'
                            onClick={() => handleAcknowledge(item.id)}
                          >
                            <Icon path={mdiCheckBold} size={1} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ReturnApplicationContent;
