import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

// Redux
import { returnApplication } from "../../../../../../../redux/slices/projects/returnApplicationSlice";

const ReturnApplication = ({
  showReturnApplication,
  closeReturnApplication,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const { user, project } = useSelector((state) => ({
    project: state.projectReducer.project,
    user: state.userReducer.user,
  }));

  const toolbar = [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "blockquote",
    "underline",
    { list: "ordered" },
    { list: "bullet" },
  ];

  const handleCloseReturnApplication = () => {
    closeReturnApplication();
    setBtnLoading(false);
  };

  const handleSubmit = async () => {
    setBtnLoading(true);
    const newValues = {
      project_id: project.id,
      reason: value,
      return_by: user.id,
      return_at: format(new Date(), "yyyy-MM-dd, hh:mm"),
    };
    await dispatch(returnApplication(newValues));
    setValue("");
    handleCloseReturnApplication();
    navigate(`/senarai-projek/status-permohonan`);
  };

  return (
    <React.Fragment>
      <Modal
        show={showReturnApplication}
        onHide={handleCloseReturnApplication}
        centered
      >
        <Modal.Body>
          <h5>Sila nyatakan sebab-sebab maklumat projek perlu dikemas kini</h5>
          <div className='mb-3'>Nama Projek: {project.name}</div>
          <ReactQuill
            name='note'
            placeholder='Maklumat catatan'
            modules={{ toolbar: toolbar }}
            value={value}
            onChange={(value) => setValue(value)}
          />

          <div className='d-flex justify-content-end gap-3 mt-3'>
            <Button
              variant='light'
              onClick={handleCloseReturnApplication}
              disabled={btnLoading}
            >
              Batal
            </Button>
            <Button
              variant='danger'
              onClick={handleSubmit}
              disabled={btnLoading}
            >
              Kembalikan
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ReturnApplication;
