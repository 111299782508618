import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const fetchUsers = createAsyncThunk("users/fetchUser", async (props) => {
  try {
    const { params, source } = props;
    const response = await axios.get(`/users`, {
      params,
      cancelToken: source.token,
    });

    const data = await response.data;

    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(error.message);
    } else {
      console.error(error);
    }
  }
});

export const createUser = createAsyncThunk("user/create", async (values) => {
  try {
    const response = await axios.post(`/users`, values);

    const data = await response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getUser = createAsyncThunk("user/getUser", async (id) => {
  try {
    const response = await axios.get(`/users/${id}`);

    const data = await response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
});

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/users/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeUser = createAsyncThunk(
  "user/removeUser",
  async (params) => {
    try {
      const { id, is_deleted } = params;
      const response = await axios.delete(`/users/${id}/delete`);

      const data = await response;

      return params;
    } catch (error) {
      console.log(error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (params) => {
    try {
      const { id, type } = params;
      const response = await axios.post(`/password/reset/${id}`, params);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const checkNric = createAsyncThunk("users/checkNric", async (values) => {
  try {
    const response = await axios.post(`/check-nric-if-exist`, values);

    const data = await response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  users: [],
  user: {},
  temp_name: null,
  temp_password: null,
  successCreated: false,
  meta: {},
  links: {},
  isLoading: false,
  btnLoading: false,
  searchUser: "",
  page: 1,
  perPage: 30,
  keywords: "",
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserProfile: (state, action) => {
      state.user = {};
    },
    resetUsersReducer: (state) => initialState,
    resetTempPassword: (state) => {
      state.temp_password = null;
      state.temp_name = null;
    },
    setSuccessCreated: (state, action) => {
      state.successCreated = action.payload;
    },
    setSearchUser: (state, action) => {
      state.searchUser = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.isLoading = true;
      state.btnLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      const payload = action.payload;
      state.users = payload.data;
      state.meta = payload.meta;
      state.links = payload.links;
      state.isLoading = false;
      state.btnLoading = false;
    });

    builder.addCase(createUser.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      const { user, password } = action.payload;
      state.users.push(user);
      state.temp_password = password;
      state.temp_name = user.name;
      state.btnLoading = false;
    });

    builder.addCase(removeUser.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      const data = action.payload;
      const id = data.id;

      if (data.is_deleted) {
        state.users = state.users.filter(
          (item) => item.id.toString() !== id.toString(),
        );
      } else {
        state.users = state.users.map((user) => {
          if (id === user.id) {
            return {
              ...data,
              is_deleted: true,
            };
          }
          return user;
        });
      }

      state.btnLoading = false;
    });

    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload;

      state.users = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        }
        return user;
      });

      state.btnLoading = false;
    });
  },
});

export const {
  resetUserProfile,
  resetUsersReducer,
  resetTempPassword,
  setSuccessCreated,
  setSearchUser,
  setPage,
  setPerPage,
  setKeywords,
} = usersSlice.actions;

export default usersSlice.reducer;
