import React, { useContext, forwardRef, useState, useEffect } from "react";
import { Button, Card, Form, Row, Col, Tab, Nav } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, IconButton, Tooltip } from "@mui/material";
import axios from "axios";

// Styles
import NotyfContext from "../../../contexts/NotyfContext";
import {
  customStyles,
  customStylesForm,
} from "../../../components/common/customStyleSelect";
import SabahLogo from "../../../assets/img/photos/sabah-logo.png";

// Icons
import Icon from "@mdi/react";
import { mdiHelpCircle, mdiLoading, mdiArrowLeft, mdiDelete } from "@mdi/js";

// Components
import LoadingState from "../../../components/common/LoadingState";
import SuccessResetPassword from "./components/SuccessResetPassword";

// Redux
import {
  createUser,
  getUser,
  updateUser,
  resetUserProfile,
  resetPassword,
  checkNric,
} from "../../../redux/slices/usersSlice";
import {
  fetchRoles,
  fetchGroupRoles,
} from "../../../redux/slices/maintenance/rolesSlice";
import {
  getMinistries,
  getDepartments,
  getUpenSections,
  getBranches,
  getDistrictItems,
} from "../../../redux/slices/utilsSlice";

const UserForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const routeParams = useParams();

  const { type, id } = routeParams;

  const [roleOptions, setRoleOptions] = useState([]);
  const [newRoleOptions, setNewRoleOptions] = useState([]);
  const [otherRoleOptions, setOtherRoleOptions] = useState([]);
  const [roleValues, setRoleValues] = useState([]);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState(null);

  const [errors, setErrors] = useState(null);

  const [groupRoleOptions, setGroupRoleOptions] = useState([]);

  const [inputExecutingAgencyOptions, setInputExecutingAgencyOptions] =
    useState([]);

  const {
    isLoading,
    btnLoading,
    roles,
    departments,
    districtOptions,
    branches,
    ministries,
    userProfile,
    groupRoles,
  } = useSelector((state) => ({
    isLoading: state.usersReducer.isLoading,
    btnLoading: state.usersReducer.btnLoading,
    roles: state.roleReducer.roles,
    userProfile: state.usersReducer.user,
    departments: state.utilsReducer.dropdownItems.departments,
    branches: state.utilsReducer.dropdownItems.branches,
    ministries: state.utilsReducer.dropdownItems.ministries,
    districtOptions: state.utilsReducer.dropdownItems.districtItems,
    groupRoles: state.roleReducer.groupRoles,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullname: userProfile.name || "",
      email: userProfile.email || "",
      nric: userProfile.nric || "",
      position: userProfile.profile?.position || "",
      ministry: userProfile.ministry || null,
      department: userProfile.department || null,
      branch: userProfile.profile?.branch || null,
      district: userProfile.profile?.district || null,
      roles: roleValues,
      office_address: userProfile.profile?.office_address || "",
      office_phone_number: userProfile.profile?.office_phone_number || "",
      phone_number: userProfile.profile?.phone_number || "",
      grade: userProfile.profile?.grade || "",
      group_role: !isEmpty(userProfile)
        ? {
            ...userProfile?.group_role,
            label: userProfile?.group_role?.name,
            value: userProfile?.group_role?.name,
          }
        : null,
      input_ministries: userProfile.user_access_to_ministries || null,
      input_executing_agencies:
        userProfile.user_access_to_executing_agencies || null,
      input_department_branch:
        userProfile.user_access_to_department_branches || null,
      input_district: userProfile.user_access_to_district || null,
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string()
        .required("Masukkan nama penuh")
        .min(5, "Mesti lebih dari 5 perkataan"),
      email: Yup.string()
        .email("Masukkan email yang betul")
        .required("Masukkan email pengguna"),
      // roles: Yup.array().min(1, "Tidak boleh dikosongkan."),
      nric: Yup.string()
        .min(12, "Minimum No.MyKad adalah 12 nombor")
        .max(12, "Maksimum No.MyKad adalah 12 nombor")
        .test(
          "noEOrSign", // type of the validator (should be unique)
          "Sila isi No. MyKad", // error message
          (value) =>
            typeof value === "string" && !/[eE+-]/.test(value.toString()),
        )
        .required("Sila isi No. MyKad"),
    }),
    onSubmit: async (values) => {
      if (type === "kemaskini") {
        const newValues = {
          id: userProfile.id,
          user_profile_id: userProfile.profile?.id,
          is_deleted: userProfile?.is_deleted,
          name: values.fullname,
          email: values.email,
          nric: values.nric.toString(),
          position: values.position,
          ministry_id: Number(values.ministry?.code) || null,
          department_id: values.department?.id || null,
          branch_id: values.branch?.id || null,
          district_id: values.district?.id || null,
          roles: values.roles,
          office_address: values.office_address,
          office_phone_number: values.office_phone_number,
          phone_number: values.phone_number,
          grade: values.grade,
          group_role: values.group_role.id,
          ministry_codes: values.input_ministries
            ? values.input_ministries.map((item) => item.code)
            : null,
          department_ids: values.input_executing_agencies
            ? values.input_executing_agencies.map((item) => item.id)
            : null,
          department_branch_ids: values.input_department_branch
            ? values.input_department_branch.map((item) => item.id)
            : null,
          district_ids: values.input_district ? values.input_district.id : null,
        };
        await dispatch(updateUser(newValues));
        handleNotification("edited", newValues);
      } else {
        const newValues = {
          name: values.fullname,
          email: values.email,
          nric: values.nric.toString(),
          position: values.position,
          ministry_id: Number(values.ministry?.code) || null,
          department_id: values.department?.id || null,
          branch_id: values.branch?.id || null,
          district_id: values.district?.id || null,
          roles: values.roles,
          office_address: values.office_address,
          office_phone_number: values.office_phone_number,
          phone_number: values.phone_number,
          grade: values.grade,
          group_role: values.group_role.id,
          ministry_codes: values.input_ministries
            ? values.input_ministries.map((item) => item.code)
            : null,
          department_ids: values.input_executing_agencies
            ? values.input_executing_agencies.map((item) => item.id)
            : null,
          department_branch_ids: values.input_department_branch
            ? values.input_department_branch.map((item) => item.id)
            : null,
          district_ids: values.input_district ? values.input_district.id : null,
        };
        await dispatch(createUser(newValues));
        handleNotification("created", newValues);

        navigate("/admin/senarai-pengguna");
      }
    },
  });

  const handleCheckRoles = (e) => {
    const value = e.target.value;
    const isSuperadmin = value === "superadmin";

    let newValues;
    let updatedRoleOptions;

    if (formik.values.roles.includes(value)) {
      newValues = formik.values.roles.filter((item) => item !== value);
      updatedRoleOptions = newRoleOptions
        .flatMap((obj) => obj.items)
        .map((item) => ({
          ...item,
          disabled: false,
        }));
    } else {
      updatedRoleOptions = newRoleOptions
        .flatMap((obj) => obj.items)
        .map((item) => ({
          ...item,
          disabled: isSuperadmin && item.name !== value,
        }));
      newValues = isSuperadmin ? [value] : [...formik.values.roles, value];
    }

    const groupedData = updatedRoleOptions.reduce((acc, currentItem) => {
      const { type } = currentItem;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(currentItem);
      return acc;
    }, {});

    const result = Object.entries(groupedData).map(([type, items]) => {
      return {
        type: type,
        items: items,
      };
    });

    formik.setFieldValue("roles", newValues);
    setNewRoleOptions(result);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSelectMinistry = (value) => {
    formik.setFieldValue("ministry", value);

    if (isEmpty(value)) {
      formik.setFieldValue("department", null);
      formik.setFieldValue("branch", null);
      setDepartmentOptions([]);
      setBranchOptions([]);
      return;
    }

    const ministry_code = Number(value.code);
    const newOptions = departments.filter(
      (dept) => dept.ministry_code.toString() === ministry_code.toString(),
    );

    setDepartmentOptions(newOptions);
  };

  const handleSelectDepartment = (value) => {
    formik.setFieldValue("department", value);

    if (isEmpty(value)) {
      formik.setFieldValue("branch", null);
      setBranchOptions([]);
      return;
    }

    const newOptions = branches.filter(
      (branch) => branch.department_id === value.id,
    );

    setBranchOptions(newOptions);
  };

  const handleResetPassword = async () => {
    const newValues = {
      id: id,
      type: "normal",
    };
    const response = await dispatch(resetPassword(newValues));
    const data = await response.payload;
    handleResetSuccessfullyMsg();
  };

  const handleResetPasswordBypass = async () => {
    const newValues = {
      id: id,
      type: "bypass",
    };
    const response = await dispatch(resetPassword(newValues));
    const data = await response.payload;
    setData(data);
    setShowForm(true);
  };

  const handleSelectGroupRole = (v) => {
    const accessTo = v.access_to.split(", ");
    formik.setFieldValue("roles", accessTo);
    formik.setFieldValue("group_role", v);
  };

  const handleChangeInputMinistries = (value) => {
    formik.setFieldValue("input_ministries", value);

    const flatDepartments = value?.flatMap((item) => item.departments);

    const sortedOptions = flatDepartments?.toSorted(
      (a, b) => a.ministry_code - b.ministry_code,
    );
    setInputExecutingAgencyOptions(sortedOptions);
  };

  const handleChangeInputExecutingAgencies = (value) => {
    formik.setFieldValue("input_executing_agencies", value);
  };

  const handleChangeInputDepartmentBranch = (value) => {
    formik.setFieldValue("input_department_branch", value);
  };

  const handleChangeInputDistricts = (value) => {
    formik.setFieldValue("input_district", value);
  };

  const handleCheckNric = async (value) => {
    const values = {
      nric: value,
    };

    const response = await dispatch(checkNric(values));
    const data = await response.payload;

    if (data.status === 500) {
      setErrors({
        ...errors,
        nricExist: data.message,
      });
    } else {
      setErrors(null);
    }
  };

  const handleChangeNric = (value) => {
    formik.setFieldValue("nric", value);

    if (value.length === 12) {
      if (type !== "kemaskini") {
        handleCheckNric(value);
      }
    }
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = `Maklumat ${values.name}`;
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `${msg} berjaya dipadam.`;
        break;
      case "edited":
        msg = `${msg} berjaya dikemaskini.`;
        break;

      default:
        msg = `${msg} berjaya ditambah.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleErrorNotification = () => {
    notyf.open({
      type: "error",
      message: "Peranan pengguna tidak boleh dikosongkan, sila semak.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleResetSuccessfullyMsg = () => {
    // Success
    notyf.open({
      type: "success",
      message: "Set Semula password berjaya.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(fetchRoles());
    dispatch(getDepartments(props));
    dispatch(getMinistries(props));
    dispatch(getUpenSections(props));
    dispatch(getBranches(props));
    dispatch(getDistrictItems(props));
    dispatch(fetchGroupRoles());

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    if (type === "kemaskini") {
      dispatch(getUser(id)).then(({ payload }) => {
        const { group_role, roles } = payload;
        const accessArray = group_role?.access_to
          ?.split(",")
          .map((item) => item.trim());
        setRoleValues(accessArray);
      });
    } else {
      dispatch(resetUserProfile());
    }
  }, [dispatch, id, type]);

  useEffect(() => {
    if (!isEmpty(roles)) {
      const mainRoles = roles
        .filter((item) => item.type === "main")
        .map((item) => {
          return { ...item, disabled: false };
        });
      setRoleOptions(mainRoles);

      const secondaryRoles = roles
        .filter((item) => item.type === "other")
        .map((item) => {
          return { ...item, disabled: false };
        });
      setOtherRoleOptions(secondaryRoles);

      const groupedData = roles
        .toSorted((a, b) => a.numbering - b.numbering)
        .reduce((acc, currentItem) => {
          const { type } = currentItem;
          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(currentItem);
          return acc;
        }, {});

      const result = Object.entries(groupedData).map(([type, items]) => {
        return {
          type: type,
          items: items,
        };
      });

      setNewRoleOptions(result);
    }
  }, [roles]);

  useEffect(() => {
    const newValues = groupRoles?.map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.name,
      };
    });

    setGroupRoleOptions(newValues);
  }, [groupRoles]);

  useEffect(() => {
    if (isEmpty(userProfile)) return;
    const ministriValues = userProfile?.user_access_to_ministries;

    handleChangeInputMinistries(ministriValues);
  }, [userProfile]);

  return (
    <React.Fragment>
      <SuccessResetPassword
        showForm={showForm}
        closeForm={() => setShowForm(false)}
        data={data}
      />

      <Card>
        <Card.Header>
          <div className='d-flex justify-content-between mb-3'>
            <div>
              {type === "kemaskini"
                ? "Kemaskini pengguna."
                : "Tambah pengguna baru."}
              <p className='small fst-italic mb-0'>
                Sila isi maklumat terperinci pengguna.
              </p>
            </div>

            <div>
              <IconButton className='m-0 p-0' onClick={() => navigate(-1)}>
                <Icon path={mdiArrowLeft} size={1} />
              </IconButton>
            </div>
          </div>
        </Card.Header>
        {isLoading ? (
          <LoadingState />
        ) : (
          <Card.Body>
            <Row>
              <Col md={7}>
                <Form
                  autoComplete='off'
                  autoCorrect='off'
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (isEmpty(formik.values.group_role)) {
                      handleErrorNotification();
                      return false;
                    }
                    formik.handleSubmit();
                    return false;
                  }}
                >
                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>Nama Penuh *</Form.Label>
                    <Form.Control
                      type='text'
                      name='fullname'
                      value={formik.values.fullname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.fullname && formik.errors.fullname
                          ? true
                          : false
                      }
                      isValid={
                        formik.touched.fullname && !formik.errors.fullname
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.fullname}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>No. MyKad *</Form.Label>
                    <Form.Control
                      type='number'
                      name='nric'
                      value={formik.values.nric}
                      onChange={(e) => handleChangeNric(e.target.value)}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        (formik.touched.nric && formik.errors.nric) ||
                        !isEmpty(errors)
                          ? true
                          : false
                      }
                      isValid={
                        formik.touched.nric && !formik.errors.nric
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik?.errors?.nric}
                      {errors?.nricExist}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>Emel *</Form.Label>
                    <Form.Control
                      type='email'
                      name='email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                      isValid={
                        formik.touched.email && !formik.errors.email
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>Jawatan</Form.Label>
                    <Form.Control
                      type='text'
                      name='position'
                      value={formik.values.position}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>Gred</Form.Label>
                    <Form.Control
                      type='text'
                      name='grade'
                      value={formik.values.grade}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>
                      No. Telefon Pejabat *
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='office_phone_number'
                      value={formik.values.office_phone_number}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>
                      No. Telefon (Bimbit)*
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='phone_number'
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>Alamat Pejabat</Form.Label>
                    <Form.Control
                      type='text'
                      as='textarea'
                      name='office_address'
                      value={formik.values.office_address}
                      onChange={formik.handleChange}
                      style={{ maxHeight: 150, minHeight: 80, height: 80 }}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>Kementerian *</Form.Label>
                    <Select
                      name='ministry'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      isSearchable
                      isClearable
                      options={ministries}
                      styles={customStylesForm}
                      placeholder='Pilih Kementerian'
                      value={formik.values.ministry}
                      onChange={(value) => handleSelectMinistry(value)}
                      noOptionsMessage={() => "Tiada pilihan"}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>
                      Jabatan{" "}
                      <span className='text-muted' style={{ fontSize: 11 }}>
                        (Jika berkaitan)
                      </span>
                    </Form.Label>
                    <Select
                      name='department'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      isSearchable
                      isClearable
                      options={departmentOptions}
                      styles={customStylesForm}
                      placeholder='Pilih Jabatan atau Agensi...'
                      value={formik.values.department}
                      onChange={(value) => handleSelectDepartment(value)}
                      noOptionsMessage={() => "Tiada pilihan"}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>
                      Cawangan{" "}
                      <span className='text-muted' style={{ fontSize: 11 }}>
                        (Jika berkaitan)
                      </span>
                    </Form.Label>
                    <Select
                      name='branch'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      isSearchable
                      isClearable
                      options={branchOptions}
                      styles={customStylesForm}
                      placeholder='Pilih cawangan'
                      value={formik.values.branch}
                      onChange={(value) =>
                        formik.setFieldValue("branch", value)
                      }
                      noOptionsMessage={() => "Tiada pilihan"}
                    />
                  </Form.Group>

                  {/* <Form.Group className='mb-3'>
                    <Form.Label className='fw-bold'>
                      Daerah
                      <span
                        className='text-muted ms-1'
                        style={{ fontSize: 11 }}
                      >
                        (Hanya untuk pegawai daerah sahaja)
                      </span>
                    </Form.Label>
                    <Select
                      name='district'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      isSearchable
                      isClearable
                      options={districtOptions}
                      styles={customStylesForm}
                      placeholder='Pilih daerah'
                      value={formik.values.district}
                      onChange={(value) =>
                        formik.setFieldValue("district", value)
                      }
                      noOptionsMessage={() => "Tiada pilihan"}
                    />
                  </Form.Group> */}

                  <Divider />
                  <div className='my-3'>
                    <Form.Group className='mb-3'>
                      <h5>Akses Kementerian</h5>
                      <Select
                        name='input_ministries'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        isSearchable
                        isClearable
                        isMulti
                        closeMenuOnSelect={false}
                        options={ministries}
                        // styles={customStylesForm}
                        placeholder='Pilih Kementerian'
                        value={formik.values.input_ministries}
                        onChange={(value) => handleChangeInputMinistries(value)}
                        noOptionsMessage={() => "Tiada pilihan"}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                      <h5>Akses Jabatan</h5>
                      <Select
                        name='input_executing_agencies'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        isSearchable
                        isClearable
                        isMulti
                        closeMenuOnSelect={false}
                        options={inputExecutingAgencyOptions}
                        // styles={customStylesForm}
                        placeholder='Pilih Jabatan'
                        value={formik.values.input_executing_agencies}
                        onChange={(value) =>
                          handleChangeInputExecutingAgencies(value)
                        }
                        noOptionsMessage={() => "Tiada pilihan"}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                      <h5>Akses Cawangan (Jika Berkaitan)</h5>
                      <Select
                        name='input_executing_agencies'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        isSearchable
                        isClearable
                        isMulti
                        closeMenuOnSelect={false}
                        options={branches}
                        // styles={customStylesForm}
                        placeholder='Pilih Cawangan'
                        value={formik.values.input_department_branch}
                        onChange={(value) =>
                          handleChangeInputDepartmentBranch(value)
                        }
                        noOptionsMessage={() => "Tiada pilihan"}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                      <h5>
                        Akses Daerah (Hanya untuk pegawai daerah/Projek Mikro)
                      </h5>
                      <Select
                        name='input_district'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        isSearchable
                        isClearable
                        closeMenuOnSelect={false}
                        options={districtOptions}
                        // styles={customStylesForm}
                        placeholder='Pilih Daerah'
                        value={formik.values.input_district}
                        onChange={(value) => handleChangeInputDistricts(value)}
                        noOptionsMessage={() => "Tiada pilihan"}
                      />
                    </Form.Group>
                  </div>

                  <Divider />
                  <div className='my-3'>
                    <Form.Label className='fw-bold'>
                      <div className='d-flex'>
                        <span className='me-2'>Tahap capaian sistem *</span>
                        {formik.errors.roles && formik.touched.roles && (
                          <span
                            className='text-error align-self-center'
                            style={{ fontSize: 10 }}
                          >
                            {formik.errors.roles}
                          </span>
                        )}
                      </div>
                      <p className='text-sm text-muted mb-0'>
                        Sila pilih tahap capaian.
                      </p>
                    </Form.Label>

                    <Form.Group className='mb-3'>
                      <Form.Label>Peranan Pengguna</Form.Label>
                      <Select
                        name='group_role'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        isSearchable
                        options={groupRoleOptions}
                        placeholder='Pilih peranan pengguna...'
                        noOptionsMessage={() => "Tiada Pilihan"}
                        value={formik.values.group_role}
                        onChange={(value) => handleSelectGroupRole(value)}
                      />
                    </Form.Group>
                    {/* <div>
                      {newRoleOptions.map((obj1) => {
                        if (obj1.type !== "main") {
                          return (
                            <React.Fragment key={obj1.type}>
                              <div className='mb-3'>
                                <h5 className='text-uppercase'>{obj1.type}</h5>
                                {obj1.type === "Dashboard" && (
                                  <span>
                                    Sila pilih antara Dashboard atau Dashboard
                                    Pengguna
                                  </span>
                                )}
                                <Row>
                                  {obj1.items.slice(0, 4).map((obj2) => {
                                    return (
                                      <React.Fragment key={obj2.id}>
                                        <Col sm={4}>
                                          <Form.Group
                                            className='d-flex align-items-center'
                                            key={obj2.id}
                                            controlId={obj2.id}
                                          >
                                            <Form.Check
                                              name='roles'
                                              className='me-2'
                                              label={obj2.label}
                                              value={obj2.name}
                                              type='checkbox'
                                              onChange={(e) =>
                                                handleCheckRoles(e)
                                              }
                                              checked={(
                                                formik.values.roles || []
                                              ).includes(obj2.name)}
                                              disabled={obj2.disabled}
                                            />
                                          </Form.Group>
                                        </Col>
                                      </React.Fragment>
                                    );
                                  })}
                                  {obj1.items.slice(4, 7).map((obj2) => (
                                    <React.Fragment key={obj2.id}>
                                      <Col sm={4}>
                                        <Form.Group
                                          className='d-flex align-items-center'
                                          key={obj2.id}
                                          controlId={obj2.id}
                                        >
                                          <Form.Check
                                            name='roles'
                                            className='me-2'
                                            label={obj2.label}
                                            value={obj2.name}
                                            type='checkbox'
                                            onChange={(e) =>
                                              handleCheckRoles(e)
                                            }
                                            checked={(
                                              formik.values.roles || []
                                            ).includes(obj2.name)}
                                            disabled={obj2.disabled}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </React.Fragment>
                                  ))}
                                  {obj1.items.slice(7, 10).map((obj2) => (
                                    <React.Fragment key={obj2.id}>
                                      <Col sm={4}>
                                        <Form.Group
                                          className='d-flex align-items-center'
                                          key={obj2.id}
                                          controlId={obj2.id}
                                        >
                                          <Form.Check
                                            name='roles'
                                            className='me-2'
                                            label={obj2.label}
                                            value={obj2.name}
                                            type='checkbox'
                                            onChange={(e) =>
                                              handleCheckRoles(e)
                                            }
                                            checked={(
                                              formik.values.roles || []
                                            ).includes(obj2.name)}
                                            disabled={obj2.disabled}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </React.Fragment>
                                  ))}
                                </Row>
                              </div>
                            </React.Fragment>
                          );
                        }
                      })}
                    </div> */}

                    <div className='d-flex justify-content-between mt-4'>
                      <div className='d-flex gap-3'>
                        {/* <Tooltip>
                        <IconButton color='error'>
                          <Icon path={mdiDelete} size={1} />
                        </IconButton>
                      </Tooltip> */}
                        <Button
                          variant='light'
                          onClick={handleResetPassword}
                          disabled={btnLoading}
                        >
                          Set Semula Kata Laluan
                        </Button>
                        <Button
                          variant='light'
                          onClick={handleResetPasswordBypass}
                          disabled={btnLoading}
                        >
                          Buat Kata Laluan Sementara
                        </Button>
                      </div>

                      <div className='d-flex justify-item-start gap-3'>
                        <Button
                          type='submit'
                          variant='success'
                          disabled={btnLoading || !isEmpty(errors)}
                        >
                          {btnLoading ? (
                            <Icon
                              className='mx-3'
                              path={mdiLoading}
                              size={0.8}
                              spin={true}
                            />
                          ) : (
                            "Simpan"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Col>
              <Col md={5}>
                <div className='text-center'>
                  <img src={SabahLogo} className='w-50 opacity-25'></img>
                </div>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </React.Fragment>
  );
};

export default UserForm;
