import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// redux
import { setSearchApplicationStatus } from "../../../../../redux/slices/micro-project/microProjectSlices";

const InProcessApplication = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => ({
    data: state.microProjectReducer.applicationStatistics?.status_counts,
  }));

  return (
    <React.Fragment>
      <Card
        className='w-100 flex-fill'
        style={{ cursor: "pointer" }}
        onClick={() =>
          dispatch(setSearchApplicationStatus([data[1]?.code, data[2]?.code]))
        }
      >
        <Card.Header className='pb-0'>
          <h5 className='text-uppercase'>{data[1]?.name}</h5>
        </Card.Header>
        <Card.Body className='pt-0 d-flex justify-content-center align-items-center'>
          <div style={{ fontSize: 26, fontWeight: 700 }}>
            {numeral(data[1]?.count + data[2]?.count).format("0,00")}
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default InProcessApplication;
