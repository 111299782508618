import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";

const TableHead = ({ showColumn, project, showAllYear, viewColumns }) => {
  const handleShowAllYear = (e) => {
    const { rmk } = project;
    const { first_year, second_year, third_year, fourth_year, fifth_year } =
      rmk;
    showAllYear([first_year, second_year, third_year, fourth_year, fifth_year]);
  };

  const columns = [
    {
      id: "index",
      className: "text-center align-middle text-truncate",
      rowSpan: 2,
      styles: { width: "5%" },
      cell: (
        <React.Fragment>
          <div className='custom-input-checkbox'>
            <Form.Check
              type='checkbox'
              size='lg'
              onChange={(e) => handleShowAllYear(e)}
            />
          </div>
        </React.Fragment>
      ),
      hideColum: false,
    },
    {
      id: "project_scope",
      name: "KOMPONEN PROJEK",
      className: "text-center align-middle  text-truncate",
      rowSpan: 2,
      hideColum: false,
    },
    {
      id: "cost_requirement",
      name: "ALIRAN SILING DIPOHON (RM)",
      className: "text-center align-middle  text-truncate",
      rowSpan: 2,
      styles: { width: "20%" },
      hideColum: !viewColumns.dipohon.checked,
    },
    {
      id: "upen_section",
      name: "PERAKUAN UPEN",
      className: "text-center",
      children: [
        {
          id: "upen_certified_ceiling_requirement",
          name: "ALIRAN SILING (RM)",
          className: "text-center  text-truncate",
          hideColum: !viewColumns.upen.checked,
        },
      ],
      hideColum: !viewColumns.upen.checked,
    },
    {
      id: "jppn_section",
      name: "PERAKUAN JPPN",
      className: `${
        !showColumn.jppn ? "hide-column " : "text-center  text-truncate"
      }`,
      children: [
        {
          id: "jppn_certified_ceiling_requirement",
          name: "ALIRAN SILING (RM)",
          className: `${
            !showColumn.jppn ? "hide-column " : "text-center  text-truncate"
          }`,
          hideColum: !viewColumns.jppn.checked,
        },
      ],
      hideColum: !viewColumns.jppn.checked,
    },
    {
      id: "cabinet_section",
      name: "KELULUSAN KABINET",
      className: `${
        !showColumn.cabinet ? "hide-column " : "text-center  text-truncate"
      }`,
      children: [
        {
          id: "cabinet_certified_ceiling_requirement",
          name: "ALIRAN SILING (RM)",
          className: `${
            !showColumn.cabinet ? "hide-column " : "text-center  text-truncate"
          }`,
        },
      ],
    },
  ];

  const colSpan = useMemo(() => {
    const childrenLengths = columns.map((column) =>
      column.children ? column.children.length : 0,
    );
    return childrenLengths;
  }, [columns]);

  return (
    <thead className='text-center align-middle custom-table-head'>
      <tr>
        {columns.map((column, idx) => {
          return (
            <React.Fragment key={column.id}>
              {!column.hideColum && (
                <th
                  className={column.className}
                  style={column.styles}
                  colSpan={colSpan[idx]}
                  rowSpan={column.rowSpan}
                >
                  {column.cell || column.name}
                </th>
              )}
            </React.Fragment>
          );
        })}
      </tr>
      <tr>
        {columns.map((column) => (
          <React.Fragment key={`${column.id}-child`}>
            {column.children && (
              <React.Fragment>
                {column.children.map((child) => (
                  <React.Fragment key={child.id}>
                    {!child.hideColum && (
                      <th className={child.className}>{child.name}</th>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
