import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";

const SustainableDevelopmentGoals = ({
  isEdit,
  handleEditItem,
  handleRemoveItem,
}) => {
  const { sustainableDevelopmentGoals } = useSelector((state) => ({
    sustainableDevelopmentGoals: state.sdgReducer.sustainableDevelopmentGoals,
  }));

  return (
    <div className='table-responsive'>
      <Table size='sm'>
        <thead
          className='text-uppercase custom-table-head'
          style={{ fontSize: 12 }}
        >
          <tr>
            <th className='text-center'>Bil.</th>
            <th>Matlamat</th>
            <th>Keterangan</th>
            <th className='text-center'>Tindakan</th>
          </tr>
        </thead>
        <tbody>
          {sustainableDevelopmentGoals
            ?.toSorted((a, b) => a.id - b.id)
            .map((item, idx) => (
              <tr key={item.id}>
                <td className='text-center'>{idx + 1}.</td>
                <td>{item.goal}</td>
                <td>{item.description}</td>
                <td>
                  <div className='d-flex justify-content-center gap-1'>
                    <Tooltip title='Edit' placement='top' arrow>
                      <IconButton
                        color='info'
                        size='small'
                        aria-label='Edit'
                        onClick={() => handleEditItem(item)}
                      >
                        <MdModeEditOutline fontSize='large' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Padam' placement='top' arrow>
                      <IconButton
                        color='error'
                        size='small'
                        aria-label='Padam'
                        onClick={() => handleRemoveItem(item)}
                      >
                        <MdDelete fontSize='large' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SustainableDevelopmentGoals;
