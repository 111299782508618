import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CertifiedCardLoading } from "../../../../../components/common/cardLoading";
import { isEmpty } from "lodash";

const ApplicationStatistics = () => {
  const [applicationSent, setApplicationSent] = useState({
    total_project: 0,
    total_cost: 0,
    total_ceiling: 0,
  });
  const [certifiedByUpen, setCertifiedByUpen] = useState({
    total_project: 0,
    total_cost: 0,
    total_ceiling: 0,
  });
  const [certifiedByJppn, setCertifiedByJppn] = useState({
    total_project: 0,
    total_cost: 0,
    total_ceiling: 0,
  });

  const { cardIsLoading, statistics } = useSelector((state) => ({
    cardIsLoading: state.certifyReducer.cardIsLoading,
    statistics: state.certifyReducer.statisticsCard,
  }));

  useEffect(() => {
    if (!isEmpty(statistics)) {
      const applicationData = {
        total_project: statistics.application_sent.total_project,
        total_cost: statistics.application_sent.cost,
        total_ceiling: statistics.application_sent.ceiling,
      };
      const upenData = {
        total_project: statistics.certified_by_upen.total_project,
        total_cost: statistics.certified_by_upen.cost,
        total_ceiling: statistics.certified_by_upen.ceiling,
      };
      const jppnData = {
        total_project: statistics.certified_by_jppn.total_project,
        total_cost: statistics.certified_by_jppn.cost,
        total_ceiling: statistics.certified_by_jppn.ceiling,
      };

      setApplicationSent(applicationData);
      setCertifiedByUpen(upenData);
      setCertifiedByJppn(jppnData);
    }
  }, [statistics]);

  if (cardIsLoading) return <CertifiedCardLoading />;

  return (
    <React.Fragment>
      <Row>
        <Col md={4} className='d-flex'>
          <Card className='w-100 flex-fill'>
            <Card.Body className='p-3'>
              <div className='fw-bold fs-5 text-uppercase'>
                Daftar permohonan projek
              </div>

              <div className='fw-bolder fs-5 mt-1'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Projek</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        {numeral(applicationSent.total_project).format("0,00")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Kos</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        <span className='me-1'>RM</span>
                        {numeral(applicationSent.total_cost).format("0,00.00")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Siling</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        <span className='me-1'>RM</span>
                        {numeral(applicationSent.total_ceiling).format(
                          "0,00.00",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className='d-flex'>
          <Card className='w-100 flex-fill'>
            <Card.Body className='p-3'>
              <div className='fw-bold fs-5 text-uppercase'>Diperaku UPEN</div>

              <div className='fw-bolder fs-5 mt-1'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Projek</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        {numeral(certifiedByUpen.total_project).format("0,00")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Kos</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        <span className='me-1'>RM</span>
                        {numeral(certifiedByUpen.total_cost).format("0,00.00")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Siling</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        <span className='me-1'>RM</span>
                        {numeral(certifiedByUpen.total_ceiling).format(
                          "0,00.00",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className='d-flex'>
          <Card className='w-100 flex-fill'>
            <Card.Body className='p-3'>
              <div className='fw-bold fs-5 text-uppercase'>Diperaku JPPN</div>

              <div className='fw-bolder fs-5 mt-1'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Projek</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        {numeral(certifiedByJppn.total_project).format("0,00")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Kos</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        <span className='me-1'>RM</span>
                        {numeral(certifiedByJppn.total_cost).format("0,00.00")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span>Jumlah Siling</span>
                          <span className='mx-1'>:</span>
                        </div>
                      </td>
                      <td>
                        <span className='me-1'>RM</span>
                        {numeral(certifiedByJppn.total_ceiling).format(
                          "0,00.00",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApplicationStatistics;
