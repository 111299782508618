import React, { useEffect } from "react";
import Select from "react-select";
import { customStyles } from "../../../../components/common/customStyleSelect";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Tooltip } from "@mui/material";

// icons
import Icon from "@mdi/react";
import { mdiMagnify, mdiRestore } from "@mdi/js";

// redux
import {
  setKeywords,
  setSelectDistrict,
  setIsSearching,
  setResetPage,
} from "../../../../redux/slices/micro-project/microProjectSlices";
import { getDistrictItems } from "../../../../redux/slices/utilsSlice";

const Filters = () => {
  const dispatch = useDispatch();

  const { keywords, districtOptions, selectDistrict } = useSelector(
    (state) => ({
      keywords: state.microProjectReducer.keywords,
      districtOptions: state.utilsReducer.dropdownItems.districtItems,
      selectDistrict: state.microProjectReducer.selectDistrict,
    }),
  );

  const handleSearch = () => {
    dispatch(setIsSearching(true));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const params = {
        source: source,
      };

      await dispatch(getDistrictItems(params));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className='d-flex align-items-center gap-3'>
        <div className='search-box-custom'>
          <div className='position-relative'>
            <label htmlFor='search-bar-0' className='search-label-custom'>
              <input
                type='text'
                className='form-control'
                placeholder='Cari...'
                value={keywords}
                onChange={(e) => dispatch(setKeywords(e.target.value))}
                style={{ width: 250 }}
              />
            </label>
            <Icon className='search-icon-2' path={mdiMagnify} size='18px' />
          </div>
        </div>
        <Form.Group>
          <Select
            className='react-select-container'
            classNamePrefix='react-select'
            isSearchable
            isClearable
            options={districtOptions}
            styles={customStyles}
            placeholder='Semua Daerah'
            value={selectDistrict}
            onChange={(value) => dispatch(setSelectDistrict(value))}
            noOptionsMessage={() => "Tiada pilihan"}
          />
        </Form.Group>
        <Button variant='secondary' onClick={handleSearch}>
          <Icon path={mdiMagnify} size='18px' />
        </Button>
        <div className='ms-auto'>
          <Tooltip tile='Set Semula Paparan' placement='top' arrow>
            <div
              onClick={() => dispatch(setResetPage())}
              style={{ cursor: "pointer" }}
            >
              <Icon path={mdiRestore} size={1} />
            </div>
          </Tooltip>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Filters;
