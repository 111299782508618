import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchStateLegislativeAssemblies = createAsyncThunk(
  "stateLegislativeAssemblies/fetchStateLegislativeAssemblies",
  async (props) => {
    try {
      const { source } = props;

      const response = await axios.get(`/state-legislative-assemblies`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createStateLegislativeAssembly = createAsyncThunk(
  "stateLegislativeAssemblies/createStateLegislativeAssembly",
  async (values) => {
    try {
      const response = await axios.post(
        `/state-legislative-assemblies`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateStateLegislativeAssembly = createAsyncThunk(
  "stateLegislativeAssemblies/updateStateLegislativeAssembly",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(
        `/state-legislative-assemblies/${id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeStateLegislativeAssembly = createAsyncThunk(
  "stateLegislativeAssemblies/removeStateLegislativeAssembly",
  async (id) => {
    try {
      const response = await axios.delete(
        `/state-legislative-assemblies/${id}`,
      );

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  stateLegislativeAssemblies: [],
  isLoading: false,
  btnLoading: false,
};

export const stateLegislativeAssembliesSlice = createSlice({
  name: "stateLegislativeAssemblies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchStateLegislativeAssemblies.pending,
      (state, action) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      fetchStateLegislativeAssemblies.fulfilled,
      (state, action) => {
        state.stateLegislativeAssemblies = action.payload;
        state.isLoading = false;
      },
    );

    builder.addCase(createStateLegislativeAssembly.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      createStateLegislativeAssembly.fulfilled,
      (state, action) => {
        state.stateLegislativeAssemblies.push(action.payload);
        state.isLoading = false;
      },
    );

    builder.addCase(updateStateLegislativeAssembly.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(
      updateStateLegislativeAssembly.fulfilled,
      (state, action) => {
        const { id } = action.payload;

        state.stateLegislativeAssemblies = state.stateLegislativeAssemblies.map(
          (item) => {
            if (item.id === id) {
              return { ...action.payload };
            }

            return item;
          },
        );
        state.btnLoading = false;
      },
    );

    builder.addCase(removeStateLegislativeAssembly.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(
      removeStateLegislativeAssembly.fulfilled,
      (state, action) => {
        state.stateLegislativeAssemblies =
          state.stateLegislativeAssemblies.filter(
            (item) => item.id !== action.payload,
          );

        state.btnLoading = false;
      },
    );
  },
});

export default stateLegislativeAssembliesSlice.reducer;
