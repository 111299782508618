import React from "react";
import { Col, Row } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

// Components
import ProjectInformation from "./components/cards/ProjectInformation";
import ProjectTimeline from "./components/charts/ProjectTimeline";
import ExecutingAgency from "./components/cards/ExecutingAgency";
import StartDate from "./components/cards/StartDate";

import CompletionDate from "./components/cards/CompletionDate";
import LineGraph from "./components/charts/LineGraph";
import FinancialReportSummary from "./components/cards/FinancialReportSummary";
import RadialBar from "./components/charts/RadialBar";
import ProjectLocation from "./components/maps/ProjectLocation";

const PageContent = () => {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const itemLeft = {
    hidden: { opacity: 0, x: -30 },
    show: { opacity: 1, x: 0 },
  };

  return (
    <React.Fragment>
      <motion.div variants={container} initial='hidden' animate='show'>
        <Row>
          <Col
            as={motion.div}
            variants={itemLeft}
            transition={{ duration: 0.5 }}
            md={6}
            className='d-flex'
          >
            <ProjectInformation />
          </Col>
          <Col
            as={motion.div}
            variants={itemLeft}
            transition={{ duration: 0.5 }}
            md={6}
            className='d-flex'
          >
            <Row className='flex-fill'>
              <Col
                as={motion.div}
                variants={itemLeft}
                transition={{ duration: 0.5 }}
                sm={12}
                className='d-flex'
              >
                <ExecutingAgency />
              </Col>
              <Col
                as={motion.div}
                variants={itemLeft}
                transition={{ duration: 0.5 }}
                sm={6}
                className='d-flex'
              >
                <StartDate />
              </Col>
              <Col
                as={motion.div}
                variants={itemLeft}
                transition={{ duration: 0.5 }}
                sm={6}
                className='d-flex'
              >
                <CompletionDate />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col
            as={motion.div}
            variants={itemLeft}
            transition={{ duration: 0.5 }}
            md={5}
            className='d-flex'
          >
            <RadialBar />
          </Col>
          <Col
            as={motion.div}
            variants={itemLeft}
            transition={{ duration: 0.5 }}
            md={7}
            className='d-flex'
          >
            <LineGraph />
          </Col>
        </Row>

        <FinancialReportSummary itemLeft={itemLeft} />

        <ProjectTimeline itemLeft={itemLeft} />

        {/* <ProjectLocation /> */}
      </motion.div>
    </React.Fragment>
  );
};

export default PageContent;
