import React, { useCallback, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import usePalette from "../../../hooks/usePalette";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";

// Icons
import Icon from "@mdi/react";
import { mdiMicrosoftExcel } from "@mdi/js";

// components
import AnalysisTable from "./AnalysisTable";

import { ModuleRegistry } from "@ag-grid-community/core";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

// redux
import { fetchMicroProjectAnalysisTable } from "../../../redux/slices/micro-project/microProjectSlice";
import numeral from "numeral";
import { isEmpty } from "lodash";

ModuleRegistry.registerModules([ExcelExportModule]);

const MainPage = () => {
  const dispatch = useDispatch();
  const gridRef = useRef();
  const palette = usePalette();

  const [rowData, setRowData] = useState([]);

  const onGridReady = async (params) => {
    const props = {
      params: {},
    };
    await dispatch(fetchMicroProjectAnalysisTable(props)).then((res) => {
      setRowData(res.payload);
    });
  };

  // Download Execel
  const onBtnExport = useCallback(() => {
    const params = {
      fileName: "Senarai Projek Mikro",
      sheetName: "Senarai Projek Mikro",
      processRowGroupCallback: (params) => {
        return params.node.key; // Customize group row text, remove arrow
      },
      processCellCallback: ({ column, node, value }) => {
        if (
          column.colId === "cost_requirement" ||
          column.colId === "cost_approved"
        ) {
          const formattedValue = numeral(parseFloat(value)).format("0,0.00");
          return formattedValue;
        }

        if (column.colId === "application_status") {
          const { data } = node;
          return data?.application_status_name;
        }

        if (!isEmpty(value) && typeof value === "object") {
          return value.name;
        } else {
          return value;
        }
      },
    };
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  return (
    <React.Fragment>
      <Container fluid className='p-0'>
        <div className='d-flex justify-content-end mb-3'>
          <div>
            <Tooltip title='Muat Turun XLSX' arrow>
              <Button variant='light' onClick={onBtnExport}>
                <Icon
                  path={mdiMicrosoftExcel}
                  size={1}
                  color={palette.success}
                />
              </Button>
            </Tooltip>
          </div>
        </div>
        <AnalysisTable
          gridRef={gridRef}
          rowData={rowData}
          onGridReady={onGridReady}
        />
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
