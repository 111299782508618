import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import { v4 as uuidv4 } from "uuid";

import {
  getCerityProjects,
  getProjectDetails,
  getCerityStatistics,
  createCreditScore,
} from "./projectsSlice";
import { updateCertify, rejectProject } from "./certifiedComponents";

const initialState = {
  projects: [],
  links: {},
  meta: {},
  project: {
    data: {},
    total_cost_required: 0,
    total_ceiling: 0,
  },
  isLoading: false,
  loadingProjects: false,
  cardIsLoading: false,
  statisticsCard: null,
  btnLoading: false,
  filters: {
    keywords: "",
    searchFields: null,
    stagesFilter: null,
    searchFields: null,
    ministryValues: [],
    departmentValues: [],
  },
  paginations: {
    page: 1,
    perPage: 30,
  },
  creditScoreCriteria: [],
};

export const certifySlice = createSlice({
  name: "certify",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.paginations.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.paginations.perPage = action.payload;
    },
    setStagesFilter: (state, action) => {
      state.filters.stagesFilter = action.payload;
    },
    setSearchFields: (state, action) => {
      state.filters.searchFields = action.payload;
    },
    setMinistryValues: (state, action) => {
      state.filters.ministryValues = action.payload;
    },
    setDepartmentValues: (state, action) => {
      state.filters.departmentValues = action.payload;
    },
    getCriteria: (state, action) => {
      state.creditScoreCriteria = action.payload;
    },
    addCriteria: (state, action) => {
      state.creditScoreCriteria.push(action.payload);
    },
    updateCriteria: (state, action) => {
      const { id, name, value } = action.payload;

      state.creditScoreCriteria = state.creditScoreCriteria.map((item) => {
        if (item.id === id) {
          const updated = {
            ...item,
            [name]: value,
          };
          return updated;
        }
        return item;
      });
    },
    deleteCriteria: (state, action) => {
      state.creditScoreCriteria = state.creditScoreCriteria.filter(
        (item) => item.id !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCerityProjects.pending, (state) => {
      state.loadingProjects = true;
      state.projects = [];
    });
    builder.addCase(getCerityProjects.fulfilled, (state, action) => {
      state.projects = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;

      state.loadingProjects = false;
    });

    builder.addCase(updateCertify.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateCertify.fulfilled, (state) => {
      state.btnLoading = false;
    });
    builder.addCase(rejectProject.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(rejectProject.fulfilled, (state) => {
      state.btnLoading = false;
    });

    builder.addCase(getCerityStatistics.pending, (state) => {
      state.cardIsLoading = true;
      state.statisticsCard = null;
    });

    builder.addCase(getCerityStatistics.fulfilled, (state, action) => {
      state.statisticsCard = action.payload;
      state.cardIsLoading = false;
    });

    builder.addCase(getProjectDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectDetails.fulfilled, (state, action) => {
      const { project, total_cost_required, total_ceiling } = action.payload;

      state.project.data = project;
      state.project.total_cost_required = total_cost_required;
      state.project.total_ceiling = total_ceiling;

      state.creditScoreCriteria = project?.project_criteria;

      state.isLoading = false;
    });

    builder.addCase(createCreditScore.fulfilled, (state, action) => {
      state.project.data.project_criteria = action.payload;
      state.creditScoreCriteria = action.payload;
    });
  },
});

export const {
  setPage,
  setPerPage,
  setStagesFilter,
  setSearchFields,
  setMinistryValues,
  setDepartmentValues,
  getCriteria,
  addCriteria,
  updateCriteria,
  deleteCriteria,
} = certifySlice.actions;

export default certifySlice.reducer;
