import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmDelete = ({ show, onHide, destroy }) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <h5>Anda pasti untuk memadam permohonan ini?</h5>
          <p>Permohonan yang dipadam tidak akan dapat dikembalikan.</p>
          <div className='d-flex justify-content-end gap-2'>
            <Button variant='light' onClick={onHide}>
              Batal
            </Button>
            <Button variant='danger' onClick={destroy}>
              Padam
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmDelete;
