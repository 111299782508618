import React, { useEffect, useRef, useContext, useState } from "react";
import { useSelector } from "react-redux";
import NotyfContext from "../../../../../../contexts/NotyfContext";
import { useParams } from "react-router-dom";
import esriConfig from "@arcgis/core/config";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import WebMap from "@arcgis/core/WebMap";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";

import { intersects, overlaps } from "@arcgis/core/geometry/geometryEngine";

import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import IdentityManager from "@arcgis/core/identity/IdentityManager";

// Widgets
import Sketch from "@arcgis/core/widgets/Sketch";
import SketchTooltipOptions from "@arcgis/core/views/interactive/sketch/SketchTooltipOptions";
import Search from "@arcgis/core/widgets/Search";

const WebMapArcgis = () => {
  const mapRef = useRef();
  const notyf = useContext(NotyfContext);

  let view = useRef(null);

  const [projectCoordinates, setProjectCoordinates] = useState([
    117.017156, 5.489928,
  ]);

  const { user, project } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.projectReducer.project,
  }));

  const editThisAction = {
    title: "Padam",
    id: "remove-this",
    className: "esri-icon-trash",
  };

  useEffect(() => {
    const info = new OAuthInfo({
      appId: "TEvYHd3nhPCZbW5Q", // Replace with your client ID
      portalUrl: "https://smepps-geo.sabah.gov.my/arcgis",
      popup: false,
    });

    IdentityManager.registerOAuthInfos([info]);

    const rendererArea = {
      type: "unique-value",
      field: "OBJECTID", // Replace with the actual field name in your data
      defaultSymbol: {
        type: "simple-fill",
        color: [239, 187, 8, 0.5],
        outline: {
          width: 1,
          color: [239, 187, 8],
        },
      },
      uniqueValueInfos: [],
    };

    const popupProjectInfo = {
      title: "Maklumat Projek",
      content: [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "name",
              label: "Nama Projek",
              isEditable: true,
              tooltip: "",
              visible: true,
              format: null,
              stringFieldOption: "text-box",
            },
            {
              fieldName: "centroid_longitude",
              label: "Longitud",
              isEditable: true,
              tooltip: "",
              visible: true,
              format: null,
              stringFieldOption: "text-box",
            },
            {
              fieldName: "centroid_latitude",
              label: "Latitud",
              isEditable: true,
              tooltip: "",
              visible: true,
              format: null,
              stringFieldOption: "text-box",
            },
          ],
        },
      ],
      actions: [editThisAction],
    };

    const projectLabel = {
      symbol: {
        type: "text",
        color: "#eceff1",
        haloColor: "#37474f",
        haloSize: "1px",
        font: {
          size: "12px",
          family: "Noto Sans",
          style: "italic",
          weight: "normal",
        },
      },

      labelPlacement: "above-center",
      labelExpressionInfo: {
        expression: "$feature.name",
      },
    };

    const graphicsLayer = new GraphicsLayer();

    IdentityManager.getCredential(info.portalUrl + "/sharing")
      .then((credential) => {
        const districtLayer = new FeatureLayer({
          url: "https://services7.arcgis.com/imUjhC2BWfjR1bCA/arcgis/rest/services/SbhDistrictBdy/FeatureServer/0",
        });

        const areaLayer = new FeatureLayer({
          url: "https://smepps-geo.sabah.gov.my/arcmanager/rest/services/Project_Location_Area/FeatureServer",
          outFields: ["*"],
          renderer: rendererArea,
          popupTemplate: popupProjectInfo,
          labelingInfo: [projectLabel],
          definitionExpression: `project_id = ${project.id}`,
        });

        const webMap = new WebMap({
          basemap: "hybrid", // Replace with your WebMap ID
          layers: [districtLayer, graphicsLayer, areaLayer],
        });

        const fetchProjectCoordinates = async () => {
          try {
            const query = areaLayer.createQuery();
            query.where = `project_id = ${project.id}`; // Adjust as necessary
            query.outFields = ["centroid_latitude", "centroid_longitude"]; // Replace with your actual field names

            const results = await areaLayer.queryFeatures(query);

            if (results.features.length > 0) {
              const firstFeature = results.features[0];
              const newCoordinates = [
                firstFeature.attributes.centroid_longitude,
                firstFeature.attributes.centroid_latitude,
              ];

              view = new MapView({
                container: mapRef.current,
                map: webMap,
                center: newCoordinates,
                zoom: 19,
              });

              const sketchTooltipOptions = new SketchTooltipOptions({
                enabled: true,
                visibleElements: {
                  area: true,
                  length: true,
                },
              });

              const sketch = new Sketch({
                layer: graphicsLayer,
                view: view,
                // graphic will be selected as soon as it is created
                creationMode: "update",
                availableCreateTools: ["polygon"],
                tooltipOptions: sketchTooltipOptions,
              });

              view.ui.add(sketch, "top-right");

              sketch.on("create", (event) => {
                if (event.state === "complete") {
                  const { graphic } = event;

                  const newValues = {
                    ...graphic,
                    symbol: graphic.symbol,
                    geometry: graphic.geometry,
                    attributes: {
                      // OBJECTID: 1604,
                      Daerah: null,
                      DUN: null,
                      Parlimen: null,
                      created_user: user.name,
                      created_date: new Date(),
                      last_edited_user: user.name,
                      last_edited_date: new Date(),
                      name: project.name,
                      project_id: project.id,
                    },
                  };
                  const edits = {
                    addFeatures: [newValues],
                  };
                  handleSubmit(edits, event.tool);
                  graphicsLayer.remove(event.graphic);
                }
              });

              // Remove Layout
              const handleRemove = async (params, type) => {
                const edits = {
                  deleteFeatures: [params],
                };
                // if (type === "point") {
                //   await pointLayer.applyEdits(edits).catch((error) => {
                //     console.log("error = ", error);
                //   });
                // }

                if (type === "polygon") {
                  await areaLayer.applyEdits(edits).catch((error) => {
                    console.log("error = ", error);
                  });
                }

                if (view.popup.visible) {
                  view.popup.close();
                }
              };

              view.when(() => {
                reactiveUtils.on(
                  () => view.popup,
                  "trigger-action",
                  (e) => {
                    if (e.action.id === "remove-this") {
                      const selectedFeature = view.popup.selectedFeature;
                      if (selectedFeature) {
                        const selectedLayer = selectedFeature.layer;
                        const featureAttributes = selectedFeature.attributes;
                        const objectIdToDelete = featureAttributes.OBJECTID;

                        handleRemove(
                          selectedFeature,
                          selectedLayer.geometryType,
                        );
                      }
                    }
                  },
                );
              });

              view.when().then(() => {
                view.whenLayerView(areaLayer).then((layerView) => {
                  if (layerView) {
                    const processedSet = new Set();

                    layerView.watch("updating", (updating) => {
                      if (!updating) {
                        layerView.queryFeatures().then((res) => {
                          res.features.forEach((feature, index) => {
                            res.features.forEach((otherFeature, otherIndex) => {
                              if (index !== otherIndex) {
                                const isOverlapping = overlaps(
                                  feature.geometry,
                                  otherFeature.geometry,
                                );

                                if (isOverlapping) {
                                  const attributes = otherFeature.attributes;
                                  const objectId = attributes.OBJECTID;

                                  if (!processedSet.has(objectId)) {
                                    const newUniqueValueInfo = {
                                      value: objectId.toString(),
                                      symbol: {
                                        type: "picture-fill",
                                        // url: getSymbolUrlBasedOnLayerCount(
                                        //   processedSet.size + 1,
                                        // ),
                                        width: "24px",
                                        height: "24px",
                                        outline: {
                                          style: "solid",
                                        },
                                      },
                                    };

                                    rendererArea.uniqueValueInfos.push(
                                      newUniqueValueInfo,
                                    );
                                    processedSet.add(objectId);
                                  }
                                }
                              }
                            });
                          });

                          // Ensure the layer is re-rendered after symbols are updated
                          areaLayer.renderer = rendererArea;
                        });
                      }
                    });
                  } else {
                    console.error("Layer view not available.");
                  }
                });
              });

              // Save Layout
              const handleSubmit = (params, type) => {
                // if (type === "point") {
                //   pointLayer.applyEdits(params).catch((error) => {
                //     console.log("error = ", error);
                //   });
                // }
                console.log(params);
                if (type === "polygon") {
                  areaLayer.applyEdits(params).catch((error) => {
                    console.log("error = ", error);
                  });
                }
              };

              const searchWidget = new Search({
                view: view,
                allPlaceholder: "Cari",
                includeDefaultSources: true,
                sources: [
                  {
                    layer: districtLayer,
                    searchFields: ["Dist_Name"],
                    displayField: "Dist_Name",
                    exactMatch: false,
                  },
                ],
              });

              view.ui.add(searchWidget, {
                position: "top-right",
                index: 2,
              });

              // Store the last added graphic
              let lastGraphic = null;

              // Function to clear the last graphic
              function clearLastGraphic() {
                if (lastGraphic) {
                  view.graphics.remove(lastGraphic);
                  lastGraphic = null;
                }
              }

              searchWidget.on("search-complete", function (event) {
                if (event.numResults === 0) {
                  // Check if the search term is a coordinate
                  var searchTerm = searchWidget.searchTerm.trim();
                  var coords = searchTerm.split(",");
                  if (coords.length === 2) {
                    var lat = parseFloat(coords[0]);
                    var lon = parseFloat(coords[1]);

                    if (!isNaN(lat) && !isNaN(lon)) {
                      var point = new Point({
                        longitude: lon,
                        latitude: lat,
                      });

                      // Create a graphic for the point
                      var graphic = new Graphic({
                        geometry: point,
                        symbol: {
                          type: "simple-marker",
                          color: "red",
                          size: "12px",
                        },
                        popupTemplate: {
                          title: "Coordinates",
                          content: `
                            <ul>
                              <li><b>Latitude:</b> ${lat}</li>
                              <li><b>Longitude:</b> ${lon}</li>
                            </ul>
                          `,
                        },
                      });

                      // view.graphics.removeAll();
                      // view.graphics.add(graphic);
                      clearLastGraphic(); // Clear the last graphic if there is one
                      view.graphics.add(graphic);
                      lastGraphic = graphic; // Store the current graphic

                      view
                        .goTo({
                          target: point,
                          zoom: 15, // Adjust zoom level as needed
                        })
                        .catch(function (error) {
                          console.error(error);
                        });

                      view.popup.open({
                        features: [graphic],
                        location: point,
                      });

                      // Manually trigger the selection
                      searchWidget.searchTerm = searchTerm;
                      searchWidget.resultGraphic = graphic;
                      searchWidget.viewModel.selectedResult = {
                        feature: graphic,
                        name: "Coordinates: " + searchTerm,
                      };
                    }
                  }
                }
              });

              // Remove the point when clicking on the map, but not on the point
              view.on("click", function (event) {
                view.hitTest(event).then(function (response) {
                  let isGraphic = response.results.some(function (result) {
                    return result.graphic === lastGraphic;
                  });
                  if (!isGraphic) {
                    clearLastGraphic();
                  }
                });
              });
            } else {
              console.warn("No features found for the given project ID.");
            }
          } catch (error) {
            console.error("Error fetching project coordinates: ", error);
          }
        };

        fetchProjectCoordinates();
      })
      .catch((error) => {
        console.error("Authentication failed: ", error);
      });

    return () => {
      if (view.current) {
        view.container = null;
      }
    };
  }, [project]);

  return (
    <React.Fragment>
      <div
        ref={mapRef}
        style={{ height: "100%", width: "100%", padding: 0, margin: 0 }}
      />
    </React.Fragment>
  );
};

export default WebMapArcgis;
