import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import axios from "axios";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons

import Icon from "@mdi/react";
import { mdiHelpCircle } from "@mdi/js";

// Redux
import { newUser } from "../../../../../redux/slices/user-management/userManagementSlice";
import {
  getOptions,
  getDistrictItems,
} from "../../../../../redux/slices/utilsSlice";
import { fetchRoles } from "../../../../../redux/slices/maintenance/rolesSlice";
import { Tooltip } from "@mui/material";

const RegistrationForm = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [roleValues, setRoleValues] = useState([]);

  const { ministries, departments, districts, roles } = useSelector(
    (state) => ({
      ministries: state.utilsReducer.dropdownItems.ministries,
      departments: state.utilsReducer.dropdownItems.departments,
      districts: state.utilsReducer.dropdownItems.districtItems,
      roles: state.roleReducer.roles,
    }),
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      nric: "",
      office_address: "",
      phone_number: "",
      fax_number: "",
      ministry_id: "",
      department_id: "",
      district_id: "",
      position: "",
      roles: roleValues,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama penuh pegawai diperlukan"),
      email: Yup.string()
        .email("Sila pastikan emel adalah betul")
        .required("Emel pegawai diperlukan"),
      nric: Yup.string()
        .max(12, "maksimum nombor ialah 12 angka")
        .min(12, "minimum nombor ialah 12 angka")
        .required("Nombor MyKad diperlukan"),
      position: Yup.string().required("Jawatan pegawai diperlukan"),
      roles: Yup.array().min(1, "Tidak boleh dikosongkan."),
    }),
    onSubmit: async (values) => {
      await dispatch(newUser(values));
      showNotification();
      navigate(-1);
    },
  });

  const handleChangeMinistry = (e) => {
    const value = e.target.value;

    formik.setFieldValue("ministry_id", value);

    if (isEmpty(value)) {
      setFilteredDepartments([]);
      return;
    }

    const options = departments.filter((item) => item.ministry_code === value);

    setFilteredDepartments(options);
  };

  const handleChangeDeparment = (e) => {
    const value = e.target.value;

    formik.setFieldValue("department_id", value);
  };

  const handleChangeDistrict = (e) => {
    const value = e.target.value;

    formik.setFieldValue("district_id", value);
  };

  const handleCheckRoles = (e) => {
    const value = e.target.value;

    const isSuperadmin = value === "superadmin";

    let newValues;
    let updatedRoleOptions;

    if (formik.values.roles.includes(value)) {
      newValues = formik.values.roles.filter((item) => item !== value);
      updatedRoleOptions = roleOptions.map((item) => ({
        ...item,
        disabled: false,
      }));
    } else {
      updatedRoleOptions = roleOptions.map((item) => ({
        ...item,
        disabled: isSuperadmin && item.name !== value,
      }));
      newValues = isSuperadmin ? [value] : [...formik.values.roles, value];
    }

    formik.setFieldValue("roles", newValues);
    setRoleOptions(updatedRoleOptions);
  };

  const showNotification = () => {
    // Success
    notyf.open({
      type: "success",
      message: "Pengguna baru telah berjaya di isi",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const initialize = () => {
      const props = {
        source: source,
      };
      dispatch(getOptions(props));
      dispatch(getDistrictItems(props));
      dispatch(fetchRoles());
    };
    initialize();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(roles)) {
      const newOptions = roles.map((item) => {
        return {
          ...item,
          disabled: false,
        };
      });
      setRoleOptions(newOptions);
    }
  }, [roles]);

  return (
    <React.Fragment>
      <Container fluid className='p-0 h-100'>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <Card style={{ width: "55%" }}>
            <Card.Body>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return;
                }}
                autoComplete='off'
                autoCorrect='off'
                validated={false}
              >
                <h5 className='mb-3'>
                  Sila isi maklumat pegawai berdasarkan senarai yang telah
                  diberikan oleh Ketua Agensi
                </h5>

                <Form.Group className='mb-3'>
                  <Form.Label>Nama</Form.Label>
                  <Form.Control
                    type='text'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.touched.name && formik.errors.name ? true : false
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label>Emel</Form.Label>
                  <Form.Control
                    type='email'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label>Nombor MyKad</Form.Label>
                  <Form.Control
                    name='nric'
                    maxLength={12}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.nric}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.touched.nric && formik.errors.nric ? true : false
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.nric}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label>Jawatan</Form.Label>
                  <Form.Control
                    type='text'
                    name='position'
                    value={formik.values.position}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.touched.position && formik.errors.position
                        ? true
                        : false
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.position}
                  </Form.Control.Feedback>
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Form.Group className='mb-3'>
                      <Form.Label>No. Telefon</Form.Label>
                      <Form.Control
                        name='phone_number'
                        type='text'
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className='mb-3'>
                      <Form.Label>No. Fax</Form.Label>
                      <Form.Control
                        name='fax_number'
                        type='text'
                        value={formik.values.fax_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className='mb-3'>
                  <Form.Label>Alamat Pejabat</Form.Label>
                  <Form.Control
                    name='office_address'
                    type='text'
                    as='textarea'
                    style={{ height: 80, minHeight: 80, maxHeight: 110 }}
                    value={formik.values.office_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label>Kementerian</Form.Label>
                  <Form.Select
                    name='ministry_id'
                    onChange={(e) => handleChangeMinistry(e)}
                    value={formik.values.ministry_id}
                  >
                    <option value=''>Pilih</option>
                    {ministries.map((item) => (
                      <option key={item.id} value={item.code}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label>Jabatan</Form.Label>

                  <Form.Select
                    name='ministry_id'
                    onChange={(e) => handleChangeDeparment(e)}
                    value={formik.values.department_id}
                  >
                    <option value=''>Pilih</option>
                    {!isEmpty(filteredDepartments) ? (
                      <>
                        {filteredDepartments.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.label}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {departments.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.label}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label>Pegawai Daerah (Jika Berkaitan)</Form.Label>
                  <Form.Select
                    name='district_id'
                    onChange={(e) => handleChangeDistrict(e)}
                    value={formik.values.district_id}
                  >
                    <option value=''>Pilih</option>
                    {districts.map((item) => (
                      <option key={item.id} value={item.code}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Label className='fw-bold'>
                  <div className='d-flex'>
                    <span className='me-2'>Tahap capaian sistem *</span>
                    {formik.errors.roles && formik.touched.roles && (
                      <span
                        className='text-error align-self-center'
                        style={{ fontSize: 10 }}
                      >
                        {formik.errors.roles}
                      </span>
                    )}
                  </div>
                  <p className='text-sm text-muted mb-0'>
                    Sila pilih tahap capaian.
                  </p>
                </Form.Label>

                <Row>
                  <Col md={4}>
                    {roleOptions.slice(0, 4).map((item) => (
                      <Form.Group
                        className='d-flex align-items-center'
                        key={item.id}
                        controlId={item.id}
                      >
                        <Form.Check
                          name='roles'
                          className='me-2'
                          label={item.label}
                          value={item.name}
                          type='checkbox'
                          onChange={(e) => handleCheckRoles(e)}
                          checked={(formik.values.roles || []).includes(
                            item.name,
                          )}
                          disabled={item.disabled}
                        />
                        {!isEmpty(item.description) && (
                          <Tooltip title={item.description} arrow followCursor>
                            <Icon path={mdiHelpCircle} size={0.6} />
                          </Tooltip>
                        )}
                      </Form.Group>
                    ))}
                  </Col>

                  <Col md={4}>
                    {roleOptions.slice(4, 7).map((item) => (
                      <Form.Group
                        className='d-flex align-items-center'
                        key={item.id}
                        controlId={item.id}
                      >
                        <Form.Check
                          name='roles'
                          className='me-2'
                          label={item.label}
                          value={item.name}
                          type='checkbox'
                          onChange={(e) => handleCheckRoles(e)}
                          checked={(formik.values.roles || []).includes(
                            item.name,
                          )}
                          disabled={item.disabled}
                        />
                        {!isEmpty(item.description) && (
                          <Tooltip title={item.description} arrow followCursor>
                            <Icon path={mdiHelpCircle} size={0.6} />
                          </Tooltip>
                        )}
                      </Form.Group>
                    ))}
                  </Col>

                  <Col md={4}>
                    {roleOptions.slice(7, 10).map((item) => (
                      <Form.Group
                        className=''
                        key={item.id}
                        controlId={item.id}
                      >
                        <Form.Check
                          name='roles'
                          label={item.label}
                          value={item.name}
                          type='checkbox'
                          onChange={(e) => handleCheckRoles(e)}
                          checked={(formik.values.roles || []).includes(
                            item.name,
                          )}
                          disabled={item.disabled}
                        />
                      </Form.Group>
                    ))}
                  </Col>
                </Row>

                <div className='d-flex justify-content-end gap-3'>
                  <Button variant='light'>Batal</Button>
                  <Button type='submit' variant='success'>
                    Simpan
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default RegistrationForm;
