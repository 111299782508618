import React, { useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";

// Components
import Records from "./Records";

// Redux
import { getLoginHistories } from "../../../redux/slices/admin/activityLogSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initilize = () => {
      const props = {
        source: source,
      };

      dispatch(getLoginHistories(props));
    };

    initilize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Container className='p-0'>
        <Card>
          <Card.Body>
            <h4 className='mb-3'>Rekod Log Masuk</h4>
            <Records />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
