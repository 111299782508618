import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isEmpty } from "lodash";

// Components
import CeilingSettings from "./components/tables/CeilingSettings";
import CeilingForm from "./components/modals/CeilingForm";
import Filters from "./components/Filters";
import ModalDelete from "../../../components/common/ModalDelete";

// redux
import {
  fetchCeilingLimits,
  removeCeilingLimit,
} from "../../../redux/slices/rmk-information/ceilingLimitsSlice";
import {
  getMinistries,
  getDepartments,
  getRmkItems,
} from "../../../redux/slices/utilsSlice";

const PageContent = () => {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [rmkFilter, setRmkFilter] = useState(null);
  const [ministryFilter, setMinistryFilter] = useState(null);
  const [item, setItem] = useState({});

  const { activeRmk, btnLoading } = useSelector((state) => ({
    activeRmk: state.rmkReducer.activeRmk,
    btnLoading: state.ceilingLimitReducer.btnLoading,
  }));

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleFilterRmk = (value) => {
    setRmkFilter(value);
  };

  const handleFilterMinistry = (value) => {
    setMinistryFilter(value);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeCeilingLimit(data.id));
    handleCloseRemove();
    // handleNotification("error", data);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        rmk_id: rmkFilter?.id,
        ministry_id: ministryFilter?.code,
      },
    };

    dispatch(getRmkItems(props));
    dispatch(getMinistries(props));
    dispatch(getDepartments(props));
    dispatch(fetchCeilingLimits(props));

    return () => {
      source.cancel();
    };
  }, [dispatch, rmkFilter, ministryFilter]);

  useEffect(() => {
    if (isEmpty(activeRmk)) return;

    setRmkFilter(activeRmk);
  }, [activeRmk]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />
      <CeilingForm showForm={showForm} closeForm={() => setShowForm(false)} />

      <Card className='mt-3'>
        <Card.Header className='pb-0'>
          <div className='d-flex justify-content-between'>
            <div>
              <Filters
                handleFilterRmk={handleFilterRmk}
                rmkFilter={rmkFilter}
                handleFilterMinistry={handleFilterMinistry}
                ministryFilter={ministryFilter}
              />
            </div>
            <Button onClick={handleOpenForm} size='sm'>
              Tetapkan Siling
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <CeilingSettings handleRemoveItem={handleRemoveItem} />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default PageContent;
