import { isEmpty } from "lodash";
import React from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// redux
import { loginRecordSetPage } from "../../../redux/slices/admin/activityLogSlice";
import TablePagination from "./TablePagination";

const Records = () => {
  const dispatch = useDispatch();

  const { loginHistories } = useSelector((state) => ({
    loginHistories: state.activityReducer.loginHistories,
  }));

  const { data, page, perPage } = loginHistories;

  const indexOfLastPage = page * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const tableContent = data?.slice(indexOfFirstPage, indexOfLastPage);

  // Change page
  const paginate = (pageNumber) => {
    dispatch(loginRecordSetPage(pageNumber));
  };

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th>Bil.</th>
              <th>Nama Pengunna</th>
              <th>Agensi</th>
              <th>Masa Log Masuk</th>
            </tr>
          </thead>
          <tbody>
            {isEmpty(tableContent) ? (
              <tr>
                <td colSpan={4} className='text-center'>
                  Tiada maklumat tersedia
                </td>
              </tr>
            ) : (
              <>
                {tableContent.map((item, idx) => (
                  <tr>
                    <td className='text-center'>
                      {indexOfFirstPage + idx + 1}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.agency || "-"}</td>
                    <td>{item.login_at}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
        <TablePagination
          perPage={perPage}
          totalRow={data?.length}
          currentPage={page}
          paginate={paginate}
        />
      </div>
    </React.Fragment>
  );
};

export default Records;
