import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProjectCodeList = ({ handleEditItem }) => {
  const { ministries } = useSelector((state) => ({
    ministries: state.projectCodeReducer.ministries,
  }));

  return (
    <div className='table-responsive'>
      <Table hover size='sm' style={{ width: "100%" }}>
        <thead
          className='text-uppercase custom-table-head'
          style={{ fontSize: 12 }}
        >
          <tr>
            <th className='text-center' style={{ width: "10%" }}>
              Kod
            </th>
            <th>Nama</th>
          </tr>
        </thead>
        <tbody>
          {ministries.map((ministry) => {
            const { programs } = ministry;
            return (
              <React.Fragment key={ministry.code}>
                <tr style={{ backgroundColor: "#eceff1" }}>
                  <td className='text-center fw-bolder'>D{ministry.code}</td>
                  <td>{ministry.name}</td>
                </tr>
                {programs.map((program) => {
                  const { main_projects } = program;
                  return (
                    <React.Fragment key={program.id}>
                      <tr>
                        <td className='text-center fw-bolder'>
                          <div
                            className='bg-secondary text-center text-white px-2 text-truncate'
                            style={{ fontWeight: 700 }}
                          >
                            {program.code}
                          </div>
                        </td>
                        <td>{program.name}</td>
                      </tr>
                      {main_projects.map((mainProject) => {
                        const { projects } = mainProject;
                        return (
                          <React.Fragment key={mainProject.id}>
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditItem(mainProject)}
                            >
                              <td className='text-center fw-bolder'>
                                <div className='bg-primary text-center text-white px-2'>
                                  {mainProject.code}
                                </div>
                              </td>
                              <td>{mainProject.name}</td>
                            </tr>
                            {projects.map((project) => {
                              return (
                                <React.Fragment key={project.id}>
                                  <tr>
                                    <td className='text-center fw-bolder'>
                                      {project.code}
                                    </td>
                                    <td>{project.name}</td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ProjectCodeList;
