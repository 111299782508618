import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchMinistries = createAsyncThunk(
  "projectCode/fetchMinistries",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`/project-codes/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const updateCode = createAsyncThunk(
  "projectCode/updateCode",
  async (values) => {
    try {
      const response = await axios.post(`/project-codes/edit`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

const initialState = {
  ministries: [],
  isLoading: false,
  btnLoading: false,
};

export const projectCodeSlice = createSlice({
  name: "projectCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMinistries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMinistries.fulfilled, (state, action) => {
      state.ministries = action.payload;
      state.isLoading = false;
    });

    builder.addCase(updateCode.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateCode.fulfilled, (state, action) => {
      const data = action.payload;

      state.ministries = state.ministries.map((ministry) => {
        const matchingMinistry = data.find(
          (dataItem) =>
            dataItem.ministry_id.toString() === ministry.code.toString(),
        );

        if (matchingMinistry) {
          const programs = ministry.programs.map((program) => {
            const matchingProgram = data.find(
              (dataItem) => dataItem.program_id === program.id,
            );

            if (matchingProgram) {
              const mainProjects = program.main_projects.map((mainProject) => {
                const matchingMainProject = data.find(
                  (dataItem) => dataItem.main_project_id === mainProject.id,
                );

                if (matchingMainProject) {
                  const subProjects = mainProject.projects.map((project) => {
                    const matchingProject = data.find(
                      (dataItem) => dataItem.id === project.id,
                    );

                    if (matchingProject) {
                      return {
                        ...project,
                        ...matchingProject,
                      };
                    }
                    return project;
                  });
                  return {
                    ...mainProject,
                    projects: subProjects,
                  };
                }
                return mainProject;
              });
              return {
                ...program,
                main_projects: mainProjects,
              };
            }
            return program;
          });
          return {
            ...ministry,
            programs: programs,
          };
        }
        return ministry;
      });

      state.btnLoading = false;
    });
  },
});

export default projectCodeSlice.reducer;
