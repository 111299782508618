import React, { useEffect, useState } from "react";
import {
  View,
  Page,
  Text,
  Image,
  Document,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";
import numeral from "numeral";
import { Button } from "react-bootstrap";

// Styles
import { styles } from "./pdfStyles";
import { useSelector } from "react-redux";

// Icons
import Icon from "@mdi/react";
import { mdiPrinter } from "@mdi/js";

import SabahLogo from "../../assets/img/photos/sabah-logo.png";

const PDFFile = (props) => {
  const { project } = props;

  const [ceilingFlowInfo, setCeilingFlowInfo] = useState([]);

  const { rmk } = project;
  const { first_year, second_year, third_year, fourth_year, fifth_year } = rmk;

  const groupedByYear = ceilingFlowInfo?.reduce(
    (acc, item) => {
      const { year } = item;

      if (year === first_year) {
        acc[first_year].push(item);
      } else if (year === second_year) {
        acc[second_year].push(item);
      } else if (year === third_year) {
        acc[third_year].push(item);
      } else if (year === fourth_year) {
        acc[fourth_year].push(item);
      } else if (year === fifth_year) {
        acc[fifth_year].push(item);
      }
      return acc;
    },
    {
      [first_year]: [],
      [second_year]: [],
      [third_year]: [],
      [fourth_year]: [],
      [fifth_year]: [],
    },
  );

  const ceilingFlow = Object.entries(groupedByYear).map(([year, data]) => ({
    year: year,
    data,
  }));

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !isEmpty(project)) {
      const {
        financial_details,
        executing_agency,
        financially_responsible_agency,
        owner_agency,
        applicant_agency,
        upen_section,
        stages,
        rmk,
      } = project;

      const updatedArray = financial_details?.map((financial) => {
        const updatedChildren = financial.ceiling_flow_details?.map((flow) => ({
          ...flow,
          financial_detail_id: financial.id,
          cost_description: financial.cost_description,
        }));
        return { ...financial, ceiling_flow_details: updatedChildren };
      });

      const childrenArray = updatedArray
        ?.map((financial) => financial.ceiling_flow_details)
        ?.reduce((acc, flow) => acc.concat(flow), []);

      setCeilingFlowInfo(childrenArray);
    }
    return () => {
      isMounted = false;
    };
  }, [project]);
  return (
    <Document>
      <Page size='A4' style={styles.body}>
        {/* <Image src={SabahLogo} style={styles.image} /> */}
        <Text style={styles.title}>
          {`Keterangan Mengenai Maklumat Projek`.toLocaleUpperCase()}
        </Text>
        <Text style={styles.title}>
          {`${project.rmk?.name} : ${first_year}-${fifth_year}`.toLocaleUpperCase()}
        </Text>
        <Text style={styles.title}>
          {"(Pembiayaan Kerajaan Negeri)".toLocaleUpperCase()}
        </Text>
        <Table style={styles.tableContainer}>
          <TR>
            <TD weighting={0.04} style={styles.tdIndexTitle}>
              BIL.
            </TD>
            <TD weighting={0} style={styles.tdTitle}>
              PROJEK PROFIL
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              1.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              Kementerian
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.ministry?.value}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              2.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              Jabatan
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.department?.value}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              3.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              Program
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.program?.name}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              4.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              Projek Utama
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.main_project?.name}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              5.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              Nama Projek
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.name}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              6.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              Justifikasi Projek
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.justification}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              7.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              Keterangan Projek
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.justification}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              8.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              KPI Program dan Projek
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>
                {project?.key_performance_indicator}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              9.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Matlamat Pembangunan Mampan</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>-</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              10.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Sektor Utama</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.main_sector?.name}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              11.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Halatuju Strategi</Text>
            </TD>
            <TD style={styles.tdContent}>
              <View>
                {project?.strategic_directions?.map((item, idx) => {
                  const lastIndex = project?.strategic_directions.length - 1;

                  return (
                    <View key={idx}>
                      <Text>{item.data?.name}</Text>
                    </View>
                  );
                })}
              </View>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              12.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Kategori Projek</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text
                style={styles.cellText}
              >{`${project?.project_category?.value} (${project?.project_category?.code})`}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              13.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Jenis Projek</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.project_type?.name}</Text>
            </TD>
          </TR>
        </Table>
      </Page>
      <Page size='A4' style={styles.body}>
        <Table style={styles.tableContainer}>
          <TR>
            <TD weighting={0.04} style={styles.tdIndexTitle}></TD>
            <TD weighting={0} style={styles.tdTitle}>
              MAKLUMAT KEWANGAN
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              14.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Skop Projek</Text>
            </TD>
            <TD>
              <Table style={styles.childTableContainer}>
                <TH>
                  <TD style={styles.childCellTitle}>Skop</TD>
                  <TD style={styles.childCellTitle}>Kos Dipohon</TD>
                </TH>
                {project?.financial_details?.map((item) => (
                  <TR key={item.id}>
                    <TD style={styles.childCellRowCostDescription}>
                      {item.cost_description}
                    </TD>
                    <TD style={styles.childCellRowCost}>
                      {`RM ${numeral(item.cost_required).format("0,0.00")}`}
                    </TD>
                  </TR>
                ))}
                <TR>
                  <TD
                    style={{
                      display: "flex",
                      padding: 4,
                    }}
                  >
                    Jumlah
                  </TD>
                  <TD
                    style={{
                      padding: 4,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {`RM ${numeral(
                      project?.financial_details?.reduce(
                        (acc, financial) =>
                          acc + parseFloat(financial.cost_required),
                        0,
                      ),
                    ).format("0,0.00")}`}
                  </TD>
                </TR>
              </Table>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              15.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Aliran Siling</Text>
            </TD>
            <TD>
              <Table style={styles.childTableContainer}>
                <TR>
                  {ceilingFlow?.map((item) => (
                    <TD key={item.year} style={styles.childCellTitle}>
                      Tahun {item.year}
                    </TD>
                  ))}
                </TR>
                <TR>
                  {ceilingFlow?.map((item) => {
                    const costRequired = item.data.reduce(
                      (acc, flow) => acc + parseFloat(flow.amount),
                      0,
                    );
                    return (
                      <TD
                        key={item.year}
                        style={{
                          padding: 4,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {`RM ${numeral(costRequired).format("0,0.00")}`}
                      </TD>
                    );
                  })}
                </TR>
              </Table>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              16.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Sumber Pembiayaan</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.funding_sources}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndexTitle}></TD>
            <TD weighting={0} style={styles.tdTitle}>
              MAKLUMAT FIZIKAL
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              17.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Skop Projek</Text>
            </TD>
            <TD>
              <Table style={styles.childTableContainer}>
                <TH>
                  <TD style={styles.childCellTitle}>Skop</TD>
                  <TD style={styles.childCellTitle}>Tarikh Mula (Dijangka)</TD>
                  <TD style={styles.childCellTitle}>Tarikh Siap (Dijangka)</TD>
                  <TD style={styles.childCellTitle}>Tempoh</TD>
                </TH>
                {project?.financial_details?.map((item) => (
                  <TR key={item.id}>
                    <TD style={styles.childCellRowCostDescription}>
                      {item.cost_description}
                    </TD>
                    <TD style={styles.childCellRowDate}>
                      {!isEmpty(
                        item.project_scope_details?.expected_start_date,
                      ) &&
                        format(
                          new Date(
                            item.project_scope_details?.expected_start_date,
                          ),
                          "dd MMM yyyy",
                        )}
                    </TD>
                    <TD style={styles.childCellRowDate}>
                      {!isEmpty(
                        item.project_scope_details?.expected_completion_date,
                      ) &&
                        format(
                          new Date(
                            item.project_scope_details?.expected_completion_date,
                          ),
                          "dd MMM yyyy",
                        )}
                    </TD>
                    <TD style={styles.childCellRowDate}>
                      {item.project_scope_details?.expected_completion_date &&
                        formatDistanceStrict(
                          new Date(
                            item.project_scope_details?.expected_start_date,
                          ),
                          new Date(
                            item.project_scope_details?.expected_completion_date,
                          ),
                          {
                            addSuffix: false,
                            locale: ms,
                          },
                        )}
                    </TD>
                  </TR>
                ))}
              </Table>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              18.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Tarikh Tender (Jangkaan)</Text>
            </TD>
            <TD style={styles.tdContent}>
              {!isEmpty(project.physical_details?.expected_tender_date) &&
                format(
                  new Date(project.physical_details?.expected_tender_date),
                  "dd MMM yyyy",
                )}
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              19.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Tarikh Mula (Jangkaan)</Text>
            </TD>
            <TD style={styles.tdContent}>
              {!isEmpty(project.physical_details?.expected_start_date) &&
                format(
                  new Date(project.physical_details?.expected_start_date),
                  "dd MMM yyyy",
                )}
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              20.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Tarikh Siap (Jangkaan)</Text>
            </TD>
            <TD style={styles.tdContent}>
              {!isEmpty(project.physical_details?.expected_completion_date) &&
                format(
                  new Date(project.physical_details?.expected_completion_date),
                  "dd MMM yyyy",
                )}
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              21.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Keluasan Tapak</Text>
            </TD>
            <TD style={styles.tdContent}>
              {numeral(project.physical_details?.site_width).format("0,0.00")}{" "}
              ekar
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              22.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>
                Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
              </Text>
            </TD>
            <TD style={styles.tdContent}>
              {project.physical_details
                ?.proposed_land_in_accordance_with_local_plans
                ? "Ya"
                : "Tidak"}
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              23.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Cadangan Berkaitan Utiliti</Text>
            </TD>
            <TD style={styles.tdContent}>
              {project.physical_details?.proposed_utility ? "Ya" : "Tidak"}
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              24.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>
                Cadangan kegunaan tanah telah dibincang ke Jawatankuasa
                Perancang Negeri
              </Text>
            </TD>
            <TD style={styles.tdContent}>
              {project.physical_details?.proposed_land_discussed_with_upen
                ? "Ya"
                : "Tidak"}
            </TD>
          </TR>
        </Table>
      </Page>
      <Page size='A4' style={styles.body}>
        <Table style={styles.tableContainer}>
          <TR>
            <TD weighting={0.04} style={styles.tdIndexTitle}></TD>
            <TD weighting={0} style={styles.tdTitle}>
              MAKLUMAT LAIN
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              25.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Seksyen UPEN</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>{project?.upen_section?.name}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              26.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Agensi Permohonan</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>
                {project?.applicant_agency?.value}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              27.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Agensi Pemilik</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>
                {project?.owner_agency?.value}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              28.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Agensi Bertanggungjawab (Kewangan)</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>
                {project?.financially_responsible_agency?.value}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.04} style={styles.tdIndex}>
              29.
            </TD>
            <TD weighting={0.2} style={styles.tdLabel}>
              <Text>Agensi Pelaksana</Text>
            </TD>
            <TD style={styles.tdContent}>
              <Text style={styles.cellText}>
                {project?.executing_agency?.value}
              </Text>
            </TD>
          </TR>
        </Table>
      </Page>
    </Document>
  );
};

const Application = () => {
  const [fileName, setFileName] = useState("");

  const { project } = useSelector((state) => ({
    project: state.projectReducer.project,
  }));

  useEffect(() => {
    if (!isEmpty(project)) {
      const { created_at } = project;

      const createdAt = format(new Date(created_at), "yyyy MM dd");
      const convertName = createdAt.split(" ").join("_");
      setFileName(convertName);
    }
  }, [project]);

  if (isEmpty(project)) return <>Loading...</>;

  return (
    <React.Fragment>
      <PDFDownloadLink
        document={<PDFFile project={project} />}
        fileName={`${fileName}.pdf`}
      >
        {({ blob, url, loading, error }) => (
          <Button variant='secondary' disabled={loading}>
            <Icon path={mdiPrinter} size={0.8} />
          </Button>
        )}
      </PDFDownloadLink>
    </React.Fragment>
  );
};

export default Application;
