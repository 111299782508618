import React from "react";
import { Button, Modal } from "react-bootstrap";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

const ModalDelete = ({
  showRemove,
  closeRemove,
  title,
  message,
  data,
  confirmRemove,
  btnLoading,
  module,
}) => {
  const handleCloseRemove = () => {
    closeRemove();
  };

  return (
    <Modal show={showRemove} onHide={handleCloseRemove} size='sm' centered>
      <Modal.Body>
        <h4 className='mb-3'>{title}</h4>

        {module === "user" ? (
          <h3>{data?.is_deleted ? "Padam?" : "Set ke tidak aktif?"}</h3>
        ) : (
          <h3>Padam maklumat.</h3>
        )}
        <p>{data && data.name}</p>
        <p>{message && message}</p>
        {data?.is_deleted && (
          <p className='text-error'>Pengguna ini akan dipadam secara kekal.</p>
        )}

        <div className='d-flex justify-content-center'>
          <Button
            variant='light'
            className='me-3 px-3'
            onClick={handleCloseRemove}
            disabled={btnLoading}
          >
            Batal
          </Button>
          <Button
            className='px-3'
            variant='danger'
            onClick={() => confirmRemove(data)}
            disabled={btnLoading}
          >
            {btnLoading ? (
              <Icon className='mx-3' path={mdiLoading} size={0.8} spin={true} />
            ) : (
              <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                {module === "user" ? (
                  <> {data?.is_deleted ? "Padam" : "Tidak aktif"}</>
                ) : (
                  "Padam"
                )}
              </span>
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDelete;
