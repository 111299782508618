import React, { useEffect, useState } from "react";
import { Badge, Button, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

// redux
import { setAddSelectedProject } from "../../../../../redux/slices/jppn/jppnModuleSlice";

const AvailableProject = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const [projectValue, setProjectValue] = useState(null);

  const { options } = useSelector((state) => ({
    options: state.jppnReducer.apply_new_project.available_ceiling_projects,
  }));

  const handleSelectProject = (value) => {
    const newValue = {
      ...value,
      jppn_ceiling_required: 0,
    };
    setProjectValue(newValue);
  };

  const handleAddProject = async () => {
    await dispatch(setAddSelectedProject(projectValue));
    onHide();
  };

  useEffect(() => {
    setProjectValue(null);
  }, [show]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        centered
        keyboard={false}
        backdrop='static'
      >
        <Modal.Body>
          <h5 className='mb-3'>Sila pilih projek.</h5>
          <div className='mb-3'>
            <div className='mb-3'>
              <Select
                isSearchable
                isClearable
                options={options}
                placeholder='Pilih...'
                styles={{
                  indicatorSeparator: (state) => ({
                    display: "none",
                  }),
                }}
                value={projectValue}
                onChange={(value) => handleSelectProject(value)}
                noOptionsMessage={() => "Tiada pilihan"}
              />
            </div>
            <h5>Maklumat Projek</h5>
            <Table size='sm' bordered>
              <tbody>
                <tr>
                  <th style={{ width: "30%" }}>Kod Projek</th>
                  <td>{projectValue?.sub_project_full_code || "-"}</td>
                </tr>
                <tr>
                  <th>Nama Projek</th>
                  <td style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                    {projectValue?.name || "-"}
                  </td>
                </tr>
                <tr>
                  <th>Baki Siling (RM)</th>
                  <td style={{ fontWeight: 700 }}>
                    {projectValue?.total_ceiling_balance || "-"}
                  </td>
                </tr>
                <tr>
                  <th className='align-middle'>Siling Dipohon (RM)</th>
                  <td>
                    <Form.Group>
                      <Form.Control
                        className={`text-truncate`}
                        as={CurrencyInput}
                        placeholder='0.00'
                        decimalsLimit={2}
                        decimalScale={2}
                        value={projectValue?.jppn_ceiling_required}
                        onValueChange={(value) => {
                          const newValues = {
                            ...projectValue,
                            jppn_ceiling_required: value,
                          };
                          setProjectValue(newValues);
                        }}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <th>Status Kemajuan</th>
                  <td>
                    {projectValue && (
                      <Badge
                        bg=''
                        className='text-uppercase'
                        style={{
                          backgroundColor:
                            projectValue?.current_progress_status?.color,
                        }}
                      >
                        {projectValue?.current_progress_status?.label}
                      </Badge>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className='d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={onHide}>
              Batal
            </Button>
            <Button variant='success' onClick={handleAddProject}>
              Tambah
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AvailableProject;
