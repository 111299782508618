import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, Card } from "react-bootstrap";

import { AuthContext } from "../../contexts/AuthContext";

// Img
import Searching from "../../assets/img/illustrations/searching.png";
import SabahLogo from "../../assets/img/photos/sabah-logo.png";

const Page404 = () => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const goBack = () => {
    // if (user?.roles.includes("dashboard")) {
    //   navigate("/pengumuman");
    // }
    navigate("/");
  };

  return (
    <React.Fragment>
      <Helmet title='404 Error' />
      <div className='text-center'>
        <h1 className='display-1 fw-bold'>404</h1>
        <p className='h1'>Halaman tidak ditemui.</p>
        <p className='h2 fw-normal mt-3 mb-4'>
          Halaman yang anda cari tidak ditemui.
        </p>
        {/* <Link to='#'> */}
        <Button variant='primary' size='lg' onClick={goBack}>
          Kembali ke halaman utama
        </Button>
        {/* </Link> */}
      </div>
    </React.Fragment>
  );
};

export default Page404;
