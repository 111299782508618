import React, { useRef } from "react";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { Button, Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import usePalette from "../../../../../hooks/usePalette";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import numeral from "numeral";

// Icons
import Icon from "@mdi/react";
import { MdCancel } from "react-icons/md";

// Components
import SourceOfCeiling from "./tables/SourceOfCeiling";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Form.Control
    {...field}
    {...props}
    type='text'
    as='textarea'
    style={{ minHeight: 60, maxHeight: 85 }}
  />
);

const SourceOfFinancial = () => {
  const formRef = useRef();
  const palette = usePalette();

  const { values, submitForm } = useFormikContext();

  const {
    project,
    currentRmk,
    financialSource,
    ceilingIncrement,
    virementOutProjects,
  } = useSelector((state) => ({
    financialSource: state.jppnReducer.virement.financialSource,
    currentRmk: state.rmkReducer.activeRmk,
    project: state.projectReducer.project,
    ceilingIncrement: state.jppnReducer.virement.ceilingIncrement,
    virementOutProjects: state.jppnReducer.virement.virementOutProjects,
  }));

  return (
    <React.Fragment>
      <div className='mb-4'>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>6.0</span>Cadangan Sumber Kewangan
        </Form.Label>

        {!isEmpty(virementOutProjects) && (
          <div className='d-flex flex-row ps-4'>
            <div className='me-3'>6.1</div>
            <p>
              {`${project?.ministry?.short_form} telah mengenalpasti sumber pindahan siling bagi projek ini iaitu memlalui sumber dalam program di bawah ${project?.ministry?.short_form} sendiri iaitu sejumlah`}
              {ceilingIncrement !== 0 ? (
                <span className='mx-1'>
                  RM{numeral(ceilingIncrement).format("0,0")}
                </span>
              ) : (
                <span className='fst-italic mx-1' style={{ fontWeight: 700 }}>
                  sila masukkan nilai tambahan siling
                </span>
              )}
              {`akan dipindah keluar daripada`}
              {financialSource ? (
                <span className='mx-1'>{`${financialSource?.program?.prefix_type}${financialSource?.ministry?.code} ${financialSource?.program?.code} ${financialSource?.main_project?.code}${financialSource?.code} : ${financialSource?.name}`}</span>
              ) : (
                <span className='fst-italic mx-1' style={{ fontWeight: 700 }}>
                  sila pilih sumber kewangan
                </span>
              )}
              .
            </p>
          </div>
        )}

        <div className='d-flex ps-4'>
          <div className='me-3'>
            6.{!isEmpty(virementOutProjects) ? "2" : "1"}
          </div>
          <p className='mb-0'>
            Ringkasan cadangan sumber kewangan untuk permohonan siling projek
            ini adalah seperti di <b>Jadual 1</b> yang menjelaskan kedudukan
            selepas pindahan siling.
          </p>
        </div>

        {/* <FieldArray name='source_of_financial'>
          {({ insert, remove, push }) => (
            <React.Fragment>
              {values.source_of_financial.map((item, index) => (
                <div key={index} className='d-flex flex-row ps-4'>
                  <div className='me-3'>6.{index + 1}</div>
                  <Form.Group className='position-relative mb-3 w-100'>
                    <Field
                      name={`source_of_financial.${index}.description`}
                      component={CustomInputComponent}
                    />
                    {index !== 0 && (
                      <div
                        className='position-absolute'
                        style={{ top: -11, right: -8 }}
                      >
                        <Tooltip placement='top' title='Padam' arrow>
                          <Link to='#' onClick={() => remove(index)}>
                            <MdCancel size={20} color={palette.danger} />
                          </Link>
                        </Tooltip>
                      </div>
                    )}
                  </Form.Group>
                </div>
              ))}
              <div className='d-flex justify-content-center'>
                <Button
                  size='sm'
                  variant='secondary'
                  onClick={() => push({ description: "" })}
                >
                  + Cadangan Sumber Kewangan
                </Button>
              </div>
            </React.Fragment>
          )}
        </FieldArray> */}
      </div>
      <SourceOfCeiling />

      <div className='d-flex ps-4 mt-3'>
        <div className='me-3'>6.2</div>
        <p className='mb-0'>
          {`Pindahan sumber siling daripada ${financialSource?.name} (${financialSource?.program?.prefix_type}${financialSource?.ministry?.code} ${financialSource?.program?.code} ${financialSource?.main_project?.code}${financialSource?.code}) tidak akan menjejaskan pelaksanaan program ini memandangkan siling tersedia adalah mencukupi bagi memenuhi permohonan tahunan projek-projek dibawah program tersebut.`}
        </p>
      </div>
    </React.Fragment>
  );
};

export default SourceOfFinancial;
