const programCategories = [
  {
    id: 1,
    label: "Program Nasional",
    value: "Program Nasional",
  },
  {
    id: 2,
    label: "Program Negeri",
    value: "Program Negeri",
  },
  {
    id: 3,
    label: "Program Kementerian Negeri",
    value: "Program Kementerian Negeri",
  },
  {
    id: 4,
    label: "Program Jabatan Negeri",
    value: "Program Jabatan Negeri",
  },
];

const fundingSourceOptions = [
  {
    id: 1,
    value: "Kumpulan Wang Negeri",
  },
  {
    id: 2,
    value: "Pinjaman Persekutuan",
  },
  {
    id: 3,
    value: "Bayaran Balik Persekutuan",
  },
];

const annoucementType = [
  {
    id: "",
    label: "Pilih...",
    value: "",
  },
  {
    id: 1,
    label: "Pengumuman",
    value: 1,
  },
  {
    id: 2,
    label: "Peringatan",
    value: 2,
  },
  {
    id: 3,
    label: "Penyelenggaraan",
    value: 3,
  },
];

const statusOptions = [
  {
    id: 1,
    label: "Draf",
    value: "Draf",
  },
  {
    id: 2,
    label: "Dihantar",
    value: "Dihantar",
  },
  {
    id: 5,
    label: "Ditolak",
    value: "Ditolak",
  },
];

const documentTypeOptions = [
  {
    label: "Peta / Lakaran lokasi projek",
    value: "Peta / Lakaran lokasi projek",
  },
  {
    label: "Surat / dokumen pemilikan hak milik tapak (bina baru bangunan)",
    value: "Surat / dokumen pemilikan hak milik tapak (bina baru bangunan)",
  },
  {
    label: "Gambar keadaan semasa cadangan projek",
    value: "Gambar keadaan semasa cadangan projek",
  },
  {
    label: "Lain-lain",
    value: "Lain-lain",
  },
];

export {
  programCategories,
  fundingSourceOptions,
  annoucementType,
  statusOptions,
  documentTypeOptions,
};
