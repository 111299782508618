import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { customStylesForm } from "../../../../components/common/customStyleSelect";
import { useDispatch, useSelector } from "react-redux";

// Redux
import {
  createGroupRoles,
  updateGroupRoles,
} from "../../../../redux/slices/maintenance/rolesSlice";
import { isEmpty } from "lodash";

const GroupRoleForm = ({ show, hide, isEdit, data }) => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);

  const { roles } = useSelector((state) => ({
    roles: state.roleReducer.roles,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      accessToInput: null,
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data?.id,
          name: values.name,
          access_to: values.accessToInput.map((item) => item.name).join(", "),
        };
        await dispatch(updateGroupRoles(newValues));
      } else {
        const newValues = {
          name: values.name,
          access_to: values.accessToInput.map((item) => item.name).join(", "),
        };
        await dispatch(createGroupRoles(newValues));
      }
      hide();
    },
  });

  useEffect(() => {
    const newValues = roles
      ?.filter((item) => !data?.access_to?.split(", ").includes(item.name))
      .map((item) => {
        return {
          ...item,
          value: item.name,
        };
      });

    setOptions(newValues);
  }, [roles, data]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const find = roles
        ?.filter((item) => data?.access_to?.split(", ").includes(item.name))
        .map((item) => {
          return {
            ...item,
            value: item.name,
          };
        });
      formik.setFieldValue("accessToInput", find);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={hide}
        centered
        size='md'
        backdrop='static'
        keyboard={false}
      >
        <Modal.Body>
          <div>
            <Card.Title className='mb-0'>Tambah Peranan</Card.Title>
            <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
          </div>
          <Form
            autoComplete='off'
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Form.Group className='mb-3'>
              <Form.Label>Nama Peranan</Form.Label>
              <Form.Control
                type='text'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={
                  formik.touched.name && !formik.errors.name ? true : false
                }
                isInvalid={
                  formik.touched.name && formik.errors.name ? true : false
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Tahap Akses/Pautan</Form.Label>
              <Select
                name='access_to'
                className='react-select-container'
                classNamePrefix='react-select'
                isSearchable
                isMulti
                options={options}
                // styles={customStylesForm}
                placeholder='Pilih tahap akses...'
                noOptionsMessage={() => "Tiada Pilihan"}
                value={formik.values.accessToInput}
                onChange={(value) =>
                  formik.setFieldValue("accessToInput", value)
                }
              />
            </Form.Group>

            <div className='mt-3 d-flex justify-content-end gap-3'>
              <Button
                variant='light'
                onClick={hide}
                // disabled={btnLoading}
              >
                Batal
              </Button>
              <Button
                className='px-3'
                variant='success'
                type='submit'
                // disabled={btnLoading}
              >
                Simpan
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default GroupRoleForm;
