import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosS3p from "../../../utils/axiosS3p";
import axios from "axios";

export const fetchFinancialData = createAsyncThunk(
  "s3p/fetchFinancialData",
  async () => {
    try {
      const props = {
        year: 2024,
      };

      const { year } = props;
      const ministry = "all";

      const response = await axios.get(
        `https://acc-api.sabah.gov.my/smepps/${year}/${ministry}`,
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_ACC_API_TOKEN, //the token is a variable which holds the token
          },
        },
      );

      const data = await response;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  financialData: [],
  isLoading: false,
  btnLoading: false,
};

export const s3pDataSlice = createSlice({
  name: "s3p",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFinancialData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFinancialData.fulfilled, (state, action) => {
      state.financialData = action.payload;
      state.btnLoading = false;
    });
  },
});

export default s3pDataSlice.reducer;
