import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

//Styles
import { customStyles } from "../../../components/common/customStyleSelect";
import NotyfContext from "../../../contexts/NotyfContext";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

// Components
import MainSectors from "./components/tables/MainSectors";
import MainSectorForm from "./components/modals/MainSectorForm";
import ModalDelete from "../../../components/common/ModalDelete";
import LoadingState from "../../../components/common/LoadingState";

// Redux
import { fetchRmkList } from "../../../redux/slices/rmk-information/rmkSlice";
import {
  fetchMainSectors,
  removeMainSector,
} from "../../../redux/slices/rmk-information/stateMainSectorSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [openForm, setOpenForm] = useState(false);
  const [rmkInfo, setRmkInfo] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);

  const { rmkList, mainSectorList, isLoading, btnLoading } = useSelector(
    (state) => ({
      rmkList: state.rmkReducer.rmkList,
      isLoading: state.mainSectorReducer.isLoading,
      btnLoading: state.mainSectorReducer.btnLoading,
      mainSectorList: state.mainSectorReducer.mainSectorList,
    }),
  );

  const handleCloseForm = () => {
    setOpenForm(false);
    handleResetState();
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleResetState = () => {
    setIsEdit(false);
    setItem({});
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    handleResetState();
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeMainSector(data.id));
    handleNotification("error", data);
  };

  useEffect(() => {
    dispatch(fetchRmkList());
    dispatch(fetchMainSectors());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(rmkList)) {
      const activeRmk = rmkList.find((item) => item.active === true);
      setRmkInfo(activeRmk);
    }
  }, [rmkList]);

  useEffect(() => {
    if (!btnLoading) {
      handleCloseRemove();
      handleCloseForm();
    }
  }, [btnLoading]);

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <MainSectorForm
        rmkInfo={rmkInfo}
        showForm={openForm}
        closeForm={handleCloseForm}
        handleNotification={handleNotification}
        data={item}
        btnLoading={btnLoading}
        isEdit={isEdit}
      />

      <Helmet title='Senarai Sektor Utama Negeri' />
      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Maklumat RMK'
          breadcrumbItem='Senarai Sektor Utama Negeri'
        />
        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Sektor Utama
              </Button>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari Sektor Utama...'
                      // value={searchText}
                      // onChange={(e) => setSearchText(e.target.value)}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>

              <Form.Group>
                <Select
                  placeholder='RMK'
                  styles={customStyles}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={[
                    {
                      id: 2,
                      label: "RMK 12",
                      value: "RMK 12",
                    },
                    {
                      id: 3,
                      label: "RMK 11",
                      value: "RMK 11",
                    },
                  ]}
                />
              </Form.Group>
            </div>
          </Card.Header>
          <Card.Body>
            <MainSectors
              mainSectorList={mainSectorList}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
