import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchMainSectors = createAsyncThunk(
  "rmk/fetchMainSectors",
  async () => {
    try {
      const response = await axios.get(`/main-sectors`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createMainSector = createAsyncThunk(
  "rmk/createMainSector",
  async (values) => {
    try {
      const response = await axios.post(`/main-sectors`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateMainSector = createAsyncThunk(
  "rmk/updateMainSector",
  async (values) => {
    try {
      const response = await axios.put(`/main-sectors/${values.id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeMainSector = createAsyncThunk(
  "rmk/removeMainSector",
  async (id) => {
    try {
      const response = await axios.delete(`/main-sectors/${id}`);

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  mainSectorList: [],
  isLoading: false,
  btnLoading: false,
};

export const stateMainSectorSlice = createSlice({
  name: "stateMainSector",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMainSectors.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMainSectors.fulfilled, (state, action) => {
      state.mainSectorList = action.payload;
      state.isLoading = false;
    });

    builder.addCase(createMainSector.pending, (state) => {
      state.btnLoading = true;
    });

    builder.addCase(createMainSector.fulfilled, (state, action) => {
      state.mainSectorList.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateMainSector.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateMainSector.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.mainSectorList = state.mainSectorList.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeMainSector.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeMainSector.fulfilled, (state, action) => {
      state.mainSectorList = state.mainSectorList.filter(
        (item) => item.id !== action.payload,
      );
      state.btnLoading = false;
    });
  },
});

export default stateMainSectorSlice.reducer;
