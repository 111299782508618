import React, { useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

// Components
import Users from "./components/Users";
import Filters from "./components/Filters";

// Redux
import { getDistrictItems } from "../../../../redux/slices/utilsSlice";
import { getOptions } from "../../../../redux/slices/utilsSlice";
import { fetchUsers } from "../../../../redux/slices/user-management/userManagementSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchMinistry, searchDepartment } = useSelector((state) => ({
    searchMinistry: state.userManagementReducer.search.ministry,
    searchDepartment: state.userManagementReducer.search.department,
  }));

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {
          ministry_id: searchMinistry,
          department_id: searchDepartment,
        },
      };

      let navigateUrl = "/pengurusan-pengguna/senarai-pengguna-berdaftar";

      if (!isEmpty(searchMinistry)) {
        navigateUrl += `?kementerian=${searchMinistry}`;
      }

      if (!isEmpty(searchDepartment)) {
        navigateUrl += `${
          navigateUrl.includes("?") ? "&" : "?"
        }jabatan=${searchDepartment}`;
      }

      navigate(navigateUrl);

      dispatch(getDistrictItems(props));
      dispatch(getOptions(props));
      dispatch(fetchUsers(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch, searchMinistry, searchDepartment]);
  return (
    <React.Fragment>
      <Helmet title='Senarai Pengguna yang telah berdaftar' />
      <Container fluid className='p-0'>
        <h4>Senarai Pengguna Berdaftar</h4>

        <Card>
          <Card.Body>
            <Filters />
            <Users />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
