import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

const ProjectScopes = ({ handleEditItem, handleRemoveItem }) => {
  const { scopeList } = useSelector((state) => ({
    scopeList: state.projectScopeReducer.scopeList,
  }));

  const sortList = [...scopeList].sort((a, b) => b.id - a.id);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center'>Bil.</th>
              <th>Nama Skop</th>
              <th>Keterangan Skop</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {sortList.map((row, idx) => (
              <tr key={row.id}>
                <td className='text-center'>{idx + 1}.</td>
                <td>{row.name}</td>
                <td>{row.description}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Tooltip title='Edit' placement='top' arrow>
                      <IconButton
                        color='info'
                        size='small'
                        aria-label='Edit'
                        onClick={() => handleEditItem(row)}
                      >
                        <MdModeEditOutline fontSize='large' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Padam' placement='top' arrow>
                      <IconButton
                        color='error'
                        size='small'
                        aria-label='Padam'
                        onClick={() => handleRemoveItem(row.id)}
                      >
                        <MdDelete fontSize='large' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default ProjectScopes;
