import React, { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import ModalDelete from "../../../components/common/ModalDelete";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// redux
import {
  fetchSustainableDevelopmentGoals,
  removeSustainableDevelopmentGoal,
} from "../../../redux/slices/maintenance/sustainableDevelopmentGoalsSlice";

// Components
import SustainableDevelopmentGoals from "./components/tables/SustainableDevelopmentGoals";
import SustainableDevelopmentGoalForm from "./components/modals/SustainableDevelopmentGoalForm";
import axios from "axios";

const MainPage = () => {
  const dispatch = useDispatch();

  const [perPage, setPerPage] = useState(15);
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);

  const { btnLoading } = useSelector((state) => ({
    btnLoading: state.sdgReducer.btnLoading,
  }));

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setItem({});
    setIsEdit(false);
  };

  const handleEditForm = (values) => {
    setOpenForm(true);
    setItem(values);
    setIsEdit(true);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    setItem({});
    setIsEdit(false);
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeSustainableDevelopmentGoal(data.id));
    handleCloseRemove();
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
      params: {},
    };

    dispatch(fetchSustainableDevelopmentGoals(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <SustainableDevelopmentGoalForm
        showForm={openForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        data={item}
      />

      <Helmet title='Senarai Cawangan' />
      <Container fluid className='p-0'>
        <h4>
          Senarai Maklumat Pembangunan Mampan
          <span className='ms-1 fst-italic text-muted text-sm'>
            (Sustainable Development Goals)
          </span>
        </h4>

        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> SDG
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <SustainableDevelopmentGoals
              handleEditItem={handleEditForm}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
