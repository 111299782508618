import { format } from "date-fns";
import { ms } from "date-fns/locale";
import React from "react";
import { Card, Accordion, Table, Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const ResponsibleOfficer = ({ project }) => {
  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>MAKLUMAT PEGAWAI BERTANGGUNGJAWAB</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <Card className='mb-0'>
          <Card.Body>
            <div className='px-3 py-2'>
              <Row>
                <Col md={6} className='d-flex'>
                  <div className='flex-fill'>
                    <Form.Group as={Row} className='mb-1'>
                      <Form.Label column sm={4} className='text-sm-right'>
                        Pegawai Bertanggungjawab
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Label column className='fw-bolder'>
                          {project?.created_by?.name}
                        </Form.Label>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mb-1'>
                      <Form.Label column sm={4} className='text-sm-right'>
                        Jawatan
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Label column className='fw-bolder'>
                          {project?.created_by?.position}
                        </Form.Label>
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col md={6} className='d-flex'>
                  <div className='flex-fill'>
                    <Form.Group as={Row} className='mb-1'>
                      <Form.Label column sm={4} className='text-sm-right'>
                        No. Telefon
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Label column className='fw-bolder'>
                          {project?.created_by?.phone_number}
                        </Form.Label>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mb-1'>
                      <Form.Label column sm={4} className='text-sm-right'>
                        Emel
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Label column className='fw-bolder'>
                          {project?.created_by?.email}
                        </Form.Label>
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group as={Row} className='mb-1'>
                    <Form.Label column sm={4} className='text-sm-right'>
                      Dikemaskini oleh
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Label column className='fw-bolder'>
                        {project?.updated_by?.name}
                      </Form.Label>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group as={Row} className='mb-1'>
                    <Form.Label column sm={4} className='text-sm-right'>
                      Kementerian
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Label column className='fw-bolder'>
                        {project?.created_by?.ministry?.name}
                      </Form.Label>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row} className='mb-1'>
                    <Form.Label column sm={4} className='text-sm-right'>
                      Tarikh Kemaskini
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Label column className='fw-bolder'>
                        {project.updated_at &&
                          format(
                            new Date(project?.updated_at),
                            "dd/MM/yyyy hh:mm a",
                            { locale: ms },
                          )}
                      </Form.Label>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ResponsibleOfficer;
