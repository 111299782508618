import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DataEkonomi = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const newTab = window.open("https://www.google.com", "_blank");

    if (newTab) {
      newTab.focus();
    }

    const returnTimeout = setTimeout(() => {
      navigate(-1);
    }, 1000);

    return () => clearTimeout(returnTimeout);
  }, []);

  return <div>Redirecting to external URL...</div>;
};

export default DataEkonomi;
