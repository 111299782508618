import numeral from "numeral";
import React from "react";
import { Card } from "react-bootstrap";
import usePalette from "../../../../hooks/usePalette";
import { useSelector } from "react-redux";

const ApprovedApplication = () => {
  const palette = usePalette();

  const totalProjects = useSelector(
    (state) =>
      state.microProjectReducer.dashboards.cards?.total_projects_approved,
  );

  return (
    <Card className='flex-fill w-100'>
      <Card.Header>
        <h4 className='text-uppercase' style={{ color: palette.success }}>
          Jumlah Permohonan Diluluskan
        </h4>
      </Card.Header>
      <Card.Body className='text-center'>
        <h1 style={{ fontWeight: 700, color: palette.success }}>
          {numeral(totalProjects).format("0,0")}
        </h1>
      </Card.Body>
    </Card>
  );
};

export default ApprovedApplication;
