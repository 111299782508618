import React from "react";
import { Button, Modal } from "react-bootstrap";
import usePalette from "../../../../../../hooks/usePalette";
import { useSelector } from "react-redux";

// Icons
import Icon from "@mdi/react";
import { mdiAlertCircle, mdiLoading } from "@mdi/js";

const ConfirmSend = ({ show, hide, confirmSend }) => {
  const palette = usePalette();

  const { btnLoading } = useSelector((state) => ({
    btnLoading: state.reportReducer.btnLoading,
  }));
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={hide}
        centered
        size='sm'
        backdrop='static'
        keyboard={false}
      >
        <Modal.Body>
          <div className='text-center mb-2'>
            <Icon
              className='mb-3'
              path={mdiAlertCircle}
              size={3.5}
              color={palette.danger}
            />
            <h4>Anda pasti untuk menghantar?</h4>
          </div>
          <div className='mb-3 text-center'>
            <p>Sila pastikan laporan diisi dengan maklumat yang betul.</p>
          </div>
          {/* <div
            className='mb-3 border-start border-danger px-3 py-2'
            style={{ backgroundColor: "#ffebee" }}
          >
            <p className='mb-0 text-danger'>
              Sekiranya terdapat perubahan yang perlu dilakukan ke atas laporan
              yang telah dihantar, sila berhubung dengan pihak UPEN(seksyen yang
              dipertanggungjawabkan) dan nyatakan no. laporan yang hendak
              diubah.
            </p>
          </div> */}
          <div className='d-flex justify-content-center gap-2'>
            <Button variant='light' onClick={hide} disabled={btnLoading}>
              <span className='text-sm'>BATAL</span>
            </Button>
            <Button
              variant='success'
              onClick={confirmSend}
              disabled={btnLoading}
            >
              {btnLoading ? (
                <>
                  <Icon
                    className='align-self-center'
                    path={btnLoading && mdiLoading}
                    size={0.8}
                    spin={btnLoading ? true : false}
                    style={{ marginLeft: 14, marginRight: 14 }}
                  />
                </>
              ) : (
                <>
                  <span className='text-sm'>HANTAR</span>
                </>
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmSend;
