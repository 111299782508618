export const SIDEBAR_POSITION = {
  LEFT: "left",
  RIGHT: "right",
};

export const SIDEBAR_BEHAVIOR = {
  STICKY: "sticky",
  FIXED: "fixed",
  COMPACT: "compact",
};

export const LAYOUT = {
  FLUID: "fluid",
  BOXED: "boxed",
};

export const THEME = {
  DEFAULT: "default",
  COLORED: "colored",
  DARK: "dark",
  LIGHT: "light",
};

export const THEME_PALETTE_LIGHT = {
  primary: "#3B82EC",
  "primary-dark": "#1659c7",
  "primary-light": "#84aef2",
  secondary: "#495057",
  success: "#4caf50",
  info: "#1F9BCF",
  warning: "#ff9800",
  "secondary-danger": "#e65100",
  danger: "#d32f2f",
  white: "#fff",
  "gray-100": "#f4f7f9",
  "gray-200": "#e2e8ee",
  "gray-300": "#dee6ed",
  "gray-400": "#ced4da",
  "gray-500": "#adb5bd",
  "gray-600": "#6c757d",
  "gray-700": "#495057",
  "gray-800": "#020202",
  "gray-900": "#212529",
  black: "#000",
};

export const THEME_PALETTE_DARK = {
  ...THEME_PALETTE_LIGHT,
  "primary-dark": "#84aef2",
  "primary-light": "#1659c7",
  white: "#293042",
  "gray-100": "#3e4555",
  "gray-200": "#545968",
  "gray-300": "#696e7b",
  "gray-400": "#7f838e",
  "gray-500": "#9498a1",
  "gray-600": "#a9acb3",
  "gray-700": "#bfc1c6",
  "gray-800": "#d4d6d9",
  "gray-900": "#eaeaec",
  black: "#fff",
};

export const PROGRESS_STATUS = [
  {
    id: 1,
    label: "BELUM MULA",
    value: "BELUM MULA",
    color: "#b0bec5",
  },
  {
    id: 2,
    label: "DALAM PERANCANGAN",
    value: "DALAM PERANCANGAN",
    color: "#607d8b",
  },
  {
    id: 3,
    label: "DAHULU JADUAL",
    value: "DAHULU JADUAL",
    color: "#90caf9",
  },
  {
    id: 4,
    label: "IKUT JADUAL",
    value: "DAHULU JADUAL",
    color: "#2196f3",
  },
  {
    id: 5,
    label: "LEWAT JADUAL",
    value: "DAHULU JADUAL",
    color: "#ffc107",
  },
  {
    id: 6,
    label: "SAKIT",
    value: "SAKIT",
    color: "#f44336",
  },
  {
    id: 7,
    label: "SIAP AKTIVITI",
    value: "SIAP AKTIVITI",
    color: "#81c784",
  },
  {
    id: 8,
    label: "SIAP SEPENUHNYA",
    value: "SIAP SEPENUHNYA",
    color: "#43a047",
  },
];

export const MONTH_OPTIONS = [
  {
    no: 1,
    value: "Januari",
  },
  {
    no: 2,
    value: "Februari",
  },
  {
    no: 3,
    value: "Mac",
  },
  {
    no: 4,
    value: "April",
  },
  {
    no: 5,
    value: "Mei",
  },
  {
    no: 6,
    value: "Jun",
  },
  {
    no: 7,
    value: "Julai",
  },
  { no: 8, value: "Ogos" },
  {
    no: 9,
    value: "September",
  },
  {
    no: 10,
    value: "Oktober",
  },
  {
    no: 11,
    value: "November",
  },
  {
    no: 12,
    value: "Disember",
  },
];

export const PROJECT_TYPE_OPTIONS = [
  {
    id: 1,
    name: "Fizikal",
  },
  {
    id: 2,
    name: "Program (Fizikal)",
  },
  {
    id: 3,
    name: "Program (Bukan Fizikal)",
  },
];

export const PROJECT_CATEGORY_OPTIONS = [
  {
    id: 1,
    name: "Baru",
  },
  {
    id: 2,
    name: "Sambungan",
  },
  {
    id: 3,
    name: "Sedia Ada",
  },
];
