import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import CurrencyInput from "react-currency-input-field";

// redux
import { getRmkItems } from "../../../../../../redux/slices/utilsSlice";
import { storeExpenditureData } from "../../../../../../redux/slices/projects/projectExpenditureData";

const ExpenditureForm = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const [yearOptions, setYearOptions] = useState([]);
  const [rmkValue, setRmkValue] = useState(null);
  const [data, setData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const { rmk, project } = useSelector((state) => ({
    rmk: state.utilsReducer.dropdownItems.rmkItems,
    project: state.projectReducer.project,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rmk: rmkValue || null,
      year: data ? { label: data?.year, value: data?.year } : null,
      state_fund: data?.state_fund || "0",
      federal_loan: data?.federal_loan || "0",
      federal_loan_repayment: data?.federal_loan_repayment || "0",
      current_balance: data?.current_balance || "0",
      revised_total_allocation: data?.revised_total_allocation || "0",
      januari_bendahari:
        data?.monthly_info?.find((item) => item.month === "01")?.bendahari ||
        "0",
      januari_lejar:
        data?.monthly_info?.find((item) => item.month === "01")?.lejar || "0",
      januari_terkini:
        data?.monthly_info?.find((item) => item.month === "01")?.terkini || "0",
      februari_bendahari:
        data?.monthly_info?.find((item) => item.month === "02")?.bendahari ||
        "0",
      februari_lejar:
        data?.monthly_info?.find((item) => item.month === "02")?.lejar || "0",
      februari_terkini:
        data?.monthly_info?.find((item) => item.month === "02")?.terkini || "0",
      mac_bendahari:
        data?.monthly_info?.find((item) => item.month === "03")?.bendahari ||
        "0",
      mac_lejar:
        data?.monthly_info?.find((item) => item.month === "03")?.lejar || "0",
      mac_terkini:
        data?.monthly_info?.find((item) => item.month === "03")?.terkini || "0",
      april_bendahari:
        data?.monthly_info?.find((item) => item.month === "04")?.bendahari ||
        "0",
      april_lejar:
        data?.monthly_info?.find((item) => item.month === "04")?.lejar || "0",
      april_terkini:
        data?.monthly_info?.find((item) => item.month === "04")?.terkini || "0",
      mei_bendahari:
        data?.monthly_info?.find((item) => item.month === "05")?.bendahari ||
        "0",
      mei_lejar:
        data?.monthly_info?.find((item) => item.month === "05")?.lejar || "0",
      mei_terkini:
        data?.monthly_info?.find((item) => item.month === "05")?.terkini || "0",
      jun_bendahari:
        data?.monthly_info?.find((item) => item.month === "06")?.bendahari ||
        "0",
      jun_lejar:
        data?.monthly_info?.find((item) => item.month === "06")?.lejar || "0",
      jun_terkini:
        data?.monthly_info?.find((item) => item.month === "06")?.terkini || "0",
      julai_bendahari:
        data?.monthly_info?.find((item) => item.month === "07")?.bendahari ||
        "0",
      julai_lejar:
        data?.monthly_info?.find((item) => item.month === "07")?.lejar || "0",
      julai_terkini:
        data?.monthly_info?.find((item) => item.month === "07")?.terkini || "0",
      ogos_bendahari:
        data?.monthly_info?.find((item) => item.month === "08")?.bendahari ||
        "0",
      ogos_lejar:
        data?.monthly_info?.find((item) => item.month === "08")?.lejar || "0",
      ogos_terkini:
        data?.monthly_info?.find((item) => item.month === "08")?.terkini || "0",
      september_bendahari:
        data?.monthly_info?.find((item) => item.month === "09")?.bendahari ||
        "0",
      september_lejar:
        data?.monthly_info?.find((item) => item.month === "09")?.lejar || "0",
      september_terkini:
        data?.monthly_info?.find((item) => item.month === "09")?.terkini || "0",
      oktober_bendahari:
        data?.monthly_info?.find((item) => item.month === "10")?.bendahari ||
        "0",
      oktober_lejar:
        data?.monthly_info?.find((item) => item.month === "10")?.lejar || "0",
      oktober_terkini:
        data?.monthly_info?.find((item) => item.month === "10")?.terkini || "0",
      november_bendahari:
        data?.monthly_info?.find((item) => item.month === "11")?.bendahari ||
        "0",
      november_lejar:
        data?.monthly_info?.find((item) => item.month === "11")?.lejar || "0",
      november_terkini:
        data?.monthly_info?.find((item) => item.month === "11")?.terkini || "0",
      disember_bendahari:
        data?.monthly_info?.find((item) => item.month === "12")?.bendahari ||
        "0",
      disember_lejar:
        data?.monthly_info?.find((item) => item.month === "12")?.lejar || "0",
      disember_terkini:
        data?.monthly_info?.find((item) => item.month === "12")?.terkini || "0",
    },
    validationSchema: Yup.object().shape({
      rmk: Yup.object().shape().required("Sila pilih rmk").nullable(),
      year: Yup.object().shape().required("Sila pilih tahun").nullable(),
    }),
    onSubmit: async (values) => {
      setBtnLoading(true);
      const newValues = {
        ...values,
        project_id: project?.id,
        project_code: project?.sub_project_full_code,
        year: values?.year?.value,
      };
      await dispatch(storeExpenditureData(newValues));
      handleOnHide();
      setBtnLoading(false);
    },
  });

  const handleOnHide = () => {
    onHide();
    setData(null);
    formik.resetForm();
  };

  const handleSelectRmk = (value) => {
    const years = [
      {
        label: value.fifth_year,
        value: value.fifth_year,
      },
      {
        label: value.fourth_year,
        value: value.fourth_year,
      },
      {
        label: value.third_year,
        value: value.third_year,
      },
      {
        label: value.second_year,
        value: value.second_year,
      },
      {
        label: value.first_year,
        value: value.first_year,
      },
    ];

    formik.setFieldValue("rmk", value);
    formik.setFieldValue("year", null);
    setRmkValue(value);

    setYearOptions(years);
    setData(null);
  };

  const handleSelectYear = (obj) => {
    const year = obj?.value;
    const s3pData = project?.s3p_data;

    formik.setFieldValue("year", obj);

    if (year) {
      const selectedData = s3pData?.find((item) => item.year === year) || null;
      const newValues = {
        ...selectedData,
        rmk: rmkValue,
      };
      setData(selectedData);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    formik.handleSubmit();

    return false;
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initilize = () => {
      const props = {
        source: source,
      };

      dispatch(getRmkItems(props));
    };

    initilize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleOnHide}
        centered
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label>RMK</Form.Label>
              <Select
                name='rmk'
                options={rmk}
                placeholder='Pilih RMK'
                noOptionsMessage={() => "Tiada pilihan"}
                components={{ IndicatorSeparator: () => null }}
                value={formik.values?.rmk}
                onChange={(value) => handleSelectRmk(value)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Tahun</Form.Label>
              <Select
                name='year'
                options={yearOptions}
                placeholder='Pilih Tahun'
                noOptionsMessage={() => "Tiada pilihan"}
                components={{ IndicatorSeparator: () => null }}
                value={formik.values?.year}
                onChange={(obj) => handleSelectYear(obj)}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Kumpulan Wang Negeri (RM)</Form.Label>
                  <Form.Control
                    name='state_fund'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.state_fund}
                    onValueChange={(value) => {
                      formik.setFieldValue("state_fund", value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Pinjaman Persekutuan (RM)</Form.Label>
                  <Form.Control
                    name='federal_loan'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.federal_loan}
                    onValueChange={(value) => {
                      formik.setFieldValue("federal_loan", value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Bayaran Balik Persekutuan (RM)</Form.Label>
                  <Form.Control
                    name='federal_loan_repayment'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.federal_loan_repayment}
                    onValueChange={(value) => {
                      formik.setFieldValue("federal_loan_repayment", value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Peruntukan Disemak (RM)</Form.Label>
                  <Form.Control
                    name='revised_total_allocation'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.revised_total_allocation}
                    onValueChange={(value) => {
                      formik.setFieldValue("revised_total_allocation", value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Baki Semasa (RM)</Form.Label>
                  <Form.Control
                    name='current_balance'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.current_balance}
                    onValueChange={(value) => {
                      formik.setFieldValue("current_balance", value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Januari</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='januari_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.januari_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("januari_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='januari_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.januari_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("januari_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='januari_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.januari_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("januari_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Februari</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='februari_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.februari_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("februari_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='februari_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.februari_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("februari_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='februari_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.februari_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("februari_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Mac</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='mac_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.mac_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("mac_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='mac_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.mac_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("mac_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='mac_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.mac_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("mac_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>April</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='april_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.april_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("april_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='april_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.april_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("april_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='april_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.april_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("april_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Mei</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='mei_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.mei_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("mei_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='mei_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.mei_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("mei_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='mei_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.mei_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("mei_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Jun</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='jun_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.jun_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("jun_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='jun_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.jun_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("jun_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='jun_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.jun_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("jun_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Julai</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='julai_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.julai_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("julai_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='julai_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.julai_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("julai_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='julai_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.julai_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("julai_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Ogos</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='ogos_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.ogos_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("ogos_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='ogos_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.ogos_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("ogos_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='ogos_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.ogos_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("ogos_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>September</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='september_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.september_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("september_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='september_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.september_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("september_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='september_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.september_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("september_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Oktober</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='oktober_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.oktober_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("oktober_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='oktober_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.oktober_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("oktober_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='oktober_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.oktober_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("oktober_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>November</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='november_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.november_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("november_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='november_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.november_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("november_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='november_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.november_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("november_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='fw-bolder'>Disember</Form.Label>
              <Row>
                <Col>
                  <Form.Label>S3P (RM)</Form.Label>
                  <Form.Control
                    name='disember_bendahari'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.disember_bendahari}
                    onValueChange={(value) => {
                      formik.setFieldValue("disember_bendahari", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Lejar (RM)</Form.Label>
                  <Form.Control
                    name='disember_lejar'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.disember_lejar}
                    onValueChange={(value) => {
                      formik.setFieldValue("disember_lejar", value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Terkini (RM)</Form.Label>
                  <Form.Control
                    name='disember_terkini'
                    className='text-end'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.disember_terkini}
                    onValueChange={(value) => {
                      formik.setFieldValue("disember_terkini", value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>

          <div className='d-flex justify-content-end gap-3 mt-3'>
            <Button
              variant='light'
              disabled={btnLoading}
              onClick={handleOnHide}
            >
              Batal
            </Button>
            <Button
              variant='success'
              disabled={btnLoading}
              onClick={(e) => handleSave(e)}
            >
              Simpan
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ExpenditureForm;
