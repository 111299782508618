import React from "react";

// Logo
import SabahLogo from "../../assets/img/photos/sabah-logo.png";

const LoadingState = () => {
  return (
    <div className='vh-100 d-flex flex-column justify-content-center align-items-center'>
      <div>
        <img className='loading-logo' src={SabahLogo} alt='' height={200} />
      </div>
    </div>
  );
};

export default LoadingState;
