import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import usePalette from "../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiSquareEditOutline } from "@mdi/js";

// Components
import BasicInfo from "./components/BasicInfo";
import Details from "./components/Details";
import { IconButton } from "@mui/material";

const MainPage = () => {
  const palette = usePalette();
  const [isEdit, setIsEdit] = useState(false);

  const handleEnableEdit = () => {
    setIsEdit(true);
  };

  const handleCloseEdit = () => {
    setIsEdit(false);
  };

  return (
    <React.Fragment>
      <Helmet title='Blank Page' />
      <Container fluid className='p-0'>
        <h1 className='h3 mb-3'>Profil</h1>
        <Card>
          <Card.Header
            className='position-relative'
            style={{ backgroundColor: "#bbdefb" }}
          >
            <div className='position-absolute' style={{ top: 10, right: 10 }}>
              <IconButton
                size='small'
                sx={{ color: palette.primary }}
                onClick={handleEnableEdit}
              >
                <Icon path={mdiSquareEditOutline} size={1} />
              </IconButton>
            </div>
            <Card.Title className='mb-0' style={{ color: "#1976d2" }}>
              Butiran Peribadi
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <BasicInfo isEdit={isEdit} handleCloseEdit={handleCloseEdit} />
          </Card.Body>
          {/* <Card.Header className='position-relative'>
            <Card.Title className='mb-0'>Butiran Peribadi</Card.Title>
          </Card.Header>
          <Card.Body>
            <Details />
          </Card.Body> */}
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
