import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const updatePhysicalDetails = createAsyncThunk(
  "project/updatePhysicalDetails",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/physical-details/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createProjectScopeDetails = createAsyncThunk(
  "project/createProjectScopeDetails",
  async (values) => {
    try {
      const response = await axios.post(`/project-scope-details`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateProjectScopeDetails = createAsyncThunk(
  "project/updateProjectScopeDetails",
  async (values) => {
    const { id } = values;
    try {
      const response = await axios.post(
        `/project-scope-details/update`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);
