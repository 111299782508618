import React from "react";
import { Card, Container } from "react-bootstrap";
import usePalette from "../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiCheckCircle } from "@mdi/js";

const SuccessPage = () => {
  const palette = usePalette();

  return (
    <React.Fragment>
      <Container
        fluid
        className='p-0 h-100 d-flex justify-content-center align-items-center'
      >
        <Card style={{ width: "40%" }}>
          <Card.Body className='d-flex flex-column justify-content-center align-items-center'>
            <h3>Pengesahan Berjaya!</h3>
            <Icon path={mdiCheckCircle} size='25%' color={palette.success} />
            <p className='text-center mt-3'>
              Pegawai-pegawai yang telah mendaftar akan mendapat kelulusan
              daripada Unit Perancang Ekonomi Negeri (UPEN) untuk mengakses
              sistem ini. Sekian Terima Kasih
            </p>
            <p>Anda boleh menutup paparan ini.</p>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default SuccessPage;
