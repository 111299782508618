import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getCombineReportProjectsProgressStatus = createAsyncThunk(
  "dashboard/combineReport/getCombineReportProjectsProgressStatus",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/combine-report-projects-progress-status/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getCombineReportProgressStatusByMinistries = createAsyncThunk(
  "dashboard/combineReport/getCombineReportProgressStatusByMinistries",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/combine-report-progress-status-by-ministries/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getCombineReportProgressStatusByDepartments = createAsyncThunk(
  "dashboard/combineReport/getCombineReportProgressStatusByDepartments",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/combine-report-progress-status-by-departments/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getCombineReportProgressStatusByPrograms = createAsyncThunk(
  "dashboard/combineReport/getCombineReportProgressStatusByPrograms",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/combine-report-progress-status-by-programs/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getCombineReportProgressStatusByMainProjects = createAsyncThunk(
  "dashboard/combineReport/getCombineReportProgressStatusByMainProjects",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `dashboard/combine-report-progress-status-by-main-projects/get`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
