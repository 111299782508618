import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Col, Form, Pagination, Row, Table } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { IconButton, Tooltip } from "@mui/material";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { TenColumns } from "../../../../../components/common/tableLoading";

// Icons
import { MdDelete, MdEdit } from "react-icons/md";

const TableProjects = ({
  item,
  projects,
  page,
  perPage,
  handlePerPage,
  goToPage,
  goToFirstPage,
  goToLastPage,
  nextPage,
  previousPage,
  handleChangePerPage,
}) => {
  const navigate = useNavigate();

  const [pageValue, setPageValue] = useState("");

  const { links, meta, isLoading } = useSelector((state) => ({
    meta: state.certifyReducer.meta,
    links: state.certifyReducer.links,
    isLoading: state.certifyReducer.loadingProjects,
  }));

  const handleEditItem = (project) => {
    const { id, application_stages_name } = project;
    navigate(`/perakuan/${application_stages_name.toLocaleLowerCase()}/${id}`);
  };

  return (
    <React.Fragment>
      <motion.div variants={item} className='table-responsive'>
        <Table hover striped size='sm' style={{ fontSize: 11 }}>
          <thead className='text-uppercase'>
            <tr>
              <th className='text-center' style={{ width: "3%" }}>
                BIL.
              </th>
              <th>PROJEK UTAMA DAN SUB PROJEK</th>
              <th>KEMENTERIAN</th>
              <th>JABATAN / AGENSI</th>
              <th className='text-center'>JENIS PERMOHONAN</th>
              <th>KOS DIPOHON</th>
              <th>SILING DIPOHON</th>
              <th className='text-center'>STATUS PERMOHONAN</th>
              <th>DIKEMAS KINI OLEH</th>
              <th className='text-center'>PERINGKAT</th>
            </tr>
          </thead>
          {isLoading ? (
            <TenColumns />
          ) : (
            <tbody>
              {projects.length === 0 ? (
                <tr>
                  <td colSpan={10} className='text-center'>
                    Maklumat tidak tersedia
                  </td>
                </tr>
              ) : (
                <>
                  {projects.map((project, idx) => {
                    const status = project.application_status;
                    const stages = project.stages;

                    let bgColorStatus = "";
                    let bgColorStages = "";

                    switch (status) {
                      case "2":
                        bgColorStatus = "badge-soft-warning";
                        break;

                      case "3":
                        bgColorStatus = "badge-soft-primary";
                        break;

                      case "4":
                        bgColorStatus = "badge-soft-success";
                        break;

                      default:
                        bgColorStatus = "badge-soft-danger";
                        break;
                    }

                    switch (stages) {
                      case "5":
                        bgColorStages = "badge-soft-primary";
                        break;

                      case "6":
                        bgColorStages = "bg-info";
                        break;

                      default:
                        bgColorStages = "badge-soft-dark container-fluid";
                        break;
                    }

                    const financialStages = project?.financial_details.flatMap(
                      (obj) => obj.financial_detail_stages,
                    );

                    const ceilingStages = project?.financial_details
                      .flatMap((obj1) => obj1.ceiling_flow_details)
                      .flatMap((obj2) => obj2.ceiling_flow_detail_stages);

                    const handleCalculateCostRequirement = (status) => {
                      const sum = financialStages
                        .filter((item) => item.status === status.toString())
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) +
                            parseFloat(currentValue.cost_required),
                          0,
                        );
                      return sum;
                    };

                    const handleCalculateCeiling = (status) => {
                      const sum = ceilingStages
                        .filter((item) => item.status === status.toString())
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) +
                            parseFloat(currentValue.amount),
                          0,
                        );
                      return sum;
                    };

                    const costRequirement = handleCalculateCostRequirement(2);
                    const ceiling = handleCalculateCeiling(2);
                    return (
                      <tr
                        key={project.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditItem(project)}
                      >
                        <td className='text-center'>
                          {(page - 1) * meta.per_page + idx + 1}.
                        </td>
                        <td className=''>
                          <p className='m-0 text-muted'>
                            <span
                              className='text-reset'
                              style={{ fontWeight: 700 }}
                            >
                              {project?.program?.name}
                            </span>
                          </p>
                          <p className='m-0'>{project?.name}</p>
                        </td>
                        <td className=''>{project.ministry?.short_form}</td>
                        <td className=''>{project.department?.value}</td>
                        <td className='text-center'>
                          {project.project_category?.code}
                        </td>

                        <td className='text-end'>
                          <span className='me-1'>RM</span>
                          {numeral(costRequirement).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          <span className='me-1'>RM</span>
                          {numeral(ceiling).format("0,0.00")}
                        </td>

                        <td className='text-center'>
                          <Badge
                            bg=''
                            className={`${bgColorStatus} container-fluid`}
                          >
                            {project.application_status_name &&
                              project.application_status_name.toLocaleUpperCase()}
                          </Badge>
                        </td>
                        <td className='text-uppercase'>
                          <div>{project.updated_by?.name}</div>
                          <div>
                            {project.updated_by?.department ? (
                              <>{project.updated_by?.department?.name}</>
                            ) : (
                              <>{project.updated_by?.ministry?.name}</>
                            )}
                          </div>
                        </td>
                        <td className='text-end text-truncate'>
                          <Badge
                            bg=''
                            className={`${bgColorStages} container-fluid`}
                          >
                            {project.application_stages_name &&
                              project.application_stages_name.toLocaleUpperCase()}
                          </Badge>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          )}
        </Table>
      </motion.div>
      {/* Pagination  */}
      <div className='d-flex justify-content-between pt-3'>
        <div>
          <span className=''>
            Halaman{" "}
            <strong>
              {meta.current_page} daripada {meta.last_page}
            </strong>
          </span>
          <span className='ms-3 me-2'>Tunjuk:</span>
          <Form.Select
            className='d-inline-block w-auto'
            value={perPage}
            onChange={(e) => handleChangePerPage(e)}
          >
            {[15, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>
          <span className='ms-3 me-2'>Pergi ke halaman:</span>
          <Form.Control
            className='d-inline-block'
            value={pageValue}
            onChange={(e) => {
              const value = Number(e.target.value);
              setPageValue(e.target.value);
              if (
                isEmpty(e.target.value) ||
                value <= 0 ||
                value > meta.last_page ||
                isNaN(value)
              )
                return false;

              goToPage(value);
            }}
            style={{ width: "75px" }}
          />
        </div>
        <div>
          <Pagination className='float-end remove-outline-item'>
            <Pagination.First
              onClick={() => goToFirstPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={links.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => goToLastPage(meta.last_page)}
              disabled={links.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableProjects;
