import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import {
  thumb,
  thumbInner,
  thumbsContainer,
  img,
} from "../../../../../components/common/thumbStyle";

// Icons
import Icon from "@mdi/react";
import { mdiPaperclipPlus } from "@mdi/js";
import { MdAttachFile, MdCancel } from "react-icons/md";
import PDF from "../../../../../assets/img/icons/pdf.svg";

import {
  createSupportDocument,
  updateSupportDocument,
  storeSupportDocumentFile,
} from "../../../../../redux/slices/projects/supportDocumentsSlice";

const SupportDocumentForm = ({
  showForm,
  closeForm,
  isEdit,
  projectId,
  editItem,
}) => {
  const palette = usePalette();

  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editItem.title || "",
      description: editItem.description || "",
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: editItem.id,
          project_id: projectId,
          title: values.title,
          description: values.description,
        };
        console.log("edit");
        // await dispatch(updateSupportDocument(newValues));
      } else {
        const newValues = {
          project_id: projectId,
          title: values.title,
          description: values.description,
        };
        await dispatch(createSupportDocument(newValues)).then(({ payload }) => {
          const { id } = payload;

          const response = files.forEach((file) => {
            const newValues = {
              id: id,
              file: file,
              collection_type: "support-document",
            };
            dispatch(storeSupportDocumentFile(newValues));
          });
        });
      }
      handleCloseModal();
    },
  });

  const handleCloseModal = () => {
    closeForm();
    setFiles([]);
    formik.resetForm();
    files.forEach((file) => URL.revokeObjectURL(file.blob));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  // Upload handler
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (acceptedFile, idx) => {
      const tempId = uuidv4();
      const file = Object.assign(acceptedFile, {
        blob: URL.createObjectURL(acceptedFile),
        temp_id: tempId,
        mime_type: acceptedFile.type,
        file_name: acceptedFile.name,
      });

      setFiles((files) => [...files, file]);
    });
  }, []);

  const handleRemoveAttachment = (item) => {
    if (isEdit) {
      console.log("isEdit");
    } else {
      const { temp_id } = item;
      setFiles(
        files.filter((prev) => prev.temp_id.toString() !== temp_id.toString()),
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [".pdf"],
    },
    onDrop,
  });

  useEffect(() => {
    if (isEmpty(editItem)) return;

    setFiles(editItem.attachments);
  }, [editItem]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.blob));
  }, []);

  return (
    <Modal
      show={showForm}
      onHide={handleCloseModal}
      centered
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <div>
          <p className='fw-bold fs-4'>Tambah Dokumen Sokongan</p>
        </div>
        <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Tajuk</Form.Label>
            <Form.Control
              name='title'
              type='text'
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Keterangan</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              name='description'
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ minHeight: 70, maxHeight: 100 }}
            />
          </Form.Group>

          <div className=''>
            <Form.Label>Lampiran</Form.Label>
            {isEmpty(files) ? (
              <div className='d-flex justify-content-center border-dashed-m py-3 rounded'>
                <div className='align-self-center'>Lampiran Tidak Tersedia</div>
              </div>
            ) : (
              <ImageList className='mb-0' cols={5}>
                {files.map((item, idx) => {
                  let src = null;

                  switch (item.mime_type) {
                    case "application/pdf":
                      src = PDF;
                      break;

                    default:
                      src = item.blob;
                      break;
                  }
                  return (
                    <ImageListItem
                      key={idx}
                      className='position-relative d-flex align-items-center'
                    >
                      <img
                        src={src}
                        alt='Fail tidak ditemui'
                        loading='lazy'
                        style={{ width: 60, height: "auto" }}
                      />

                      <div
                        className='position-absolute'
                        style={{ right: 10, top: 0 }}
                      >
                        <Tooltip title='Padam' arrow>
                          <IconButton
                            className='p-0'
                            size='small'
                            color='error'
                            onClick={() => handleRemoveAttachment(item)}
                          >
                            <MdCancel size={20} color={palette.danger} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <ImageListItemBar
                        subtitle={item.file_name}
                        position='below'
                        sx={{ width: "6rem" }}
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            )}
          </div>

          <div className='d-flex justify-content-between mt-3'>
            <div className='d-flex justify-content-between gap-2'>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Tooltip
                  title='Muat Naik Dokumen Sokongan'
                  placement='top'
                  arrow
                >
                  <IconButton size='small'>
                    <Icon
                      path={mdiPaperclipPlus}
                      size={1}
                      color={palette.info}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div className='align-self-center d-flex gap-3'>
              <Button variant='light' onClick={handleCloseModal}>
                Batal
              </Button>
              <Button variant='success' type='submit'>
                Simpan
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SupportDocumentForm;
