import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";

const MoreSearchField = ({ showCanvas, closeCanvas, searchMoreField }) => {
  const [departments, setDepartments] = useState([]);
  const [ministryValues, setMinistryValues] = useState([]);
  const [departmentValues, setDepartmentValues] = useState([]);
  const [federalProjects, setFederalProjects] = useState({
    label: "Tidak",
    value: false,
  });

  const { ministries } = useSelector((state) => ({
    ministries: state.utilsReducer.dropdownItems.ministries,
  }));

  const handleClose = () => {
    closeCanvas();
  };

  const handleSearch = () => {
    const newValues = {
      ministries_code: ministryValues.map((item) => Number(item.code)),
      department_ids: departmentValues.map((item) => item.id),
      isFederalProjects: federalProjects,
    };

    searchMoreField(newValues);
  };

  const handleResetField = () => {
    setMinistryValues([]);
    setDepartmentValues([]);
    const newValues = {
      ministries_code: [],
      department_ids: [],
    };

    searchMoreField(newValues);
  };

  const handleFilterMinistry = (value) => {
    const departmentOptions = value.reduce((acc, ministry) => {
      return acc.concat(ministry.departments);
    }, []);

    setDepartments(departmentOptions);
    setMinistryValues(value);
  };

  const handleFilterDepartment = (value) => {
    setDepartmentValues(value);
  };

  const handleSelectFederalProject = (value) => {
    setFederalProjects(value);
  };

  return (
    <Offcanvas show={showCanvas} onHide={handleClose} style={{ width: 310 }}>
      <Offcanvas.Body>
        <Offcanvas.Title className='mb-3'>Carian Tambahan</Offcanvas.Title>
        <Form autoComplete='off' noValidate style={{ fontSize: 12 }}>
          {/* <Form.Group className="mb-3">
            <Form.Label>Daerah</Form.Label>
            <Form.Control
              type="text"
              name="district"
              value={formik.values.district}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Parlimen</Form.Label>
            <Form.Control type="text" name="parliament" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Dewan Undangan Negeri (DUN)</Form.Label>
            <Form.Control type="text" name="state_legislative_assembly" />
          </Form.Group> */}
          <Form.Group className='mb-3'>
            <Form.Label>Kementerian</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              isMulti
              options={ministries || []}
              value={ministryValues}
              onChange={(value) => handleFilterMinistry(value)}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Jabatan/Agensi</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              isMulti
              options={departments || []}
              value={departmentValues}
              onChange={(value) => handleFilterDepartment(value)}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Projek Persekutuan</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              options={[
                {
                  label: "Ya",
                  value: true,
                },
                {
                  label: "Tidak",
                  value: false,
                },
              ]}
              value={federalProjects}
              onChange={(value) => handleSelectFederalProject(value)}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>
          <div className='d-flex flex-column gap-3'>
            <Button
              variant='primary'
              className='flex-fill'
              onClick={handleSearch}
            >
              Cari
            </Button>
            <Button
              variant='light'
              className='flex-fill'
              onClick={handleResetField}
            >
              Set Semula
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MoreSearchField;
