import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import numeral from "numeral";
import { useSelector } from "react-redux";
import usePalette from "../../../../hooks/usePalette";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";

// Components
import CreditScoreForm from "./modals/CreditScoreForm";

const InformationCard = () => {
  const palette = usePalette();

  const [creditScoreForm, setCreditScoreForm] = useState(false);
  const [totalCreditScore, setTotalCreditScore] = useState(0);

  const { total_cost_required, project } = useSelector((state) => ({
    total_cost_required: state.certifyReducer.project.total_cost_required,
    project: state.certifyReducer.project.data,
  }));

  const handleClickCreditScore = () => {
    setCreditScoreForm(true);
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const total = project?.project_criteria.reduce(
        (acc, item) => (acc += parseFloat(item.score)),
        0,
      );

      setTotalCreditScore(total);
    }
  }, [project]);

  return (
    <React.Fragment>
      <CreditScoreForm
        show={creditScoreForm}
        close={() => setCreditScoreForm(false)}
      />

      <Row>
        <Col md={4} className='d-flex'>
          <Card
            className='flex-fill border-2 position-relative'
            style={{ cursor: "pointer" }}
            onClick={() => handleClickCreditScore()}
          >
            <Card.Body>
              <Row>
                <Col sm={7}>
                  <Card.Title className='mb-1'>SKOR KREDIT</Card.Title>
                  <div
                    className='mb-0 text-sm text-muted '
                    style={{ lineHeight: "1.1em" }}
                  >
                    Sila klik untuk mengubah skor kredit projek untuk menentukan
                    peratus keutamaan projek
                  </div>
                </Col>
                <Col
                  sm={5}
                  className='d-flex justify-content-center align-items-center'
                >
                  <Card.Text className='h1 text-truncate'>
                    {`${numeral(totalCreditScore).format("0")} %`}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
            <div className='position-absolute' style={{ top: 3, right: 5 }}>
              <Icon path={mdiPencil} size='20px' color={palette.primary} />
            </div>
          </Card>
        </Col>
        <Col md={4} className='d-flex'>
          <Card className='flex-fill'>
            <Card.Body>
              <Card.Title className='mb-3'>JUMLAH KOS DIPOHON</Card.Title>
              <p className='mb-0 fs-4' style={{ fontWeight: 600 }}>
                {`RM ${numeral(total_cost_required).format("0,0.00")}`}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InformationCard;
