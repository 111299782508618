import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { isEmpty } from "lodash";

// Redux
import { setNotes } from "../../../../../../redux/slices/jppn/jppnModuleSlice";

const FinancialRequirement = () => {
  const dispatch = useDispatch();

  const [additionalCeiling, setAdditionalCeiling] = useState(0);
  const [initialCeiling, setInitialCeiling] = useState(0);
  const [revisedCeiling, setRevisedCeiling] = useState(0);

  const [isDisabled, setIsDisabled] = useState(false);

  const { user, project, draft, application } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.projectReducer.project,
    draft: state.jppnReducer.draft,
    application: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (isEmpty(draft)) return false;

    const additionalCeiling = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.additional_ceiling)),
      0,
    );

    setAdditionalCeiling(additionalCeiling);

    const ceiling = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.ceiling)),
      0,
    );
    const revisedCeiling = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.revised_ceiling)),
      0,
    );

    setInitialCeiling(ceiling);
    setRevisedCeiling(parseFloat(ceiling) + parseFloat(additionalCeiling));
  }, [draft]);

  useEffect(() => {
    if (isEmpty(application)) return;

    const vireInProject = application?.virement_projects?.find(
      (item) => item.virement_type === "VI",
    );

    const applicationMinistryOwner =
      vireInProject?.project?.ministry_id.toString();
    const userMinistry = user?.user_access_to_ministries?.find(
      (item) => item.code === applicationMinistryOwner,
    )?.code;

    if (applicationMinistryOwner === userMinistry) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [application]);

  return (
    <React.Fragment>
      <Form.Label className='fw-bold text-uppercase' style={{ fontSize: 14 }}>
        <span className='me-4'>4.0</span>Keperluan Kewangan
      </Form.Label>
      <div className='table-responsive'>
        <Table size='sm' bordered>
          <thead
            className='custom-table-head text-uppercase'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th style={{ width: "20%" }} className='text-center'>
                Siling Asal
                <br />
                (RM)
              </th>
              <th style={{ width: "20%" }} className='text-center'>
                Penambahan Siling
                <br />
                (RM)
              </th>
              <th style={{ width: "20%" }} className='text-center'>
                Jumlah Baharu
                <br />
                (RM)
              </th>
              <th>Catatan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-end'>
                {numeral(initialCeiling).format("0,0.00")}
              </td>
              <td className='text-end'>
                {numeral(additionalCeiling).format("0,0.00")}
              </td>
              <td className='text-end'>
                {numeral(revisedCeiling).format("0,0.00")}
              </td>
              <td>
                <Form.Group>
                  <Form.Control
                    type='text'
                    as='textarea'
                    style={{ maxHeight: 150 }}
                    value={draft?.ceiling_requirement?.notes}
                    onChange={(e) => dispatch(setNotes(e.target.value))}
                  />
                </Form.Group>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default FinancialRequirement;
