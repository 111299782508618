import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import axios from "axios";

// Icons
import Icon from "@mdi/react";
import { mdiBullhorn, mdiPlus } from "@mdi/js";

// Content
import PageContent from "./PageContent";

// Redux
import { fetchAnnouncements } from "../../redux/slices/admin/announcementsSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(fetchAnnouncements(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title='Pengumuman' />
      <Container fluid className='p-0'>
        <PageContent />
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
