import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";

// Styles
import usePalette from "../../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Redux
import {
  createDepartment,
  updateDepartment,
} from "../../../../../../redux/slices/government-structure/departmentSlice";
import { fetchMinistries } from "../../../../../../redux/slices/government-structure/ministrySlice";

const DepartmentForm = ({
  showForm,
  closeForm,
  isEdit,
  data,
  handleNotification,
  isMinistryPage,
}) => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const { ministryCode } = routeParams;

  const [options, setOptions] = useState([]);

  const { btnLoading, ministries } = useSelector((state) => ({
    ministries: state.utilsReducer.dropdownItems.ministries,
    btnLoading: state.departmentReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      short_form: data.short_form || "",
      code: data.code || "",
      ministry: data.ministry || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Sila isi nama jabatan"),
      short_form: Yup.string().required("Sila isi nama singkatan jabatan"),
      code: Yup.string().required("Sila isi kod jabatan"),
      ministry: Yup.object().shape().required("Sila pilih kementerian"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        try {
          const newValues = {
            id: data.id,
            ministry_code: Number(ministryCode) || Number(values.ministry.code),
            ...values,
          };
          const { ministry, ...newData } = newValues;

          await dispatch(updateDepartment(newData)).then((res) => {
            const data = res.payload;
            handleNotification("edited", data);
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        const newValues = {
          ministry_code: Number(ministryCode) || Number(values.ministry.code),
          ...values,
        };
        const { ministry, ...data } = newValues;

        await dispatch(createDepartment(data)).then((res) => {
          const data = res.payload;
          handleNotification("created", data);
        });
      }
    },
  });

  useEffect(() => {
    if (!btnLoading) {
      handleCloseModal();
    }
  }, [btnLoading]);

  const handleCloseModal = () => {
    closeForm();
    formik.resetForm();
  };

  return (
    <Modal show={showForm} onHide={handleCloseModal} centered>
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Jabatan atau Agensi</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Nama Jabatan atau Agensi</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.name && !formik.errors.name ? true : false
              }
              isInvalid={
                formik.touched.name && formik.errors.name ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Singkatan</Form.Label>
            <Form.Control
              type='text'
              name='short_form'
              value={formik.values.short_form}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.short_form && !formik.errors.short_form
                  ? true
                  : false
              }
              isInvalid={
                formik.touched.short_form && formik.errors.short_form
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.short_form}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kod</Form.Label>
            <Form.Control
              type='text'
              name='code'
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.code && !formik.errors.code ? true : false
              }
              isInvalid={
                formik.touched.code && formik.errors.code ? true : false
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.code}
            </Form.Control.Feedback>
          </Form.Group>

          {!isMinistryPage && (
            <Form.Group className='mb-3'>
              <Form.Label>Dibawah Kementerian</Form.Label>
              <Select
                name='ministry'
                className='react-select-container'
                classNamePrefix='react-select'
                isSearchable
                options={ministries}
                styles={customStylesForm}
                placeholder='Sila pilih kementerian...'
                noOptionsMessage={() => "Tiada Pilihan"}
                value={formik.values.ministry}
                onChange={(value) => formik.setFieldValue("ministry", value)}
              />
              {formik.errors.ministry && (
                <p className='mt-1 custom-feedback-invalid'>
                  {formik.errors.ministry}
                </p>
              )}
            </Form.Group>
          )}

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              variant='light'
              onClick={handleCloseModal}
              disabled={btnLoading}
            >
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DepartmentForm;
