import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchDepartments = createAsyncThunk(
  "departments/fetchDepartments",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(`/departments`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createDepartment = createAsyncThunk(
  "departments/createDepartment",
  async (values) => {
    const response = await axios.post(`/departments`, values);

    const data = await response.data;

    return data;
  },
);

export const getMinistry = createAsyncThunk(
  "departments/getMinistry",
  async (values) => {
    const { ministry_code, params } = values;

    const response = await axios.get(`/get-ministry/${ministry_code}`, {
      params,
    });

    const data = await response.data;

    return data;
  },
);

export const updateDepartment = createAsyncThunk(
  "departments/updateDepartment",
  async (values) => {
    try {
      const { id } = values;

      const response = await axios.put(`/departments/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeDepartment = createAsyncThunk(
  "departments/removeDepartment",
  async (id) => {
    const response = await axios.delete(`/departments/${id}`);

    return id;
  },
);

const initialState = {
  ministry: {},
  departments: [],
  links: {},
  meta: {},
  isLoading: false,
  btnLoading: false,
};

export const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepartments.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchDepartments.fulfilled, (state, action) => {
      const { meta, links, data } = action.payload;
      state.departments = data;
      state.links = links;
      state.meta = meta;
      state.isLoading = false;
    });

    builder.addCase(getMinistry.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getMinistry.fulfilled, (state, action) => {
      const { departments, ...ministry } = action.payload;
      const { data, ...meta } = departments;
      state.ministry = ministry;
      state.departments = data;
      state.links = meta.links;
      state.meta = meta;
      state.isLoading = false;
    });

    builder.addCase(createDepartment.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createDepartment.fulfilled, (state, action) => {
      state.departments.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateDepartment.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.departments = state.departments.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeDepartment.pending, (state, action) => {
      state.btnLoading = true;
    });

    builder.addCase(removeDepartment.fulfilled, (state, action) => {
      state.departments = state.departments.filter(
        (item) => item.id !== action.payload,
      );

      state.btnLoading = false;
    });
  },
});

export default departmentSlice.reducer;
