import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { debounce, isEmpty } from "lodash";
import { Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import axios from "axios";

// Icons
import { MdSearch } from "react-icons/md";

// Components
import Projects from "./components/tables/Projects";
import Filters from "./components/Filters";
import MoreSearchField from "./components/MoreSearchField";
import MinistryOptions from "./components/MinistryOptions";

// Redux
import { getApprovedProjects } from "../../../redux/slices/projects/projectSlice";
import { setSearchMinistry } from "../../../redux/slices/projects/projectsSlice";
import {
  getRmkItems,
  getMinistries,
  getPrograms,
  getMainProjects,
  getOptions,
} from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCanvas, setShowCanvas] = useState(false);
  const [rmkValue, setRmkValue] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const {
    activeRmk,
    searchMinistry,
    searchDepartments,
    searchMainProjects,
    keywords,
  } = useSelector((state) => ({
    activeRmk: state.rmkReducer.activeRmk,
    searchMinistry: state.projectReducer.searchMinistry,
    searchDepartments: state.projectReducer.searchDepartments,
    searchMainProjects: state.projectReducer.searchMainProjects,
    keywords: state.projectReducer.keywords,
  }));

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleHideCanvas = () => {
    setShowCanvas(false);
  };

  const handleSelectMinistry = (e, ministry) => {
    dispatch(setSearchMinistry(ministry));
  };

  const handleChangeRmk = (rmk) => {
    setRmkValue(rmk);
  };

  const handleSearch = () => {
    setIsSearching(true);
  };

  useEffect(() => {
    setRmkValue(activeRmk);
  }, [activeRmk]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {
          keywords: keywords,
          rmk_id: Number(rmkValue?.number),
          ministry_id: searchMinistry,
          department_ids: searchDepartments,
          main_project_ids: searchMainProjects,
        },
      };

      let navigateUrl = "/senarai-projek";

      if (!isEmpty(searchMinistry)) {
        navigateUrl += `?kementerian=${searchMinistry}`;
      }

      if (!isEmpty(searchDepartments)) {
        navigateUrl += `${
          navigateUrl.includes("?") ? "&" : "?"
        }jabatan=${searchDepartments}`;
      }

      if (!isEmpty(searchMainProjects)) {
        navigateUrl += `${
          navigateUrl.includes("?") ? "&" : "?"
        }project-utama=${searchMainProjects}`;
      }

      if (!isEmpty(keywords)) {
        navigateUrl += `${
          navigateUrl.includes("?") ? "&" : "?"
        }carian=${keywords}`;
      }

      navigate(navigateUrl);

      await dispatch(getApprovedProjects(props));
    };

    if (!isEmpty(rmkValue)) {
      initialize();
    }

    setIsSearching(false);

    return () => {
      source.cancel();
    };
  }, [
    dispatch,
    rmkValue,
    searchMinistry,
    searchDepartments,
    searchMainProjects,
    isSearching,
  ]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
      };

      await dispatch(getOptions(props));
    };

    initialize();
    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <MoreSearchField
        showCanvas={showCanvas}
        closeCanvas={handleHideCanvas}
        rmkValue={rmkValue}
        handleChangeRmk={handleChangeRmk}
      />

      <Helmet title='' />
      <Container fluid className='p-0'>
        <Filters
          handleShowCanvas={handleShowCanvas}
          handleSearch={handleSearch}
        />

        <MinistryOptions handleSelectMinistry={handleSelectMinistry} />

        <Card>
          <Card.Body>
            <h5>{`Senarai Projek ${rmkValue?.name} (${rmkValue?.value}): ${rmkValue?.first_year}-${rmkValue?.fifth_year} `}</h5>
            <Projects />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
