import Axios from "axios";

const axiosS3p = Axios.create({
  baseURL: process.env.REACT_APP_ACC_API,
});

axiosS3p.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_ACC_API_TOKEN}`;

export default axiosS3p;
