import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { Tooltip } from "@mui/material";
// Icons
import Icon from "@mdi/react";
import { mdiCog } from "@mdi/js";

// Components
import TableHead from "./TableHead";
import TableBody from "./TableBody";

// Redux
import {
  createCertifyFinancial,
  updateCertifyFinancialItem,
  createRejectedScopeList,
} from "../../../../../../redux/slices/utilsSlice";

const CeilingFlow = ({ showColumn, ceilingFlowRef, stages }) => {
  const dispatch = useDispatch();

  const [colSpan, setColSpan] = useState(0);
  const [matchCell, setMatchCell] = useState({});

  const [viewColumns, setViewColumns] = useState({
    dipohon: {
      key: "siling_dipohon",
      checked: true,
      disabled: false,
    },
    upen: {
      key: "siling_upen",
      checked: true,
      disabled: false,
    },
    jppn: {
      key: "siling_jppn",
      checked: true,
      disabled: false,
    },
  });

  const [showYear, setShowYear] = useState([]);

  const [newValues, setNewValues] = useState({
    id: null,
    amount: 0,
  });

  const { project, ceilingFlowState } = useSelector((state) => ({
    project: state.certifyReducer.project.data,

    ceilingFlowState: state.utilsReducer.certifyFinancial,
  }));

  const groupedByYear = ceilingFlowState.reduce((acc, item) => {
    const { year } = item;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});

  const result = Object.entries(groupedByYear).map(([year, children]) => ({
    year: year,
    children,
  }));

  const handleEditAmount = (item, column) => {
    if (item.id === matchCell) {
      setMatchCell({});
      setNewValues({});
      return false;
    }

    setMatchCell({
      rowNum: item.id,
      columnNum: column,
    });

    if (!isEmpty(item)) {
      const findItem = ceilingFlowState.find((flow) => flow.id === item.id);
      setNewValues({
        id: findItem.id,
        amount: findItem.amount,
      });
    }
  };

  const handleChangeAmount = (value) => {
    setNewValues({
      ...newValues,
      amount: value,
    });
  };

  const handleSaveNewValues = () => {
    dispatch(updateCertifyFinancialItem(newValues));
    handleEditAmount(newValues);
  };

  const handleShowAllYear = (years) => {
    const stringArray = years.map((year) => year.toString());

    if (isEmpty(showYear)) {
      setShowYear(stringArray);
    } else {
      setShowYear([]);
    }
  };

  const handleShow = (year) => {
    const isTrue = showYear.includes(year);
    if (isTrue) {
      setShowYear(
        showYear.filter((item) => item.toString() !== year.toString()),
      );
    } else {
      setShowYear((item) => [...item, year.toString()]);
    }
  };

  const handleViewColumn = (e) => {
    const value = e.target.value;
    const column = viewColumns[value];
    setViewColumns({
      ...viewColumns,
      [value]: {
        ...column,
        checked: !column.checked,
      },
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const result = Object.values(showColumn).filter(
        (value) => value === true,
      ).length;
      setColSpan(result + 4);
    }
    return () => {
      isMounted = false;
    };
  }, [showColumn]);

  useEffect(() => {
    if (!isEmpty(project)) {
      // Excluded rejected components
      const handleRemoveRejectedScope = (status) => {
        if (isEmpty(status)) {
          const updatedArray = project.financial_details.map((financial) => {
            const updatedChildren = financial?.ceiling_flow_details.map(
              (flow) => ({
                ...flow,
                financial_detail_id: financial.id,
                cost_description: financial.cost_description,
              }),
            );
            return { ...financial, ceiling_flow_details: updatedChildren };
          });

          const childrenArray = updatedArray
            .map((financial) => financial.ceiling_flow_details)
            .reduce((acc, flow) => acc.concat(flow), []);

          // Reset Reject Scope List
          dispatch(createRejectedScopeList([]));
          return childrenArray;
        } else {
          const findRejectedList = project.financial_details.flatMap((obj1) =>
            obj1.ceiling_flow_details.filter((obj2) =>
              obj2.ceiling_flow_detail_stages.some(
                (item) => item.status === status.toString(),
              ),
            ),
          );

          const rejectedList = project.financial_details.filter((obj1) =>
            findRejectedList.some(
              (item) => item.financial_detail_id === obj1.id,
            ),
          );

          // Store in redux
          dispatch(createRejectedScopeList(rejectedList));

          const newFinancialList = project.financial_details.filter(
            (obj1) =>
              !findRejectedList.some(
                (item) => item.financial_detail_id === obj1.id,
              ),
          );

          const updatedArray = newFinancialList.map((financial) => {
            const ceilingFlowList = financial.ceiling_flow_details.map(
              (flow) => {
                const removeRejected = flow.ceiling_flow_detail_stages.filter(
                  (item) => item.status !== status.toString(),
                );

                return {
                  ...flow,
                  financial_detail_id: financial.id,
                  cost_description: financial.cost_description,
                };
              },
            );
            return { ...financial, ceiling_flow_details: ceilingFlowList };
          });

          const childrenArray = updatedArray
            .map((financial) => financial.ceiling_flow_details)
            .reduce((acc, flow) => acc.concat(flow), []);

          return childrenArray;
        }
      };

      let newArray = [];

      switch (project.stages) {
        case "4":
          newArray = handleRemoveRejectedScope("");
          break;
        case "5":
          newArray = handleRemoveRejectedScope("7");
          break;
        case "6":
          newArray = handleRemoveRejectedScope("7");
          break;
      }

      const temporaryData = window.localStorage.getItem("TEMP_CERTIFY_DATA");
      if (isNull(temporaryData)) {
        dispatch(createCertifyFinancial(newArray));
      } else {
        const data = JSON.parse(temporaryData);
        dispatch(createCertifyFinancial(data));
      }
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (stages === "4") {
      setViewColumns({
        ...viewColumns,
        ["jppn"]: {
          checked: false,
          disabled: true,
        },
      });
    }

    return () => {};
  }, [stages]);

  return (
    <React.Fragment>
      <div className='mb-3' ref={ceilingFlowRef}>
        <div className='d-flex justify-content-between mb-2'>
          <h5>
            SENARAI SKOP PROJEK MENGIKUT ALIRAN SILING SEPANJANG TEMPOH RMK
          </h5>
          <div className='d-flex align-items-center' style={{ fontSize: 12 }}>
            <Tooltip title='Sembunyi kolum' placement='top' arrow>
              <Icon className='me-2' path={mdiCog} size={0.8} />
            </Tooltip>
            {Object.keys(viewColumns)
              .map((key) => ({ label: key, ...viewColumns[key] }))
              .map((item) => (
                <Form.Group
                  key={item.label}
                  className='me-3 text-uppercase'
                  controlId={viewColumns[item.label].key}
                >
                  <Form.Check
                    label={item.label}
                    value={item.label}
                    onChange={(e) => handleViewColumn(e)}
                    checked={viewColumns[item.label].checked}
                    disabled={viewColumns[item.label].disabled}
                  />
                </Form.Group>
              ))}
          </div>
        </div>

        <div className='table-responsive'>
          <Table bordered size='sm' style={{ fontSize: 11 }}>
            <TableHead
              showColumn={showColumn}
              project={project}
              showAllYear={handleShowAllYear}
              viewColumns={viewColumns}
            />
            <TableBody
              showColumn={showColumn}
              colSpan={colSpan}
              data={result}
              matchCell={matchCell}
              project={project}
              newValues={newValues}
              handleChangeAmount={handleChangeAmount}
              handleEditAmount={handleEditAmount}
              handleSaveNewValues={handleSaveNewValues}
              handleShow={handleShow}
              showYear={showYear}
              viewColumns={viewColumns}
            />
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CeilingFlow;
