import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

// Components
import ActivityLog from "./components/tables/ActivityLog";

const PageContent = ({
  setDateRange,
  startDate,
  endDate,
  handleSearch,
  page,
  perPage,
  goToPage,
  goToFirstPage,
  previousPage,
  nextPage,
  goToLastPage,
  handleChangePerPage,
}) => {
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className='d-flex mb-3 gap-3'>
            <Form.Group className='custom-date-range-picker'>
              <Form.Control
                as={ReactDatePicker}
                placeholderText='Pilih Tarikh'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </Form.Group>
            <div>
              <Button variant='secondary' onClick={handleSearch}>
                Cari
              </Button>
            </div>
          </div>
          <ActivityLog
            perPage={perPage}
            page={page}
            goToPage={goToPage}
            goToFirstPage={goToFirstPage}
            previousPage={previousPage}
            nextPage={nextPage}
            goToLastPage={goToLastPage}
            handleChangePerPage={handleChangePerPage}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default PageContent;
