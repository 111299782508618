import numeral from "numeral";
import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const TotalCostApproved = () => {
  const totalCost = useSelector(
    (state) => state.microProjectReducer.dashboards.cards?.total_cost_approved,
  );
  return (
    <Card className='flex-fill w-100'>
      <Card.Header>
        <h4 className='text-uppercase'>Jumlah Kos Diluluskan</h4>
      </Card.Header>
      <Card.Body className='text-center'>
        <h1 style={{ fontWeight: 700 }}>
          {`RM ${numeral(totalCost || 0).format("0,0.00")}`}
        </h1>
      </Card.Body>
    </Card>
  );
};

export default TotalCostApproved;
