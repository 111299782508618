import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Col,
  Form,
  Pagination,
  Row,
  Table,
  Badge,
  Card,
} from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Tooltip, IconButton, Skeleton } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import format from "date-fns/format";
import { EightColumns } from "../../../../../components/common/tableLoading";

// Icons
import { MdDelete, MdEdit } from "react-icons/md";

// Components
import Filters from "../Filters";
import MoreSearchField from "../MoreSearchField";

const Projects = ({
  filterItem,
  projects,
  handleRemoveItem,
  page,
  perPage,
  handlePerPage,
  goToPage,
  goToFirstPage,
  goToLastPage,
  nextPage,
  previousPage,
  handleChangePerPage,
}) => {
  const navigate = useNavigate();

  const [pageValue, setPageValue] = useState("");

  const { user, meta, links, isLoading } = useSelector((state) => ({
    user: state.userReducer.user,

    isLoading: state.projectReducer.isLoading,
    meta: state.projectReducer.meta,
    links: state.projectReducer.links,
  }));

  const isSuperadmin = user.roles.includes("superadmin");
  const isAdmin = user.roles.includes("admin");

  const handleViewProject = (project) => {
    const { id, project_category, program_name, uuid } = project;
    navigate(`/permohonan/butiran-projek/${uuid}`);
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className='table-responsive'>
            <Table hover size='sm' striped>
              <thead
                className='text-uppercase custom-table-head'
                style={{ fontSize: 11 }}
              >
                <tr>
                  <th className='text-center' style={{ width: "3%" }}>
                    Bil.
                  </th>
                  {/* <th>Kod Projek</th> */}
                  <th>Projek Utama dan Sub Projek</th>
                  <th>Kementerian</th>
                  <th>Jabatan</th>
                  <th className='text-center'>Kategori Projek</th>
                  <th className='text-center'>Status Permohonan</th>
                  <th className='text-center'>Peringkat</th>
                  <th className='text-center'>Dikemas kini oleh</th>
                  {/* <th className="text-center">Tindakan</th> */}
                </tr>
              </thead>

              {isLoading ? (
                <EightColumns />
              ) : (
                <tbody style={{ fontSize: 12 }}>
                  {projects.length === 0 ? (
                    <tr>
                      <td colSpan={9} className='text-center'>
                        Maklumat tidak tersedia
                      </td>
                    </tr>
                  ) : (
                    <>
                      {projects.map((project, idx) => {
                        const status =
                          project.application_status_name.toLocaleUpperCase();

                        let type = "";
                        let isDisabled = false;

                        switch (status) {
                          case "DRAF":
                            type = "dark";
                            break;

                          case "DIHANTAR":
                            type = "warning";
                            break;

                          case "DISEMAK":
                            type = "warning";
                            break;

                          case "DIPERAKU":
                            type = "primary";
                            isDisabled = true;
                            break;

                          case "LULUS":
                            type = "success";
                            isDisabled = true;
                            break;

                          default:
                            type = "danger";
                            isDisabled = true;
                            break;
                        }

                        //Generated code
                        const ministryCode =
                          project.ministry?.code.toString().padStart(3, "0") ||
                          "000";
                        const departmentCode =
                          project.department?.code
                            .toString()
                            .padStart(3, "0") || "000";
                        const programCode =
                          project.program?.code.toString().padStart(3, "0") ||
                          "000";
                        const projectCode =
                          project.id.toString().padStart(4, "0") || "000";

                        const codeProject = `D${ministryCode}-${departmentCode}-${programCode}-${projectCode}`;

                        return (
                          <tr
                            key={project.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleViewProject(project)}
                          >
                            <td className='text-center'>
                              {(page - 1) * meta.per_page + idx + 1}.
                            </td>
                            <td style={{ width: "43%" }}>
                              <div
                                className='text-uppercase'
                                style={{ fontWeight: 700 }}
                              >
                                {project.program?.name}
                              </div>
                              <div className='text-muted card-subtitle'>
                                {project.name}
                              </div>
                            </td>
                            <td className='text-center'>
                              {project?.ministry?.short_form}
                            </td>
                            <td>{project?.department?.value}</td>
                            <td className='text-center'>
                              {project.project_category?.code}
                            </td>
                            <td className='text-center px-2'>
                              <Badge
                                className={`badge-soft-${type} container-fluid`}
                                bg=''
                              >
                                {status}
                              </Badge>
                            </td>
                            <td className='text-center'>
                              <Badge
                                className={`badge-soft-dark container-fluid`}
                                bg=''
                              >
                                {project.application_stages_name.toLocaleUpperCase() ||
                                  "PEMOHON"}
                              </Badge>
                            </td>
                            <td className='text-center'>
                              <p className='mb-0' style={{ fontSize: 11 }}>
                                {project?.updated_by?.name
                                  ? project?.updated_by?.name
                                  : "Pengguna telah dipadam"}
                              </p>
                              <p className='mb-0' style={{ fontSize: 11 }}>
                                {!isEmpty(project.updated_at) && (
                                  <>
                                    {`${format(
                                      new Date(project?.updated_at),
                                      "dd-MM-yyyy",
                                    )}`}
                                  </>
                                )}
                              </p>
                              <p className='mb-0' style={{ fontSize: 11 }}>
                                {!isEmpty(project.updated_at) && (
                                  <>
                                    {`${format(
                                      new Date(project?.updated_at),
                                      "hh:mm a",
                                    )}`}
                                  </>
                                )}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              )}
            </Table>
          </div>
          {/* Pagination  */}
          <div className='d-flex justify-content-between pt-3'>
            <div>
              <span className=''>
                Halaman{" "}
                <strong>
                  {meta.current_page} daripada {meta.last_page}
                </strong>
              </span>
              <span className='ms-3 me-2'>Tunjuk:</span>
              <Form.Select
                className='d-inline-block w-auto'
                value={meta.per_page}
                onChange={(e) => handleChangePerPage(e)}
              >
                {[15, 30, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
              <span className='ms-3 me-2'>Pergi ke halaman:</span>
              <Form.Control
                className='d-inline-block'
                value={pageValue}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setPageValue(e.target.value);
                  if (
                    isEmpty(e.target.value) ||
                    value <= 0 ||
                    value > meta.last_page ||
                    isNaN(value)
                  )
                    return false;

                  goToPage(value);
                }}
                style={{ width: "75px" }}
              />
            </div>
            <div>
              <Pagination className='float-end remove-outline-item'>
                <Pagination.First
                  onClick={() => goToFirstPage()}
                  disabled={links.prev === null ? true : false}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={links.prev === null ? true : false}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={links.next === null ? true : false}
                />
                <Pagination.Last
                  onClick={() => goToLastPage(meta.last_page)}
                  disabled={links.next === null ? true : false}
                />
              </Pagination>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Projects;
