import React, { useEffect, useState } from "react";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

//Styles
import { customStyles } from "../../../../components/common/customStyleSelect";

// Icons
import { MdFilterList, MdSearch } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";
import Icon from "@mdi/react";
import { mdiFilterVariant, mdiRestore } from "@mdi/js"; // Icons

// Redux
import {
  setKeywords,
  setSelectDraft,
} from "../../../../redux/slices/reports/projectReportsSlice";

const Filters = ({
  handleShowCanvas,
  yearValue,
  setYearValue,
  handleReset,
}) => {
  const dispatch = useDispatch();

  const { activeRmk, keywords, isLoading, selectDraft } = useSelector(
    (state) => ({
      activeRmk: state.rmkReducer.activeRmk,
      keywords: state.reportReducer.projects.keywords,
      selectDraft: state.reportReducer.selectDraft,
      isLoading: state.reportReducer.isLoading.projects,
    }),
  );

  const [yearOptions, setYearOptions] = useState([]);

  const handleSearch = (text) => {
    dispatch(setKeywords(text));
  };

  const handleViewDraft = () => {
    if (selectDraft === false) {
      dispatch(setSelectDraft(null));
    } else {
      dispatch(setSelectDraft(false));
    }
  };

  useEffect(() => {
    if (!isEmpty(activeRmk)) {
      const { first_year, second_year, third_year, fourth_year, fifth_year } =
        activeRmk;

      const options = [
        first_year,
        second_year,
        third_year,
        fourth_year,
        fifth_year,
      ];

      setYearOptions(options);
      const yearNow = new Date().getFullYear();
      setYearValue(String(yearNow));
    }
  }, [activeRmk]);

  return (
    <div className='d-flex align-items-center gap-3 mb-3'>
      <div className='remove-outline-item'>
        <Tooltip title='Lebih Pilihan' placement='top' arrow>
          <Button variant='secondary' onClick={handleShowCanvas}>
            <Icon path={mdiFilterVariant} size={0.8} />
          </Button>
        </Tooltip>
      </div>

      <div className='search-box-custom'>
        <div className='position-relative'>
          <label htmlFor='search-bar-0' className='search-label-custom'>
            <input
              type='text'
              className='form-control'
              placeholder='Cari nama projek...'
              value={keywords}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 250 }}
              disabled={isLoading}
            />
          </label>
          <MdSearch className='search-icon-2' size={18} />
        </div>
      </div>

      <Form.Group>
        <Form.Select
          value={yearValue}
          onChange={(e) => {
            const year = String(e.target.value);
            setYearValue(year);
          }}
        >
          <option value=''>5 Tahun</option>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {`Tahun ${year}`}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group>
        <Button
          variant={selectDraft === false ? "secondary" : "outline-secondary"}
          onClick={handleViewDraft}
        >
          Draf
        </Button>
      </Form.Group>

      <div className='align-self-center'>
        <div onClick={handleReset} style={{ cursor: "pointer" }}>
          <Icon path={mdiRestore} size={1} />
        </div>
      </div>
    </div>
  );
};

export default Filters;
