import { createSlice } from "@reduxjs/toolkit";

import {
  getFinancialReport,
  getOverallExpenditure,
  getFinancialReportByMinistries,
  getFinancialReportByDepartments,
  getFinancialReportByPrograms,
  getFinancialReportByMainProjects,
  getProjectsProgressStatus,
  getProjects,
} from "./overallReportSlice";
import {
  getCombineReportProjectsProgressStatus,
  getCombineReportProgressStatusByMinistries,
  getCombineReportProgressStatusByDepartments,
  getCombineReportProgressStatusByPrograms,
  getCombineReportProgressStatusByMainProjects,
} from "./combineReportSlice";

const initialState = {
  overall: {
    cards: {
      initialAllocation: 0,
      revisedAllocation: 0,
      latestExpenditure: 0,
    },
    charts: {
      overallExpenditure: [],
      financialReportByMinistries: [],
      financialReportByDepartments: [],
      financialReportByPrograms: [],
      financialReportByMainProjects: [],
      projectsProgressStatus: [],
      isLoading: false,
    },
    projects: {
      data: [],
      isLoading: false,
      page: 1,
      perPage: 10,
      keywords: "",
    },
  },
  combine: {
    projectsProgressStatus: [],
    progressStatusByMinistries: [],
    progressStatusByDepartments: [],
    progressStatusByPrograms: [],
    progressStatusByMainProjects: [],
  },
  selectMinistry: null,
  selectDepartment: null,
  selectProgram: null,
  selectYear: {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  navFilter: "ministry",
  isLoading: false,
  searchProgressStatus: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboardReducer: (state) => initialState,
    setKeywordsByUser: (state, action) => {
      state.user.keywords = action.payload;
    },
    setSearchStatusUser: (state, action) => {
      state.user.status = action.payload;
    },
    setPage: (state, action) => {
      state.overall.projects.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.overall.projects.perPage = action.payload;
    },
    setKeywords: (state, action) => {
      state.overall.projects.keywords = action.payload;
    },
    setSelectMinistry: (state, action) => {
      state.selectMinistry = action.payload;
    },
    setSelectDepartment: (state, action) => {
      state.selectDepartment = action.payload;
    },
    setSelectProgram: (state, action) => {
      state.selectProgram = action.payload;
    },
    setSelectYear: (state, action) => {
      state.selectYear = action.payload;
    },
    setNavFilter: (state, action) => {
      state.navFilter = action.payload;
    },
    setResetState: (state) => {
      state.selectMinistry = null;
      state.selectDepartment = null;
      state.navFilter = "ministry";
      state.selectYear = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchProgressStatus: (state, action) => {
      state.searchProgressStatus = action.payload;
      state.overall.projects.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOverallExpenditure.pending, (state) => {
      state.overall.charts.isLoading = true;
    });
    builder.addCase(getFinancialReportByMinistries.pending, (state) => {
      state.overall.charts.isLoading = true;
    });
    builder.addCase(getFinancialReportByDepartments.pending, (state) => {
      state.overall.charts.isLoading = true;
    });

    builder.addCase(getFinancialReport.fulfilled, (state, action) => {
      const { initialAllocation, revisedAllocation, latestExpenditure } =
        action.payload;

      state.overall.cards.initialAllocation = initialAllocation;
      state.overall.cards.revisedAllocation = revisedAllocation;
      state.overall.cards.latestExpenditure = latestExpenditure;
    });

    builder.addCase(getOverallExpenditure.fulfilled, (state, action) => {
      state.overall.charts.overallExpenditure = action.payload;
      state.overall.charts.isLoading = false;
    });

    builder.addCase(
      getFinancialReportByMinistries.fulfilled,
      (state, action) => {
        state.overall.charts.financialReportByMinistries = action.payload;
        state.overall.charts.isLoading = false;
      },
    );
    builder.addCase(
      getFinancialReportByDepartments.fulfilled,
      (state, action) => {
        state.overall.charts.financialReportByDepartments = action.payload;
        state.overall.charts.isLoading = false;
      },
    );
    builder.addCase(getFinancialReportByPrograms.fulfilled, (state, action) => {
      state.overall.charts.financialReportByPrograms = action.payload;
      state.overall.charts.isLoading = false;
    });
    builder.addCase(
      getFinancialReportByMainProjects.fulfilled,
      (state, action) => {
        state.overall.charts.financialReportByMainProjects = action.payload;
        state.overall.charts.isLoading = false;
      },
    );

    builder.addCase(getProjectsProgressStatus.fulfilled, (state, action) => {
      state.overall.charts.projectsProgressStatus = action.payload;
    });

    builder.addCase(getProjects.pending, (state) => {
      state.overall.projects.isLoading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.overall.projects.data = action.payload;
      state.overall.projects.isLoading = false;
    });

    builder.addCase(
      getCombineReportProjectsProgressStatus.fulfilled,
      (state, action) => {
        state.combine.projectsProgressStatus = action.payload;
      },
    );

    builder.addCase(
      getCombineReportProgressStatusByMinistries.fulfilled,
      (state, action) => {
        state.combine.progressStatusByMinistries = action.payload;
      },
    );
    builder.addCase(
      getCombineReportProgressStatusByDepartments.fulfilled,
      (state, action) => {
        state.combine.progressStatusByDepartments = action.payload;
      },
    );
    builder.addCase(
      getCombineReportProgressStatusByPrograms.fulfilled,
      (state, action) => {
        state.combine.progressStatusByPrograms = action.payload;
      },
    );
    builder.addCase(
      getCombineReportProgressStatusByMainProjects.fulfilled,
      (state, action) => {
        state.combine.progressStatusByMainProjects = action.payload;
      },
    );
  },
});

export const {
  resetDashboardReducer,
  setKeywordsByUser,
  setSearchStatusUser,
  setPage,
  setPerPage,
  setKeywords,
  setSelectMinistry,
  setSelectDepartment,
  setSelectProgram,
  setSelectYear,
  setNavFilter,
  setResetState,
  setIsLoading,
  setSearchProgressStatus,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
