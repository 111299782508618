import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import axios from "../../../utils/axios";

export const fetchMinistries = createAsyncThunk(
  "dropdown/fetchMinistries",
  async () => {
    try {
      const response = await axios.get(`/listing/ministries`);
      const data = await response.data;

      return data;
    } catch (error) {}
  },
);

const initialState = {
  ministries: [],
  departments: [],
};

export const dropdownItemsSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMinistries.fulfilled, (state, action) => {
      state.ministries = action.payload;
    });
  },
});

export default dropdownItemsSlice.reducer;
