import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import axios from "axios";
import NotyfContext from "../../../contexts/NotyfContext";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import UpenSections from "./components/tables/UpenSections";
import UpenSectionForm from "./components/modals/UpenSectionForm";
import ModalDelete from "../../../components/common/ModalDelete";
import LoadingState from "../../../components/common/LoadingState";
import Page500 from "../../auth/Page500";

// Redux
import {
  fetchUpenSections,
  removeUpenSection,
} from "../../../redux/slices/maintenance/upenSectionsSlice";
import {
  getMinistries,
  getUpenOfficers,
} from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);
  const [keywords, setKeywords] = useState("");

  const { isLoading, btnLoading, user } = useSelector((state) => ({
    isLoading: state.departmentReducer.isLoading,
    btnLoading: state.departmentReducer.btnLoading,
    user: state.userReducer.user,
  }));

  const handleResetState = () => {
    setIsEdit(false);
    setItem({});
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    handleResetState();
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    handleResetState();
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeUpenSection(data.id));
    handleNotification("error", data);
    handleCloseRemove();
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleSearch = (text) => {
    setKeywords(text);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(fetchUpenSections(props));
    dispatch(getMinistries(props));
    dispatch(getUpenOfficers(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <UpenSectionForm
        showForm={openForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        data={item}
        handleNotification={handleNotification}
      />

      <Helmet title='Senarai Seksyen UPEN' />
      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Penyenggelaraan'
          breadcrumbItem='Senarai Seksyen UPEN'
        />

        {isLoading ? (
          <LoadingState />
        ) : (
          <Card className='mt-3'>
            <Card.Header>
              <div className='card-actions float-end'>
                <Button onClick={handleOpenForm}>
                  <MdAdd /> Seksyen
                </Button>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <div className='search-box-custom'>
                  <div className='position-relative'>
                    <label
                      htmlFor='search-bar-0'
                      className='search-label-custom'
                    >
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Carian...'
                        value={keywords}
                        onChange={(e) => handleSearch(e.target.value)}
                        style={{ width: 250 }}
                      />
                    </label>
                    <MdSearch className='search-icon-2' size={18} />
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <UpenSections
                keywords={keywords}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
              />
            </Card.Body>
          </Card>
        )}
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
