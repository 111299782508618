import React from "react";
import { Badge, Card } from "react-bootstrap";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const StartDate = () => {
  const start_date = useSelector(
    (state) => state.reportReducer.project?.physical_details?.actual_start_date,
  );
  return (
    <React.Fragment>
      <Card className='flex-fill w-100'>
        <Card.Header className='pb-0'>
          <h5 className='text-uppercase'>Tarikh Sebenar Mula</h5>
        </Card.Header>
        <Card.Body className='d-flex pt-0 justify-content-center align-items-center'>
          <div className=''>
            <p
              className='text-center text-uppercase mb-0'
              style={{ fontSize: 17, fontWeight: 700 }}
            >
              {start_date ? format(new Date(start_date), "dd/MM/yyyy") : "-"}
            </p>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default StartDate;
