import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  InputGroup,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { debounce, isEmpty } from "lodash";

// Styles
import usePalette from "../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../contexts/NotyfContext";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Redux
import {
  createRmk,
  updateRmk,
} from "../../../../../redux/slices/rmk-information/rmkSlice";

const RmkForm = ({
  showForm,
  closeForm,
  btnLoading,
  isEdit,
  data,
  handleNotification,
}) => {
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  const [firstYearValue, setFirstYearValue] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      number: data.number || "",
      short_form: data.short_form || "",
      first_year: data.first_year || "",
      second_year: data.second_year || "",
      third_year: data.third_year || "",
      fourth_year: data.fourth_year || "",
      fifth_year: data.fifth_year || "",
      active: data.active || 0,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Sila isi"),
      short_form: Yup.string().required("Sila isi"),
      first_year: Yup.string().required("Sila isi tahun pertama"),
      second_year: Yup.string().required("Sila isi tahun kedua"),
      third_year: Yup.string().required("Sila isi tahun ketiga"),
      fourth_year: Yup.string().required("Sila isi tahun keempat"),
      fifth_year: Yup.string().required("Sila isi tahun kelima"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const newValues = {
          ...values,
          id: data.id,
        };
        dispatch(updateRmk(newValues)).then((res) => {
          const data = res.payload;
          handleNotification("edited", data);
        });
      } else {
        dispatch(createRmk(values)).then((res) => {
          const data = res.payload;
          handleNotification("success", data);
        });
      }
    },
  });

  const handleCloseModal = () => {
    closeForm();
    setFirstYearValue("");
    formik.resetForm();
  };

  const handleRmkName = (e) => {
    const value = e.target.value;
    formik.setFieldValue("name", value);
    // shortFormGenerator(modifiedSentence);
  };

  const handleRmkInput = (e) => {
    const value = e.target.value;

    const num = value.split("-")[1];
    // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    // .join(" ");

    formik.setFieldValue("number", num);
    formik.setFieldValue("short_form", value);
  };

  // const shortFormGenerator = debounce((num) => {
  //   let shortFormValue = "";

  //   if (!isEmpty(num)) {
  //     shortFormValue = "RMK" + Number(num);
  //   }

  //   formik.setFieldValue("short_form", shortFormValue);
  //   formik.setFieldValue("name", `Rancangan Malaysia Ke ${Number(num)}`);
  // }, 500);

  const handleFirstYear = (e) => {
    setFirstYearValue(e.target.value);
    autoFilYears(e);
  };

  const autoFilYears = debounce((e) => {
    const firstYear = parseInt(e.target.value);
    formik.setFieldValue("first_year", firstYear);
    formik.setFieldValue("second_year", firstYear + parseInt(1));
    formik.setFieldValue("third_year", firstYear + parseInt(2));
    formik.setFieldValue("fourth_year", firstYear + parseInt(3));
    formik.setFieldValue("fifth_year", firstYear + parseInt(4));
  }, 500);

  useEffect(() => {
    if (!isEmpty(data)) {
      setFirstYearValue(data.first_year);
    }
  }, [data]);

  useEffect(() => {
    if (!btnLoading) {
      handleCloseModal();
    }
  }, [btnLoading]);

  return (
    <Modal show={showForm} onHide={handleCloseModal} centered>
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah RMK</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md={8}>
              <Form.Group className='mb-3'>
                <Form.Label>Tajuk</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  placeholder='cth: Rancangan Malaysia Kedua Belas'
                  value={formik.values.name}
                  onChange={(e) => handleRmkName(e)}
                  onBlur={formik.handleBlur}
                  isValid={
                    formik.touched.name && !formik.errors.name ? true : false
                  }
                  isInvalid={
                    formik.touched.name && formik.errors.name ? true : false
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>RMKe</Form.Label>
                <Form.Control
                  type='text'
                  name='short_form'
                  placeholder='cth: RMKe-12'
                  value={formik.values.short_form}
                  onChange={(e) => handleRmkInput(e)}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.short_form && formik.errors.short_form
                      ? true
                      : false
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className='mb-3'>
            <Form.Label>Tahun Pertama</Form.Label>
            <Form.Control
              type='number'
              name='first_year'
              value={firstYearValue}
              onChange={(e) => handleFirstYear(e)}
              onBlur={formik.handleBlur}
              placeholder='cth. 2021'
              isInvalid={
                formik.touched.first_year && formik.errors.first_year
                  ? true
                  : false
              }
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Tahun Kedua</Form.Label>
            <Form.Control
              type='number'
              name='second_year'
              value={formik.values.second_year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.second_year && formik.errors.second_year
                  ? true
                  : false
              }
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Tahun Ketiga</Form.Label>
            <Form.Control
              type='number'
              name='third_year'
              value={formik.values.third_year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.third_year && formik.errors.third_year
                  ? true
                  : false
              }
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Tahun Keempat</Form.Label>
            <Form.Control
              type='number'
              name='fourth_year'
              value={formik.values.fourth_year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.fourth_year && formik.errors.fourth_year
                  ? true
                  : false
              }
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Tahun Kelima</Form.Label>
            <Form.Control
              type='number'
              name='fifth_year'
              value={formik.values.fifth_year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.fifth_year && formik.errors.fifth_year
                  ? true
                  : false
              }
            />
          </Form.Group>
          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={handleCloseModal}>
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RmkForm;
