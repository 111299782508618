import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ConfirmSend = ({ show, hide, confirm, formik }) => {
  const [message, setMessage] = useState("");

  const { btnLoading, project } = useSelector((state) => ({
    btnLoading: state.microProjectReducer.btnLoading,
    project: state.microProjectReducer.microProject,
  }));

  useEffect(() => {
    if (isEmpty(project)) return;

    if (project?.application_status === 1) {
      setMessage(
        "Sila pastikan maklumat yang diberikan adalah benar, sila semak jika terdapat maklumat yang tidak tepat sebelum menghantar.",
      );
    }

    if (project?.application_status === 2) {
      setMessage(
        "Permohonan akan dihantar ke Kementerian untuk proses seterusnya.",
      );
    }

    if (project?.application_status === 3) {
      setMessage(
        "Jika anda telah menyemak permohonan, sila hantar ke peringkat seterusnya untuk kelulusan.",
      );
    }

    if (project?.application_status === 4) {
      setMessage("Adakah anda pasti untuk meluluskan projek ini?");
    }
  }, [project]);

  return (
    <Modal show={show} onHide={hide} size='sm' centered>
      <Modal.Body>
        <div className='d-flex justify-content-center mb-3'>
          Perubahan akan di buat!
        </div>
        <p>{message}</p>
        {!isEmpty(formik.touched) && !isEmpty(formik.errors) && (
          <div>
            <p className='text-error'>
              Ruangan kosong! Sila semak semula permohonan.
            </p>
          </div>
        )}
        <div className='d-flex justify-content-center gap-3'>
          <Button variant='light' onClick={hide} disabled={btnLoading}>
            Batal
          </Button>
          <Button variant='success' onClick={confirm} disabled={btnLoading}>
            {project?.action_button?.action_2?.text}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmSend;
