import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { AuthProvider } from "./contexts/AuthContext";

import ScrollToTop from "./components/ScrollToTop";

import "react-datepicker/dist/react-datepicker.css";

import MaintenancePage from "./pages/auth/UnderMaintenancePage";
import numeral from "numeral";

import esriConfig from "@arcgis/core/config";
import { LicenseManager } from "@ag-grid-enterprise/core";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);

// load a locale
numeral.register("locale", "ms", {
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  abbreviations: {
    thousand: "k",
    million: "j",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "MYR",
  },
});

// switch between locales
numeral.locale("ms");

// esriConfig.apiKey = process.env.REACT_APP_ARCGIS_API_KEY;
esriConfig.portalUrl = "https://smepps-geo.sabah.gov.my/arcgis/home";

const App = () => {
  const content = useRoutes(routes);

  /* uncomment to enable maintenance mode */
  // return (
  //   <HelmetProvider>
  //     <Helmet
  //       titleTemplate='%s | SMEPPS'
  //       defaultTitle='Sistem Maklumat Ekonomi dan Perancangan Pembangunan Sabah'
  //     />
  //     <Provider store={store}>
  //       <ThemeProvider>
  //         <MaintenancePage />
  //       </ThemeProvider>
  //     </Provider>
  //   </HelmetProvider>
  // );

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate='%s | SMEPPS'
        defaultTitle='Sistem Maklumat Ekonomi dan Perancangan Pembangunan Sabah'
      />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <ChartJsDefaults />
              <ScrollToTop />
              <AuthProvider>{content}</AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
