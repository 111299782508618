import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { isEmpty } from "lodash";

const FinancialRequirement = () => {
  const dispatch = useDispatch();

  const [initialCeiling, setInitialCeiling] = useState(0);
  const [additionalCeiling, setAdditionalCeiling] = useState(0);
  const [revisedCeiling, setRevisedCeiling] = useState(0);

  const { application } = useSelector((state) => ({
    application: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (isEmpty(application.virement_projects)) return false;

    const vireInProject = application?.virement_projects?.find(
      (item) => item.virement_type === "VI",
    );

    const ceilingAmount = vireInProject?.project?.financial_details?.reduce(
      (acc, item) => (acc += parseFloat(item?.ceiling)),
      0,
    );

    const additionalAmount = vireInProject?.virement_details?.reduce(
      (acc, item) => (acc += parseFloat(item?.virement_amount)),
      0,
    );

    setInitialCeiling(ceilingAmount);
    setAdditionalCeiling(additionalAmount);
  }, [application?.virement_projects]);

  return (
    <React.Fragment>
      <Form.Label className='fw-bold text-uppercase' style={{ fontSize: 14 }}>
        <span className='me-4'>4.0</span>Keperluan Kewangan
      </Form.Label>
      <div className='table-responsive'>
        <Table size='sm' bordered>
          <thead
            className='custom-table-head text-uppercase'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th style={{ width: "20%" }} className='text-center'>
                Siling Asal
                <br />
                (RM)
              </th>
              <th style={{ width: "20%" }} className='text-center'>
                Penambahan Siling
                <br />
                (RM)
              </th>
              <th style={{ width: "20%" }} className='text-center'>
                Jumlah Baharu
                <br />
                (RM)
              </th>
              {/* <th>Catatan</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-end'>
                {numeral(initialCeiling).format("0,0.00")}
              </td>
              <td className='text-end'>
                {numeral(additionalCeiling).format("0,0.00")}
              </td>
              <td className='text-end'>
                {numeral(initialCeiling + additionalCeiling).format("0,0.00")}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default FinancialRequirement;
