import React, { useEffect, useState } from "react";
import { Table, Tab } from "react-bootstrap";
import numeral from "numeral";
import usePalette from "../../../../../hooks/usePalette";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../ConfirmDelete";

// icons
import Icon from "@mdi/react";
import {
  mdiAlertCircle,
  mdiCheckCircle,
  mdiPencil,
  mdiTrashCan,
} from "@mdi/js";

// Pagination
import TablePagination from "./TablePagination";

// redux
import { deleteLocationChangesApplication } from "../../../../../redux/slices/jppn/jppnLocationChangesSlice";

const LocationChanges = ({ eventKey }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const palette = usePalette();

  const [data, setData] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const { locationChangeApplications } = useSelector((state) => ({
    locationChangeApplications: state.jppnReducer.locationChangeApplications,
  }));

  const handleViewApplication = (item) => {
    const { id } = item;

    navigate(`/permohonan/jppn/ubah-lokasi/${id}/kemas-kini`);
  };

  const handleDeleteApplication = async () => {
    await dispatch(deleteLocationChangesApplication(idToDelete));
    setIdToDelete(null);
    setShowConfirmDelete(false);
  };

  return (
    <React.Fragment>
      <ConfirmDelete
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        destroy={handleDeleteApplication}
      />
      <Tab.Pane eventKey={eventKey}>
        <h4 className='mb-3'>Senarai Permohonan Ubah Lokasi</h4>
        <div className='table-responsive'>
          <Table size='sm' bordered>
            <thead className='custom-table-head text-uppercase'>
              <tr>
                <th className='text-center'>Bil.</th>
                <th className='text-center'>No. Rujukan</th>
                <th style={{ width: 350 }}>Nama Projek</th>
                <th>Dikomen Oleh Kementerian</th>
                <th>Dikomen Oleh UPEN</th>
                <th className='text-center'>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(locationChangeApplications) && (
                <tr>
                  <td colSpan={6} className='text-center'>
                    Tiada maklumat tersedia
                  </td>
                </tr>
              )}
              {!isEmpty(locationChangeApplications) && (
                <>
                  {locationChangeApplications?.map((item, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <tr>
                          <td className='text-center'>{idx + 1}</td>
                          <td className='text-center'>{item?.reference_no}</td>
                          <td>{item.project?.name || "-"}</td>
                          <td className='text-center'>
                            {!isEmpty(item.ministry_comments) && (
                              <Icon
                                path={mdiCheckCircle}
                                size='18px'
                                color={palette.success}
                              />
                            )}
                            {isEmpty(item.ministry_comments) && (
                              <Icon
                                path={mdiAlertCircle}
                                size='18px'
                                color={palette.warning}
                              />
                            )}
                          </td>
                          <td className='text-center'>
                            {!isEmpty(item.upen_comments) && (
                              <Icon
                                path={mdiCheckCircle}
                                size='18px'
                                color={palette.success}
                              />
                            )}
                            {isEmpty(item.upen_comments) && (
                              <Icon
                                path={mdiAlertCircle}
                                size='18px'
                                color={palette.warning}
                              />
                            )}
                          </td>
                          <td>
                            <div className='d-flex justify-content-center gap-3'>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleViewApplication(item)}
                              >
                                <Icon
                                  path={mdiPencil}
                                  color={palette.primary}
                                  size='20px'
                                />
                              </div>
                              {isNull(item?.is_approved) && (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setShowConfirmDelete(true);
                                    setIdToDelete(item.id);
                                  }}
                                >
                                  <Icon
                                    path={mdiTrashCan}
                                    color={palette.danger}
                                    size='20px'
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
          <TablePagination />
        </div>
      </Tab.Pane>
    </React.Fragment>
  );
};

export default LocationChanges;
