import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { format } from "date-fns";

const CompletionDate = () => {
  const complete_date = useSelector(
    (state) =>
      state.reportReducer.project?.physical_details?.actual_complete_date,
  );
  return (
    <React.Fragment>
      <Card className='flex-fill w-100'>
        <Card.Header className='pb-0'>
          <h5 className='text-uppercase'>Tarikh Sebenar Siap</h5>
        </Card.Header>
        <Card.Body className='d-flex pt-0 justify-content-center align-items-center'>
          <div className=''>
            <p
              className='text-center text-uppercase mb-0'
              style={{ fontSize: 17, fontWeight: 700 }}
            >
              {complete_date
                ? format(new Date(complete_date), "dd/MM/yyyy")
                : "-"}
            </p>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default CompletionDate;
