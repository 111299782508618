import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { IconButton, Tooltip } from "@mui/material";
import NotyfContext from "../../../../../contexts/NotyfContext";
import axios from "axios";
import usePalette from "../../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiTrashCan, mdiComment } from "@mdi/js";
import { IoAlertOutline } from "react-icons/io5";

// Components
import BasicInfo from "./components/BasicInfo";
import MainAccordion from "./components/MainAccordion";
import LoadingState from "../../../../../components/common/LoadingState";
import ModalDelete from "../../../../../components/common/ModalDelete";
import ReturnApplication from "./components/modals/ReturnApplication";
import Application from "../../../../export/Application";

// Redux
import { fetchMainSectors } from "../../../../../redux/slices/rmk-information/stateMainSectorSlice";
import { fetchUpenSections } from "../../../../../redux/slices/maintenance/upenSectionsSlice";
import { fetchProjectDetails } from "../../../../../redux/slices/projects/projectSlice";
import {
  resetProjectsReducer,
  removeProject,
} from "../../../../../redux/slices/projects/projectsSlice";
import {
  getMinistries,
  getDepartments,
  getExecutingAgencies,
  getCategories,
  getTargetGroups,
  getPrograms,
  getMainProjects,
  getDistrictItems,
  getParliamentItems,
  getStateLegislativeAssembly,
  getSustainableDevelopmentGoals,
  getUpenSections,
  getProjectScope,
  resetComponentsState,
  resetDropdownItems,
} from "../../../../../redux/slices/utilsSlice";
import ReturnApplicationContent from "./components/modals/ReturnApplicationContent";
import { getNewProjectApplication } from "../../../../../redux/slices/jppn/jppnApplyNewProjectSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const routeParams = useParams();
  const navigate = useNavigate();
  const palette = usePalette();

  const { applicationId } = routeParams;

  const [disableBtn, setDisableBtn] = useState(true);
  const [rmkInfo, setRmkInfo] = useState("");
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);
  const [enableBtnSave, setEnableBtnSave] = useState(false);
  const [showReturnApplication, setShowReturnApplication] = useState(false);
  const [returnApplicationNotification, setReturnApplicationNotification] =
    useState(false);
  const [showReturnApplicationContent, setShowReturnApplicationContent] =
    useState(false);

  const [isSuperadminOrAdmin, setIsSuperadminOrAdmin] = useState(false);
  const [isMinistryOrHod, setIsMinistryOrHod] = useState(false);

  const [projectId, setProjectId] = useState(null);
  const [disabledInput, setDisabledInput] = useState(false);

  const {
    isLoading,
    user,
    activeRmk,
    mainSectorList,
    applicationStatus,
    project,
    applicationStages,
    application,
  } = useSelector((state) => ({
    user: state.userReducer.user,
    activeRmk: state.rmkReducer.activeRmk,
    mainSectorList: state.mainSectorReducer.mainSectorList,

    isLoading: state.projectReducer.isLoading,
    applicationStatus: state.projectReducer.applicationStatus,
    project: state.projectReducer.project,
    applicationStages: state.projectReducer.applicationStages,

    application: state.jppnReducer.application,
  }));

  const handleShowSummary = () => {
    navigate(`/jppn/daftar-projek-baharu/${applicationId}/semak`);
  };

  const handleEnableBtn = () => {
    setDisableBtn(false);
  };

  const handleRemoveItem = (project) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    setItem({});
  };

  const handleConfirmRemove = async () => {
    await dispatch(removeProject(project.id));
    handleNotification();
    navigate(`/senarai-projek/status-permohonan`);
  };

  const handleReturnApplication = () => {
    setShowReturnApplication(true);
  };

  const handleShowReturnApplicationContent = () => {
    setShowReturnApplicationContent(true);
  };

  // Notification
  const handleNotification = () => {
    // Success
    notyf.open({
      type: "error",
      message: `${project.name} telah dipadam.`,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const props = {
      source: source,
      params: {
        rmk_id: activeRmk.number,
      },
    };

    Promise.all([
      dispatch(resetComponentsState()),
      dispatch(fetchMainSectors()),
      dispatch(fetchUpenSections()),
      dispatch(getMinistries(props)),
      dispatch(getDepartments(props)),
      dispatch(getExecutingAgencies(props)),
      dispatch(getCategories()),
      dispatch(getTargetGroups(props)),
      dispatch(getPrograms(props)),
      dispatch(getMainProjects(props)),
      dispatch(getDistrictItems(props)),
      dispatch(getParliamentItems(props)),
      dispatch(getStateLegislativeAssembly(props)),
      dispatch(getSustainableDevelopmentGoals(props)),
      dispatch(getUpenSections(props)),
      dispatch(getProjectScope(props)),
    ])
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });

    return () => {
      source.cancel();
    };
  }, [dispatch, routeParams]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const props = {
      source: source,
      id: applicationId,
    };

    const initialize = async () => {
      const responseApplication = await dispatch(
        getNewProjectApplication(props),
      );
      const data = responseApplication.payload;
      dispatch(
        fetchProjectDetails({
          source: source,
          project_id: data.project?.uuid,
        }),
      );
      setProjectId(data.project?.id);
    };

    if (!isEmpty(applicationId)) {
      initialize();
    } else {
      dispatch(resetProjectsReducer());
    }

    return () => {
      source.cancel();
    };
  }, [dispatch, applicationId]);

  useEffect(() => {
    if (!isEmpty(activeRmk)) {
      const { name, short_form, fifth_year, first_year } = activeRmk;
      const value = `${name} (${short_form}): ${first_year}-${fifth_year}`;
      setRmkInfo(value);
    }
  }, [activeRmk]);

  useEffect(() => {
    if (!isEmpty(project)) {
      if (
        applicationStages === 0 ||
        applicationStages === "" ||
        project.stages === "1" ||
        user.roles.includes("superadmin") ||
        user.roles.includes("admin")
      ) {
        setEnableBtnSave(true);
      } else {
        setEnableBtnSave(false);
      }
    } else {
      setEnableBtnSave(true);
    }
    return () => {};
  }, [applicationStages, project]);

  useEffect(() => {
    const isSuperadminOrAdmin =
      user.roles.includes("superadmin") || user.roles.includes("admin");
    const isMinistryOrHod =
      user.roles.includes("ministry") || user.roles.includes("department");

    setIsSuperadminOrAdmin(isSuperadminOrAdmin);
    setIsMinistryOrHod(isMinistryOrHod);

    return () => {};
  }, [user]);

  useEffect(() => {
    if (isEmpty(project)) return;

    const { return_applications } = project;

    const isAcknowledgedFalse = return_applications.some(
      (item) => item.is_acknowledged === false,
    );

    setReturnApplicationNotification(isAcknowledgedFalse);

    const isApproved = project?.application_status.toString() === "4";
    setDisabledInput(isApproved);
    return () => {};
  }, [project]);

  if (isLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <ReturnApplication
        showReturnApplication={showReturnApplication}
        closeReturnApplication={() => setShowReturnApplication(false)}
      />

      <ReturnApplicationContent
        showReturnApplicationContent={showReturnApplicationContent}
        closeReturnApplicationContent={() =>
          setShowReturnApplicationContent(false)
        }
      />

      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        message={`${project.name}`}
        confirmRemove={handleConfirmRemove}
        // btnLoading={btnLoading}
      />

      <Helmet title='Daftar Permohonan' />
      <Container fluid className='p-0'>
        <div>
          <div className='d-flex'>
            <div>
              <h3 style={{ fontWeight: 700 }}>{rmkInfo}</h3>
              <b className='fw-bold fs-4 mb-0'>
                Daftar Projek{" "}
                <span className='fw-bolder'>
                  {project.application_status === "1" &&
                    `(${applicationStatus.toLocaleUpperCase()})`}
                </span>
              </b>
              <p className='text-sm text-muted'>
                Sila isi ruangan yang diperlukan. Ruangan bertanda * adalah
                wajib.
              </p>
            </div>
            <div className='align-self-end ms-auto'>
              <div className='m-2 position-relative'>
                <Tooltip title='Perubahan Maklumat' placement='top' arrow>
                  <IconButton
                    className='p-0'
                    onClick={handleShowReturnApplicationContent}
                  >
                    <Icon
                      path={mdiComment}
                      size={1.2}
                      color={
                        returnApplicationNotification
                          ? palette.danger
                          : palette.secondary
                      }
                    />
                    {returnApplicationNotification && (
                      <IoAlertOutline
                        className='position-absolute'
                        size={17}
                        color={palette.white}
                        style={{ top: 4, right: 6 }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <BasicInfo
            enableBtnSave={enableBtnSave}
            mainSectorList={mainSectorList}
            projectId={projectId}
          />

          {!isEmpty(project) && (
            <React.Fragment>
              <MainAccordion
                enableBtnSave={enableBtnSave}
                handleEnableBtn={handleEnableBtn}
              />

              <div
                className={`d-flex ${
                  isSuperadminOrAdmin
                    ? "justify-content-center"
                    : "justify-content-center"
                } mt-3`}
              >
                {isSuperadminOrAdmin && (
                  <Tooltip title='Padam projek' placement='right' arrow>
                    <IconButton
                      size='small'
                      color='error'
                      onClick={() => handleRemoveItem(project)}
                    >
                      <Icon path={mdiTrashCan} size={1} />
                    </IconButton>
                  </Tooltip>
                )}

                <div className='d-flex justify-content-evenly gap-3 mx-auto'>
                  <div>
                    <Application />
                  </div>

                  {!disabledInput && (
                    <Button variant='primary'>
                      <span style={{ fontSize: 12 }}>Simpan Draf</span>
                    </Button>
                  )}

                  {!disabledInput && (
                    <Button
                      variant='success'
                      onClick={handleShowSummary}
                      disabled={projectId ? false : true}
                    >
                      <span style={{ fontSize: 12 }}>Semak</span>
                    </Button>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
