import numeral from "numeral";
import React from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import usePalette from "../../../../../hooks/usePalette";
// Icons
import Icon from "@mdi/react";
import { mdiAlertCircleOutline } from "@mdi/js";

const ConfirmCertify = ({
  openConfirm,
  handleCloseConfirm,
  handleSubmitConfirmation,
}) => {
  const palette = usePalette();

  const { btnLoading, project } = useSelector((state) => ({
    btnLoading: state.certifyReducer.btnLoading,
    project: state.certifyReducer.project.data,
  }));

  return (
    <Modal show={openConfirm} onHide={handleCloseConfirm} size='sm' centered>
      <Modal.Body>
        <div className='text-center p-4 '>
          <div className='mb-2'>
            <Icon
              path={mdiAlertCircleOutline}
              color={palette.warning}
              size={4}
            />
          </div>
          <p className='mb-0'>
            Sila pastikan maklumat yang di isi adalah betul sebelum
            <span className='ms-1'>
              {project.stages === "6" ? "diluluskan" : "diperakukan"}.
            </span>
          </p>
        </div>
        <div className='d-flex justify-content-center gap-3'>
          <Button
            variant='light'
            onClick={handleCloseConfirm}
            disabled={btnLoading}
          >
            <span style={{ fontSize: 12 }}>Batal</span>
          </Button>
          <Button
            variant='success'
            disabled={btnLoading}
            onClick={handleSubmitConfirmation}
          >
            <span style={{ fontSize: 12 }}>
              {project.stages === "6" ? "Lulus" : "Peraku"}
            </span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmCertify;
