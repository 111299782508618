import React from 'react'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

// Components
import TableHead from './TableHead'
import TableBody from './TableBody'

// Redux
import { addComponent } from '../../../../../../redux/slices/utilsSlice'

const RejectComponents = () => {

  const dispatch = useDispatch()

  const { rejectedComponents } = useSelector((state) => ({
    rejectedComponents: state.utilsReducer.rejectedComponents,
  }))

  const handleAddComponentBack = async (component) => {
    await dispatch(addComponent(component))
  }

  return (
    <React.Fragment>
      <div className='mb-3'>
        <h5>SENARAI KOMPONEN PROJEK YANG TELAH DI KELUARKAN</h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ fontSize: 11 }}>
            <TableHead />
            <TableBody
              data={rejectedComponents}
              handleAddComponentBack={handleAddComponentBack}
            />
          </Table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RejectComponents