import React, { useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { isEmpty } from "lodash";

const UpenSections = ({ handleEditItem, handleRemoveItem, keywords }) => {
  const { isLoading, upenSections } = useSelector((state) => ({
    isLoading: state.departmentReducer.isLoading,
    upenSections: state.upenSectionReducer.upenSections,
  }));

  const filteredSection = upenSections.filter((item) => {
    let filterPass = true;
    if (!isEmpty(keywords)) {
      filterPass =
        filterPass &&
        item.name?.toLocaleLowerCase().includes(keywords.toLocaleLowerCase());
    }
    return filterPass;
  });

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th className='text-center'>Bil.</th>
              <th className='text-center'></th>
              <th>Nama Seksyen</th>
              {/* <th className='text-center'>Kementerian</th> */}
              <th className='text-center'>Pegawai</th>
              {/* <th className="text-center">Kementerian</th> */}
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {filteredSection.map((row, idx) => (
              <tr key={row.id}>
                <td className='text-center'>{idx + 1}.</td>
                <td className='text-center'>{row.short_form}</td>
                <td>{row.name}</td>
                {/* <td className=''>
                  {row.ministries.map((ministry) => (
                    <Badge
                      key={ministry.id}
                      bg=''
                      className='badge-soft-info me-1'
                    >
                      {ministry.name}
                    </Badge>
                  ))}
                </td> */}
                <td className=''>
                  {row.users.map((user) => (
                    <Badge key={user.id} bg='' className='badge-soft-dark me-1'>
                      {user.name}
                    </Badge>
                  ))}
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Tooltip title='Edit' placement='top' arrow>
                      <IconButton
                        color='info'
                        size='small'
                        aria-label='Edit'
                        onClick={() => handleEditItem(row)}
                      >
                        <MdModeEditOutline fontSize='large' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Padam' placement='top' arrow>
                      <IconButton
                        color='error'
                        size='small'
                        aria-label='Padam'
                        onClick={() => handleRemoveItem(row)}
                      >
                        <MdDelete fontSize='large' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default UpenSections;
