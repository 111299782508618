import React from "react";
import { Skeleton } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";

const CardLoading = () => {
  return (
    <React.Fragment>
      <div className='' style={{ height: 50 }}>
        <Skeleton
          animation='wave'
          variant='rectangular'
          height={25}
          width={300}
        />
      </div>
      <div className='pb-4' style={{ height: 120 }}>
        <div className='d-flex h-100 gap-4'>
          <Skeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height='100%'
          />
          <Skeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height='100%'
          />
          <Skeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height='100%'
          />
          <Skeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height='100%'
          />
        </div>
      </div>
      <div style={{ height: 280 }}>
        <Row className='h-100'>
          <Col md={6}>
            <div className='d-flex flex-column h-100 ps-1 py-4 border-bottom border-start border-2 justify-content-center gap-4'>
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='100%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='90%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='75%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='50%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='45%'
                height={25}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className='d-flex flex-column h-100 ps-1 py-4 border-bottom border-start border-2 justify-content-center gap-4'>
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='100%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='90%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='75%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='50%'
                height={25}
              />
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='45%'
                height={25}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className='pb-4 mt-3' style={{ height: 280 }}>
        <div className='d-flex h-100 gap-4'>
          <Skeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height='100%'
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const ProjectReportLoading = () => {
  return (
    <React.Fragment>
      <div className='d-flex gap-3'>
        <Skeleton
          animation='wave'
          sx={{ borderRadius: "28px" }}
          width={210}
          height={65}
        />
        <Skeleton
          animation='wave'
          sx={{ borderRadius: "28px" }}
          width={210}
          height={65}
        />
        <Skeleton
          animation='wave'
          sx={{ borderRadius: "28px" }}
          width={210}
          height={65}
        />
      </div>
      <Row>
        <Col md={6} className='d-flex'>
          <Skeleton
            animation='wave'
            variant='rounded'
            className='flex-fill w-100'
            height={280}
          />
        </Col>
        <Col md={6}>
          <Skeleton
            animation='wave'
            variant='rounded'
            className='flex-fill w-100'
            height={280}
          />
        </Col>
      </Row>
      <div className='mt-3'>
        <Skeleton
          animation='wave'
          variant='rounded'
          className='flex-fill w-100'
          height={280}
        />
      </div>
      <div className='mt-3'>
        <Skeleton
          animation='wave'
          variant='rounded'
          className='flex-fill w-100'
          height={280}
        />
      </div>
    </React.Fragment>
  );
};

const CertifiedCardLoading = () => {
  return (
    <React.Fragment>
      <Row>
        {[1, 2, 3].map((item) => (
          <Col key={item} md={4} className='d-flex mb-3'>
            <Skeleton
              className='flex-fill w-100'
              variant='rounded'
              animation='wave'
              height={70}
            />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export { CardLoading, ProjectReportLoading, CertifiedCardLoading };
