import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import axios from "../../../utils/axios";

export const fetchProjectRequested = createAsyncThunk(
  "totalProject/fetchProjectRequested",
  async (values) => {
    try {
      const response = await axios.get(`/projects/total`, {
        params: {
          application_status: 2,
          rmk_id: values.rmk_id,
        },
      });

      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchProjectInProgress = createAsyncThunk(
  "totalProject/fetchProjectInProgress",
  async (values) => {
    try {
      const response = await axios.get(`/projects/total`, {
        params: {
          application_status: 4,
          rmk_id: values.rmk_id,
        },
      });

      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchNonKspApproved = createAsyncThunk(
  "totalProject/fetchNonKspApproved",
  async (values) => {
    try {
      const response = await axios.get(`/projects/total`, {
        params: {
          application_status: 5,
          rmk_id: values.rmk_id,
          project_category_id: values.project_category_id,
        },
      });

      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchKspApproved = createAsyncThunk(
  "totalProject/fetchKspApproved",
  async (values) => {
    try {
      const response = await axios.get(`/projects/total`, {
        params: {
          application_status: 5,
          rmk_id: values.rmk_id,
          project_category_id: values.project_category_id,
        },
      });

      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchProjectRejected = createAsyncThunk(
  "totalProject/fetchProjectRejected",
  async (values) => {
    try {
      const response = await axios.get(`/projects/total`, {
        params: {
          application_status: 6,
          rmk_id: values.rmk_id,
        },
      });

      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  totalProjectRequested: 0,
  totalProjectInProgress: 0,
  totalNonKspApproved: 0,
  totalKspApproved: 0,
  totalProjectRejected: 0,
  isLoading: true,
};

export const totalProjectSlice = createSlice({
  name: "totalProject",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectRequested.fulfilled, (state, action) => {
      state.totalProjectRequested = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchProjectInProgress.fulfilled, (state, action) => {
      state.totalProjectInProgress = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchNonKspApproved.fulfilled, (state, action) => {
      state.totalNonKspApproved = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchKspApproved.fulfilled, (state, action) => {
      state.totalKspApproved = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchProjectRejected.fulfilled, (state, action) => {
      state.totalProjectRejected = action.payload;
      state.isLoading = false;
    });
  },
});

export default totalProjectSlice.reducer;
