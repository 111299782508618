import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  setSearchMinistry,
  setSearchDepartment,
  setSearchBranch,
} from "../../../../redux/slices/reports/projectReportsSlice";

const MoreSearchField = ({ showCanvas, closeCanvas, searchMoreField }) => {
  const dispatch = useDispatch();

  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [ministryValues, setMinistryValues] = useState([]);
  const [departmentValues, setDepartmentValues] = useState([]);

  const {
    user,
    ministries,
    departments,
    branches,
    searchMinistry,
    searchDepartment,
    searchBranch,
  } = useSelector((state) => ({
    user: state.userReducer.user,
    ministries: state.utilsReducer.dropdownItems.ministries,
    departments: state.utilsReducer.dropdownItems.departments,
    branches: state.utilsReducer.dropdownItems.branches,
    searchMinistry: state.reportReducer.searchMinistry,
    searchDepartment: state.reportReducer.searchDepartment,
    searchBranch: state.reportReducer.searchBranch,
  }));

  const handleClose = () => {
    closeCanvas();
  };

  const handleResetField = () => {
    setMinistryValues([]);
    setDepartmentValues([]);
    const newValues = {
      ministries_code: [],
      department_ids: [],
    };

    searchMoreField(newValues);
  };

  const handleFilterDepartment = (value) => {
    setDepartmentValues(value);
  };

  const handleSearchMinistry = (value) => {
    dispatch(setSearchMinistry(value));
  };

  useEffect(() => {
    if (isEmpty(searchMinistry)) {
      setFilteredDepartments(departments);
    } else {
      setFilteredDepartments(searchMinistry.departments);
    }
  }, [searchMinistry, departments]);

  return (
    <Offcanvas show={showCanvas} onHide={handleClose} style={{ width: 310 }}>
      <Offcanvas.Body>
        <Offcanvas.Title className='mb-3'>Carian Tambahan</Offcanvas.Title>
        <Form autoComplete='off' noValidate style={{ fontSize: 12 }}>
          {user?.user_access_to_ministries.length !== 1 && (
            <Form.Group className='mb-3'>
              <Form.Label>Kementerian</Form.Label>
              <Select
                placeholder=''
                className='react-select-container'
                classNamePrefix='react-select'
                options={ministries || []}
                value={searchMinistry}
                onChange={(value) => handleSearchMinistry(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isClearable
              />
            </Form.Group>
          )}

          <Form.Group className='mb-3'>
            <Form.Label>Jabatan/Agensi</Form.Label>
            <Select
              placeholder=''
              className='react-select-container'
              classNamePrefix='react-select'
              options={filteredDepartments}
              value={searchDepartment}
              onChange={(value) => dispatch(setSearchDepartment(value))}
              noOptionsMessage={() => "Tiada pilihan"}
              isClearable
            />
          </Form.Group>

          {user.user_access_to_executing_agencies.some(
            (item) => item.id === 1030,
          ) && (
            <Form.Group className='mb-3'>
              <Form.Label>Cawangan</Form.Label>
              <Select
                placeholder=''
                className='react-select-container'
                classNamePrefix='react-select'
                options={branches}
                value={searchBranch}
                onChange={(value) => dispatch(setSearchBranch(value))}
                noOptionsMessage={() => "Tiada pilihan"}
                isClearable
              />
            </Form.Group>
          )}

          <div className='d-flex flex-column gap-3'>
            <Button
              variant='primary'
              className='flex-fill'
              onClick={searchMoreField}
            >
              Cari
            </Button>
            <Button
              variant='light'
              className='flex-fill'
              onClick={handleResetField}
            >
              Set Semula
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MoreSearchField;
