import { useFormik } from "formik";
import React from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";

import {
  createCreditScoreCriteria,
  updateCreditScoreCriteria,
} from "../../../../../redux/slices/maintenance/creditScoreCriteriaSlice";
import { useDispatch, useSelector } from "react-redux";

const CriteriaForm = ({ openForm, closeForm, isEdit, data }) => {
  const dispatch = useDispatch();

  const { btnLoading } = useSelector((state) => ({
    btnLoading: state.creditScoreCriteriaReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
      score: data?.score || "",
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          ...values,
          id: data.id,
        };
        await dispatch(updateCreditScoreCriteria(newValues));
      } else {
        await dispatch(createCreditScoreCriteria(values));
      }

      handleSuccess();
    },
  });

  const handleSuccess = () => {
    formik.resetForm();
    closeForm();
  };

  return (
    <React.Fragment>
      <Modal show={openForm} onHide={closeForm} centered>
        <Modal.Body>
          <div>
            <Card.Title className='mb-0'>Tambah Kriteria</Card.Title>
            <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
          </div>
          <Form
            autoComplete='off'
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Form.Group className='mb-3'>
              <Form.Label>Nama Kriteria</Form.Label>
              <Form.Control
                type='text'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Keterangan Kriteria</Form.Label>
              <Form.Control
                placeholder='jika berkaitan'
                as='textarea'
                type='text'
                name='description'
                style={{ minHeight: 75, maxHeight: 105 }}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Skor (%)</Form.Label>
              <Form.Control
                placeholder='cth. 10'
                type='number'
                name='score'
                min={0}
                value={formik.values.score}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>

            <div className='mt-3 d-flex justify-content-end gap-3'>
              <Button variant='light' onClick={closeForm} disabled={btnLoading}>
                Batal
              </Button>
              <Button variant='success' type='submit' disabled={btnLoading}>
                Simpan
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CriteriaForm;
