import React, { useState } from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { SixColumns } from "../../../../../../components/common/tableLoading";
import { isEmpty, isNaN } from "lodash";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

const ExecutingAgencies = ({
  handleEditItem,
  handleRemoveItem,
  page,
  perPage,
  goToPage,
  goToFirstPage,
  previousPage,
  nextPage,
  goToLastPage,
  isMinistryPage,
}) => {
  const [pageValue, setPageValue] = useState("1");

  const { isLoading, executingAgencies, meta, links } = useSelector(
    (state) => ({
      isLoading: state.executingAgencyReducer.isLoading,
      executingAgencies: state.executingAgencyReducer.executingAgencies,
      meta: state.executingAgencyReducer.meta,
      links: state.executingAgencyReducer.links,
    }),
  );

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead
            className='custom-table-head text-uppercase'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th className='text-center'>Bil.</th>
              <th>Nama Agensi Pelaksana</th>
              <th className='text-center'>Kod</th>
              <th className='text-center'>Kementerian</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading ? (
            <SixColumns />
          ) : (
            <tbody>
              {isEmpty(executingAgencies) ? (
                <tr>
                  <td colSpan={5} className='text-center'>
                    Maklumat tidak ditemui
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {executingAgencies.map((row, idx) => (
                    <tr key={row.id}>
                      <td className='text-center'>
                        {(page - 1) * perPage + idx + 1}.
                      </td>
                      <td>{row.name}</td>
                      <td className='text-center'>{row.code}</td>
                      {!isMinistryPage && (
                        <td className='text-center'>
                          {row.ministry?.short_form}
                        </td>
                      )}
                      <td>
                        <div className='d-flex justify-content-center'>
                          <Tooltip title='Edit' placement='top' arrow>
                            <IconButton
                              color='info'
                              size='small'
                              aria-label='Edit'
                              onClick={() => handleEditItem(row)}
                            >
                              <MdModeEditOutline fontSize='large' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Padam' placement='top' arrow>
                            <IconButton
                              color='error'
                              size='small'
                              aria-label='Padam'
                              onClick={() => handleRemoveItem(row)}
                            >
                              <MdDelete fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div className='d-flex justify-content-between pt-3'>
        <div>
          <span className=''>
            Halaman{" "}
            <strong>
              {meta.current_page} daripada {meta.last_page}
            </strong>
          </span>
          <span className='ms-3 me-2'>Pergi ke halaman:</span>
          <Form.Control
            className='d-inline-block'
            value={pageValue}
            onChange={(e) => {
              const value = Number(e.target.value);
              setPageValue(e.target.value);
              if (
                isEmpty(e.target.value) ||
                value <= 0 ||
                value > meta.last_page ||
                isNaN(value)
              )
                return false;

              goToPage(value);
            }}
            style={{ width: "75px" }}
          />
        </div>
        <div>
          <Pagination className='float-end remove-outline-item'>
            <Pagination.First
              onClick={() => goToFirstPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={links.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => goToLastPage(meta.last_page)}
              disabled={links.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExecutingAgencies;
