import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import ModalDelete from "../../../../components/common/ModalDelete";
import NotyfContext from "../../../../contexts/NotyfContext";
import { isEmpty } from "lodash";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import Districts from "./components/tables/Districts";
import DaerahForm from "./components/modals/DaerahForm";

// Redux
import {
  fetchDistricts,
  removeDistrict,
} from "../../../../redux/slices/state-information/districtsSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [openForm, setOpenForm] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [item, setItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { isLoading, btnLoading, districts } = useSelector((state) => ({
    districts: state.districtReducer.districts,
    btnLoading: state.districtReducer.btnLoading,
  }));

  const filteredData = districts?.filter((item) => {
    let isFilterPassed = true;

    if (!isEmpty(searchText)) {
      isFilterPassed =
        isFilterPassed &&
        item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
    }

    return isFilterPassed;
  });

  const handleCloseForm = () => {
    setOpenForm(false);
    setIsEdit(false);
    setItem({});
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleEditItem = (item) => {
    setIsEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    setItem({});
    // handleResetState();
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeDistrict(data.id));
    handleNotification("error", data);
    handleCloseRemove();
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values?.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values?.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values?.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(fetchDistricts());
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <DaerahForm
        showForm={openForm}
        closeForm={handleCloseForm}
        handleNotification={handleNotification}
        isEdit={isEdit}
        data={item}
      />

      <Helmet title='Senarai Daerah' />
      <Container fluid className='p-0'>
        <Breadcrumbs title='Maklumat Negeri' breadcrumbItem='Senarai Daerah' />
        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Daerah
              </Button>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari Daerah...'
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Districts
              districts={filteredData}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
