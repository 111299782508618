import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import axios from "axios";
import MessageModal from "../common/MessageModal";

function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();

  const [openModal, setOpenModal] = useState(false);

  return (
    <React.Fragment>
      <MessageModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        message='Penukaran kata laluan telah diminta, sila semak emel anda daftarkan didalam sistem'
      />
      <Formik
        initialValues={{
          nric: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape()}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/user-password-reset/request`,
              {
                nric: values.nric,
              },
            );
            setOpenModal(true);
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className='my-3' variant='danger'>
                <div className='alert-message'>{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className='mb-3'>
              <Form.Label>No. MyKad (Tanpa '-')</Form.Label>
              <Form.Control
                type='text'
                name='nric'
                label='No. MyKad (Tanpa "-")'
                value={values.nric}
                isInvalid={Boolean(touched.nric && errors.nric)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.nric && (
                <Form.Control.Feedback type='invalid'>
                  {errors.nric}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className='text-center mt-3'>
              <Button
                type='submit'
                variant='primary'
                size='lg'
                disabled={isSubmitting}
              >
                Set Semula Kata Laluan
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default ResetPassword;
