import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";

const Justification = () => {
  const routeParams = useParams();
  const { category } = routeParams;

  const [parentIndex, setParentIndex] = useState(0);

  const { project } = useSelector((state) => ({
    project: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (category === "kenaikan-siling") {
      setParentIndex(5);
    } else {
      setParentIndex(4);
    }
  }, [category]);

  return (
    <React.Fragment>
      <div>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>{parentIndex}.0</span>
          Justifikasi
        </Form.Label>
        {JSON.parse(project?.justification).map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <div className='d-flex flex-row ps-4'>
                <div className='me-3'>4.{idx + 1}</div>
                <p>{item?.description}</p>
              </div>
              {item?.children.map((child, subIndex) => {
                return (
                  <div className='d-flex flex-row ps-4'>
                    <div className='me-5'>
                      {parentIndex}.{idx + 1}.{subIndex + 1}
                    </div>
                    <p>{child?.description}</p>
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Justification;
