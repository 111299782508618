import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Table, Button, Card } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";

// Icons
import { MdDelete, MdEdit } from "react-icons/md";

// Components
import HrRequirementForm from "../modals/HrRequirementForm";

// Redux
import { removeHumanResourcesRequirement } from "../../../../../../../redux/slices/projects/humanResourcesRequirementSlice";

const HrRequirement = ({ enableBtnSave, disabledInput }) => {
  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const { isLoading, project } = useSelector((state) => ({
    project: state.projectReducer.project,
  }));

  const handleCloseForm = () => {
    setOpenForm(false);
    setRowData({});
    setIsEdit(false);
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleRemoveRow = (id) => {
    dispatch(removeHumanResourcesRequirement(id));
  };

  const handleEditRow = (row) => {
    setRowData(row);
    setOpenForm(true);
    setIsEdit(true);
  };

  return (
    <React.Fragment>
      <HrRequirementForm
        openForm={openForm}
        closeForm={handleCloseForm}
        data={rowData}
        isEdit={isEdit}
      />

      <Card.Header className='pb-0'>
        {!disabledInput && (
          <div className='card-actions float-end'>
            <Button size='sm' variant='secondary' onClick={handleOpenForm}>
              + Maklumat
            </Button>
          </div>
        )}

        <Card.Title>Keperluan Sumber Manusia</Card.Title>
        <Card.Text className='text-sm'>
          Sila isi maklumat keperluan sumber manusia jika perlu.
        </Card.Text>
      </Card.Header>

      <Card.Body className='m-0'>
        <div className='table-responsive'>
          <Table size='sm' style={{ fontSize: 12 }}>
            <thead>
              <tr>
                <th className='text-center'>Bil.</th>
                <th className='text-center'>Bilangan staff</th>
                <th>Nama Jawatan</th>
                <th>Gred Jawatan</th>
                <th className='text-center'>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(project?.human_resources_requirements) ? (
                <tr>
                  <td colSpan={7} className='text-center'>
                    Tiada Maklumat
                  </td>
                </tr>
              ) : (
                <>
                  {project?.human_resources_requirements.map((row, idx) => (
                    <tr key={row.id}>
                      <td className='text-center'>{idx + 1}.</td>
                      <td className='text-center'>{row.number_of_staff}</td>
                      <td>{row.position_name}</td>
                      <td>{row.position_gred}</td>
                      <td>
                        <div className='d-flex justify-content-center gap-2'>
                          <Tooltip title='Edit' placement='top' arrow>
                            <IconButton
                              color='primary'
                              size='small'
                              aria-label='edit'
                              onClick={() => handleEditRow(row)}
                            >
                              <MdEdit fontSize='large' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Padam' placement='top' arrow>
                            <IconButton
                              color='error'
                              size='small'
                              aria-label='padam'
                              onClick={() => handleRemoveRow(row.id)}
                            >
                              <MdDelete fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </React.Fragment>
  );
};

export default HrRequirement;
