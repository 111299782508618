import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import * as Yup from "yup";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import NotyfContext from "../../contexts/NotyfContext";

const FirstTimeLogin = () => {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const { signOut } = useContext(AuthContext);

  const [errors, setErrors] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.userReducer.user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      current_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      current_password: Yup.string().required("Kata Laluan Semasa diperlukan"),
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Mesti Mengandungi 8 Aksara, Huruf Besar, Huruf Kecil, Nombor, dan Aksara Khas",
        )
        .required("Kata Laluan Baharu diperlukan"),
    }),
    onSubmit: async (values) => {
      try {
        setDisableButton(true);

        const newValues = {
          ...values,
          id: user.id,
        };

        const response = await axios.post(`/password/change`, newValues);
        const data = await response.data;

        if (data.success) {
          handleNotification(data);
          setErrors(null);
        } else {
          setErrors({
            ...errors,
            current_password: "Kata laluan semasa tidak sama",
          });
        }
      } catch (error) {
        console.error("Password change failed", error);
      }
    },
  });

  // Notification
  const handleNotification = async (res) => {
    let msg = `${res.message}`;
    let color = "success";

    // Success
    await notyf.open({
      type: color,
      message: msg,
      duration: 3000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
    navigate(res.redirect);
    setDisableButton(false);
  };

  useEffect(() => {
    if (!isEmpty(formik.values.password)) {
      if (
        formik.values.password !== formik.values.confirm_password &&
        !isEmpty(formik.values.confirm_password)
      ) {
        setErrors({
          ...errors,
          confirm_password: "Kata laluan baharu tidak sama",
        });
        setDisableButton(true);
      } else {
        setErrors(null);
        setDisableButton(false);
      }
    } else {
      setErrors(null);
    }

    return () => {};
  }, [formik.values.confirm_password, formik.values.password]);

  return (
    <React.Fragment>
      <Container
        fluid
        className='p-0 d-flex align-items-center justify-content-center h-100'
      >
        <Card style={{ width: "50%" }}>
          <Card.Body>
            <h5>Sila tukar kata laluan.</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Form.Group className='mb-3'>
                <Form.Label>Kata Laluan Semasa</Form.Label>
                <Form.Control
                  type='password'
                  name='current_password'
                  value={formik.values.current_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!isEmpty(errors?.current_password)}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.current_password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Kata Laluan Baharu</Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={!isEmpty(formik.errors.password)}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors?.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Sahkan Kata Laluan Baharu</Form.Label>
                <Form.Control
                  type='password'
                  name='confirm_password'
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  isInvalid={!isEmpty(errors?.confirm_password)}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.confirm_password}
                </Form.Control.Feedback>
              </Form.Group>
              <div className='d-flex justify-content-center gap-3'>
                <Button
                  type='submit'
                  variant='success'
                  disabled={disableButton}
                >
                  Tukar
                </Button>
                <Button
                  variant='light'
                  onClick={async () => {
                    await signOut();
                  }}
                >
                  Batal
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default FirstTimeLogin;
