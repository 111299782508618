import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiCheckCircle } from "@mdi/js";
import { IoAlert } from "react-icons/io5";

// Styles
import usePalette from "../../../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../../../contexts/NotyfContext";

import { getTargetApi } from "../../../../../../../utils/common/mock-data";

// Redux
import { updateProject } from "../../../../../../../redux/slices/projects/projectSlice";

const ProjectInfoForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  handleNotification,
  project,
  btnLoading,
  enableBtnSave,
}) => {
  const notyf = useContext(NotyfContext);
  const palette = usePalette();
  const dispatch = useDispatch();

  const [targetGroupOptions, setTargetGroupOptions] = useState([]);
  const [generateCode, setGenerateCode] = useState("");
  const [checkSection, setCheckSection] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabledInput, setDisabledInput] = useState(false);

  const { applicationStatus, targetGroups, user } = useSelector((state) => ({
    applicationStatus: state.projectReducer.applicationStatus,
    user: state.userReducer.user,
    targetGroups: state.utilsReducer.dropdownItems.groups,
  }));

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // name: project.name || "",
      description: project?.description || "",
      justification: project?.justification || "",
      benefits: project?.benefits || "",
      project_type_id: project?.project_type_id || null,
      target_groups: project?.target_groups.map((item) => item.id) || [],
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required("Masukkan keterangan projek")
        .min(15, "Keterangan projek mesti lebih dari 15 perkataan."),
      benefits: Yup.string()
        .required("Masukkan faedah projek")
        .min(15, "Faedah projek mesti lebih dari 15 perkataan."),
      justification: Yup.string()
        .required("Masukkan justifikasi projek")
        .min(15, "Justifikasi projek mesti lebih dari 15 perkataan."),
      project_type_id: Yup.number()
        .typeError("Sila pilih jenis projek")
        .required("Sila pilih jenis projek"),
      target_groups: Yup.array()
        .min(1, "Sila pilih sekurang-kurangnya 1 pilihan")
        .required("Sila pilih kumpulan sasar"),
    }),
    onSubmit: async (values) => {
      const targetGroupIds = values.target_groups.map((item) => item.id);

      const newValues = {
        id: project.uuid,
        description: values.description,
        justification: values.justification,
        benefits: values.benefits,
        project_type_id: values.project_type_id,
        target_groups: targetGroupIds,
      };
      await dispatch(updateProject(newValues));

      handleNotification("edited", newValues);
      handleChecked(1);
      handleExpand(2);
    },
  });

  useEffect(() => {
    let isMounted = true;

    if (!isMounted) return;

    if (isEmpty(project)) return;
    //Generated code
    const ministryCode = project.ministry?.code;
    const departmentCode =
      project.department?.code.toString().padStart(3, "0") || "000";
    const programCode =
      project.program?.code.toString().padStart(3, "0") || "000";
    const mainProjectCode =
      project.main_project?.code.toString().padStart(3, "0") || "000";
    const projectCode = project.id.toString().padStart(4, "0") || "000";
    const codeProject = `D${ministryCode}-000-${programCode}-${mainProjectCode}${project.code}`;
    setGenerateCode(codeProject);

    //Target Groups
    formik.setFieldValue("target_groups", project.target_groups);

    const isApproved = project?.application_status.toString() === "4";
    setDisabledInput(isApproved);

    return () => {
      isMounted = false;
    };
  }, [project]);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(1)}
      >
        {isEmpty(formik.errors) ? (
          <Icon
            className='me-1'
            path={mdiCheckCircle}
            color={palette.success}
            style={{ height: 28, width: 28 }}
          />
        ) : (
          <span
            className='number'
            style={{ color: palette.danger, borderColor: palette.danger }}
          >
            1.
          </span>
        )}
        Maklumat Projek
        {!isEmpty(formik.errors) && (
          <IoAlert size={24} color={palette.danger} />
        )}
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Body className='m-0'>
            <Card.Text className='text-sm'>Sila isi maklumat projek.</Card.Text>
            <Form
              noValidate
              autoComplete='off'
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Keterangan Projek *</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='description'
                      style={{
                        minHeight: 75,
                        maxHeight: 95,
                      }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabledInput}
                      isValid={
                        formik.touched.description && !formik.errors.description
                          ? true
                          : false
                      }
                      isInvalid={
                        formik.touched.description && formik.errors.description
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Faedah Projek</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='benefits'
                      style={{
                        height: 120,
                        minHeight: 120,
                        maxHeight: 200,
                      }}
                      value={formik.values.benefits}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabledInput}
                      isValid={
                        formik.touched.benefits && !formik.errors.benefits
                          ? true
                          : false
                      }
                      isInvalid={
                        formik.touched.benefits && formik.errors.benefits
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.benefits}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Justifikasi Projek</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='justification'
                      style={{
                        height: 120,
                        minHeight: 120,
                        maxHeight: 200,
                      }}
                      value={formik.values.justification}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabledInput}
                      isValid={
                        formik.touched.justification &&
                        !formik.errors.justification
                          ? true
                          : false
                      }
                      isInvalid={
                        formik.touched.justification &&
                        formik.errors.justification
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.justification}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className='mb-3'>
                <Form.Label>Jenis Projek (Pilih Satu) *</Form.Label>
                <Form.Group controlId='project_type_1'>
                  <Form.Check
                    id='project_type_1'
                    label='Fizikal'
                    type='radio'
                    name='project_type_id'
                    value={1}
                    checked={
                      formik.values.project_type_id === 1 ||
                      project.project_type?.id === 1
                    }
                    onChange={(e) => formik.setFieldValue("project_type_id", 1)}
                    disabled={disabledInput}
                  />
                </Form.Group>
                <Form.Group controlId='project_type_2'>
                  <Form.Check
                    id='project_type_2'
                    label='Program (Fizikal)'
                    type='radio'
                    name='project_type_id'
                    value={2}
                    checked={
                      formik.values.project_type_id === 2 ||
                      project.project_type?.id === 2
                    }
                    onChange={() => formik.setFieldValue("project_type_id", 2)}
                    disabled={disabledInput}
                  />
                </Form.Group>
                <Form.Group controlId='project_type_3'>
                  <Form.Check
                    id='project_type_3'
                    label='Program (Bukan Fizikal)'
                    type='radio'
                    name='project_type_id'
                    value={3}
                    checked={
                      formik.values.project_type_id === 3 ||
                      project.project_type?.id === 3
                    }
                    onChange={() => formik.setFieldValue("project_type_id", 3)}
                    disabled={disabledInput}
                  />
                </Form.Group>
                {formik.touched.project_type_id &&
                  formik.errors.project_type_id && (
                    <p className='mt-1 custom-feedback-invalid'>
                      {formik.errors.project_type_id}
                    </p>
                  )}
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Kumpulan Sasar *</Form.Label>
                <Select
                  placeholder='Pilih...'
                  isMulti
                  noOptionsMessage={() => "Tiada pilihan"}
                  options={targetGroups}
                  value={formik.values.target_groups}
                  isDisabled={disabledInput}
                  onChange={(value) =>
                    formik.setFieldValue("target_groups", value)
                  }
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#ced4da",
                      backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057",
                    }),
                  }}
                />
                {formik.touched.target_groups &&
                  formik.errors.target_groups && (
                    <p className='mt-1 custom-feedback-invalid'>
                      {formik.errors.target_groups}
                    </p>
                  )}
              </Form.Group>

              {!disabledInput && (
                <div className='d-flex justify-content-end mt-4'>
                  <Button
                    className='px-3'
                    type='submit'
                    variant='primary'
                    disabled={!isEmpty(project) ? false : true}
                  >
                    {btnLoading ? (
                      <Icon
                        className='mx-3'
                        path={mdiLoading}
                        size={0.8}
                        spin={true}
                      />
                    ) : (
                      <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                        Simpan maklumat projek
                      </span>
                    )}
                  </Button>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default ProjectInfoForm;
