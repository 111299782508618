import React, { useEffect, useState } from "react";
import numeral from "numeral";
import {
  Card,
  OverlayTrigger,
  ProgressBar,
  Tab,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { format, parse } from "date-fns";
import { ms } from "date-fns/locale";
import usePalette from "../../../../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiCurrencyUsd, mdiArrowRightBottomBold } from "@mdi/js";

const AllocationData = ({ eventKey }) => {
  const palette = usePalette();

  const currentYear = new Date().getFullYear();

  const [projectCode, setProjectCode] = useState("");
  const [progressColor, setProgressColor] = useState("danger");
  const [allocationData, setAllocationData] = useState(null);
  const [financialProgress, setFinancialProgress] = useState(0);
  const [allocationWarrantTransactions, setAllocationWarrantTransactions] =
    useState([]);

  const [revisedCeiling, setRevisedCeiling] = useState(0);
  const [initialAllocation, setInitialAllocation] = useState(0);
  const [revisedAllocation, setRevisedAllocation] = useState(0);
  const [virement, setVirement] = useState("");
  const [aspAmount, setAspAmount] = useState(0);
  const [currentBalanceAllocation, setCurrentBalanceAllocation] = useState(0);

  const { project, currentRmk } = useSelector((state) => ({
    project: state.projectReducer.project,
    currentRmk: state.rmkReducer.activeRmk,
  }));

  useEffect(() => {
    if (!isEmpty(project)) {
      const {
        executing_agency,
        program,
        main_project,
        code,
        acc_information_current_year,
        s3p_data,
        financial_details,
      } = project;
      const fullCode = project?.sub_project_full_code;

      setProjectCode(fullCode);

      setAllocationData(acc_information_current_year);

      const allocation = parseFloat(acc_information_current_year?.peruntukan);
      const currentBalance = parseFloat(
        acc_information_current_year?.baki_semasa,
      );

      const expenditure = allocation - currentBalance;
      const expenditurePercentage = (expenditure / allocation) * 100;
      const currentBalancePercentage = (currentBalance / allocation) * 100;

      setFinancialProgress({
        expenditure: `RM ${numeral(expenditure).format("0,0.00")}`,
        expenditurePercentage: expenditurePercentage,
        currentBalance: `RM ${numeral(currentBalance).format("0,0.00")}`,
        currentBalancePercentage: currentBalancePercentage,
      });

      let progressColor = "success";

      if (expenditurePercentage <= 49) {
        progressColor = "success";
      }

      if (expenditurePercentage >= 50 && expenditurePercentage <= 79) {
        progressColor = "warning";
      }

      if (expenditurePercentage >= 80) {
        progressColor = "danger";
      }

      setProgressColor(progressColor);

      // Initial Allocation
      const sumInitialAllocation = s3p_data?.reduce(
        (acc, obj) => (acc += parseFloat(obj.state_fund)),
        0,
      );
      setInitialAllocation(sumInitialAllocation);

      const sumBalanceAllocation = s3p_data?.reduce(
        (acc, obj) => (acc += parseFloat(obj.current_balance)),
        0,
      );
      setCurrentBalanceAllocation(sumBalanceAllocation);

      const financialActivities = s3p_data?.flatMap(
        (item) => item.financial_activities,
      );

      const asp = financialActivities
        .filter((item) => item.type === "ASP")
        .reduce((acc, item) => acc + parseFloat(item.amount), 0);

      const virementIn = financialActivities
        .filter((item) => item.type === "VI")
        .reduce((acc, item) => acc + parseFloat(item.amount), 0);

      const virementOut = financialActivities
        .filter((item) => item.type === "VO")
        .reduce((acc, item) => acc + parseFloat(item.amount), 0);

      const sumRevisedAllocation =
        sumInitialAllocation + asp + virementIn - virementOut;

      setRevisedAllocation(sumRevisedAllocation);

      const initialCeiling = financial_details
        .flatMap((item) => item.ceiling_flow_details)
        .reduce((acc, item) => acc + parseFloat(item.amount), 0);

      const calcRevisedCeiling = initialCeiling - sumRevisedAllocation;

      setRevisedCeiling(calcRevisedCeiling);

      const virementData = `(+)${numeral(virementIn).format(
        "0,0.00",
      )}/ (-)${numeral(virementOut).format("0,0.00")}`;

      setAspAmount(asp);
      setVirement(virementData);
    }
  }, [project]);

  return (
    <Tab.Pane eventKey={eventKey}>
      <div className='mb-3'>
        <h5>
          <span className='text-uppercase me-1'>
            Maklumat Peruntukan Semasa
          </span>
          ({project?.rmk?.short_form})
        </h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 11 }}
            >
              <tr>
                <th className='text-center' style={{ width: "5%" }}>
                  <div className=''>
                    <div>(A)</div>
                    <div>Bil.</div>
                  </div>
                </th>
                <th className='text-center' style={{ width: "10%" }}>
                  <div>(B)</div>
                  <div>Kod Projek</div>
                </th>
                <th className='text-center'>
                  <div>(C)</div>
                  <div>Siling Disemak</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>(D)</div>
                  <div>Peruntukan Asal MOF</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>(F)</div>
                  <div>Craw</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>(G)</div>
                  <div>ASP</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>(H)</div>
                  <div className='text-truncate'>Virement (+ / -)</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>(I)</div>
                  <div>Peruntukan Disemak MOF</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>(J)</div>
                  <div>Perbelanjaan terkini</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>(K)</div>
                  <div>Baki peruntukan terkini</div>
                  <div>(RM)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>1</td>
                <td className='text-truncate'>{projectCode}</td>
                <td className='text-end'>
                  {numeral(revisedCeiling).format("0,0.00")}
                </td>
                <td className='text-end'>
                  {numeral(initialAllocation).format("0,0.00")}
                </td>
                <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                <td className='text-end'>
                  {numeral(aspAmount).format("0,0.00")}
                </td>
                <td className='text-end'>{virement}</td>
                <td className='text-end'>
                  {numeral(revisedAllocation).format("0,0.00")}
                </td>
                <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                <td className='text-end'>
                  {numeral(currentBalanceAllocation).format("0,0.00")}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className='mb-3'>
        <h5 className='text-uppercase'>Transaksi Waran Peruntukan</h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 11 }}
            >
              <tr>
                <th className='text-center' style={{ width: "5%" }}></th>
                <th>Kod Akauan</th>
                <th>Deskripsi</th>
                <th className='text-center'>
                  <div>
                    Peruntukan
                    <span className='ms-1'>
                      {allocationData?.tahun_kewangan}
                    </span>
                  </div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Baki Semasa</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>Jumlah dan Perbelanjaan(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>
                  <Icon
                    path={mdiCurrencyUsd}
                    size='18px'
                    color={palette.success}
                  />
                </td>
                <td className='text-truncate'>{allocationData?.full_code}</td>
                <td>{allocationData?.tajuk}</td>
                <td className='text-end'>
                  {numeral(allocationData?.peruntukan).format("0,0.00")}
                </td>
                <td className='text-end'>
                  {numeral(allocationData?.baki_semasa).format("0,0.00")}
                </td>
                <td>
                  <ProgressBar>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='expenditure'>
                          {financialProgress.expenditure}
                        </Tooltip>
                      }
                    >
                      <ProgressBar
                        id='expenditure'
                        variant={progressColor}
                        now={financialProgress.expenditurePercentage}
                        key={1}
                        isChild={true}
                      ></ProgressBar>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='balance'>
                          {financialProgress.currentBalance}
                        </Tooltip>
                      }
                    >
                      <ProgressBar
                        id='balance'
                        variant='secondary'
                        now={financialProgress.currentBalancePercentage}
                        key={2}
                        isChild={true}
                      ></ProgressBar>
                    </OverlayTrigger>
                  </ProgressBar>
                </td>
              </tr>
              {allocationWarrantTransactions?.map((item) => {
                return (
                  <tr>
                    <td className='text-end'>
                      <Icon path={mdiArrowRightBottomBold} size='18px' />
                    </td>
                    <td colSpan={2}>-</td>
                    <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                    <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                    <td>
                      <div>
                        {/* <ProgressBar variant={progressColor} now={1} /> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </Tab.Pane>
  );
};

export default AllocationData;
