import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchDistricts = createAsyncThunk(
  "districts/fetchDistricts",
  async () => {
    try {
      const response = await axios.get(`/districts`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createDistrict = createAsyncThunk(
  "districts/createDistrict",
  async (values) => {
    const response = await axios.post(`/districts`, values);

    const data = await response.data;

    return data;
  },
);

export const updateDistrict = createAsyncThunk(
  "districts/updateDistrict",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/districts/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeDistrict = createAsyncThunk(
  "districts/removeDistrict",
  async (id) => {
    try {
      const response = await axios.delete(`/districts/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  districts: [],
  isLoading: false,
  btnLoading: false,
};

export const districtsSlice = createSlice({
  name: "districts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDistricts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDistricts.fulfilled, (state, action) => {
      state.districts = action.payload;
      state.isLoading = false;
    });

    builder.addCase(createDistrict.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createDistrict.fulfilled, (state, action) => {
      state.districts.push(action.payload);
      state.isLoading = false;
    });

    builder.addCase(updateDistrict.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateDistrict.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.districts = state.districts.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeDistrict.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(removeDistrict.fulfilled, (state, action) => {
      state.districts = state.districts.filter(
        (item) => item.id !== action.payload,
      );

      state.btnLoading = false;
    });
  },
});

export default districtsSlice.reducer;
