import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const createProjectScopeFinancial = createAsyncThunk(
  "projectScopeFinancial/createProjectScopeFinancial",
  async (params) => {
    try {
      const { project_id, values } = params;
      const response = await axios.post(
        `/project/store-project-scope-financial/${project_id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createProjectCeilingFlow = createAsyncThunk(
  "projectScopeFinancial/createProjectCeilingFlow",
  async (values) => {
    try {
      const response = await axios.post(
        `/project/store-project-ceiling-flow`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);
