import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ConfirmCancel = ({ show, onHide, cancel, handleChange, value }) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <h5>Andakah anda pasti untuk membatalkan permohonan?</h5>
          <p>
            Segala maklumat yang diubah tidak boleh dikembalikan. Harap maklum.
          </p>
          <Form.Group>
            <Form.Label>Sila nyatakan sebab:</Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              style={{ height: 130, maxHeight: 180 }}
              onChange={handleChange}
              value={value}
            />
          </Form.Group>
          <div className='d-flex justify-content-end gap-3'>
            <Button variant='light' onClick={onHide}>
              Kembali
            </Button>
            <Button variant='danger' onClick={cancel}>
              Batal
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmCancel;
