import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import numeral from "numeral";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingState from "../../../../../components/common/LoadingState";
import usePalette from "../../../../../hooks/usePalette";

// icons
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";

// Redux
import {
  getVirementSource,
  setSourceCeilingData,
  getProjectBasicInfo,
  setVirementToData,
  setProjectComponents,
  setRevisedCeiling,
  setVirementOutProjects,
  removeVirementOutProject,
  changeVirementOutAmount,
} from "../../../../../redux/slices/jppn/jppnModuleSlice";

const VirementInformation = ({ nextPage }) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const routeParams = useParams();

  const [options, setOptions] = useState([]);
  const [totalVirementOutAmount, setTotalVirementOutAmount] = useState(0);

  const {
    projectIsLoading,
    currentRmk,
    project,
    projects,
    isLoading,
    application,
    draft,
    virementOutProjects,
  } = useSelector((state) => ({
    projectIsLoading: state.projectReducer.isLoading,
    currentRmk: state.rmkReducer.activeRmk,
    project: state.projectReducer.project,
    projects: state.jppnReducer.projects.data,
    isLoading: state.jppnReducer.virement.isLoading,

    application: state.jppnReducer.application,

    draft: state.jppnReducer.draft,

    virementOutProjects: state.jppnReducer.virement.virementOutProjects,
  }));

  const handleSelectSource = async (value, idx) => {
    const projectId = value;

    const props = {
      index: idx,
      params: {
        id: projectId,
      },
    };

    const response = await dispatch(getVirementSource(props));

    const data = await response.payload;
  };

  const handleAddSource = () => {
    const newItem = null;

    dispatch(setVirementOutProjects(newItem));
  };

  const handleRemoveSource = (idx) => {
    dispatch(removeVirementOutProject(idx));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    if (!isEmpty(project)) {
      const ministryCode = project.ministry.code;
      const programCode = project.program.code;

      const props = {
        source: source,
        params: {
          ministry_code: ministryCode,
          program_code: programCode,
        },
      };

      dispatch(getProjectBasicInfo(props));

      // if (routeParams.id !== project.id.toString()) {
      const projectComponents = project?.financial_details.map((item) => {
        return {
          ...item,
          current_revised_ceiling: item.revised_ceiling,
          additional_ceiling: 0,
        };
      });

      dispatch(setProjectComponents(projectComponents));
      // }
    }

    return () => {
      source.cancel();
    };
  }, [project]);

  useEffect(() => {
    if (!isEmpty(projects)) {
      const values = projects?.map((item) => {
        const prefix = item.program.prefix_type;
        return {
          ...item,
          short_code: `${prefix}${item.ministry_id} ${item.program.code} ${item.main_project.code}${item.code}`,
        };
        return prefix;
      });
      setOptions(values);
    }
  }, [projects]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const vireInProject = application?.virement_projects?.find(
      (item) => item.virement_type === "VI",
    );

    const ministryCode = vireInProject?.project?.ministry.code;
    const programCode = vireInProject?.project?.program.code;

    const props = {
      source: source,
      params: {
        ministry_code: ministryCode,
        program_code: programCode,
      },
    };

    dispatch(getProjectBasicInfo(props));

    return () => {
      source.cancel();
    };
  }, [application]);

  useEffect(() => {
    const sum = virementOutProjects
      ?.flatMap((item) => item?.financial_details)
      ?.reduce(
        (acc, item) => (acc += parseFloat(item?.virement_out_amount)),
        0,
      );

    setTotalVirementOutAmount(sum);
  }, [virementOutProjects]);

  if (projectIsLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <div className='mb-3'>
        <h4 className='mb-3'>
          {routeParams.category === "penyenaraian-projek-baharu" &&
            "Daftar Projek Baharu"}
          {routeParams.category === "kenaikan-siling" && "Maklumat Virement"}
          {routeParams.category === "perubahan-skop" && "Perubahan Skop"}
          {routeParams.category === "perubahan-nama-projek" &&
            "Perubahan Nama Projek"}
        </h4>
        <div className='table-responsive'>
          <Table bordered size='sm' className='mb-0'>
            <tbody>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Kod
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? application?.virement_projects?.find(
                        (item) => item.virement_type === "VI",
                      )?.project?.sub_project_full_code
                    : project?.sub_project_full_code}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Agensi Pelaksana
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? `${
                        application?.virement_projects?.find(
                          (item) => item.virement_type === "VI",
                        )?.project?.executing_agency?.name
                      } (${
                        application?.virement_projects?.find(
                          (item) => item.virement_type === "VI",
                        )?.project?.executing_agency?.short_form
                      })`
                    : `${project?.executing_agency?.value} (${project?.executing_agency?.short_form})`}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Nama Projek
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? application?.virement_projects?.find(
                        (item) => item.virement_type === "VI",
                      )?.project?.name
                    : project?.name}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Kategori Projek
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? application?.virement_projects?.find(
                        (item) => item.virement_type === "VI",
                      )?.project?.project_category?.name
                    : project?.project_category?.value}
                </td>
              </tr>

              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                  rowSpan={draft?.project_components?.length + 1}
                >
                  Komponen Projek
                </th>
                <td style={{ backgroundColor: "#eceff1" }}>Nama Komponen</td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eceff1" }}
                >
                  Siling Asal {currentRmk?.short_form} (RM)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eceff1" }}
                >
                  Siling Disemak {currentRmk?.short_form} (RM)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eceff1" }}
                >
                  Tambahan Siling (RM)
                </td>
              </tr>

              {draft?.project_components?.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td style={{ fontWeight: 700 }}>{item.cost_description}</td>
                    <td className='text-end'>
                      {numeral(item?.ceiling).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(item?.revised_ceiling).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      <Form.Group>
                        <Form.Control
                          className={`text-end text-truncate`}
                          as={CurrencyInput}
                          placeholder='0.00'
                          decimalsLimit={2}
                          decimalScale={2}
                          value={item?.additional_ceiling}
                          onValueChange={(value) => {
                            const newValues = {
                              rowId: item.id,
                              additional_ceiling: value,
                            };
                            dispatch(setRevisedCeiling(newValues));
                          }}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Jumlah Siling (RM)
                </th>
                <td />
                <td className='text-end'>
                  {numeral(
                    draft?.project_components?.reduce(
                      (acc, item) => (acc += parseFloat(item?.ceiling)),
                      0,
                    ),
                  ).format("0,0.00")}
                </td>
                <td className='text-end'>
                  {numeral(
                    draft?.project_components?.reduce(
                      (acc, item) => (acc += parseFloat(item?.revised_ceiling)),
                      0,
                    ),
                  ).format("0,0.00")}
                </td>
                <td className='text-end'>
                  {numeral(
                    draft?.project_components?.reduce(
                      (acc, item) =>
                        (acc += parseFloat(item?.additional_ceiling)),
                      0,
                    ),
                  ).format("0,0.00")}
                </td>
              </tr>

              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Jumlah Siling Terkumpul Daripada Sumber Kewangan (RM)
                </th>

                <td colSpan={4}>
                  {numeral(totalVirementOutAmount).format("0,0.00")}
                </td>
              </tr>
            </tbody>
          </Table>

          {/* VIREMENT OUT PROJECTS */}
          <Table bordered size='sm'>
            <tbody className=''>
              <tr style={{ backgroundColor: "#e0e0e0" }}>
                <td colSpan={3} className='text-uppercase fw-bolder'>
                  Sila pilih Sumber Kewangan{" "}
                  <span style={{ color: "red" }}>
                    (SILA ABAIKAN JIKA TIDAK MEMPUNYAI SUMBER)
                  </span>
                </td>
                <td className='text-end'>
                  <Button onClick={handleAddSource}>+ Sumber</Button>
                </td>
              </tr>
              {virementOutProjects?.map((item, idx) => {
                const totalRevisedAllocationByRmk = item?.s3p_data?.reduce(
                  (acc, obj) =>
                    (acc += parseFloat(obj?.revised_total_allocation)),
                  0,
                );

                const eLejerByRmk = item?.s3p_data
                  ?.flatMap((s3p) => s3p.overall_financial_data_by_months)
                  .reduce((acc, obj) => (acc += parseFloat(obj?.lejar)), 0);

                const balanceFund =
                  parseFloat(totalRevisedAllocationByRmk) -
                  parseFloat(eLejerByRmk);

                const totalRevisedCeilingByRmk =
                  item?.financial_details?.reduce(
                    (acc, obj) => (acc += parseFloat(obj.revised_ceiling)),
                    0,
                  );

                const totalBalanceCeilingByRmk =
                  totalRevisedCeilingByRmk - eLejerByRmk;

                return (
                  <React.Fragment key={idx}>
                    <tr>
                      <th className='align-middle' style={{ width: "25%" }}>
                        Projek Kod
                      </th>
                      <td colSpan={3}>
                        <div className='d-flex align-items-center'>
                          <Form.Group className='flex-grow-1'>
                            <Form.Select
                              value={item?.id}
                              onChange={(e) => {
                                const value = e.target.value;
                                handleSelectSource(value, idx);
                              }}
                            >
                              <option value=''>Pilih</option>
                              {options.map((item, idx) => (
                                <option key={idx} value={item.id}>
                                  {item.short_code}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <div className='ps-3 pe-2'>
                            <div
                              onClick={() => handleRemoveSource(idx)}
                              style={{ cursor: "pointer" }}
                            >
                              <Icon
                                path={mdiCloseCircle}
                                size='22px'
                                color={palette.danger}
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    {item ? (
                      <>
                        <tr>
                          <th>Nama Projek</th>
                          <td colSpan={3}>{item?.name}</td>
                        </tr>
                        {/* <tr>
                          <th className='align-middle' style={{ width: "25%" }}>
                            Jumlah Siling Disemak {currentRmk?.short_form} (RM)
                          </th>
                          <td colSpan={4}>
                            {numeral(
                              item?.financial_details?.reduce(
                                (acc, item) =>
                                  (acc += parseFloat(item?.revised_ceiling)),
                                0,
                              ),
                            ).format("0,0.00")}
                          </td>
                        </tr> */}

                        <tr>
                          <th className='align-middle' style={{ width: "25%" }}>
                            Jumlah Baki Siling {currentRmk?.short_form} (RM)
                          </th>
                          <td colSpan={3}>
                            {numeral(totalBalanceCeilingByRmk).format("0,0.00")}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className='border-end-0 align-middle'
                            style={{ width: "25%" }}
                            rowSpan={item?.financial_details?.length + 1}
                          >
                            Komponen Projek
                          </th>
                          <td
                            colSpan={2}
                            style={{ backgroundColor: "#eceff1" }}
                          >
                            Nama Komponen
                          </td>
                          {/* <td
                            className='text-center'
                            style={{ backgroundColor: "#eceff1" }}
                          >
                            Siling Disemak
                            <span className='ms-1'>
                              {item?.rmk?.short_form}
                            </span>{" "}
                            (RM)
                          </td> */}
                          <td
                            className='text-center'
                            style={{ backgroundColor: "#eceff1" }}
                          >
                            Siling Dipindah (RM)
                          </td>
                        </tr>

                        {item?.financial_details?.map((subItem, subIdx) => {
                          return (
                            <tr key={subIdx}>
                              <td colSpan={2}>{subItem?.cost_description}</td>
                              {/* <td className='text-end'>
                                {numeral(subItem?.revised_ceiling).format(
                                  "0,0.00",
                                )}
                              </td> */}
                              <td className='text-end'>
                                <Form.Group>
                                  <Form.Control
                                    className={`text-end text-truncate`}
                                    as={CurrencyInput}
                                    placeholder='0.00'
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    value={subItem?.virement_out_amount}
                                    onValueChange={(value) => {
                                      const newValues = {
                                        parentIdx: idx,
                                        childIdx: subIdx,
                                        virement_out_amount: value,
                                      };
                                      dispatch(
                                        changeVirementOutAmount(newValues),
                                      );
                                    }}
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                          );
                        })}
                        <tr
                          style={{
                            borderTop: "double",
                            borderBottom: "double",
                          }}
                        >
                          <th
                            className='border-end-0 align-middle'
                            style={{ width: "25%" }}
                          >
                            Jumlah Siling
                          </th>
                          <td colSpan={2} />
                          <td className='text-end pe-3'>
                            {numeral(
                              item?.financial_details?.reduce(
                                (acc, item) =>
                                  (acc += parseFloat(
                                    item?.virement_out_amount,
                                  )),
                                0,
                              ),
                            ).format("0,0.00")}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td />
                        <td colSpan={4}>Sila Pilih Kod Projek</td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button onClick={nextPage}>Seterusnya</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VirementInformation;
