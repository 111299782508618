import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { debounce, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Components
import LoadingState from "../../../components/common/LoadingState";
import Projects from "./components/tables/Projects";
import Filters from "./components/Filters";
import MoreSearchField from "./components/MoreSearchField";
import TotalProgressStatus from "./components/TotalProgressStatus";

// Redux
import {
  fetchProjects,
  setSearchStatus,
  setSelectDraft,
} from "../../../redux/slices/reports/projectReportsSlice";
import { getTotalProgressStatusToDate } from "../../../redux/slices/reports/projectReportSlice";
import { getOptions, getBranches } from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const navigate = useNavigate();

  const { stages } = routeParams;

  const [showCanvas, setShowCanvas] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [columnNo, setColumnNo] = useState(null);
  const [clickCount, setClickCount] = useState(1);
  const [sortKey, setSortKey] = useState(null);
  const [sortValue, setSortValue] = useState(null);

  const [trigger, setTrigger] = useState(false);
  const [searchFields, setSearchFields] = useState(null);

  const [yearValue, setYearValue] = useState(new Date().getFullYear());

  const {
    currentRmk,
    searchMinistry,
    searchDepartment,
    searchBranch,
    searchStatus,
    keywords,
  } = useSelector((state) => ({
    currentRmk: state.rmkReducer.activeRmk,
    searchMinistry: state.reportReducer.searchMinistry,
    searchDepartment: state.reportReducer.searchDepartment,
    searchBranch: state.reportReducer.searchBranch,
    searchStatus: state.reportReducer.searchStatus,
    keywords: state.reportReducer.projects.keywords,
  }));

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleHideCanvas = () => {
    setShowCanvas(false);
  };

  const handleSearchMoreField = (values) => {
    // setSearchFields(values);
    setTrigger((prev) => !prev); // Toggle the state
  };

  const handleSort = (column, value, columnNo) => {
    if (clickCount === 3) {
      setClickCount(1);
    } else {
      setClickCount(clickCount + 1);
    }

    setColumnNo(columnNo);
    setSortKey(column);
    setSortValue(value);
  };

  const handleFilterStatus = (status) => {
    dispatch(setSearchStatus(status));
  };

  const handleReset = () => {
    dispatch(setSearchStatus(null));
    dispatch(setSelectDraft(null));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        rmk_id: currentRmk.number,
        sortKey: sortKey,
        sortValue: sortValue,
        keywords: keywords,
        ministry_id: searchMinistry?.code,
        department_id: searchDepartment?.id,
        branch_id: searchBranch?.id,
        year: yearValue,
        status: searchStatus,
      },
    };

    let navigateUrl = "/laporan/senarai-projek";

    if (!isEmpty(searchMinistry)) {
      navigateUrl += `?kementerian=${searchMinistry.code}`;
    }

    if (!isEmpty(searchDepartment)) {
      navigateUrl += `${navigateUrl.includes("?") ? "&" : "?"}jabatan=${
        searchDepartment.id
      }`;
    }

    if (!isEmpty(searchBranch)) {
      navigateUrl += `${navigateUrl.includes("?") ? "&" : "?"}cawangan=${
        searchBranch.id
      }`;
    }

    navigate(navigateUrl);

    dispatch(fetchProjects(props));
    dispatch(getTotalProgressStatusToDate(props));

    return () => {
      source.cancel();
    };
  }, [dispatch, currentRmk, sortValue, trigger, searchStatus]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(getOptions(props));
    dispatch(getBranches(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <MoreSearchField
        showCanvas={showCanvas}
        closeCanvas={handleHideCanvas}
        searchMoreField={handleSearchMoreField}
      />

      <Helmet title='Senarai Laporan Projek' />

      <Container fluid className='p-0'>
        <Breadcrumbs title='Laporan' breadcrumbItem='Senarai Projek' />

        <TotalProgressStatus filterStatus={handleFilterStatus} />

        <Card className='mt-3'>
          <Card.Body>
            <Filters
              handleShowCanvas={handleShowCanvas}
              yearValue={yearValue}
              setYearValue={(value) => {
                setYearValue(value);
              }}
              handleReset={handleReset}
            />
            <Projects
              handleSort={handleSort}
              clickCount={clickCount}
              sortKey={sortKey}
              columnNo={columnNo}
              yearValue={yearValue}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
