import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Card, Col, Form, Nav, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Tooltip } from "@mui/material";
import usePalette from "../../hooks/usePalette";
import LoadingState from "../../components/common/LoadingState";

// Icons
import Icon from "@mdi/react";
import { mdiRestore, mdiChevronLeft } from "@mdi/js";

// Components
import OverallReport from "./components/overall-report/OverallReport";
import CombineReport from "./components/combine-report/CombineReport";

// redux
import { setDashboardTab } from "../../redux/slices/utilsSlice";
import {
  setNavFilter,
  setSelectDepartment,
  setSelectMinistry,
  setSelectProgram,
  setSelectYear,
  setSearchProgressStatus,
} from "../../redux/slices/dashboard/dashboardSlice";

const PageContent = ({ changeRmk, rmkValue, yearOptions }) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [showCanvas, setShowCanvas] = useState(false);

  const { isLoading, dashboardTab, selectYear, rmkItems, navFilter } =
    useSelector((state) => ({
      dashboardTab: state.utilsReducer.tabValue.dashboardTab,
      rmkItems: state.utilsReducer.dropdownItems.rmkItems,
      selectYear: state.dashboardReducer.selectYear,
      isLoading: state.dashboardReducer.isLoading,
      navFilter: state.dashboardReducer.navFilter,
    }));

  const container = {
    show: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, x: -20 },
    show: { opacity: 1, x: 0 },
  };

  const handleChangeTab = (value) => {
    dispatch(setDashboardTab(value));
  };
  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleNavFilter = () => {
    if (navFilter === "department") {
      dispatch(setNavFilter("ministry"));
      dispatch(setSelectMinistry(null));
    }
    if (navFilter === "program") {
      dispatch(setNavFilter("department"));
      dispatch(setSelectDepartment(null));
    }
    if (navFilter === "main-project") {
      dispatch(setNavFilter("program"));
      dispatch(setSelectProgram(null));
    }
  };

  const handleResetDashboard = () => {
    dispatch(setNavFilter("ministry"));
    dispatch(setSelectMinistry(null));
    dispatch(setSelectDepartment(null));
    dispatch(setSelectProgram(null));
    dispatch(setSearchProgressStatus(null));
  };

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between'>
        <div>
          <h3>Dashboard Pemantauan Projek Pembangunan Negeri Sabah</h3>
        </div>
        {selectYear && (
          <div>
            <h1 className='fw-bolder'>TAHUN {selectYear?.label}</h1>
          </div>
        )}
      </div>
      <div className='d-flex gap-3'>
        <Form.Group style={{ width: 150 }}>
          <Select
            className='react-select-container'
            classNamePrefix='react-select'
            placeholder='Pilih RMK'
            options={rmkItems}
            value={rmkValue}
            onChange={(value) => changeRmk(value)}
            isDisabled={isLoading}
          />
        </Form.Group>
        <Form.Group style={{ width: 150 }}>
          <Select
            className='react-select-container'
            classNamePrefix='react-select'
            placeholder='Pilih Tahun'
            isClearable
            options={yearOptions}
            value={selectYear}
            onChange={(value) => dispatch(setSelectYear(value))}
            isDisabled={isLoading}
          />
        </Form.Group>
        <div className='align-self-center'>
          <div onClick={handleNavFilter} style={{ cursor: "pointer" }}>
            <Icon path={mdiChevronLeft} size={1.2} />
          </div>
        </div>
        <div className='align-self-center'>
          <div onClick={handleResetDashboard} style={{ cursor: "pointer" }}>
            <Icon path={mdiRestore} size={1} />
          </div>
        </div>
      </div>
      <div className='my-3 d-flex'>
        <Nav
          as={motion.div}
          variants={container}
          initial='hidden'
          animate='show'
          variant='pills'
          activeKey={dashboardTab}
          onSelect={handleChangeTab}
        >
          <motion.div variants={item}>
            <Nav.Item className='me-3'>
              <Nav.Link as='button' eventKey={0} style={{ borderRadius: 20 }}>
                <span style={{ fontWeight: 700, fontSize: 12 }}>
                  MAKLUMAT KESELURUHAN
                </span>
              </Nav.Link>
            </Nav.Item>
          </motion.div>

          <motion.div variants={item}>
            <Nav.Item className='me-3'>
              <Nav.Link as='button' eventKey={1} style={{ borderRadius: 20 }}>
                <span style={{ fontWeight: 700, fontSize: 12 }}>
                  MAKLUMAT KEWANGAN & FIZIKAL
                </span>
              </Nav.Link>
            </Nav.Item>
          </motion.div>
        </Nav>
      </div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {dashboardTab === "0" && <OverallReport rmkValue={rmkValue} />}
          {dashboardTab === "1" && <CombineReport />}
        </>
      )}
    </React.Fragment>
  );
};

export default PageContent;
