import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

// Icons
import Icon from "@mdi/react";
import { mdiViewList, mdiDelete, mdiPencil } from "@mdi/js";

const Ministries = ({
  handleEditItem,
  handleRemoveItem,
  handleViewChildren,
}) => {
  const { isLoading, ministries } = useSelector((state) => ({
    isLoading: state.ministryReducer.isLoading,
    ministries: state.ministryReducer.ministries,
  }));

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead
            className='custom-table-head text-uppercase'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th className='text-center'>Bil.</th>
              <th></th>
              <th>Nama Kementerian</th>
              <th>Kod</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {ministries.map((row, idx) => (
              <tr key={row.id}>
                <td className='text-center'>{idx + 1}.</td>
                <td>{row.short_form}</td>
                <td>{row.name}</td>
                <td>{row.code}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Tooltip
                      title='Lihat Jabatan dan Agensi'
                      placement='top'
                      arrow
                    >
                      <IconButton
                        color='info'
                        size='small'
                        aria-label='Lihat Jabatan dan Agensi'
                        onClick={() => handleViewChildren(row)}
                      >
                        <Icon path={mdiViewList} size={0.8} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Edit' placement='top' arrow>
                      <IconButton
                        color='info'
                        size='small'
                        aria-label='Edit'
                        onClick={() => handleEditItem(row)}
                      >
                        <Icon path={mdiPencil} size={0.8} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Padam' placement='top' arrow>
                      <IconButton
                        color='error'
                        size='small'
                        aria-label='Padam'
                        onClick={() => handleRemoveItem(row)}
                      >
                        <Icon path={mdiDelete} size={0.8} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default Ministries;
