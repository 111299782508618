import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";

//Page content
import PageContent from "./PageContent";

const MainPage = () => {
  return (
    <React.Fragment>
      <Helmet title='Tetapan Siling' />
      <Container fluid className='p-0'>
        <Breadcrumbs title='Maklumat RMK' breadcrumbItem='Tetapan Siling' />

        <PageContent />
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
