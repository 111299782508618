import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchExecutingAgencies = createAsyncThunk(
  "executingAgency/fetchExecutingAgencies",
  async (params) => {
    try {
      const response = await axios.get(`/executing-agencies`, { params });
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createExecutingAgency = createAsyncThunk(
  "executingAgency/createExecutingAgency",
  async (values) => {
    const response = await axios.post(`/executing-agencies`, values);

    const data = await response.data;

    return data;
  },
);

export const updateExecutingAgency = createAsyncThunk(
  "executingAgency/updateExecutingAgency",
  async (values) => {
    try {
      const { id } = values;

      const response = await axios.put(`/executing-agencies/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeExecutingAgency = createAsyncThunk(
  "executingAgency/removeExecutingAgency",
  async (id) => {
    const response = await axios.delete(`/executing-agencies/${id}`);

    return id;
  },
);

const initialState = {
  executingAgencies: [],
  links: {},
  meta: {},
  isLoading: false,
  btnLoading: false,
};

export const executingAgencySlice = createSlice({
  name: "executingAgency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExecutingAgencies.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchExecutingAgencies.fulfilled, (state, action) => {
      const { meta, links, data } = action.payload;
      state.executingAgencies = data;
      state.links = links;
      state.meta = meta;
      state.isLoading = false;
    });

    builder.addCase(createExecutingAgency.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createExecutingAgency.fulfilled, (state, action) => {
      state.executingAgencies.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateExecutingAgency.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateExecutingAgency.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.executingAgencies = state.executingAgencies.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeExecutingAgency.pending, (state, action) => {
      state.btnLoading = true;
    });

    builder.addCase(removeExecutingAgency.fulfilled, (state, action) => {
      state.executingAgencies = state.executingAgencies.filter(
        (item) => item.id !== action.payload,
      );

      state.btnLoading = false;
    });
  },
});

export default executingAgencySlice.reducer;
