import React, { useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";

// components
import TableUlitities from "./TableUlitities";
import { getMinistries } from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = () => {
      const props = {
        source: source,
      };

      dispatch(getMinistries(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Container fluid className='p-0'>
        <Card>
          <Card.Body>
            <TableUlitities />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
