import React from "react";
import { Card } from "react-bootstrap";

// components
import Projects from "./components/Projects";
import Filters from "./components/Filters";

const PageContent = () => {
  return (
    <Card>
      <Card.Header className='pb-0'>
        <Filters />
      </Card.Header>
      <Card.Body>
        <Projects />
      </Card.Body>
    </Card>
  );
};

export default PageContent;
