import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

// Components
import OverallFinancialReport from "../charts/OverallFinancialReport";
import FinancialReportByScope from "../tables/FinancialReportByScope";

const FinancialReportSummary = ({ itemLeft }) => {
  const [yearOptions, setYearOptions] = useState([]);
  const [yearValue, setYearValue] = useState("");
  const [s3pData, setS3pData] = useState([]);

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  const handleChangeYear = (e) => {
    const value = e.target.value;

    setYearValue(value);

    const financialInfoByYear = project.s3p_data.filter(
      (item) => item.year.toString() === value.toString(),
    );

    setS3pData(financialInfoByYear);
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const { rmk } = project;

      const { first_year, second_year, third_year, fourth_year, fifth_year } =
        rmk;

      setYearOptions([
        first_year,
        second_year,
        third_year,
        fourth_year,
        fifth_year,
      ]);
    }

    const currentYear = new Date().getFullYear();

    setYearValue(currentYear);
  }, [project]);

  useEffect(() => {
    setYearValue(new Date().getFullYear());
  }, []);

  return (
    <React.Fragment>
      <Card as={motion.div} variants={itemLeft} transition={{ duration: 0.5 }}>
        <Card.Body>
          <div className='d-flex justify-content-center gap-2'>
            <div className='h-100'>
              <h4 className='text-uppercase'>Laporan Kewangan TAHUN</h4>
            </div>

            <Form.Group>
              <Form.Select
                value={yearValue}
                onChange={(e) => handleChangeYear(e)}
              >
                {yearOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>

          <Row>
            <Col md={4}>
              <OverallFinancialReport s3pData={s3pData} yearValue={yearValue} />
            </Col>
            <Col md={8}>
              <FinancialReportByScope s3pData={s3pData} yearValue={yearValue} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default FinancialReportSummary;
