import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { isEmpty } from "lodash";

const CreditScoreCriteria = ({ handleEditItem, handleRemoveItem }) => {
  const { criteriaList } = useSelector((state) => ({
    criteriaList: state.creditScoreCriteriaReducer.criteriaList,
  }));

  return (
    <div className='table-responsive'>
      <Table style={{ width: "100%" }}>
        <thead
          className='text-uppercase custom-table-head'
          style={{ fontSize: 11 }}
        >
          <tr>
            <th className='text-center' style={{ width: "5%" }}>
              Bil.
            </th>
            <th>Kriteria</th>
            <th>Keterangan</th>
            <th>Skor kredit (%)</th>
            <th className='text-center'>Tindakan</th>
          </tr>
        </thead>
        <tbody>
          {isEmpty(criteriaList) ? (
            <tr>
              <td colSpan={5} className='text-center'>
                Tiada maklumat tersedia
              </td>
            </tr>
          ) : (
            <>
              {criteriaList?.map((row, idx) => {
                return (
                  <tr key={idx}>
                    <td className='text-center'>{idx + 1}</td>
                    <td>{row.name}</td>
                    <td>{row.description}</td>
                    <td>{row.score}</td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Tooltip title='Edit' placement='top' arrow>
                          <IconButton
                            color='info'
                            size='small'
                            aria-label='Edit'
                            onClick={() => handleEditItem(row)}
                          >
                            <MdModeEditOutline fontSize='large' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Padam' placement='top' arrow>
                          <IconButton
                            color='error'
                            size='small'
                            aria-label='Padam'
                            onClick={() => handleRemoveItem(row.id)}
                          >
                            <MdDelete fontSize='large' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CreditScoreCriteria;
