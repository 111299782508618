import React, { useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";

// content
import PageContent from "./PageContent";

// redux
import {
  getApprovedProjects,
  getApprovedProjectsByDistricts,
  getApprovedProjectsByDun,
  getApprovedProjectsByParliaments,
} from "../../../redux/slices/micro-project/microProjectSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {},
      };

      await dispatch(getApprovedProjects(props));
      await dispatch(getApprovedProjectsByDistricts(props));
      await dispatch(getApprovedProjectsByDun(props));
      await dispatch(getApprovedProjectsByParliaments(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Container fluid className='p-0'>
        <h3>Dashboard</h3>
        <PageContent />
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
