import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const ExpandFinancialDetails = ({ show, onHide }) => {
  const { data } = useSelector((state) => ({
    data: state.dashboardReducer.overall.charts.financialReportByMinistries,
  }));

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        size='xl'
        centered
        keyboard={false}
        backdrop='static'
      >
        <Modal.Header className='border-0'>
          <h4 className='text-uppercase' style={{ fontWeight: 700 }}>
            Prestasi Perbelanjaan Keseluruhan
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className='table-responsive'>
            <Table>
              <thead className='text-uppercase'>
                <tr>
                  <th>Agensi</th>
                  <th className='text-center'>
                    Peruntukan
                    <div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Peruntukan Disemak<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Perbelanjaan<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Baki Peruntukan<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Perbelanjaan + Terkini<div>(RM)</div>
                  </th>
                  <th className='text-center'>
                    Baki Terkini<div>(RM)</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td className='text-end'>1</td>
                  <td className='text-end'>1</td>
                  <td className='text-end'>1</td>
                  <td className='text-end'>1</td>
                  <td className='text-end'>1</td>
                  <td className='text-end'>1</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex border-0'>
          <Button variant='light' onClick={onHide}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ExpandFinancialDetails;
