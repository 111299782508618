import React, { useContext, useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import NotyfContext from "../../../contexts/NotyfContext";
import LoadingState from "../../../components/common/LoadingState";

// components
import ProjectInformation from "./components/ProjectInformation";

// redux
import {
  getDistrictItems,
  getMainProjects,
  getParliamentItems,
  getPrograms,
  getStateLegislativeAssembly,
} from "../../../redux/slices/utilsSlice";
import { getMicroProject } from "../../../redux/slices/micro-project/microProjectSlice";

const ApplicationForm = ({ sendNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const notyf = useContext(NotyfContext);

  const { ministry, id } = routeParams;

  const [tab, setTab] = useState(1);

  const isLoading = useSelector((state) => state.microProjectReducer.isLoading);

  const handleNextTab = (num) => {
    setTab(num);
  };

  const handlePrevTab = () => {
    setTab(tab - 1);
  };

  const getOptions = async (props) => {
    await dispatch(getPrograms(props)).then(async (res) => {
      let params1 = null;

      if (props.params.ministry_id === "11") {
        const program = res.payload.find((item) => item.code === "000400");
        const { id } = program;

        params1 = {
          source: props.source,
          params: {
            program_id: id,
          },
        };
      }

      if (props.params.ministry_id === "19") {
        const program = await res.payload[0];
        const { id } = program;

        params1 = {
          source: props.source,
          params: {
            program_id: id,
          },
        };
      }

      await dispatch(getMainProjects(params1));
    });
  };

  // Notification
  const handleNotification = () => {
    // Success
    notyf.open({
      type: "default",
      message: `Draf permohonan telah dicipta.`,
      duration: 3000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        ministry_id: ministry,
      },
    };

    const newValues = {
      ministry_id: ministry,
    };

    const initialize = () => {
      getOptions(props);
      dispatch(getDistrictItems(props));
      dispatch(getParliamentItems(props));
      dispatch(getStateLegislativeAssembly(props));
      dispatch(getMicroProject(id));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Container
        fluid
        className='p-0'
        style={{ minHeight: "100%", height: "100%" }}
      >
        <h3 className='mb-3'>
          Permohonan Peruntukan Di Bawah{" "}
          <span>
            {routeParams.ministry === "11" &&
              "Projek Pembangunan Khas Jabatan Ketua Menteri"}
            {routeParams.ministry === "19" &&
              "Kementerian Pembangunan Luar Bandar"}
          </span>
        </h3>
        {isLoading ? (
          <LoadingState />
        ) : (
          <ProjectInformation sendNotification={handleNotification} />
        )}
      </Container>
    </React.Fragment>
  );
};

export default ApplicationForm;
