import React, { useEffect, useState } from "react";
import { Table, Tab } from "react-bootstrap";
import numeral from "numeral";
import usePalette from "../../../../../hooks/usePalette";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../ConfirmDelete";

// icons
import Icon from "@mdi/react";
import {
  mdiAlertCircle,
  mdiCheckCircle,
  mdiPencil,
  mdiTrashCan,
} from "@mdi/js";

// Pagination
import TablePagination from "./TablePagination";

// redux
import { deleteNewProjectApplication } from "../../../../../redux/slices/jppn/jppnApplyNewProjectSlice";

const ApplyNewProjects = ({ eventKey }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const palette = usePalette();

  const [data, setData] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const { appliedProjects } = useSelector((state) => ({
    appliedProjects: state.jppnReducer.apply_new_project.applied_projects,
  }));

  const handleViewApplication = (item) => {
    const { id } = item;
    navigate(`/jppn/daftar-projek-baharu/${id}`);
  };

  const handleDeleteApplication = async () => {
    await dispatch(deleteNewProjectApplication(idToDelete));
    setIdToDelete(null);
    setShowConfirmDelete(false);
  };

  return (
    <React.Fragment>
      <ConfirmDelete
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        destroy={handleDeleteApplication}
      />

      <Tab.Pane eventKey={eventKey}>
        <h4 className='mb-3'>Senarai Permohonan Projek Baharu</h4>
        <div className='table-responsive'>
          <Table size='sm' bordered>
            <thead className='custom-table-head text-uppercase'>
              <tr>
                <th className='text-center'>Bil.</th>
                <th className='text-center'>No. Rujukan</th>
                <th style={{ width: 350 }}>Nama Projek</th>
                <th>Dikomen Oleh Kementerian</th>
                <th>Dikomen Oleh MOF</th>
                <th>Dikomen Oleh UPEN</th>
                <th className='text-center'>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(appliedProjects) && (
                <tr>
                  <td colSpan={7} className='text-center'>
                    Tiada maklumat tersedia
                  </td>
                </tr>
              )}
              {!isEmpty(appliedProjects) && (
                <>
                  {appliedProjects?.map((item, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <tr>
                          <td className='text-center'>{idx + 1}</td>
                          <td className='text-center'>
                            {item?.reference || "-"}
                          </td>
                          <td>
                            {item.project?.name ||
                              "Projek terlibat adalah berikut:"}
                          </td>
                          <td className='text-center'>
                            {!isEmpty(item.ministry_comments) && (
                              <Icon
                                path={mdiCheckCircle}
                                size='18px'
                                color={palette.success}
                              />
                            )}
                            {isEmpty(item.ministry_comments) && (
                              <Icon
                                path={mdiAlertCircle}
                                size='18px'
                                color={palette.warning}
                              />
                            )}
                          </td>
                          <td className='text-center'>
                            {!isEmpty(item.mof_comments) && (
                              <Icon
                                path={mdiCheckCircle}
                                size='18px'
                                color={palette.success}
                              />
                            )}
                            {isEmpty(item.mof_comments) && (
                              <Icon
                                path={mdiAlertCircle}
                                size='18px'
                                color={palette.warning}
                              />
                            )}
                          </td>
                          <td className='text-center'>
                            {!isEmpty(item.upen_comments) && (
                              <Icon
                                path={mdiCheckCircle}
                                size='18px'
                                color={palette.success}
                              />
                            )}
                            {isEmpty(item.upen_comments) && (
                              <Icon
                                path={mdiAlertCircle}
                                size='18px'
                                color={palette.warning}
                              />
                            )}
                          </td>
                          <td>
                            <div className='d-flex justify-content-center gap-3'>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleViewApplication(item)}
                              >
                                <Icon
                                  path={mdiPencil}
                                  color={palette.primary}
                                  size='20px'
                                />
                              </div>
                              {isNull(item?.approved_at) && (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setShowConfirmDelete(true);
                                    setIdToDelete(item.id);
                                  }}
                                >
                                  <Icon
                                    path={mdiTrashCan}
                                    color={palette.danger}
                                    size='20px'
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
          <TablePagination />
        </div>
      </Tab.Pane>
    </React.Fragment>
  );
};

export default ApplyNewProjects;
