import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const updateCertify = createAsyncThunk(
  "certify/certifyComponents",
  async (values) => {
    try {
      const { projectId } = values;
      const response = await axios.put(
        `/project/certify-components/${projectId}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const rejectProject = createAsyncThunk(
  "certify/rejectProject",
  async (id) => {
    try {
      const response = await axios.post(`project/${id}/reject`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);
