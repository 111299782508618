import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import SidebarFooter from "./SidebarFooter";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import Sabah from "../../assets/img/photos/sabah-logo.png";
import SMEPPS from "../../assets/img/photos/logo-no-background.svg";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();

  return (
    <nav
      className={`sidebar ${!isOpen ? "collapsed" : ""}`}
      style={{ zIndex: 9 }}
    >
      <div className='sidebar-content'>
        <PerfectScrollbar>
          <a className='sidebar-brand' href='/'>
            <img src={Sabah} style={{ height: "auto", width: 35 }} />
            <span className='align-middle ms-2'>
              SMEPPS<span style={{ fontSize: 8 }}></span>
            </span>
          </a>
          <SidebarNav items={items} />
          {/* <SidebarFooter /> */}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
