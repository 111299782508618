import React from "react";
import { Card } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import numeral from "numeral";
import { useSelector } from "react-redux";

import Img from "../../../../../../assets/img/illustrations/financial-info-2.png";

const LatestExpenditure = ({ itemLeft }) => {
  const { selectYear, latestExpenditure } = useSelector((state) => ({
    latestExpenditure: state.dashboardReducer.overall.cards.latestExpenditure,
    selectYear: state.dashboardReducer.selectYear,
  }));
  return (
    <React.Fragment>
      <Card
        as={motion.div}
        variants={itemLeft}
        transition={{ duration: 0.3 }}
        className='flex-fill'
        style={{ height: 200 }}
      >
        <Card.Body className='d-flex position-relative'>
          <div className='ps-3 align-self-center  flex-fill'>
            <p className='text-muted fw-bold fs-3 mb-2'>PERBELANJAAN TERKINI</p>
            <Card.Text
              className='fs-2'
              style={{ fontSize: 18, fontWeight: 700 }}
            >
              {`RM ${numeral(latestExpenditure).format("0,0.00")}`}
            </Card.Text>
          </div>
          <div className='position-absolute' style={{ bottom: 2, right: 2 }}>
            <img
              src={Img}
              alt='Customer Support'
              className='img-fluid illustration-img opacity-75'
            />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default LatestExpenditure;
