import React from "react";
import { Button, Tab } from "react-bootstrap";

const AddiotionalInfo = ({ eventKey, openExpenditureForm }) => {
  return (
    <Tab.Pane eventKey={eventKey}>
      <div className='d-flex justify-content-center'>
        <h5>Pilihan</h5>
      </div>
      <div className='d-flex justify-content-center py-2'>
        <Button className='px-4' variant='light' onClick={openExpenditureForm}>
          KEMASKINI PERBELANJAAN
        </Button>
      </div>
    </Tab.Pane>
  );
};

export default AddiotionalInfo;
