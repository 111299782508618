import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchRmkList = createAsyncThunk("rmk/fetchRmkList", async () => {
  const response = await axios.get(`/rmk`);

  const data = await response.data;

  return data;
});

export const createRmk = createAsyncThunk("rmk/createRmk", async (values) => {
  const response = await axios.post(`/rmk`, values);

  const data = await response.data;

  return data;
});

export const updateRmk = createAsyncThunk("rmk/updateRmk", async (values) => {
  const response = await axios.put(`/rmk/${values.id}`, values);

  const data = await response.data;

  return data;
});

export const removeRmk = createAsyncThunk("rmk/removeRmk", async (id) => {
  const response = await axios.delete(`/rmk/${id}`);

  return id;
});

export const activatedRmk = createAsyncThunk(
  "rmk/activatedRmk",
  async (values) => {
    const response = await axios.put(`/rmk/set-active/${values.id}`);

    const data = await response.data;

    return data;
  },
);

export const getActiveRmk = createAsyncThunk("rmk/getActiveRmk", async () => {
  try {
    const response = await axios.get(`/rmk/active`);

    const data = await response.data;

    return data;
  } catch (error) {
    console.error(error);
  }
});

const initialState = {
  rmkList: [],
  activeRmk: {},
  isLoading: true,
  btnLoading: false,
};

export const rmkSlice = createSlice({
  name: "rmk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRmkList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRmkList.fulfilled, (state, action) => {
      state.rmkList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(activatedRmk.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.rmkList = state.rmkList.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return { ...item, active: 0 };
      });
      state.isLoading = false;
    });

    builder.addCase(createRmk.pending, (state) => {
      state.btnLoading = true;
    });

    builder.addCase(createRmk.fulfilled, (state, action) => {
      state.rmkList.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateRmk.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateRmk.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.rmkList = state.rmkList.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeRmk.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeRmk.fulfilled, (state, action) => {
      state.rmkList = state.rmkList.filter(
        (item) => item.id !== action.payload,
      );
      state.btnLoading = false;
    });

    builder.addCase(getActiveRmk.fulfilled, (state, action) => {
      state.activeRmk = action.payload;
      state.isLoading = false;
    });
  },
});

export default rmkSlice.reducer;
