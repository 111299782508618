import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";

//Styles
import { customStyles } from "../../../../components/common/customStyleSelect";

const Filters = ({
  handleFilterRmk,
  rmkFilter,
  handleFilterMinistry,
  ministryFilter,
}) => {
  const { rmkItems, ministries, activeRmk } = useSelector((state) => ({
    rmkItems: state.utilsReducer.dropdownItems.rmkItems,
    ministries: state.utilsReducer.dropdownItems.ministries,
    activeRmk: state.rmkReducer.activeRmk,
  }));

  return (
    <div className='d-flex gap-2'>
      <Form.Group>
        <Form.Group>
          <Select
            className='react-select-container'
            classNamePrefix='react-select'
            isSearchable
            options={rmkItems}
            styles={customStyles}
            placeholder='Pilih rmk...'
            value={rmkFilter}
            onChange={(value) => handleFilterRmk(value)}
            noOptionsMessage={() => "Tiada pilihan"}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group style={{ width: 400 }}>
        <Select
          className='react-select-container'
          classNamePrefix='react-select'
          isSearchable
          isClearable
          options={ministries}
          styles={customStyles}
          placeholder='Semua Kementerian...'
          value={ministryFilter}
          onChange={(value) => handleFilterMinistry(value)}
          noOptionsMessage={() => "Tiada pilihan"}
        />
      </Form.Group>
    </div>
  );
};

export default Filters;
