import React, { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ModalDelete from "../../../components/common/ModalDelete";
import { useNavigate } from "react-router-dom";

// Icons
import { MdSearch } from "react-icons/md";

// components
import Users from "./components/Users";

// redux
import {
  fetchMicroProjectUsers,
  removeUser,
} from "../../../redux/slices/admin-micro-project/userSlice";
import { setKeywords } from "../../../redux/slices/admin-micro-project/usersSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showRemove, setShowRemove] = useState(false);
  const [item, setItem] = useState(null);
  const [goToPage, setGoToPage] = useState("");

  const { users, btnLoading } = useSelector((state) => ({
    users: state.microProjectUserReducer.users,
    btnLoading: state.microProjectUserReducer.btnLoading,
  }));

  const { data, page, perPage, keywords } = users;

  const handleAddUser = () => {
    navigate(`/projek-mikro/admin/pengguna/baharu`);
  };

  const handleRemoveUser = (user) => {
    setShowRemove(true);
    setItem(user);
  };

  const handleEditUser = (user) => {
    navigate(`/projek-mikro/admin/pengguna/kemaskini/${user.id}`);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeUser(data));
    handleCloseRemove();
  };

  const handleSearch = (text) => {
    dispatch(setKeywords(text));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {
          per_page: perPage,
          page: page,
          keywords: keywords,
          is_micro_project_admin: true,
        },
      };

      await dispatch(fetchMicroProjectUsers(props));
    };
    initialize();

    setGoToPage(page);

    return () => {
      source.cancel();
    };
  }, [dispatch, page, perPage, keywords]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
        module='user'
      />
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
        module='user'
      />
      <Helmet title='Senarai Pengguna' />
      <Container fluid className='p-0'>
        <Card>
          <Card.Header className='d-flex'>
            <div className='flex-grow-1'>
              <h3>Senarai Pengguna</h3>
            </div>
            <Button onClick={handleAddUser}>+ Pengguna</Button>
          </Card.Header>
          <Card.Body>
            <div className='d-flex align-items-center gap-3 mb-3'>
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari...'
                      value={keywords}
                      onChange={(e) => handleSearch(e.target.value)}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>
              {/* <div>
                <Tooltip title='Tunjuk pengguna tidak aktif' arrow>
                  <Button
                    variant={`${notActive ? "danger" : "outline-danger"}`}
                    onClick={handleViewNotActive}
                  >
                    Tidak Aktif
                  </Button>
                </Tooltip>
              </div> */}
            </div>
            <Users
              data={data}
              handleRemoveUser={handleRemoveUser}
              handleEditUser={handleEditUser}
              goToPage={goToPage}
              setGoToPage={setGoToPage}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
