import { useFormik } from "formik";
import React from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import Select from "react-select";

const MoreSearchField = ({ showCanvas, closeCanvas }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      district: "",
      state_legislative_assembly: "",
      parliament: "",
      departments: [],
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleClose = () => {
    closeCanvas();
  };

  const handleSearch = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  const handleResetField = () => {
    formik.resetForm();
  };

  return (
    <Offcanvas show={showCanvas} onHide={handleClose} style={{ width: 310 }}>
      <Offcanvas.Body>
        <Offcanvas.Title className="mb-3">Carian Tambahan</Offcanvas.Title>
        <Form autoComplete="off" noValidate style={{ fontSize: 12 }}>
          <Form.Group className="mb-3">
            <Form.Label>Daerah</Form.Label>
            <Form.Control
              type="text"
              name="district"
              value={formik.values.district}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Parlimen</Form.Label>
            <Form.Control type="text" name="parliament" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Dewan Undangan Negeri</Form.Label>
            <Form.Control type="text" name="state_legislative_assembly" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Jabatan/Agensi</Form.Label>
            <Select
              placeholder=""
              className="react-select-container"
              classNamePrefix="react-select"
              isMulti
              options={[
                {
                  id: 1,
                  label: "JKR",
                  value: "JKR",
                },
                {
                  id: 2,
                  label: "UPEN",
                  value: "UPEN",
                },
              ]}
            />
          </Form.Group>
          <div className="d-flex flex-column gap-3">
            <Button
              type="submit"
              variant="primary"
              className="flex-fill"
              onClick={handleSearch}
            >
              Cari
            </Button>
            <Button
              variant="light"
              className="flex-fill"
              onClick={handleResetField}
            >
              Set Semula
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MoreSearchField;
