import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import Select from "react-select";

import {
  customStyles,
  customStylesForm,
} from "../../../../../components/common/customStyleSelect";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// redux
import {
  createCeilingLimit,
  updateCeilingLimit,
} from "../../../../../redux/slices/rmk-information/ceilingLimitsSlice";

const CeilingForm = ({ showForm, closeForm, data, isEdit }) => {
  const dispatch = useDispatch();

  const [rmkOptions, setRmkOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { rmkItems, ministries, departments, btnLoading } = useSelector(
    (state) => ({
      btnLoading: state.ceilingLimitReducer.btnLoading,
      rmkItems: state.utilsReducer.dropdownItems.rmkItems,
      ministries: state.utilsReducer.dropdownItems.ministries,
      departments: state.utilsReducer.dropdownItems.departments,
    }),
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rmk: null,
      ministry: null,
      department: null,
      ceiling: "",
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          rmk_id: values.rmk.id,
          ministry_id: Number(values.ministry.code),
          department_id: values.department.id,
          ceiling: values.ceiling,
        };
        await dispatch(updateCeilingLimit(newValues));
      } else {
        const newValues = {
          rmk_id: values.rmk?.id || null,
          ministry_id: Number(values.ministry?.code) || null,
          department_id: values.department?.id || null,
          ceiling: values.ceiling,
        };
        await dispatch(createCeilingLimit(newValues));
      }
      handleCloseForm();
    },
  });

  const handleCloseForm = () => {
    closeForm();
    formik.resetForm();
  };

  const handleSelectMinistry = (value) => {
    formik.setFieldValue("ministry", value);

    if (isEmpty(value)) {
      setDepartmentOptions([]);
      formik.setFieldValue("department", null);
      return;
    }
    const ministry_code = Number(value?.code);
    const getDepartments = departments.filter(
      (item) => item.ministry_code === ministry_code,
    );

    setDepartmentOptions(getDepartments);
  };

  return (
    <Modal centered show={showForm} onHide={handleCloseForm}>
      <Modal.Body>
        <div>
          <p className='text-muted text-sm0'>
            Tetapkan siling mengikut apa yang telah dipersetujui.
          </p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>RMK</Form.Label>
            <Select
              name='rmk'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              options={rmkItems}
              styles={customStylesForm}
              placeholder='Pilih'
              value={formik.values.rmk}
              onChange={(value) => formik.setFieldValue("rmk", value)}
              noOptionsMessage={() => "Tiada pilihan"}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Kementerian</Form.Label>
            <Select
              name='ministry'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              options={ministries}
              styles={customStylesForm}
              placeholder='Pilih'
              value={formik.values.ministry}
              onChange={(value) => handleSelectMinistry(value)}
              noOptionsMessage={() => "Tiada pilihan"}
              placeholder='Pilih'
              isClearable
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Jabatan / Agensi (Jika Berkaitan)</Form.Label>
            <Select
              name='department'
              className='react-select-container'
              classNamePrefix='react-select'
              isSearchable
              options={departmentOptions}
              styles={customStylesForm}
              placeholder='Pilih'
              value={formik.values.department}
              onChange={(value) => formik.setFieldValue("department", value)}
              noOptionsMessage={() => "Tiada pilihan"}
              placeholder='Pilih'
              isClearable
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Maksimum Siling</Form.Label>
            <InputGroup>
              <InputGroup.Text id='basic-addon1'>RM</InputGroup.Text>
              <Form.Control
                as={CurrencyInput}
                min={0}
                name='ceiling'
                autoComplete='off'
                decimalsLimit={2}
                onValueChange={(value) => {
                  formik.setFieldValue("ceiling", parseFloat(value));
                }}
              />
            </InputGroup>
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              className='text-uppercase'
              variant='light'
              onClick={handleCloseForm}
              disabled={btnLoading}
            >
              Batal
            </Button>
            <Button
              className='px-3'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span
                  className='text-uppercase'
                  style={{ marginLeft: 2.5, marginRight: 2.5 }}
                >
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CeilingForm;
