import { isEmpty } from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const SourceOfCeiling = () => {
  const [virementInProject, setVirementInProject] = useState("");
  const [additionalCeiling, setAdditionalCeiling] = useState(0);
  const [initialCeilingRmk, setInitialCeilingRmk] = useState(0);
  const [revisedCeilingRmk, setRevisedCeilingRmk] = useState(0);

  const { project, currentRmk, application, draft, virementOutProjects } =
    useSelector((state) => ({
      project: state.projectReducer.project,
      currentRmk: state.rmkReducer.activeRmk,

      application: state.jppnReducer.application,
      draft: state.jppnReducer.draft,
      virementOutProjects: state.jppnReducer.virement.virementOutProjects,
    }));

  useEffect(() => {
    // const project = application?.virement_projects[0]?.project;
    const project = application?.virement_projects?.find(
      (item) => item.type === "VI",
    )?.project;
    const nameAndCode = `${project?.name} (${project?.program?.prefix_type}${project?.ministry?.code} ${project?.program?.code}
      ${project?.main_project?.code}${project?.code})`;

    setVirementInProject(nameAndCode);
  }, [application]);

  useEffect(() => {
    if (isEmpty(draft)) return false;

    const sumAdditionalCeiling = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.additional_ceiling)),
      0,
    );

    setAdditionalCeiling(sumAdditionalCeiling);

    const sumInitialCeilingRmk = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.ceiling)),
      0,
    );
    const sumRevisedCeilingRmk = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.revised_ceiling)),
      0,
    );

    setInitialCeilingRmk(sumInitialCeilingRmk);
    setRevisedCeilingRmk(
      parseFloat(sumInitialCeilingRmk) + parseFloat(sumAdditionalCeiling),
    );
  }, [draft]);

  useEffect(() => {
    if (isEmpty(virementOutProjects)) return false;
  }, [virementOutProjects]);

  return (
    <React.Fragment>
      <h4 className='text-center'>Jadual 1 :</h4>
      <h4 className='text-center px-4 mb-3'>
        {!isEmpty(virementOutProjects)
          ? ` Kedudukan Siling ${`Tajuk`} dan ${
              project?.name
            } Selepas Pindahan Siling`
          : `Kedudukan Siling ${project?.name} Selepas Pindahan Siling`}
      </h4>
      <div className='table-responsive'>
        <Table bordered size='sm'>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center align-middle' style={{ width: "40%" }}>
                Nama Projek
              </th>
              <th>
                Siling Asal
                <br />
                <span
                  className='text-truncate me-1'
                  style={{ textTransform: "none" }}
                >
                  {currentRmk?.short_form}
                </span>
                (RM)
              </th>
              <th>Siling Disemak (RM)</th>
              <th className='text-center'>
                Siling dikurangkan(-) atau ditambah(+) <br /> (RM)
              </th>
              <th>Siling Baharu (RM)</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(virementOutProjects) && (
              <>
                <tr>
                  <td colSpan={5} style={{ fontWeight: 700 }}>
                    Daripada :
                  </td>
                </tr>

                {virementOutProjects?.map((item, idx) => {
                  const initialCeiling = item?.financial_details?.reduce(
                    (acc, scope) => (acc += parseFloat(scope.ceiling)),
                    0,
                  );

                  const virementOutAmount = item?.financial_details?.reduce(
                    (acc, flow) =>
                      (acc += parseFloat(flow?.virement_out_amount)),
                    0,
                  );

                  const revisedCeiling = item?.financial_details?.reduce(
                    (acc, scope) => (acc += parseFloat(scope.revised_ceiling)),
                    0,
                  );

                  const code = `${item?.program?.prefix_type}${item?.ministry?.code} ${item?.program?.code} ${item?.main_project?.code}${item?.code}`;

                  const totalELejarByRmk = item?.s3p_data
                    ?.flatMap((obj) => obj.overall_financial_data_by_months)
                    ?.reduce((acc, obj) => (acc += parseFloat(obj?.lejar)), 0);

                  const currentCeiling = initialCeiling - totalELejarByRmk;

                  const newRevisedCeiling =
                    parseFloat(currentCeiling) -
                      parseFloat(virementOutAmount) || 0;

                  return (
                    <tr key={idx}>
                      <td>
                        {item?.name} ({code})
                      </td>
                      <td className='text-end'>
                        {numeral(initialCeiling).format("0,0.00")}
                      </td>
                      <td className='text-end'>
                        {numeral(currentCeiling).format("0,0.00")}
                      </td>
                      <td className='text-center'>
                        (-){numeral(virementOutAmount).format("0,0")}
                      </td>
                      <td className='text-end'>
                        {numeral(newRevisedCeiling).format("0,0.00")}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}

            {!isEmpty(virementOutProjects) && (
              <tr>
                <td colSpan={5} style={{ fontWeight: 700 }}>
                  Kepada :
                </td>
              </tr>
            )}

            <tr>
              <td>
                {!isEmpty(project) ? (
                  <>
                    {project?.name} ({project?.program?.prefix}
                    {project?.ministry?.code} {project?.program?.code}{" "}
                    {project?.main_project?.code}
                    {project?.code})
                  </>
                ) : (
                  virementInProject
                )}
              </td>
              <td className='text-end'>
                {numeral(initialCeilingRmk).format("0,0.00")}
              </td>
              <td className='text-end'>
                {numeral(initialCeilingRmk).format("0,0.00")}
              </td>
              <td className='text-center'>
                (+)
                {numeral(additionalCeiling).format("0,0")}
              </td>
              <td className='text-end'>
                {numeral(revisedCeilingRmk).format("0,0.00")}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default SourceOfCeiling;
