import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";

// Styles
import { customStyles } from "../../../../../../../components/common/customStyleSelect";
import NotyfContext from "../../../../../../../contexts/NotyfContext";
import usePalette from "../../../../../../../hooks/usePalette";

// Icons
import { MdCheckCircle } from "react-icons/md";

// Map
import MapForm from "../maps/MapForm";

// Redux
import { updateProject } from "../../../../../../../redux/slices/projects/projectSlice";

const SiteInformationForm = ({
  handleChecked,
  passedSteps,
  handleExpand,
  enableBtnSave,
}) => {
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const palette = usePalette();

  const [stateLegislativeAssemblyOptions, setStateLegislativeAssemblyOptions] =
    useState([]);
  const [disabledInput, setDisabledInput] = useState(false);

  const { project, parliamentItems, districtItems } = useSelector((state) => ({
    project: state.projectReducer.project,
    districtItems: state.utilsReducer.dropdownItems.districtItems,
    parliamentItems: state.utilsReducer.dropdownItems.parliamentItems,
  }));

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      parliament: project.site_informations?.parliament || null,
      state_legislative_assembly:
        project.site_informations?.state_legislative_assembly || null,
      district: project.site_informations?.district || null,
      geran: project.site_informations?.geran || "",
      reference_no: project.site_informations?.reference_no || "",
      site_ownership: project.site_informations?.site_ownership || "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values) => {
      const newValues = {
        id: project.uuid,
        site_information: {
          site_information_id: project.site_informations?.id || null,
          project_id: project.id,
          district_id: values.district?.id || null,
          geran: values.geran,
          parliament_id: values.parliament?.id || null,
          state_legislative_assembly_id:
            values.state_legislative_assembly?.id || null,
          reference_no: values.reference_no,
          site_ownership: values.site_ownership,
        },
      };
      await dispatch(updateProject(newValues));
      handleChecked(4);
      handleNotification("success");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  const handleSelectParliament = (value) => {
    formik.setFieldValue("parliament", value);

    if (isEmpty(value)) {
      setStateLegislativeAssemblyOptions([]);
      formik.setFieldValue("state_legislative_assembly", null);
      return;
    }
    const options = value.state_legislative_assemblies.map((item) => {
      return {
        ...item,
        label: `${item.code} - ${item.name}`,
        value: `${item.code} - ${item.name}`,
      };
    });

    setStateLegislativeAssemblyOptions(options);
  };

  // Notification
  const handleNotification = (type, values) => {
    // Errors
    if (type === "danger") {
      const errors = Object.values(values);

      errors.map((item) => {
        const strMsg = item;

        notyf.open({
          type: type,
          message: strMsg,
          duration: 7000,
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "top",
          },
        });
      });
      return false;
    }

    // Success
    notyf.open({
      type: type,
      message: "Maklumat lokasi projek berjaya disimpan.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    if (isEmpty(project)) return;

    const isApproved = project?.application_status.toString() === "4";
    setDisabledInput(isApproved);
  }, [project]);

  return (
    <React.Fragment>
      <Accordion.Header
        bsPrefix='success-border accordion-header'
        onClick={() => handleExpand(4)}
      >
        {(passedSteps || []).includes(4) ? (
          <MdCheckCircle className='me-2' size={29} color={palette.success} />
        ) : (
          <span className='number'>4.</span>
        )}
        Maklumat Lokasi Projek{" "}
        {project.project_type_id === 3 && "(Tidak diperlukan)"}
      </Accordion.Header>
      <Accordion.Body className='p-0'>
        <Card className='mb-0'>
          <Card.Body className='m-0'>
            <Card.Text className='text-sm'>
              Sila isi maklumat lokasi projek.
            </Card.Text>
            <Form noValidate autoComplete='off'>
              <Row>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Parlimen</Form.Label>
                    <Select
                      isSearchable
                      isClearable
                      name='parliament'
                      options={parliamentItems}
                      placeholder='Pilih...'
                      value={formik.values.parliament}
                      onChange={(value) => handleSelectParliament(value)}
                      noOptionsMessage={() => "Tiada pilihan"}
                      isDisabled={disabledInput}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#ced4da",
                          backgroundColor: state.isDisabled
                            ? "#e2e8ee"
                            : "#FFF",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Dewan Undangan Negeri</Form.Label>
                    <Select
                      isSearchable
                      name='state_legislative_assembly'
                      options={stateLegislativeAssemblyOptions}
                      placeholder='Pilih...'
                      value={formik.values.state_legislative_assembly}
                      onChange={(value) =>
                        formik.setFieldValue(
                          "state_legislative_assembly",
                          value,
                        )
                      }
                      noOptionsMessage={() => "Tiada pilihan"}
                      isDisabled={disabledInput}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#ced4da",
                          backgroundColor: state.isDisabled
                            ? "#e2e8ee"
                            : "#FFF",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Daerah</Form.Label>
                    <Select
                      isSearchable
                      name='district'
                      options={districtItems}
                      placeholder='Pilih...'
                      value={formik.values.district}
                      onChange={(value) =>
                        formik.setFieldValue("district", value)
                      }
                      isDisabled={disabledInput}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#ced4da",
                          backgroundColor: state.isDisabled
                            ? "#e2e8ee"
                            : "#FFF",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isDisabled
                            ? "rgb(84, 84, 84)"
                            : "#495057",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Geran</Form.Label>
                    <Form.Control
                      type='text'
                      name='geran'
                      value={formik.values.geran}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabledInput}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      No. Fail Rujukan MyeTAPP/JKPTG/No. Hak Milik
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='reference_no'
                      value={formik.values.reference_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabledInput}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group as={Row} className='mb-3'>
                <Col sm={3}>
                  <Form.Label>Pemilikan Tapak</Form.Label>
                </Col>
                <Col sm={9}>
                  <Form.Check
                    inline
                    type='radio'
                    id='ownership_yes'
                    name='site_ownership'
                    label='Ya'
                    className='mb-2'
                    value='true'
                    checked={formik.values.site_ownership === "true"}
                    onChange={(e) =>
                      formik.setFieldValue("site_ownership", e.target.value)
                    }
                    disabled={disabledInput}
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='ownership_no'
                    name='project_design'
                    label='Tidak'
                    className='mb-2'
                    value='false'
                    checked={formik.values.site_ownership === "false"}
                    onChange={(e) =>
                      formik.setFieldValue("site_ownership", e.target.value)
                    }
                    disabled={disabledInput}
                  />
                </Col>
              </Form.Group>
            </Form>

            {/* <MapForm /> */}

            {!disabledInput && (
              <div className='d-flex justify-content-end'>
                <Button
                  type='submit'
                  variant='primary'
                  onClick={handleSubmit}
                  // disabled={(passedSteps || []).includes(2) ? false : true}
                >
                  Simpan
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default SiteInformationForm;
