import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, Form, Modal, Card } from "react-bootstrap";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import usePalette from "../../../../../hooks/usePalette";
import format from "date-fns/format";

// Icons
import Icon from "@mdi/react";
import {
  mdiSend,
  mdiDotsHorizontal,
  mdiDelete,
  mdiPencil,
  mdiClose,
  mdiTextBoxRemoveOutline,
  mdiLoading
} from "@mdi/js";

// Components
import ModalDelete from "../../../../../components/common/ModalDelete";

// Redux
import {
  createFinancialDetailNote,
  updateFinancialDetailNote,
  removeFinancialDetailNote,
  createCeilingFlowNote,
  updateCeilingFlowNote,
  removeCeilingFlowNote,
} from "../../../../../redux/slices/projects/financialDetailsSlice";

const NotesSection = ({ openNotes, handleCloseNotes, data, section }) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const notesContainer = useRef(null);

  const [textValue, setTextValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [notes, setNotes] = useState([]);

  const [openRemove, setOpenRemove] = useState(false);

  const toolbar = [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "blockquote",
    "underline",
    { list: "ordered" },
    { list: "bullet" },
  ];

  const { user, btnLoading, project } = useSelector((state) => ({
    user: state.userReducer.user,

    project: state.projectReducer.project,
    btnLoading: state.projectReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: "",
    },
    onSubmit: async (values) => {
      if (section === "financial") {
        if (isEdit) {
          const newValues = {
            id: item.id,
            financial_detail_id: item.financial_detail_id,
            note: values.note,
            write_by: user.id,
          }
          await dispatch(updateFinancialDetailNote(newValues))
          handleCancelEdit()
        } else {
          const newValues = {
            financial_detail_id: data.id,
            note: values.note,
            write_by: user.id,
          }
          await dispatch(createFinancialDetailNote(newValues))
          formik.resetForm();
        }
      } else {
        if (isEdit) {
          const newValues = {
            id: item.id,
            financial_detail_id: data.financial_detail_id,
            ceiling_flow_detail_id: data.id,
            note: values.note,
            write_by: user.id,
          };
          await dispatch(updateCeilingFlowNote(newValues))
          handleCancelEdit()
        } else {
          const newValues = {
            financial_detail_id: data.financial_detail_id,
            ceiling_flow_detail_id: data.id,
            note: values.note,
            write_by: user.id,
          };
          await dispatch(createCeilingFlowNote(newValues));
          formik.resetForm();
        }

      }
    },
  });

  const handleEditNote = (item) => {
    const { note } = item
    setIsEdit(true)
    setItem(item);
    formik.setFieldValue('note', note)
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    setItem({});
    formik.resetForm();
  }

  const handleRemoveNote = (item) => {
    setOpenRemove(true);
    setItem(item);
  }

  const handleConfirmRemove = async (item) => {
    if (section === "financial") {
      const newValues = {
        id: item.id,
        financial_detail_id: item.financial_detail_id,
      }
      await dispatch(removeFinancialDetailNote(newValues))
    } else {
      const newValues = {
        id: item.id,
        ceiling_flow_detail_id: item.ceiling_flow_detail_id,
        financial_detail_id: data.financial_detail_id,
      };
      await dispatch(removeCeilingFlowNote(newValues));
    }
    handleCloseRemove();
  };

  const handleCloseRemove = () => {
    setOpenRemove(false);
    setItem({});
  };

  const closeModalNote = () => {
    handleCloseNotes()
    setItem({});
    setIsEdit(false)
    formik.resetForm()
  }

  const scrollToMyRef = () => {
    const scroll =
      notesContainer.current.scrollHeight - notesContainer.current.clientHeight;
    notesContainer.current.scrollTo(0, scroll);
  };

  useEffect(() => {
    let isMounted = true;
    if (section === "financial") {
      const financialDetails = project?.financial_details?.find(
        (item) => item?.id === data?.id
      );

      if (financialDetails && isMounted) {
        setNotes(financialDetails.financial_detail_notes);
      }
    } else {
      const ceilingFlow = project?.financial_details
        ?.flatMap((obj1) => obj1.ceiling_flow_details)
        .find((item) => item.id === data.id);

      if (ceilingFlow && isMounted) {
        setNotes(ceilingFlow.ceiling_flow_detail_notes);
      }
    }
    return () => {
      isMounted = false; // update the mount state to false when the component is unmounted
    };
  }, [project, openNotes]);

  useEffect(() => {
    if (openNotes) {
      scrollToMyRef();
    }
  }, [notes]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={openRemove}
        closeRemove={handleCloseRemove}
        confirmRemove={handleConfirmRemove}
        title="Padam catatan?"
        message="Catatan akan dipadam sepenuhnya."
        data={item}
        btnLoading={btnLoading}
      />

      <Modal
        show={openNotes}
        onHide={closeModalNote}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className={`${openRemove ? "bg-modal-black" : ""}`}>
          <div className="mb-3">
            <div className="card-actions float-end">
              <Tooltip title="Tutup Catatan" arrow>
                <IconButton className="p-0" onClick={closeModalNote}>
                  <Icon path={mdiClose} size={0.9} />
                </IconButton>
              </Tooltip>
            </div>
            <Card.Title className="mb-0">Catatan</Card.Title>
          </div>
          <div
            ref={notesContainer}
            className="px-2"
            style={{ overflowY: "auto", maxHeight: 420 }}
          >
            {isEmpty(notes) ? (
              <div className="text-center">
                <Icon path={mdiTextBoxRemoveOutline} size={3} />
                <p className="mb-0">Belum ada catatan.</p>
                <p>Sila tambah catatan jika perlu.</p>
              </div>
            ) : (
              <React.Fragment>
                {notes?.map((item) => (
                  <React.Fragment key={item.id}>
                    <div className="position-relative">

                      {user.id === item.write_by.id && (
                        <Dropdown
                          align="end"
                          className="position-absolute"
                          style={{ right: 0, top: 0 }}
                        >
                          <Dropdown.Toggle as="a" bsPrefix="-">
                            <Icon path={mdiDotsHorizontal} size={1} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{ minWidth: 66 }}
                            className="py-0"
                          >
                            <Dropdown.Item
                              className="text-center"
                              onClick={() => handleEditNote(item)}
                            >
                              <Icon
                                path={mdiPencil}
                                size={0.7}
                                color={palette.primary}
                              />
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-center"
                              onClick={() => handleRemoveNote(item)}
                            >
                              <Icon
                                path={mdiDelete}
                                size={0.7}
                                color={palette.danger}
                              />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                      <div className="d-flex justify-content-between">
                        <div className="text-sm">
                          {!isEmpty(item.write_by) ? (
                            <React.Fragment>
                              <p className="mb-0">
                                <span className="fw-bolder">{item.write_by.name}</span>
                                {item.write_by.roles.some(role => ["superadmin", "admin"].includes(role)) && (
                                  <span className="ms-1">(Admin)</span>
                                )}
                              </p>
                              <p className="mb-0 text-sm text-muted">
                                {item.write_by.department?.name}
                              </p>
                              <p className="mb-0 text-sm text-muted">
                                {!isEmpty(item.write_by.department) ? item.write_by.ministry?.short_form : item.write_by.ministry?.name}
                              </p>
                            </React.Fragment>
                          ) : (
                            "Pegguna tidak ditemui"
                          )}
                        </div>
                        <div className="text-end align-self-end text-sm">
                          {!isEmpty(item.updated_at) &&
                            format(
                              new Date(item.updated_at),
                              "dd MMM yyyy, hh:mm a"
                            )}
                        </div>
                      </div>
                      <div>{item.note && parse(item?.note)}</div>
                    </div>
                    <Divider variant="middle my-3" />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </div>
          <div className="mt-3">
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Form.Group className="mb-2">
                <Form.Label>{isEdit ? "Kemaskini" : "Tambah"} Catatan</Form.Label>
                <ReactQuill
                  name="note"
                  placeholder="Maklumat catatan"
                  modules={{ toolbar: toolbar }}
                  value={formik.values.note}
                  onChange={(value) => formik.setFieldValue("note", value)}
                />
              </Form.Group>
              <div className="d-flex justify-content-between">
                <Button size="sm" variant="light" onClick={closeModalNote}>
                  Tutup Catatan
                </Button>
                <div>
                  {isEdit && (
                    <IconButton className="me-2" size="small" onClick={handleCancelEdit}>
                      <Icon path={mdiClose} size={0.9} color={palette.danger} />
                    </IconButton>
                  )}
                  {btnLoading ? (
                    <Icon
                      className="mx-3"
                      path={mdiLoading}
                      size={0.8}
                      spin={true}
                    />
                  ) : (
                    <IconButton size="small" type="submit">
                      <Icon path={mdiSend} size={0.9} color={palette.success} />
                    </IconButton>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default NotesSection;
