import React from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

// Icons
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

// Styles
import usePalette from "../../../../../../hooks/usePalette";
import { customStylesForm } from "../../../../../../components/common/customStyleSelect";

// Redux
import {
  createParliament,
  updateParliament,
} from "../../../../../../redux/slices/state-information/parliamentsSlice";

const ParliamentForm = ({
  showForm,
  closeForm,
  isEdit,
  data,
  handleNotification,
}) => {
  const dispatch = useDispatch();

  const { btnLoading } = useSelector((state) => ({
    btnLoading: state.parliamentReducer.btnLoading,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      code: data.code || "",
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: data.id,
          ...values,
        };
        await dispatch(updateParliament(newValues));
        handleNotification("edited", newValues);
      } else {
        await dispatch(createParliament(values));
        handleNotification("created", values);
      }
      handleCloseModal();
    },
  });

  const handleCloseModal = () => {
    closeForm();
  };
  return (
    <Modal show={showForm} onHide={closeForm} centered>
      <Modal.Body>
        <div>
          <Card.Title className='mb-0'>Tambah Parlimen</Card.Title>
          <p className='text-sm'>Sila isi maklumat yang berkaitan.</p>
        </div>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Form.Group className='mb-3'>
            <Form.Label>Nama Parlimen</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Kod</Form.Label>
            <Form.Control
              type='text'
              name='code'
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            <Button
              className='text-uppercase'
              variant='light'
              onClick={handleCloseModal}
            >
              Batal
            </Button>
            <Button
              className='px-3 text-uppercase'
              variant='success'
              type='submit'
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Icon
                  className='mx-3'
                  path={mdiLoading}
                  size={0.8}
                  spin={true}
                />
              ) : (
                <span style={{ marginLeft: 2.5, marginRight: 2.5 }}>
                  Simpan
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ParliamentForm;
