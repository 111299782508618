import React from "react";
import { Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

const StateLegislativeAssemblies = ({
  stateLegislativeAssemblies,
  handleEditItem,
  handleRemoveItem,
}) => {
  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table hover size='sm'>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center' style={{ width: "10%" }}>
                Bil.
              </th>
              <th style={{ width: "10%" }}></th>
              <th>Dewan Undangan Negeri (DUN)</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {stateLegislativeAssemblies.map((item, idx) => (
              <tr key={item.id}>
                <td className='text-center'>{idx + 1}.</td>
                <td className='text-center'>{item.code}</td>
                <td>{item.name}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Tooltip title='Edit' placement='top' arrow>
                      <IconButton
                        color='info'
                        size='small'
                        aria-label='Edit'
                        onClick={() => handleEditItem(item)}
                      >
                        <MdModeEditOutline fontSize='large' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Padam' placement='top' arrow>
                      <IconButton
                        color='error'
                        size='small'
                        aria-label='Padam'
                        onClick={() => handleRemoveItem(item)}
                      >
                        <MdDelete fontSize='large' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default StateLegislativeAssemblies;
