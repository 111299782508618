import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Badge, Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiSquareRounded } from "@mdi/js";
import numeral from "numeral";
import { format } from "date-fns";
import { ms } from "date-fns/locale";

const RadialBar = () => {
  const [series, setSeries] = useState([]);
  const [seriesLegend, setSeriesLegend] = useState({});

  const { project, progressStatusOptions } = useSelector((state) => ({
    project: state.reportReducer.project,
    progressStatusOptions:
      state.utilsReducer.dropdownItems.progressStatusOptions,
  }));

  const options = {
    chart: {
      height: 280,
      type: "radialBar",
    },
    stroke: {
      lineCap: "round",
    },
    colors: ["#2196f3", "#4caf50"],
    labels: ["Fizikal", "Kewangan"],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          startAngle: -135,
          endAngle: 135,
        },
      },
    },
  };

  useEffect(() => {
    if (isEmpty(project)) return false;
    const { reports, s3p_data } = project;

    const physicalLatestReport =
      reports?.filter((item) => item.is_sent === true)[0] || null;

    const financialReportCurrentYear = s3p_data.find(
      (item) => item.year === new Date().getFullYear() || null,
    );

    const totalExpenditure = financialReportCurrentYear?.monthly_info.reduce(
      (acc, item) => (acc += parseFloat(item.lejar)),
      0,
    );

    const expenditurePercentage =
      (totalExpenditure /
        parseFloat(financialReportCurrentYear?.revised_total_allocation)) *
        100 || 0;

    setSeries([
      physicalLatestReport?.progress || 0,
      expenditurePercentage?.toFixed(2) || 0,
    ]);

    setSeriesLegend({
      physical: {
        percentage: physicalLatestReport?.progress || 0,
        progressStatus:
          physicalLatestReport &&
          progressStatusOptions.find(
            (item) => item.code === physicalLatestReport?.progress_status,
          ),
        updatedAt:
          physicalLatestReport &&
          format(new Date(physicalLatestReport.updated_at), "dd/MM/yyyy", {
            locale: ms,
          }),
      },
      financial: {
        percentage: expenditurePercentage?.toFixed(2) || 0,
        progressStatus: null,
        updatedAt: !isEmpty(financialReportCurrentYear)
          ? format(
              new Date(financialReportCurrentYear?.updated_at),
              "dd/MM/yyyy",
              {
                locale: ms,
              },
            )
          : null,
      },
    });
  }, [project]);

  return (
    <React.Fragment>
      <Card className='w-100 flex-fill'>
        <Card.Header className='pb-0'>
          <Card.Title className='mb-0'>
            Kemajuan Fizikal dan Kewangan
          </Card.Title>
        </Card.Header>
        <Card.Body className='pt-0'>
          <div id='chart'>
            <ReactApexChart
              options={options}
              series={series}
              type='radialBar'
              height={300}
            />
          </div>
          <div className='mt-3 table-responsive'>
            <Table
              size='sm'
              className='text-uppercase'
              style={{ fontSize: 11 }}
            >
              <thead className=''>
                <tr>
                  <th />
                  <th className='text-center'>Kemajuan</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Dikemas kini pada</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-truncate fw-bolder'>
                    <Icon
                      className='me-1'
                      path={mdiSquareRounded}
                      size={1}
                      color='#2196f3'
                    />
                    Fizikal
                    <span className='ms-1' style={{ textTransform: "none" }}>
                      ({project?.rmk?.short_form})
                    </span>
                  </td>
                  <td className='text-center fw-bolder'>{`${seriesLegend?.physical?.percentage} %`}</td>
                  <td className='text-center'>
                    <Badge
                      bg=''
                      className={`text-center`}
                      style={{
                        fontSize: 10,
                        fontWeight: 700,
                        backgroundColor:
                          seriesLegend?.physical?.progressStatus?.color,
                      }}
                    >
                      {seriesLegend?.physical?.progressStatus?.label}
                    </Badge>
                  </td>
                  <td className='text-center text-truncate'>
                    {seriesLegend?.physical?.updatedAt || "-"}
                  </td>
                </tr>
                <tr>
                  <td className='text-truncate fw-bolder'>
                    <Icon
                      className='me-1'
                      path={mdiSquareRounded}
                      size={1}
                      color='#4caf50'
                    />
                    Kewangan (Tahun
                    <span className='ms-1'>{new Date().getFullYear()}</span>)
                  </td>
                  <td className='text-center fw-bold fw-bolder'>{`${seriesLegend?.financial?.percentage} %`}</td>
                  <td className='text-center'>-</td>
                  <td className='text-center'>
                    {seriesLegend?.financial?.updatedAt || "-"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default RadialBar;
