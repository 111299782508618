import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { Form, Pagination, Table } from "react-bootstrap";
import { SevenColumns } from "../../../../components/common/tableLoading";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

// redux
import {
  setPage,
  setPerPage,
} from "../../../../redux/slices/admin-micro-project/usersSlice";

const Users = ({ handleRemoveUser, handleEditUser, goToPage, setGoToPage }) => {
  const dispatch = useDispatch();

  const { isLoading, users } = useSelector((state) => ({
    users: state.microProjectUserReducer.users,
    isLoading: state.microProjectUserReducer.isLoading,
  }));

  const { data, meta, links, perPage } = users;

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table style={{ width: "100%" }}>
          <thead className='text-uppercase' style={{ fontSize: 12 }}>
            <tr>
              <th className='text-center' style={{ width: "5%" }}>
                Bil.
              </th>
              <th style={{ width: "30%" }}>Nama</th>
              <th>No. MyKad</th>
              <th>Emel</th>
              <th className='text-center'>Daerah</th>
              <th className='text-center'>Status</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading && <SevenColumns />}
          {!isLoading && (
            <tbody>
              {isEmpty(data) && (
                <tr>
                  <td colSpan={7} className='text-center'>
                    Tiada Maklumat Tersedia
                  </td>
                </tr>
              )}
              {!isEmpty(data) && (
                <>
                  {data?.map((item, idx) => {
                    const baseIndex = (meta?.current_page - 1) * meta?.per_page;
                    return (
                      <tr key={idx}>
                        <td className='text-center'>{baseIndex + idx + 1}.</td>
                        <td>{item?.name}</td>
                        <td>{item?.nric}</td>
                        <td>{item?.email}</td>
                        <td className='text-center'>
                          {item?.user_access_to_district?.value}
                        </td>
                        <th className='text-center'>
                          {item.is_deleted ? "Tidak Aktif" : "Aktif"}
                        </th>
                        <td>
                          <div className='d-flex justify-content-center'>
                            <Tooltip title='Edit' placement='top' arrow>
                              <span>
                                <IconButton
                                  color='info'
                                  size='small'
                                  aria-label='Edit'
                                  onClick={() => handleEditUser(item)}
                                >
                                  <MdModeEditOutline fontSize='large' />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title='Padam' placement='top' arrow>
                              <span>
                                <IconButton
                                  color='error'
                                  size='small'
                                  aria-label='Padam'
                                  onClick={() => handleRemoveUser(item)}
                                >
                                  <MdDelete fontSize='large' />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div className='d-flex justify-content-between'>
        <div>
          <span className=''>
            Halaman{" "}
            <strong>
              {meta?.current_page} daripada {meta?.last_page}
            </strong>
            <span className='ms-3 me-2'>Tunjuk:</span>
            <Form.Select
              className='d-inline-block w-auto'
              value={perPage}
              onChange={(e) => {
                const value = e.target.value;

                dispatch(setPerPage(value));
                dispatch(setPage(1));
              }}
            >
              {[15, 30, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
          </span>
          <span className='ms-3 me-2'>Pergi ke halaman:</span>
          <Form.Control
            className='d-inline-block'
            type='number'
            value={goToPage}
            onChange={(e) => {
              const value = e.target.value;
              setGoToPage(value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(setPage(goToPage));
              }
            }}
            style={{ width: "75px" }}
          />
        </div>
        <Pagination>
          <Pagination.First
            onClick={() => {
              dispatch(setPage(1));
            }}
            disabled={links?.prev === null ? true : false}
          />
          <Pagination.Prev
            onClick={() => {
              dispatch(setPage(meta?.current_page - 1));
            }}
            disabled={links?.prev === null ? true : false}
          />
          <Pagination.Next
            onClick={() => {
              dispatch(setPage(meta?.current_page + 1));
            }}
            disabled={links?.next === null ? true : false}
          />
          <Pagination.Last
            onClick={() => {
              dispatch(setPage(meta?.last_page));
            }}
            disabled={links?.next === null ? true : false}
          />
        </Pagination>
      </div>
    </React.Fragment>
  );
};

export default Users;
