import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchUsers,
  newUser,
  userRegistrationResponse,
  removeUser,
} from "./userManagementSlice";

const initialState = {
  users: [],
  isLoading: false,
  btnLoading: false,
  search: {
    ministry: "",
    department: "",
  },
};

export const userManagementSlice = createSlice({
  name: "user-management",
  initialState,
  reducers: {
    setSearchMinistry: (state, action) => {
      state.search.ministry = action.payload;
    },
    setSearchDepartment: (state, action) => {
      state.search.department = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.isLoading = false;
    });
    builder.addCase(newUser.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(newUser.fulfilled, (state, action) => {
      state.users.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(userRegistrationResponse.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(userRegistrationResponse.fulfilled, (state, action) => {
      const { user } = action.payload;

      state.users = state.users.map((item) => {
        if (item.id === user.id) {
          return {
            ...item,
            status: user.status,
            registration_status: user.registration_status,
          };
        }

        return item;
      });

      state.btnLoading = false;
    });

    builder.addCase(removeUser.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.users = state.users.filter(
        (item) => item.id.toString() != id.toString(),
      );

      state.btnLoading = false;
    });
  },
});

export const { setSearchMinistry, setSearchDepartment } =
  userManagementSlice.actions;

export default userManagementSlice.reducer;
