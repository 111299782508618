import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import axios from "axios";
import { isEmpty } from "lodash";

// redux
import { getKPLBOfficers } from "../../../../redux/slices/utilsSlice";
import { setKPLBSection } from "../../../../redux/slices/admin-micro-project/userSlice";

const ModalForm = ({ show, onHide, item }) => {
  const dispatch = useDispatch();

  const [officerValues, setOfficerValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { officers } = useSelector((state) => ({
    officers: state.utilsReducer.dropdownItems.kplb_officers,
  }));

  const handleSave = async () => {
    setIsLoading(true);
    const newValues = {
      main_project_id: item.id,
      officers: officerValues,
    };
    await dispatch(setKPLBSection(newValues));
    onHide();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEmpty(item)) return;

    const values = item?.officers?.map((officer) => ({
      id: officer.id,
      label: officer.name,
      value: officer.name,
    }));
    setOfficerValues(values);
  }, [item]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {},
      };

      await dispatch(getKPLBOfficers(props));
    };
    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        keyboard='false'
        backdrop='static'
        centered
      >
        <Modal.Body>
          <Form autoComplete='off'>
            <Form.Group className='mb-3'>
              <Form.Label>Nama Projek Utama</Form.Label>
              <Form.Control
                name='name'
                type='text'
                defaultValue={item?.name}
                disabled
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Pegawai</Form.Label>
              <Select
                name='users'
                className='react-select-container'
                classNamePrefix='react-select'
                isSearchable
                isMulti
                closeMenuOnSelect={false}
                options={officers}
                placeholder='Pilih pegawai bertugas...'
                noOptionsMessage={() => "Tiada Pilihan"}
                // styles={{
                //   control: (baseStyles, state) => ({
                //     ...baseStyles,
                //     backgroundColor: state.isDisabled ? "#e2e8ee" : "white",
                //   }),
                //   indicatorSeparator: (baseStyles) => ({
                //     ...baseStyles,
                //     width: 0,
                //   }),
                // }}
                value={officerValues}
                onChange={(value) => setOfficerValues(value)}
              />
            </Form.Group>
            <div className='d-flex justify-content-end gap-3'>
              <Button variant='light' onClick={onHide} disabled={isLoading}>
                Batal
              </Button>
              <Button
                variant='success'
                onClick={handleSave}
                disabled={isLoading}
              >
                Simpan
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ModalForm;
