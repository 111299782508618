import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

// Components
import CertifyStages from "./accordion-items/CertifyStages";

const MainAccordion = ({
  handleNotification,
  scopeProjectRef,
  ceilingFlowRef,
  paginations,
  filters,
}) => {
  return (
    <Card className='p-0 mt-0 mb-0'>
      <Accordion className='remove-outline-item' flush defaultActiveKey={[1]}>
        <CertifyStages
          eventKey={1}
          handleNotification={handleNotification}
          scopeProjectRef={scopeProjectRef}
          ceilingFlowRef={ceilingFlowRef}
          paginations={paginations}
          filters={filters}
        />
      </Accordion>
    </Card>
  );
};

export default MainAccordion;
