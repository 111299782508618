import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Components
import LoadingState from "../../../components/common/LoadingState";
import PageContent from "./components/PageContent";

// Redux
import { getProjectDetails } from "../../../redux/slices/certify/projectsSlice";
import {
  setPage,
  setPerPage,
} from "../../../redux/slices/certify/certifySlice";
import { getCreditScoreCriteriaList } from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { projectId } = routeParams;

  const [paginations, setPaginations] = useState({
    page: null,
    perPage: null,
  });
  const [filters, setFilters] = useState({
    stagesFilter: null,
    searchFields: null,
  });

  const { isLoading, page, perPage, stagesFilter, searchFields } = useSelector(
    (state) => ({
      isLoading: state.certifyReducer.isLoading,
      page: state.certifyReducer.paginations.page,
      perPage: state.certifyReducer.paginations.perPage,

      stagesFilter: state.certifyReducer.filters.stagesFilter,
      searchFields: state.certifyReducer.filters.searchFields,
    }),
  );

  useEffect(() => {
    const initialize = async () => {
      await dispatch(getProjectDetails(projectId));
      await dispatch(setPage(1));
      await dispatch(setPerPage(30));
    };

    setPaginations({
      page: page,
      perPage: perPage,
    });
    setFilters({
      stagesFilter: stagesFilter,
      searchFields: searchFields,
    });

    initialize();
  }, [dispatch]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(getCreditScoreCriteriaList(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title='Perakuan Projek' />
      <Container fluid className='p-0'>
        <h1 className='h3 mb-3'>Perakuan Projek</h1>

        {isLoading ? (
          <LoadingState />
        ) : (
          <PageContent paginations={paginations} filters={filters} />
        )}
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
