import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getCerityProjects = createAsyncThunk(
  "certify/getCerityProjects",
  async (props) => {
    const { params, source } = props;
    try {
      const response = await axios.get(`/projects-certify`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProjectDetails = createAsyncThunk(
  "certify/getProjectDetails",
  async (id) => {
    try {
      const response = await axios.get(
        `/projects-certify/get-project/${id}?with=query`,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getCerityStatistics = createAsyncThunk(
  "certify/getCerityStatistics",
  async (props) => {
    const { params, source } = props;
    try {
      const response = await axios.get(`/projects-certify/statistics`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createCreditScore = createAsyncThunk(
  "certify/createCreditScore",
  async (values) => {
    try {
      const response = await axios.post(
        `/projects-certify/credit-score`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      console.error(error);
    }
  },
);
