import React from "react";
import { matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const ChildRoutes = (props) => {
  const { items, page, depth, currentRoute } = props;

  const user = useSelector((state) => state.userReducer.user);

  let isTrue = false;

  if (!isEmpty(user)) {
    const arr1 = page.accessLevel;
    const arr2 = user.roles;

    function checkRoles(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        if (arr2.includes(arr1[i])) {
          return true;
        }
      }
      return false;
    }

    isTrue = checkRoles(arr1, arr2);
  }

  if (isTrue) {
    if (page.children) {
      const open = page.href
        ? !!matchPath(
            {
              path: page.href,
              end: false,
            },
            currentRoute,
          )
        : false;

      if (!isEmpty(user.roles)) {
        items.push(
          <SidebarNavListItem
            depth={depth}
            icon={page.icon}
            key={page.title}
            badge={page.badge}
            open={!!open}
            title={page.title}
            subtitle={page.subtitle}
            href={page.href}
            externalUrl={page.externalUrl}
          >
            <SidebarNavList depth={depth + 1} pages={page.children} />
          </SidebarNavListItem>,
        );
      }
    } else {
      items.push(
        <SidebarNavListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          title={page.title}
          subtitle={page.subtitle}
          externalUrl={page.externalUrl}
        />,
      );
    }
  }

  return items;
};

export default ChildRoutes;
