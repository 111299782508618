import React, { useEffect, useRef, useState } from "react";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { Button, Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import usePalette from "../../../../../hooks/usePalette";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Form.Control
    {...field}
    {...props}
    type='text'
    as='textarea'
    style={{ minHeight: 60, maxHeight: 85 }}
  />
);

const Background = () => {
  const formRef = useRef();
  const palette = usePalette();

  const { values, submitForm } = useFormikContext();

  const [isDisabled, setIsDisabled] = useState(false);

  const { user, project, application } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.projectReducer.project,
    application: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (isEmpty(application)) return;

    const vireInProject = application?.virement_projects?.find(
      (item) => item.virement_type === "VI",
    );

    const applicationMinistryOwner =
      vireInProject?.project?.ministry_id.toString();
    const userMinistry = user?.user_access_to_ministries?.find(
      (item) => item.code === applicationMinistryOwner,
    )?.code;

    if (applicationMinistryOwner === userMinistry) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [application]);

  return (
    <React.Fragment>
      <Form.Label className='fw-bold text-uppercase'>
        <span className='me-4'>2.0</span>Latar Belakang
      </Form.Label>
      <FieldArray name='background'>
        {({ insert, remove, push }) => (
          <React.Fragment>
            {values?.background?.map((item, index) => {
              return (
                <div key={index} className='d-flex flex-row ps-4'>
                  <div className='me-3'>2.{index + 1}</div>
                  <Form.Group className='position-relative mb-3 w-100'>
                    <Field
                      name={`background.${index}.description`}
                      component={CustomInputComponent}
                      disabled={isDisabled}
                    />
                    {!isDisabled && (
                      <div
                        className='position-absolute'
                        style={{ top: -11, right: -8 }}
                      >
                        <Tooltip placement='top' title='Padam' arrow>
                          <Link to='#' onClick={() => remove(index)}>
                            <MdCancel size={20} color={palette.danger} />
                          </Link>
                        </Tooltip>
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            })}

            {!isDisabled && (
              <div className='d-flex justify-content-center'>
                <Button
                  size='sm'
                  variant='secondary'
                  onClick={() => push({ description: "" })}
                  disabled={isDisabled}
                >
                  + Latar Belakang
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  );
};

export default Background;
