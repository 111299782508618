import React, { useContext, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

// Styles
import NotyfContext from "../../../../../contexts/NotyfContext";

// redux
import { activatedRmk } from "../../../../../redux/slices/rmk-information/rmkSlice";

const RmkList = ({ rmkList, handleEditItem, handleRemoveItem }) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const handleActive = (row) => {
    dispatch(activatedRmk(row));
    handleNotification("success", row);
  };

  // Notification
  const handleNotification = (type, values) => {
    // Errors
    if (type === "danger") {
      const errors = Object.values(values);

      errors.map((item) => {
        const strMsg = item;

        notyf.open({
          type: type,
          message: strMsg,
          duration: 7000,
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "top",
          },
        });
      });
      return false;
    }

    // Success

    const msg = `${values.name} berjaya diaktifkan.`;

    notyf.open({
      type: type,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead className='text-uppercase'>
            <tr>
              <th className='text-center'>Bil.</th>
              <th className='text-center'>Aktif</th>
              <th>Rancangan Malaysia Ke</th>
              <th className='text-center'>Tahun</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {rmkList
              .toSorted((a, b) => b.first_year - a.first_year)
              .map((row, idx) => {
                const yearArr = [
                  row.first_year,
                  row.second_year,
                  row.third_year,
                  row.fourth_year,
                  row.fifth_year,
                ];

                const sortYearArr = yearArr.sort((a, b) => b - a);

                return (
                  <React.Fragment key={row.id}>
                    <tr>
                      <td
                        rowSpan={yearArr.length}
                        className='text-center border-1'
                      >
                        {idx + 1}.
                      </td>
                      <td
                        rowSpan={yearArr.length}
                        className='text-center border-1'
                      >
                        <Form.Check
                          type='radio'
                          name='active'
                          value={row.active}
                          checked={true === row.active}
                          onChange={() => handleActive(row)}
                        />
                      </td>
                      <td
                        rowSpan={yearArr.length}
                        className='border-right border-1'
                      >
                        {row.name}
                      </td>
                      <td className='text-center'>{yearArr[0]}</td>
                      <td
                        rowSpan={yearArr.length}
                        className='text-center border-left border-1'
                      >
                        <Tooltip title='Edit' placement='top' arrow>
                          <IconButton
                            color='info'
                            size='small'
                            aria-label='Edit'
                            onClick={() => handleEditItem(row)}
                          >
                            <MdModeEditOutline fontSize='large' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Padam' placement='top' arrow>
                          <IconButton
                            color='error'
                            size='small'
                            aria-label='Padam'
                            onClick={() => handleRemoveItem(row)}
                          >
                            <MdDelete fontSize='large' />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                    {sortYearArr.slice(1).map((year, idx) => (
                      <tr key={idx}>
                        <td className='text-center'>{year}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default RmkList;
