import numeral from "numeral";
import React from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import usePalette from "../../../../../hooks/usePalette";
// Icons
import Icon from "@mdi/react";
import { mdiAlertCircleOutline } from "@mdi/js";

const ConfirmReject = ({
  openReject,
  handleCloseReject,
  handleConfirmReject,
}) => {
  const palette = usePalette();

  const { btnLoading, project } = useSelector((state) => ({
    btnLoading: state.certifyReducer.btnLoading,
    project: state.certifyReducer.project.data,
  }));

  return (
    <Modal show={openReject} onHide={handleCloseReject} size='sm' centered>
      <Modal.Body>
        <div className='text-center p-4 '>
          <div className='mb-2'>
            <Icon
              path={mdiAlertCircleOutline}
              color={palette.danger}
              size={4}
            />
          </div>
          <h4>Adakah anda ingin menolak projek ini?</h4>
          <p className='mb-0'>
            Projek yang ditolak akan kembali kepada pegawai yang memohon untuk
            projek ini.
          </p>
        </div>
        <div className='d-flex justify-content-center gap-3'>
          <Button
            variant='light'
            onClick={handleCloseReject}
            disabled={btnLoading}
          >
            <span style={{ fontSize: 12 }}>Tidak</span>
          </Button>
          <Button
            variant='danger'
            disabled={btnLoading}
            onClick={handleConfirmReject}
          >
            <span className='mx-2' style={{ fontSize: 12 }}>
              Ya
            </span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmReject;
