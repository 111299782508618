import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import { Tooltip } from "@mui/material";
import usePalette from "../../../hooks/usePalette";
import LoadingState from "../../../components/common/LoadingState";

// Icons
import Icon from "@mdi/react";
import { mdiMicrosoftExcel, mdiFilterVariant } from "@mdi/js";

// components
import AnalysisTable from "./components/AnalysisTable";

// redux
import { fetchProjects } from "../../../redux/slices/projects/analysisTableSlice";
import { getRmkItems } from "../../../redux/slices/utilsSlice";

import { ModuleRegistry } from "@ag-grid-community/core";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import MoreSearchField from "./components/MoreSearchField";

ModuleRegistry.registerModules([ExcelExportModule]);

const MainPage = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const palette = usePalette();

  const columnState = JSON.parse(localStorage.getItem("columnState"));

  const [selectRmk, setSelectRmk] = useState(null);
  const [rowData, setRowData] = useState();
  const [showCanvas, setShowCanvas] = useState(false);
  const [loading, setLoading] = useState(true);

  const { currentRmk, rmkOptions } = useSelector((state) => ({
    currentRmk: state.rmkReducer.activeRmk,
    rmkOptions: state.utilsReducer.dropdownItems.rmkItems,
  }));

  const handleSearchMoreField = () => {
    setLoading(true);
    onGridReady();
  };

  // Download Execel
  const onBtnExport = useCallback(() => {
    const params = {
      fileName: "Jadual Analisis Dalaman",
      sheetName: "jad",
      processRowGroupCallback: (params) => {
        return params.node.key; // Customize group row text, remove arrow
      },
    };
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  // Save columns state
  const saveColumnState = useCallback(() => {
    const columnState = gridRef.current.columnApi.getColumnState();
    localStorage.setItem("columnState", JSON.stringify(columnState));
    console.log("Column state saved");
  }, []);

  const onGridReady = async (params) => {
    const props = {
      params: {
        rmk_id: selectRmk?.number,
        is_federal_projects:
          JSON.parse(localStorage.getItem("federalProjects"))?.value || false,
      },
    };
    await dispatch(fetchProjects(props)).then((res) => {
      setLoading(false);
      setRowData(res.payload);
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
    };

    dispatch(getRmkItems(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    setSelectRmk(currentRmk);
    handleSearchMoreField();
  }, [currentRmk]);

  useEffect(() => {
    if (columnState && gridRef?.current?.columnApi) {
      gridRef?.current?.columnApi?.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
      console.log("Restored Column State");
    } else {
      console.log("No column state to restore");
    }
  }, [columnState]);

  return (
    <React.Fragment>
      <MoreSearchField
        showCanvas={showCanvas}
        closeCanvas={() => setShowCanvas(false)}
        searchMoreField={handleSearchMoreField}
      />
      <Container fluid className='p-0'>
        <div className='d-flex justify-content-between mb-3'>
          <div>
            <Tooltip title='Lebih Pilihan' placement='top' arrow>
              <Button variant='secondary' onClick={() => setShowCanvas(true)}>
                <Icon path={mdiFilterVariant} size={0.8} />
              </Button>
            </Tooltip>
          </div>
          <div>
            <Tooltip title='Muat Turun XLSX' arrow>
              <Button onClick={onBtnExport} variant='light'>
                <Icon
                  path={mdiMicrosoftExcel}
                  size={1}
                  color={palette.success}
                />
              </Button>
            </Tooltip>
          </div>
        </div>
        {loading ? (
          <LoadingState />
        ) : (
          <AnalysisTable
            gridRef={gridRef}
            selectRmk={selectRmk}
            onGridReady={onGridReady}
            rowData={rowData}
            setRowData={setRowData}
            saveColumnState={saveColumnState}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
