import React, { useEffect, useState, useContext } from "react";
import { Card, Accordion, Row, Col, Badge } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import Placeholder from "react-bootstrap/Placeholder";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import numeral from "numeral";
import format from "date-fns/format";
import { ms } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import NotyfContext from "../../../../contexts/NotyfContext";

// Images
import PPNS from "../ppns-image-5.png";

import { PROGRESS_STATUS } from "../../../../constants";

// Accordion Body
import ProjectBrief from "./accordion-items/ProjectBrief";
import StrategicDirectionInfo from "./accordion-items/StrategicDirectionInfo";
import ProjectInfo from "./accordion-items/ProjectInfo";
import ResponsibleOfficer from "./accordion-items/ResponsibleOfficer";
import ProjectScope from "./accordion-items/ProjectScope";
import ProjectLocation from "./accordion-items/ProjectLocation";
import ExecutingAgencyInfo from "./accordion-items/ExecutingAgencyInfo";
import ActivitySetInfo from "./accordion-items/ActivitySetInfo";
import FinancialInfo from "./accordion-items/FinancialInfo";
import ProjectHistory from "./accordion-items/ProjectHistory";
import JKMMicroProjects from "./accordion-items/JKMMicroProjects";
import KPLBMicroProjects from "./accordion-items/KPLBMicroProjects";
import JPPNActivities from "./accordion-items/JPPNActivities";

const ProfileInfo = ({ itemRight, itemTop }) => {
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  const [approvedCost, setApprovedCost] = useState(0);
  const [approvedCeiling, setApprovedCeiling] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [showJKMMicroProjects, setShowJKMMicroProjects] = useState(false);
  const [showKPLBMicroProjects, setShowKPLBMicroProjects] = useState(false);

  const [currentYearFundAmount, setCurrentYearFundAmount] = useState(0);
  const [currentYearRevisedFundAmount, setCurrentYearRevisedFundAmount] =
    useState(0);

  const { user, project, progressStatus } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.projectReducer.project,
    progressStatus: state.utilsReducer.dropdownItems.progressStatusOptions,
  }));

  const isSuperAdmin = user.roles.includes("superadmin");
  const isAdmin = user.roles.includes("admin");
  const isAdminLevel = isSuperAdmin || isAdmin;

  // Notification
  const handleNotification = () => {
    let msg = `Maklumat projek telah dikemas kini.`;
    let color = "success";

    notyf.open({
      type: color,
      message: msg,
      duration: 3000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const status = progressStatus.find(
        (item) => item.code === project.progress_status,
      );

      setCurrentStatus(status);

      const cost = project?.financial_details?.reduce(
        (acc, item) => acc + parseFloat(item.cost_required),
        0,
      );

      setApprovedCost(cost);

      const ceiling = project.financial_details
        .flatMap((item) => item.ceiling_flow_details)
        .reduce((acc, ceiling) => (acc += parseFloat(ceiling.amount)), 0);

      setApprovedCeiling(ceiling);

      const isJKMTrue = project?.ministry?.code === "11";
      const isJKMProgramTrue = project?.program?.code === "000400";
      const isJKMMainProjectTrue = project?.main_project?.code === "0001";

      if (isJKMTrue && isJKMProgramTrue && isJKMMainProjectTrue) {
        setShowJKMMicroProjects(true);
      } else {
        setShowJKMMicroProjects(false);
      }

      const isKPLBTrue = project?.ministry?.code === "19";
      const isKPLBProgramTrue = project?.program?.code === "000100";
      const isKPLBMainProjectTrue =
        project?.main_project?.code === "0001" ||
        project?.main_project?.code === "0002" ||
        project?.main_project?.code === "0003" ||
        project?.main_project?.code === "0004" ||
        project?.main_project?.code === "0005" ||
        project?.main_project?.code === "0006" ||
        project?.main_project?.code === "0007" ||
        project?.main_project?.code === "0008" ||
        project?.main_project?.code === "0009" ||
        project?.main_project?.code === "0010";

      if (isKPLBTrue && isKPLBProgramTrue && isKPLBMainProjectTrue) {
        setShowKPLBMicroProjects(true);
      } else {
        setShowKPLBMicroProjects(false);
      }

      const currentYearFund = project?.s3p_data?.find(
        (item) => item.year === new Date().getFullYear(),
      );

      setCurrentYearFundAmount(currentYearFund?.state_fund);

      const sumASP = currentYearFund?.financial_activities
        ?.filter((item) => item.type === "ASP")
        ?.reduce((acc, item) => (acc += parseFloat(item?.amount)), 0);
      const sumVI = currentYearFund?.financial_activities
        ?.filter((item) => item.type === "VI")
        ?.reduce((acc, item) => (acc += parseFloat(item?.amount)), 0);
      const sumVO = currentYearFund?.financial_activities
        ?.filter((item) => item.type === "VO")
        ?.reduce((acc, item) => (acc += parseFloat(item?.amount)), 0);

      const revisedFund =
        parseFloat(currentYearFund?.state_fund) + sumASP + sumVI - sumVO;

      setCurrentYearRevisedFundAmount(revisedFund);
    }
  }, [project]);

  return (
    <React.Fragment>
      <Card as={motion.div} variants={itemTop} className='flex-fill mb-0'>
        <Card.Img
          src={PPNS}
          alt='gambar'
          style={{ opacity: "18%", borderRadius: 0, height: "280px" }}
        />
        <Card.ImgOverlay className='px-5 pt-4' style={{ overflow: "auto" }}>
          <div className='text-sm'>Nama Projek</div>
          <h3 className='fw-bolder'>{project?.name}</h3>
          <Row>
            <Col md={6}>
              <div className='mb-1'>
                <div className='text-sm'>Kod Projek</div>
                <Card.Text className='fw-bolder'>
                  {`${project.program?.prefix}${
                    project.program?.ministry_code
                  }-${project.executing_agency?.code}-000000-${
                    project.program?.code
                  }-${project.main_project?.code}${
                    project.code && project.code
                  }-00000`}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>RMK</div>
                <Card.Text className='fw-bolder'>
                  {project?.rmk && (
                    <React.Fragment>{`${project.rmk?.name} (${project.rmk?.short_form})`}</React.Fragment>
                  )}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>Jenis Projek</div>
                <Card.Text className='fw-bolder'>
                  {project?.project_type?.name}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>Status Pelaksanaan</div>
                <Card.Text className='fw-bolder'>
                  {!isEmpty(currentStatus) ? (
                    <Badge
                      bg=''
                      className={`px-3 text-uppercase`}
                      style={{ backgroundColor: currentStatus.color }}
                    >
                      <span style={{ fontSize: 11 }}>
                        {currentStatus.label}
                      </span>
                    </Badge>
                  ) : (
                    "-"
                  )}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>Kategori Projek</div>
                <Card.Text className='fw-bolder'>
                  {project?.project_category && (
                    <React.Fragment>
                      {`${project.project_category?.value} (${project.project_category?.code})`}
                    </React.Fragment>
                  )}
                </Card.Text>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-1'>
                <div className='text-sm'>Kos Keseluruhan Diluluskan</div>
                <Card.Text className='fw-bolder'>
                  {`RM ${numeral(approvedCost).format("0,0.00")}`}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>Siling Keseluruhan Diluluskan</div>
                <Card.Text className='fw-bolder'>
                  {`RM ${numeral(approvedCeiling).format("0,0.00")}`}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>
                  Peruntukan Asal Tahun ({new Date().getFullYear()})
                </div>
                <Card.Text className='fw-bolder'>
                  {`RM ${numeral(currentYearFundAmount).format("0,0.00")}`}
                </Card.Text>
              </div>
              <div className='mb-1'>
                <div className='text-sm'>
                  Peruntukan Disemak Tahun ({new Date().getFullYear()})
                </div>
                <Card.Text className='fw-bolder'>
                  {`RM ${numeral(currentYearRevisedFundAmount).format(
                    "0,0.00",
                  )}`}
                </Card.Text>
              </div>
            </Col>
          </Row>
        </Card.ImgOverlay>
      </Card>
      <Card className='p-0 mt-0'>
        <Accordion className='remove-outline-item' flush>
          <Accordion.Item as={motion.div} variants={itemRight} eventKey='1'>
            <ProjectBrief
              project={project}
              isAdminLevel={isAdminLevel}
              showNotification={handleNotification}
            />
          </Accordion.Item>
          <Accordion.Item as={motion.div} variants={itemRight} eventKey='2'>
            <ProjectScope
              project={project}
              isAdminLevel={isAdminLevel}
              showNotification={handleNotification}
            />
          </Accordion.Item>
          <Accordion.Item as={motion.div} variants={itemRight} eventKey='3'>
            <FinancialInfo showNotification={handleNotification} />
          </Accordion.Item>
          <Accordion.Item as={motion.div} variants={itemRight} eventKey='4'>
            <ProjectLocation project={project} />
          </Accordion.Item>
          <Accordion.Item as={motion.div} variants={itemRight} eventKey='5'>
            <ExecutingAgencyInfo
              project={project}
              isAdminLevel={isAdminLevel}
            />
          </Accordion.Item>

          <Accordion.Item as={motion.div} variants={itemRight} eventKey='6'>
            <JPPNActivities project={project} />
          </Accordion.Item>

          <Accordion.Item as={motion.div} variants={itemRight} eventKey='7'>
            <ActivitySetInfo project={project} />
          </Accordion.Item>

          <Accordion.Item as={motion.div} variants={itemRight} eventKey='8'>
            <ProjectHistory project={project} />
          </Accordion.Item>

          <Accordion.Item as={motion.div} variants={itemRight} eventKey='9'>
            <ResponsibleOfficer project={project} />
          </Accordion.Item>

          {showJKMMicroProjects && (
            <Accordion.Item as={motion.div} variants={itemRight} eventKey='10'>
              <JKMMicroProjects />
            </Accordion.Item>
          )}
          {showKPLBMicroProjects && (
            <Accordion.Item as={motion.div} variants={itemRight} eventKey='10'>
              <KPLBMicroProjects />
            </Accordion.Item>
          )}
        </Accordion>
      </Card>
    </React.Fragment>
  );
};

export default ProfileInfo;
