import React, { useEffect, useState } from "react";
import { Badge, Pagination, Table, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import usePalette from "../../../../../hooks/usePalette";

// Loading State
import { FiveColumns } from "../../../../../components/common/tableLoading";

// Icons
import Icon from "@mdi/react";
import { mdiSquare } from "@mdi/js";

const Projects = () => {
  const navigate = useNavigate();
  const palette = usePalette();

  let cumulativeIndex = 0;

  const [pageValue, setPageValue] = useState("");
  const [data, setData] = useState([]);

  const { isLoading, projects, meta, links } = useSelector((state) => ({
    isLoading: state.projectReducer.isLoading,
    projects: state.projectReducer.projects,
    meta: state.projectReducer.meta,
    links: state.projectReducer.links,
  }));

  const handleSelectProject = (project) => {
    const { uuid } = project;
    navigate(`/profil-projek/${uuid}`);
  };

  useEffect(() => {
    const groupByProgram = projects.reduce((groups, project) => {
      const { program_id, program } = project;
      if (!groups[program_id]) {
        groups[program_id] = { program, main_projects: {} };
      }
      if (!groups[program_id].main_projects[project.main_project_id]) {
        groups[program_id].main_projects[project.main_project_id] = {
          main_project: project.main_project || null,
          projects: [],
        };
      }

      groups[program_id].main_projects[project.main_project_id].projects.push(
        project,
      );
      return groups;
    }, {});

    const groupedByPrograms = Object.values(groupByProgram).map(
      ({ program, main_projects }) => ({
        program,
        main_projects: Object.values(main_projects),
      }),
    );
    setData(groupedByPrograms);
  }, [projects]);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm' style={{ fontSize: 12 }}>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center'>Bil.</th>
              <th className='text-center'>Kod</th>
              <th>
                <span className='me-1'>
                  <Icon path={mdiSquare} size={0.8} />
                  <span className='ms-1'>Program</span>
                </span>
                /
                <span className='mx-1'>
                  <Icon path={mdiSquare} color={palette.primary} size={0.8} />
                  <span className='ms-1'>Projek utama</span>
                </span>
                /
                <span className='ms-1'>
                  <span className='ms-1'>Sub Projek</span>
                </span>
              </th>
              <th className='text-center'>Jabatan</th>
              <th className='text-center'>kementerian</th>
            </tr>
          </thead>
          {isLoading ? (
            <FiveColumns />
          ) : (
            <>
              {isEmpty(data) ? (
                <tbody>
                  <tr>
                    <td colSpan={5} className='text-center'>
                      Tiada maklumat tersedia
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {data
                    ?.toSorted(
                      (a, b) =>
                        a.program?.ministry?.code - b.program?.ministry?.code,
                    )
                    ?.map(({ program, main_projects }, idx) => {
                      const totalRowSpan = main_projects.reduce(
                        (acc, { projects }) => acc + projects.length + 1,
                        0,
                      );

                      return (
                        <React.Fragment key={idx}>
                          <tr>
                            <td />
                            <td>
                              <div
                                className='bg-secondary text-center text-white px-2 text-truncate'
                                style={{ fontWeight: 700 }}
                              >
                                {program?.code}
                              </div>
                            </td>
                            <td style={{ width: "55%" }}>
                              <span style={{ fontWeight: 700 }}>
                                {program.name}
                              </span>
                            </td>
                            <td style={{ width: "20%" }} />
                            <td
                              rowSpan={totalRowSpan + 1}
                              className='align-middle text-center border-start'
                              style={{ width: "15%" }}
                            >
                              <div
                                style={{ fontWeight: 700, fontSize: "120%" }}
                              >
                                <div>
                                  {program.prefix_type}
                                  {program.ministry?.code}
                                </div>
                                <div>{program.ministry?.short_form}</div>
                              </div>
                            </td>
                          </tr>
                          {main_projects?.map(
                            ({ main_project, projects }, idx) => {
                              return (
                                <React.Fragment key={idx}>
                                  <tr>
                                    <td />
                                    <td style={{ fontWeight: 500 }}>
                                      {!isNull(main_project) && (
                                        <div className='bg-primary text-center text-white px-2'>
                                          {isNull(main_project) ? (
                                            <span
                                              style={{ color: palette.danger }}
                                            >
                                              Tiada Projek Utama
                                            </span>
                                          ) : (
                                            main_project?.code
                                          )}
                                        </div>
                                      )}
                                    </td>
                                    <td
                                      className='text-uppercase'
                                      style={{ fontWeight: 500 }}
                                    >
                                      {isNull(main_project) ? (
                                        <span style={{ color: palette.danger }}>
                                          Tiada Projek Utama
                                        </span>
                                      ) : (
                                        <span>{main_project?.name}</span>
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                  {projects
                                    ?.toSorted((a, b) => a?.code - b?.code)
                                    ?.map((project, idx) => {
                                      cumulativeIndex++;
                                      return (
                                        <React.Fragment key={idx}>
                                          <tr
                                            className='hover-effect'
                                            onClick={() =>
                                              handleSelectProject(project)
                                            }
                                          >
                                            <td className='text-center'>
                                              {cumulativeIndex}
                                            </td>
                                            <td>
                                              <div
                                                className='text-center px-2'
                                                style={{ fontWeight: 700 }}
                                              >
                                                {project?.code}
                                              </div>
                                            </td>
                                            <td>
                                              <span
                                                className='text-uppercase'
                                                style={{ fontWeight: 500 }}
                                              >
                                                {project?.name}
                                              </span>
                                            </td>
                                            <td className='border-start text-center text-uppercase'>
                                              {isEmpty(
                                                project.executing_agency,
                                              ) ? (
                                                <span
                                                  className='fw-bold'
                                                  style={{
                                                    color: palette.danger,
                                                  }}
                                                >
                                                  Tiada Maklumat
                                                </span>
                                              ) : (
                                                <>
                                                  <div className='fw-bolder'>
                                                    {
                                                      project?.executing_agency
                                                        ?.code
                                                    }
                                                  </div>
                                                  <div>{`${project?.executing_agency?.name}`}</div>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    })}
                                </React.Fragment>
                              );
                            },
                          )}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              )}
            </>
          )}
        </Table>
      </div>
    </React.Fragment>
  );
};

export default Projects;
