import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchRoles = createAsyncThunk("roles/fetchRoles", async () => {
  const response = await axios.get(`/maintenance/roles`);
  const data = await response.data;
  return data;
});

export const fetchGroupRoles = createAsyncThunk(
  "roles/fetchGroupRoles",
  async (params) => {
    const response = await axios.get(`/group-roles`, { params });
    const data = await response.data;
    return data;
  },
);

export const createGroupRoles = createAsyncThunk(
  "roles/createGroupRoles",
  async (values) => {
    const response = await axios.post(`/group-roles`, values);
    const data = await response.data;
    return data;
  },
);

export const updateGroupRoles = createAsyncThunk(
  "roles/updateGroupRoles",
  async (values) => {
    const { id } = values;
    const response = await axios.put(`/group-roles/${id}`, values);
    const data = await response.data;
    return data;
  },
);

export const deleteGroupRoles = createAsyncThunk(
  "roles/deleteGroupRoles",
  async (id) => {
    const response = await axios.delete(`/group-roles/${id}`);
    const data = await response.data;
    return id;
  },
);

const initialState = {
  groupRoles: [],
  roles: [],
  isLoading: true,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchGroupRoles.fulfilled, (state, action) => {
      state.groupRoles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createGroupRoles.fulfilled, (state, action) => {
      state.groupRoles.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateGroupRoles.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.groupRoles = state.groupRoles.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.isLoading = false;
    });
    builder.addCase(deleteGroupRoles.fulfilled, (state, action) => {
      state.groupRoles = state.groupRoles.filter(
        (item) => item.id !== action.payload,
      );
      state.isLoading = false;
    });
  },
});

export default rolesSlice.reducer;
