import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { isEmpty } from "lodash";
import routes from "../../routes";
import dashboardItems from "../sidebar/dashboardItems";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const location = useLocation();

  const { isAuthenticated, isInitialized, user } = useContext(AuthContext);

  if (isInitialized && !isAuthenticated) {
    return <Navigate to='/auth/sign-in' />;
  }

  const arr1 = dashboardItems;
  const pathName = location.pathname;

  let accessLevel = [];

  function isValueInNestedArray(value, array) {
    for (let item of array) {
      if (Array.isArray(item.pages)) {
        for (let row of item.pages) {
          if (isEmpty(row.children)) {
            if (isValueInNestedArray(value, item.pages)) {
              return true;
            }
          } else {
            if (Array.isArray(row.children)) {
              if (isValueInNestedArray(value, row.children)) {
                return true;
              }
            }
          }
        }
      } else if (value === item.href) {
        accessLevel = item.accessLevel;
        return true;
      }
    }
    return false;
  }

  const isTrue = isValueInNestedArray(pathName, arr1);

  if (isEmpty(user)) return false;

  if (isTrue) {
    const checkRoles = (accessLevel, roles) => {
      const arr1 = accessLevel;
      const arr2 = roles;
      for (let i = 0; i < arr1.length; i++) {
        if (arr2.includes(arr1[i])) {
          return true;
        }
      }
      return false;
    };

    const isAccessAble = checkRoles(accessLevel, user.roles);

    if (!isAccessAble) return <Navigate to='/404' />;

    return <React.Fragment>{children}</React.Fragment>;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
