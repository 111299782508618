import React, { useEffect, useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { useDispatch } from "react-redux";
// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import TargetGroup from "./components/tables/TargetGroup";
import TargetGroupForm from "./components/modals/TargetGroupForm";

// redux
import { fetchTargetGroups } from '../../../redux/slices/maintenance/targetGroupSlice'


const MainPage = () => {

  const dispatch = useDispatch()
  const [openForm, setOpenForm] = useState(false);

  const handleCloseForm = () => {
    setOpenForm(false)
  }

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  useEffect(() => {
    dispatch(fetchTargetGroups())
  }, [dispatch])

  return (
    <React.Fragment>
      <TargetGroupForm showForm={openForm} closeForm={handleCloseForm} />

      <Helmet title="Kumpulan Sasar" />
      <Container fluid className="p-0">
        <Breadcrumbs title="Penyelenggaraan" breadcrumbItem="Kumpulan Sasar" />

        <Card className="mt-3">
          <Card.Header>
            <div className="card-actions float-end">
              <Button onClick={handleOpenForm}>
                <MdAdd /> Kumpulan
              </Button>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div className="search-box-custom">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label-custom">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Cari Nama Kumpulan..."
                      // value={searchText}
                      // onChange={(e) => setSearchText(e.target.value)}
                      style={{ width: 280 }}
                    />
                  </label>
                  <MdSearch className="search-icon-2" size={18} />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <TargetGroup />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
