import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { AuthContext } from "../../contexts/AuthContext";
import usePalette from "../../hooks/usePalette";
import { useFormik } from "formik";
import * as Yup from "yup";

const ChangePassword = () => {
  const { signOut } = useContext(AuthContext);
  const palette = usePalette();

  const [errors, setErrors] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const [disableButton, setDisableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.userReducer.user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Mesti Mengandungi 8 Aksara, Huruf Besar, Huruf Kecil, Nombor, dan Aksara Khas",
      ),
    }),
    onSubmit: async (values) => {
      try {
        setDisableButton(true);

        const newValues = {
          ...values,
          id: user.id,
        };
        const response = await axios.post(`/password/change`, newValues);
        const data = await response;

        startCountdown();
      } catch (error) {
        console.error("Password change failed", error);
        setDisableButton(false);
        setIsLoading(false);
      }
    },
  });

  const startCountdown = () => {
    setCountdown(3);
    setIsLoading(true);
    const timerId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          signOut();
          clearInterval(timerId);
          setDisableButton(false);
          setIsLoading(false);
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (!isEmpty(formik.values.password)) {
      if (
        formik.values.password !== formik.values.confirmPassword &&
        !isEmpty(formik.values.confirmPassword)
      ) {
        setErrors({ ...errors, password: "Kata laluan tidak sama!" });
        setDisableButton(true);
      } else {
        setErrors(null);
        setDisableButton(false);
      }
    } else {
      setErrors(null);
      formik.resetForm();
    }
  }, [formik.values.confirmPassword, formik.values.password]);

  return (
    <React.Fragment>
      <Container
        fluid
        className='p-0 d-flex align-items-center justify-content-center h-100'
      >
        <Card style={{ width: 600 }}>
          <Card.Body>
            <div className='mb-3'>
              <h4>Ubah Kata Laluan</h4>
            </div>
            <Form
              autoComplete='off'
              autoCorrect='off'
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Form.Group className='mb-3'>
                <Form.Label>Kata Laluan Baru</Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  disabled={isLoading}
                  isInvalid={!isEmpty(formik.errors.password)}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors?.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Sahkan Kata Laluan Baru</Form.Label>
                <Form.Control
                  type='password'
                  name='confirmPassword'
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  isInvalid={!isEmpty(errors?.password)}
                  disabled={isLoading}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.password}
                </Form.Control.Feedback>
              </Form.Group>
              <div className='d-flex justify-content-end align-items-center'>
                {isLoading && (
                  <div className='me-3'>
                    <span style={{ color: palette.success }}>
                      Kata laluan berjaya diubah
                    </span>
                    , log keluar dalam masa {countdown}...
                  </div>
                )}

                <Button
                  variant='success'
                  type='submit'
                  disabled={disableButton}
                >
                  Tukar Kata Laluan
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default ChangePassword;
