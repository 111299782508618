import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LoadingState from "../../../../../../components/common/LoadingState";
import { isEmpty } from "lodash";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { ms } from "date-fns/locale";
import parse from "html-react-parser";
import axios from "axios";

// Icons
import Icon from "@mdi/react";
import { mdiLoading, mdiSend, mdiClose } from "@mdi/js";
import { MdAttachment, MdMap } from "react-icons/md";

// Components
import SuccessNotification from "../../../../../../components/common/SuccessNotification";
import Application from "../../../../../export/Application";

// redux
import {
  updateProject,
  fetchProjectDetails,
} from "../../../../../../redux/slices/projects/projectSlice";
import {
  getNewProjectApplication,
  updateNewProjectApplication,
} from "../../../../../../redux/slices/jppn/jppnApplyNewProjectSlice";
import { setProjectComponents } from "../../../../../../redux/slices/jppn/jppnModuleSlice";

const SummaryProjectForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();

  const { applicationId } = routeParams;

  const [showSuccess, setShowSuccess] = useState(false);
  const [ceilingFlowInfo, setCeilingFlowInfo] = useState([]);
  const [stages, setStages] = useState(0);
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [upenSectionProject, setUpenSectionProject] = useState([]);
  const [showSendBtn, setShowSendBtn] = useState(false);
  const [errors, setErrors] = useState({});
  const [tooltipTitle, setTooltipTitle] = useState("");
  const [selectedRmk, setSelectedRmk] = useState([]);
  const [totalCeilingFlow, setTotalCeilingFlow] = useState(0);

  const colSpan = 6;

  const { btnLoading, project, user, isLoading, application } = useSelector(
    (state) => ({
      project: state.projectReducer.project,
      btnLoading: state.projectReducer.btnLoading,
      isLoading: state.projectReducer.isLoading,

      user: state.userReducer.user,
      application: state.jppnReducer.apply_new_project.application,
    }),
  );

  const { first_year, second_year, third_year, fourth_year, fifth_year } =
    selectedRmk;

  const groupedByYear = ceilingFlowInfo?.reduce(
    (acc, item) => {
      const { year } = item;

      if (year === first_year) {
        acc[first_year].push(item);
      } else if (year === second_year) {
        acc[second_year].push(item);
      } else if (year === third_year) {
        acc[third_year].push(item);
      } else if (year === fourth_year) {
        acc[fourth_year].push(item);
      } else if (year === fifth_year) {
        acc[fifth_year].push(item);
      }
      return acc;
    },
    {
      [first_year]: [],
      [second_year]: [],
      [third_year]: [],
      [fourth_year]: [],
      [fifth_year]: [],
    },
  );

  const ceilingFlow = Object.entries(groupedByYear).map(([year, data]) => ({
    year: year,
    data,
  }));

  const handleBack = () => {
    navigate(`/jppn/daftar-projek-baharu/${applicationId}`);
  };

  // const handleSubmitApplication = async () => {
  //   const selected_financial_details = project.financial_details.map(
  //     (item) => item,
  //   );
  //   const selected_ceiling_flow_details = project.financial_details.flatMap(
  //     (item) => item.ceiling_flow_details,
  //   );

  //   const newValues = {
  //     id: project.id,
  //     application_status: applicationStatus,
  //     stages: stages,
  //     selected_financial_details: selected_financial_details,
  //     selected_ceiling_flow_details: selected_ceiling_flow_details,
  //     section_id: upenSectionProject,
  //     application_sent: true,
  //     application_sent_by: user.id,
  //   };
  //   await dispatch(updateProject(newValues));
  //   setShowSuccess(true);
  // };

  const handleNextProcess = async () => {
    const projectComponents = project?.financial_details?.map((item) => {
      const component = {
        id: item.id,
        project_id: item.project_id,
        cost_description: item.cost_description,
        cost_required: item.cost_required,
        ceiling: item.ceiling,
        revised_ceiling: item.ceiling,
      };

      return component;
    });

    const newValues = {
      id: applicationId,
      project_components: projectComponents,
    };
    await dispatch(updateNewProjectApplication(newValues));

    navigate(
      `/permohonan/jppn/daftar-projek-baharu/${applicationId}/kemas-kini`,
    );
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !isEmpty(project)) {
      const {
        financial_details,
        executing_agency,
        financially_responsible_agency,
        owner_agency,
        applicant_agency,
        upen_section,
        stages,
        rmk,
      } = project;

      const updatedArray = financial_details?.map((financial) => {
        const updatedChildren = financial.ceiling_flow_details?.map((flow) => ({
          ...flow,
          financial_detail_id: financial.id,
          cost_description: financial.cost_description,
        }));
        return { ...financial, ceiling_flow_details: updatedChildren };
      });

      const childrenArray = updatedArray
        ?.map((financial) => financial.ceiling_flow_details)
        ?.reduce((acc, flow) => acc.concat(flow), []);

      setCeilingFlowInfo(childrenArray);

      const errorMessages = {};

      if (isEmpty(financial_details)) {
        errorMessages.financial_details = "Sila isi maklumat kewangan";
      }

      if (isEmpty(executing_agency)) {
        errorMessages.executing_agency = "Sila isi agensi pelaksana";
      }

      if (isEmpty(financially_responsible_agency)) {
        errorMessages.financially_responsible_agency =
          "Sila isi agensi bertanggungjawab dalam bahagian kewangan";
      }

      if (isEmpty(upen_section)) {
        errorMessages.upen_section = "Sila isi seksyen UPEN";
      }

      setErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
      setSelectedRmk(rmk);

      // User
      const isAdmin = user.roles.some((role) =>
        ["superadmin", "admin"].includes(role),
      );
      const isMinistry = user.roles.includes("ministry");
      const isDepartment = user.roles.includes("department");
      const isUser = user.roles.includes("user");

      if (isAdmin) {
        setStages(4);
        setApplicationStatus(2);
      } else {
        if (isMinistry) {
          setStages(4);
          setApplicationStatus(2);
          setTooltipTitle("Hantar ke UPEN");
        }
        if (isDepartment) {
          setStages(3);
          setApplicationStatus(2);
          setTooltipTitle("Hantar ke Kementerian");
        }
        if (isUser) {
          setStages(2);
          setApplicationStatus(2);
          setTooltipTitle("Hantar ke Ketua Jabatan");
        }
      }

      const upenSections = user.ministry?.upen_sections;

      if (!isEmpty(upenSections)) {
        const sectionIds = upenSections.map((item) => item.id);
        setUpenSectionProject(sectionIds);
      }

      switch (true) {
        case (Number(stages) === 1 ||
          Number(stages) === 5 ||
          isEmpty(stages)) &&
          isUser:
          setShowSendBtn(true);
          break;
        case Number(stages) === 2 && isDepartment:
          setShowSendBtn(true);
          break;
        case Number(stages) === 3 && isMinistry:
          setShowSendBtn(true);
          break;

        default:
          setShowSendBtn(false);
          break;
      }

      const sum = project?.financial_details
        ?.flatMap((obj1) =>
          obj1.ceiling_flow_details?.flatMap((obj2) => parseFloat(obj2.amount)),
        )
        .reduce((acc, amount) => acc + parseFloat(amount), 0);

      setTotalCeilingFlow(sum);
    }
    return () => {
      isMounted = false;
    };
  }, [project, user]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (isEmpty(project)) {
      const props = {
        source: source,
        id: applicationId,
      };
      dispatch(getNewProjectApplication(props)).then((res) => {
        const payload = res.payload;
        dispatch(
          fetchProjectDetails({
            source: source,
            project_id: payload.project?.uuid,
          }),
        );
      });
    }
    return () => {
      source.cancel();
    };
  }, [dispatch, application]);

  if (isLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <SuccessNotification
        showSuccess={showSuccess}
        handleCloseSuccess={handleCloseSuccess}
        goBackUrl={"/senarai-projek/status-permohonan"}
        primaryMessage={`Permohonan ${project.name}`}
        secondaryMessage={`senarai permohonan`}
      />
      <Card>
        <Card.Body>
          <h5 className='text-center mb-1 mt-2'>
            {`Keterangan Mengenai Maklumat Projek`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-1'>
            {`${project.rmk?.name} : ${first_year}-${fifth_year}`.toLocaleUpperCase()}
          </h5>
          <h5 className='text-center mb-3'>
            {"(Pembiayaan Kerajaan Negeri)".toLocaleUpperCase()}
          </h5>

          <Table bordered size='sm' style={{ fontSize: 12 }}>
            <tbody>
              {/* Project Profile */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='text-center' style={{ width: "5%" }}>
                  Bil.
                </td>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Profil
                </th>
                <th className='border-start-0' colSpan={colSpan}></th>
              </tr>
              <tr>
                <td className='text-center'>1</td>
                <td className='fw-bold'>Kementerian</td>
                <td colSpan={colSpan}>{project?.ministry?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>2</td>
                <td className='fw-bold'>Jabatan</td>
                <td colSpan={colSpan}>{project?.department?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>3</td>
                <td className='fw-bold'>Program</td>
                <td colSpan={colSpan}>{project?.program?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>4</td>
                <td className='fw-bold'>Projek Utama</td>
                <td colSpan={colSpan}>{project?.main_project?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>5</td>
                <td className='fw-bold'>Nama Projek</td>
                <td colSpan={colSpan}>{project?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>6</td>
                <td className='fw-bold'>Keterangan Projek</td>
                <td colSpan={colSpan}>{project?.description}</td>
              </tr>
              <tr>
                <td className='text-center'>6</td>
                <td className='fw-bold'>Justifikasi Projek</td>
                <td colSpan={colSpan}>{project?.justification}</td>
              </tr>
              <tr>
                <td className='text-center'>9</td>
                <td className='fw-bold'>KPI Program dan Projek</td>
                <td colSpan={colSpan}>{project?.key_performance_indicator}</td>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  11
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  Matlamat Pembangunan Mampan
                  <p className='mb-0 text-sm fst-italic'>
                    Sustainable Development Goal (SDG)
                  </p>
                </td>
              </tr>
              {/* Sustainable Development Goal */}
              {project?.strategic_directions?.map((item, idx) => {
                const lastIndex = project?.strategic_directions.length - 1;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      lastIndex === idx ? "" : "border-bottom-0"
                    } border-top-0`}
                  >
                    <td className='ps-0' colSpan={colSpan}></td>
                  </tr>
                );
              })}

              <tr>
                <td className='text-center'>10</td>
                <td className='fw-bold'>Sektor Utama</td>
                <td colSpan={colSpan}>{project?.main_sector?.name}</td>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  11
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.strategic_directions?.length + 1 || 0}
                >
                  Halatuju Strategi
                </td>
              </tr>
              {/* Strategic directions */}
              {project?.strategic_directions?.map((item, idx) => {
                const lastIndex = project?.strategic_directions.length - 1;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      lastIndex === idx ? "" : "border-bottom-0"
                    } border-top-0`}
                  >
                    <td className='ps-0' colSpan={colSpan}>
                      <ul className='mb-0 pe-0 ps-4'>
                        <li>{item.data?.name}</li>
                      </ul>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className='text-center'>12</td>
                <td className='fw-bold'>Kategori Projek</td>
                <td colSpan={colSpan}>
                  {`${project?.project_category?.value} (${project?.project_category?.code})`}
                </td>
              </tr>
              <tr>
                <td className='text-center'>13</td>
                <td className='fw-bold'>Jenis Projek</td>
                <td colSpan={colSpan}>{project?.project_type?.name}</td>
              </tr>

              {/* Financial Infomation */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Kewangan
                  {errors?.financial_details && (
                    <p className='text-error mb-0'>
                      {errors?.financial_details}
                    </p>
                  )}
                </th>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.financial_details?.length + 2 || 0}
                >
                  14
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.financial_details?.length + 2 || 0}
                >
                  Skop dan Kos Dipohon
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={4}
                >
                  Skop
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={3}
                >
                  Nilai
                  <div>(RM)</div>
                </td>
              </tr>
              {project?.financial_details?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={4}>
                    <span className='ms-4'>{item.cost_description}</span>
                  </td>
                  <td colSpan={3} className='text-center'>
                    {`${numeral(item.cost_required).format("0,0.00")}`}
                  </td>
                </tr>
              ))}
              <tr>
                <td className='text-end' colSpan={4}>
                  Jumlah
                </td>
                <td className='text-center' colSpan={3}>
                  {`${numeral(
                    project?.financial_details?.reduce(
                      (acc, financial) =>
                        acc + parseFloat(financial.cost_required),
                      0,
                    ),
                  ).format("0,0.00")}`}
                </td>
              </tr>
              <tr>
                <td className='text-center' rowSpan={2}>
                  15
                </td>
                <td className='fw-bold' rowSpan={2}>
                  Aliran Siling
                </td>
                {ceilingFlow?.map((item) => (
                  <td
                    key={item.year}
                    className='text-center'
                    style={{ backgroundColor: "#eff2f7" }}
                  >
                    Tahun {item.year}
                    <div>(RM)</div>
                  </td>
                ))}
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Jumlah Siling
                  <div>(RM)</div>
                </td>
              </tr>
              <tr>
                {ceilingFlow?.map((item) => {
                  const amount = item.data.reduce(
                    (acc, flow) => acc + parseFloat(flow.amount),
                    0,
                  );
                  return (
                    <td key={item.year} className='text-center'>
                      {`${numeral(amount).format("0,0.00")}`}
                    </td>
                  );
                })}
                <td className='text-center'>
                  {numeral(totalCeilingFlow).format("0,0.00")}
                </td>
              </tr>

              <tr>
                <td className='text-center'>16</td>
                <td className='fw-bold'>Sumber Pembiayaan</td>
                <td colSpan={colSpan}>
                  {project?.funding_sources
                    .map((item) => {
                      if (item === 1) return "Kumpulan Wang Negeri";
                      if (item === 2) return "Pinjaman Persekutuan";
                      if (item === 3) return "Bayaran Balik Persekutuan";
                      return "";
                    })
                    .filter(Boolean)
                    .join(" dan ")}
                </td>
              </tr>

              {/* Physical Information  */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Fizikal
                </th>
              </tr>
              <tr>
                <td
                  className='text-center'
                  rowSpan={project?.financial_details?.length + 1 || 0}
                >
                  17
                </td>
                <td
                  className='fw-bold'
                  rowSpan={project?.financial_details?.length + 1 || 0}
                >
                  Skop Projek
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={3}
                >
                  Skop
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Mula (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tarikh Siap (Jangkaan)
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Tempoh
                </td>
              </tr>
              {project?.financial_details?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={3}>{item.cost_description}</td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_start_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {!isEmpty(
                      item.project_scope_details?.expected_completion_date,
                    ) &&
                      format(
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        "dd MMM yyyy",
                      )}
                  </td>
                  <td className='text-center'>
                    {item.project_scope_details?.expected_completion_date &&
                      formatDistanceStrict(
                        new Date(
                          item.project_scope_details?.expected_start_date,
                        ),
                        new Date(
                          item.project_scope_details?.expected_completion_date,
                        ),
                        {
                          addSuffix: false,
                          locale: ms,
                        },
                      )}
                  </td>
                </tr>
              ))}

              <tr>
                <td className='text-center'>18</td>
                <td className='fw-bold'>Tarikh Tender (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_tender_date) &&
                    format(
                      new Date(project.physical_details?.expected_tender_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>19</td>
                <td className='fw-bold'>Tarikh Mula Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(project.physical_details?.expected_start_date) &&
                    format(
                      new Date(project.physical_details?.expected_start_date),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>20</td>
                <td className='fw-bold'>Tarikh Siap Projek (Jangkaan)</td>
                <td colSpan={colSpan}>
                  {!isEmpty(
                    project.physical_details?.expected_completion_date,
                  ) &&
                    format(
                      new Date(
                        project.physical_details?.expected_completion_date,
                      ),
                      "dd MMM yyyy",
                    )}
                </td>
              </tr>

              <tr>
                <td className='text-center'>21</td>
                <td className='fw-bold'>Keluasan Tapak</td>
                <td colSpan={colSpan}>
                  {numeral(project.physical_details?.site_width).format(
                    "0,0.00",
                  )}{" "}
                  ekar
                </td>
              </tr>

              <tr>
                <td className='text-center'>22</td>
                <td className='fw-bold'>
                  Cadangan Kegunaan Tanah Selaras Dengan Rancangan Tempatan
                </td>
                <td colSpan={colSpan}>
                  {project.physical_details?.accordance_with_local_plans
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>

              <tr>
                <td className='text-center'>23</td>
                <td className='fw-bold'>Cadangan Berkaitan Utiliti</td>
                <td colSpan={colSpan}>
                  {project.physical_details?.utility_related ? "Ya" : "Tidak"}
                </td>
              </tr>

              {/* Others */}
              <tr className='custom-table-head' style={{ height: 40 }}>
                <td className='border-end-0' />
                <th
                  className='border-start-0 align-middle'
                  colSpan={colSpan + 1}
                >
                  Maklumat Lain
                </th>
              </tr>

              <tr>
                <td className='text-center' rowSpan={4}>
                  25
                </td>
                <td className='fw-bold' rowSpan={4}>
                  Matriks Rangka Kerja Logik
                </td>
              </tr>

              <tr>
                <td colSpan={colSpan}>
                  <h5>Petunjuk Utama Prestasi</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.key_performance_indicators)} */}
                </td>
              </tr>
              <tr>
                <td colSpan={colSpan}>
                  <h5>Kaedah Pengesahan</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.validation_method)} */}
                </td>
              </tr>
              <tr>
                <td colSpan={colSpan}>
                  <h5>Andaian</h5>
                  {/* {!isEmpty(project.framework_matrix) && parse(JSON.parse(project.framework_matrix)?.assumption)} */}
                </td>
              </tr>

              <tr>
                <td
                  className='text-center'
                  rowSpan={
                    project?.human_resources_requirements?.length + 1 || 0
                  }
                >
                  26
                </td>
                <td
                  className='fw-bold'
                  rowSpan={
                    project?.human_resources_requirements?.length + 1 || 0
                  }
                >
                  Keperluan Sumber Manusia
                </td>
                <td
                  colSpan={2}
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                >
                  Bilangan Staff
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Nama Jawatan
                </td>
                <td
                  className='text-center'
                  style={{ backgroundColor: "#eff2f7" }}
                  colSpan={2}
                >
                  Gred Jawatan
                </td>
              </tr>
              {project?.human_resources_requirements?.map((item) => (
                <tr key={item.id}>
                  <td colSpan={2} className='text-center'>
                    {item.number_of_staff}
                  </td>
                  <td colSpan={2}>{item.position_name}</td>
                  <td colSpan={2} className='text-center'>
                    {item.position_gred}
                  </td>
                </tr>
              ))}
              <tr>
                <td className='text-center'>27</td>
                <td className='fw-bold'>
                  Seksyen UPEN
                  {errors?.upen_section && (
                    <p className='mb-0 text-error'>{errors?.upen_section}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.upen_section?.name}</td>
              </tr>
              <tr>
                <td className='text-center'>28</td>
                <td className='fw-bold'>
                  Agensi Permohonan
                  {errors?.applicant_agency && (
                    <p className='mb-0 text-error'>{errors.applicant_agency}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.applicant_agency?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>29</td>
                <td className='fw-bold'>
                  Agensi Pemilik
                  {errors?.owner_agency && (
                    <p className='mb-0 text-error'>{errors.owner_agency}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.owner_agency?.value}</td>
              </tr>
              <tr>
                <td className='text-center'>30</td>
                <td className='fw-bold'>
                  Agensi Bertanggungjawab (Kewangan)
                  {errors?.executing_agency && (
                    <p className='mb-0 text-error'>
                      {errors.financially_responsible_agency}
                    </p>
                  )}
                </td>
                <td colSpan={colSpan}>
                  {project?.financially_responsible_agency?.value}
                </td>
              </tr>
              <tr>
                <td className='text-center'>31</td>
                <td className='fw-bold'>
                  Agensi Pelaksana
                  {errors?.executing_agency && (
                    <p className='mb-0 text-error'>{errors.executing_agency}</p>
                  )}
                </td>
                <td colSpan={colSpan}>{project?.executing_agency?.value}</td>
              </tr>
            </tbody>
          </Table>

          <div className='d-flex justify-content-between'>
            <div>
              <Application />
            </div>
            <div className='d-flex gap-3'>
              <Button
                variant='light'
                onClick={handleBack}
                disabled={btnLoading}
              >
                Ubah
              </Button>
              {/* {showSendBtn && ( */}
              <Tooltip placement='top' title={tooltipTitle} arrow>
                <Button
                  variant='success'
                  onClick={handleNextProcess}
                  disabled={btnLoading}
                >
                  <Icon
                    className='me-2'
                    path={btnLoading ? mdiLoading : mdiSend}
                    size={0.8}
                    spin={btnLoading}
                  />
                  Seterusnya
                </Button>
              </Tooltip>
              {/* )} */}
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SummaryProjectForm;
