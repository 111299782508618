import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import usePalette from "../../../../hooks/usePalette";
import numeral from "numeral";
import { isEmpty } from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// icons
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";

// components
import AvailableProject from "./components/AvailableProject";

// redux
import {
  createNewProjectApplication,
  getCompletedProjectsWithCeilingBalance,
} from "../../../../redux/slices/jppn/jppnApplyNewProjectSlice";
import {
  setRemoveProject,
  setResetSelectedProject,
} from "../../../../redux/slices/jppn/jppnModuleSlice";
import { createProject } from "../../../../redux/slices/projects/projectSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const palette = usePalette();

  const [showModal, setShowModal] = useState(false);
  const [totalAvailableCeiling, setTotalAvailableCeiling] = useState(0);

  const { activeRmk, selectedpProjects, user } = useSelector((state) => ({
    selectedpProjects: state.jppnReducer.apply_new_project.selected_projects,
    activeRmk: state.rmkReducer.activeRmk,
    user: state.userReducer.user,
  }));

  const handleApplyNewProject = async () => {
    const newValues1 = {
      rmk_id: activeRmk.number,
      application_status: "1",
      ministry_id: Number(user.ministry?.code),
      department_id: user.department?.id,
      approval_stages_to: "JPPN",
    };

    const responseProject = await dispatch(createProject(newValues1));
    const dataProject = responseProject.payload;

    const newValues2 = {
      project_id: dataProject.id,
      purpose: `Kertas Kerja ini bertujuan untuk mendapatkan pertimbangan dan kelulusan daripada Jawatankuasa Perancang Pembangunan Negeri (JPPN) ke atas permohonan untuk perubahan skop ${dataProject?.name} dibawah ${activeRmk?.name} (${activeRmk?.short_form})`,
      projects: selectedpProjects,
    };
    const responseApplication = await dispatch(
      createNewProjectApplication(newValues2),
    );
    const dataApplication = responseApplication.payload;

    navigate(`/jppn/daftar-projek-baharu/${dataApplication.id}`);
  };

  const handleRemoveProject = (id) => {
    dispatch(setRemoveProject(id));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    dispatch(setResetSelectedProject());

    const props = {
      source: source,
      params: null,
    };

    const initialize = async () => {
      await dispatch(getCompletedProjectsWithCeilingBalance(props));
    };

    initialize();
    return () => {
      source.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    const total = selectedpProjects?.reduce((acc, item) => {
      const numericValue = numeral(item.jppn_ceiling_required).value();
      return acc + numericValue;
    }, 0);
    setTotalAvailableCeiling(total);
  }, [selectedpProjects]);

  return (
    <React.Fragment>
      <AvailableProject show={showModal} onHide={() => setShowModal(false)} />

      <Container>
        <Breadcrumbs title='JPPN' breadcrumbItem='Penyenaraian Projek Baharu' />
        <Card className='mt-3'>
          <Card.Header className='pb-0'>
            <div className='d-flex justify-content-between'>
              <h5>
                Senarai projek dengan maklumat baki siling yang telah dipilih
              </h5>
              <Button variant='secondary' onClick={() => setShowModal(true)}>
                + Projek
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <div className='table-responsive'>
              <Table size='sm' bordered>
                <thead className='text-uppercase'>
                  <tr>
                    <th className='text-center'>Bil.</th>
                    <th className='text-center'>Kod Projek</th>
                    <th>Nama Projek</th>
                    <th className='text-center'>Status Kemajuan</th>
                    <th className='text-center'>Baki Siling (RM)</th>
                    <th className='text-center'>Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  {isEmpty(selectedpProjects) ? (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        Tiada pilihan projek
                      </td>
                    </tr>
                  ) : (
                    <>
                      {selectedpProjects?.map((project, idx) => {
                        return (
                          <tr key={idx}>
                            <td className='text-center'>{idx + 1}</td>
                            <td>{project?.sub_project_full_code}</td>
                            <td>{project?.name}</td>
                            <td className='text-center px-2'>
                              <Badge
                                bg=''
                                className='text-uppercase w-100'
                                style={{
                                  backgroundColor:
                                    project?.current_progress_status?.color,
                                }}
                              >
                                {project?.current_progress_status?.label}
                              </Badge>
                            </td>
                            <td className='text-end pe-3'>
                              {numeral(project?.jppn_ceiling_required).format(
                                "0,0.00",
                              )}
                            </td>
                            <td>
                              <div
                                className='text-center'
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemoveProject(project.id)}
                              >
                                <Icon
                                  path={mdiCloseCircle}
                                  color={palette.danger}
                                  size='20px'
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={4} className='text-end'>
                          Jumlah
                        </td>
                        <td
                          className='text-end px-3'
                          style={{ fontWeight: 700 }}
                        >
                          {numeral(totalAvailableCeiling).format("0,0.00")}
                        </td>
                        <td />
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
        <div className='d-flex justify-content-end'>
          <Button onClick={handleApplyNewProject}>Mohon Projek Baharu</Button>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
