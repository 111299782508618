import React, { useEffect, useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { includes, isEmpty } from "lodash";

// Components
import ConfirmCertify from "../modals/ConfirmCertify";
import ConfirmReject from "../modals/ConfirmReject";
import RejectComponents from "../tables/reject-components/RejectComponents";
import FinancialDetails from "../tables/financial-details/FinancialDetails";
import CeilingFlow from "../tables/ceiling-flow/CeilingFlow";

// Redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";
import {
  setPage,
  setPerPage,
  setStagesFilter,
  setSearchFields,
} from "../../../../../redux/slices/certify/certifySlice";
import {
  updateCertify,
  rejectProject,
} from "../../../../../redux/slices/certify/certifiedComponents";

const CertifyStages = ({
  eventKey,
  scopeProjectRef,
  ceilingFlowRef,
  handleNotification,
  paginations,
  filters,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();

  const { stages, projectId } = routeParams;

  const [certifiedId, setCertifyId] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [showColumn, setShowColumn] = useState({
    jppn: false,
    cabinet: false,
  });
  const [prevCost, setPrevCost] = useState([]);
  const [isError, setIsError] = useState(false);

  const { certifyComponents, rejectedComponents, project, btnLoading } =
    useSelector((state) => ({
      project: state.certifyReducer.project.data,
      btnLoading: state.certifyReducer.btnLoading,
      certifyComponents: state.utilsReducer.certifyFinancial,
      rejectedComponents: state.utilsReducer.rejectedComponents,
    }));

  const handleChangeInitialCost = (cost) => {
    const { id, cost_required } = cost;
    const changeInitialCost = prevCost.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          cost_required: parseFloat(cost_required),
        };
      }
      return item;
    });

    setPrevCost(changeInitialCost);
  };

  const handleCertifyCost = (cost) => {
    const { id } = cost;

    const isExist = prevCost.some((item) => item.id === id);
    if (isExist) {
      const removeItem = prevCost.filter((item) => item.id !== id);
      setPrevCost(removeItem);
    } else {
      setPrevCost((prev) => [...prev, cost]);
    }
  };

  const handleReject = async () => {
    setOpenReject(true);
  };

  const handleConfirmReject = async () => {
    await dispatch(rejectProject(projectId));

    await dispatch(setPage(paginations.page));
    await dispatch(setPerPage(paginations.perPage));
    await dispatch(setStagesFilter(filters.stagesFilter));
    await dispatch(setSearchFields(filters.searchFields));

    navigate(`/perakuan/senarai-projek`);
    setOpenReject(false);

    const values = {
      message: "Projek telah berjaya ditolak",
      type: "error",
    };

    handleNotification(values);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleSubmitConfirmation = async () => {
    const groupedData = certifyComponents.reduce((acc, obj) => {
      const key = `${obj.financial_detail_id}_${obj.cost_description}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

    const components = Object.entries(groupedData).map(
      ([uniqueId, ceiling_flow_details]) => {
        const splitText = uniqueId.split("_");

        const totalAmountComponent = ceiling_flow_details.reduce(
          (acc, item) => acc + parseFloat(item.amount),
          0,
        );

        let cost_required = 0;

        const isTrue = prevCost.some(
          (item) => item.id === Number(splitText[0]),
        );

        if (isTrue) {
          const obj = prevCost.find((item) => item.id === Number(splitText[0]));
          cost_required = obj.cost_required;
        } else {
          cost_required = totalAmountComponent;
        }

        return {
          id: Number(splitText[0]),
          cost_description: splitText[1],
          ceiling_flow_details,
          ceiling: totalAmountComponent,
          cost_required: cost_required,
        };
      },
    );

    let rejected = null;

    if (!isEmpty(rejectedComponents)) {
      rejected = {
        status: 7,
        components: rejectedComponents,
      };
    }

    let status = 3;
    let stages = Number(project.stages) + 1;

    if (project.stages === "6") {
      status = Number(project.application_status) + 1;
      stages = Number(project.stages);
    }

    const newValues = {
      projectId: project.id,
      main_project_id: project.main_project_id,
      application_status: status,
      stages: stages,
      certify_components: components,
      rejected_components: rejected,
    };

    await dispatch(updateCertify(newValues));
    handleCloseConfirm();

    await dispatch(setPage(paginations.page));
    await dispatch(setPerPage(paginations.perPage));
    await dispatch(setStagesFilter(filters.stagesFilter));
    await dispatch(setSearchFields(filters.searchFields));

    navigate("/perakuan/senarai-projek");

    window.localStorage.removeItem("TEMP_CERTIFY_DATA");
  };

  const handleIsError = (item) => {
    setIsError(item);
  };

  useEffect(() => {
    if (isEmpty(project)) return;

    switch (project.stages) {
      case "4": // UPEN
        setShowColumn({
          jppn: false,
          cabinet: false,
        });
        break;

      case "5": // JPPN
        setShowColumn({
          jppn: true,
          cabinet: false,
        });
        break;

      case "6": // JPPN
        setShowColumn({
          jppn: true,
          cabinet: true,
        });
        break;
    }
  }, [project]);

  return (
    <React.Fragment>
      <ConfirmReject
        openReject={openReject}
        handleCloseReject={() => setOpenReject(false)}
        handleConfirmReject={handleConfirmReject}
      />

      <ConfirmCertify
        openConfirm={openConfirm}
        handleCloseConfirm={handleCloseConfirm}
        handleSubmitConfirmation={handleSubmitConfirmation}
      />

      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          <span className='fw-bolder'>PERAKUAN KEWANGAN DAN SKOP PROJEK</span>
        </Accordion.Header>
        <Accordion.Body className='border-primary p-0 border border-top-0'>
          <Card className='mb-0'>
            <Card.Body>
              <FinancialDetails
                showColumn={showColumn}
                stages={project.stages}
                handleCertifyCost={handleCertifyCost}
                handleIsError={handleIsError}
                handleChangeInitialCost={handleChangeInitialCost}
                scopeProjectRef={scopeProjectRef}
              />

              {project.stages === "4" && <RejectComponents />}

              <CeilingFlow
                showColumn={showColumn}
                ceilingFlowRef={ceilingFlowRef}
                stages={project.stages}
              />

              <div className='d-flex justify-content-end gap-3 mt-3'>
                <Button variant='outline-danger' onClick={handleReject}>
                  Tolak
                </Button>
                {/* <Button variant="primary">Draf</Button> */}
                <Button
                  variant='success'
                  onClick={handleOpenConfirm}
                  disabled={btnLoading || isError}
                >
                  {project.stages === "6" ? "Lulus" : "Peraku"}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </React.Fragment>
  );
};

export default CertifyStages;
