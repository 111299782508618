import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getActivities = createAsyncThunk(
  "activity/getActivities",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(`/admin/activity-log/get?with=query`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getLoginHistories = createAsyncThunk(
  "activity/getLoginHistories",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(`/admin/login-history/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

const initialState = {
  activities: [],
  loginHistories: {
    data: [],
    page: 1,
    perPage: 30,
  },
  isLoading: false,
  links: {},
  meta: {},
};

export const activityLogSlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    loginRecordSetPage: (state, action) => {
      state.loginHistories.page = action.payload;
    },
    loginRecordSetPerPage: (state, action) => {
      state.loginHistories.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getActivities.fulfilled, (state, action) => {
      const { data, links, meta } = action.payload;

      state.isLoading = false;

      state.activities = data;
      state.links = links;
      state.meta = meta;
    });

    builder.addCase(getLoginHistories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoginHistories.fulfilled, (state, action) => {
      state.loginHistories.data = action.payload;
      state.isLoading = false;
    });
  },
});

export const { loginRecordSetPage, loginRecordSetPerPage } =
  activityLogSlice.actions;

export default activityLogSlice.reducer;
