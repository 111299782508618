import React, { useState, useEffect } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// Components
import Filters from "./components/Filters";
import MoreSearchField from "./components/MoreSearchField";
import TableProjects from "./components/tables/TableProjects";

// Redux
import { getProjects } from "../../../redux/slices/projects/projectsSlice";

const container = {
  show: {
    transition: {
      staggerChildren: 0.08,
    },
  },
};

const filterItem = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
};

const item = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
};

const applicationCategoryOptions = [
  {
    id: 1,
    code: "B",
    name: "Baru",
  },
  {
    id: 2,
    code: "S",
    name: "Sambungan",
  },
  {
    id: 7,
    code: "B-KSP",
    name: "Baru (KSP)",
  },
  {
    id: 8,
    code: "S-KSP",
    name: "Sambungan (KSP)",
  },
  {
    id: 9,
    code: "SA-KSP",
    name: "Sedia Ada (KSP)",
  },
];

const MainPage = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();

  const { applicationType } = routeParams;

  const [showCanvas, setShowCanvas] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [title, setTitle] = useState("");

  const { user, page, perPage } = useSelector((state) => ({
    user: state.userReducer.user,
    projects: state.projectReducer.projects,
    page: state.projectReducer.pagination.page,
    perPage: state.projectReducer.pagination.perPage,
  }));

  const handleSearch = debounce((text) => {
    console.log(text);
  }, 500);

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleHideCanvas = () => {
    setShowCanvas(false);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        per_page: perPage,
        page: page,
      },
      applicationType: applicationType,
    };

    const initialize = async () => {
      await dispatch(getProjects(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [applicationType, page, perPage]);

  return (
    <React.Fragment>
      <MoreSearchField showCanvas={showCanvas} closeCanvas={handleHideCanvas} />

      <Helmet title='Senarai Projek' />

      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Permohonan'
          breadcrumbItem={`Senarai Projek ${title}`}
        />
        <motion.div
          variants={container}
          initial='hidden'
          animate='show'
          className='mt-3'
        >
          <Filters
            filterItem={filterItem}
            handleSearch={handleSearch}
            handleShowCanvas={handleShowCanvas}
          />
          <Card>
            <Card.Body>
              <TableProjects item={item} />
            </Card.Body>
          </Card>
        </motion.div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
