import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

//Styles
import { customStyles } from "../../../../../components/common/customStyleSelect";

// Icons
import { MdFilterList, MdSearch } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

// Redux
import { setKeywords } from "../../../../../redux/slices/jppn/jppnModuleSlice";

const Filters = ({ handleShowCanvas }) => {
  const dispatch = useDispatch();

  const keywords = useSelector((state) => state.jppnReducer.projects.keywords);

  const handleSearch = debounce((text) => {
    dispatch(setKeywords(text));
  }, 900);

  return (
    <div className='d-flex align-items-center gap-2 mb-3'>
      <div className='remove-outline-item'>
        <Tooltip title='Lebih Pilihan' placement='top' arrow>
          <Button variant='secondary' onClick={handleShowCanvas}>
            <Icon path={mdiFilterVariant} size={0.8} />
          </Button>
        </Tooltip>
      </div>
      <div className='search-box-custom'>
        <div className='position-relative'>
          <label htmlFor='search-bar-0' className='search-label-custom'>
            <input
              type='text'
              className='form-control'
              placeholder='Cari nama projek'
              // value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 280 }}
            />
          </label>
          <MdSearch className='search-icon-2' size={18} />
        </div>
      </div>
    </div>
  );
};

export default Filters;
