import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const returnApplication = createAsyncThunk(
  "project/returnApplication",
  async (values) => {
    try {
      const response = await axios.post(`/return-applications`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const acknowledgedItem = createAsyncThunk(
  "project/acknowledgedItem",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/return-applications/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);
