import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import axios from "axios";
import usePalette from "../../../hooks/usePalette";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { mdiViewListOutline, mdiViewSequential, mdiTrashCan } from "@mdi/js";
import { MdKeyboardBackspace } from "react-icons/md";

// Components
import ProfileInfo from "./components/ProfileInfo";
import LoadingState from "../../../components/common/LoadingState";
import SummaryView from "../project-profile/components/view-mode/SummaryView";
import ModalDelete from "../../../components/common/ModalDelete";
import NotyfContext from "../../../contexts/NotyfContext";

// Redux
import { fetchProjectDetails } from "../../../redux/slices/projects/projectSlice";
import { removeProject } from "../../../redux/slices/projects/projectsSlice";
import {
  getBranches,
  getOptions,
  getProgressStatusOptions,
} from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const routeParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const palette = usePalette();
  const notyf = useContext(NotyfContext);

  const { projectId } = routeParams;

  const [viewMode, setViewMode] = useState("1");
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [data, setData] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const { isLoading, project, user } = useSelector((state) => ({
    isLoading: state.projectReducer.isLoading,
    project: state.projectReducer.project,
    user: state.userReducer.user,
  }));

  const handleGoBack = () => {
    navigate(-1);
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const itemTop = {
    hidden: { opacity: 0, y: -20 },
    show: { opacity: 1, y: 0 },
  };

  const itemRight = {
    hidden: { opacity: 0, x: -20 },
    show: { opacity: 1, x: 0 },
  };

  const handleViewMode = (e) => {
    setViewMode(e.currentTarget.value);
  };

  const handleRemoveProject = () => {
    setShowModalDelete(true);
    setData(projectId);
  };

  const handleConfirmRemove = async (projectId) => {
    await dispatch(removeProject(projectId));
    handleNotification();
    navigate(`/senarai-projek`);
  };

  // Notification
  const handleNotification = () => {
    // Success
    notyf.open({
      type: "error",
      message: `${project.name} telah dipadam.`,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
      project_id: projectId,
    };
    dispatch(fetchProjectDetails(props));
    return () => {
      source.cancel();
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    if (!isEmpty(project)) {
      const props = {
        source: source,
        params: {
          ministry_id: project?.ministry?.code || null,
        },
      };
      dispatch(getOptions(props));
      dispatch(getBranches(props));
      dispatch(getProgressStatusOptions(props));
    }

    return () => {
      source.cancel();
    };
  }, [dispatch, project]);

  useEffect(() => {
    if (!isEmpty(user)) {
      const isSuperadmin = user.roles.includes("superadmin");
      setShowButton(isSuperadmin);
    }
  }, [user]);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <React.Fragment>
      <Helmet title='Profil Projek' />
      <Container fluid className='p-0'>
        <ModalDelete
          showRemove={showModalDelete}
          closeRemove={() => setShowModalDelete(false)}
          title={`${project.name} akan dipadam!`}
          message={`Segala maklumat projek akan dipadam. Teruskan?`}
          data={data}
          confirmRemove={handleConfirmRemove}
        />

        <motion.div variants={container} initial='hidden' animate='show'>
          <motion.div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
            className='mb-3'
          >
            <IconButton
              size='small'
              aria-label='Senarai Projek'
              onClick={handleGoBack}
            >
              <MdKeyboardBackspace size={24} />
            </IconButton>
            <span className='ms-1'>Senarai Projek</span>
          </motion.div>

          <div className='d-flex justify-content-between mb-3'>
            <h4 className='align-self-center mb-0'>Maklumat Projek</h4>
            <ButtonGroup size='sm'>
              <Tooltip title='Maklumat Terperinci' placement='top' arrow>
                <ToggleButton
                  id={`radio-1`}
                  name='default'
                  type='radio'
                  variant={viewMode === "1" ? "secondary" : "outline-secondary"}
                  value='1'
                  checked={viewMode === "1"}
                  onChange={(e) => handleViewMode(e)}
                >
                  <Icon path={mdiViewSequential} size={1} />
                </ToggleButton>
              </Tooltip>
              <Tooltip title='Ringkasan Maklumat' placement='top' arrow>
                <ToggleButton
                  id={`radio-2`}
                  name='groupBy'
                  type='radio'
                  variant={viewMode === "2" ? "secondary" : "outline-secondary"}
                  value='2'
                  checked={viewMode === "2"}
                  onChange={(e) => handleViewMode(e)}
                >
                  <Icon path={mdiViewListOutline} size={1} />
                </ToggleButton>
              </Tooltip>
            </ButtonGroup>
          </div>

          {viewMode === "1" && (
            <ProfileInfo itemRight={itemRight} itemTop={itemTop} />
          )}
          {viewMode === "2" && <SummaryView />}

          {showButton && (
            <div className=''>
              <Tooltip title='Padam Projek' arrow>
                <IconButton
                  size='small'
                  className='p-0'
                  color='error'
                  onClick={handleRemoveProject}
                >
                  <Icon path={mdiTrashCan} size={1} />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </motion.div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
