import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Badge, Button, Form, Table, Row, Col, Card } from "react-bootstrap";
import numeral from "numeral";
import { isEmpty, isNull } from "lodash";
import parse from "html-react-parser";
import { Divider } from "@mui/material";

// Icons
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiPencil, mdiCheck, mdiNoteTextOutline, mdiCircle } from "@mdi/js";

// Components
import usePalette from "../../../../../hooks/usePalette";
import RequestedCeilingForm from "../modals/RequestedCeilingForm";
import NotesSection from "../modals/NotesSection";

// Redux
import { removeFinancialDetails } from "../../../../../redux/slices/projects/financialDetailsSlice";

const TableRow = ({
  row,
  btnLoading,
  handleEditItem,
  matchId,
  handleMatchId,
  handleClearMatchId,
  handleOpenNoteSection,
}) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [open, setOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState([]);

  const { financialDetails, ceilingItems } = useSelector((state) => ({
    financialDetails: state.projectReducer.project.financial_details,
    ceilingItems: state.utilsReducer.ceilingItems,
  }));

  const handleRemoveItem = (id) => {
    dispatch(removeFinancialDetails(id));
  };

  useEffect(() => {
    const groupedCost = ceilingItems.reduce((acc, item) => {
      const { financial_detail_id } = item;
      if (!acc[financial_detail_id]) {
        acc[financial_detail_id] = [];
      }
      acc[financial_detail_id].push(item);
      return acc;
    }, {});

    const { id } = row;

    const existingId = Object.keys(groupedCost);

    setDisableBtn(existingId);
    return () => {
      setDisableBtn([]);
    };
  }, [ceilingItems]);

  return (
    <React.Fragment>
      <tr style={{ "& > *": { borderBottom: "unset" } }}>
        <td>{row.cost_description}</td>
        <td className='text-truncate text-end'>
          {`RM ${numeral(row.cost_required).format("0,0.00")}`}
        </td>
        <td className='text-center'>
          <Tooltip
            title='Lhat catatan'
            placement='top'
            followCursor
            arrow
            className='p-0'
          >
            <span>
              <IconButton
                onClick={() => handleOpenNoteSection(row)}
                className='button-animated position-relative'
                style={{ backgroundColor: "transparent" }}
              >
                <Icon
                  className=''
                  path={mdiNoteTextOutline}
                  size={1}
                  color={palette.primary}
                />
                {financialDetails?.find((item) => item.id === row.id)
                  .financial_detail_notes.length !== 0 && (
                  <Icon
                    className='position-absolute'
                    path={mdiCircle}
                    size={0.55}
                    style={{ top: 5, right: 5 }}
                    color='#ef5350'
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </td>
        <td>
          <div className='d-flex justify-content-center'>
            <Tooltip title='Edit' placement='top' arrow>
              <span>
                <IconButton
                  color='primary'
                  size='small'
                  aria-label='padam'
                  disabled={
                    btnLoading || disableBtn.includes(row.id.toString())
                  }
                  onClick={() => handleEditItem(row)}
                >
                  <MdEdit fontSize='large' />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Padam' placement='top' arrow>
              <span>
                <IconButton
                  color='error'
                  size='small'
                  aria-label='padam'
                  disabled={
                    btnLoading || disableBtn.includes(row.id.toString())
                  }
                  onClick={() => handleRemoveItem(row.id)}
                >
                  <MdDelete fontSize='large' />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

const FinancialInformation = ({
  notifyError,
  project,
  projectId,
  handleNotification,
  btnLoading,
  enableBtnSave,
}) => {
  const [openForm, setOpenForm] = useState(false);
  const [totalCost, setTotalCost] = useState("");
  const [disableBtn, setDisableBtn] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [matchId, setMatchId] = useState(null);
  const [item, setItem] = useState({});

  const [openNotes, setOpenNotes] = useState(false);

  const handleCloseForm = () => {
    setItem({});
    setOpenForm(false);
    setIsEdit(false);
  };

  const handleEditItem = (item) => {
    setItem(item);
    setOpenForm(true);
    setIsEdit(true);
  };

  const handleMatchId = (id) => {
    setMatchId(id);
  };

  const handleOpenNoteSection = (item, section) => {
    setOpenNotes(true);
    setItem(item);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
    setItem({});
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const total = project.financial_details.reduce(
        (acc, item) => acc + parseFloat(item.cost_required),
        0,
      );
      setTotalCost(total);
    }
  }, [project]);

  return (
    <React.Fragment>
      <NotesSection
        openNotes={openNotes}
        handleCloseNotes={handleCloseNotes}
        data={item}
        section='financial'
      />

      <RequestedCeilingForm
        openRequestCeilingForm={openForm}
        toggleRequestCeilingForm={handleCloseForm}
        data={item}
        project={project}
        handleNotification={handleNotification}
        btnLoading={btnLoading}
        isEdit={isEdit}
      />
      <Card.Header className='pb-0'>
        {enableBtnSave && (
          <div className='card-actions float-end'>
            <Button
              size='sm'
              variant='secondary'
              onClick={() => setOpenForm(true)}
              disabled={btnLoading}
            >
              + Maklumat
            </Button>
          </div>
        )}

        <Card.Text className='text-sm'>
          Sila isi maklumat kewangan dengan teliti.
        </Card.Text>
      </Card.Header>
      <Card.Body className='m-0'>
        <div className='table-responsive'>
          <Table size='sm' style={{ fontSize: 12 }}>
            <thead>
              <tr>
                <th className='text-truncate' style={{ width: "40%" }}>
                  Keterangan Kos Dipohon
                </th>
                <th className='text-end text-truncate'>Jumlah Kos Dipohon</th>
                <th className='text-center'>Catatan</th>
                <th className='text-center'>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              {project?.financial_details?.length === 0 ? (
                <tr className={`${notifyError ? "bg-error-form" : ""}`}>
                  <td colSpan={7} className={`text-center fst-italic`}>
                    <span className={`${notifyError ? "text-error" : ""}`}>
                      {notifyError
                        ? "Sila isi maklumat kewangan"
                        : "Maklumat Tidak Tersedia"}
                    </span>
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {project?.financial_details?.map((row, idx) => (
                    <TableRow
                      key={row.id}
                      row={row}
                      btnLoading={btnLoading}
                      handleEditItem={handleEditItem}
                      matchId={matchId}
                      handleMatchId={handleMatchId}
                      handleOpenNoteSection={handleOpenNoteSection}
                    />
                  ))}
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </div>

        <div className='mb-3'>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column sm={4} className='text-end'>
              Jumlah Kos Dipohon
            </Form.Label>
            <Col sm={3}>
              <Form.Control
                className='text-end fw-bold'
                type='text'
                placeholder='RM 0.00'
                readOnly
                value={`RM ${numeral(totalCost).format("0,0.00")}`}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column sm={4} className='text-end'>
              Jumlah Siling
            </Form.Label>
            <Col sm={3}>
              <Form.Control
                className='text-end fw-bold'
                type='text'
                placeholder='RM 0.00'
                readOnly
                value={`RM ${numeral(totalCost).format("0,0.00")}`}
              />
            </Col>
          </Form.Group>
        </div>
      </Card.Body>
    </React.Fragment>
  );
};

export default FinancialInformation;
