import React from "react";
import { Badge, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { Tooltip, IconButton } from "@mui/material";
import { isEmpty } from "lodash";

const GroupRoles = ({ handleEditItem, handleRemoveItem }) => {
  const { groupRoles } = useSelector((state) => ({
    groupRoles: state.roleReducer.groupRoles,
  }));

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center'>Bil</th>
              <th>Name</th>
              <th>Tahap Akses</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {isEmpty(groupRoles) ? (
              <tr>
                <td colSpan={4} className='text-center'>
                  Tiada maklumat tersedia
                </td>
              </tr>
            ) : (
              <>
                {groupRoles?.map((item, idx) => {
                  const accessTo = item?.access_to?.split(", ");
                  return (
                    <tr key={idx}>
                      <td className='text-center'>{idx + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        {accessTo?.map((obj) => (
                          <Badge key={obj} className='me-1'>
                            {obj}
                          </Badge>
                        ))}
                      </td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          <Tooltip title='Edit' placement='top' arrow>
                            <IconButton
                              color='info'
                              size='small'
                              aria-label='Edit'
                              onClick={() => handleEditItem(item)}
                            >
                              <MdModeEditOutline fontSize='large' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Padam' placement='top' arrow>
                            <IconButton
                              color='error'
                              size='small'
                              aria-label='Padam'
                              onClick={() => handleRemoveItem(item.id)}
                            >
                              <MdDelete fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default GroupRoles;
