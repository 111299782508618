import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingState from "../../../../../components/common/LoadingState";
import usePalette from "../../../../../hooks/usePalette";
import WebMapArcgis from "./maps/WebMapArcgis";

// icons
import Icon from "@mdi/react";
import { mdiCloseCircle, mdiArrowRightBold } from "@mdi/js";

// Redux
import {
  setAddCoordinate,
  changeCoordinate,
  removeCoordinate,
} from "../../../../../redux/slices/jppn/jppnModuleSlice";

const LocationChanges = ({ nextPage }) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const routeParams = useParams();

  const [projectCode, setProjectCode] = useState("");
  const [projectName, setProjectName] = useState("");
  const [executingAgency, setExecutingAgency] = useState("");
  const [projectCategory, setProjectCategory] = useState("");
  const [siteInformations, setSiteInformations] = useState(null);

  const {
    projectIsLoading,
    currentRmk,
    project,

    isLoading,

    application,
    draft,
  } = useSelector((state) => ({
    projectIsLoading: state.projectReducer.isLoading,
    currentRmk: state.rmkReducer.activeRmk,
    project: state.projectReducer.project,
    isLoading: state.jppnReducer.virement.isLoading,

    application: state.jppnReducer.application,
    draft: state.jppnReducer.draft,
  }));

  const handleAddCoordinate = () => {
    const newCoordinate = {
      latitude: "",
      longitude: "",
    };
    dispatch(setAddCoordinate(newCoordinate));
  };

  useEffect(() => {
    const { type, category } = routeParams;
    if (type === "kemas-kini") {
      if (category === "kenaikan-siling") {
        setProjectCode(
          application?.virement_projects[0]?.project?.sub_project_full_code,
        );
        setExecutingAgency(
          `${application?.virement_projects[0]?.project?.executing_agency?.name} (${application?.virement_projects[0]?.project?.executing_agency?.short_form})`,
        );
      }
      if (category === "perubahan-nama-projek") {
        setProjectCode(application?.project?.sub_project_full_code);
        setExecutingAgency(
          `${application?.project?.executing_agency?.name} (${application?.project?.executing_agency?.short_form})`,
        );
        setProjectCategory(application?.project?.project_category?.name);
      }
      if (category === "ubah-lokasi") {
        setProjectCode(application?.project?.sub_project_full_code);
        setProjectName(application?.project?.name);
        setExecutingAgency(
          `${application?.project?.executing_agency?.name} (${application?.project?.executing_agency?.short_form})`,
        );
        setProjectCategory(application?.project?.project_category?.name);
        setSiteInformations(application?.project?.site_informations);
      }
    } else {
      setProjectCode(project?.sub_project_full_code);
      setProjectName(project?.name);
      setExecutingAgency(
        `${project?.executing_agency?.value} (${project?.executing_agency?.short_form})`,
      );
      setProjectCategory(project?.project_category?.value);
      setSiteInformations(project?.site_informations);
    }
  }, [routeParams, application, project]);

  if (projectIsLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <div className='mb-3'>
        <h4 className='mb-3'>Maklumat Projek</h4>
        <div className='table-responsive'>
          <Table bordered size='sm' className='mb-0'>
            <tbody>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Kod
                </th>
                <td colSpan={4}>
                  {/* {routeParams.type === "kemas-kini"
                    ? application?.virement_projects[0]?.project
                        ?.sub_project_full_code
                    : project?.sub_project_full_code} */}
                  {projectCode}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Nama Projek
                </th>
                <td colSpan={4}>{projectName}</td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Agensi Pelaksana
                </th>
                <td colSpan={4}>
                  {/* {routeParams.type === "kemas-kini"
                    ? `${application?.virement_projects[0]?.project?.executing_agency?.name} (${application?.virement_projects[0]?.project?.executing_agency?.short_form})`
                    : `${project?.executing_agency?.value} (${project?.executing_agency?.short_form})`} */}
                  {executingAgency}
                </td>
              </tr>

              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Kategori Projek
                </th>
                <td colSpan={4}>
                  {/* {routeParams.type === "kemas-kini"
                    ? application?.virement_projects[0]?.project
                        ?.project_category?.name
                    : project?.project_category?.value} */}
                  {projectCategory}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ fontWeight: 700 }}>
                  Lokasi Asal
                </td>
              </tr>
              <tr>
                <th>Daerah</th>
                <td colSpan={4}>
                  {siteInformations?.district?.value ||
                    "Tiada maklumat tersedia"}
                </td>
              </tr>
              <tr>
                <th>Parlimen</th>
                <td colSpan={4}>
                  {siteInformations?.parliament?.value ||
                    "Tiada maklumat tersedia"}
                </td>
              </tr>
              <tr>
                <th>DUN</th>
                <td colSpan={4}>
                  {siteInformations?.state_legislative_assembly?.value ||
                    "Tiada maklumat tersedia"}
                </td>
              </tr>
              {/* <tr>
                <td colSpan={4} style={{ fontWeight: 700 }}>
                  <div className='d-flex align-items-center'>
                    <div className='flex-grow-1'>Lokasi Baharu</div>
                    <Button
                      variant='secondary'
                      className='me-3'
                      onClick={handleAddCoordinate}
                    >
                      + Koordinat
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <th className='text-center'>Bil.</th>
                <th>Latitud</th>
                <th>Longitud</th>
                <th />
              </tr>
              {draft?.coordinates?.map((item, idx) => {
                return (
                  <tr>
                    <td className='text-center'>{idx + 1}</td>
                    <td>
                      <Form.Group>
                        <Form.Control
                          name='latitude'
                          className={`text-end text-truncate`}
                          type='text'
                          value={item?.latitude}
                          onChange={(e) => {
                            const newValues = {
                              rowIdx: idx,
                              name: "latitude",
                              value: e.target.value,
                            };
                            dispatch(changeCoordinate(newValues));
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group>
                        <Form.Control
                          name='longitude'
                          className={`text-end text-truncate`}
                          type='text'
                          value={item?.longitude}
                          onChange={(e) => {
                            const newValues = {
                              rowIdx: idx,
                              name: "longitude",
                              value: e.target.value,
                            };
                            dispatch(changeCoordinate(newValues));
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td align='middle'>
                      <div
                        className='text-center'
                        onClick={() => dispatch(removeCoordinate(idx))}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon
                          path={mdiCloseCircle}
                          size='22px'
                          color={palette.danger}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })} */}
            </tbody>
          </Table>
        </div>
        <div className='py-3'>
          <div className='position-relative mb-3' style={{ height: 520 }}>
            {/* <div
                  className='position-absolute esri-widget--button esri-widget esri-interactive'
                  style={{ top: "16.5%", left: 15, zIndex: 9999 }}
                >
                  <Icon
                    path={mdiArrowExpandAll}
                    size='20px'
                    onClick={() => setExpandMap(true)}
                  />
                </div> */}

            <WebMapArcgis />
          </div>
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button onClick={nextPage}>Seterusnya</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocationChanges;
