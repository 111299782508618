import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { Tooltip } from "@mui/material";
import usePalette from "../../../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiRestore } from "@mdi/js";

// redux
import { setResetState } from "../../../../../../redux/slices/dashboard/dashboardSlice";

const ProjectsProgressStatusChart = () => {
  const dispatch = useDispatch();
  const palette = usePalette();

  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [group, setGroup] = useState("");

  const {
    progressStatusByMinistries,
    progressStatusByDepartments,
    progressStatusByPrograms,
    progressStatusByMainProjects,
    selectMinistry,
    navFilter,
  } = useSelector((state) => ({
    progressStatusByMinistries:
      state.dashboardReducer.combine.progressStatusByMinistries,
    progressStatusByDepartments:
      state.dashboardReducer.combine.progressStatusByDepartments,
    progressStatusByPrograms:
      state.dashboardReducer.combine.progressStatusByPrograms,
    progressStatusByMainProjects:
      state.dashboardReducer.combine.progressStatusByMainProjects,
    selectMinistry: state.dashboardReducer.selectMinistry,
    navFilter: state.dashboardReducer.navFilter,
  }));

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    legend: {
      show: true,
      position: "top",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ["#4caf50", "#ffc107", "#f44336"],
    labels: labels,
    fill: {
      opacity: 1,
    },
    yaxis: {
      title: {
        text: "Bilangan Projek",
        rotate: -90,
        style: {
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => {
          return value.toFixed(0);
        },
      },
      tickAmount: 1,
    },
    xaxis: {
      labels: {
        trim: true,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      inverseOrder: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="apexcharts-tooltip-title text-center" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: 700">
            ${w.config.labels[dataPointIndex]}
          </div>

          ${w.config.series
            .toReversed()
            .map(
              (s) => `
            <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${
                s.color
              };"></span>
              <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group">
                  <span class="apexcharts-tooltip-text-y-label">
                    ${s.name.toLocaleUpperCase()}:
                  </span>
                  <span class="apexcharts-tooltip-text-y-value">
                    ${numeral(s.data[dataPointIndex]).format("0,0")}
                  </span>
                </div>
              </div>
            </div>
            `,
            )
            .join("")}
          <div div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;" >
            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">
                  JUMLAH PROJEK:
                </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${numeral(
                    series
                      .map((s) => s[dataPointIndex])
                      .reduce((a, b) => a + b, 0),
                  ).format("0,0")}
                </span>
                </div>
              </div>
          </div >`;
      },
    },
  };

  useEffect(() => {
    if (navFilter === "ministry") {
      setGroup("Kementerian");

      const ministryLabel = progressStatusByMinistries.map((item) => {
        const label = `${item.ministry.short_form}`;
        return label;
      });

      const progressStatus = progressStatusByMinistries.flatMap(
        (ministry) => ministry.projects_progress_status,
      );

      const groupedData = progressStatus.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = { data: [], color: curr.color };
        }
        acc[curr.name].data.push(curr.count);
        return acc;
      }, {});

      const result = Object.entries(groupedData).map(
        ([name, { data, color }]) => ({
          name: name,
          data: data,
          color: color,
        }),
      );

      setLabels(ministryLabel);
      setSeries(result);
    }
    if (navFilter === "department") {
      setGroup("Jabatan");

      const departmentLabel = progressStatusByDepartments.map((item) => {
        const label = `${item.department.short_form}`;
        return label;
      });

      const progressStatus = progressStatusByDepartments.flatMap(
        (department) => department.projects_progress_status,
      );

      const groupedData = progressStatus.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = { data: [], color: curr.color };
        }
        acc[curr.name].data.push(curr.count);
        return acc;
      }, {});

      const result = Object.entries(groupedData).map(
        ([name, { data, color }]) => ({
          name: name,
          data: data,
          color: color,
        }),
      );

      setLabels(departmentLabel);
      setSeries(result);
    }
    if (navFilter === "program") {
      setGroup("Program");
      const programLabel = progressStatusByPrograms.map((item) => {
        const label = `${item.program.name}`;
        return label;
      });

      const progressStatus = progressStatusByPrograms.flatMap(
        (program) => program.projects_progress_status,
      );

      const groupedData = progressStatus.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = { data: [], color: curr.color };
        }
        acc[curr.name].data.push(curr.count);
        return acc;
      }, {});

      const result = Object.entries(groupedData).map(
        ([name, { data, color }]) => ({
          name: name,
          data: data,
          color: color,
        }),
      );

      setLabels(programLabel);
      setSeries(result);
    }
    if (navFilter === "main-project") {
      setGroup("Projek Utama");
      const mainProjectLabel = progressStatusByMainProjects.map((item) => {
        const label = `${item.mainProject.name}`;
        return label;
      });

      const progressStatus = progressStatusByMainProjects.flatMap(
        (mainProject) => mainProject.projects_progress_status,
      );

      const groupedData = progressStatus.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = { data: [], color: curr.color };
        }
        acc[curr.name].data.push(curr.count);
        return acc;
      }, {});

      const result = Object.entries(groupedData).map(
        ([name, { data, color }]) => ({
          name: name,
          data: data,
          color: color,
        }),
      );

      setLabels(mainProjectLabel);
      setSeries(result);
    }
  }, [navFilter]);

  return (
    <React.Fragment>
      <Card
        as={motion.div}
        variants={{
          hidden: { opacity: 0, x: -20 },
          show: { opacity: 1, x: 0 },
        }}
        transition={{ duration: 0.3 }}
        className='flex-fill position-relative'
      >
        <div className='position-absolute' style={{ top: 15, right: 10 }}>
          <Tooltip title='Set Semula' placement='top' arrow>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(setResetState())}
            >
              <Icon path={mdiRestore} size={1} color={palette.primary} />
            </div>
          </Tooltip>
        </div>
        <Card.Header>
          <Card.Title className='text-uppercase'>
            Maklumat kemajuan prestasi fizikal mengikut {group}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {!isEmpty(series) && (
            <div className='chart'>
              <Chart
                options={options}
                series={series}
                type='bar'
                height='350'
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ProjectsProgressStatusChart;
