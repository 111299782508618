import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import usePalette from "../../../../../hooks/usePalette";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

// Icons
import Icon from "@mdi/react";
import { mdiPlus, mdiTrashCan } from "@mdi/js";

// Redux
import {
  getCriteria,
  addCriteria,
  updateCriteria,
  deleteCriteria,
} from "../../../../../redux/slices/certify/certifySlice";
import { createCreditScore } from "../../../../../redux/slices/certify/projectsSlice";

const CreditScoreForm = ({ show, close }) => {
  const palette = usePalette();
  const dispatch = useDispatch();
  const routeParams = useParams();

  const [totalScore, setTotalScore] = useState(0);

  const { project, creditScoreCriteria } = useSelector((state) => ({
    project: state.certifyReducer.project.data,
    creditScoreCriteria: state.certifyReducer.creditScoreCriteria,
  }));

  const handleAddCriteria = () => {
    const newValues = {
      id: uuidv4(),
      criteria: "",
      score: "",
    };
    dispatch(addCriteria(newValues));
  };

  const handleDeleteCriteria = (id) => {
    dispatch(deleteCriteria(id));
  };

  const handleSaveCriteria = () => {
    const newValues = {
      project_id: routeParams.projectId,
      criteria: creditScoreCriteria,
    };
    dispatch(createCreditScore(newValues));
    close();
  };

  const handleClose = () => {
    dispatch(getCriteria(project.project_criteria));
    close();
  };

  useEffect(() => {
    const total = creditScoreCriteria.reduce(
      (acc, item) => (acc += parseFloat(item.score)),
      0,
    );

    setTotalScore(total);
  }, [creditScoreCriteria]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        keyboard={false}
        backdrop='static'
        size='xl'
      >
        <Modal.Body className='py-4 px-6'>
          <div className='mt-4'>
            <p className=''>
              Pengiraan skor kredit bagi projek ini adalah hasil daripada
              analisis faktor-faktor kritikal termasuk utiliti yang tersedia,
              impak sosial, kelestarian alam sekitar, serta kepatuhan kepada
              matlamat dan penilaian keseluruhan.
            </p>
          </div>
          <div className='mb-3'>
            <span>Nama Projek : {project.name}</span>
          </div>
          <div className='mb-3'></div>

          <div className='mb-3'>
            <h5 className='mb-1'>Senarai Kriteria</h5>
            <p className='mb-3 text-sm text-muted'>
              Sila tambah kriteria-kriteria yang diperlukan untuk mencapai
              objektif pembangunan projek.
            </p>
            <div className='d-flex justify-content-end mb-3'>
              <Button onClick={handleAddCriteria}>+ Kriteria</Button>
            </div>
            <div className=''>
              <Table size='sm' style={{ width: "100%", fontSize: 13 }}>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th className='text-center' style={{ width: "5%" }}>
                      Bil.
                    </th>
                    <th>Kriteria</th>
                    <th className='ps-4 text-center' style={{ width: "30%" }}>
                      Skor (%)
                    </th>
                    <th className='text-center'>Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  {isEmpty(creditScoreCriteria) ? (
                    <tr>
                      <td colSpan={4} className='text-center'>
                        Tiada maklumat tersedia
                      </td>
                    </tr>
                  ) : (
                    <>
                      {creditScoreCriteria?.map((item, idx) => (
                        <tr key={idx}>
                          <td className='text-center'>{idx + 1}</td>
                          <td>
                            <Form.Control
                              type='text'
                              name='criteria'
                              value={item.criteria}
                              onChange={(e) => {
                                const value = e.target.value;
                                const newValues = {
                                  id: item.id,
                                  name: "criteria",
                                  value: value,
                                };
                                dispatch(updateCriteria(newValues));
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              className='text-end'
                              type='number'
                              style={{
                                MozAppearance: "textfield",
                                WebkitAppearance: "none",
                              }}
                              value={item.score}
                              onChange={(e) => {
                                const value = e.target.value;
                                const newValues = {
                                  id: item.id,
                                  name: "score",
                                  value: value,
                                };
                                dispatch(updateCriteria(newValues));
                              }}
                            />
                          </td>
                          <td className='text-center'>
                            <Icon
                              path={mdiTrashCan}
                              color={palette.danger}
                              size='18px'
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteCriteria(item.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  {!isEmpty(creditScoreCriteria) && (
                    <tr>
                      <td
                        colSpan={2}
                        className='text-end border-bottom-0'
                        style={{ fontWeight: 700 }}
                      >
                        Jumlah
                      </td>
                      <td className='ps-4 border-bottom-0 text-end'>
                        {numeral(totalScore).format("0.00")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>

          <div className='d-flex justify-content-end mt-3 gap-3'>
            <Button variant='light' onClick={handleClose}>
              Batal
            </Button>
            <Button variant='success' onClick={handleSaveCriteria}>
              Simpan
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CreditScoreForm;
