import React, { useEffect, useState } from "react";
import { Badge, Form, Pagination, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EightColumns } from "../../../../../components/common/tableLoading";
import { isEmpty } from "lodash";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";

// components
import SuccessCreateUser from "../SuccessCreateUser";

// Redux
import {
  removeUser,
  resetTempPassword,
  setPage,
  setPerPage,
} from "../../../../../redux/slices/usersSlice";

const Users = ({
  handleRemoveUser,
  handleEditUser,
  handleActivatedUser,
  goToPage,
  setGoToPage,
}) => {
  const dispatch = useDispatch();

  const [successCreated, setSuccessCreated] = useState(false);

  const { users, page, perPage, meta, links, isLoading, searchUser } =
    useSelector((state) => ({
      meta: state.usersReducer.meta,
      links: state.usersReducer.links,
      isLoading: state.usersReducer.isLoading,
      searchUser: state.usersReducer.searchUser,
      users: state.usersReducer.users,
      page: state.usersReducer.page,
      perPage: state.usersReducer.perPage,
    }));

  return (
    <React.Fragment>
      <SuccessCreateUser
        successCreated={successCreated}
        closeSuccessCreated={() => {
          dispatch(resetTempPassword());
          setSuccessCreated(false);
        }}
      />

      <div className='table-responsive'>
        <Table size='sm'>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 12 }}
          >
            <tr>
              <th className='text-center'>Bil.</th>
              <th>Nama</th>
              <th>No. MyKad</th>
              <th>Email</th>
              <th>Kementerian</th>
              <th>Jabatan / Agensi</th>
              <th className='text-center'>Status</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading ? (
            <EightColumns />
          ) : (
            <tbody>
              {users.map((user, idx) => {
                const baseIndex = (meta?.current_page - 1) * meta?.per_page;
                return (
                  <tr key={user.id}>
                    <td className='text-center'>{baseIndex + idx + 1}.</td>
                    <td>
                      {user.name}
                      <p className='text-sm m-0 text-muted'>
                        {user.user_profile?.position}
                      </p>
                    </td>
                    <td>{user?.nric}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.ministry ? (
                        user.ministry.label
                      ) : (
                        <span className='fst-italic'>
                          Maklumat tidak tersedia
                        </span>
                      )}
                    </td>
                    <td>
                      {user.department ? (
                        user.department.label
                      ) : (
                        <span className='fst-italic'>
                          Maklumat tidak tersedia
                        </span>
                      )}
                    </td>
                    <td className='text-center'>
                      <div
                        className='switch'
                        onClick={() => handleActivatedUser(user)}
                      >
                        <input
                          type='checkbox'
                          checked={user.is_deleted ? false : true}
                        />
                        <span className='slider round'></span>
                      </div>
                    </td>

                    <td>
                      <div className='d-flex justify-content-center'>
                        <Tooltip title='Edit' placement='top' arrow>
                          <span>
                            <IconButton
                              color='info'
                              size='small'
                              aria-label='Edit'
                              onClick={() => handleEditUser(user)}
                            >
                              <MdModeEditOutline fontSize='large' />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title='Padam' placement='top' arrow>
                          <span>
                            <IconButton
                              color='error'
                              size='small'
                              aria-label='Padam'
                              onClick={() => handleRemoveUser(user)}
                              disabled={
                                user.roles.includes("superadmin") ? true : false
                              }
                            >
                              <MdDelete fontSize='large' />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>
      <div className='d-flex justify-content-between'>
        <div>
          <span className=''>
            Halaman{" "}
            <strong>
              {meta?.current_page} daripada {meta?.last_page}
            </strong>
            <span className='ms-3 me-2'>Tunjuk:</span>
            <Form.Select
              className='d-inline-block w-auto'
              value={perPage}
              onChange={(e) => {
                const value = e.target.value;

                dispatch(setPerPage(value));
                dispatch(setPage(1));
              }}
            >
              {[15, 30, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
          </span>
          <span className='ms-3 me-2'>Pergi ke halaman:</span>
          <Form.Control
            className='d-inline-block'
            type='number'
            value={goToPage}
            onChange={(e) => {
              const value = e.target.value;
              setGoToPage(value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(setPage(goToPage));
              }
            }}
            style={{ width: "75px" }}
          />
        </div>
        <Pagination>
          <Pagination.First
            onClick={() => {
              dispatch(setPage(1));
            }}
            disabled={links?.prev === null ? true : false}
          />
          <Pagination.Prev
            onClick={() => {
              dispatch(setPage(meta?.current_page - 1));
            }}
            disabled={links?.prev === null ? true : false}
          />
          <Pagination.Next
            onClick={() => {
              dispatch(setPage(meta?.current_page + 1));
            }}
            disabled={links?.next === null ? true : false}
          />
          <Pagination.Last
            onClick={() => {
              dispatch(setPage(meta?.last_page));
            }}
            disabled={links?.next === null ? true : false}
          />
        </Pagination>
      </div>
    </React.Fragment>
  );
};

export default Users;
