import React from "react";

// Logo
import SabahLogo from "../../assets/img/photos/sabah-logo.png";

const LoadingPage = () => {
  return (
    <div
      className='vh-100 d-flex flex-column justify-content-center align-items-center'
      style={{ overflow: "hidden", backgroundColor: "#0288D1" }}
    >
      <img
        className='loading-logo'
        src={SabahLogo}
        alt=''
        height={250}
        width='auto'
      />
    </div>
  );
};

export default LoadingPage;
