import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, Pagination, Row, Table, Badge } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Tooltip, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Icons
import { MdDelete, MdEdit } from "react-icons/md";

// Redux
import {
  setPage,
  setPerPage,
} from "../../../../../redux/slices/projects/projectsSlice";
import { isEmpty } from "lodash";

const TableProjects = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeParams = useParams();
  let timeoutId;

  const [pageValue, setPageValue] = useState("1");

  const { user, projects, meta, links, isLoading } = useSelector((state) => ({
    user: state.userReducer.user,
    projects: state.projectReducer.projects,
    meta: state.projectReducer.meta,
    links: state.projectReducer.links,
    isLoading: state.projectReducer.isLoading,
  }));

  const isSuperadmin = user.roles.includes("superadmin");

  const handleViewProject = (project) => {
    const { id } = project;
    const { applicationType } = routeParams;
    navigate(`/permohonan/butiran-projek/${applicationType}/${id}`);
  };

  const nextPage = () => {
    dispatch(setPage(meta.current_page + 1));
  };
  const previousPage = () => {
    dispatch(setPage(meta.current_page - 1));
  };
  const goToFirstPage = () => {
    dispatch(setPage(1));
  };
  const goToLastPage = (num) => {
    dispatch(setPage(num));
  };

  const handleChangePage = (e) => {
    setPageValue(e.target.value);

    const value = Number(e.target.value);

    if (
      isEmpty(e.target.value) ||
      value <= 0 ||
      value > meta.last_page ||
      isNaN(value)
    )
      return false;

    clearTimeout(timeoutId);

    // Set a timeout for 1 second before dispatching
    timeoutId = setTimeout(() => {
      dispatch(setPage(value));
    }, 1000); // 1000 milliseconds = 1 second
  };

  return (
    <React.Fragment>
      <motion.div variants={item}>
        <Table hover size='sm' striped style={{ fontSize: 12 }}>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 11 }}
          >
            <tr>
              <th className='text-center ' style={{ width: "3%" }}>
                Bil.
              </th>
              <th>Projek Utama dan sub projek</th>
              <th className='text-center'>Kementerian</th>
              <th>Jabatan</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, idx) => {
              return (
                <tr key={project.id} onClick={() => handleViewProject(project)}>
                  <td className='text-center'>
                    {(meta.current_page - 1) * meta.per_page + idx + 1}.
                  </td>
                  <td style={{ width: "43%" }}>
                    <div className='text-uppercase' style={{ fontWeight: 700 }}>
                      {project.main_project?.name}
                    </div>
                    <div className='text-muted card-subtitle'>
                      {project.name}
                    </div>
                    <div className='d-flex mt-1'>
                      <Badge className='me-2 badge-soft-dark' bg=''>
                        <span style={{ fontSize: 12 }}>
                          {project?.rmk?.short_form}
                        </span>
                      </Badge>
                    </div>
                  </td>
                  <td className='text-center'>
                    {project?.ministry?.short_form}
                  </td>
                  <td>{project?.department?.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </motion.div>
      <div className='d-flex justify-content-between pt-3'>
        <div>
          <span className=''>
            <span className='me-1'>Halaman</span>
            <strong>
              {meta.current_page} daripada {meta.last_page}
            </strong>
          </span>
          <span className='ms-3 me-2'>Tunjuk:</span>
          <Form.Select
            className='d-inline-block w-auto'
            value={meta.per_page}
            onChange={(e) => {
              const value = Number(e.target.value);
              dispatch(setPerPage(value));
            }}
          >
            {[15, 30, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>
          <span className='ms-3 me-2'>Pergi ke halaman:</span>
          <Form.Control
            className='d-inline-block'
            value={pageValue}
            onChange={(e) => {
              handleChangePage(e);
            }}
            style={{ width: "75px" }}
          />
        </div>
        <div>
          <Pagination className='float-end remove-outline-item'>
            <Pagination.First
              onClick={() => goToFirstPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={links.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={links.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => goToLastPage(meta.last_page)}
              disabled={links.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableProjects;
