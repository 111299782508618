import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";

const Branches = ({ isEdit, handleEditItem, handleRemoveItem }) => {
  const { branches, meta, links } = useSelector((state) => ({
    branches: state.branchReducer.branches,
    meta: state.branchReducer.meta,
    links: state.branchReducer.links,
  }));

  return (
    <div className='table-responsive'>
      <Table size='sm'>
        <thead
          className='text-uppercase custom-table-head'
          style={{ fontSize: 12 }}
        >
          <tr>
            <th className='text-center'>Bil.</th>
            <th>Nama Cawangan</th>
            <th>Keterangan</th>
            <th>Jabatan</th>
            <th className='text-center'>Tindakan</th>
          </tr>
        </thead>
        <tbody>
          {branches
            .toSorted((a, b) => a.id - b.id)
            .map((row, idx) => (
              <tr key={row.id}>
                <td className='text-center'>{idx + 1}</td>
                <td>{row.name}</td>
                <td>{row.description}</td>
                <td>{row.department && row.department.name}</td>
                <td>
                  <div className='d-flex justify-content-center gap-1'>
                    <Tooltip title='Edit' placement='top' arrow>
                      <IconButton
                        color='info'
                        size='small'
                        aria-label='Edit'
                        onClick={() => handleEditItem(row)}
                      >
                        <MdModeEditOutline fontSize='large' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Padam' placement='top' arrow>
                      <IconButton
                        color='error'
                        size='small'
                        aria-label='Padam'
                        onClick={() => handleRemoveItem(row)}
                      >
                        <MdDelete fontSize='large' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Branches;
