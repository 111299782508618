import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchCeilingLimits = createAsyncThunk(
  "ceilingLimits/fetchCeilingLimits",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`/ceiling-limits`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createCeilingLimit = createAsyncThunk(
  "ceilingLimits/createCeilingLimit",
  async (values) => {
    const response = await axios.post(`/ceiling-limits`, values);

    const data = await response.data;

    return data;
  },
);

export const updateCeilingLimit = createAsyncThunk(
  "ceilingLimits/updateCeilingLimit",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/ceiling-limits/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeCeilingLimit = createAsyncThunk(
  "ceilingLimits/removeCeilingLimit",
  async (id) => {
    try {
      const response = await axios.delete(`/ceiling-limits/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  ceilingLimits: [],
  isLoading: false,
  btnLoading: false,
};

export const ceilingLimitsSlice = createSlice({
  name: "ceilingLimits",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCeilingLimits.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCeilingLimits.fulfilled, (state, action) => {
      state.ceilingLimits = action.payload;
      state.isLoading = false;
    });

    builder.addCase(createCeilingLimit.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(createCeilingLimit.fulfilled, (state, action) => {
      state.ceilingLimits.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateCeilingLimit.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(updateCeilingLimit.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.ceilingLimits = state.ceilingLimits.map((item) => {
        if (item.id === id) {
          return { ...action.payload };
        }

        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeCeilingLimit.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(removeCeilingLimit.fulfilled, (state, action) => {
      state.ceilingLimits = state.ceilingLimits.filter(
        (item) => item.id !== action.payload,
      );

      state.btnLoading = false;
    });
  },
});

export default ceilingLimitsSlice.reducer;
