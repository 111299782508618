import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchProjects = createAsyncThunk(
  "analysisTable/fetchProjects",
  async (props) => {
    try {
      const { params } = props;
      const response = await axios.get(`/project-analysis-table/get`, {
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

export const getApplicationStatistics = createAsyncThunk(
  "analysisTable/getApplicationStatistics",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(`/application-statistics/get`, {
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

export const getMainProjectsView = createAsyncThunk(
  "analysisTable/getMainProjectsView",
  async (props) => {
    try {
      const { params, source } = props;
      const response = await axios.get(
        `/project-analysis-table/get-main-projects-view`,
        {
          params,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  },
);

const initialState = {
  projects: [],
  links: {},
  meta: {},
  project: {},
  mainProjects: [],
  applicationStatus: "",
  total_application: 0,
  total_application_cost: 0,
  total_application_ceiling: 0,
  total_cost_certified_by_upen: 0,
  total_ceiling_certified_by_upen: 0,
  total_cost_certified_by_jppn: 0,
  total_ceiling_certified_by_jppn: 0,
  total_cost_approved_by_cabinet: 0,
  total_ceiling_approved_by_cabinet: 0,
  ceiling_flow_by_year: null,
  total_application: 0,
  total_application_cost: 0,
  total_application_ceiling: 0,
  total_cost_certified_by_upen: 0,
  total_ceiling_certified_by_upen: 0,
  total_cost_certified_by_jppn: 0,
  total_ceiling_certified_by_jppn: 0,
  total_cost_approved_by_cabinet: 0,
  total_ceiling_approved_by_cabinet: 0,
  ceiling_flow_by_year: null,
  isLoading: false,
  btnLoading: false,
  search: {
    ministries: [],
    departments: [],
    district: "",
    state_legislative_assembly: "",
    parliament: "",
    isToken: "",
  },
};

export const analysisTableSlice = createSlice({
  name: "analysisTable",
  initialState,
  reducers: {
    setSearchDistrict: (state, action) => {
      state.search.district = action.payload;
    },
    setSearchDistrict: (state, action) => {
      state.search.state_legislative_assembly = action.payload;
    },
    setSearchDistrict: (state, action) => {
      state.search.parliament = action.payload;
    },
    setTokenStatus: (state, action) => {
      state.search.isToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getApplicationStatistics.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getApplicationStatistics.fulfilled, (state, action) => {
      const {
        total,
        total_application_cost,
        total_application_ceiling,
        total_cost_certified_by_upen,
        total_ceiling_certified_by_upen,
        total_cost_certified_by_jppn,
        total_ceiling_certified_by_jppn,
        total_cost_approved_by_cabinet,
        total_ceiling_approved_by_cabinet,
        ceiling_flow_by_year,
      } = action.payload;

      state.total_application = total;
      state.total_application_cost = total_application_cost;
      state.total_application_ceiling = total_application_ceiling;
      state.total_cost_certified_by_upen = total_cost_certified_by_upen;
      state.total_ceiling_certified_by_upen = total_ceiling_certified_by_upen;
      state.total_cost_certified_by_jppn = total_cost_certified_by_jppn;
      state.total_ceiling_certified_by_jppn = total_ceiling_certified_by_jppn;
      state.total_cost_approved_by_cabinet = total_cost_approved_by_cabinet;
      state.total_ceiling_approved_by_cabinet =
        total_ceiling_approved_by_cabinet;
      state.ceiling_flow_by_year = ceiling_flow_by_year;

      state.isLoading = false;
    });

    // Main Projects View
    builder.addCase(getMainProjectsView.fulfilled, (state, action) => {
      state.mainProjects = action.payload;

      state.isLoading = false;
    });
  },
});

export const {
  setSearchMinistries,
  setSearchDepartments,
  setSearchDistrict,
  setSearchStateLegislativeAssembly,
  setSearchParliament,
  setTokenStatus,
} = analysisTableSlice.actions;

export default analysisTableSlice.reducer;
