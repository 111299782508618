import React, { useEffect, useState } from "react";
import {
  View,
  Page,
  Text,
  Image,
  Document,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";
import { Button } from "react-bootstrap";

// Styles
import { styles } from "./pdfStyles";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { isEmpty } from "lodash";

const MyDocument = ({
  project,
  virementSource,
  currentRmk,
  ceilingIncrement,
  projectCode,
  content,
  initialCeiling,
  currentCeiling,
  revisedCeiling,
  virementCeiling,
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <Text style={styles.confidential} fixed>
          Sulit
        </Text>
        <View style={styles.headerSection}>
          <Text style={styles.header}>KERAJAAN NEGERI SABAH</Text>
          <Text style={styles.header}>
            JAWATANKUASA PERANCANG PEMBANGUNAN NEGERI
          </Text>
          <Text style={styles.header}>(KERTAS KERJA BIL.__/{currentYear})</Text>
        </View>
        <View style={styles.mainTitleSection}>
          <Text style={styles.header}>
            PERMOHONAN MENAMBAH SILING PERUNTUKAN DIBAWAH{" "}
            <Text style={{ textTransform: "uppercase" }}>
              {currentRmk?.name}
            </Text>{" "}
            ({currentRmk?.short_form}) BERJUMLAH{" "}
            {`RM${numeral(virementCeiling).format("0,0.00")}`} BAGI{" "}
            <Text style={{ textTransform: "uppercase" }}>{project?.name}</Text>{" "}
            {`${projectCode}, `}
            <Text style={{ textTransform: "uppercase" }}>
              {project?.ministry?.value}
            </Text>
          </Text>
        </View>
        <View style={styles.titleSection}>
          <Text style={styles.indexing}>1.0</Text>
          <Text style={styles.title}>Tujuan</Text>
        </View>
        {content?.purpose && (
          <View style={styles.contentSection}>
            <Text style={styles.contentIndexing}>{`1.1`}</Text>
            <Text style={styles.text}>{content?.purpose}</Text>
          </View>
        )}

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>2.0</Text>
          <Text style={styles.title}>Latar Belakang</Text>
        </View>
        {content?.background?.map((item, idx) => (
          <View style={styles.contentSection}>
            <Text style={styles.contentIndexing}>{`2.${idx + 1}`}</Text>
            <Text style={styles.text}>{item?.description}</Text>
          </View>
        ))}

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>3.0</Text>
          <Text style={styles.title}>Komponen Projek</Text>
        </View>
        <Table style={styles.tableContainer}>
          <TH>
            <TD style={{ justifyContent: "center" }}>Bil.</TD>
            <TD style={{ padding: 4 }}>Komponen Projek</TD>
            <TD
              style={{
                justifyContent: "center",
                textAlign: "center",
                padding: 4,
              }}
            >
              Siling Asal (RM)
            </TD>
            <TD
              style={{
                justifyContent: "center",
                textAlign: "center",
                padding: 4,
              }}
            >
              Siling Disemak (RM)
            </TD>
          </TH>
          {content?.project_components?.map((item, idx) => (
            <TR>
              <TD style={{ justifyContent: "center" }}>{idx + 1}</TD>
              <TD style={{ padding: 4 }}>{item.cost_description}</TD>
              <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                {numeral(item.ceiling).format("0,0.00")}
              </TD>
              <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                {numeral(item.revised_ceiling).format("0,0.00")}
              </TD>
            </TR>
          ))}
          <TR>
            <TD></TD>
            <TD style={{ padding: 4 }}>Jumlah</TD>
            <TD style={{ justifyContent: "flex-end", padding: 4 }}>
              {numeral(
                content?.project_components?.reduce(
                  (acc, item) => (acc += parseFloat(item.ceiling)),
                  0,
                ),
              ).format("0,0.00")}
            </TD>
            <TD style={{ justifyContent: "flex-end", padding: 4 }}>
              {numeral(
                content?.project_components?.reduce(
                  (acc, item) => (acc += parseFloat(item.revised_ceiling)),
                  0,
                ),
              ).format("0,0.00")}
            </TD>
          </TR>
        </Table>

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>4.0</Text>
          <Text style={styles.title}>Keperluan Kewangan</Text>
        </View>
        {content?.ceiling_requirement && (
          <Table style={styles.tableContainer}>
            <TH>
              <TD style={{ justifyContent: "center", padding: 4 }}>
                Siling Asal
              </TD>
              <TD
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 4,
                }}
              >
                Penambahan Siling (RM)
              </TD>
              <TD
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 4,
                }}
              >
                Jumlah Baharu (RM)
              </TD>
              <TD style={{ justifyContent: "center", padding: 4 }}>Catatan</TD>
            </TH>

            <TR>
              <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                {numeral(content?.ceiling_requirement?.initial_ceiling).format(
                  "0,0.00",
                )}
              </TD>
              <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                {numeral(
                  content?.ceiling_requirement?.additional_ceiling,
                ).format("0,0.00")}
              </TD>
              <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                {numeral(
                  content?.ceiling_requirement?.total_new_ceiling,
                ).format("0,0.00")}
              </TD>
              <TD style={{ padding: 4 }}>
                {content?.ceiling_requirement?.notes}
              </TD>
            </TR>
          </Table>
        )}

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>5.0</Text>
          <Text style={styles.title}>Justifikasi</Text>
        </View>
        {content?.justification?.map((item, idx) => (
          <React.Fragment>
            <View style={styles.contentSection}>
              <Text style={styles.contentIndexing}>{`5.${idx + 1}`}</Text>
              <Text style={styles.text}>{item?.description}</Text>
            </View>
            {item?.children.map((child, subIdx) => (
              <View style={styles.contentSubSection}>
                <Text style={styles.contentIndexing}>
                  {`5.${idx + 1}.${idx + 1}`}
                </Text>
                <Text style={styles.text}>{item?.description}</Text>
              </View>
            ))}
          </React.Fragment>
        ))}

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>6.0</Text>
          <Text style={styles.title}>Cadangan Sumber Kewangan</Text>
        </View>
        {content?.source_of_financial && (
          <>
            <View style={styles.contentSection}>
              <Text style={styles.contentIndexing}>{`6.1`}</Text>
              <Text style={styles.text}>
                {`${project?.ministry?.short_form} telah mengenalpasti sumber pindahan siling bagi projek ini iaitu memlalui sumber dalam program di bawah ${project?.ministry?.short_form} sendiri iaitu sejumlah RM${ceilingIncrement} akan dipindah keluar daripada ${virementSource?.program?.prefix_type}${virementSource?.ministry?.code} ${virementSource?.program?.code} ${virementSource?.main_project?.code}${virementSource?.code} : ${virementSource?.name}.`}
              </Text>
            </View>
            <View style={styles.contentSection}>
              <Text style={styles.contentIndexing}>{`6.2`}</Text>
              <Text style={styles.text}>
                Ringkasan cadangan sumber kewangan untuk permohonan siling
                projek ini adalah seperti di Jadual 1 yang menjelaskan kedudukan
                selepas pindahan siling.
              </Text>
            </View>

            <Table style={styles.tableContainer}>
              <TH>
                <TD style={{ justifyContent: "center", padding: 4 }}>
                  Nama Projek
                </TD>
                <TD
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    padding: 4,
                  }}
                >
                  Siling Asal (RM)
                </TD>
                <TD
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    padding: 4,
                  }}
                >
                  Siling Terkini (RM)
                </TD>
                <TD style={{ justifyContent: "center", padding: 4 }}>
                  Siling dikurangkan(-) atau ditambah(+)
                </TD>
                <TD style={{ justifyContent: "center", padding: 4 }}>
                  Siling Disemak (RM)
                </TD>
              </TH>
              {!isEmpty(content?.virement_out_projects) && (
                <TR>
                  <TD style={{ padding: 4 }}>Daripada :</TD>
                </TR>
              )}
              {!isEmpty(content?.virement_out_projects) && (
                <TR>
                  <TD style={{ padding: 4 }}>
                    {content.source_of_financial?.from?.project_name}
                  </TD>
                  <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                    {numeral(
                      content.source_of_financial?.from?.initial_ceiling,
                    ).format("0,0.00")}
                  </TD>
                  <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                    {numeral(
                      content.source_of_financial?.from?.current_ceiling,
                    ).format("0,0.00")}
                  </TD>
                  <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                    (-)
                    {numeral(
                      content.source_of_financial?.from?.ceiling_changes,
                    ).format("0,0.00")}
                  </TD>
                  <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                    {numeral(
                      content.source_of_financial?.from?.revised_ceiling,
                    ).format("0,0.00")}
                  </TD>
                </TR>
              )}

              {!isEmpty(content?.virement_out_projects) && (
                <TR>
                  <TD style={{ padding: 4 }}>Kepada :</TD>
                </TR>
              )}

              <TR>
                <TD style={{ padding: 4 }}>
                  {content.source_of_financial?.to?.project_name}
                </TD>
                <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                  {numeral(
                    content.source_of_financial?.to?.initial_ceiling,
                  ).format("0,0.00")}
                </TD>
                <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                  {numeral(
                    content.source_of_financial?.to?.current_ceiling,
                  ).format("0,0.00")}
                </TD>
                <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                  (+)
                  {numeral(
                    content.source_of_financial?.to?.ceiling_changes,
                  ).format("0,0.00")}
                </TD>
                <TD style={{ justifyContent: "flex-end", padding: 4 }}>
                  {numeral(
                    content.source_of_financial?.to?.revised_ceiling,
                  ).format("0,0.00")}
                </TD>
              </TR>
            </Table>

            <View style={styles.contentSection}>
              <Text style={styles.contentIndexing}>{`6.3`}</Text>
              <Text style={styles.text}>
                {`Pindahan sumber siling daripada Projek Pembangunan Pelancongan Luar Bandar (D43 000200 00320001) tidak akan menjelaskan pelaksanaan program ini memandangkan siling tersedia adalah mencukupi bagi memenuhi permohonan tahunan projek-projek dibawah program tersebut.`}
              </Text>
            </View>

            <View style={styles.contentSection}>
              <Text style={styles.contentIndexing}>{`6.4`}</Text>
              <Text style={styles.text}>
                {`Sumber kewangan akan diperolehi secara pindah peruntukan daripada vot-vot di bawah KPKAS yang akan dikenalpasti mempunyai penjimatan atau melalui permohonan Anggaran Peruntukan Tambahan (ASP) pada tahun`}
              </Text>
            </View>
          </>
        )}

        <Text style={styles.confidentialBottom}>Sulit</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber}`}
          fixed
        />
      </Page>
    </Document>
  );
};

const ViewPaperWork = () => {
  const [ceilingRequested, setCeilingRequested] = useState(0);
  const [projectCode, setProjectCode] = useState("");
  const [virementCeiling, setVirementCeiling] = useState(0);

  const {
    project,
    currentRmk,
    ceilingIncrement,
    virementSource,
    draft,

    initialCeiling,
    currentCeiling,
    revisedCeiling,

    virementOutProjects,
  } = useSelector((state) => ({
    project: state.projectReducer.project,
    currentRmk: state.rmkReducer.activeRmk,
    virementSource: state.jppnReducer.virement.financialSource,
    ceilingIncrement: state.jppnReducer.virement.ceilingIncrement,
    draft: state.jppnReducer.draft,

    initialCeiling: state.jppnReducer.virement.to.initialCeiling,
    currentCeiling: state.jppnReducer.virement.to.currentCeiling,
    revisedCeiling: state.jppnReducer.virement.to.revisedCeiling,

    virementOutProjects: state.jppnReducer.virement.virementOutProjects,
  }));

  useEffect(() => {
    const value = numeral(ceilingIncrement).format("0,0.00");
    setCeilingRequested(value);

    const code = `(${project?.program?.prefix}${project?.ministry_id} ${project?.program?.code} ${project?.main_project?.code}${project?.code})`;

    setProjectCode(code);
  }, [ceilingIncrement, project]);

  useEffect(() => {}, [virementOutProjects]);

  useEffect(() => {
    const additionalCeiling = draft?.project_components
      ?.flatMap((item) => item.ceiling_flow_details)
      ?.reduce((acc, item) => (acc += parseFloat(item?.additional_ceiling)), 0);

    setVirementCeiling(additionalCeiling);
  }, [draft]);

  return (
    <React.Fragment>
      {/* <PDFDownloadLink document={<PDFFile />} fileName={`test.pdf`}>
        {({ blob, url, loading, error }) => (
          <Button variant='secondary' disabled={loading}>
            Test
          </Button>
        )}
      </PDFDownloadLink> */}
      <PDFViewer width='100%' height='100%'>
        <MyDocument
          project={project}
          currentRmk={currentRmk}
          ceilingIncrement={ceilingRequested}
          projectCode={projectCode}
          virementSource={virementSource}
          content={draft}
          initialCeiling={initialCeiling}
          currentCeiling={currentCeiling}
          revisedCeiling={revisedCeiling}
          virementCeiling={virementCeiling}
        />
      </PDFViewer>
    </React.Fragment>
  );
};

export default ViewPaperWork;
