import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { IconButton, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
// Styles
import { customStyles } from "../../../../components/common/customStyleSelect";

// Icons
import { MdSearch } from "react-icons/md";
import Icon from "@mdi/react";
import { mdiFilterVariant } from "@mdi/js";

import { statusOptions } from "../../../../utils/common/options-data";

const Filters = ({
  filterItem,
  handleSearch,
  handleShowCanvas,
  handleChangeStages,
  handleChangeStatus,
  stagesFilter,
  selectedStatus,
}) => {
  const { isLoading } = useSelector((state) => ({
    isLoading: state.certifyReducer.isLoading,
  }));
  return (
    <div className='d-flex align-items-center gap-2 mb-3 mt-3'>
      <motion.div variants={filterItem} className='remove-outline-item'>
        <Tooltip title='Lebih Pilihan' placement='top' arrow>
          <Button variant='secondary' onClick={handleShowCanvas}>
            <Icon path={mdiFilterVariant} size={0.8} />
          </Button>
        </Tooltip>
      </motion.div>
      <motion.div variants={filterItem}>
        <div className='search-box-custom'>
          <div className='position-relative'>
            <label htmlFor='search-bar-0' className='search-label-custom'>
              <input
                type='text'
                className='form-control'
                placeholder='Cari...'
                // value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: 280 }}
              />
            </label>
            <MdSearch className='search-icon-2' size={18} />
          </div>
        </div>
      </motion.div>
      <motion.div variants={filterItem}>
        <Form.Group>
          <Select
            placeholder='Status Permohonan'
            styles={customStyles}
            className='react-select-container'
            classNamePrefix='react-select'
            isClearable
            value={selectedStatus}
            options={statusOptions}
            onChange={(value) => handleChangeStatus(value)}
            noOptionsMessage={() => "Tiada pilihan"}
            isDisabled={isLoading}
          />
        </Form.Group>
      </motion.div>
      {/* <motion.div variants={filterItem}>
        <Form.Group>
          <Select
            placeholder='Peringkat'
            styles={customStyles}
            className='react-select-container'
            classNamePrefix='react-select'
            isClearable
            value={stagesFilter}
            options={[
              {
                id: 2,
                label: "Jabatan",
                value: "Jabatan",
              },
              {
                id: 3,
                label: "Kementerian",
                value: "Kementerian",
              },
              {
                id: 4,
                label: "UPEN",
                value: "UPEN",
              },
              {
                id: 5,
                label: "JPPN",
                value: "JPPN",
              },
              {
                id: 6,
                label: "Kabinet",
                value: "Kabinet",
              },
            ]}
            onChange={(value) => handleChangeStages(value)}
            noOptionsMessage={() => "Tiada pilihan"}
            isDisabled={isLoading}
          />
        </Form.Group>
      </motion.div> */}
    </div>
  );
};

export default Filters;
