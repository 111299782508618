import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../redux/slices/userSlice";

// Icon
import Icon from "@mdi/react";
import { mdiEyeOutline } from "@mdi/js";

// import useAuth from "../../hooks/useAuth";
import { AuthContext } from "../../contexts/AuthContext";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signIn } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);

  const downloadForm = () => {
    const newTab = window.open(
      process.env.REACT_APP_USER_REGISTRATION_FORM,
      "_blank",
    );

    if (newTab) {
      newTab.focus();
    }
  };

  const togglePwdVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={{
        nric: "",
        password: "",
        submit: false,
        rememberMe: true,
      }}
      validationSchema={Yup.object().shape({
        nric: Yup.string()
          .max(12, "No. MyKad tidak boleh melebihi 12 nombor")
          .min(12, "No. MyKad tidak boleh kurang dari 12 nombor")
          .required("No. MyKad diperlukan"),
        password: Yup.string().max(255).required("Kata laluan diperlukan"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const user = await signIn(values.nric, values.password);

          const roles = user.roles || [];
          const isTrue = user.first_time_login;

          dispatch(setIsLoading(true));

          let redirectPath = "/auth/404"; // Default path

          if (isTrue) {
            redirectPath = "/tukar-kata-laluan";
          } else {
            if (roles.includes("superadmin")) {
              redirectPath = "/pengumuman";
            }
            if (roles.includes("dashboard")) {
              redirectPath = "/pengumuman";
            }
            if (roles.includes("laporan_prestasi_projek")) {
              redirectPath = "/pengumuman";
            }
            if (roles.includes("projek_mikro_dashboard")) {
              redirectPath = "/projek-mikro/dashboard";
            }
            if (roles.includes("projek_mikro_senarai")) {
              redirectPath = "/projek-mikro/senarai-projek";
            }
            if (roles.includes("projek_mikro_status_senarai")) {
              redirectPath = "/projek-mikro/status-senarai-projek";
            }
            if (roles.includes("admin_projek_mikro")) {
              redirectPath = "/projek-mikro/admin/senarai-pengguna";
            }
          }

          setTimeout(() => {
            dispatch(setIsLoading(false));
            navigate(redirectPath);
          }, 2000);
        } catch (error) {
          const response = error.response;
          const result = response.data.errors || "Something went wrong";

          setStatus({ success: false });
          setErrors({ nric: result.nric, password: result.password });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label style={{ color: "#707982" }}>No. MyKad</Form.Label>
              <Form.Control
                size='lg'
                type='nric'
                name='nric'
                placeholder='Masukkan no. Mykad'
                value={values.nric}
                isInvalid={Boolean(touched.nric && errors.nric)}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ backgroundColor: "#fff", borderColor: "#ced4da" }}
              />
              {!!touched.nric && (
                <Form.Control.Feedback type='invalid'>
                  {errors.nric}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className='position-relative mb-3'>
              <Form.Label style={{ color: "#707982" }}>Kata laluan</Form.Label>
              <Form.Control
                size='lg'
                type={showPassword ? "text" : "password"}
                name='password'
                placeholder='Masukkan kata laluan'
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ backgroundColor: "#fff", borderColor: "#ced4da" }}
              />
              <Icon
                className='position-absolute'
                style={{ right: 13, top: 38, cursor: "pointer" }}
                path={mdiEyeOutline}
                onClick={togglePwdVisibility}
                size='18px'
              />
              {!!touched.password && (
                <Form.Control.Feedback type='invalid'>
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div className='d-flex justify-content-between'>
              <div>
                <small>
                  <Link to='#' onClick={downloadForm}>
                    Muat turun borang permohonan akses.
                  </Link>
                </small>
              </div>
              <div>
                <small>
                  <Link to='/lupa-kata-laluan'>Lupa Kata Laluan?</Link>
                </small>
              </div>
            </div>

            <div className='text-center mt-3'>
              <Button type='submit' variant='primary' disabled={isSubmitting}>
                Log Masuk
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SignIn;
