import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import Placeholder from "react-bootstrap/Placeholder";

import SidebarNavSection from "./SidebarNavSection";

const SidebarNav = ({ items }) => {
  
  const user = useSelector((state) => state.userReducer.user);

  const placeHolderItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  if (isEmpty(user)) {
    return (
      <div className="px-4 mt-1 pt-5">
        <Placeholder as="p" animation="glow" size="lg">
          <Placeholder xs={6} />
        </Placeholder>
        {placeHolderItems.map((item) => (
          <Placeholder key={item} as="p" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        ))}
      </div>
    );
  }

  return (
    <ul className="sidebar-nav">
      {items &&
        items.map((item) => (
          <SidebarNavSection
            key={item.title}
            pages={item.pages}
            title={item.title}
            showTo={item.showTo}
            user={user}
          />
        ))}
    </ul>
  );
};

export default SidebarNav;
