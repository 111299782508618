import { Tooltip } from "@mui/material";
import numeral from "numeral";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const TotalProgressStatus = ({ filterStatus }) => {
  const { progressStatus } = useSelector((state) => ({
    progressStatus: state.reportReducer.totalProgressStatus,
  }));

  return (
    <React.Fragment>
      <Row className='mt-3'>
        {/* First Row - First 2 Items */}
        <Col md={3} lg={3} className='mb-3'>
          {progressStatus?.slice(0, 2).map((item) => (
            <Card
              key={item.code}
              className={`activity-completed-card mb-3`}
              style={{ cursor: "pointer", backgroundColor: item.color }}
              onClick={() => filterStatus(item.code)}
            >
              <Tooltip title={item.description} placement='top-end' arrow>
                <Card.Body>
                  <Card.Text className='text-uppercase text-truncate title-style'>
                    {item.label}{" "}
                    <span style={{ fontWeight: 700 }}>
                      {`(${item.label
                        .split(" ")
                        .map((word) => word.charAt(0))
                        .join("")})`}
                    </span>
                  </Card.Text>
                  <Card.Text className='body-style'>
                    {numeral(item.total || 0).format("0,0")}
                  </Card.Text>
                </Card.Body>
              </Tooltip>
            </Card>
          ))}
        </Col>

        {/* Second Row - Next 2 Items */}
        <Col md={3} lg={3} className='mb-3'>
          {progressStatus?.slice(2, 4).map((item) => (
            <Card
              key={item.code}
              className={`activity-completed-card mb-3`}
              style={{ cursor: "pointer", backgroundColor: item.color }}
              onClick={() => filterStatus(item.code)}
            >
              <Tooltip title={item.description} placement='top-end' arrow>
                <Card.Body>
                  <Card.Text className='text-uppercase text-truncate title-style'>
                    {item.label}{" "}
                    <span style={{ fontWeight: 700 }}>
                      {`(${item.label
                        .split(" ")
                        .map((word) => word.charAt(0))
                        .join("")})`}
                    </span>
                  </Card.Text>
                  <Card.Text className='body-style'>
                    {numeral(item.total || 0).format("0,0")}
                  </Card.Text>
                </Card.Body>
              </Tooltip>
            </Card>
          ))}
        </Col>

        {/* Third Row - Next 2 Items */}
        <Col md={3} lg={3} className='mb-3'>
          {progressStatus?.slice(4, 6).map((item) => (
            <Card
              key={item.code}
              className={`activity-completed-card mb-3`}
              style={{ cursor: "pointer", backgroundColor: item.color }}
              onClick={() => filterStatus(item.code)}
            >
              <Tooltip title={item.description} placement='top-end' arrow>
                <Card.Body>
                  <Card.Text className='text-uppercase text-truncate title-style'>
                    {item.label}{" "}
                    <span style={{ fontWeight: 700 }}>
                      {`(${item.label
                        .split(" ")
                        .map((word) => word.charAt(0))
                        .join("")})`}
                    </span>
                  </Card.Text>
                  <Card.Text className='body-style'>
                    {numeral(item.total || 0).format("0,0")}
                  </Card.Text>
                </Card.Body>
              </Tooltip>
            </Card>
          ))}
        </Col>

        {/* Fourth Row - Last 2 Items */}
        <Col md={3} lg={3} className='mb-3'>
          {progressStatus?.slice(6, 8).map((item) => (
            <Card
              key={item.code}
              className={`activity-completed-card mb-3`}
              style={{ cursor: "pointer", backgroundColor: item.color }}
              onClick={() => filterStatus(item.code)}
            >
              <Tooltip title={item.description} placement='top-end' arrow>
                <Card.Body>
                  <Card.Text className='text-uppercase text-truncate title-style'>
                    {item.label}{" "}
                    <span style={{ fontWeight: 700 }}>
                      {`(${item.label
                        .split(" ")
                        .map((word) => word.charAt(0))
                        .join("")})`}
                    </span>
                  </Card.Text>
                  <Card.Text className='body-style'>
                    {numeral(item.total || 0).format("0,0")}
                  </Card.Text>
                </Card.Body>
              </Tooltip>
            </Card>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TotalProgressStatus;
