import React, { useState, useContext, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// Styles
import NotyfContext from "../../../../../../contexts/NotyfContext";

// Components
import ProjectInfoForm from "./accordion-items/ProjectInfoForm";
import FinancialForm from "./accordion-items/FinancialForm";
import ProjectScopeForm from "./accordion-items/ProjectScopeForm";
import SiteInformationForm from "./accordion-items/SiteInformationForm";
import FrameworkForm from "./accordion-items/FrameworkForm";
import AdditionalInfoForm from "./accordion-items/AdditionalInfoForm";

const MainAccordion = ({ handleEnableBtn, enableBtnSave }) => {
  const notyf = useContext(NotyfContext);

  const routeParams = useParams();
  const { projectId } = routeParams;

  const [active, setActive] = useState(0);
  const [passedSteps, setPassedSteps] = useState([]);

  const { btnLoading, project, activeRmk, user } = useSelector((state) => ({
    activeRmk: state.rmkReducer.activeRmk,

    project: state.projectReducer.project,
    btnLoading: state.projectReducer.btnLoading,

    user: state.userReducer.user,
  }));

  const handleChecked = (item) => {
    const num = parseInt(item);
    const check = passedSteps.includes(item);

    if (!check) {
      const modifiedSteps = [...passedSteps, num];
      if (num >= 1 && num <= 6) {
        setActive(num + 1);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  const handleExpand = (key) => {
    if (key === "review") {
      handleEnableBtn(false);
      return false;
    }

    if (active === key) {
      setActive(0);
    } else {
      setActive(key);
    }
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat projek berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <Card className='mb-0'>
      <Accordion activeKey={active} className='remove-outline-item' flush>
        <Accordion.Item eventKey={1}>
          <ProjectInfoForm
            handleChecked={handleChecked}
            passedSteps={passedSteps}
            handleExpand={handleExpand}
            handleNotification={handleNotification}
            project={project}
            btnLoading={btnLoading}
            enableBtnSave={enableBtnSave}
          />
        </Accordion.Item>
        <Accordion.Item eventKey={2}>
          <FinancialForm
            handleChecked={handleChecked}
            passedSteps={passedSteps}
            handleExpand={handleExpand}
            activeRmk={activeRmk}
            project={project}
            projectId={projectId}
            btnLoading={btnLoading}
            enableBtnSave={enableBtnSave}
          />
        </Accordion.Item>
        <Accordion.Item eventKey={3}>
          <ProjectScopeForm
            handleChecked={handleChecked}
            passedSteps={passedSteps}
            handleExpand={handleExpand}
            projectId={projectId}
            enableBtnSave={enableBtnSave}
          />
        </Accordion.Item>
        <Accordion.Item eventKey={4}>
          <SiteInformationForm
            handleChecked={handleChecked}
            passedSteps={passedSteps}
            handleExpand={handleExpand}
            enableBtnSave={enableBtnSave}
          />
        </Accordion.Item>
        <Accordion.Item eventKey={5}>
          <FrameworkForm
            handleChecked={handleChecked}
            passedSteps={passedSteps}
            handleExpand={handleExpand}
            enableBtnSave={enableBtnSave}
          />
        </Accordion.Item>
        <Accordion.Item eventKey={6}>
          <AdditionalInfoForm
            handleChecked={handleChecked}
            passedSteps={passedSteps}
            handleExpand={handleExpand}
            enableBtnSave={enableBtnSave}
          />
        </Accordion.Item>
      </Accordion>
    </Card>
  );
};

export default MainAccordion;
