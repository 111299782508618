import axios from "axios";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Card,
  Col,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";

// redux
import {
  getMicroProjects,
  getApprovedMicroProjectsStatistics,
} from "../../../../../redux/slices/projects/projectSlice";
import {
  setMicroProjectPage,
  setMicroProjectPerPage,
} from "../../../../../redux/slices/projects/projectsSlice";

const JKMMicroProjects = () => {
  const dispatch = useDispatch();

  const [goToPage, setGoToPage] = useState("");

  const { project, microProjects, statistics } = useSelector((state) => ({
    project: state.projectReducer.project,
    microProjects: state.projectReducer.microProjects,
    statistics: state.projectReducer.microProjectsStatistics,
  }));

  const { data, links, meta, isLoading, page, perPage } = microProjects;

  useEffect(() => {
    let source = axios.CancelToken.source();

    if (!isEmpty(project)) {
      const { main_project_id } = project;

      const props = {
        source: source,
        params: {
          get_micro_projects: true,
          main_project_id: main_project_id,
          per_page: perPage,
          page: page,
        },
      };

      dispatch(getApprovedMicroProjectsStatistics(props));
      dispatch(getMicroProjects(props));
    }
    setGoToPage(page);

    return () => {
      source.cancel();
    };
  }, [dispatch, page, perPage]);

  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>SENARAI PROJEK MIKRO</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <Card className='mb-0'>
          <Card.Header className='pb-0'>
            <h5>Senarai Projek Mikro yang telah diluluskan.</h5>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className='d-flex'>
                <div
                  className='p-4 mb-3 border flex-fill w-100'
                  style={{ borderRadius: 6 }}
                >
                  <h5 className='text-uppercase mb-4'>
                    Jumlah Projek Diluluskan
                  </h5>
                  <h3>
                    {numeral(statistics?.total_approved_projects).format("0,0")}
                  </h3>
                </div>
              </Col>
              <Col className='d-flex'>
                <div
                  className='p-4 mb-3 border flex-fill w-100'
                  style={{ borderRadius: 6 }}
                >
                  <h5 className='text-uppercase mb-4'>
                    Jumlah Kos Keseluruhan Diluluskan
                  </h5>
                  <h3>
                    {`RM ${numeral(statistics?.total_approved_cost).format(
                      "0,0",
                    )}`}
                  </h3>
                </div>
              </Col>
            </Row>

            <div className='table-responsive'>
              <Table size='sm'>
                <thead className='text-uppercase'>
                  <tr>
                    <th className='text-center'>Bil.</th>
                    <th>Projek Utama</th>
                    <th>Nama Projek</th>
                    <th>Daerah</th>
                    <th>Kos Diluluskan (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, idx) => {
                    const baseIndex = (meta?.current_page - 1) * meta?.per_page;
                    return (
                      <tr>
                        <td className='text-center'>{baseIndex + idx + 1}.</td>
                        <td>{item?.main_project?.name}</td>
                        <td>{item?.name}</td>
                        <td>{item?.district?.name}</td>
                        <td className='text-end pe-3'>
                          {numeral(item?.cost_approved).format("0,0.00")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <span className=''>
                  Halaman{" "}
                  <strong>
                    {meta?.current_page} daripada {meta?.last_page}
                  </strong>
                  <span className='ms-3 me-2'>Tunjuk:</span>
                  <Form.Select
                    className='d-inline-block w-auto'
                    value={meta?.per_page}
                    onChange={(e) => {
                      const value = e.target.value;

                      dispatch(setMicroProjectPerPage(value));
                      dispatch(setMicroProjectPage(1));
                    }}
                  >
                    {[15, 30, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Form.Select>
                </span>
                <span className='ms-3 me-2'>Pergi ke halaman:</span>
                <Form.Control
                  className='d-inline-block'
                  type='number'
                  value={goToPage}
                  onChange={(e) => {
                    const value = e.target.value;
                    setGoToPage(value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(setMicroProjectPage(goToPage));
                    }
                  }}
                  style={{ width: "75px" }}
                />
              </div>
              <Pagination>
                <Pagination.First
                  onClick={() => {
                    dispatch(setMicroProjectPage(1));
                  }}
                  disabled={links?.prev === null ? true : false}
                />
                <Pagination.Prev
                  onClick={() => {
                    dispatch(setMicroProjectPage(meta?.current_page - 1));
                  }}
                  disabled={links?.prev === null ? true : false}
                />
                <Pagination.Next
                  onClick={() => {
                    dispatch(setMicroProjectPage(meta?.current_page + 1));
                  }}
                  disabled={links?.next === null ? true : false}
                />
                <Pagination.Last
                  onClick={() => {
                    dispatch(setMicroProjectPage(meta?.last_page));
                  }}
                  disabled={links?.next === null ? true : false}
                />
              </Pagination>
            </div>
          </Card.Body>
        </Card>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default JKMMicroProjects;
