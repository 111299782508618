import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.post(`/user-profile/edit/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  user: {},
  isLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchUser: (state, action) => {
      state.user = action.payload;
    },
    resetUserReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user.profile = action.payload;
    });
  },
});

export const { setIsLoading, fetchUser, resetUserReducer } = userSlice.actions;

export default userSlice.reducer;
