import React, { useState, useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingState from "../../../components/common/LoadingState";

// Icons
import Icon from "@mdi/react";
import { mdiFileDocumentOutline } from "@mdi/js";

// Content
import ProjectSummary from "./project-summary/ProjectSummary";
import Reporting from "./reporting/Reporting";
import FinancialReport from "./financial-report/FinancialReport";
import ProjectProfile from "./modals/ProjectProfile";

// Redux
import { getProject } from "../../../redux/slices/reports/projectReportsSlice";
import {
  setReportTab,
  getProgressStatusOptions,
} from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const [showProjectProfile, setShowProjectProfile] = useState(false);

  const { reportProfileTab, isLoading } = useSelector((state) => ({
    reportProfileTab: state.utilsReducer.tabValue.reportProfileTab,
    isLoading: state.reportReducer.isLoading.project,
  }));

  const handleChangeTab = (value) => {
    dispatch(setReportTab(value));
  };

  const handleViewProject = () => {
    setShowProjectProfile(true);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const { projectId } = routeParams;

    const props = {
      id: projectId,
      source: source,
    };

    const initialize = () => {
      dispatch(getProject(props));
      dispatch(getProgressStatusOptions(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <ProjectProfile
        showProjectProfile={showProjectProfile}
        closeProjectProfile={() => setShowProjectProfile(false)}
      />

      <Container fluid className='p-0'>
        <h4 className='text-uppercase'>Laporan Kemajuan Projek</h4>

        {isLoading ? (
          <React.Fragment>
            <LoadingState />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='my-3 d-flex'>
              <Nav
                variant='pills'
                activeKey={reportProfileTab}
                onSelect={handleChangeTab}
              >
                <Nav.Item className='me-3'>
                  <Nav.Link
                    as='button'
                    eventKey='0'
                    style={{ borderRadius: 20 }}
                  >
                    <span style={{ fontWeight: 700, fontSize: 11 }}>
                      RINGKASAN LAPORAN PROJEK
                    </span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className='me-3'>
                  <Nav.Link
                    as='button'
                    eventKey='1'
                    style={{ borderRadius: 20 }}
                  >
                    <span style={{ fontWeight: 700, fontSize: 11 }}>
                      LAPORAN KEWANGAN
                    </span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className='me-3'>
                  <Nav.Link
                    as='button'
                    eventKey='2'
                    style={{ borderRadius: 20 }}
                  >
                    <span style={{ fontWeight: 700, fontSize: 11 }}>
                      BUAT LAPORAN
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            {reportProfileTab === "0" && <ProjectSummary />}
            {reportProfileTab === "1" && <FinancialReport />}
            {reportProfileTab === "2" && <Reporting />}
          </React.Fragment>
        )}
        <div className='position-fixed' style={{ top: 195, right: 0 }}>
          <div
            className='bg-info'
            style={{
              padding: "0.47rem",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            <div
              className='d-flex align-items-center certify-button'
              onClick={handleViewProject}
              style={{ fontSize: 12 }}
            >
              <Icon path={mdiFileDocumentOutline} size={1} />
              <span className='mt-2'>PROJEK PROFIL</span>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
