import React from "react";
import { Button, Card, Modal } from "react-bootstrap";

const SuccessResetPassword = ({ showForm, closeForm, data }) => {
  return (
    <Modal
      size='sm'
      show={showForm}
      onHide={closeForm}
      centered
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <Card className='bg-success text-white fw-bold text-center'>
          <Card.Body>
            <Card.Text>{data?.msg}</Card.Text>
            <Card.Text>Kata Laluan Sementara: {data?.new_password}</Card.Text>
          </Card.Body>
        </Card>
        <div className='d-flex justify-content-end'>
          <Button variant='light' onClick={closeForm}>
            Tutup
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessResetPassword;
