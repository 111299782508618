import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import NotyfContext from "../../../../contexts/NotyfContext";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import Ministries from "./components/tables/Ministries";
import MinistryForm from "./components/modals/MinistryForm";
import ModalDelete from "../../../../components/common/ModalDelete";

// Redux
import {
  fetchMinistries,
  removeMinistry,
} from "../../../../redux/slices/government-structure/ministrySlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const [openForm, setOpenForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);

  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    const params = {
      keywords: keywords,
    };
    dispatch(fetchMinistries(params));
  }, [dispatch, keywords]);

  const handleResetState = () => {
    setEdit(false);
    setItem({});
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    handleResetState();
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleEditItem = (item) => {
    setEdit(true);
    setOpenForm(true);
    setItem(item);
  };

  const handleViewChildren = (item) => {
    navigate(
      `/admin/struktur-kerajaan/${item.short_form}/${item.code}/jabatan-dan-agensi`,
    );
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    handleResetState();
  };

  const handleConfirmRemove = (data) => {
    dispatch(removeMinistry(data.id));
    handleNotification("error", data);
    handleCloseRemove();
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = "";
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `Maklumat ${values.name} berjaya dipadam.`;
        break;
      case "edited":
        msg = `Maklumat ${values.name} berjaya dikemaskini.`;
        break;

      default:
        msg = `Maklumat ${values.name} berjaya dicipta.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleSearch = debounce((text) => {
    setKeywords(text);
  }, 500);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
      />

      <MinistryForm
        showForm={openForm}
        closeForm={handleCloseForm}
        edit={edit}
        data={item}
        handleNotification={handleNotification}
      />

      <Helmet title='Senarai Kementerian' />
      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Struktur Kerajaan'
          breadcrumbItem='Senarai Kementerian'
        />
        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Kementerian
              </Button>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div className='search-box-custom'>
                <div className='position-relative'>
                  <label htmlFor='search-bar-0' className='search-label-custom'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Cari Kementerian...'
                      // value={searchText}
                      onChange={(e) => handleSearch(e.target.value)}
                      style={{ width: 250 }}
                    />
                  </label>
                  <MdSearch className='search-icon-2' size={18} />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Ministries
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              handleViewChildren={handleViewChildren}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
