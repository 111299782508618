import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchSustainableDevelopmentGoals = createAsyncThunk(
  "sustainableDevelopmentGoal/fetchSustainableDevelopmentGoals",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(
        `/maintenance/sustainable-development-goals`,
        { params, cancelToken: source.token },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createSustainableDevelopmentGoal = createAsyncThunk(
  "sustainableDevelopmentGoal/createSustainableDevelopmentGoal",
  async (values) => {
    try {
      const response = await axios.post(
        `/maintenance/sustainable-development-goals`,
        values,
      );
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateSustainableDevelopmentGoal = createAsyncThunk(
  "sustainableDevelopmentGoal/updateSustainableDevelopmentGoal",
  async (values) => {
    try {
      const { id } = values;

      const response = await axios.put(
        `/maintenance/sustainable-development-goals/${id}`,
        values,
      );
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeSustainableDevelopmentGoal = createAsyncThunk(
  "sustainableDevelopmentGoal/removeSustainableDevelopmentGoal",
  async (id) => {
    try {
      const response = await axios.delete(
        `/maintenance/sustainable-development-goals/${id}`,
      );
      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  sustainableDevelopmentGoals: [],
  isLoading: false,
  btnLoading: false,
};

export const branchSlice = createSlice({
  name: "sustainableDevelopmentGoal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchSustainableDevelopmentGoals.pending,
      (state, action) => {
        state.isLoading = true;
      },
    );

    builder.addCase(
      fetchSustainableDevelopmentGoals.fulfilled,
      (state, action) => {
        state.sustainableDevelopmentGoals = action.payload;
        state.isLoading = false;
      },
    );

    builder.addCase(
      createSustainableDevelopmentGoal.pending,
      (state, action) => {
        state.btnLoading = true;
      },
    );
    builder.addCase(
      createSustainableDevelopmentGoal.fulfilled,
      (state, action) => {
        state.sustainableDevelopmentGoals.push(action.payload);
        state.btnLoading = false;
      },
    );

    builder.addCase(
      updateSustainableDevelopmentGoal.pending,
      (state, action) => {
        state.btnLoading = true;
      },
    );
    builder.addCase(
      updateSustainableDevelopmentGoal.fulfilled,
      (state, action) => {
        const { id } = action.payload;

        state.sustainableDevelopmentGoals =
          state.sustainableDevelopmentGoals.map((item) => {
            if (item.id === id) {
              return { ...action.payload };
            }

            return item;
          });
        state.btnLoading = false;
      },
    );

    builder.addCase(
      removeSustainableDevelopmentGoal.pending,
      (state, action) => {
        state.btnLoading = true;
      },
    );

    builder.addCase(
      removeSustainableDevelopmentGoal.fulfilled,
      (state, action) => {
        state.sustainableDevelopmentGoals =
          state.sustainableDevelopmentGoals.filter(
            (item) => item.id !== action.payload,
          );

        state.btnLoading = false;
      },
    );
  },
});

export default branchSlice.reducer;
