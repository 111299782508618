import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

import image from "../assets/img/photos/login-banner.jpg";
import SabahLogo from "../assets/img/photos/sabah-logo.png";

import Main from "../components/Main";

import LoadingPage from "../components/common/LoadingPage";

const SignInLayout = ({ children }) => {
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  if (isLoading) return <LoadingPage />;

  return (
    <React.Fragment>
      <div className='container-fluid h-100'>
        <div className='row justify-content-center h-100'>
          <Col md={7} className='hidden-md-down p-0'>
            <div
              className='d-flex align-items-center h-100'
              style={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </Col>
          <Col
            md={5}
            className='p-0 d-flex'
            style={{ backgroundColor: "#e1f5fe" }}
          >
            {children}
            <Outlet />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignInLayout;
