import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 65,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 10,
    textAlign: "center",
  },
  text: {
    fontSize: 10,
    textAlign: "justify",
  },
  subTitle: {
    display: "flex",
    fontSize: "0.75rem",
  },
  image: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
    height: 63,
    width: 40,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  tableContainer: {
    marginTop: 10,
    fontSize: 9,
    borderColor: "#dee6ed",
  },
  tdIndexTitle: {
    backgroundColor: "#dee6ed",
    padding: 4,
    display: "flex",
    justifyContent: "center",
  },
  tdTitle: {
    backgroundColor: "#dee6ed",
    padding: 4,
  },
  tdIndex: {
    padding: 4,
    display: "flex",
    justifyContent: "center",
  },
  tdLabel: {
    fontWeight: "extrabold",
    padding: 4,
  },
  tdContent: {
    padding: 4,
  },
  cellText: {
    display: "flex",
    flexWrap: "wrap",
  },

  childTableContainer: {
    fontSize: 9,
    borderColor: "#dee6ed",
  },
  childCellTitle: {
    display: "flex",
    justifyContent: "center",
    padding: 4,
    backgroundColor: "rgb(239, 242, 247)",
  },
  childCellRowCostDescription: {
    padding: 4,
  },
  childCellRowCost: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 4,
  },
  childCellRowDate: {
    display: "flex",
    justifyContent: "center",
    padding: 4,
  },
});

export { styles };
