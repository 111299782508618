import React, { useEffect, useState } from "react";
import CreditScoreCriteria from "./components/tables/CreditScoreCriteria";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useDispatch } from "react-redux";
import axios from "axios";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// Components
import CriteriaForm from "./components/modals/CriteriaForm";

// Redux
import {
  fetchCreditScoreCriteria,
  removeCreditScoreCriteria,
} from "../../../redux/slices/maintenance/creditScoreCriteriaSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const handleEditItem = (values) => {
    setData(values);
    setOpenForm(true);
    setIsEdit(true);
  };

  const handleRemoveItem = (id) => {
    dispatch(removeCreditScoreCriteria(id));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(fetchCreditScoreCriteria(props));
    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <CriteriaForm
        openForm={openForm}
        closeForm={() => {
          setOpenForm(false);
          setData(null);
          setIsEdit(false);
        }}
        isEdit={isEdit}
        data={data}
      />
      <Helmet title='Skor Kredit Kriteria' />
      <Container fluid className='p-0'>
        <Breadcrumb
          title='Penyelenggaraan'
          breadcrumbItem='Senarai Skor Kredit Kriteria'
        />
        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={() => setOpenForm(true)}>
                <MdAdd /> Kriteria
              </Button>
            </div>
          </Card.Header>
          <Card.Body className='pt-0'>
            <CreditScoreCriteria
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
