import React, { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import ModalDelete from "../../../../components/common/ModalDelete";

// Icons
import { MdAdd, MdSearch } from "react-icons/md";

// redux
import {
  fetchBranches,
  removeBranch,
} from "../../../../redux/slices/government-structure/branchSlice";

// Components
import Branches from "./components/tables/Branches";
import BranchForm from "./components/modals/BranchForm";

const MainPage = () => {
  const dispatch = useDispatch();

  const [perPage, setPerPage] = useState(15);
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const [showRemove, setShowRemove] = useState(false);

  const { btnLoading } = useSelector((state) => ({
    btnLoading: state.branchReducer.btnLoading,
  }));

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setItem({});
    setIsEdit(false);
  };

  const handleEditForm = (values) => {
    setOpenForm(true);
    setItem(values);
    setIsEdit(true);
  };

  const handleRemoveItem = (item) => {
    setShowRemove(true);
    setItem(item);
  };

  const handleCloseRemove = () => {
    setShowRemove(false);
    setItem({});
    setIsEdit(false);
  };

  const handleConfirmRemove = async (data) => {
    await dispatch(removeBranch(data.id));
    handleCloseRemove();
  };

  useEffect(() => {
    const params = {
      per_page: perPage,
    };

    dispatch(fetchBranches(params));
  }, [dispatch]);

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={handleCloseRemove}
        data={item}
        confirmRemove={handleConfirmRemove}
        btnLoading={btnLoading}
      />

      <BranchForm
        showForm={openForm}
        closeForm={handleCloseForm}
        isEdit={isEdit}
        data={item}
      />

      <Helmet title='Senarai Cawangan' />
      <Container fluid className='p-0'>
        <Breadcrumbs title='Jabatan' breadcrumbItem='Senarai Cawangan' />

        <Card className='mt-3'>
          <Card.Header>
            <div className='card-actions float-end'>
              <Button onClick={handleOpenForm}>
                <MdAdd /> Cawangan
              </Button>
            </div>
            <div>
              <div className='d-flex align-items-center gap-2'>
                <div className='search-box-custom'>
                  <div className='position-relative'>
                    <label
                      htmlFor='search-bar-0'
                      className='search-label-custom'
                    >
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Cari cawangan...'
                        // value={searchText}
                        // onChange={(e) => handleSearch(e.target.value)}
                        style={{ width: 250 }}
                      />
                    </label>
                    <MdSearch className='search-icon-2' size={18} />
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Branches
              handleEditItem={handleEditForm}
              handleRemoveItem={handleRemoveItem}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
