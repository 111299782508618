import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchCreditScoreCriteria = createAsyncThunk(
  "creditScoreCriteria/fetchCreditScoreCriteria",
  async (props) => {
    try {
      const { source } = props;
      const response = await axios.get(`/maintenance/credit-score-criterias`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createCreditScoreCriteria = createAsyncThunk(
  "creditScoreCriteria/createCreditScoreCriteria",
  async (values) => {
    const response = await axios.post(
      `/maintenance/credit-score-criterias`,
      values,
    );
    const data = await response.data;
    return data;
  },
);

export const updateCreditScoreCriteria = createAsyncThunk(
  "creditScoreCriteria/updateCreditScoreCriteria",
  async (values) => {
    const { id } = values;
    const response = await axios.put(
      `/maintenance/credit-score-criterias/${id}`,
      values,
    );
    const data = await response.data;
    return data;
  },
);

export const removeCreditScoreCriteria = createAsyncThunk(
  "creditScoreCriteria/removeCreditScoreCriteria",
  async (id) => {
    const response = await axios.delete(
      `/maintenance/credit-score-criterias/${id}`,
    );
    const data = await response.data;
    return id;
  },
);
const initialState = {
  criteriaList: [],
  isLoading: false,
  btnLoading: false,
};

export const creditScoreCriteriaSlice = createSlice({
  name: "creditScoreCriteria",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCreditScoreCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCreditScoreCriteria.fulfilled, (state, action) => {
      state.criteriaList = action.payload;

      state.isLoading = false;
    });

    builder.addCase(createCreditScoreCriteria.pending, (state) => {
      state.btnLoading = true;
    });

    builder.addCase(createCreditScoreCriteria.fulfilled, (state, action) => {
      state.criteriaList.push(action.payload);
      state.btnLoading = false;
    });

    builder.addCase(updateCreditScoreCriteria.pending, (state) => {
      state.btnLoading = true;
    });
    builder.addCase(updateCreditScoreCriteria.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.criteriaList = state.criteriaList.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.btnLoading = false;
    });

    builder.addCase(removeCreditScoreCriteria.fulfilled, (state, action) => {
      const id = action.payload;
      state.criteriaList = state.criteriaList.filter(
        (item) => item.id.toString() !== id.toString(),
      );
      state.btnLoading = false;
    });
  },
});

export default creditScoreCriteriaSlice.reducer;
