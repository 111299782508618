import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { isEmpty } from "lodash";
import numeral from "numeral";
import NotyfContext from "../../../../contexts/NotyfContext";
import LoadingState from "../../../../components/common/LoadingState";

// Components
import SourceOfFinancial from "./components/SourceOfFinancial";
import VirementInformation from "./components/VirementInformation";
import FinancialRequirement from "./components/tables/FinancialRequirement";
import Purpose from "./components/Purpose";
import Background from "./components/Background";
import ProjectComponent from "./components/ProjectComponent";
import Justification from "./components/Justification";
import ViewPaperWork from "./components/pdf-components/ViewPaperWork";
import MinistryComments from "./components/MinistryComments";
import MOFComments from "./components/MOFComments";
import UPENComments from "./components/UPENComments";
import Closing from "./components/Closing";

// Redux
import { fetchProjectDetails } from "../../../../redux/slices/projects/projectSlice";
import {
  createVirementApplication,
  getVirementApplication,
  updateVirementApplication,
} from "../../../../redux/slices/jppn/jppnCeilingVirementSlice";
import {
  createProjectNameChangesApplication,
  updateProjectNameChangesApplication,
} from "../../../../redux/slices/jppn/jppnProjectNameChanges";
import {
  createProjectScopesChangesApplication,
  updateProjectScopesChangesApplication,
} from "../../../../redux/slices/jppn/jppnProjectScopesChanges";
import {
  createLocationChangesApplication,
  updateLocationChangesApplication,
} from "../../../../redux/slices/jppn/jppnLocationChangesSlice";
import {
  getProjectBasicInfo,
  setVirementToData,
  setCreateDraft,
  setProjectComponents,
} from "../../../../redux/slices/jppn/jppnModuleSlice";
import { updateNewProjectApplication } from "../../../../redux/slices/jppn/jppnApplyNewProjectSlice";

const PageContent = ({ prevPage }) => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const notyf = useContext(NotyfContext);

  const [viewPDF, setViewPDF] = useState(false);

  const {
    currentRmk,
    projectIsLoading,
    project,
    virementSource,
    user,
    inputField,

    draft,
    virementOutProjects,
    application,
  } = useSelector((state) => ({
    currentRmk: state.rmkReducer.activeRmk,
    projectIsLoading: state.projectReducer.isLoading,
    project: state.projectReducer.project,
    user: state.userReducer.user,
    inputField: state.jppnReducer.virement.inputField,

    draft: state.jppnReducer.draft,
    virementOutProjects: state.jppnReducer.virement.virementOutProjects,

    application: state.jppnReducer.application,
  }));

  const initialValues = {
    background: draft?.background,
    project_components: draft?.project_components,
    ceiling_requirement: draft?.ceiling_requirement,
    source_of_financial: draft?.source_of_financial,
    justification: draft?.justification,
    ministry_comments: draft?.ministry_comments,
    mof_comments: draft?.mof_comments,
    upen_comments: draft?.upen_comments,
    closing: draft?.closing,
  };

  const handleViewPaperWork = () => {
    const { category, id } = routeParams;
    navigate(`/permohonan/jppn/${category}/${id}/semak`);
  };

  const handleViewPDF = async (e) => {
    e.preventDefault();
    setViewPDF(true);

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setViewPDF(false);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  // Notification
  const handleNotification = () => {
    let msg = "Permohonan telah dihantar";

    if (routeParams.type === "kemas-kini") {
      msg = "Permohonan berjaya diubah";
    }
    // Success
    notyf.open({
      type: "success",
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={async (values) => {
          const initialCeiling = draft?.project_components
            ?.flatMap((item) => item?.ceiling_flow_details)
            ?.reduce((acc, item) => (acc += parseFloat(item?.amount)), 0);
          const additionalCeiling = draft?.project_components?.reduce(
            (acc, item) => (acc += parseFloat(item?.additional_ceiling)),
            0,
          );

          // Ceiling Virement
          if (routeParams.category === "kenaikan-siling") {
            const newValues = {
              ...values,
              purpose: draft?.purpose,
              source_of_financial: {
                // description_one: `${project?.executing_agency?.short_form} telah mengenalpasti sumber pindahan siling bagi projek ini iaitu melalui sumber dalama program di bawah ${project?.executing_agency?.short_form} sendiri iaitu sejumlah RM${ceilingIncrement} akan dipindah keluar daripada ${virementSource?.program?.prefix_type}${virementSource?.ministry?.code} ${virementSource?.program?.code} ${virementSource?.main_project?.code}${virementSource?.code} : ${virementSource?.name}`,
                description_one:
                  "Ringkasan cadangan sumber kewangan untuk permohonan siling projek ini adalah seperti Jadual 1 yang menjelaskan kedudukan selepas pindahan siling.",
                // description_three: `Pindahan sumber siling daripada ${virementSource?.name} (${virementSource?.program?.prefix_type}${virementSource?.ministry?.code} ${virementSource?.program?.code} ${virementSource?.main_project?.code}${virementSource?.code}) tidak akan menjelaskan pelaksanaan program ini memandangkan siling tersedia adalah mencukupi bagi memenuhi permohonan tahunan projek-projek dibawah program tersebut.`,
                description_two: `Sumber kewangan akan diperolehi secara pindah peruntukan daripada vot-vot di bawah ${project?.ministry?.short_form} yang akan dikenalpasti mempunyai penjimatan atau melalui permohonan Anggaran Peruntukan Tambahan (ASP) pada tahun`,
              },
              ceiling_requirement: {
                initial_ceiling: initialCeiling,
                additional_ceiling: additionalCeiling,
                total_new_ceiling:
                  parseFloat(initialCeiling) + parseFloat(additionalCeiling),
                notes: draft?.ceiling_requirement?.notes,
              },
              virement_out_projects: virementOutProjects,
            };

            if (viewPDF) {
              await dispatch(setCreateDraft(newValues));
              handleViewPaperWork();
              return false;
            }

            if (routeParams.type === "kemas-kini") {
              const data = {
                ...newValues,
                id: routeParams.id,
                virement_in_project: {
                  project_id: application?.virement_projects?.find(
                    (item) => item.virement_type === "VI",
                  )?.project_id,
                  total_virement_amount: additionalCeiling,
                },
              };
              await dispatch(updateVirementApplication(data));
            } else {
              const data = {
                ...newValues,
                virement_in_project: {
                  project_id: project?.id,
                  total_virement_amount: additionalCeiling,
                },
              };
              await dispatch(createVirementApplication(data));
            }
          }

          // Project Name Changes
          if (routeParams.category === "perubahan-nama-projek") {
            const newValues = {
              purpose: null,
              background: values?.background,
              justification: values?.justification,
              ministry_comments: values?.ministry_comments,
              upen_comments: values?.upen_comments,
              closing: values?.closing,
              project_name: draft?.project_name,
              project_id: project?.id,
            };

            if (routeParams.type === "kemas-kini") {
              const data = {
                ...newValues,
                id: routeParams.id,
              };
              await dispatch(updateProjectNameChangesApplication(data));
            } else {
              await dispatch(createProjectNameChangesApplication(newValues));
            }
          }

          // Perubahan Skop
          if (routeParams.category === "perubahan-skop") {
            const newValues = {
              purpose: null,
              background: values?.background,
              justification: values?.justification,
              ministry_comments: values?.ministry_comments,
              upen_comments: values?.upen_comments,
              closing: values?.closing,
              project_scopes: draft?.project_scopes,
              project_id: project?.id,
            };

            if (routeParams.type === "kemas-kini") {
              const data = {
                ...newValues,
                id: routeParams.id,
              };
              await dispatch(updateProjectScopesChangesApplication(data));
            } else {
              await dispatch(createProjectScopesChangesApplication(newValues));
            }
          }

          // Ubah Lokasi
          if (routeParams.category === "ubah-lokasi") {
            const newValues = {
              purpose: null,
              background: values?.background,
              justification: values?.justification,
              ministry_comments: values?.ministry_comments,
              upen_comments: values?.upen_comments,
              closing: values?.closing,
              coordinates: draft?.coordinates,
              project_id: project?.id,
            };

            if (routeParams.type === "kemas-kini") {
              const data = {
                ...newValues,
                id: routeParams.id,
              };
              await dispatch(updateLocationChangesApplication(data));
            } else {
              await dispatch(createLocationChangesApplication(newValues));
            }
          }

          // Penyenaraian projek baharu
          if (routeParams.category === "daftar-projek-baharu") {
            const newValues = {
              purpose: draft.purpose,
              background: values?.background,
              justification: values?.justification,
              mof_comments: values?.mof_comments,
              ministry_comments: values?.ministry_comments,
              upen_comments: values?.upen_comments,
              closing: values?.closing,
            };

            if (routeParams.type === "kemas-kini") {
              const data = {
                ...newValues,
                id: routeParams.id,
              };
              await dispatch(updateNewProjectApplication(data));
            }
          }

          handleNotification();
          if (routeParams.type !== "kemas-kini") {
            navigate("/senarai-projek/jppn/status-permohonan");
          }
        }}
      >
        <Form style={{ fontSize: 14 }}>
          <Purpose />
          <Background />

          <ProjectComponent />

          {routeParams.category === "kenaikan-siling" && (
            <FinancialRequirement />
          )}
          <Justification />

          {routeParams.category === "kenaikan-siling" && <SourceOfFinancial />}

          <MinistryComments />

          {(routeParams.category === "kenaikan-siling" ||
            routeParams.category === "daftar-projek-baharu") && <MOFComments />}

          <UPENComments />
          <Closing />
        </Form>
      </Formik>

      <div className='d-flex justify-content-end gap-3'>
        <Button className='px-3' variant='light' onClick={prevPage}>
          Sebelum
        </Button>
        {/* <Button
          className='px-3'
          variant='info'
          onClick={(e) => handleViewPDF(e)}
        >
          PDF
        </Button> */}
        {/* <Button className='px-3' variant='secondary'>
          Draft
        </Button> */}
        <Button className='px-3' variant='primary' onClick={handleSubmit}>
          {routeParams.type === "kemas-kini" ? "Ubah" : "Simpan"}
        </Button>
      </div>

      {/* <ViewPaperWork /> */}
    </React.Fragment>
  );
};

export default PageContent;
