import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import Select from "react-select";

// components
import MainProjects from "./components/MainProjects";
import ModalForm from "./components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// redux
import { getMainProjects, getRmkItems } from "../../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  const { currentRmk, rmkOptions } = useSelector((state) => ({
    currentRmk: state.rmkReducer.activeRmk,
    rmkOptions: state.utilsReducer.dropdownItems.rmkItems,
  }));

  const [showForm, setShowForm] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  const [selectRmk, setSelectRmk] = useState(currentRmk);

  const handleOpenForm = (item) => {
    setShowForm(true);
    setRowItem(item);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {
          is_micro_project_admin: true,
          rmk_id: selectRmk?.number,
        },
      };

      await dispatch(getMainProjects(props));
      await dispatch(getRmkItems(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch, selectRmk]);
  return (
    <React.Fragment>
      <ModalForm
        show={showForm}
        onHide={() => {
          setShowForm(false);
          setRowItem(null);
        }}
        item={rowItem}
      />

      <Helmet title='Senarai Pengguna' />
      <Breadcrumbs title='Sistem Admin' breadcrumbItem='Senarai Seksyen' />
      <Container fluid className='p-0'>
        <Card className='mt-3'>
          <Card.Header className='d-flex justify-content-between'>
            <div className=''>
              <Form.Group>
                <Select
                  name='users'
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  options={rmkOptions}
                  placeholder='Pilih RMK'
                  noOptionsMessage={() => "Tiada Pilihan"}
                  value={selectRmk}
                  onChange={(value) => setSelectRmk(value)}
                />
              </Form.Group>
            </div>
          </Card.Header>
          <Card.Body>
            <MainProjects openForm={handleOpenForm} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
