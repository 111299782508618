import React from "react";
import { Form, Pagination } from "react-bootstrap";

const TablePagination = ({ perPage, totalProjects, currentPage, paginate }) => {
  const pageNumbers = [];
  const maxVisiblePages = 5; // Maximum number of page links to display
  const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);

  for (let i = 1; i <= Math.ceil(totalProjects / perPage); i++) {
    pageNumbers.push(i);
  }

  // Function to determine if a page number is within the visible range
  const withinRange = (pageNumber) => {
    return (
      pageNumber >= currentPage - halfMaxVisiblePages &&
      pageNumber <= currentPage + halfMaxVisiblePages
    );
  };

  return (
    <div className='d-flex justify-content-between'>
      <div>
        <span className=''>
          Halaman <strong>1 daripada 2</strong>
        </span>
        <span className='ms-3 me-2'>Tunjuk:</span>
        <Form.Select
          className='d-inline-block w-auto'
          value=''
          // onChange={(e) => handleChangePerPage(e)}
        >
          {[15, 30, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Form.Select>
        <span className='ms-3 me-2'>Pergi ke halaman:</span>
        <Form.Control
          className='d-inline-block'
          value=''
          onChange={(e) => {
            const value = Number(e.target.value);
          }}
          style={{ width: "75px" }}
        />
      </div>
      <Pagination>
        <Pagination.First
          // onClick={() => paginate(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          // onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {pageNumbers.map((number) =>
          withinRange(number) ? (
            <Pagination.Item
              key={number}
              active={currentPage === number}
              onClick={() => paginate(number)}
            >
              {number}
            </Pagination.Item>
          ) : (
            <React.Fragment key={number}>
              {number === 1 || number === Math.ceil(totalProjects / perPage) ? (
                <Pagination.Ellipsis key={number} />
              ) : null}
            </React.Fragment>
          ),
        )}
        <Pagination.Next
          // onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(totalProjects / perPage)}
        />
        <Pagination.Last
          // onClick={() => paginate(Math.ceil(totalProjects / perPage))}
          disabled={currentPage === Math.ceil(totalProjects / perPage)}
        />
      </Pagination>
    </div>
  );
};

export default TablePagination;
